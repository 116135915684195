import { useEffect, useState } from "react";
import MiniQuizIntro from "./steps/MiniQuizIntro";
import MiniQuiz from "./steps/MiniQuiz";
import MiniQuizEnding from "./steps/MiniQuizEnding";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";
import { api, apiAnom, notifError } from "../../../utils/api";
import MiniQuizInputName from "./steps/MiniQuizInputName";
import MiniQuizInputEmail from "./steps/MiniQuizInputEmail";
import MiniQuizInputPhone from "./steps/MiniQuizInputPhone";
import Cookies from "js-cookie";
import LayoutV2 from "../../../components/Layout_V2";
import MiniQuizExploreMore from "./steps/MiniQuizExploreMore";

export default function TMBMiniQuizPage({ toPretest, toTMB, standalone }) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [currentStep, setCurrentStep] = useState(0);
  const [miniQuizAnswers, setMiniQuizAnswers] = useState();
  const [userData, setUserData] = useState();
  const [pretestData, setPretestData] = useState();
  const [quizResult, setQuizResult] = useState([]);

  const [screenHeight, setViewportHeight] = useState(window.innerHeight);
  const [screenWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (Cookies.get("userPretestData")) {
      const pretestDataString = Cookies.get("userPretestData");
      const data = JSON.parse(pretestDataString);
      setPretestData(data);
    }
  }, []);

  useEffect(() => {
    if (pretestData) {
      Cookies.remove("userPretestData");
    }
  }, [pretestData]);

  function prevStep() {
    setCurrentStep(currentStep - 1);
  }

  function nextStep() {
    setCurrentStep(currentStep + 1);
  }

  function DetermineMiniQuizResult(countA, countB, countC) {
    let result;

    switch (true) {
      case countA > countB && countA > countC:
        result = ["IPA"];
        break;
      case countB > countA && countB > countC:
        result = ["IPS"];
        break;
      case countC > countA && countC > countB:
        result = ["IPA", "IPS"];
        break;
      case countA === countB && countA === countC:
        result = ["IPA", "IPS"];
        break;
      case countA === countB && countA > countC:
        result = ["IPA", "IPS"];
        break;
      case countA === countC && countA > countB:
        result = ["IPA", "IPS"];
        break;
      case countB === countC && countB > countA:
        result = ["IPA", "IPS"];
        break;
      default:
        result = [];
    }

    return result;
  }

  function finishQuiz(e) {
    setMiniQuizAnswers(e);
    const answerMap = { 1: "A", 2: "B", 3: "C" };

    const count = e.reduce((acc, curr) => {
      const answerLetter = answerMap[curr.answer_index];
      acc[answerLetter] = (acc[answerLetter] || 0) + 1;
      return acc;
    }, {});

    const result = DetermineMiniQuizResult(
      count.A ?? 0,
      count.B ?? 0,
      count.C ?? 0
    );

    const userAnswers = e.map((item) => {
      const { answer_index, ...newItem } = item;
      return newItem;
    });
    const miniQuizData = {
      answers: userAnswers,
      is_fun_quiz: standalone ? true : false,
    };

    Cookies.set("userMQData", JSON.stringify(miniQuizData), {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setQuizResult(result.join());
    setCurrentStep(currentStep + 1);
    //   .post("/ptmq/submit", {
    //     student_id: auth.isAuth ? auth.id : null,
    //     mini_quiz_result: miniQuizData,
    //     pre_test_result: pretestData,
    //   })
    //   .then((res) => {
    //     Cookies.set("ptmqID", res.data.data.ptmq_id, {
    //       sameSite: "None",
    //       secure: true,
    //       expires: 1,
    //     });
    //     setQuizResult(res.data.data.miniquiz_result);
    //     Cookies.remove("userPretestData");
    //     setCurrentStep(currentStep + 1);
    //   })
    //   .catch(notifError);
  }

  function NameSubmit(e) {
    const studentName = e;
    let jsonForm = {
      ...userData,
      fullname: studentName,
    };
    setUserData(jsonForm);
    setCurrentStep(currentStep + 1);
  }

  function EmailSubmit(e) {
    const studentEmail = e;
    Cookies.set("ptmqEmail", studentEmail, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    let jsonForm = {
      ...userData,
      email: studentEmail,
    };
    setUserData(jsonForm);
    setCurrentStep(currentStep + 1);
  }

  function PhoneSubmit(e) {
    const studentPhone = e;
    let jsonForm = {
      ...userData,
      phone: studentPhone,
    };
    setUserData(jsonForm);
    setCurrentStep(currentStep + 1);
  }

  function nextTMB(e) {
    const userMiniQuizData = {
      answers: miniQuizAnswers,
    };
    if (e !== undefined) {
      Object.assign(userMiniQuizData, { is_interest_another_major: e });
    }
    if (standalone) {
      Object.assign(userMiniQuizData, { is_fun_quiz: true });
    }
    if (standalone) {
      navigate("/tes-minat", { state: { userMiniQuizData: userMiniQuizData } });
    } else {
      toTMB(userMiniQuizData);
    }
  }

  const resultIllu = (result) => {
    switch (result) {
      case "IPA":
        return screenWidth < 1024
          ? "/tmb_miniquiz/illu_result_sierra_ipa.png"
          : "/tmb_miniquiz/illu_result_sierra_ipa_desktop.svg";
      case "IPS":
        return screenWidth < 1024
          ? "/tmb_miniquiz/illu_result_sierra_ips.png"
          : "/tmb_miniquiz/illu_result_sierra_ips_desktop.svg";
      case "IPA,IPS":
        return screenWidth < 1024
          ? "/tmb_miniquiz/illu_result_sierra_ipc.png"
          : "/tmb_miniquiz/illu_result_sierra_ipc_desktop.svg";
      default:
        return "/tmb_miniquiz/illu_result_sierra_ipc.png";
    }
  };

  // const quizSteps = standalone
  //   ? [
  //       <MiniQuizIntro
  //         screenWidth={screenWidth}
  //         onNext={nextStep}
  //         onBack={toPretest}
  //         standalone={standalone}
  //       />,
  //       // <MiniQuizInputName
  //       //   submit={NameSubmit}
  //       //   screenWidth={screenWidth}
  //       //   prevStep={prevStep}
  //       // />,
  //       // <MiniQuizInputEmail
  //       //   submit={EmailSubmit}
  //       //   screenWidth={screenWidth}
  //       //   prevStep={prevStep}
  //       // />,
  //       // <MiniQuizInputPhone
  //       //   submit={PhoneSubmit}
  //       //   screenWidth={screenWidth}
  //       //   prevStep={prevStep}
  //       // />,
  //       <MiniQuiz
  //         screenWidth={screenWidth}
  //         onBack={prevStep}
  //         onNext={finishQuiz}
  //       />,
  //       <MiniQuizEnding
  //         screenWidth={screenWidth}
  //         onBack={prevStep}
  //         onNext={() => {
  //           if (quizResult === "IPA,IPS") {
  //             nextTMB();
  //           } else {
  //             nextStep();
  //           }
  //         }}
  //         isStandalone={standalone ? true : false}
  //         quizResult={quizResult}
  //       />,
  //       <MiniQuizExploreMore
  //         screenWidth={screenWidth}
  //         onBack={prevStep}
  //         onNext={(e) => {
  //           nextTMB(e);
  //         }}
  //         quizResult={quizResult}
  //       />,
  //     ]
  //   : [
  //       <MiniQuizIntro
  //         screenWidth={screenWidth}
  //         onNext={nextStep}
  //         onBack={toPretest}
  //         standalone={standalone}
  //       />,
  //       <MiniQuiz
  //         screenWidth={screenWidth}
  //         onBack={prevStep}
  //         onNext={finishQuiz}
  //       />,
  //       <MiniQuizEnding
  //         screenWidth={screenWidth}
  //         onBack={prevStep}
  //         onNext={() => {
  //           if (quizResult === "IPA,IPS") {
  //             nextTMB();
  //           } else {
  //             nextStep();
  //           }
  //         }}
  //         isStandalone={standalone ? true : false}
  //         quizAnswers={miniQuizAnswers}
  //         quizResult={quizResult}
  //       />,
  //       <MiniQuizExploreMore
  //         screenWidth={screenWidth}
  //         onBack={prevStep}
  //         onNext={(e) => {
  //           nextTMB(e);
  //         }}
  //         quizResult={quizResult}
  //       />,
  //     ];

  const quizSteps = [
    <MiniQuizIntro
      screenWidth={screenWidth}
      onNext={nextStep}
      onBack={toPretest}
      standalone={standalone}
    />,
    <MiniQuiz
      screenWidth={screenWidth}
      onBack={prevStep}
      onNext={finishQuiz}
    />,
    <MiniQuizEnding
      screenWidth={screenWidth}
      onBack={prevStep}
      onNext={() => {
        if (quizResult === "IPA,IPS") {
          nextTMB();
        } else {
          nextStep();
        }
      }}
      quizAnswers={miniQuizAnswers}
      quizResult={quizResult}
    />,
    <MiniQuizExploreMore
      screenWidth={screenWidth}
      onBack={prevStep}
      onNext={(e) => {
        nextTMB(e);
      }}
      quizResult={quizResult}
    />,
  ];

  return (
    <LayoutV2
      withoutHeader={!standalone}
      withoutFooter={!standalone}
      padding="0"
      noHeight={!standalone && true}
    >
      <div
        className="flex flex-col flex-1 w-full h-full justify-start items-stretch relative"
        style={{
          backgroundColor: "#44BBCD",
          overflowY: !standalone && "hidden",
        }}
      >
        {quizSteps[currentStep]}
        {currentStep === 0 && (
          <img
            src={`${
              screenWidth < 1024
                ? "/tmb_miniquiz/illu_sierra_test.png"
                : "/tmb_miniquiz/illu_sierra_desktop.svg"
            }`}
            className="absolute bottom-0 left-0 z-20"
            style={{
              maxWidth: screenWidth >= 1024 && "490px",
              left: screenWidth >= 1024 && "50%",
              transform: screenWidth >= 1024 && "translate(-50%, 0%)",
            }}
          />
        )}
        {/* {currentStep === (standalone ? 5 : 2) && ( */}
        {currentStep === 2 && (
          <img
            src={resultIllu(quizResult)}
            className="absolute bottom-0 z-20"
            // className="absolute -bottom-32 sm:-bottom-16 sm:right-4 z-20"
            style={{
              maxWidth: screenWidth >= 1024 && "1037px",
              left: quizResult === "IPA" && "0px",
              right: quizResult !== "IPA" && "0px",
            }}
          />
        )}
        <img
          src={`${
            screenWidth < 1024
              ? "/tmb_miniquiz/vector_1.svg"
              : "/tmb_miniquiz/vector_1_desktop.svg"
          }`}
          className="absolute -bottom-16 right-0 z-10"
          style={{ maxWidth: screenWidth >= 1024 && "469px" }}
        />
        <img
          src={`${
            screenWidth < 1024
              ? "/tmb_miniquiz/vector_2.svg"
              : "/tmb_miniquiz/vector_2_desktop.svg"
          }`}
          className="absolute -top-20 left-9 sm:-top-12 sm:left-15"
          style={{ maxWidth: screenWidth >= 1024 && "188.4px" }}
        />
      </div>
    </LayoutV2>
  );
}
