import { useState } from "react";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import FavoriteButton from "./FavoriteButton";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import { Link } from "react-router-dom";

export default function CardUniversity({ isFavorite, ...rest }) {
  const [favorite, setFavorite] = useState(isFavorite);
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  let navigate = useNavigate();
  const accreditationTitle = (type) => {
    switch (type) {
      case "N":
        return "Negeri";
      case "L":
        return "Luar Negeri";
      case "S":
        return "Swasta";
      default:
        return "Lainnya";
    }
  };

  // const onCardUnivClicked = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   navigate(`/explore-kampus/detail/${rest.id}`, {
  //     state: {
  //       isFavorite: favorite,
  //     },
  //   });
  // };

  const addUnivToFav = (fav, id) => {
    if (fav && !auth.isAuth) {
      navigate({
        pathname: "/signin",
        search: `?redirect=/explore-kampus/detail/${id}`,
      });
      return;
    }

    const url = fav
      ? "/favorites/university/add"
      : "/favorites/university/delete";

    _api
      .post(url, {
        uni_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };

  return (
    <div
      className="relative divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow card-max-width"
      style={{
        minWidth: "300px",
      }}
    >
      <div className="flex flex-col">
        <div className="flex items-center justify-center h-28 w-full">
          <img
            src={rest.img_url}
            alt={rest.name}
            className="rounded-t-lg w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col my-2 mx-3">
          <div className="flex flex-row items-center w-full h-7">
            <p className="flex flex-1 justify-start text-xs text-gray-500">
              {accreditationTitle(rest.type ?? "")}
            </p>
            {rest.verified ? (
              <div className="flex flex-1 justify-end">
                <img
                  src="/verified_icon2.svg"
                  alt="verified icon"
                  className="h-4 w-4 mac:h-6 mac:w-6 ml-1 mac:ml-2"
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <Link to={`/explore-kampus/${rest.slug}`}>
            <div className="flex flex-row items-center justify-start leading-tight ml-3 my-2 h-12 tablet:w-72">
              <img
                className="h-10 w-10 object-contain"
                src={rest.logo_url}
                alt={rest.name + "logo"}
              />
              <p className="text-lg font-bold text-gray-900 ml-3 line-clamp-2">
                {rest.name}
              </p>
            </div>
          </Link>
          <div className="flex flex-row w-full items-start h-12">
            <div className="flex flex-row flex-1 items-center justify-start">
              <LocationMarkerIcon className="h-3 w-3" />
              <p className="text-sm font-normal text-gray-500 ml-1">
                {rest.province}
              </p>
            </div>
            <p className="flex flex-1 items-start justify-end text-sm text-right font-normal text-gray-500">
              Akreditasi {rest.accreditation}
            </p>
          </div>
          <p className="text-sm font-bold text-black">Biaya Kuliah</p>
          <p className="text-sm font-medium text-black tracking-spacey">
            {`${
              rest.lowest_fee_major == null
                ? "Rp 0"
                : `Rp ${rest.lowest_fee_major.toLocaleString()}`
            } - ${
              rest.highest_fee_major == null
                ? "Rp 0"
                : `Rp ${rest.highest_fee_major.toLocaleString()}`
            }`}
          </p>
        </div>
        <FavoriteButton
          className="absolute top-3 right-2"
          isFavorite={favorite}
          onChangeFavorite={() => {
            setFavorite(!favorite);
            //callback fav, and id;
            addUnivToFav(!favorite, rest.id);
          }}
        />
      </div>
    </div>
  );
}
