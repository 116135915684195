import { MdArrowBack } from "react-icons/md";

export default function StepFourV2({ onBack, onNext, screenWidth }) {
  return (
    <div className="flex flex-col flex-1 w-screen h-full items-center relative">
      <div className="w-full flex justify-start sm:pl-28">
        <button onClick={onBack} className="mt-13 ml-9">
          <MdArrowBack className="w-6 sm:w-10 fhd:w-14 h-auto text-white" />
        </button>
      </div>
      <div
        className="flex flex-col items-stretch mt-10 sm:mt-16 fhd:ml-22 px-7"
        style={{
          maxWidth: screenWidth >= 1024 && "699.51px",
        }}
      >
        {screenWidth < 1024 ? (
          <div className="w-full flex justify-center">
            <img
              src="/illu_tmb_endingchat_mobile.svg"
              className="w-full h-auto"
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <img
              src="/illu_tmb_endingchat_desktop.svg"
              className="w-full h-auto"
            />
          </div>
        )}
        <div className="w-full flex justify-end sm:mt-7">
          <button
            onClick={onNext}
            className="bg-mustard rounded-md sm:rounded-20px w-max text-center font-bold text-base sm:text-xl text-black1 relative z-50"
            style={{
              paddingTop: `${screenWidth < 1024 ? "5px" : "14px"}`,
              paddingBottom: `${screenWidth < 1024 ? "5px" : "14px"}`,
              paddingLeft: `${screenWidth < 1024 ? "9px" : "18px"}`,
              paddingRight: `${screenWidth < 1024 ? "9px" : "18px"}`,
            }}
          >
            Lihat Karakter
          </button>
        </div>
      </div>
      <img
        src="/illu_tmb_revamp_planet_2.svg"
        className="absolute -z-1 right-0 bottom-0"
        style={{
          width: screenWidth < 1024 ? "165px" : "600px",
        }}
      />
      <img
        src="/illu_tmb_revamp_sierra.svg"
        className="absolute z-10 w-68 left-4 -bottom-36 tablet:w-80 tablet:left-30 tablet:-bottom-20 mac:w-100 mac:left-48 mac:-bottom-14"
        style={{
          minWidth: `${screenWidth >= 1920 && "544.51px"}`,
          maxWidth: `${screenWidth >= 1920 && "544.51px"}`,
          left: `${screenWidth >= 1920 && "257.29px"}`,
          bottom: `${screenWidth >= 1920 && "-100px"}`,
        }}
      />
    </div>
  );
}
