import { ArrowRightIcon } from "@heroicons/react/solid";

export default function CardCharHome({
  screendwidth,
  backgroundColor,
  description,
  iconUrl,
  vectorColor,
  onClick,
  fontColor,
}) {
  return (
    <div
      className="w-full flex flex-col justify-start items-stretch relative"
      style={{
        height: screendwidth < 1024 ? "637px" : "270px",
        backgroundColor: backgroundColor,
        borderRadius: "20px",
        padding: screendwidth < 1024 ? "45px 16px" : "49px 64px",
      }}
    >
      <p
        className="text-left font-bold text-2xl relative z-90"
        style={{
          lineHeight: "28.8px",
          letterSpacing: "-0.8px",
          color: fontColor,
        }}
      >
        Karakter Mana yang Kamu Banget?
      </p>
      <p
        className="text-left font-normal text-base text-white relative z-90"
        style={{
          lineHeight: "24.8px",
          marginTop: screendwidth < 1024 ? "13px" : "20px",
          maxWidth: screendwidth >= 1024 && "517px",
          color: fontColor,
        }}
      >
        {description}
      </p>
      <button
        onClick={onClick}
        className="w-full bg-white rounded-lg flex gap-2 justify-center items-center relative z-90"
        style={{
          marginTop: screendwidth < 1024 ? "55px" : "20px",
          minHeight: screendwidth < 1024 ? "46.22px" : "52px",
          maxHeight: screendwidth < 1024 ? "46.22px" : "52px",
          maxWidth: screendwidth >= 1024 && "200.56px",
        }}
      >
        <p
          className="text-right font-semibold text-base tablet:text-sm text-primaryColor"
          style={{ lineHeight: screendwidth < 1024 ? "24px" : "21px" }}
        >
          Cari Tahu Sekarang!
        </p>
        <ArrowRightIcon className="w-6 h-6 text-primaryColor" />
      </button>
      <img
        src={iconUrl}
        className="absolute bottom-0 tablet:right-0 z-90"
        style={{
          transform: screendwidth < 1024 && "translate(-50%, 0%)",
          left: screendwidth < 1024 && "50%",
        }}
      />
      <div className="absolute top-0 right-0 z-80">
        {screendwidth < 1024 ? (
          <svg
            width="238"
            height="637"
            viewBox="0 0 238 637"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M188.825 637H217.646C228.691 637 237.646 628.046 237.646 617V20C237.646 8.95431 228.691 0 217.646 0H0C249.738 233.769 228.997 536.58 188.825 637Z"
              fill={vectorColor}
            />
          </svg>
        ) : (
          <svg
            width="428"
            height="270"
            viewBox="0 0 428 270"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M273.671 270H410C419.941 270 428 261.941 428 252V18C428 8.05888 419.941 0 410 0H0.955078C333.159 89.8238 315.546 226.867 273.671 270Z"
              fill={vectorColor}
            />
          </svg>
        )}
      </div>
    </div>
  );
}
