const TMBResultPDFCover = ( name, date, eduLevel, schoolName, major, reccMajors, reccUnivs, char1, char2, char3 ) => `
<!DOCTYPE html>
<html>

<head>
  <style>
    #commonText {
      font-size: 48px;
      font-weight: 600;
      line-height: 60.48px;
      letter-spacing: -0.04em;
      text-align: center;
      margin-top: -40px;
    }

    #nameBox {
      height: 150px;
      padding: 0 38.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 70px;
    }

    #schoolBox {
      height: 150px;
      padding: 45px 41.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    #majorBox {
      height: 150px;
      padding: 15px 36.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 80px;
    }

    #univLogoBox {
      width: 212px;
      height: 212px;
      border-radius: 100%;
      background-color: rgba(230, 230, 230, 1);
    }

    #univLogoImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  </style>
</head>

<body>
  <div
    style="width: 2480px; height: 3508px; object-fit: cover; position: relative; display: flex; flex-direction: column; justify-content: flex-start; align-items: stretch;">
    <div style="position: relative; width: 2480px; height: 1528px;">
      <img src="/tmb_result/cover_header.svg" style="width: 100%; height: auto; object-fit: contain;" />
      <div
        style="width: 100%; display: flex; flex-direction: column; justify-content: end; align-items: center; gap: 54px; position: absolute; bottom: 35px;">
        <div id="nameBox" style="min-width: 1002px;">
          <p id="commonText" style="color: black;">
            ${name}</p>
        </div>
        <div style="height: 81px;">
          <p
            style="font-size: 64px;font-weight: 600;line-height: 80.64px;letter-spacing: -0.04em;text-align: center; color: white; margin-top: -40px;">
            Tanggal Pengambilan Tes:</p>
        </div>
      </div>
    </div>
    <div
      style="width: 100%; height: 133px; display: flex; justify-content: center; align-items: center; background-color: rgba(255, 202, 45, 1);">
      <p id="commonText" style="color: black;">
        ${date}</p>
    </div>
    <div
      style="width: 100%; height: 245px; display: flex; justify-content: center; align-items: center; gap: 44px; background-color: black; position: relative; z-index: 10;">
      <div id="schoolBox" style="width: 621px;">
        <p id="commonText" style="color: black;">
          ${eduLevel}</p>
      </div>
      <div id="schoolBox" style="width: 1002px;">
        <p id="commonText" style="color: black;">
          ${schoolName}</p>
      </div>
      <div id="schoolBox" style="width: 621px;">
        <p id="commonText" style="color: black;">
          ${major}</p>
      </div>
    </div>
    <div style="width: 100%; display: flex; flex: 1 1 0%; background-color: #57C2D2; position: relative;">
      <div
        style="width: 816px; display: flex; flex-direction: column; justify-content: start; align-items: stretch; gap: 76px; position: absolute; top: 75px; left: 111px; z-index: 10;">
        <div
          style="width: 100%; height: 1140px; display: flex; flex-direction: column; justify-content: start; align-items: stretch; gap: 48px;">
          <div id="majorBox" style="width: 100%; background-color: black;">
            <p id="commonText" style="color: white;">
              Rekomendasi jurusan yang cocok untuk kamu:</p>
          </div>
          <div id="majorBox" style="width: 100%; background-color: white;">
            <p id="commonText" style="color: black;">
              ${reccMajors[0]}</p>
          </div>
          <div id="majorBox" style="width: 100%; background-color: white;">
            <p id="commonText" style="color: black;">
              ${reccMajors[1]}</p>
          </div>
          <div id="majorBox" style="width: 100%; background-color: white;">
            <p id="commonText" style="color: black;">
              ${reccMajors[2]}</p>
          </div>
          <div id="majorBox" style="width: 100%; background-color: white;">
            <p id="commonText" style="color: black;">
              ${reccMajors[3]}</p>
          </div>
          <div id="majorBox" style="width: 100%; background-color: white;">
            <p id="commonText" style="color: black;">
              ${reccMajors[4]}</p>
          </div>
        </div>
        {reccUnivs &&
        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
          <div id="univLogoBox">
            <img src=${reccMajors[0]} style="width: 100%; height: 100%; object-fit: contain;" />
          </div>
          {reccMajors[1] &&
          <div id="univLogoBox">
            <img src=${reccMajors[1]} style="width: 100%; height: 100%; object-fit: contain;" />
          </div>}
          {reccMajors[2] &&
          <div id="univLogoBox">
            <img src=${reccMajors[2]} style="width: 100%; height: 100%; object-fit: contain;" />
          </div>}
        </div>
        }
      </div>
      <img src="/tmb_result/illu_vector_blob.svg" style="position: absolute; bottom: 0; left: 0;" />
      <img src="/tmb_result/illu_sierra_bottom.svg" style="position: absolute; bottom: 0; right: 0;" />
    </div>
  </div>
  <div style="width: 2480px; height: 3508px; object-fit: contain;">
    <img src=${char1} style="width: 100%; height: 100%;" />
  </div>
  <div style="width: 2480px; height: 3508px; object-fit: contain;">
    <img src=${char2} style="width: 100%; height: 100%;" />
  </div>
  <div style="width: 2480px; height: 3508px; object-fit: contain;">
    <img src=${char3} style="width: 100%; height: 100%;" />
  </div>
</body>

</html>
`;

export default TMBResultPDFCover;