import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Layout from "../../../components/Layout";

import { authState } from "../../../atoms/authState";
import { api, apiAnom, notifError } from "../../../utils/api";
import FavoriteButton from "../../../components/FavoriteButton";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HeartIcon as HeartIconFilled,
} from "@heroicons/react/solid";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleDownIcon,
  ChevronUpIcon,
  HeartIcon,
  LocationMarkerIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { useRef, useState, Fragment } from "react";
import { useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Dialog, Listbox, Menu, Transition } from "@headlessui/react";
import VerifiedIcon from "../../../components/VerifiedIcon";
import { MdClose } from "react-icons/md";
import HomeCardExplore from "../../../components/HomeCardExplore";
import HomeCardMajor from "../../../components/HomeCardMajor";
import HomeCardClass from "../../../components/HomeCardClass";
import { useDraggable } from "react-use-draggable-scroll";
import PageLoading from "../../PageLoading";
import SignInModal from "../../../components/SignInModal";
import { signInUpState } from "../../../atoms/signInUpState";
import { regisUniState } from "../../../atoms/regisUni";
import CardContentDetailKampus from "../../../components/CardContentDetailKampus";
import CardInfoDetailKampus from "../../../components/CardInfoDetailKampus";
import ButtonBiayaMasuk from "../../../components/ButtonBiayaMasuk";
import ButtonJalurPenerimaan from "../../../components/ButtonJalurPenerimaan";
import Slider from "react-slick";
import "../../../slick-carousel.css";
import LayoutV2 from "../../../components/Layout_V2";
import HomeCardMajorV3 from "../../../components/HomeCardMajorV3";

export default function UniversityDetailSlugV3() {
  const sliderRefFacultyMajor = useRef();
  const sliderRefAdmission = useRef();
  const sliderRefActivities = useRef();
  const sliderRefClasses = useRef();
  const sliderRefRegisInfo = useRef();
  const sliderRefAlumnus = useRef();
  const sliderRefFacilities = useRef();
  const sliderRefAccomodations = useRef();
  const sliderRefSocialLife = useRef();
  const facultiesScrollRef = useRef();
  // const { events } = useDraggable(facultiesScrollRef);
  let { slug } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [regisInfoClickTime, setRegisInfoClickTime] = useState(Date.now());
  const [data, setData] = useState();
  const [favorite, setFavorite] = useState(state?.isFavorite ?? false);
  const [isLoading, setLoading] = useState(true);
  const [faved, setFaved] = useState(false);
  const [activities, setActivities] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [activityCounter, setActivityCounter] = useState(1);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [toRegisUni, setToRegisUni] = useState(false);
  const setSign = useSetRecoilState(signInUpState);
  const setRegisUni = useSetRecoilState(regisUniState);
  const [uniClasses, setUniClasses] = useState([]);
  const [showPBMTooltip, setShowPBMTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const slickFacultyMajorSetting = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
  };

  const slickAdmissionSetting = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  const slickActivitiesSetting = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  const slickAlumnusSetting = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  const slickFacilitiesSetting = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  const slickSocialLifeSetting = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  useEffect(() => {
    _api
      .get(`/explore-kampus-v2/${slug}`)
      .then((res) => {
        setData(res.data.data);
        setUniClasses(
          res.data.data.classes.filter((obj) => obj.deleted === false)
        );
        setFavorite(res.data.data.favorite);
        setActivities(res.data.data.extras.activities);
        setFaculties(res.data.data.extras.faculties);
        setSelectedFaculty(
          res.data.data.extras.faculties.length > 0 &&
            res.data.data.extras.faculties[0]
        );
        setFacultiesTotalSlides(
          res.data.data.extras.faculties.length > 0 &&
            Math.ceil(res.data.data.extras.faculties[0].majors.length / 3)
        );
        document.title = `Explore Kampus - ${res.data.data.name.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ""
        )}`;
        setLoading(false);
      })
      .catch(notifError);
  }, []);

  const typeTitle = (type) => {
    switch (type) {
      case "N":
        return "Negeri";
      case "L":
        return "Luar Negeri";
      case "S":
        return "Swasta";
      default:
        return "Lainnya";
    }
  };

  const addUnivToFav = (fav, id) => {
    if (fav && !auth.isAuth) {
      navigate({
        pathname: "/signin",
        search: `?redirect=/explore-kampus/detail/${id}`,
      });
    }

    const url = fav
      ? "/favorites/university/add"
      : "/favorites/university/delete";

    _api
      .post(url, {
        uni_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };

  const [isActivityDDOpen, setIsActivityDDOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [selectedFaculty, setSelectedFaculty] = useState();
  const [facultiesTotalSlides, setFacultiesTotalSlides] = useState();
  const [facultiesCurrentSlideIndex, setFacultiesCurrentSlideIndex] =
    useState(1);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  const admissionRedirect = (slug) => {
    switch (slug) {
      case "lasalle-college-jakarta":
        return "/admission/lasalle-college-jakarta?admissionID=2ThmTYRZNwZoniAFH1coUFOqO7T";
      case "sampoerna-university":
        return "/admission/sampoerna-university?admissionID=2U1p0nZx3FbmkVmCGxlhWidpP6x";
      case "universitas-widyatama":
        return "/admission/universitas-widyatama?admissionID=2U1oduDcRfxTxCJgYeN61Pv7phB";
      default:
        return `/daftar-kampus/${slug}`;
    }
  };

  function admiUniRedirect(slug) {
    if (slug === "lasalle-college-jakarta") {
      return "/admission/lasalle-college-jakarta?admissionID=2U1p2QtEMc2SE3drW1CMGPRbmKD";
    } else {
      return `/daftar-kampus/${slug}`;
    }
  }

  function isValidHttpUrl(string) {
    let url = string;
    try {
      url = new URL(string);
    } catch (_) {
      return `https:${string}`;
    }
    return url;
  }

  function isValidWANumber(string) {
    const waNumber = string.replace(/\s/g, "");
    if (waNumber[0] + waNumber[1] === "62") {
      return waNumber;
    } else if (waNumber[0] !== "0") {
      return "62" + waNumber;
    } else if (waNumber[0] === "0") {
      return waNumber.replace(/^.{1}/g, "62");
    }
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0px" relative={true} showModal={true}>
      {/* Sticky */}
      <div
        className="flex flex-row w-full justify-between tablet:items-start mt-8 px-6 fixed z-80"
        style={{
          paddingLeft: screenWidth >= 1024 && " 72px",
          paddingRight: screenWidth >= 1024 && " 72px",
        }}
      >
        <div className="w-1/2 flex justify-start items-center">
          <button
            onClick={() => {
              navigate("/tes-minat");
            }}
            className={`h-13 bg-white flex justify-center items-center ${
              screenWidth < 1024 ? "w-13" : "gap-2"
            }`}
            style={{
              boxShadow:
                screenWidth < 1024
                  ? "4px 4px 40px rgba(64, 69, 79, 0.16)"
                  : "3.56px 3.56px 35.56px 0px rgba(64, 69, 79, 0.16)",
              borderRadius: "8px",
              width: screenWidth >= 1024 && "115.22px",
            }}
          >
            <img
              src={
                screenWidth < 1024 ? "/icon_tmb.png" : "/icon_tmb_desktop.svg"
              }
              style={{ width: "26px", height: "26px" }}
            />
            {screenWidth >= 1024 && (
              <p
                className="text-left font-semibold text-primaryColor"
                style={{ fontSize: "14.22px", lineHeight: "21.33px" }}
              >
                Tes minat
              </p>
            )}
          </button>
        </div>
        <div className="w-1/2 flex flex-row justify-end gap-4 ">
          <button
            className="w-13 h-13 bg-white flex justify-center items-center"
            style={{
              boxShadow: "4px 4px 40px rgba(64, 69, 79, 0.16)",
              borderRadius: "8px",
            }}
            aria-hidden="true"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addUnivToFav(!favorite, data?.id);
            }}
          >
            {favorite ? (
              <HeartIconFilled
                className="text-red-700"
                style={{ width: "30px", height: "30px" }}
              />
            ) : (
              <HeartIcon style={{ width: "30px", height: "30px" }} />
            )}
          </button>
          {data?.contacts?.website && (
            <a
              href={isValidHttpUrl(data?.contacts?.website)}
              target="_blank"
              className="w-13 h-13 bg-white flex justify-center items-center"
              style={{
                boxShadow: "4px 4px 40px rgba(64, 69, 79, 0.16)",
                borderRadius: "8px",
                gap: screenWidth >= 1024 && "3.22px",
                width: screenWidth >= 1024 && "177.78px",
              }}
            >
              {screenWidth >= 1024 && (
                <p
                  className="text-right font-semibold text-primaryColor"
                  style={{ fontSize: "14.22px", lineHeight: "21.33px" }}
                >
                  Penerimaan kampus
                </p>
              )}
              <img
                src={
                  screenWidth < 1024
                    ? "/icon_hyperlink.svg"
                    : "/icon_hyperlink_desktop.svg"
                }
                style={{
                  width: screenWidth < 1024 ? "26px" : "14.22px",
                  height: screenWidth < 1024 ? "26px" : "14.22px",
                }}
              />
            </a>
          )}
        </div>
      </div>
      {/* Header */}
      <div
        className="flex flex-row w-full justify-between tablet:items-start mt-8 px-6"
        style={{
          paddingLeft: screenWidth >= 1024 && " 72px",
          paddingRight: screenWidth >= 1024 && " 72px",
        }}
      >
        <div className="w-full flex justify-center">
          <img
            className="h-14 tablet:h-22 w-auto"
            src={data?.logo_url}
            alt={"logo"}
          />
        </div>
      </div>
      {/* Univ Desc. */}
      <div
        className={`flex flex-col tablet:flex-row tablet:justify-between w-full`}
        style={{
          paddingRight: screenWidth >= 1024 && "72.55px",
          gap: screenWidth >= 1024 && "43.45px",
        }}
      >
        {screenWidth < 1024 ? (
          <div
            className="w-full relative"
            style={{
              height: screenWidth < 1024 && "332px",
              marginTop: "26px",
            }}
          >
            <img
              src="/bg_detail_kampus_mobile.png"
              className="absolute top-0 w-full object-cover"
              style={{
                maxHeight: screenWidth < 1024 && "332px",
                borderRadius: screenWidth < 1024 && "18px",
              }}
            />
            <div
              className="flex flex-col w-full h-full relative z-20"
              style={{
                paddingTop: "30px",
              }}
            >
              <div
                className="flex justify-center items-center"
                style={{
                  height: "76px",
                }}
              >
                <p
                  className="text-center font-bold text-3xl tablet:text-60px text-white"
                  style={{
                    lineHeight: screenWidth < 1024 ? "38.4px" : "72px",
                    letterSpacing: screenWidth < 1024 ? "-1.3px" : "-1.3px",
                    paddingLeft: "17px",
                    paddingRight: "17px",
                  }}
                >
                  {data?.name}
                </p>
              </div>
              <div
                className="w-full flex flex-row justify-center items-center gap-5"
                style={{
                  marginTop: "26px",
                  paddingLeft: "17px",
                  paddingRight: "17px",
                }}
              >
                <div className="flex flex-row gap-2 items-center justify-end">
                  <img className="h-4" src="/univ-data-type-icon.svg" />
                  <p className="text-center font-normal text-xs tablet:text-base text-white">
                    {typeTitle(data?.type)}
                  </p>
                </div>
                <div className="flex flex-row gap-2 items-center justify-center">
                  <img className="h-4" src="/location-icon.svg" />
                  <p className="text-center font-normal text-xs tablet:text-base text-white">
                    {data?.province}
                  </p>
                </div>
                {data?.accreditation !== "Not Applied" && (
                  <div className="flex flex-row gap-2 items-center justify-start">
                    <img className="h-4" src="/star-icon.svg" />
                    <p className="text-center font-normal text-xs tablet:text-base text-white">
                      {`Akreditasi ${data?.accreditation}`}
                    </p>
                  </div>
                )}
                {screenWidth >= 1024 && data?.verified && (
                  <img
                    src="/verified_icon2.svg"
                    className="h-4 w-4 ml-1 tablet:h-5 tablet:w-5 tablet:ml-2 mac:h-6 mac:w-6 mac:ml-2"
                  />
                )}
              </div>
              {videoPlaying ? (
                <iframe
                  id="ytplayer"
                  type="text/html"
                  // width={`${screenWidth}`}
                  height={`${screenWidth < 1024 ? 157 : 628}`}
                  src={`https://www.youtube.com/embed/${
                    data?.video_url.split("watch?v=")[1]
                  }?autoplay=1&origin=https://www.youtube.com`}
                  frameborder="0"
                  style={{ marginTop: "10px", borderRadius: "18px" }}
                ></iframe>
              ) : (
                <div
                  className="relative w-full"
                  style={{
                    minHeight: "170.08px",
                    maxHeight: screenWidth < 1024 ? "170.08px" : "628px",
                    marginTop: "10px",
                  }}
                >
                  {data.video_url && data.video_url !== "" && (
                    <button
                      className={`absolute flex flex-row items-center w-max ${
                        screenWidth < 1024 ? "gap-1" : "gap-2"
                      } px-4 py-2 bg-white object-center`}
                      style={{
                        borderRadius: "5px",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      onClick={() => {
                        setVideoPlaying(true);
                      }}
                    >
                      <img
                        src="/star-4point.svg"
                        className={`absolute ${
                          screenWidth < 1024 ? "-top-14" : "-top-10"
                        } ${
                          screenWidth < 1024 ? "-left-24" : "-left-12"
                        } h-12 w-auto`}
                      />
                      <img
                        src="/star-4point.svg"
                        className={`absolute ${
                          screenWidth < 1024 ? "-bottom-10" : "-bottom-8"
                        } ${
                          screenWidth < 1024 ? "-left-12" : "left-6"
                        } h-6 w-auto`}
                      />
                      <img
                        src="/star-4point.svg"
                        className={`absolute ${
                          screenWidth < 1024 ? "-bottom-5" : "-bottom-5"
                        } ${
                          screenWidth < 1024 ? "-right-24" : "-right-10"
                        } h-8 w-auto`}
                      />
                      <img
                        className={`${
                          screenWidth < 1024 ? "h-2" : "h-3.5"
                        } w-auto`}
                        src="/play-button-icon.svg"
                      />
                      <p
                        className="text-center font-semibold text-black21"
                        style={{
                          fontSize: `${screenWidth < 1024 ? "8px" : "16px"}`,
                        }}
                      >
                        Putar Profil Kampus
                      </p>
                    </button>
                  )}
                  <img
                    src={data?.img_url}
                    className="w-full h-full object-cover"
                    alt="bg img univ"
                    style={{ borderRadius: "18px" }}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className="relative flex flex-col items-end justify-start"
            style={{
              backgroundImage: "url(/bg_detail_kampus_desktop.svg)",
              width: "536px",
              minHeight: "353px",
              maxHeight: "353px",
              paddingTop: "39px",
              paddingRight: "24.56px",
            }}
          >
            <p
              className="text-right font-bold text-white flex items-start justify-end w-full"
              style={{
                fontSize: "36px",
                lineHeight: "43.2px",
                letterSpacing: "-0.71px",
                minHeight: "86px",
                maxHeight: "86px",
              }}
            >
              {data?.name}
            </p>
            <div
              className="w-full flex flex-row justify-end items-center gap-5"
              style={{
                marginTop: "12.78px",
              }}
            >
              <div className="flex flex-row gap-2 items-center justify-end">
                <img className="h-4" src="/univ-data-type-icon.svg" />
                <p className="text-center font-normal tablet:font-bold text-xs tablet:text-base text-white">
                  {typeTitle(data?.type)}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center justify-center">
                <img className="h-4" src="/location-icon.svg" />
                <p className="text-center font-normal tablet:font-bold text-xs tablet:text-base text-white">
                  {data?.province}
                </p>
              </div>
              {data?.accreditation !== "Not Applied" && (
                <div className="flex flex-row gap-2 items-center justify-start">
                  <img className="h-4" src="/star-icon.svg" />
                  <p className="text-center font-normal tablet:font-bold text-xs tablet:text-base text-white">
                    {`Akreditasi ${data?.accreditation}`}
                  </p>
                </div>
              )}
              {screenWidth >= 1024 && data?.verified && (
                <img
                  src="/verified_icon2.svg"
                  className="h-4 w-4 ml-1 tablet:h-5 tablet:w-5 tablet:ml-2 mac:h-6 mac:w-6 mac:ml-2"
                />
              )}
            </div>
            <div
              style={{
                width: "524.44px",
                minHeight: "247.11px",
                maxHeight: "247.11px",
                marginTop: "12.33px",
              }}
            >
              {videoPlaying ? (
                <iframe
                  id="ytplayer"
                  type="text/html"
                  src={`https://www.youtube.com/embed/${
                    data?.video_url.split("watch?v=")[1]
                  }?autoplay=1&origin=https://www.youtube.com`}
                  frameborder="0"
                  style={{
                    marginTop: "10px",
                    borderRadius: "18px",
                    height: "100%",
                    width: "100%",
                  }}
                ></iframe>
              ) : (
                <div className="relative w-full">
                  {data.video_url && data.video_url !== "" && (
                    <button
                      className={`absolute flex flex-row items-center w-max ${
                        screenWidth < 1024 ? "gap-1" : "gap-2"
                      } px-4 py-2 bg-white object-center`}
                      style={{
                        borderRadius: "5px",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      onClick={() => {
                        setVideoPlaying(true);
                      }}
                    >
                      <img
                        src="/star-4point.svg"
                        className={`absolute ${
                          screenWidth < 1024 ? "-top-14" : "-top-10"
                        } ${
                          screenWidth < 1024 ? "-left-24" : "-left-12"
                        } h-12 w-auto`}
                      />
                      <img
                        src="/star-4point.svg"
                        className={`absolute ${
                          screenWidth < 1024 ? "-bottom-10" : "-bottom-8"
                        } ${
                          screenWidth < 1024 ? "-left-12" : "left-6"
                        } h-6 w-auto`}
                      />
                      <img
                        src="/star-4point.svg"
                        className={`absolute ${
                          screenWidth < 1024 ? "-bottom-5" : "-bottom-5"
                        } ${
                          screenWidth < 1024 ? "-right-24" : "-right-10"
                        } h-8 w-auto`}
                      />
                      <img
                        className={`${
                          screenWidth < 1024 ? "h-2" : "h-3.5"
                        } w-auto`}
                        src="/play-button-icon.svg"
                      />
                      <p
                        className="text-center font-semibold text-black21"
                        style={{
                          fontSize: `${screenWidth < 1024 ? "8px" : "16px"}`,
                        }}
                      >
                        Putar Profil Kampus
                      </p>
                    </button>
                  )}
                  <img
                    src={data?.img_url}
                    className="w-full object-cover"
                    alt="bg img univ"
                    style={{
                      borderRadius: "18px",
                      minHeight: "247.11px",
                      maxHeight: "247.11px",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            marginTop: screenWidth < 1024 ? "17.33px" : "39px",
            paddingLeft: "17px",
            paddingRight: "17px",
            maxWidth: screenWidth >= 1024 && "628px",
            minHeight: "422px",
          }}
        >
          <p
            className="text-left font-normal text-base text-black1"
            style={{ lineHeight: "24.8px", textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
          {data?.description_source !== "" && (
            <p
              className="text-left font-normal text-sm italic"
              style={{
                color: "rgba(23, 23, 23, 0.6)",
                marginTop: "24.92px",
                lineHeight: "21.7px",
              }}
            >
              {`Sumber: ${data?.description_source}`}
            </p>
          )}
        </div>
      </div>
      {data?.extras?.faculties?.length > 0 && (
        <div
          className="relative w-full bg-tosca2 px-4 tablet:pl-18 tablet:pr-0 pb-11 z-0"
          style={{
            minHeight: screenWidth >= 1024 && "528px",
            marginTop: "19.75px",
          }}
        >
          <img
            className="absolute h-full top-0 right-0 object-cover"
            src="/bg-bubble-tosca.svg"
          />
          <div className="tablet:pr-18">
            <p
              className="text-left tablet:text-center font-bold text-2xl text-white mt-5 tablet:mt-8 relative z-20"
              style={{
                fontSize: screenWidth >= 1024 && "36px",
                lineHeight: screenWidth < 1024 ? "25.92px" : "38.88px",
                letterSpacing: screenWidth < 1024 ? "-0.87px" : "-0.71px",
              }}
            >
              Fakultas dan Jurusan {screenWidth < 1024 && " yang Tersedia"}
            </p>
          </div>
          <div className="tablet:pr-18">
            <div
              className="flex flex-col w-full items-start justify-center relative z-50"
              style={{ marginTop: screenWidth < 1024 ? "26px" : "31.11px" }}
            >
              <Listbox value={selectedFaculty} onChange={setSelectedFaculty}>
                <Listbox.Button
                  className="relative z-50 h-13 tablet:h-11 w-full py-3 px-auto rounded-lg bg-white"
                  onClick={() => {
                    setIsActivityDDOpen(!isActivityDDOpen);
                  }}
                >
                  <p
                    className="text-left font-semibold text-base"
                    style={{
                      color: "rgba(167, 166, 168, 1)",
                      lineHeight: "24px",
                      marginLeft: "26px",
                    }}
                  >
                    {selectedFaculty.name}
                  </p>
                  {!isActivityDDOpen ? (
                    <ChevronDownIcon
                      className="w-6 h-6 object contain absolute top-3 right-3"
                      style={{ color: "rgba(167, 166, 168, 1)" }}
                    />
                  ) : (
                    <ChevronUpIcon
                      className="w-6 h-6 object contain absolute top-3 right-3"
                      style={{ color: "rgba(167, 166, 168, 1)" }}
                    />
                  )}
                </Listbox.Button>
                <Listbox.Options
                  className="absolute w-full left-0 bg-white rounded-lg pb-4 px-4 pt-2 z-50"
                  style={{ top: "44px" }}
                >
                  {faculties
                    ?.filter((fac) => fac.name !== selectedFaculty.name)
                    .map((fac, index) => (
                      <Listbox.Option
                        key={index}
                        value={fac}
                        className="text-center text-tosca2 text-base font-semibold py-3 px-auto rounded-lg bg-backgroundGray4 mt-4 relative z-50"
                        onClick={() => {
                          setSelectedFaculty(fac);
                          setFacultiesTotalSlides(
                            Math.ceil(fac.majors.length / 3)
                          );
                          setIsActivityDDOpen(!isActivityDDOpen);
                        }}
                      >
                        {fac.name}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Listbox>
            </div>
          </div>
          <div
            className="relative flex flex-col items-stretch w-full mt-6 tablet:mt-7 z-10"
            style={{
              marginRight: "-21px",
              paddingRight:
                screenWidth < 1024
                  ? "4px"
                  : selectedFaculty?.majors?.length < 3
                  ? "72px"
                  : "51px",
            }}
          >
            {screenWidth >= 1024 ? (
              <div className="w-full relative z-20">
                {selectedFaculty?.majors?.length < 3 ? (
                  <div className="w-full flex flex-row gap-6 justify-start">
                    {selectedFaculty?.majors?.map((item, index) => {
                      return (
                        <div key={index}>
                          <HomeCardMajorV3
                            key={index}
                            id={item.rex_major.id}
                            majorData={item.rex_major}
                            forHomeSection={true}
                            width={
                              screenWidth < 1440
                                ? "273px"
                                : screenWidth < 1910
                                ? "310px"
                                : "430px"
                            }
                            height={
                              screenWidth < 1440
                                ? "290px"
                                : screenWidth < 1910
                                ? "317px"
                                : "437px"
                            }
                            screenWidth={screenWidth}
                          />
                          {/* <HomeCardMajor
                            univMajorAccreditation={item.accreditation}
                            univMajorLabel={item.label}
                            id={item.rex_major.id}
                            majorData={item.rex_major}
                            noShadow={true}
                            width="365.33px"
                            height="395.56px"
                          /> */}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Slider
                    ref={sliderRefFacultyMajor}
                    {...slickFacultyMajorSetting}
                    className="facNMajorUnivDetail"
                  >
                    {selectedFaculty?.majors?.map((item, index) => {
                      return (
                        <HomeCardMajorV3
                          key={index}
                          id={item.rex_major.id}
                          majorData={item.rex_major}
                          forHomeSection={true}
                          width={
                            screenWidth < 1440
                              ? "273px"
                              : screenWidth < 1910
                              ? "310px"
                              : "430px"
                          }
                          height={
                            screenWidth < 1440
                              ? "290px"
                              : screenWidth < 1910
                              ? "317px"
                              : "437px"
                          }
                          screenWidth={screenWidth}
                        />
                        // <HomeCardMajor
                        //   key={index}
                        //   univMajorAccreditation={item.accreditation}
                        //   univMajorLabel={item.label}
                        //   id={item.rex_major.id}
                        //   majorData={item.rex_major}
                        //   noShadow={true}
                        //   height="395.56px"
                        // />
                      );
                    })}
                  </Slider>
                )}
              </div>
            ) : (
              <div className="flex flex-row gap-6 overflow-x-auto">
                {selectedFaculty?.majors?.map((item, index) => {
                  return (
                    <div key={index}>
                      <HomeCardMajor
                        id={item.rex_major.id}
                        majorData={item.rex_major}
                        noShadow={true}
                        width="325px"
                        height="432.75px"
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {screenWidth >= 1024 && facultiesTotalSlides > 1 && (
            <div className="flex flex-row justify-center items-center mt-7 relative z-90 gap-2 tablet:pr-18">
              {/* <button
                onClick={() => {
                  if (facultiesCurrentSlideIndex === 1) {
                    sliderRefFacultyMajor.current.slickGoTo(
                      selectedFaculty.majors.length - 3
                    );
                    setFacultiesCurrentSlideIndex(facultiesTotalSlides);
                  } else {
                    sliderRefFacultyMajor.current.slickPrev();
                    setFacultiesCurrentSlideIndex(
                      facultiesCurrentSlideIndex - 1
                    );
                  }
                }}
              >
                <ChevronLeftIcon
                  className="w-auto h-5"
                  style={{
                    color: "white",
                    opacity: "100%",
                  }}
                />
              </button>
              <button
                onClick={() => {
                  if (facultiesCurrentSlideIndex === facultiesTotalSlides) {
                    sliderRefFacultyMajor.current.slickGoTo(0);
                    setFacultiesCurrentSlideIndex(1);
                  } else {
                    sliderRefFacultyMajor.current.slickNext();
                    setFacultiesCurrentSlideIndex(
                      facultiesCurrentSlideIndex + 1
                    );
                  }
                }}
              >
                <ChevronRightIcon
                  className="w-auto h-5"
                  style={{
                    color: "white",
                  }}
                />
              </button> */}

              <button
                // disabled={currentSliderPage === 1}
                onClick={() => {
                  if (facultiesCurrentSlideIndex === 1) {
                    sliderRefFacultyMajor.current.slickGoTo(
                      selectedFaculty.majors.length - 3
                    );
                    setFacultiesCurrentSlideIndex(facultiesTotalSlides);
                  } else {
                    sliderRefFacultyMajor.current.slickPrev();
                    setFacultiesCurrentSlideIndex(
                      facultiesCurrentSlideIndex - 1
                    );
                  }
                }}
                className={`bg-white rounded-lg p-3`}
              >
                <ArrowLeftIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
              <button
                // disabled={
                //   currentSliderPage ===
                //   Math.ceil(
                //     majors[selectedMajor].univ_recommendations.length / 3
                //   )
                // }
                onClick={() => {
                  if (facultiesCurrentSlideIndex === facultiesTotalSlides) {
                    sliderRefFacultyMajor.current.slickGoTo(0);
                    setFacultiesCurrentSlideIndex(1);
                  } else {
                    sliderRefFacultyMajor.current.slickNext();
                    setFacultiesCurrentSlideIndex(
                      facultiesCurrentSlideIndex + 1
                    );
                  }
                }}
                className={`bg-white rounded-lg p-3`}
              >
                <ArrowRightIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
            </div>
          )}
          {data.verified && (
            <div className="tablet:pr-18">
              <div
                className="w-max tablet:w-full flex flex-col tablet:flex-row justify-center items-start tablet:items-center rounded-xl bg-white p-6 tablet:pl-9 tablet:pr-8 tablet:pt-10 tablet:pb-8 tablet:mt-12 relative z-10"
                style={{ marginTop: screenWidth < 1024 ? "26px" : "20px" }}
              >
                <p
                  className="text-left font-semibold text-sm text-black1 z-30 tablet:w-1/2"
                  style={{
                    fontSize: screenWidth >= 1024 && "24.89px",
                    lineHeight: screenWidth >= 1024 && "26.88px",
                    letterSpacing: screenWidth >= 1024 && "-0.71px",
                  }}
                >
                  Perkiraan Biaya Semester
                </p>
                <div className="flex flex-row items-center justify-start tablet:justify-end w-full mt-2 tablet:w-1/2 tablet:m-0">
                  <p className="text-left tablet:text-right font-bold text-base tablet:text-2xl text-tosca2">
                    {`${
                      selectedFaculty?.min_semester_fee === null ||
                      selectedFaculty?.min_semester_fee === 0
                        ? "Rp 0"
                        : `Rp ${selectedFaculty?.min_semester_fee.toLocaleString()}`
                    } - ${
                      selectedFaculty?.max_semester_fee === null ||
                      selectedFaculty?.max_semester_fee === 0
                        ? "Rp 0"
                        : `Rp ${selectedFaculty?.max_semester_fee.toLocaleString()}`
                    }`}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {data?.extras?.admissions?.length > 0 && (
        <div
          className="w-full flex flex-col"
          style={{
            backgroundColor: "rgba(241, 243, 246, 1)",
            height: screenWidth < 1024 ? "245px" : "256px",
            paddingTop: screenWidth < 1024 ? "46px" : "53.66px",
            paddingLeft: screenWidth < 1024 ? "17.33px" : "72px",
          }}
        >
          <div
            className={`${
              screenWidth >= 1024 &&
              "flex flex-row justify-between items-center"
            }`}
            style={{
              paddingRight: screenWidth >= 1024 && "72px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "25.92px", letterSpacing: "0.87px" }}
            >
              Jalur penerimaan
            </p>
            {screenWidth >= 1024 && (
              <div
                className="flex flex-row justify-end items-center"
                style={{ gap: "7.11px" }}
              >
                <button
                  onClick={() => {
                    sliderRefAdmission.current.slickPrev();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowLeftIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
                <button
                  onClick={() => {
                    sliderRefAdmission.current.slickNext();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowRightIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
              </div>
            )}
          </div>
          {data?.extras?.admissions.length > 3 && screenWidth >= 1024 ? (
            <div
              className="w-full"
              style={{
                marginTop: "21.33px",
                paddingRight: "58px",
              }}
            >
              <Slider
                ref={sliderRefAdmission}
                {...slickAdmissionSetting}
                className="admissionUnivDetail"
              >
                {data?.extras?.admissions?.map((item, index) => (
                  <ButtonJalurPenerimaan
                    key={index}
                    item={item}
                    screenWidth={screenWidth}
                    slider={true}
                  />
                ))}
              </Slider>
            </div>
          ) : (
            <div
              className="flex flex-row items-center justify-start overflow-x-scroll py-4"
              style={{ marginTop: "10px" }}
            >
              {data?.extras?.admissions?.map((item, index) => (
                <ButtonJalurPenerimaan
                  key={index}
                  item={item}
                  screenWidth={screenWidth}
                />
              ))}
            </div>
          )}
        </div>
      )}
      {data?.extras?.tuitions?.length > 0 &&
        data?.extras?.tuitions?.filter((item) =>
          data.verified
            ? !item.label.includes("URL Dynamic")
            : item.label.includes("URL Dynamic")
        ).length > 0 && (
          <div
            className="flex flex-col w-full relative pl-4 tablet:px-18"
            style={{
              height: screenWidth < 1024 && "235px",
              paddingTop: screenWidth < 1024 ? "22.33px" : "34px",
              paddingLeft: screenWidth < 1024 && "17.33px",
              paddingBottom: screenWidth < 1024 ? "33.67px" : "46px",
            }}
          >
            {data.extras.tuitions.length > 0 && (
              <div className="flex flex-row justify-start items-center gap-3 relative w-full tablet:w-1/3">
                <p
                  className="text-left font-bold text-2xl text-black"
                  style={{ lineHeight: "25.92px", letterSpacing: "0.87px" }}
                >
                  Perkiraan biaya kampus
                </p>
                <button
                  // disabled={screenWidth >= 1024}
                  onMouseEnter={() => {
                    if (screenWidth >= 1024) {
                      setShowPBMTooltip(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (screenWidth >= 1024) {
                      setShowPBMTooltip(false);
                    }
                  }}
                  onClick={() => {
                    if (screenWidth < 1024) {
                      setShowPBMTooltip(true);
                    }
                  }}
                  onBlur={() => {
                    if (screenWidth < 1024) {
                      setShowPBMTooltip(false);
                    }
                  }}
                >
                  <div className="relative">
                    <img src="/icon_tooltip.svg" />
                    {showPBMTooltip && screenWidth >= 1024 && (
                      <div
                        className="absolute -top-30 tablet:-top-18 tablet:right-5 bg-white p-5 tablet:p-8 rounded-lg flex flex-row gap-6 w-max"
                        style={{
                          boxShadow: `24px 24px 200px 0px rgba(64, 69, 79, ${
                            screenWidth < 1024 ? "0.3" : "0.12"
                          })`,
                          transform: `translate(${
                            screenWidth < 1024 ? "0" : "100%"
                          }, 0)`,
                        }}
                      >
                        <img
                          src="/illu_espa_tooltip.png"
                          className="w-10 h-10"
                        />
                        <p
                          className="text-left font-normal text-sm text-black"
                          style={{
                            lineHeight: "21.7px",
                            minWidth: screenWidth >= 1024 && "417px",
                            maxWidth: screenWidth < 1024 ? "250px" : "417px",
                          }}
                        >
                          Jumlah ini adalah perkiraan dan bisa berubah
                          sewaktu-waktu sesuai kebijakan kampus.
                        </p>
                      </div>
                    )}
                  </div>
                </button>
                {/* Tooltip */}
                {showPBMTooltip && screenWidth < 1024 && (
                  <div
                    className="absolute -top-30 tablet:-top-18 tablet:right-10 bg-white p-5 tablet:p-8 rounded-lg flex flex-row gap-6 w-max"
                    style={{
                      boxShadow: `24px 24px 200px 0px rgba(64, 69, 79, ${
                        screenWidth < 1024 ? "0.3" : "0.12"
                      })`,
                      transform: `translate(${
                        screenWidth < 1024 ? "0" : "100%"
                      }, 0)`,
                    }}
                  >
                    <img src="/illu_espa_tooltip.png" className="w-10 h-10" />
                    <p
                      className="text-left font-normal text-sm text-black"
                      style={{
                        lineHeight: "21.7px",
                        minWidth: screenWidth >= 1024 && "417px",
                        maxWidth: screenWidth < 1024 ? "250px" : "417px",
                      }}
                    >
                      Jumlah ini adalah perkiraan dan bisa berubah sewaktu-waktu
                      sesuai kebijakan kampus.
                    </p>
                  </div>
                )}
              </div>
            )}
            <div
              className="flex flex-row items-center overflow-x-auto py-4 tablet:gap-4"
              style={{ marginTop: "10px" }}
            >
              {data.verified
                ? data?.extras?.tuitions
                    ?.filter((item) => !item.label.includes("URL Dynamic"))
                    .map((item, index) => (
                      <ButtonBiayaMasuk
                        key={index}
                        item={item}
                        screenWidth={screenWidth}
                      />
                    ))
                : data?.extras?.tuitions.filter((item) =>
                    item.label.includes("URL Dynamic")
                  ) && (
                    <ButtonBiayaMasuk
                      item={
                        data?.extras?.tuitions[
                          data?.extras?.tuitions.findIndex((item) =>
                            item.label.includes("URL Dynamic")
                          )
                        ]
                      }
                      screenWidth={screenWidth}
                    />
                  )}
            </div>
          </div>
        )}
      {data?.extras?.activities?.length > 0 && (
        <div
          className="flex flex-col w-full"
          style={{
            paddingLeft: screenWidth < 1024 ? "17.33px" : "72px",
            paddingTop: "33.67px",
          }}
        >
          <div
            className={`${
              screenWidth >= 1024 &&
              "flex flex-row justify-between items-center"
            }`}
            style={{
              paddingRight: screenWidth >= 1024 && "72px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "25.92px", letterSpacing: "0.87px" }}
            >
              Kegiatan Mahasiswa
            </p>
            {screenWidth >= 1024 && (
              <div
                className="flex flex-row justify-end items-center"
                style={{ gap: "7.11px" }}
              >
                <button
                  onClick={() => {
                    sliderRefActivities.current.slickPrev();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowLeftIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
                <button
                  onClick={() => {
                    sliderRefActivities.current.slickNext();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowRightIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
              </div>
            )}
          </div>
          {data?.extras?.activities.length > 3 && screenWidth >= 1024 ? (
            <div
              className="w-full"
              style={{
                marginTop: "21.33px",
                paddingBottom: screenWidth >= 1024 && "70.89px",
                paddingRight: screenWidth >= 1024 && "60px",
              }}
            >
              <Slider
                ref={sliderRefActivities}
                {...slickActivitiesSetting}
                className="commonUnivDetailSectionGap"
              >
                {data?.extras?.activities?.map((item, index) => (
                  <div key={index} className="pb-2">
                    <div
                      key={index}
                      className="flex flex-col w-full h-full bg-white"
                      style={{
                        minWidth: screenWidth < 1024 ? "273px" : "365.33px",
                        maxWidth: screenWidth < 1024 ? "273px" : "365.33px",
                        minHeight: screenWidth < 1024 ? "182px" : "267.56px",
                        maxHeight: screenWidth < 1024 ? "182px" : "267.56px",
                        borderRadius: "10px",
                        boxShadow:
                          screenWidth < 1024
                            ? "26px 26px 216.67px 0px rgba(23, 23, 23, 0.08)"
                            : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <img
                        className="w-full object-cover"
                        src={item.img_url}
                        style={{
                          height: screenWidth < 1024 ? "106px" : "186.67px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      />
                      <div className="flex flex-col h-full w-full px-5 py-6">
                        <p
                          className="text-left font-bold text-xl text-black line-clamp-2 overflow-ellipsis"
                          style={{ lineHeight: "24px" }}
                        >
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div
              className="flex flex-row overflow-x-auto py-4"
              style={{ marginTop: "10px" }}
            >
              {data?.extras?.activities?.map((item, index) => (
                <CardContentDetailKampus
                  key={index}
                  imgUrl={item.img_url}
                  title={item.name}
                  img_src={item.img_src}
                  screenWidth={screenWidth}
                  customGap="16px"
                />
              ))}
            </div>
          )}
        </div>
      )}
      {data?.extras?.alumnus?.length > 0 && (
        <div
          className="w-full flex flex-col"
          style={{
            backgroundColor: "rgba(241, 243, 246, 1)",
            height: "376px",
            paddingTop: "35.67px",
            paddingLeft: screenWidth < 1024 ? "17.33px" : "72px",
            paddingRight: screenWidth >= 1024 && "74px",
          }}
        >
          <p
            className="text-left font-bold text-base text-tosca2"
            style={{ letterSpacing: "2.17px", lineHeight: "24px" }}
          >
            LINGKAR ALUMNUS
          </p>
          <p
            className="text-left font-bold text-2xl text-black"
            style={{
              letterSpacing: "-0.87x",
              lineHeight: "28.8px",
              marginTop: "17.33px",
            }}
          >
            Alumni Terkenal
          </p>
          <div
            className="flex flex-row justify-start tablet:gap-4 items-center overflow-x-auto py-4"
            style={{
              marginTop: "10.44px",
            }}
          >
            {data?.extras?.alumnus?.map((item, index) => (
              <div
                key={index}
                className="flex flex-row bg-white justify-center items-center"
                style={{
                  // minWidth: screenWidth < 1024 && "334px",
                  width: screenWidth >= 1024 && "365px",
                  minWidth:
                    data?.extras?.alumnus?.length < 4
                      ? screenWidth < 1024 && "334px"
                      : screenWidth < 1024
                      ? "334px"
                      : "365px",
                  maxWidth: screenWidth < 1024 ? "334px" : "365px",
                  minHeight: "177px",
                  maxHeight: "177px",
                  borderRadius: "14.98px",
                  gap: "20px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  paddingTop: "28px",
                  paddingBottom: "28px",
                  marginRight: screenWidth < 1024 && "20px",
                }}
              >
                <img
                  src={item.img_url}
                  className="object-cover rounded-full"
                  style={{
                    width: "119.48px",
                    height: "119.48px",
                  }}
                />
                <div
                  className="flex flex-col justify-center items-start gap-1"
                  style={{ maxWidth: "143px" }}
                >
                  <p
                    className="text-left font-bold text-xl text-black line-clamp-2 overflow-ellipsis"
                    style={{ lineHeight: "31px" }}
                  >
                    {item.name}
                  </p>
                  <div className="flex flex-row justify-start items-center gap-1">
                    <img
                      src="/icon_toga_tosca.svg"
                      style={{ maxWidth: "24px" }}
                    />
                    <p
                      className="text-left font-normal text-sm text-black188 line-clamp-2 overflow-hidden"
                      style={{ lineHeight: "21px" }}
                    >
                      {item.major_name}
                      {", "}
                      {item.graduated_year}
                    </p>
                  </div>
                  <div className="flex flex-row justify-start items-start gap-1">
                    <img
                      src="/icon_star_yellowstroke.svg"
                      style={{ maxWidth: "24px" }}
                    />
                    <p
                      className="text-left font-normal text-sm text-black188 line-clamp-2 overflow-hidden"
                      style={{ lineHeight: "21px" }}
                    >
                      {item.occupation}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {data?.extras?.facilities?.length > 0 && (
        <div
          className="flex flex-col w-full"
          style={{
            paddingLeft: screenWidth < 1024 ? "17.33px" : "72px",
            paddingTop: "36.67px",
          }}
        >
          <div
            className={`${
              screenWidth >= 1024 &&
              "flex flex-row justify-between items-center"
            }`}
            style={{
              paddingRight: screenWidth >= 1024 && "72px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "25.92px", letterSpacing: "0.87px" }}
            >
              Fasilitas Kampus
            </p>
            {screenWidth >= 1024 && (
              <div
                className="flex flex-row justify-end items-center"
                style={{ gap: "7.11px" }}
              >
                <button
                  onClick={() => {
                    sliderRefFacilities.current.slickPrev();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowLeftIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
                <button
                  onClick={() => {
                    sliderRefFacilities.current.slickNext();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowRightIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
              </div>
            )}
          </div>
          {data?.extras?.activities.length > 3 && screenWidth >= 1024 ? (
            <div
              className="w-full"
              style={{
                marginTop: "21.33px",
                paddingBottom: screenWidth >= 1024 && "70.89px",
                paddingRight: screenWidth >= 1024 && "60px",
              }}
            >
              <Slider
                ref={sliderRefFacilities}
                {...slickFacilitiesSetting}
                className="commonUnivDetailSectionGap"
              >
                {data?.extras?.facilities?.map((item, index) => (
                  <div key={index} className="pb-2">
                    <CardContentDetailKampus
                      imgUrl={item.img_url}
                      title={item.name}
                      img_src={item.img_src}
                      screenWidth={screenWidth}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="flex flex-row overflow-x-auto py-4">
              {data?.extras?.facilities?.map((item, index) => (
                <CardContentDetailKampus
                  key={index}
                  imgUrl={item.img_url}
                  title={item.name}
                  img_src={item.img_src}
                  screenWidth={screenWidth}
                  customGap="26px"
                />
              ))}
            </div>
          )}
        </div>
      )}
      {data?.extras?.social_life?.length > 0 && (
        <div
          className="flex flex-col w-full"
          style={{
            paddingLeft: screenWidth < 1024 ? "17.33px" : "72px",
            paddingTop: "36.67px",
          }}
        >
          <div
            className={`${
              screenWidth >= 1024 &&
              "flex flex-row justify-between items-center"
            }`}
            style={{
              paddingRight: screenWidth >= 1024 && "72px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "25.92px", letterSpacing: "0.87px" }}
            >
              Kehidupan Sosial
            </p>
            {screenWidth >= 1024 && (
              <div
                className="flex flex-row justify-end items-center"
                style={{ gap: "7.11px" }}
              >
                <button
                  onClick={() => {
                    sliderRefSocialLife.current.slickPrev();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowLeftIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
                <button
                  onClick={() => {
                    sliderRefSocialLife.current.slickNext();
                  }}
                  className="bg-tosca2"
                  style={{ padding: "10.67px", borderRadius: "7.11px" }}
                >
                  <ArrowRightIcon
                    className="h-auto"
                    style={{ width: "13.85px", color: "white" }}
                  />
                </button>
              </div>
            )}
          </div>
          {data?.extras?.social_life?.length > 3 && screenWidth >= 1024 ? (
            <div
              className="w-full"
              style={{
                marginTop: "21.33px",
                paddingBottom: screenWidth >= 1024 && "70.89px",
                paddingRight: screenWidth >= 1024 && "60px",
              }}
            >
              <Slider
                ref={sliderRefSocialLife}
                {...slickSocialLifeSetting}
                className="commonUnivDetailSectionGap"
              >
                {data?.extras?.social_life?.map((item, index) => (
                  <div key={index} className="pb-2">
                    <CardContentDetailKampus
                      imgUrl={item.img_url}
                      title={item.name}
                      img_src={item.img_src}
                      screenWidth={screenWidth}
                    />
                    {/* <div
                      key={index}
                      className="flex flex-col w-full h-full bg-white"
                      style={{
                        // minWidth: screenWidth < 1024 ? "273px" : "365.33px",
                        // maxWidth: screenWidth < 1024 ? "273px" : "365.33px",
                        minHeight: screenWidth < 1024 ? "182px" : "267.56px",
                        maxHeight: screenWidth < 1024 ? "182px" : "267.56px",
                        borderRadius: "10px",
                        boxShadow:
                          screenWidth < 1024
                            ? "26px 26px 216.67px 0px rgba(23, 23, 23, 0.08)"
                            : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <img
                        className="w-full object-cover"
                        src={item.img_url}
                        style={{
                          height: screenWidth < 1024 ? "106px" : "186.67px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      />
                      <div className="flex flex-col h-full w-full px-5 py-6">
                        <p
                          className="text-left font-bold text-xl text-black line-clamp-2 overflow-ellipsis"
                          style={{ lineHeight: "24px" }}
                        >
                          {item.name}
                        </p>
                      </div>
                    </div> */}
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="flex flex-row overflow-x-auto py-4">
              {data?.extras?.social_life?.map((item, index) => (
                <CardContentDetailKampus
                  key={index}
                  imgUrl={item.img_url}
                  title={item.name}
                  img_src={item.img_src}
                  screenWidth={screenWidth}
                  customGap="26px"
                />
              ))}
            </div>
          )}
        </div>
      )}
      <div
        className="flex flex-col"
        style={{
          paddingLeft: screenWidth < 1024 ? "17.33px" : "72px",
          paddingRight: screenWidth < 1024 ? "17.33px" : "72px",
          marginTop: screenWidth < 1024 && "124.67px",
          paddingBottom: screenWidth < 1024 ? "41.67px" : "62px",
        }}
      >
        <div
          className={`${
            screenWidth >= 1024 && "flex flex-row justify-between items-start"
          }`}
        >
          <p
            className="text-left font-bold text-3xl text-black"
            style={{ lineHeight: "38.4px", letterSpacing: "-1.3px" }}
          >
            Temukan lokasi {screenWidth < 1024 && <br />}
            kampus di sini
          </p>
          {screenWidth >= 1024 && (
            <button
              onClick={() => {
                navigate("/explore-jurusan");
              }}
              className="flex flex-row gap-3 items-center justify-center w-full tablet:w-max px-6 py-3 bg-primaryColor"
              style={{
                borderRadius: "8px",
              }}
            >
              <p
                className="text-center font-semibold text-base text-white"
                style={{
                  lineHeight: "24px",
                }}
              >
                Explore Jurusan
              </p>
              <ArrowRightIcon className="w-5 h-5" color={"#FFFFFF"} />
            </button>
          )}
        </div>
        <div
          className="flex flex-row mt-8 tablet:mt-2 items-center justify-start"
          style={{
            gap: screenWidth < 1024 ? "26px" : "21px",
            minHeight: screenWidth < 1024 ? "94px" : "56px",
            maxHeight: screenWidth < 1024 ? "94px" : "56px",
          }}
        >
          <div
            className="flex h-full items-center justify-center"
            style={{ width: screenWidth < 1024 ? "66px" : "40px" }}
          >
            <img src="/icon_map_pinpoint.svg" />
          </div>
          <div className="flex flex-1">
            <p
              className="text-left text-normal text-base text-black188"
              style={{ lineHeight: "24.8px" }}
            >
              {data.contacts.address}
            </p>
          </div>
        </div>
        <div
          className="w-full flex flex-col tablet:flex-row tablet:mt-5"
          style={{ gap: screenWidth >= 1024 && "73px" }}
        >
          {data?.contacts?.gmaps_url.includes("https://") && (
            <iframe
              src={data?.contacts?.gmaps_url}
              // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126907.96495974768!2d106.75541990270881!3d-6.280303406876473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ec1a804e8b85%3A0xd7bf80e1977cea07!2sUniversitas%20Indonesia!5e0!3m2!1sen!2sid!4v1716211748846!5m2!1sen!2sid"
              width={`${screenWidth < 1024 ? "100%" : "585px"}`}
              height="254px"
              style={{
                border: 0,
                borderRadius: "12px",
                marginTop: screenWidth < 1024 && "26.33px",
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
          {screenWidth < 1024 && (
            <button
              onClick={() => {
                navigate("/explore-jurusan");
              }}
              className="flex flex-row gap-3 items-center justify-center w-full tablet:w-max px-6 py-3 bg-primaryColor"
              style={{
                borderRadius: "8px",
                marginTop: "26px",
              }}
            >
              <p
                className="text-center font-semibold text-base text-white"
                style={{
                  lineHeight: "24px",
                }}
              >
                Explore Jurusan
              </p>
              <ArrowRightIcon className="w-5 h-5" color={"#FFFFFF"} />
            </button>
          )}
          <div
            className="w-full flex flex-col justify-start items-start"
            style={{
              paddingTop: "17px",
              paddingRight: "20.89px",
              paddingBottom: "35px",
              paddingLeft: "30.22px",
              gap: "14px",
              marginTop: screenWidth < 1024 && "32.67px",
              backgroundColor: "rgba(241, 243, 246, 1)",
              borderRadius: "10.67px",
              maxWidth: screenWidth >= 1024 && "480px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black188"
              style={{ lineHeight: "25.92px", letterSpacing: "-0.71px" }}
            >
              Kontak Informasi
            </p>
            <div
              className={
                screenWidth < 1024
                  ? "flex flex-col justify-between items-start gap-2"
                  : "grid grid-cols-2 gap-6"
              }
              // className="flex flex-col tablet:flex-row justify-between items-start gap-2 tablet:gap-6"
              // style={{
              //   minWidth: screenWidth < 1024 ? "202.44px" : "480px",
              //   maxWidth: screenWidth < 1024 ? "202.44px" : "480px",
              // }}
            >
              <div className="flex flex-col justify-start items-start gap-2">
                {data.pic_email !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_mail.svg"}
                    content={data.pic_email}
                    special={true}
                  />
                )}
                {data.pic_phone !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_phone.svg"}
                    content={data.pic_phone}
                    additionalContent={" (PIC)"}
                  />
                )}
                {data.contacts.email !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_mail.svg"}
                    content={data.contacts.email}
                    special={true}
                  />
                )}
                {data.contacts.phone !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_phone.svg"}
                    content={data.contacts.phone}
                    additionalContent={" (Universitas)"}
                  />
                )}
                {data.contacts.mobile !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_mobile.svg"}
                    content={data.contacts.mobile}
                    additionalContent={" (Universitas)"}
                  />
                )}
                {data.contacts.website !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_web.svg"}
                    content={data.contacts.website}
                    link={data.contacts.website}
                    special={true}
                  />
                )}
              </div>
              <div
                className="flex flex-col justify-start items-start gap-2 mt-1 tablet:mt-0"
                style={{ maxWidth: "202.44px" }}
              >
                {data.contacts.whatsapp !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_whatsapp.svg"}
                    content={data.contacts.whatsapp}
                    link={`https://wa.me/${data.contacts.whatsapp
                      .replace("+", "")
                      .replace(" ", "")}`}
                  />
                )}
                {data.contacts.social_media.fb !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_facebook.svg"}
                    content={data.contacts.social_media.fb}
                    link={`https://www.facebook.com/${data.contacts.social_media.fb}`}
                  />
                )}
                {data.contacts.social_media.ig !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_instagram.svg"}
                    content={`@${data.contacts.social_media.ig}`}
                    link={`https://www.instagram.com/${data.contacts.social_media.ig}/`}
                  />
                )}
                {data.contacts.social_media.lk !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_linkedin.svg"}
                    content={data.contacts.social_media.lk}
                    link={`https://www.linkedin.com/in/${data.contacts.social_media.lk}/`}
                  />
                )}
                {data.contacts.social_media.tt !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_tiktok.svg"}
                    content={`@${data.contacts.social_media.tt}`}
                    link={`https://www.tiktok.com/@${data.contacts.social_media.tt}/`}
                  />
                )}
                {data.contacts.social_media.tw !== "" && (
                  <CardInfoDetailKampus
                    icon={"/icon_uni_info_x.svg"}
                    content={`@${data.contacts.social_media.tw}`}
                    link={`https://x.com/${data.contacts.social_media.tw}/`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
