import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/solid";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";

export default function RMSubEmailNasabah({ submit, back }) {
  const [emailNasabah, setEmailNasabah] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
    if (Cookies.get("emailNasabah")) {
      setEmailNasabah(Cookies.get("emailNasabah"));
      setIsValidEmail(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submit(e.target[1].value);
  };

  const handleEnterKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isValidEmail) {
      submit(e.target.value);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-end w-full">
      <button onClick={back} className="w-full justify-start">
        <ArrowLeftIcon className="w-6 h-6 text-white font-semibold" />
      </button>
      <label
        className="w-full text-left font-bold text-base text-white mt-20 tablet:mt-50"
        htmlFor="email_nasabah"
      >
        Alamat Email Nasabah
      </label>
      <input
        ref={inputRef}
        className="form-input flex w-full mt-3 rounded-md text-center font-normal text-xs text-black py-2"
        style={{ height: "30px" }}
        id="email_nasabah"
        type="email_nasabah"
        name="email_nasabah"
        value={emailNasabah}
        onChange={(e) => {
          const emailRegex = /^[^\s@]+@(?:[^\s@]+\.)?(?:id|com)$/i;
          setEmailNasabah(e.target.value);
          setIsValidEmail(emailRegex.test(e.target.value));
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            // handleEnterKey(e);
          }
        }}
        placeholder="Input alamat email nasabah."
        required
      />
      <div className="flex flex-row justify-between items-start w-full mt-2">
        <p className="text-left font-normal text-sm text-white italic leading-4">
          Contoh:
          <br /> felia.wijaya@gmail.com
        </p>
        <button
          type="submit"
          disabled={!isValidEmail}
          className="w-max py-2 px-8 rounded-md text-center font-bold text-sm text-black1 mt-1"
          style={{
            backgroundColor: !isValidEmail ? "gray" : "rgba(255, 202, 45, 1)",
          }}
        >
          Next
        </button>
      </div>
    </form>
  );
}
