import { useEffect } from "react";
import { api, getAnomToken, notifError } from "../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

export default function RMVerifCallback() {
  const navigate = useNavigate();
  let { base64 } = useParams();
  let path = "/";

  useEffect(() => {
    if (!Cookies.get("anom.token")) {
      getAnomToken();
      setTimeout(() => {
        api
          .post(`/rm/leads/update/payment-status/${base64}`)
          .then(() => {
            window.Swal.fire({
              title: "Success!",
              text: `Verifikasi Email Berhasil!`,
              icon: "success",
              confirmButtonText: "Tutup",
              allowOutsideClick: false,
            }).then(() => {
              navigate(path);
            });
          })
          .catch(notifError);
      }, 300);
    } else {
      api
        .post(`/rm/leads/update/payment-status/${base64}`)
        .then(() => {
          window.Swal.fire({
            title: "Success!",
            text: `Verifikasi Email Berhasil!`,
            icon: "success",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(path);
          });
        })
        .catch(notifError);
    }
  }, []);

  return <div className="w-screen h-screen bg-white"></div>;
}
