import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import FavoriteButton from "./FavoriteButton";
import { signInUpState } from "../atoms/signInUpState";
import { favMajorState } from "../atoms/favMajor";

export default function HomeCardMajorV3({
  id,
  majorData,
  univMajorLabel,
  univMajorAccreditation,
  width,
  height,
  screenWidth,
}) {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [favorite, setFavorite] = useState(majorData.favorite);
  const setSign = useSetRecoilState(signInUpState);
  const setFavState = useSetRecoilState(favMajorState);
  const navigate = useNavigate();

  function getCareerName(career) {
    var careerName = "";
    if (typeof career === "string" || career instanceof String) {
      return career;
    } else {
      careerName = career.name;
    }
    return careerName;
  }

  const addMajorToFav = (fav, id) => {
    if (!auth.isAuth) {
      if (window.location.pathname === "/") {
        navigate({
          pathname: "/signin",
          search: `?redirect=/explore-kampus/detail/${id}`,
        });
      }
      setSign({ trySignUp: true, trySignIn: false });
      setFavState({ afterFavMajor: true, majorId: id });
    }

    if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    }

    const url = fav ? "/favorites/major/add" : "/favorites/major/delete";

    _api
      .post(url, {
        majors_of_universities_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };
  return (
    <div
      onClick={() => {
        navigate(`/explore-jurusan/${majorData.slug}`);
      }}
      className="relative flex flex-col items-stretch bg-white rounded-lg shadow-md hover:shadow-2xl"
      style={{
        minWidth: width ? width : "100%",
        maxWidth: width ? width : "100%",
        minHeight: height ? height : "100%",
        maxHeight: height ? height : "100%",
      }}
    >
      <div className="relative w-full h-full">
        <div
          style={{
            minHeight:
              screenWidth < 1440
                ? "103.44px"
                : screenWidth < 1910
                ? "110px"
                : "160px",
            maxHeight:
              screenWidth < 1440
                ? "103.44px"
                : screenWidth < 1910
                ? "110px"
                : "160px",
          }}
        >
        <img
          src={majorData.img_url}
          className="object-cover rounded-t-lg w-full"
          alt="major bg img"
          style={{
            maxHeight:
              screenWidth < 1440
                ? "103.44px"
                : screenWidth < 1910
                ? "110px"
                : "160px",
          }}
        /></div>
        <div
          className="absolute flex justify-center items-center gap-2"
          style={{ bottom: "9.28px", left: "13.76px" }}
        >
          {majorData.tmb_result_matched && (
            <p
              className="px-3 py-1 rounded-lg text-center font-medium text-white"
              style={{
                backgroundColor: "#FE5244",
                fontSize: "10.44px",
                lineHeight: "15.67px",
              }}
            >
              Kamu banget ✨
            </p>
          )}
          {univMajorAccreditation && (
            <p
              className="px-3 py-1 rounded-lg text-center font-medium text-base text-black1"
              style={{ backgroundColor: "#F9C60B" }}
            >
              {`Akreditasi ${univMajorAccreditation}`}
            </p>
          )}
        </div>
      </div>
      <FavoriteButton
        className="top-4 right-4"
        isFavorite={favorite}
        onChangeFavorite={() => {
          //callback fav, and id;
          addMajorToFav(!favorite, id);
        }}
      />
      <div
        className="bg-white rounded-b-lg"
        style={{ padding: "13.26px 21.22px 21.22px 21.22px" }}
      >
        <p
          className="text-left font-bold text-base fhd:text-2xl text-black1 line-clamp-1 overflow-ellipsis"
          style={{ lineHeight: screenWidth >= 1440 ? "auto" : "19.2px" }}
        >
          {!univMajorLabel ? majorData.name : univMajorLabel}
        </p>
        <p
          className="text-left font-normal text-xs fhd:text-lg text-black1 line-clamp-3 overflow-ellipsis"
          style={{
            lineHeight: screenWidth >= 1440 ? "auto" : "18.6px",
            marginTop: "13px",
          }}
          dangerouslySetInnerHTML={{
            __html: majorData.description.replace(/<[^>]+>/g, ""),
          }}
        />
        <div
          className="w-full flex flex-col justify-start items-start"
          style={{ marginTop: "13px" }}
        >
          {majorData.career_prospects !== null &&
            majorData.career_prospects?.length > 0 && (
              <div className="flex flex-col">
                <p
                  className="text-left font-semibold text-xs fhd:text-lg text-black1"
                  style={{
                    fontSize: screenWidth >= 1440 ? "auto" : "10.44px",
                    lineHeight: screenWidth >= 1440 ? "auto" : "16.19px",
                  }}
                >
                  Prospek Karir
                </p>
                <div
                  className="flex flex-row"
                  style={{ gap: "5.3px", marginTop: "10.61px" }}
                >
                  {majorData.career_prospects.slice(0, 2).map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-center bg-backgroundGray4"
                      style={{
                        padding: "1.61px 7.96px",
                        borderRadius: "5.3px",
                        maxWidth:
                          screenWidth < 1440
                            ? "90.91px"
                            : screenWidth < 1910
                            ? "100px"
                            : "140px",
                      }}
                    >
                      <p
                        className="text-center font-medium text-xs fhd:text-lg text-black1"
                        style={{
                          lineHeight: screenWidth >= 1440 ? "auto" : "15.67px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                      >
                        {getCareerName(item)}
                      </p>
                    </div>
                  ))}
                  {majorData.career_prospects.length > 2 && (
                    <div
                      className="flex justify-center items-center text-center font-medium text-xs fhd:text-lg text-black1 bg-backgroundGray4 line-clamp-1 overflow-ellipsis whitespace-nowrap"
                      style={{
                        // fontSize: "10.44px",
                        // lineHeight: "15.67px",
                        padding: "2.61px 7.96px",
                        borderRadius: "5.3px",
                      }}
                    >
                      ...
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
