import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import FavoriteButton from "./FavoriteButton";

export default function CardMajor({ isFavorite, ...rest }) {
  const [favorite, setFavorite] = useState(isFavorite ?? false);
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  let navigate = useNavigate();
  const onCardMajorClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/explore-jurusan/${rest.slug}`, {
      state: {
        isFavorite: favorite,
      },
    });
  };

  const addMajorToFav = (fav, id) => {
    if (fav && !auth.isAuth) {
      navigate({
        pathname: "/signin",
        search: `?redirect=/explore-jurusan/detail/${id}`,
      });
    }

    const url = fav ? "/favorites/major/add" : "/favorites/major/delete";

    _api
      .post(url, {
        majors_of_universities_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };

  var description =  rest.description.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, " ");

  return (
    <div
      className="relative divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow card-max-width"
      style={{
        minWidth: "300px",
      }}
    >
      <div className="relative group">
        <img src={rest.img_url} alt="" />
      </div>
      <div className="px-4 pb-2">
        <div className="relative group bg-white">
          <div onClick={onCardMajorClicked} className="mt-4 cursor-pointer">
            <h3 className="my-1 font-normal text-primary">{rest.name}</h3>
            <p
              className="mt-2 text-sm text-gray-500 h-16 line-clamp-3"
            >
              {description}
            </p>
            {/* <div
              className="mt-2 text-sm text-gray-500 h-16 line-clamp-3"
              dangerouslySetInnerHTML={{ __html: rest.description }}
            ></div> */}
          </div>
        </div>
      </div>
      <FavoriteButton
        className="absolute top-3 right-2"
        isFavorite={favorite}
        onChangeFavorite={() => {
          setFavorite(!favorite);
          //callback fav, and id;
          addMajorToFav(!favorite, rest.id);
        }}
      />
    </div>
  );
}
