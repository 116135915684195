export default function ClassFacilities({ item, index, mobile }) {
  return (
    <div
      className="flex flex-col gap-2 tablet:gap-4 w-max bg-white p-6 rounded-xl"
      style={{
        minWidth: `${mobile ? "230px" : "284px"}`,
        maxWidth: `${mobile ? "230px" : "284px"}`,
        height: `${mobile ? "122px" : "159px"}`,
      }}
    >
      <p
        className="text-left font-medium text-base text-black1 line-clamp-1 overflow-ellipsis"
        style={{ lineHeight: "20px" }}
      >
        {item.name}
      </p>
      <p
        className="text-left font-normal text-xs text-black188"
        style={{ lineHeight: "15px" }}
      >
        {item.description}
      </p>
    </div>
  );
}
