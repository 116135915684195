import { useRecoilValue } from "recoil";
import { useState, useEffect } from "react";

import { authState } from "../../atoms/authState";
import { apiAnom, api } from "../../utils/api";
import ReactSelect from "react-select";
import * as _ from "lodash";
import PageLoading from "../PageLoading";
import Layout from "../../components/Layout";
import Button from "../../components/base/Button";
import Pagination from "../../components/pagination/Pagination";
import DropdownItem from "../../components/DropdownItem";
import CardUniversity from "../../components/CardUniversity";
import { useNavigate } from "react-router-dom";
import LayoutV2 from "../../components/Layout_V2";

let pageSize = 12;

export default function EksplorasiKampus() {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;

  const [currentPage, setCurrentPage] = useState(1);

  const [filterType, setFilterType] = useState([]);
  const [filterAcreditation, setFilterAcreditation] = useState([]);
  const [filterProvince, setFilterProvince] = useState([]);

  const [listData, setListData] = useState();
  const [keyword, setKeyword] = useState();
  const [listProvince, setListProvince] = useState([]);

  const universityType = [
    {
      value: "N",
      label: "Negeri",
    },
    {
      value: "S",
      label: "Swasta",
    },
    {
      value: "L",
      label: "Luar Negeri",
    },
  ];

  const univAccreditaion = [
    {
      value: "A",
      label: "A",
    },
    {
      value: "B",
      label: "B",
    },
    {
      value: "C",
      label: "C",
    },
    {
      value: "O",
      label: "Lainnya",
    },
  ];

  useEffect(() => {
    const getListProvinces = () => {
      _api
        .get("/provinces")
        .then((res) => {
          setListProvince(res.data.data);
        })
        .catch((_) => {
          setListProvince([]);
        });
    };
    getListProvinces();
  }, [_api, auth.isAuth]);

  useEffect(() => {
    const getUniveristy = () => {
      _api
        .post("/university/search", {
          name: keyword,
          type: _.map(filterType, "value"),
          province_ids: _.map(filterProvince, "id"),
          accreditation: _.map(filterAcreditation, "value"),
          page: currentPage,
          limit: pageSize,
          sort_direction: "DESC",
        })
        .then((res) => {
          setListData(res.data.data);
        })
        .catch((_) => {
          setListData([]);
        });
    };
    getUniveristy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.isAuth,
    currentPage,
    filterType,
    filterAcreditation,
    filterProvince,
  ]);

  if (!listData) return <PageLoading />;

  const styles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "rgba(217, 217, 217, 0.5)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
    }),
    input: (provided) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: "1 !important",
      },
    }),
  };

  const onDeleteType = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterType(filterType.filter((item) => item.value !== type["value"]));
    setCurrentPage(1);
  };

  const onDeleteAcreditation = (e, accreditation) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterAcreditation(
      filterAcreditation.filter((item) => item.value !== accreditation["value"])
    );
    setCurrentPage(1);
  };

  const onDeleteProvince = (e, prov) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterProvince(filterProvince.filter((item) => item.id !== prov["id"]));
    setCurrentPage(1);
  };

  const onSearchByBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _api
      .post("/university/search", {
        name: keyword,
        type: _.map(filterType, "value"),
        province_ids: _.map(filterProvince, "id"),
        accreditation: _.map(filterAcreditation, "value"),
        page: 1,
        limit: pageSize,
        sort_direction: "DESC",
      })
      .then((res) => {
        setListData(res.data.data);
        setCurrentPage(1);
      })
      .catch((_) => {
        setListData([]);
      });
  };

  const navigateToCompare = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/bandingkan-kampus");
  };
  return (
    <LayoutV2>
      <div>
        <h1 className="mb-1 text-2xl tablet:text-5xl font-bold">
          Explore Kampus
        </h1>
        <p className="text-lg font-light text-gray-500">
          Temukan kampus idaman kamu sekarang!
        </p>
        <div className="my-2 flex justify-between items-center">
          <div className="w-full px-4 py-3 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg">
            <form className="flex flex-col sm:flex-row remove-input-txt-border">
              <input
                type="text"
                className="flex-1 sm:flex-2 form-input shadow-sm sm:text-sm sm:rounded-r-none"
                style={{
                  maxHeight: "38px",
                  backgroundColor: "rgba(217, 217, 217, 0.5)",
                }}
                placeholder="Cari kampus berdasarkan nama..."
                onChange={(e) => setKeyword(e.target.value)}
              />
              <ReactSelect
                styles={styles}
                options={universityType}
                isMulti
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuColor
                getOptionLabel={(data) => data["label"]}
                getOptionValue={(data) => data["value"]}
                components={{
                  Option: DropdownItem,
                }}
                className="flex-1 shadow-sm sm:text-sm focus:border-none"
                placeholder="Jenis"
                onChange={(v) => {
                  setFilterType(v);
                  setCurrentPage(1);
                }}
                value={filterType}
              />
              <ReactSelect
                styles={styles}
                options={listProvince}
                isMulti
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuColor
                getOptionLabel={(data) => data["name"]}
                getOptionValue={(data) => data["id"]}
                components={{
                  Option: DropdownItem,
                }}
                className="flex-1 shadow-sm sm:text-sm focus:border-none"
                placeholder="Lokasi"
                onChange={(v) => {
                  setFilterProvince(v);
                  setCurrentPage(1);
                }}
                value={filterProvince}
              />
              <ReactSelect
                styles={styles}
                options={univAccreditaion}
                isMulti
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                getOptionLabel={(data) => data["label"]}
                getOptionValue={(data) => data["value"]}
                components={{
                  Option: DropdownItem,
                }}
                className="flex-1 shadow-sm sm:text-sm focus:border-none"
                placeholder="Akreditasi"
                onChange={(v) => {
                  setFilterAcreditation(v);
                  setCurrentPage(1);
                }}
                value={filterAcreditation}
              />
              <Button
                onClick={onSearchByBtn}
                aria-controls="modal"
                variant="primary"
                style={{
                  maxHeight: "38px",
                }}
                className="rounded-md sm:rounded-r-md sm:rounded-l-none mt-2 sm:mt-0"
              >
                <span className="px-2.5">Cari</span>
              </Button>
            </form>
          </div>
        </div>
        <div className="mb-4">
          {/* Filter Univ Type */}
          {filterType.length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Jenis:</p>
              {filterType.map((type, index) => (
                <span
                  key={type["value"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{type["label"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteType(e, type)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
          {/* Filter Province */}
          {filterProvince.length > 0 && (
            <div className="px-2 flex flex-row flex-wrap  items-center">
              <p className="text-xs text-black mr-3">Lokasi:</p>
              {filterProvince.map((prov, index) => (
                <span
                  key={prov["id"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{prov["name"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteProvince(e, prov)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
          {/* Filter Akreditas */}
          {filterAcreditation.length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Akreditasi:</p>
              {filterAcreditation.map((accreditation, index) => (
                <span
                  key={accreditation["value"]}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{accreditation["label"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteAcreditation(e, accreditation)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
        {listData.universities.length < 1 && listData.universities && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">
                Pencarian data tidak di temukan
              </h3>
            </div>
          </div>
        )}
        {/* <div className="flex items-center bg-compare-univ overflow-hidden border border-gray-100 rounded-md shadow-custom1 px-3">
          <div className="flex flex-row justify-between items-center">
            <p className=" flex-0 whitespace-pre-wrap text-lg">{"Bingung pilih kampus?"}</p>
            <button
              onClick={navigateToCompare}
              className="rounded-full text-xs leading-none bg-alternate py-1 px-3"
            >
              Bandingkan Kampus
            </button>
          </div>
        </div> */}
        <div className="pb-4 grid tablet:grid-cols-3 gap-4 justify-center mt-4">
          {listData.universities.map((d, i) => {
            return (
              <div key={i} className="mb-4">
                <CardUniversity
                  isFavorite={listData.favorites?.includes(d.id) ?? false}
                  {...d}
                />
              </div>
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={listData.metadata.total_item}
          pageSize={listData.metadata.limit}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </LayoutV2>
  );
}
