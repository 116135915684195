import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import Layout from "../components/Layout";
import UnivShortcut from "../components/UnivShortcut";
import { api, apiAnom } from "../utils/api";
import { setLocalStorage, getLocalStorage } from "../utils/localStorage";
import AsyncSelect from "react-select/async";
import debounce from "debounce-async";
import { useLocation, useNavigate } from "react-router-dom";
import LayoutV2 from "../components/Layout_V2";

export default function UnivComparation() {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;

  // eslint-disable-next-line no-unused-vars
  const [univOptions, setUnivOptions] = useState([]);
  const [compareItem, setCompareItem] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    setCompareItem(getLocalStorage("selectedUniversity") ?? [])
    const getStateData = () => {
      // const list = JSON.parse(localStorage.getItem('selectedUniversity')) ?? [];
      // if(state?.data !== undefined  && list !== [] && list.length < 4) {
      //   if(!list.some(e => e.id === state?.data?.id)) {
      //     list.push(state?.data);
      //   }
      // }
      if (state?.data !== undefined) {
        storeCompareItem(state?.data);
      }
    };
    getStateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalStorage("selectedUniversity", compareItem, process.env.REACT_APP_TTL_LOCALSTORAGE_IN_MINUTE);
  }, [compareItem]);

  const loadOptions = useMemo(() => {
    return debounce(async (inputValue) => {
      const { data } = await _api.post("/university/search", {
        name: inputValue,
        type: [],
        province_ids: [],
        accreditation: [],
        page: 1,
        limit: 12,
        sort_direction: "DESC",
      });

      const options = data.data.universities.map((list) => ({
        value: list.id,
        label: list.name,
      }));

      setUnivOptions(options);

      return options;
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUnivOptions]);

  const storeCompareItem = (data) => {
    const list = getLocalStorage("selectedUniversity");
    if (!list.some((e) => e.id === data?.id)) {
      list.push(data);
    } else {
      window.Swal.fire({
        title: "Perhatian!",
        text: "Kampus sudah terpilih sebelumnya",
        icon: "warning",
        confirmButtonText: "Close",
      });
    }
    if (list.length > 4) {
      window.Swal.fire({
        title: "Perhatian!",
        text: "Pilihan maksimal 4",
        icon: "warning",
        confirmButtonText: "Close",
      });
      return;
    }
    setCompareItem(list);
  };

  const removeCompareItem = async (index) => {
    const list = getLocalStorage("selectedUniversity");
    if (list === null) return;
    list.splice(index, 1);
    setCompareItem(list);
  };

  const getUniversityDetails = async (id) => {
    _api
      .get(`/university/detail/${id}`)
      .then((res) => {
        storeCompareItem(res.data.data);
      })
      .catch((_) => {
        return;
      });
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "rgba(217, 217, 217, 0.5)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
    }),
    input: (provided) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: "1 !important",
      },
    }),
  };

  const onOpenDetail = (e, rest) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/explore-kampus/detail/${rest.id}`, {
      state: {
        isFavorite: rest.favorite,
      },
    });
  };

  return (
    <LayoutV2>
      <div>
        <h1 className="mb-1 text-2xl tablet:text-5xl font-bold">
          Bandingkan Kampus
        </h1>
        <p className="text-lg font-light text-gray-500">
          Temukan kampus impianmu sekarang!
        </p>
        <div className="sm:my-6 my-3 flex justify-between items-center">
          <div className="w-full px-4 py-3 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg">
            <form className="flex flex-col sm:flex-row remove-input-txt-border">
              <AsyncSelect
                styles={styles}
                loadOptions={loadOptions}
                isClearable={true}
                isSearchable={true}
                menuColor
                hideSelectedOptions={true}
                defaultOptions
                className="flex-1 shadow-sm sm:text-sm focus:border-none"
                placeholder="Cari kampus berdasarkan nama..."
                onChange={(option) => {
                  if (option === null) return;
                  getUniversityDetails(option.value);
                }}
              />
            </form>
          </div>
        </div>
        <div className="relative pb-4 overflow-x-auto">
          {compareItem.length < 1 && compareItem && (
            <div className="flex py-10 justify-center">
              <div className="text-center">
                <h3 className="text-gray-800 text-2xl">
                  Kamu belum memilih Universitas untuk dibandingkan
                </h3>
              </div>
            </div>
          )}
          <div className="inline-flex sm:grid sm:grid-cols-4 gap-4 h-full ">
            {compareItem.map((e, i) => {
              return (
                <UnivShortcut
                  key={i}
                  {...e}
                  onRemoveSelect={() => {
                    removeCompareItem(i);
                  }}
                  onOpenDetail={(a) => {
                    onOpenDetail(a, e);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
