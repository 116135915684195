export default function TMBCard({ icon, title, caption }) {
  return (
    <div className="flex flex-col justify-center items-center p-8 rounded-xl w-full bg-white">
      <img alt="icon" src={icon} className="h-15 w-15 object-contain" />
      <p className="text-center text-black2 text-xl font-bold mt-6">{title}</p>
      <p className="text-center text-black21 text-sm font-normal mt-2">
        {caption}
      </p>
    </div>
  );
}
