export default function SigninupSlide({ image, bgColor, minHeight }) {
  return (
    <div
      className="w-full h-full flex flex-1 items-end"
      style={{ backgroundColor: bgColor, minHeight: minHeight }}
    >
      <img
        src={image}
        className="w-max object-contain relative z-40"
        style={{ maxHeight: "457px" }}
      />
    </div>
  );
}
