import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { authState } from "../../atoms/authState";
import { api, apiAnom, apiV2, notifError } from "../../utils/api";
import PageLoading from "../PageLoading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../../utils/utils";
import Button from "../../components/base/Button";
import { signInUpState } from "../../atoms/signInUpState";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import countryJSON from "../../components/jsons/Countries.json";
import indoProvJSON from "../../components/jsons/IndonesiaProvinceWithCities.json";
import indoCityJSON from "../../components/jsons/IndonesiaCities.json";
import { postCheckModalState } from "../../atoms/postCheckModalState";
import PostCheckModal from "../../components/PostCheckModal";
import LayoutV2 from "../../components/Layout_V2";

export default function SampoernaAdmissionForm() {
  const params = new URLSearchParams(window.location.search);
  const admissionID = params.get("admissionID");
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const [univData, setUnivData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [guardianFirstName, setGuardianFirstName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [englishScore, setEnglishScore] = useState("");
  const [mathScore, setMathScore] = useState("");
  const [standardizeTest, setStandardizeTest] = useState("");
  const [ptn, setPTN] = useState("");
  const [ptnOverseas, setPTNOverseas] = useState("");
  const [howDoYouKnow, setHowDoYouKnow] = useState("");
  const [guardianPhone, setGuardianPhone] = useState("");
  const [city, setCity] = useState("");
  const [highSchool, setHighSchool] = useState("");
  const [lastGrade, setLastGrade] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCodeDropdown, setIsCodeDropdown] = useState("");
  const [isCodeDropdown2, setIsCodeDropdown2] = useState("");
  const [errors, setErrors] = useState({});
  const [disableChangePhone, setDisableChangePhone] = useState(false);
  const [countryCode, setCountryCode] = useState(getCountryCallingCode("ID"));
  const [countryCode2, setCountryCode2] = useState(getCountryCallingCode("ID"));
  const [countryList, setCountryList] = useState([]);
  const [provinceNCity, setProvinceNCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("Indonesia");
  const [selectedProvince, setSelectedProvince] = useState("DKI Jakarta");
  const [selectedCity, setSelectedCity] = useState("Pilih Kota");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [indonesiaCities, setIndonesiaCities] = useState([]);
  const [startDate, setStartDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState("01/01/0001");
  const [isBigFile, setIsBigFile] = useState(false);
  const [reportFileName, setReportFileName] = useState("");
  const [isSubmitProcess, setIsSubmitProcess] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [postCheckData, setPostcheckData] = useState();
  const [formFinalData, setFormFinalData] = useState();
  const setPostCheckModalState = useSetRecoilState(postCheckModalState);
  const [uniID, setUniID] = useState();
  const [showPostCheckModal, setShowPostCheckModal] = useState(false);

  function closeModal() {
    setShowPostCheckModal(false);
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    setShowPostCheckModal(false);
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  useEffect(() => {
    setReportFileName("");
    setCountryList(countryJSON);
    setProvinceNCity(indoProvJSON);
    setIndonesiaCities(indoCityJSON);
    if (!auth.isAuth) {
      setSign({ trySignIn: true, trySignUp: false });
    } else if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    } else {
      setIsLoading(true);
      setFullName(
        auth.fname && auth.lname ? auth.fname + " " + auth.lname : ""
      );
      setFirstName(auth.fname ? auth.fname : "");
      setLastName(auth.lname ? auth.lname : "");
      setEmail(auth.email);
      let newCountryCode;
      let newPhone;
      if (auth.phone.includes("-")) {
        newCountryCode = auth.phone.split("-")[0];
        newPhone = auth.phone.split("-")[1];
      } else {
        newCountryCode = getCountryCallingCode("ID");
        newPhone = auth.phone.slice(auth.phone.indexOf("8"));
      }
      setPhone(newPhone);
      setCountryCode(newCountryCode);

      _api
        .get(`/explore-kampus-v2/${window.location.pathname.split("/")[2]}`)
        .then((res) => {
          setUniID(res.data.data.id);
          setUnivData(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setUnivData({});
        });
    }
  }, []);

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          case "countryCode":
            isErr = !/()\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitProcess(true);
    if (isBigFile) {
      window.Swal.fire({
        text: `Silakan unggah dokumen rapor dengan ukuran dokumen maks. 5 MB`,
        icon: "error",
        confirmButtonText: "Tutup",
        allowOutsideClick: true,
      });
    } else {
      e.preventDefault();
      e.stopPropagation();
      const { elements } = e.target;
      if (!validate(elements)) return;
      let formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const newPhone = countryCode + "-" + phone;
      const newGuardianPhone = countryCode2 + "-" + guardianPhone;
      const fullAddress = {
        country: selectedCountry,
        province: selectedProvince,
        city: selectedCity,
        full_address: address,
        zip_code: addressZipCode,
      };
      formData.append("uni_id", univData.id);
      formData.append("full_name", fullName);
      formData.append("date_of_birth", dateOfBirth);
      formData.append("gender", selectedGender);
      formData.append("email", email);
      formData.append("phone", newPhone);
      formData.append("address", fullAddress);
      formData.append("grade", selectedHSGrade);
      formData.append("school_name", highSchool);
      formData.append("school_city", selectedIndonesiaCity);
      formData.append("mother_name", motherName);
      formData.append("guardian_phone", newGuardianPhone);
      formData.append("guardian_email", guardianEmail);
      formData.append("english_score", englishScore);
      formData.append("math_score", mathScore);
      formData.append("report_card", reportFile);
      formData.append("selected_major", selectedMajor);
      formData.append("regis_session", regisSession);
      formData.append("standardize_test", standardizeTest);
      formData.append("ptn", ptn);
      formData.append("ptn_overseas", ptnOverseas);
      formData.append("how_do_you_know", howDoYouKnow);
      formData.append("paid_status", "SUBMITTED"); // DRAFT or SUBMITTED

      apiV2
        .get(`/invoices/postcheck/${uniID}`)
        .then((res) => {
          setPostcheckData(res.data.data);
          setFormFinalData(formData);
          setShowPostCheckModal(true);
          // setPostCheckModalState({ isOpen: true });
        })
        .catch((err) => {
          setFormFinalData(formData);
          setPostcheckData(false);
          if (err.response.status === 404) {
            _api
              .post(
                `/students/univ-admission-USA1052/${admissionID}`,
                formData,
                config
              )
              .then((res) => {
                setIsSubmitProcess(false);

                window.Swal.fire({
                  title: "Success!",
                  text: `Kamu telah mendaftar di ${univData.name}`,
                  icon: "success",
                  confirmButtonText: "Tutup",
                  allowOutsideClick: false,
                }).then(() => {
                  navigate(
                    `/invoice?invoice_number=${res.data.data.invoice_number}`
                  );
                });
              })
              .catch((_) => {
                setIsSubmitProcess(false);

                window.Swal.fire({
                  title: "Gagal",
                  text: `Pendaftaran kampus gagal`,
                  icon: "error",
                  confirmButtonText: "Tutup",
                  allowOutsideClick: false,
                }).then(() => {
                  navigate(`/profile`);
                });
              });
          } else {
            window.Swal.fire({
              title: "Gagal",
              text: err.response.data.error.message,
              icon: "error",
              confirmButtonText: "Tutup",
              allowOutsideClick: false,
            }).then(() => {
              navigate(`/profile`);
            });
          }
        });
    }
  };

  function submitForm() {
    setShowPostCheckModal(false);
    // setPostCheckModalState({ isOpen: false });
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    apiV2
      .post(
        `/invoices/recreate-invoice/${univData.id}/${admissionID}`,
        formFinalData,
        config
      )
      .then((res) => {
        setTimeout(() => {
          setIsSubmitProcess(false);

          window.Swal.fire({
            title: "Success!",
            text: `Kamu telah mendaftar di ${univData.name}`,
            icon: "success",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/invoice?invoice_number=${res.data.data.invoice_number}`);
          });
        }, 1000);
      })
      .catch((_) => {
        setTimeout(() => {
          window.Swal.fire({
            title: "Gagal",
            text: `Pendaftaran kampus gagal`,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/profile`);
          });
        }, 1000);
      });
  }

  const regisSession = [
    "Spring Intake (January)",
    "Summer/Fall Intake (June/Aug)",
  ];

  const highSchoolGrade = ["Kelas 10", "Kelas 11", "Kelas 12"];

  const majorOfInterest = [
    "FET (Mechanical Engineering)",
    "FET (Industrial Engineering)",
    "FET (Computer Science)",
    "FET (Information System)",
    "FET (Visual Communication Design)",
    "FOB (Management)",
    "FOB (Accounting)",
    "FOE (English Education)",
    "FAS (Psychology)",
    "SUAC-FST (Undecided - Science)",
    "SUAC-FOB (Undecided - Social Science)",
    "Other",
  ];
  const gender = ["Laki-laki", "Perempuan"];
  const [selectedMajor, setSelectedMajor] = useState(majorOfInterest[0]);
  const [selectedRegisSession, setSelectedRegisSession] = useState(
    regisSession[0]
  );
  const [selectedGender, setSelectedGender] = useState(gender[0]);
  const [reportFile, setReportFile] = useState();
  function handleReportFile(event) {
    if (event.target.files[0].size > 5120000) {
      window.Swal.fire({
        text: `File terlalu besar. Silakan unggah dokumen rapor dengan ukuran dokumen maks. 5 MB`,
        icon: "error",
        confirmButtonText: "Tutup",
        allowOutsideClick: true,
      });
      event.value = "";
      setIsBigFile(true);
    } else {
      setIsBigFile(false);
      setReportFile(event.target.files[0]);
      setReportFileName(event.target.files[0].name);
    }
  }
  const [selectedHSGrade, setSelectedHSGrade] = useState(highSchoolGrade[0]);
  const [selectedIndonesiaCity, setSelectedIndonesiaCity] =
    useState("Pilih kota");

  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <LayoutV2>
      <p className="text-center font-bold text-3xl hd:text-48px text-black1 mt-4 hd:mt-20">
        Isi Formulir Pendaftaran
      </p>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-8 px-4 hd:px-20 mt-12 pb-20 overflow-auto"
      >
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Nama Lengkap <span className={"text-red-500"}>*</span>
          </label>
          <input
            id="fullName"
            type="text"
            name="fullName"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan nama lengkap"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama Lengkap harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Tanggal Lahir <span className={"text-red-500"}>*</span>
          </label>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Cth: 01/20/2001 (MM/DD/YYYY)"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama belakang harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <ReactDatePicker
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setDateOfBirth(
                moment(date).tz("Asia/Jakarta").format("DD/MM/YYYY")
              );
            }}
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Jenis Kelamin <span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Tipe Program"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedGender}
            onChange={(e) => {
              const program = e.target.value;
              setSelectedGender(program);
            }}
            required
          >
            {gender.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="personal_email"
          >
            Email Pribadi <span className={"text-red-500"}>*</span>
          </label>
          <input
            id="personal_email"
            type="text"
            name="personal_email"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan email pribadi"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Email pribadi harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="phone"
          >
            No. HP Siswa <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-row w-full justify-start items-center mt-4">
            <div
              className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
              style={{
                height: "50px",
                border: "1px solid #E0E0E1",
              }}
              onClick={() => {
                setIsCodeDropdown(!isCodeDropdown);
              }}
            >
              <div className="border-0 w-12 h:w-18 pl-1">
                {`+${countryCode}`}
              </div>
              <ChevronDownIcon
                className="h-5 w-auto p-0 m-0"
                style={{ paddingTop: "2px" }}
              />
              {isCodeDropdown && (
                <div
                  className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10"
                  //   style={{ maxHeight: "344px" }}
                >
                  {/* <option value="">{en["ZZ"]}</option> */}
                  {getCountries().map((country) => (
                    <button
                      key={country}
                      value={country}
                      className="text-left px-4 pt-2 w-max"
                      onClick={() => {
                        setIsCodeDropdown(false);
                        setCountryCode(getCountryCallingCode(country));
                      }}
                    >
                      {en[country]}
                      {/* {labels[country]} +{getCountryCallingCode(country)} */}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              id="phone"
              type="number"
              name="phone"
              accept="phone"
              className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                mapHasKeyAndValueNotEmpty(errors, "phone")
                  ? "border-red-400"
                  : ""
              }`}
              placeholder="cth. 8XXXXXXXXXXXX"
              value={phone}
              onChange={(e) => {
                if (phone[0] === "0") {
                  setPhone(e.target.value.slice(1));
                } else {
                  setPhone(e.target.value);
                }
              }}
              onBlur={() => {
                if (phone[0] === "0") {
                  setPhone(phone.slice(1));
                }
              }}
              disabled={disableChangePhone}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("No. HP harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
        </div>
        {/* <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="alamat_lengkap"
          >
            Alamat Lengkap<span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan alamat lengkap"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Alamat lengkap harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div> */}
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="alamat_lengkap"
          >
            Alamat Lengkap<span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Negara"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedCountry}
            onChange={(e) => {
              const country = e.target.value;
              setSelectedCountry(country);
            }}
            required
          >
            {countryList.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
          {selectedCountry === "Indonesia" && (
            <select
              placeholder={"Pilih Provinsi"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedProvince}
              onChange={(e) => {
                const province = e.target.value;
                setSelectedProvince(province);
              }}
              required
            >
              {provinceNCity.map((item, index) => (
                <option key={index} value={item.provinsi}>
                  {item.provinsi}
                </option>
              ))}
            </select>
          )}
          {selectedCountry === "Indonesia" && (
            <select
              placeholder={"Pilih Kota"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedCity}
              onChange={(e) => {
                const city = e.target.value;
                setSelectedCity(city);
              }}
              required
            >
              {provinceNCity.length > 0 &&
                provinceNCity
                  .filter((element) => element.provinsi === selectedProvince)[0]
                  .kota.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
            </select>
          )}
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan alamat lengkap"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Alamat lengkap harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan kode pos"
            value={addressZipCode}
            onChange={(e) => setAddressZipCode(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Kode pos harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="grade"
          >
            Tingkat Pendidikan <span className={"text-red-500"}>*</span>
          </label>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan tingkat pendidikan"
            value={lastGrade}
            onChange={(e) => setLastGrade(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Tingkat pendidikan harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <select
            id="high_school_grade"
            placeholder={"Pilih tingkat pendidikan"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            aria-label="high_school_grade"
            name="high_school_grade"
            value={selectedHSGrade}
            onChange={(e) => {
              const grade = e.target.value;
              setSelectedHSGrade(grade);
            }}
            required
          >
            {highSchoolGrade.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="school_name"
          >
            Nama Sekolah <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nama sekolah"
            value={highSchool}
            onChange={(e) => setHighSchool(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama sekolah harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="school_city"
          >
            Kota Sekolah <span className={"text-red-500"}>*</span>
          </label>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan kota sekolah"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Kota sekolah harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <select
            id="high_school_grade"
            placeholder={"Pilih tingkat pendidikan"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            aria-label="high_school_grade"
            name="high_school_grade"
            value={selectedIndonesiaCity}
            onChange={(e) => {
              const city = e.target.value;
              setSelectedIndonesiaCity(city);
            }}
            required
          >
            {indonesiaCities.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="mother_name"
          >
            Nama Ibu <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan nama ibu"
            value={motherName}
            onChange={(e) => setMotherName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama ibu harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="phone"
          >
            No. HP Wali <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-row w-full justify-start items-center mt-4">
            <div
              className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
              style={{
                height: "50px",
                border: "1px solid #E0E0E1",
              }}
              onClick={() => {
                setIsCodeDropdown2(!isCodeDropdown2);
              }}
            >
              <div className="border-0 w-12 h:w-18 pl-1">
                {`+${countryCode2}`}
              </div>
              <ChevronDownIcon
                className="h-5 w-auto p-0 m-0"
                style={{ paddingTop: "2px" }}
              />
              {isCodeDropdown2 && (
                <div className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10">
                  {getCountries().map((country) => (
                    <button
                      key={country}
                      value={country}
                      className="text-left px-4 pt-2 w-max"
                      onClick={() => {
                        setIsCodeDropdown2(false);
                        setCountryCode2(getCountryCallingCode(country));
                      }}
                    >
                      {en[country]}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              id="guardianPhone"
              type="number"
              name="guardianPhone"
              accept="guardianPhone"
              className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                mapHasKeyAndValueNotEmpty(errors, "guardianPhone")
                  ? "border-red-400"
                  : ""
              }`}
              placeholder="cth. 8XXXXXXXXXXXX"
              value={guardianPhone}
              onChange={(e) => {
                if (guardianPhone[0] === "0") {
                  setGuardianPhone(e.target.value.slice(1));
                } else {
                  setGuardianPhone(e.target.value);
                }
              }}
              onBlur={() => {
                if (guardianPhone[0] === "0") {
                  setGuardianPhone(guardianPhone.slice(1));
                }
              }}
              disabled={disableChangePhone}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("No. HP wali harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Email Wali <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan email wali"
            value={guardianEmail}
            onChange={(e) => setGuardianEmail(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Email wali harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="english_report_score"
          >
            Nilai Rapor Bahasa Inggris (di kelas 11)
            <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nilai rapor bahasa inggris"
            value={englishScore}
            onChange={(e) => setEnglishScore(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity(
                "Nilai rapor bahasa inggris harus diisi"
              )
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="math_report_score"
          >
            Nilai Rapor Matematika (di kelas 11)
            <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nilai rapor matematika"
            value={mathScore}
            onChange={(e) => setMathScore(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nilai rapor matematika harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="math_report_score"
          >
            Unggah rapor terakhirmu
            <span className={"text-red-500"}>*</span>
          </label>
          <div
            className="flex flex-col hd:flex-row items-center justify-center gap-4 hd:gap-6 w-full rounded-xl mt-2 px-2 py-4"
            style={{ minHeight: "100px", border: "1px dashed #652E97" }}
          >
            <input
              id="file"
              type="file"
              name="file"
              accept=".jpeg,.pdf"
              className=""
              onChange={handleReportFile}
              disabled={false}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("Rapor harus diunggah")
              }
              onInput={(F) => F.target.setCustomValidity("")}
              style={{ display: "none" }}
            />
            <p className="text-center font-normal text-base text-black1">
              {reportFileName === ""
                ? "format dokumen: Rapor_Nama Lengkap. Maks. 5 MB. PDF/JPEG"
                : reportFileName}
            </p>
            <label
              htmlFor="file"
              className="flex flex-row items-center justify-center w-max gap-2 py-2 px-3 bg-white border-1 border-primaryColor rounded-lg"
              style={{ cursor: "pointer" }}
            >
              {reportFileName === "" && (
                <img
                  src="/icon_upload.svg"
                  className="w-full h-full"
                  style={{ width: "20px", height: "16px" }}
                />
              )}
              <p className="text-center font-semibold text-sm text-primaryColor">
                {reportFileName === "" ? "Unggah file" : "Ganti file"}
              </p>
            </label>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="major_of_interest"
          >
            Jurusan Pilihan <span className={"text-red-500"}>*</span>
          </label>
          <select
            id="major_of_interest"
            placeholder={"Pilih Jurusan pilihan"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            aria-label="education level"
            name="major_of_interest"
            value={selectedMajor}
            onChange={(e) => {
              const major = e.target.value;
              setSelectedMajor(major);
            }}
            required
          >
            {majorOfInterest.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Pilih Masa Pendaftaran <span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Sesi Pendaftaran"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedRegisSession}
            onChange={(e) => {
              const session = e.target.value;
              setSelectedRegisSession(session);
            }}
            required
          >
            {regisSession.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="standardize_test"
          >
            Apakah sebelumnya kamu pernah mengambil tes terstandar{" "}
            {"(IELTS/TOEFL/SAT)"}? Apabila IYA, silakan ketikkan macam tes
            beserta hasil akhirnya. Cth.: {`"IELTS - 5.5 overall score"`}
            <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="IELTS – 5.5./TOEFL – 85/TIDAK"
            value={standardizeTest}
            onChange={(e) => setStandardizeTest(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Tes terstandar harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="ptn"
          >
            Apakah kamu juga mendaftar ke PTN {"(Perguruan Tinggi Negeri"}?
            Apabila IYA, silakan ketikkan PTN tersebut
            <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Universitas ABC/TIDAK"
            value={ptn}
            onChange={(e) => setPTN(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) => F.target.setCustomValidity("PTN harus diisi")}
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="ptn"
          >
            Apakah kamu juga mendaftar ke universitas luar negeri? Apabila IYA,
            silakan ketikkan Nama Universitas tersebut
            <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Universitas ABC/TIDAK"
            value={ptnOverseas}
            onChange={(e) => setPTNOverseas(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Universitas luar negeri harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="ptn"
          >
            Bagaimana kamu mengetahui tentang Sampoerna University?
            <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Beritahu kami"
            value={howDoYouKnow}
            onChange={(e) => setHowDoYouKnow(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity(
                "Informasi mengenai Sampoerna University harus diisi"
              )
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>

        {/* Submit Button */}
        <div className="flex flex-wrap px-3 -mx-3 mt-10 w-full justify-center">
          <Button
            disabled={isSubmitProcess}
            type="submit"
            className="w-max rounded-lg"
          >
            {isSubmitProcess ? "Submitting..." : "Simpan"}
          </Button>
        </div>
      </form>

      {/* post check modal */}

      <PostCheckModal
        isModalShow={showPostCheckModal}
        screenWidth={screenWidth}
        postCheckData={postCheckData}
        submitForm={submitForm}
        closeModal={closeModal}
      />
    </LayoutV2>
  );
}
