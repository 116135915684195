import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

export default function RegisProgressCard({ screenWidth, item }) {
  const navigate = useNavigate();

  const regisStatusMeta = [
    {
      fontColor: "rgba(23, 23, 23, 0.8)",
      bgColor: "rgba(227, 227, 227, 1)",
    },
    {
      fontColor: "rgba(249, 198, 11, 1)",
      bgColor: "rgba(255, 249, 227, 1)",
    },
    {
      fontColor: "rgba(0, 168, 119, 1)",
      bgColor: "rgba(238, 255, 250, 1)",
    },
    {
      fontColor: "rgba(254, 82, 68, 1)",
      bgColor: "rgba(255, 236, 234, 1)",
    },
  ];

  const admisParam = (formSlug) => {
    switch (formSlug) {
      case "lasalle-college-jakarta":
        return "2ThmTYRZNwZoniAFH1coUFOqO7T";
      case "sampoerna-university":
        return "2U1p0nZx3FbmkVmCGxlhWidpP6x";
      case "universitas-widyatama":
        return "2U1oduDcRfxTxCJgYeN61Pv7phB";
      default:
        return "";
    }
  };

  const buttonStatusMeta = [
    {
      icon: "./icon_regis_draft.svg",
      caption: "Lanjut Isi",
      link: `${
        item.form_slug === "general"
          ? `/daftar-kampus/${item.univ_slug}`
          : `/admission/${item.form_slug}?admissionID=${item.admission_id}`
      }`,
    },
    {
      icon: "./icon_regis_waiting.svg",
      caption: "Cara Bayar",
      link: `/invoice?invoice_number=${item.invoice_number}`,
    },
    {
      icon: "./icon_regis_download.svg",
      caption: "Unduh invoice",
      link: `/payment?invoice_number=${item.invoice_number}`,
    },
    {
      icon: "./icon_regis_repurchase.svg",
      caption: "Beli lagi",
      link: `/payment?invoice_number=${item.invoice_number}`,
    },
  ];

  const regisStatus = () => {
    switch (item.form_status) {
      case "DRAFT":
        return regisStatusMeta[0];
      case "SUBMITTED":
        return regisStatusMeta[1];
      case "PAID":
        return regisStatusMeta[2];
      case "VA EXPIRED":
        return regisStatusMeta[3];
      default:
        return regisStatusMeta[0];
    }
  };

  const buttonStatus = () => {
    switch (item.form_status) {
      case "DRAFT":
        return buttonStatusMeta[0];
      case "SUBMITTED":
        return buttonStatusMeta[1];
      case "PAID":
        return buttonStatusMeta[2];
      case "VA EXPIRED":
        return buttonStatusMeta[3];
      default:
        return buttonStatusMeta[0];
    }
  };

  const statusLabel = () => {
    switch (item.form_status) {
      case "DRAFT":
        return "Draf";
      case "SUBMITTED":
        return "Menunggu Pembayaran";
      case "PAID":
        return "Berhasil";
      case "VA EXPIRED":
        return "Gagal";
      default:
        return "Draf";
    }
  };

  return (
    <button
      onClick={() => {
        navigate(buttonStatus().link);
      }}
      className="flex flex-col w-full bg-white rounded-xl p-4"
      style={{
        // minHeight: "177px",
        // maxHeight: "177px",
        minWidth: screenWidth >= 1024 && "379px",
        maxWidth: screenWidth >= 1024 && "379px",
        boxShadow: "2px 4px 20px 0px rgba(23, 23, 23, 0.10)",
      }}
    >
      <div className="flex flex-row gap-2 justify-start items-center">
        <p
          className="text-left font-normal"
          style={{ fontSize: "8px", color: "rgba(23, 23, 23, 0.6)" }}
        >
          {moment(item.registered_at).format("DD/MM/YY HH:MM")}
        </p>
        <div
          className="px-2 text-left font-bold rounded-2xl"
          style={{
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "8px",
            color: regisStatus().fontColor,
            backgroundColor: regisStatus().bgColor,
          }}
        >
          {statusLabel(item.form_status)}
        </div>
      </div>
      <div className="flex flex-row justify-start items-center gap-2 mt-2">
        <img
          src={item.logo_url}
          alt="logo"
          className="w-6 h-6 object-contain"
        />
        <p
          className="text-left font-normal text-xs text-black188"
          style={{ lineHeight: "15px" }}
        >
          {item.univ_name}
        </p>
      </div>
      <p
        className="text-left font-semibold text-base text-black1 mt-1"
        style={{ lineHeight: "24px" }}
      >
        {item.admission_name}
      </p>
      <div
        className="flex w-full border-1 my-4"
        style={{ color: "rgba(217, 219, 219, 1)" }}
      ></div>
      <div className="flex flex-row w-full justify-center items-center">
        <div className="flex flex-col w-2/5 justify-center items-start">
          <p
            className="text-left font-normal text-xs text-black188"
            style={{ lineHeight: "15px" }}
          >
            Total Harga
          </p>
          <p
            className="text-left font-semibold text-base text-black1 line-clamp-1 overflow-ellipsis"
            style={{ lineHeight: "24px" }}
          >
            {`Rp ${item.total_price.toLocaleString()}`}
          </p>
        </div>
        <div className="flex justify-end w-3/5">
          {item.is_admission_expired ? (
            <p
              className="text-right font-normal text-sm"
              style={{ color: "rgba(129, 127, 130, 1)" }}
            >
              Sudah tutup
            </p>
          ) : (
            <div
              // onClick={() => {
              //   navigate(buttonStatus().link);
              // }}
              className="flex flex-row justify-center items-center gap-2 bg-white border-1 border-primaryColor px-3 py-2 rounded-lg"
            >
              <img
                alt="button icon"
                src={buttonStatus().icon}
                className="w-6 h-6 object-contain"
              />
              <p
                className="text-center font-semibold text-xs text-primaryColor"
                style={{ lineHeight: "15px" }}
              >
                {buttonStatus().caption}
              </p>
            </div>
          )}
        </div>
      </div>
    </button>
  );
}
