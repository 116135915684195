import { useEffect, useState } from "react";
import { RadioButton } from "../../../components/RadioButton";
import countryJSON from "../../../components/jsons/Countries.json";

export default function LaSalleAcademicInformation({
  draftData,
  callbackData,
  callbackPrevData,
  setCurrentForm,
  currentForm,
  isSubmitProcess,
}) {
  const principalOccupation = ["Employee", "Student", "Other"];
  const nativeLang = ["English", "Indonesian", "Other"];

  const [selectedOccupation, setSelectedOccupation] = useState(
    principalOccupation[0]
  );
  const [selectedLang, setSelectedLang] = useState(nativeLang[0]);
  const [selectedWorkCountry, setSelectedWorkCountry] = useState("Indonesia");
  const [countryList, setCountryList] = useState([]);

  const [bidangPekerjaan, setBidangPekerjaan] = useState("");
  const [namaSMA, setNamaSMA] = useState("");
  const [tahunKelulusan, setTahunKelulusan] = useState("");
  const [pendidikanTerakhir, setPendidikanTerakhir] = useState("");
  const [bahasaLainnya, setBahasaLainnya] = useState("");

  const occupationRadioChangeHandler = (value) => {
    setSelectedOccupation(principalOccupation[value]);
  };

  const langRadioChangeHandler = (value) => {
    setSelectedLang(nativeLang[value]);
  };

  useEffect(() => {
    setCountryList(countryJSON);
  }, []);

  useEffect(() => {
    if (draftData) {
      setSelectedOccupation(
        principalOccupation[
          draftData.pekerjaanUtama
            ? principalOccupation.indexOf(draftData.pekerjaanUtama ?? 0)
            : 0
        ]
      );
      setSelectedWorkCountry(draftData.negaraBekerja ?? "Indonesia");
      setBidangPekerjaan(draftData.bidangPekerjaan ?? "");
      setNamaSMA(draftData.namaSMA ?? "");
      setTahunKelulusan(draftData.tahunKelulusan ?? "");
      setPendidikanTerakhir(draftData.pendidikanTerakhir ?? "");
      setSelectedLang(
        nativeLang[draftData.bahasa ? nativeLang.indexOf(draftData.bahasa) : 0]
      );
      setBahasaLainnya(draftData.bahasaLainnya ?? "");
    }
  }, [draftData]);

  function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    callbackData({
      pekerjaanUtama: selectedOccupation,
      negaraBekerja: elements[3].value,
      bidangPekerjaan: elements[4].value,
      namaSMA: elements[5].value,
      tahunKelulusan: elements[6].value,
      pendidikanTerakhir: elements[7].value,
      bahasa: selectedLang,
      bahasaLainnya: elements[11].value,
    });
  }

  function toPrevStep() {
    callbackPrevData({
      pekerjaanUtama: selectedOccupation,
      negaraBekerja: selectedWorkCountry,
      bidangPekerjaan: bidangPekerjaan,
      namaSMA: namaSMA,
      tahunKelulusan: tahunKelulusan,
      pendidikanTerakhir: pendidikanTerakhir,
      bahasa: selectedLang,
      bahasaLainnya: bahasaLainnya,
    });
    setCurrentForm(currentForm - 1);
  }

  return (
    <div className="flex flex-col w-full justify-start items-start pb-10">
      <form
        onSubmit={submitForm}
        className="flex flex-col w-full justify-start"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-900">
              Pekerjaan Utama <span className={"text-red-500"}>*</span>
            </label>
            <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-2">
              {principalOccupation.map((item, index) => (
                <div key={index}>
                  <RadioButton
                    onClicked={() => {
                      occupationRadioChangeHandler(index);
                    }}
                    id={"occupation" + index}
                    isSelected={selectedOccupation === item}
                    label={item}
                    value={item}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="mt-2">
            <label
              className="block text-base font-bold leading-6 text-gray-900"
              htmlFor="principal_occupation"
            >
              Negara Tempat Bekerja <span className={"text-red-500"}>*</span>
            </label>

            <select
              placeholder={"Pilih Negara"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedWorkCountry}
              onChange={(e) => {
                const country = e.target.value;
                setSelectedWorkCountry(country);
              }}
              required
            >
              {countryList.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-2">
            <label
              className="block text-base font-bold leading-6 text-gray-900"
              htmlFor="principal_occupation"
            >
              Bidang/Jurusan Pekerjaan <span className={"text-red-500"}>*</span>
            </label>
            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan Bidang/Jurusan Pekerjaan"
              defaultValue={bidangPekerjaan}
              onChange={(e) => {
                setBidangPekerjaan(e.target.value);
              }}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity(
                  "Bidang/Jurusan Pekerjaan harus diisi"
                )
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="highschool"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Nama Sekolah Menengah Atas{" "}
              <span className={"text-red-500"}>*</span>
            </label>
            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan Nama SMA"
              defaultValue={namaSMA}
              onChange={(e) => setNamaSMA(e.target.value)}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("Nama SMA harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="first-name"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Tahun Kelulusan <span className={"text-red-500"}>*</span>
            </label>
            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan tahun kelulusan"
              defaultValue={tahunKelulusan}
              onChange={(e) => setTahunKelulusan(e.target.value)}
              disabled={false}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("Tahun kelulusan harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="last_grade"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Pendidikan Terakhir <span className={"text-red-500"}>*</span>
            </label>

            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan pendidikan terakhir"
              defaultValue={pendidikanTerakhir}
              onChange={(e) => setPendidikanTerakhir(e.target.value)}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("Pendidikan terakhir harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="last_grade"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Bahasa<span className={"text-red-500"}>*</span>
            </label>

            <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-2">
              {nativeLang.map((item, index) => (
                <div key={index}>
                  <RadioButton
                    onClicked={() => {
                      langRadioChangeHandler(index);
                    }}
                    id={"lang" + index}
                    isSelected={selectedLang === item}
                    label={item}
                    value={item}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-2">
            <label
              htmlFor="last_grade"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Bahasa Lainnya<span className={"text-red-500"}>*</span>
            </label>

            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan bahasa lainnya"
              defaultValue={bahasaLainnya}
              onChange={(e) => setBahasaLainnya(e.target.value)}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity(
                  "Bahasa lainnya terakhir harus diisi"
                )
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
        </div>

        <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
          <button
            onClick={() => {
              toPrevStep();
            }}
            className="py-3 bg-white text-center font-semibold text-base text-primaryColor rounded-lg mt-16 border-2 border-primaryColor"
            style={{ lineHeight: "24px", minWidth: "200px" }}
          >
            Sebelumnya
          </button>

          <button
            type="submit"
            disabled={
              bidangPekerjaan === "" ||
              namaSMA === "" ||
              tahunKelulusan === "" ||
              pendidikanTerakhir === "" ||
              bahasaLainnya === "" ||
              isSubmitProcess
            }
            className={`py-3 ${
              bidangPekerjaan === "" ||
              namaSMA === "" ||
              tahunKelulusan === "" ||
              pendidikanTerakhir === "" ||
              bahasaLainnya === "" ||
              isSubmitProcess
                ? "bg-gray-400"
                : "bg-primaryColor"
            } text-center font-semibold text-base text-white rounded-lg mt-16 px-5`}
            style={{ lineHeight: "24px" }}
          >
            {isSubmitProcess
              ? "Submitting..."
              : "Selesai dan Lanjutkan ke Pembayaran"}
          </button>
        </div>
      </form>
    </div>
  );
}
