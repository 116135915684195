import Layout from "../components/Layout";
import {apiAnom, notifError} from "../utils/api";
import {useState} from "react";
import Button from "../components/base/Button";
import {Link} from "react-router-dom";
import LayoutV2 from "../components/Layout_V2";

const ForgotPassword = () => {
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        apiAnom
            .post("/auth/forgot-password", {
                email: e.target.elements[0].value,
            })
            .then((response) => {
                // when recently has requested forgot password
                if (response.status === 208) {
                    setMessage("An email has been sent. Please check your inbox");
                    return;
                }
                setSuccess(true);
            })
            .catch((err) => {
                notifError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    if (success) {
        return (
            <LayoutV2>
                <div className="pt-8 pb-12 tablet:pt-36 tablet:pb-20">
                    <img src={"/email.svg"} alt={"mail sent"} className="h-20 w-20 mx-auto mb-5" />
                    <div className="max-w-3xl mx-auto text-center pb-12">
                        <h1 className="tablet:h1 h3 mb-6">Cek Emailmu!</h1>
                        <p className="text-gray-600">
                            Kami telah mengirimkan link ke alamat emailmu agar kamu dapat melanjutkan proses pembuatan password baru.
                        </p>
                        <p className="text-gray-600 mt-4">
                            Yuk, ikuti langkah-langkahnya agar kamu bisa kembali mengeksplor berbagai kelas di exploration!
                        </p>
                    </div>
                    <div className="max-w-sm mx-auto">
                        <div className="flex items-center justify-center">
                            <Link to="/signin">
                                <Button>Masuk</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </LayoutV2>
        );
    }

    return (
        <LayoutV2>
            <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20">
                <div className="max-w-3xl mx-auto text-center pb-12">
                    <h1 className="tablet:h1 h3 mb-3">Lupa Password</h1>
                    <p>Masukkan emailmu yang terdaftar di exploration</p>
                </div>
                <div className="max-w-sm mx-auto">
                    <form onSubmit={onSubmit}>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full mb-4 px-3">
                                <label
                                    className={`block ${message.length > 0 ? "text-red-500": "text-gray-800"}  text-sm font-medium mb-1`}
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    className="form-input w-full text-gray-800"
                                    placeholder="masukkan alamat email"
                                    required
                                    onChange={() => setMessage("")}
                                />
                                {message.length > 0 && (
                                    <div className={"text-red-500"}>{message}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-6">
                            <div className="w-full px-3">
                                <Button disabled={loading} type="submit" className="w-full">
                                    Kirim Link
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </LayoutV2>
    );
}

export default ForgotPassword;
