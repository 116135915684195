import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Layout from "../../../components/Layout";

import { authState } from "../../../atoms/authState";
import { api, apiAnom } from "../../../utils/api";
import FavoriteButton from "../../../components/FavoriteButton";
import { HeartIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import { useRef, useState, Fragment } from "react";
import { useEffect } from "react";
import Button from "../../../components/base/Button";
import { ChevronDownIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CalendarIcon, ClockIcon } from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import VerifiedIcon from "../../../components/VerifiedIcon";
import LayoutV2 from "../../../components/Layout_V2";

export default function UniversityDetail() {
  moment.tz.add('Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6');
  const myRef = useRef();
  let { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [data, setData] = useState();
  const [favorite, setFavorite] = useState(state?.isFavorite ?? false);
  const [isLoading, setLoading] = useState(true);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 35,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 25,
    },
    tablet: {
      breakpoint: { max: 1024, min: 720 },
      items: 4,
      partialVisibilityGutter: 5,
    },
    mobile3: {
      breakpoint: { max: 720, min: 480 },
      items: 1,
      partialVisibilityGutter: 90,
    },
    mobile2: {
      breakpoint: { max: 480, min: 320 },
      items: 1,
      partialVisibilityGutter: 100,
    },
    mobile: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      partialVisibilityGutter: 35,
    },
  };

  const achievementResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  useEffect(() => {
    const getUnivDetails = async () => {
      await _api
        .get(`/university/detail/${id}`)
        .then((res) => {
          if (res.data.data.slug !== "") {
            navigate(`/explore-kampus/${res.data.data.slug}`, {
              replace: true,
            });
          }
          setData(res.data.data);
          setFavorite(res.data.data.favorite);
        })
        .catch((_) => {
          setData({});
        });
    };
    getUnivDetails().then(() => {
      setLoading(false);
    });
  }, [_api, auth.isAuth, id]);

  const typeTitle = (type) => {
    switch (type) {
      case "N":
        return "Negeri";
      case "L":
        return "Luar Negeri";
      case "S":
        return "Swasta";
      default:
        return "Lainnya";
    }
  };

  const addUnivToFav = (fav, id) => {
    if (fav && !auth.isAuth) {
      navigate({
        pathname: "/signin",
        search: `?redirect=/explore-kampus/detail/${id}`,
      });
    }

    const url = fav
      ? "/favorites/university/add"
      : "/favorites/university/delete";

    _api
      .post(url, {
        uni_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };

  const transformNumberPhone = (no) => {
    if (!no) return "";
    const countryCode = no.substr(0, no.includes("+") ? 3 : 2);
    const mobile = no.substr(countryCode.length, no.length);
    return `08${mobile}`;
  };

  const navigateToMajorDetail = (id) => {
    if (id == null || id === 0) return;
    navigate(`/explore-jurusan/detail/${id}`, {
      // state: {
      //   isFavorite: false,
      // }
    });
  };

  const navigateToExploreClasses = () => {
    navigate(`/explore-kelas`, {
      state: {
        initialUniv: data,
      },
    });
  };

  const navigateToClassDetail = (id) => {
    if (id == null || id === 0) return;
    navigate(`/explore-kelas/detail/${id}`, {});
  };

  const transformTuitionName = (name) => {
    if (name == null) return "";
    if (name.toLowerCase() === "semua jurusan") return name;
    return `Jurusan ${name}`;
  };

  const navigateToComparation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/bandingkan-kampus`, {
      state: {
        data: data,
      },
    });
  };

  if (isLoading === false) {
    const onContentIndexClick = (value) => {
      const element = document.getElementById(value);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    return (
      <LayoutV2>
        <div className="flex flex-row items-center justify-start text-xss text-left lg:text-base font-medium py-4 -ml-2">
          <Link to="/explore-kampus">Explore Kampus</Link>
          <div>&nbsp;{` > ${data?.name}`}</div>
        </div>
        <div className="-mx-6 relative group divide-y divide-gray-200 overflow-hidden bg-white">
          <div>
            <div className="w-full aspect-w-16 aspect-h-9">
              <img
                src={data?.img_url}
                className="w-full h- h-full object-cover"
                alt="bg img univ"
              />
            </div>
            <div className="shadow-2xl">
              <FavoriteButton
                className="top-3 right-5 lg:top-5"
                isFavorite={favorite}
                onChangeFavorite={() => {
                  //callback fav, and id;
                  addUnivToFav(!favorite, data.id);
                }}
              />
            </div>
          </div>
          <div
            className="absolute bottom-0 w-full px-4 py-1"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.86)",
            }}
          >
            <div className="flex flex-col items-center">
              <div className="items-start justify-start text-sm text-gray-500 text-left w-full">
                {typeTitle(data?.type)}
              </div>
              <div className="flex items-center justify-start w-full my-2 ml-4">
                <img
                  className="h-8 w-8 mr-3"
                  src={data?.logo_url}
                  alt={"logo"}
                />
                <p className="text-left font-bold">{data?.name}</p>
              </div>
              <div className="flex flex-row items-center w-full leading-none mb-1">
                <div className="flex flex-1 justify-start items-center">
                  <LocationMarkerIcon className="h-3 mr-1" />
                  <p className="text-sm font-normal text-gray-500 tablet:text-lg">
                    {data?.province}
                  </p>
                </div>
                <div className="flex flex-1 justify-end text-sm text-gray-500">{`Akreditasi ${data?.accreditation}`}</div>
              </div>
            </div>
            {data?.verified && (
              <div className="absolute top-2 right-4">
                <img
                  src="/verified_icon2.svg"
                  className="h-4 w-4 mac:h-6 mac:w-6 ml-1 mac:ml-2"
                />
              </div>
            )}
          </div>
        </div>
        <Menu
          as="div"
          className="relative inline-block text-left mt-5 w-44 z-10"
        >
          <div className="w-full">
            <Menu.Button className="flex flex-row gap-2 w-full justify-start rounded-md border border-black bg-gray-100 bg-opacity-20 px-4 py-2 text-sm text-left font-medium text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <div className="flex flex-1 justify-start">Cari Informasi</div>
              <div className="flex justify-end">
                <ChevronDownIcon
                  className="h-5 w-5 text-violet-200 hover:text-violet-100"
                  aria-hidden="true"
                />
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        onContentIndexClick("pengenalan");
                      }}
                      className={`${
                        active ? "bg-primaryColor text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Pengenalan
                    </button>
                  )}
                </Menu.Item>
                {data?.favorite_majors.length > 0 &&
                data?.favorite_majors !== null ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onContentIndexClick("jurusan");
                        }}
                        className={`${
                          active
                            ? "bg-primaryColor text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Jurusan Favorit
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <div></div>
                )}
                {data?.highest_fee_major !== null &&
                data?.lowest_fee_major !== null ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onContentIndexClick("biaya kuliah");
                        }}
                        className={`${
                          active
                            ? "bg-primaryColor text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Biaya Kuliah
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <div></div>
                )}
                {data?.classes.length > 0 ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onContentIndexClick("kelas");
                        }}
                        className={`${
                          active
                            ? "bg-primaryColor text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Kelas
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <div></div>
                )}
                {data?.regis_info.length > 0 ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onContentIndexClick("pendaftaran");
                        }}
                        className={`${
                          active
                            ? "bg-primaryColor text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Informasi Pendaftaran
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <div></div>
                )}
                {data?.achievements !== null &&
                data?.achievements?.length > 0 ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onContentIndexClick("pencapaian");
                        }}
                        className={`${
                          active
                            ? "bg-primaryColor text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Pencapaian
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <div></div>
                )}
                {data?.facilities !== null && data?.facilities?.length > 0 ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onContentIndexClick("fasilitas");
                        }}
                        className={`${
                          active
                            ? "bg-primaryColor text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Fasilitas Kampus
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <div></div>
                )}
                {data?.social_life !== null && data?.social_life?.length > 0 ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onContentIndexClick("sosial");
                        }}
                        className={`${
                          active
                            ? "bg-primaryColor text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Kehidupan Sosial
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <div></div>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        onContentIndexClick("kontak informasi");
                      }}
                      className={`${
                        active ? "bg-primaryColor text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Kontak Informasi
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <div className="w-full">
          <div
            id={"pengenalan"}
            className="border border-[#888888] py-2 px-3 mt-3"
          >
            <h3 className="font-bold text-lg text-primaryColor">Pengenalan</h3>
            <div
              className="text-sm lg:text-base text-gray-600 text-left my-2 whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></div>
            <span className="text-xs text-gray-400">
              {data?.description_source &&
                "sumber: " + data?.description_source}
            </span>
          </div>
          {data?.favorite_majors.length > 0 &&
          data?.favorite_majors !== null ? (
            <div
              id={"jurusan"}
              className="border border-[#888888] py-2 px-3 mt-3"
            >
              <div className="text-left text-lg font-bold text-primaryColor whitespace-pre-line">
                Jurusan Favorit
              </div>
              <div className="text-left">
                <ul className="list-decimal">
                  {data?.favorite_majors.map((d, i) => {
                    return (
                      <div
                        key={i}
                        className={`text-base text-gray-400 ${
                          d.majors_of_universities_id != null &&
                          d.majors_of_universities_id > 0
                            ? "underline cursor-pointer"
                            : "no-underline"
                        } `}
                        onClick={() =>
                          navigateToMajorDetail(d.majors_of_universities_id)
                        }
                      >
                        <div className="py-0.5">
                          {`${i + 1}.`}
                          <span>&nbsp;{d.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {data?.highest_fee_major !== null &&
          data?.lowest_fee_major !== null ? (
            <div
              id={"biaya kuliah"}
              className="flex flex-col justify-center border border-[#888888] px-3 py-2 mt-3"
            >
              <div className="text-left text-lg font-bold text-primaryColor whitespace-pre-line">
                Biaya Kuliah
              </div>
              <div className="mt-2 leading-tight">
                <p className="text-base">Biaya Semester Min.</p>
                <p className="text-lg font-bold " style={{ color: "#43BCCD" }}>
                  Rp.{" "}
                  {data?.lowest_fee_major?.semester_fees?.toLocaleString() ?? 0}
                </p>
                <p className="text-base">
                  {transformTuitionName(data?.lowest_fee_major?.name)}
                </p>
                <p className="text-base mt-3">Biaya Semester Max.</p>
                <p className="text-lg font-bold " style={{ color: "#F9C80E" }}>
                  Rp.{" "}
                  {data?.highest_fee_major?.semester_fees?.toLocaleString() ??
                    0}
                </p>
                <p className="text-base">
                  {transformTuitionName(data?.highest_fee_major?.name)}
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {data?.classes?.length > 0 ? (
            <div
              id={"kelas"}
              className="border bg-white border-backgroundGray2 rounded-lg py-2 px-3 mt-3"
            >
              <h3 className="font-bold text-lg text-primaryColor">
                Explore Kelas
              </h3>
              <div className="relative overflow-x-auto mt-3 pb-8">
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  arrows={false}
                  renderArrowsWhenDisabled={false}
                  containerClass="container"
                  renderDotsOutside
                  partialVisible
                >
                  {data?.classes?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col px-2 py-1 rounded-lg w-46 tablet:w-52 hd:w-56"
                      style={{
                        backgroundColor: "rgba(136, 136, 136, 0.15)",
                      }}
                    >
                      <div
                        className="text-sm mac:text-base font-medium line-clamp-1 tracking-wide"
                        style={{ color: "#F9C80E" }}
                      >
                        {/* Pengenalan huruf kanji dan praktek kaligrafi Jepang (Shodo) */}
                        {item.topic}
                      </div>
                      <div className="flex flex-row items-center justify-start mt-2">
                        <CalendarIcon className="font-normal w-4 h-4 mac:w-5 mac:h-5" />
                        <span className="text-xs mac:text-sm ml-2">
                          {moment(item.started_at).tz('Asia/Jakarta').format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="flex flex-row items-center justify-start mt-1">
                        <CalendarIcon className="font-normal w-4 h-4 mac:w-5 mac:h-5" />
                        <span className="text-xs mac:text-sm ml-2">
                          &nbsp;
                          {`${moment(item.started_at).tz('Asia/Jakarta').format(
                            "HH.mm"
                          )} - ${moment(item.ended_at).tz('Asia/Jakarta').format("HH.mm")}`}
                        </span>
                      </div>
                      <div
                        className="flex flex-row items-center justify-end h-full leading-none mt-2"
                        onClick={() => navigateToClassDetail(item.id)}
                      >
                        <div className="text-right text-xs mac:text-sm">
                          Daftar Kelas
                        </div>
                        <div className="h-5 w-5 mac:h-6 mac:w-6 ml-1">
                          <img
                            src="/arrow-circle-right.svg"
                            className="h-full w-auto object-contain"
                            alt="arrow right"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              <div
                className="flex flex-row items-center justify-end object-right mt-2"
                onClick={() => navigateToExploreClasses()}
              >
                <div className="text-right text-sm">Lihat Kelas Lainnya</div>
                <span>
                  <img
                    src="/arrow-circle-right.svg"
                    className="h-full ml-2"
                    alt="arrow right"
                  />
                </span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {data?.regis_info?.length > 0 ? (
            <div
              id={"pendaftaran"}
              className="border bg-white border-backgroundGray2 rounded-lg py-2 px-3 mt-3"
            >
              <h2 className="font-bold text-lg text-primaryColor">
                Informasi Pendaftaran
              </h2>
              <div className="relative overflow-x-auto mt-3 pb-8">
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  arrows={false}
                  renderArrowsWhenDisabled={false}
                  containerClass="container"
                  renderDotsOutside
                  partialVisible
                >
                  {data?.regis_info &&
                    data?.regis_info.map((d, i) => {
                      const start = moment(d.start_date).tz('Asia/Jakarta').format("DD MMM YYYY");
                      const end = moment(d.end_date).tz('Asia/Jakarta').format("DD MMM YYYY");
                      return (
                        <div
                          key={i}
                          className="flex flex-col border border-gray-500 rounded-lg px-2 py-2 mr-4 w-46 tablet:w-52 hd:w-56 h-28"
                          style={{
                            borderLeft: "12px solid #04C4B2",
                            // minWidth: "130px",
                          }}
                        >
                          <div className="flex flex-1">
                            <p className="text-sm line-clamp-2">{d.title}</p>
                          </div>
                          <div className="flex flex-1 text-sm font-bold">
                            <div className="flex flex-col items-start justify-center">
                              <p>{start} - </p>
                              <p>{end}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Carousel>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {data?.achievements?.length > 0 || data?.achievements !== null ? (
            <div
              id={"pencapaian"}
              className="border bg-white border-[#888888] rounded-lg p-2 mt-3"
            >
              <h3 className="font-bold text-lg text-primaryColor">
                Pencapaian
              </h3>
              <div className="relative pb-7">
                <Carousel
                  responsive={achievementResponsive}
                  showDots={true}
                  arrows={false}
                  renderArrowsWhenDisabled={false}
                  containerClass="container"
                  renderDotsOutside
                >
                  {data?.achievements?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-start items-center w-32 2mac:w-52 px-3"
                    >
                      <div className="w-full h-20 2mac:h-32">
                        <img
                          src={
                            item.img_url !== "" ? item.logo_url : "/logo512.png"
                          }
                          className="h-full w-full object-contain"
                          alt="logo"
                        />
                      </div>
                      <div className="text-center text-sm items-start line-clamp-2">
                        {item.name}
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {/* <div className="grid grid-cols-2 font-bold justify-center gap-2 my-8 mx-4">
            <div className="border border-[#888888] py-3 tablet:px-20 rounded-lg flex flex-col">
              <span className="text-black text-3xl text-center leading-none">
                {data?.total_faculties ? data?.total_faculties : 0}
              </span>
              <span className="text-black text-lg text-center">
                Fakultas
              </span>
            </div>
            <div className="border border-[#888888] py-3 tablet:px-20 rounded-lg flex flex-col">
              <span className="text-black text-3xl text-center leading-none">
                {data?.total_majors ? data?.total_majors : 0}
              </span>
              <span className="text-black text-lg text-center">
                Jurusan
              </span>
            </div>
          </div> */}
          {data?.facilities?.length > 0 ? (
            <div id={"fasilitas"} className="mt-3">
              <h2 className="font-bold text-lg text-primaryColor">
                Fasilitas Kampus
              </h2>
              <div className="relative overflow-x-auto mt-2">
                <div className="inline-flex gap-3 pb-2">
                  {data?.facilities &&
                    data?.facilities.map((item, index) => {
                      return (
                        <div key={index} className="flex flex-col w-28 lg:w-52">
                          <div className="items-center justify-center aspect-w-4 aspect-h-3">
                            <img
                              src={
                                item.img_url !== ""
                                  ? item.img_url
                                  : "/logo512.png"
                              }
                              className="object-cover rounded"
                              alt="bg img"
                            />
                          </div>
                          <div
                            className="text-xss lg:text-sm mt-0.5"
                            style={{ color: "#999999" }}
                          >
                            <small>{`sumber: ${item.img_source}`}</small>
                          </div>
                          <div className="text-xss lg:text-sm mt-0.5 text-black">
                            {item.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {data?.verified === true ? (
            data?.social_life?.length > 0 || data?.social_life !== null ? (
              <div id={"sosial"} className="mt-3">
                <h3 className="font-bold text-lg text-primaryColor">
                  Kehidupan Sosial
                </h3>
                <div className="relative overflow-x-auto pb-2 mt-2">
                  <div className="inline-flex gap-3">
                    {data?.social_life.map((item, index) => (
                      <div key={index} className="flex flex-col w-28 lg:w-52">
                        <div className="items-center justify-center aspect-w-4 aspect-h-3">
                          <img
                            src={
                              item.img_url !== ""
                                ? item.img_url
                                : "/logo512.png"
                            }
                            className="object-cover rounded"
                            alt="img"
                          />
                        </div>
                        <div
                          className="text-xss lg:text-sm mt-0.5"
                          style={{ color: "#999999" }}
                        >
                          <small>{`sumber: ${item.img_source}`}</small>
                        </div>
                        <div className="text-xss lg:text-sm mt-0.5 text-black">
                          {item.name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )
          ) : (
            <div></div>
          )}
          <div
            id={"kontak informasi"}
            className="border bg-white border-[#888888] px-3 py-2 mt-3"
          >
            <h3 className="font-bold text-lg text-primaryColor">
              Kontak Informasi
            </h3>
            <div className="flex flex-col my-2 text-xs lg:text-sm">
              <span className="text-left text-xs lg:text-sm text-black">
                {data?.contacts?.address ?? ""}
              </span>
              <div className="pt-1 pl-2 lg:pt-3 flex flex-col gap-1">
                <div>
                  {data?.contacts?.website !== "" ? (
                    <a
                      target="_blank"
                      href={data?.contacts?.website}
                      className=" text-left text-black"
                      rel="noreferrer"
                    >
                      <span className="inline-block align-middle">
                        <img
                          src="/logo-web.svg"
                          width="15"
                          height="15"
                          alt=""
                        />
                      </span>
                      <span>&nbsp;&nbsp;</span>
                      <span className="inline-block align-middle">
                        {data?.contacts?.website ?? ""}
                      </span>
                    </a>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  {data?.contacts?.social_media?.ig !== "" ? (
                    <a
                      target="_blank"
                      href={`https://instagram.com/${
                        data?.contacts?.social_media?.ig ?? ""
                      }`}
                      className="text-left text-black align-middle"
                      rel="noreferrer"
                    >
                      <div>
                        <span className="inline-block align-middle">
                          <img
                            src="/logo-ig-2.svg"
                            width="15"
                            height="15"
                            alt=""
                          />
                        </span>
                        <span>&nbsp;&nbsp;</span>
                        <span className="inline-block align-middle">
                          @{data?.contacts?.social_media?.ig ?? ""}
                        </span>
                      </div>
                    </a>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  {data?.contacts?.phone !== "" ? (
                    <div>
                      <span className="inline-block align-middle">
                        <img
                          src="/logo-phone.svg"
                          width="15"
                          height="15"
                          alt=""
                        />
                      </span>
                      <span>&nbsp;&nbsp;</span>
                      <span className=" text-left text-black">
                        {transformNumberPhone(data?.contacts?.phone)}
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  {data?.contacts?.whatsapp !== "" ||
                  data?.contacts?.whatsapp !== null ? (
                    <a
                      target="_blank"
                      href={`https://wa.me/${data?.contacts?.whatsapp ?? ""}`}
                      className=" text-left text-black"
                      rel="noreferrer"
                    >
                      <div>
                        <span className="inline-block align-middle">
                          <img
                            src="/logo-whatsapp.svg"
                            width="15"
                            height="15"
                            alt=""
                          />
                        </span>
                        <span>&nbsp;&nbsp;</span>
                        <span className=" text-left text-black">
                          {transformNumberPhone(data?.contacts?.whatsapp) ?? ""}
                        </span>
                      </div>
                    </a>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-4 my-3 h-14">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addUnivToFav(!favorite, data.id);
              }}
              className={`border flex flex-1 items-center justify-center ${
                favorite ? "bg-primaryColor" : "bg-white"
              } ${favorite ? "text-white" : "text-primaryColor"} ${
                favorite ? "border-none" : "border-primaryColor"
              } rounded-xl leading-tight h-full`}
            >
              {favorite ? (
                <div className="flex flex-row items-center mx-1">
                  <div className="h-6 w-6">
                    <HeartIcon
                      className="h-full object-contain text-black"
                      fill="#ff5151"
                    />
                  </div>
                  <span className="text-left text-xs font-bold text-white ml-2">
                    Telah Ditambahkan ke Favorit
                  </span>
                </div>
              ) : (
                <div className="flex flex-row items-center mx-1">
                  <div className="h-6 w-6">
                    <HeartIcon className="h-full object-contain" />
                  </div>
                  <span className="text-left text-xs font-bold text-black ml-2">
                    Tambahkan ke Favorit
                  </span>
                </div>
              )}
            </button>
            <button
              onClick={navigateToComparation}
              className="flex flex-1 items-center justify-center bg-tosca2 text-black rounded-xl leading-tight text-xs font-bold h-full"
            >
              Bandingkan Kampus
            </button>
          </div>
        </div>
      </LayoutV2>
    );
  }
  return null;
}
