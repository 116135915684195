export default function PageLoading() {
  return (
    <div className="animate-pulse"></div>
    // <div className="fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-white z-50">
    //   <div className="mx-auto text-center">
    //     <div className="h4">Loading...</div>
    //   </div>
    // </div>
  );
}
