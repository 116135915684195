import { Dialog, Transition } from "@headlessui/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { postCheckModalState } from "../atoms/postCheckModalState";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export default function PostCheckModal({
  isModalShow,
  screenWidth,
  postCheckData,
  submitForm,
  closeModal,
}) {
  const checkModal = useRecoilValue(postCheckModalState);
  const setPostCheckModalState = useSetRecoilState(postCheckModalState);
  const navigate = useNavigate();

  return (
    <Transition appear show={isModalShow} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0"
            style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
          />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-max h-full transform overflow-hidden ${
                  screenWidth >= 1024 ? "rounded-3xl" : "rounded-2xl"
                } bg-white text-left align-middle shadow-xl transition-all relative z-50`}
              >
                <div
                  className="flex flex-col items-center rounded-20px bg-white p-10"
                  style={{
                    // width: `${screenWidth >= 1024 && "894px"}`,
                    // height: `${screenWidth >= 1024 && "467px"}`,
                    borderRadius: "20px",
                  }}
                >
                  <img
                    src="/illu_admission_draft.svg"
                    alt="admis draft illu"
                    style={{
                      minWidth: screenWidth >= 1024 && "417px",
                      minHeight: screenWidth >= 1024 && "341px",
                      maxWidth: screenWidth >= 1024 && "417px",
                      maxHeight: screenWidth >= 1024 && "341px",
                    }}
                  />
                  <div
                    className="flex flex-col w-full items-center justify-start gap-2 tablet:gap-4 mt-8"
                    style={{
                      minWidth: screenWidth >= 1024 && "543px",
                      maxWidth: screenWidth >= 1024 && "543px",
                    }}
                  >
                    <p
                      className={`text-left font-bold ${
                        screenWidth >= 1024 ? "text-3xl" : "text-xl"
                      } text-black1`}
                      style={{ lineHeight: screenWidth >= 1024 && "40.32px" }}
                    >
                      Kamu Yakin Ingin Membatalkan Pesanan Sebelumnya?
                    </p>
                    <p
                      className={`text-left font-normal ${
                        screenWidth >= 1024 ? "text-base" : "text-sm"
                      }`}
                      style={{
                        color: "rgba(110, 116, 118, 1)",
                        lineHeight: screenWidth >= 1024 && "24px",
                      }}
                    >
                      Kamu masih memiliki formulir lain yang telah kamu submit
                      ke universitas ini dan belum terbayar. Jika kamu
                      melanjutkan pembayaran untuk formulir ini, formulir
                      sebelumnya akan dibatalkan,
                    </p>
                    <div className="flex w-full grid-cols-2 gap-4">
                      <button
                        onClick={submitForm}
                        className="w-full py-3 bg-primaryColor rounded-lg text-center font-bold text-base text-white mt-2 tablet:mt-0"
                      >
                        Lanjutkan dengan formulir ini!
                      </button>

                      <button
                        onClick={() => {
                          closeModal();
                          // setPostCheckModalState({ isOpen: false });
                          navigate(
                            `/invoice?invoice_number=${postCheckData.invoice_number}`
                          );
                        }}
                        className="w-full py-3 bg-white rounded-lg text-center font-bold text-sm text-black border-1 border-primaryColor mt-2 tablet:mt-0"
                      >
                        Bayar formulir yang masih menunggu
                      </button>
                    </div>
                  </div>
                  {/* <button
            type="button"
            className="absolute top-6 left-6 z-30"
            onClick={() => {
              setVideoModalOpen(false);
            }}
          >
            <MdClose size={26} color="black " />
          </button>
          <div
            className="relative flex flex-col justify-center items-center p-8 tablet:p-16 rounded-20px tablet:rounded-none"
            style={{ backgroundColor: "#F1F3F6" }}
          >
            <img
              className="h-48 w-48 tablet:h-60 tablet:w-60 mx-auto mb-8 mt-1"
              src="/ic_qrcode_wa.jpg"
              alt="Hero"
              style={{
                border: "20px solid #F9C60B",
                borderRadius: "44px",
              }}
            />
            <p
              className="text-center font-medium text-base text-black1 w-60"
              style={{ lineHeight: "24px" }}
            >
              Jangan lupa scan QR Code untuk bergabung dengan para
              Explorers lainnya disini ya!
            </p>
          </div>
          <div className="w-full h-full flex flex-col justify-start items-start px-4 py-6 tablet:p-12 overflow-auto">
            <p className="text-left font-bold tablet:font-normal text-base tablet:text-2xl text-black1">
              Yeay! Kamu telah terdaftar pada kelas:
            </p>
            <p className="text-left font-semibold text-xl tablet:text-3xl text-primaryColor mt-2 tablet:mt-4">
              {classData.info.topic}
            </p>
            <p className="text-left font-normal text-sm tablet:text-base text-black1 mt-4 tablet:mt-6">
              Link untuk mengikuti kelas sudah terkirim ke email kamu.
              Kamu juga bisa mengaksesnya melalui “Aktivitas Kamu”
              pada Profile! Sampai jumpa di kelas ya 👋
            </p>
            <div className="flex flex-grow"></div>
            <div className="w-full flex flex-col tablet:flex-row justify-between items-center mt-12 tablet:mt-0">
              <button
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <div className="text-center font-semibold text-base text-primaryColor px-10 py-3 rounded-lg">
                  Lihat Aktivitasku
                </div>
              </button>
              <button
                onClick={() => {
                  navigate("/explore-kelas");
                }}
              >
                <div className="text-center font-semibold text-base text-white bg-primaryColor px-10 py-3 rounded-lg">
                  Explore kelas lain yuk
                </div>
              </button>
            </div>
          </div> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
