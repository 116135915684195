import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useState, useEffect } from "react";

import { authState } from "../../atoms/authState";
import { apiAnom, api } from "../../utils/api";
import ReactSelect from "react-select";
import * as _ from "lodash";
import PageLoading from "../PageLoading";
import Layout from "../../components/Layout";
import Button from "../../components/base/Button";
import Pagination from "../../components/pagination/Pagination";
import DropdownItem from "../../components/DropdownItem";
import CardMajor from "../../components/CardMajor";
import LayoutV2 from "../../components/Layout_V2";

let pageSize = 12;

export default function EksplorasiJurusan() {
  const auth = useRecoilValue(authState);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBidang, setFilterBidang] = useState([]);
  const [filterKarakter, setFilterKarakter] = useSearchParams();
  const karakter = filterKarakter.get("karakter");
  const navigate = useNavigate();

  const [fosList, setFosList] = useState([]);
  const [listData, setListData] = useState();
  const [keyword, setKeyword] = useState();

  const charType = (char) => {
    switch (char) {
      case "R":
        return "The Doers";
      case "I":
        return "The Thinkers";
      case "A":
        return "The Creators";
      case "S":
        return "The Helpers";
      case "E":
        return "The Persuaders";
      case "C":
        return "The Organizer";
      default:
        return "Unique";
    }
  };

  const _api = auth.isAuth ? api : apiAnom;

  useEffect(() => {
    const getFieldOfStudy = () => {
      _api
        .get("/field-of-study")
        .then((res) => {
          setFosList(res.data.data);
        })
        .catch((_) => {
          setFosList(_);
        });
    };
    getFieldOfStudy();
  }, [_api, auth.isAuth]);

  useEffect(() => {
    const getMajors = () => {
      _api
        .post("/university/major/search", {
          name: keyword,
          field_of_studies: _.map(filterBidang, "id"),
          tmbs: [karakter ?? ""],
          page: currentPage,
          limit: pageSize,
          sort_direction: "DESC",
        })
        .then((res) => {
          setListData(res.data.data);
        })
        .catch((_) => {
          setListData([]);
        });
    };
    getMajors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuth, currentPage, filterBidang, karakter]);

  if (!listData) return <PageLoading />;

  const styles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "rgba(217, 217, 217, 0.5)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
    }),
    input: (provided) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: "1 !important",
      },
    }),
  };

  const onDeleteBidang = (e, bidang) => {
    e.preventDefault();
    e.stopPropagation();
    var selectedList = filterBidang.filter((item) => item.id !== bidang["id"]);
    setFilterBidang(selectedList);
    setCurrentPage(1);
  };

  const onDeleteKarakter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterKarakter("");
    setCurrentPage(1);
  };

  const onSearchByBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _api
      .post("/university/major/search", {
        name: keyword,
        field_of_studies: _.map(filterBidang, "id"),
        page: 1,
        limit: pageSize,
        sort_direction: "DESC",
      })
      .then((res) => {
        setListData(res.data.data);
        setCurrentPage(1);
      })
      .catch((_) => {
        setListData([]);
      });
  };
  return (
    <LayoutV2>
      <div>
        <h1 className="mb-1 text-2xl tablet:text-5xl font-bold">
          Explore Jurusan
        </h1>
        <p className="text-lg font-light text-gray-500">
          Telusuri berbagai kampus ternama sekarang!
        </p>
        <div className="mt-6 mb-6 flex justify-between items-center">
          <div className="w-full px-4 py-3 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg">
            <form className="flex flex-col sm:flex-row remove-input-txt-border">
              <input
                type="text"
                className="flex-1 sm:flex-2 form-input shadow-sm sm:text-sm sm:rounded-r-none"
                style={{
                  maxHeight: "38px",
                  backgroundColor: "rgba(217, 217, 217, 0.5)",
                }}
                placeholder="Cari jurusan berdasarkan nama..."
                onChange={(e) => setKeyword(e.target.value)}
              />
              <ReactSelect
                styles={styles}
                options={fosList}
                isMulti
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuColor
                getOptionLabel={(data) => data["name"]}
                getOptionValue={(data) => data["id"]}
                components={{
                  Option: DropdownItem,
                }}
                className="flex-1 shadow-sm sm:text-sm focus:border-none"
                placeholder="Bidang Studi"
                onChange={(v) => {
                  setFilterBidang(v);
                  setCurrentPage(1);
                }}
                value={filterBidang}
              />
              <Button
                onClick={onSearchByBtn}
                aria-controls="modal"
                variant="primary"
                style={{
                  maxHeight: "38px",
                }}
                className="rounded-md sm:rounded-r-md sm:rounded-l-none mt-2 sm:mt-0"
              >
                <span className="px-2.5">Cari</span>
              </Button>
            </form>
          </div>
        </div>
        <div className="mb-4">
          {filterBidang.length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Bidang Studi:</p>
              {filterBidang.map((bidang, index) => (
                <span
                  key={bidang["id"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{bidang["name"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteBidang(e, bidang)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
        <div className="mb-4">
          {karakter !== null && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Karakter:</p>
              <span className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900">
                <span> {` ${charType(karakter)}`}</span>
                <button
                  type="button"
                  className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                  onClick={(e) => onDeleteKarakter(e)}
                >
                  <span className="sr-only">Remove filter </span>
                  <svg
                    className="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </span>
            </div>
          )}
        </div>
        {!listData && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">Loading...</h3>
            </div>
          </div>
        )}
        {listData.majors.length < 1 && listData.majors && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">
                Pencarian data tidak di temukan
              </h3>
            </div>
          </div>
        )}
        <div className="pb-4 grid tablet:grid-cols-3 gap-4">
          {listData.majors.map((d, i) => {
            return (
              <div key={i} className="mb-4">
                <CardMajor
                  isFavorite={
                    listData.favorites.includes(parseInt(d.id)) ?? false
                  }
                  {...d}
                />
              </div>
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={listData.metadata.total_item}
          pageSize={listData.metadata.limit}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </LayoutV2>
  );
}
