import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";

export default function TMBResultCardV2({ screenWidth, data }) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );
  const charTypeIllustration = (code) => {
    switch (code) {
      case "R":
        return "/profile_tmb_illu/profile_tmb_doer.png";
      case "I":
        return "/profile_tmb_illu/profile_tmb_thinker.png";
      case "A":
        return "/profile_tmb_illu/profile_tmb_creator.png";
      case "S":
        return "/profile_tmb_illu/profile_tmb_helper.png";
      case "E":
        return "/profile_tmb_illu/profile_tmb_persuader.png";
      case "C":
        return "/profile_tmb_illu/profile_tmb_organizer.png";
      default:
        return "Unique";
    }
  };
  const charBGColor = (code) => {
    switch (code) {
      case "R":
        return "rgba(253, 207, 48, 1)";
      case "I":
        return "rgba(241, 243, 246, 1)";
      case "A":
        return "rgba(253, 82, 68, 1)";
      case "S":
        return "rgba(0, 0, 0, 1)";
      case "E":
        return "rgba(102, 46, 157, 1)";
      case "C":
        return "rgba(87, 194, 210, 1)";
      default:
        return "Unique";
    }
  };
  const charTextColor = (code) => {
    switch (code) {
      case "A":
        return "white";
      case "S":
        return "white";
      case "E":
        return "white";
      default:
        return "black";
    }
  };

  return (
    <button
      onClick={() => {
        navigate(
          auth.verified_at.split("-")[0] === "0001"
            ? "/profile-edit"
            : `/tes-minat-result/${data.id}`
        );
      }}
      className="relative rounded-xl"
      style={{
        minWidth: "363px",
        minHeight: "250px",
        boxShadow: "4px 4px 40px 0px rgba(64, 69, 79, 0.16)",
      }}
    >
      <div
        className="h-full bg-white absolute top-0 left-0 z-10 rounded-xl flex flex-col justify-start items-start"
        style={{
          width: "220px",
          paddingTop: "28.72px",
          paddingLeft: "28.72px",
          paddingRight: "9.05px",
        }}
      >
        <p
          className="text-left font-normal"
          style={{
            fontSize: "12.56px",
            lineHeight: "19.48px",
            color: "rgba(131, 130, 132, 1)",
          }}
        >
          {moment(data.created_at).format("DD MMMM YYYY | HH:mm")}
        </p>
        <p
          className="text-left font-semibold text-black188"
          style={{
            fontSize: "14.36px",
            lineHeight: "22.26px",
            marginTop: "21.54px",
          }}
        >
          Karaktermu
        </p>
        <div
          className="flex flex-col justify-start items-start"
          style={{ gap: "7.18px", marginTop: "14.36px" }}
        >
          {data.results.map((item, index) => (
            <div
              key={index}
              style={{
                paddingTop: "3.36px",
                paddingBottom: "3.36px",
                paddingLeft: "10.77px",
                paddingRight: "10.77px",
                backgroundColor: charBGColor(data.results[index].code),
                color: charTextColor(data.results[index].code),
                borderRadius: "7.18px",
              }}
            >
              {item.label_short}
            </div>
          ))}
        </div>
      </div>
      <img
        src={charTypeIllustration(data.results[0].code)}
        className="h-full bg-blue-300 absolute top-0 right-0 rounded-xl"
        style={{ width: "220px" }}
      />
    </button>
  );
}
