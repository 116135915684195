import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

export default function PretestSelectClass({
  submit,
  screenWidth,
  selectedClass,
}) {
  const auth = useRecoilValue(authState);
  const [selectedClassIndex, setSelectedClassIndex] = useState();
  const classOptions = [
    "Kelas 12",
    "Kelas 11",
    "Kelas 10",
    "< Kelas 10",
  ];
  const eduLevelIndex = (id) => {
    switch (id) {
      case 1:
        return 2;
      case 2:
        return 1;
      case 3:
        return 0;
      case 4:
        return 3;
      case 5:
        return 0;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (auth.isAuth) {
      setSelectedClassIndex(eduLevelIndex(auth.education_level_id));
    } else {
      if (selectedClass) {
        setSelectedClassIndex(eduLevelIndex(selectedClass));
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submit(e.target[4].value);
  };

  return (
    <div
      className="w-full h-full flex flex-col items-stretch"
      style={{
        minWidth: screenWidth >= 1024 && "630px",
        maxWidth: screenWidth >= 1024 && "630px",
      }}
    >
      <div className="relative z-20 w-full h-full flex flex-col flex-grow px-8 sm:px-0 items-stretch">
        <p className="text-left font-semibold text-base text-white">
          Sebelumnya, silahkan pilih kelas kamu saat ini:
        </p>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col flex-grow items-stretch relative"
          style={{
            marginTop: "13.89px",
            minWidth: screenWidth >= 1024 ? "630px" : "321px",
            maxWidth: screenWidth >= 1024 ? "630px" : "321px",
            minHeight: screenWidth >= 1024 ? "383.75px" : "269px",
            maxHeight: screenWidth >= 1024 ? "383.75px" : "269px",
          }}
        >
          <div
            className="flex flex-col justify-start items-stretch"
            style={{
              minWidth: screenWidth < 1024 ? "215.6px" : "431.64px",
              maxWidth: screenWidth < 1024 ? "215.6px" : "431.64px",
              gap: "11px",
            }}
          >
            {classOptions.map((item, index) => (
              <button
                type="submit"
                key={index}
                onClick={() => {
                  setSelectedClassIndex(index);
                }}
                className="w-full h-auto flex flex-row justify-start items-center rounded-full transform ease-in-out duration-300"
                style={{
                  gap: "14.09px",
                  backgroundColor:
                    index === selectedClassIndex
                      ? "rgba(255, 202, 45, 1)"
                      : "white",
                  padding: screenWidth < 1024 ? "4px" : "6px",
                  maxHeight: "70.42px",
                }}
              >
                <div
                  className="rounded-full transform ease-in-out duration-300"
                  style={{
                    backgroundColor:
                      index === selectedClassIndex
                        ? "white"
                        : "rgba(255, 202, 45, 1)",
                    padding: screenWidth < 1024 ? "9px" : "15px",
                    width: screenWidth < 1024 ? "36.82px" : "58.68px",
                    height: screenWidth < 1024 ? "36.82px" : "58.68px",
                  }}
                >
                  <div
                    className="w-full h-full rounded-full transform ease-in-out duration-300"
                    style={{
                      backgroundColor:
                        index === selectedClassIndex
                          ? "rgba(255, 202, 45, 1)"
                          : "white",
                    }}
                  />
                </div>
                <p
                  className={`text-left ${
                    index === selectedClassIndex ? "font-bold" : "font-medium"
                  } text-sm text-black`}
                >
                  {item}
                </p>
              </button>
            ))}
          </div>
          <input
            type="hidden"
            name="selected"
            value={classOptions[selectedClassIndex ?? 0]}
          />
          {/* <div
            className="flex w-full justify-end absolute"
            style={{
              bottom: "0px",
            }}
          >
            <button
              type="submit"
              disabled={!(selectedClassIndex >= 0)}
              className="w-max py-2 px-8 rounded-md text-center font-bold text-sm text-black1"
              style={{
                backgroundColor:
                  selectedClassIndex >= 0 ? "rgba(255, 202, 45, 1)" : "grey",
              }}
            >
              Lanjut
            </button>
          </div> */}
        </form>
      </div>
    </div>
  );
}
