import Layout from "../../components/Layout";
import LayoutV2 from "../../components/Layout_V2";

export default function ConfirmationEmailSent() {
  return (
    <LayoutV2>
      <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20">
        {/* Page header */}
        <div className="flex flex-col items-center max-w-3xl mx-auto text-center pb-12 tablet:pb-20 gap-y-5">
          <img src="/ic_email_sent.svg" alt="email" className="w-20 h-20" />
          <h2 className="h2 mb-6">Cek Emailmu!</h2>
          <div className="flex flex-col items-center gap-y-10 text-gray-600">
          <p className="max-w-lg text-left">
            Kami telah mengirimkan link ke alamat emailmu agar kamu dapat melanjutkan proses pembuatan password baru.
          </p>
          <p className="max-w-lg text-left">
            Yuk, ikuti langkah-langkahnya agar kamu bisa kembali mengeksplor berbagai kelas di exploration!
          </p>
          </div>
        </div>
      </div>
    </LayoutV2>
  )
}