import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { authState } from "../../atoms/authState";
import { api, apiAnom, notifError } from "../../utils/api";
import PageLoading from "../PageLoading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../../utils/utils";
import Button from "../../components/base/Button";
import { signInUpState } from "../../atoms/signInUpState";
import { RadioButton } from "../../components/RadioButton";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import countryJSON from "../../components/jsons/Countries.json";
import indoProvJSON from "../../components/jsons/IndonesiaProvinceWithCities.json";
import LayoutV2 from "../../components/Layout_V2";

export default function LaSalleAdmissionForm() {
  //   let { slug } = useParams();
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const [univData, setUnivData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [guardianFirstName, setGuardianFirstName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [guardianPhone, setGuardianPhone] = useState("");
  const [birthCountry, setBirthCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [occupationCountry, setOccupationCountry] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [highSchool, setHighSchool] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [lastGrade, setLastGrade] = useState("");
  const [otherLang, setOtherLang] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressProvince, setAddressProvince] = useState("");
  const [addressStreetNumber, setAddressStreetNumber] = useState("");
  const [address, setAddress] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [addressHomePhone, setAddressHomePhone] = useState("");
  const [addressSecondaryPhone, setAddressSecondaryPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCodeDropdown, setIsCodeDropdown] = useState("");
  const [isCodeDropdown2, setIsCodeDropdown2] = useState("");
  const [isCodeDropdown3, setIsCodeDropdown3] = useState("");
  const [isCodeDropdown4, setIsCodeDropdown4] = useState("");
  const [errors, setErrors] = useState({});
  const [disableChangePhone, setDisableChangePhone] = useState(false);
  const [countryCode, setCountryCode] = useState(getCountryCallingCode("ID"));
  const [countryCode2, setCountryCode2] = useState(getCountryCallingCode("ID"));
  const [countryCode3, setCountryCode3] = useState(getCountryCallingCode("ID"));
  const [countryCode4, setCountryCode4] = useState(getCountryCallingCode("ID"));
  const [countryList, setCountryList] = useState([]);
  const [provinceNCity, setProvinceNCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("Indonesia");
  const [selectedCitizenship, setSelectedCitizenship] = useState("Indonesia");
  const [selectedWorkCountry, setSelectedWorkCountry] = useState("Indonesia");
  const [selectedProvince, setSelectedProvince] = useState("DKI Jakarta");
  const [selectedCity, setSelectedCity] = useState("Pilih Kota");
  const [selectedAddressCountry, setSelectedAddressCountry] =
    useState("Indonesia");
  const [selectedAddressProvince, setSelectedAddressProvince] =
    useState("DKI Jakarta");
  const [selectedAddressCity, setSelectedAddressCity] = useState("Pilih Kota");
  const [startDate, setStartDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState("01/01/0001");

  useEffect(() => {
    setCountryList(countryJSON);
    setProvinceNCity(indoProvJSON);
    const getUnivDetails = async () => {
      await _api
        .get(`/explore-kampus-v2/${window.location.pathname.split("/")[2]}`)
        .then((res) => {
          setUnivData(res.data.data);
        })
        .catch((err) => {
          setUnivData({});
        });
    };

    if (!auth.isAuth) {
      setSign({ trySignIn: true, trySignUp: false });
    } else if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    } else {
      setLoading(true);
      setFullName(
        auth.fname && auth.lname ? auth.fname + " " + auth.lname : ""
      );
      setFirstName(auth.fname ? auth.fname : "");
      setLastName(auth.lname ? auth.lname : "");
      setEmail(auth.email);
      let newCountryCode;
      let newPhone;
      if (auth.phone.includes("-")) {
        newCountryCode = auth.phone.split("-")[0];
        newPhone = auth.phone.split("-")[1];
      } else {
        newCountryCode = getCountryCallingCode("ID");
        newPhone = auth.phone.slice(auth.phone.indexOf("8"));
      }
      setPhone(newPhone);
      setCountryCode(newCountryCode);
    }

    getUnivDetails().then(() => {
      setLoading(false);
    });
  }, []);

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          case "countryCode":
            isErr = !/()\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    if (!validate(elements)) return;
    setLoading(true);
    // building payload
    const newPhone = countryCode + "-" + phone;
    const newGuardianPhone = countryCode2 + "-" + guardianPhone;
    const newHomePhone = countryCode3 + "-" + addressHomePhone;
    const jsonForm = {
      uni_location: selectedUniLocation,
      regis_session: selectedRegisSession,
      program_type: selectedProgram,
      program_choice: selectedProgramStudi,
      first_name: firstName,
      last_name: lastName,
      date_of_birth: dateOfBirth,
      email: email,
      phone: newPhone,
      gender: selectedGender,
      native_language: selectedNativeLang,
      guardian_first_name: guardianFirstName,
      guardian_email: guardianEmail,
      guardian_phone: newGuardianPhone,
      birth_country: selectedCountry,
      province: selectedProvince,
      city: selectedCity,
      citizenship: selectedCitizenship,
      legal_status: selectedLegalStatus,
      address: {
        country: selectedAddressCountry,
        province: selectedAddressProvince,
        street_number: addressStreetNumber,
        street: address,
        city: selectedAddressCity,
        zip_code: addressZipCode,
        home_phone: newHomePhone,
        secondary_phone: addressSecondaryPhone,
      },
      principal_occupation: selectedOccupation,
      country_of_occupation: selectedWorkCountry,
      discipline: discipline,
      high_school_name: highSchool,
      graduation_year: graduationYear,
      last_grade_completed: lastGrade,
      language: selectedLang,
      other_language: otherLang,
    };
    const payload = {
      name: "",
      email: "",
      phone: "",
      uni_id: univData.id,
      univ_form_data: JSON.stringify(jsonForm),
    };
    _api
      .post("/students/univ-admission", payload)
      .then(() => {
        window.Swal.fire({
          title: "Success!",
          text: `Kamu telah mendaftar di ${univData.name}`,
          icon: "success",
          confirmButtonText: "Tutup",
          allowOutsideClick: false,
        }).then(() => {
          navigate(`/explore-kampus/${univData.slug}`);
        });
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  };

  const uniLocation = ["Jakarta", "Surabaya"];
  const regisSession = [
    "January 2023",
    "May 2023",
    "September 2023",
    "January 2024",
  ];
  const programType = ["Diploma", "Certificate", "D4", "S1"];
  const programChoices = [
    {
      type: "Diploma",
      choices: [
        "Fashion Design",
        "Fashion Business",
        "Graphic Design Diploma",
        "Game Art Design",
        "Photography",
        "Interior Design",
        "Management Creative Industries Diploma",
      ],
    },
    {
      type: "Certificate",
      choices: [
        "Pattern Maker",
        "Stylist/Illustrator",
        "Buyer",
        "Retail Manager",
        "Graphic Design Certificate",
        "Photography",
        "Interior Design",
        "Artistic Make Up Module 1 & 2",
        "Management Creative Industries Certificate",
      ],
    },
    {
      type: "D4",
      choices: [
        "Fashion Design D4 Standard",
        "Fashion Design D4 Bridging",
        "Fashion Merchandising D4 Standard",
        "Fashion Merchandising D4 Bridging",
      ],
    },
    {
      type: "S1",
      choices: [
        "Interior Design Bachelor Standard",
        "Interior Design Bachelor Bridging",
      ],
    },
  ];
  const nativeLang = ["English", "Indonesian", "Other"];
  const gender = ["Laki-laki", "Perempuan"];
  const legalStatus = [
    { status: ["Indonesian"] },
    {
      status: [
        "Indonesian",
        "Permanent Resident",
        "Temporary Resident",
        "License to Obtain",
        "Refugee",
        "Other",
      ],
    },
  ];
  const principalOccupation = ["Employee", "Student", "Other"];
  const language = ["English", "Indonesian", "Other"];

  const [selectedUniLocation, setSelectedUniLocation] = useState(
    uniLocation[0]
  );
  const [selectedRegisSession, setSelectedRegisSession] = useState(
    regisSession[0]
  );
  const [selectedProgram, setSelectedProgram] = useState(programType[0]);
  const [selectedProgramChoice, setSelectedProgramChoice] = useState(
    programChoices[0].choices
  );
  const [selectedProgramStudi, setSelectedProgramStudi] = useState(
    programChoices[0].choices[0]
  );
  const [selectedGender, setSelectedGender] = useState(gender[0]);
  const [selectedNativeLang, setSelectedNativeLang] = useState(nativeLang[0]);
  const [selectedLang, setSelectedLang] = useState(nativeLang[0]);
  const [selectedLegalStatus, setSelectedLegalStatus] = useState(
    legalStatus[0].status[0]
  );
  const [selectedOccupation, setSelectedOccupation] = useState(
    principalOccupation[0]
  );

  const nativeLangRadioChangeHandler = (e) => {
    setSelectedNativeLang(e.target.value);
  };

  const legalStatusRadioChangeHandler = (e) => {
    setSelectedLegalStatus(e.target.value);
  };

  const occupationRadioChangeHandler = (e) => {
    setSelectedOccupation(e.target.value);
  };

  const langRadioChangeHandler = (e) => {
    setSelectedLang(e.target.value);
  };

  if (isLoading) {
    <PageLoading />;
  }
  return (
    <LayoutV2>
      <p className="text-center font-bold text-3xl hd:text-48px text-black1 mt-4 hd:mt-20">
        Isi Formulir Pendaftaran
      </p>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-8 px-4 hd:px-20 mt-12 pb-20 overflow-auto"
      >
        {/* <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Nama Lengkap <span className={"text-red-500"}>*</span>
          </label>
          <input
            id="fullName"
            type="text"
            name="fullName"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan nama lengkap"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama Lengkap harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div> */}
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="uni_location"
          >
            Lokasi Kampus <span className={"text-red-500"}>*</span>
          </label>
          <select
            id="education_level"
            placeholder={"Pilih Lokasi Kampus"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            aria-label="education level"
            name="education_level_id"
            value={selectedUniLocation}
            onChange={(e) => {
              const location = e.target.value;
              setSelectedUniLocation(location);
            }}
            required
          >
            {uniLocation.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Sesi Pendaftaran <span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Sesi Pendaftaran"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedRegisSession}
            onChange={(e) => {
              const session = e.target.value;
              setSelectedRegisSession(session);
            }}
            required
          >
            {regisSession.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div> */}
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Tipe Program <span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Tipe Program"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedProgram}
            onChange={(e) => {
              const program = e.target.value;
              setSelectedProgram(program);
              setSelectedProgramChoice(
                programChoices[
                  programChoices.findIndex((item) => item.type === program)
                ].choices
              );
            }}
            required
          >
            {programType.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Program Studi <span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Program Studi"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedProgramStudi}
            onChange={(e) => {
              setSelectedProgramStudi(e.target.value);
            }}
            required
          >
            {selectedProgramChoice.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Nama Depan <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan Nama Depan"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama depan harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Nama Belakang <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan Nama Belakang"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama belakang harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Tanggal Lahir <span className={"text-red-500"}>*</span>
          </label>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Cth: 20-01-2001"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama belakang harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setDateOfBirth(
                moment(date).tz("Asia/Jakarta").format("DD/MM/YYYY")
              );
            }}
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="email"
          >
            Email <span className={"text-red-500"}>*</span>
          </label>
          <input
            id="email"
            type="text"
            name="email"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) => F.target.setCustomValidity("Email harus diisi")}
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="phone"
          >
            No. HP Siswa <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-row w-full justify-start items-center mt-4">
            <div
              className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
              style={{
                height: "50px",
                border: "1px solid #E0E0E1",
              }}
              onClick={() => {
                setIsCodeDropdown(!isCodeDropdown);
              }}
            >
              <div className="border-0 w-12 h:w-18 pl-1">
                {`+${countryCode}`}
              </div>
              <ChevronDownIcon
                className="h-5 w-auto p-0 m-0"
                style={{ paddingTop: "2px" }}
              />
              {isCodeDropdown && (
                <div
                  className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10"
                  //   style={{ maxHeight: "344px" }}
                >
                  {/* <option value="">{en["ZZ"]}</option> */}
                  {getCountries().map((country) => (
                    <button
                      key={country}
                      value={country}
                      className="text-left px-4 pt-2 w-max"
                      onClick={() => {
                        setIsCodeDropdown(false);
                        setCountryCode(getCountryCallingCode(country));
                      }}
                    >
                      {en[country]}
                      {/* {labels[country]} +{getCountryCallingCode(country)} */}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              id="phone"
              type="number"
              name="phone"
              accept="phone"
              className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                mapHasKeyAndValueNotEmpty(errors, "phone")
                  ? "border-red-400"
                  : ""
              }`}
              placeholder="cth. 8XXXXXXXXXXXX"
              value={phone}
              onChange={(e) => {
                if (phone[0] === "0") {
                  setPhone(e.target.value.slice(1));
                } else {
                  setPhone(e.target.value);
                }
              }}
              onBlur={() => {
                if (phone[0] === "0") {
                  setPhone(phone.slice(1));
                }
              }}
              disabled={disableChangePhone}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("No. HP harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Jenis Kelamin <span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Tipe Program"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedGender}
            onChange={(e) => {
              const program = e.target.value;
              setSelectedGender(program);
            }}
            required
          >
            {gender.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Bahasa Asli <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-4">
            {nativeLang.map((item, index) => (
              <div key={index}>
                <RadioButton
                  onClicked={nativeLangRadioChangeHandler}
                  id={index + 1}
                  isSelected={selectedNativeLang === item}
                  label={item}
                  value={item}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Nama Depan Wali <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nama depan wali"
            value={guardianFirstName}
            onChange={(e) => setGuardianFirstName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama depan wali harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Email Wali <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan email wali"
            value={guardianEmail}
            onChange={(e) => setGuardianEmail(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Email wali harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="phone"
          >
            No. HP Wali <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-row w-full justify-start items-center mt-4">
            <div
              className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
              style={{
                height: "50px",
                border: "1px solid #E0E0E1",
              }}
              onClick={() => {
                setIsCodeDropdown2(!isCodeDropdown2);
              }}
            >
              <div className="border-0 w-12 h:w-18 pl-1">
                {`+${countryCode2}`}
              </div>
              <ChevronDownIcon
                className="h-5 w-auto p-0 m-0"
                style={{ paddingTop: "2px" }}
              />
              {isCodeDropdown2 && (
                <div className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10">
                  {getCountries().map((country) => (
                    <button
                      key={country}
                      value={country}
                      className="text-left px-4 pt-2 w-max"
                      onClick={() => {
                        setIsCodeDropdown2(false);
                        setCountryCode2(getCountryCallingCode(country));
                      }}
                    >
                      {en[country]}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              id="guardianPhone"
              type="number"
              name="guardianPhone"
              accept="guardianPhone"
              className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                mapHasKeyAndValueNotEmpty(errors, "guardianPhone")
                  ? "border-red-400"
                  : ""
              }`}
              placeholder="cth. 8XXXXXXXXXXXX"
              value={guardianPhone}
              onChange={(e) => {
                if (guardianPhone[0] === "0") {
                  setGuardianPhone(e.target.value.slice(1));
                } else {
                  setGuardianPhone(e.target.value);
                }
              }}
              onBlur={() => {
                if (guardianPhone[0] === "0") {
                  setGuardianPhone(guardianPhone.slice(1));
                }
              }}
              disabled={disableChangePhone}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("No. HP wali harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="alamat_lengkap"
          >
            Negara Kelahiran<span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Negara"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedCountry}
            onChange={(e) => {
              const country = e.target.value;
              setSelectedCountry(country);
            }}
            required
          >
            {countryList.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        {selectedCountry === "Indonesia" && (
          <div className="flex flex-col justify-start items-start">
            <label
              className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
              htmlFor="alamat_lengkap"
            >
              Provinsi<span className={"text-red-500"}>*</span>
            </label>
            <select
              placeholder={"Pilih Provinsi"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedProvince}
              onChange={(e) => {
                const province = e.target.value;
                setSelectedProvince(province);
              }}
              required
            >
              {provinceNCity.map((item, index) => (
                <option key={index} value={item.provinsi}>
                  {item.provinsi}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedCountry === "Indonesia" && (
          <div className="flex flex-col justify-start items-start">
            <label
              className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
              htmlFor="alamat_lengkap"
            >
              Kota<span className={"text-red-500"}>*</span>
            </label>
            <select
              placeholder={"Pilih Kota"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedCity}
              onChange={(e) => {
                const city = e.target.value;
                setSelectedCity(city);
              }}
              required
            >
              {provinceNCity.length > 0 &&
                provinceNCity
                  .filter((element) => element.provinsi === selectedProvince)[0]
                  .kota.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
            </select>
          </div>
        )}
        {/* <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Negara Kelahiran <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan negara kelahiran"
            value={birthCountry}
            onChange={(e) => setBirthCountry(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Negara kelahiran harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Provinsi <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan provinsi"
            value={province}
            onChange={(e) => setProvince(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Provinsi harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Kota <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan kota"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) => F.target.setCustomValidity("Kota harus diisi")}
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div> */}
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="citizenship"
          >
            Kewarnegaraan <span className={"text-red-500"}>*</span>
          </label>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Cth. Indonesia, Kanada, Australia"
            value={citizenship}
            onChange={(e) => setCitizenship(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Kewarganegaraan harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <select
            placeholder={"Pilih Negara"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedCitizenship}
            onChange={(e) => {
              const country = e.target.value;
              setSelectedCitizenship(country);
            }}
            required
          >
            {countryList.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Status Kewarganegaraan <span className={"text-red-500"}>*</span>
          </label>
          {selectedCitizenship !== "Indonesia" ? (
            <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-4">
              {legalStatus[1].status.map((item, index) => (
                <div key={index}>
                  <RadioButton
                    onClicked={legalStatusRadioChangeHandler}
                    id={"legal" + index}
                    isSelected={selectedLegalStatus === item}
                    label={item}
                    value={item}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-row gap-8 mt-4">
              {legalStatus[0].status.map((item, index) => (
                <div key={index}>
                  <RadioButton
                    onClicked={legalStatusRadioChangeHandler}
                    id={index + 1}
                    isSelected={selectedLegalStatus === item}
                    label={item}
                    value={item}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="alamat_lengkap"
          >
            Alamat Lengkap<span className={"text-red-500"}>*</span>
          </label>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan negara"
            value={addressCountry}
            onChange={(e) => setAddressCountry(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) => F.target.setCustomValidity("Negara harus diisi")}
            onInput={(F) => F.target.setCustomValidity("")}
          />
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan provinsi"
            value={addressProvince}
            onChange={(e) => setAddressProvince(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Provinsi harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}

          <select
            placeholder={"Pilih Negara"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedAddressCountry}
            onChange={(e) => {
              const country = e.target.value;
              setSelectedAddressCountry(country);
            }}
            required
          >
            {countryList.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
          {selectedAddressCountry === "Indonesia" && (
            <select
              placeholder={"Pilih Provinsi"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedAddressProvince}
              onChange={(e) => {
                const province = e.target.value;
                setSelectedAddressProvince(province);
              }}
              required
            >
              {provinceNCity.map((item, index) => (
                <option key={index} value={item.provinsi}>
                  {item.provinsi}
                </option>
              ))}
            </select>
          )}
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nomor rumah"
            value={addressStreetNumber}
            onChange={(e) => setAddressStreetNumber(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nomor rumah harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nama jalan"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama jalan harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan kota"
            value={addressCity}
            onChange={(e) => setAddressCity(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) => F.target.setCustomValidity("Kota harus diisi")}
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          {selectedAddressCountry === "Indonesia" && (
            <select
              placeholder={"Pilih Kota"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedAddressCity}
              onChange={(e) => {
                const city = e.target.value;
                setSelectedAddressCity(city);
              }}
              required
            >
              {provinceNCity.length > 0 &&
                provinceNCity
                  .filter(
                    (element) => element.provinsi === selectedAddressProvince
                  )[0]
                  .kota.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
            </select>
          )}
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan kode pos"
            value={addressZipCode}
            onChange={(e) => setAddressZipCode(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Kode pos harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nomor telepon rumah"
            value={addressHomePhone}
            onChange={(e) => setAddressHomePhone(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nomor telepon rumah harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <div className="flex flex-row w-full justify-start items-center mt-4">
            <div
              className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
              style={{
                height: "50px",
                border: "1px solid #E0E0E1",
              }}
              onClick={() => {
                setIsCodeDropdown3(!isCodeDropdown3);
              }}
            >
              <div className="border-0 w-12 h:w-18 pl-1">
                {`+${countryCode3}`}
              </div>
              <ChevronDownIcon
                className="h-5 w-auto p-0 m-0"
                style={{ paddingTop: "2px" }}
              />
              {isCodeDropdown3 && (
                <div
                  className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10"
                  //   style={{ maxHeight: "344px" }}
                >
                  {/* <option value="">{en["ZZ"]}</option> */}
                  {getCountries().map((country) => (
                    <button
                      key={country}
                      value={country}
                      className="text-left px-4 pt-2 w-max"
                      onClick={() => {
                        setIsCodeDropdown3(false);
                        setCountryCode3(getCountryCallingCode(country));
                      }}
                    >
                      {en[country]}
                      {/* {labels[country]} +{getCountryCallingCode(country)} */}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              id="addressHomePhone"
              type="number"
              name="addressHomePhone"
              accept="phaddressHomePhoneone"
              className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                mapHasKeyAndValueNotEmpty(errors, "addressHomePhone")
                  ? "border-red-400"
                  : ""
              }`}
              placeholder="Masukkan nomor telepon rumah"
              value={addressHomePhone}
              onChange={(e) => {
                if (addressHomePhone[0] === "0") {
                  setAddressHomePhone(e.target.value.slice(1));
                } else {
                  setAddressHomePhone(e.target.value);
                }
              }}
              onBlur={() => {
                if (addressHomePhone[0] === "0") {
                  setAddressHomePhone(addressHomePhone.slice(1));
                }
              }}
              disabled={disableChangePhone}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("No. telepon rumah harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan nomor telepon alternatif"
            value={addressSecondaryPhone}
            onChange={(e) => setAddressSecondaryPhone(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity(
                "Nomor telepon alternatif jalan harus diisi"
              )
            }
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <div className="flex flex-row w-full justify-start items-center mt-4">
            <div
              className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
              style={{
                height: "50px",
                border: "1px solid #E0E0E1",
              }}
              onClick={() => {
                setIsCodeDropdown4(!isCodeDropdown4);
              }}
            >
              <div className="border-0 w-12 h:w-18 pl-1">
                {`+${countryCode4}`}
              </div>
              <ChevronDownIcon
                className="h-5 w-auto p-0 m-0"
                style={{ paddingTop: "2px" }}
              />
              {isCodeDropdown4 && (
                <div
                  className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10"
                  //   style={{ maxHeight: "344px" }}
                >
                  {/* <option value="">{en["ZZ"]}</option> */}
                  {getCountries().map((country) => (
                    <button
                      key={country}
                      value={country}
                      className="text-left px-4 pt-2 w-max"
                      onClick={() => {
                        setIsCodeDropdown4(false);
                        setCountryCode4(getCountryCallingCode(country));
                      }}
                    >
                      {en[country]}
                      {/* {labels[country]} +{getCountryCallingCode(country)} */}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              id="addressAltPhone"
              type="number"
              name="addressAltPhone"
              accept="phaddressAltPhoneone"
              className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                mapHasKeyAndValueNotEmpty(errors, "addressAltPhone")
                  ? "border-red-400"
                  : ""
              }`}
              placeholder="Masukkan nomor telepon alternatif"
              value={addressSecondaryPhone}
              onChange={(e) => {
                if (addressSecondaryPhone[0] === "0") {
                  setAddressSecondaryPhone(e.target.value.slice(1));
                } else {
                  setAddressSecondaryPhone(e.target.value);
                }
              }}
              onBlur={() => {
                if (addressSecondaryPhone[0] === "0") {
                  setAddressSecondaryPhone(addressSecondaryPhone.slice(1));
                }
              }}
              disabled={disableChangePhone}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("No. telepon alternatif harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Pekerjaan Utama <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-4">
            {principalOccupation.map((item, index) => (
              <div key={index}>
                <RadioButton
                  onClicked={occupationRadioChangeHandler}
                  id={"occupation" + index}
                  isSelected={selectedOccupation === item}
                  label={item}
                  value={item}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="principal_occupation"
          >
            Negara Tempat Bekerja <span className={"text-red-500"}>*</span>
          </label>
          {/* <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan Negara"
            value={occupationCountry}
            onChange={(e) => setOccupationCountry(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) => F.target.setCustomValidity("Negara harus diisi")}
            onInput={(F) => F.target.setCustomValidity("")}
          /> */}
          <select
            placeholder={"Pilih Negara"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedWorkCountry}
            onChange={(e) => {
              const country = e.target.value;
              setSelectedWorkCountry(country);
            }}
            required
          >
            {countryList.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="discipline"
          >
            Bidang/Jurusan Pekerjaan <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan Bidang/Jurusan Pekerjaan"
            value={discipline}
            onChange={(e) => setDiscipline(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Bidang/Jurusan Pekerjaan harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="discipline"
          >
            Nama Sekolah Menengah Atas <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan Nama SMA"
            value={highSchool}
            onChange={(e) => setHighSchool(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama SMA harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="discipline"
          >
            Tahun Kelulusan <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan tahun kelulusan"
            value={graduationYear}
            onChange={(e) => setGraduationYear(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Tahun kelulusan harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="last_grade"
          >
            Pendidikan Terakhir <span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan pendidikan terakhir"
            value={lastGrade}
            onChange={(e) => setLastGrade(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Pendidikan terakhir harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Bahasa<span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-4">
            {nativeLang.map((item, index) => (
              <div key={index}>
                <RadioButton
                  onClicked={langRadioChangeHandler}
                  id={"lang" + index}
                  isSelected={selectedLang === item}
                  label={item}
                  value={item}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="other_lang"
          >
            Bahasa Lainnya<span className={"text-red-500"}>*</span>
          </label>
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan bahasa lainnya"
            value={otherLang}
            onChange={(e) => setOtherLang(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Bahasa lainnya terakhir harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>

        {/* Submit Button */}
        <div className="flex flex-wrap px-3 -mx-3 mt-10 w-full justify-center">
          <Button
            disabled={isLoading}
            type="submit"
            className="w-max rounded-lg"
          >
            Simpan
          </Button>
        </div>
      </form>
    </LayoutV2>
  );
}
