import { MdArrowBack } from "react-icons/md";

export default function StepTwoV2({
  onBack,
  onNext,
  screenWidth,
  miniQuizStandalone,
  fromMiniQuiz,
  isFunQuiz,
}) {
  return (
    <div className="flex flex-col flex-1 w-full h-full bg-transparent relative">
      <div className="w-full h-full flex flex-col justify-start items-center">
        <div
          className={`flex w-full h-auto flex-row ${
            miniQuizStandalone ? "justify-end" : "justify-between"
            // !miniQuizStandalone
            //   ? !isFunQuiz
            //     ? "justify-between"
            //     : "justify-end"
            //   : "justify-between"
          } items-start`}
          style={{
            marginTop: screenWidth < 1024 ? "46px" : "30px",
            paddingLeft: screenWidth < 1024 ? "22px" : "113.25px",
            paddingRight: screenWidth < 1024 ? "17px" : "60px",
          }}
        >
          <button
            className={`${miniQuizStandalone && "hidden"}`}
            disabled={miniQuizStandalone}
            onClick={onBack}
          >
            <MdArrowBack
              className="w-6 sm:w-10 h-auto relative z-50"
              style={{ color: "white" }}
            />
          </button>
          <div
            className="h-auto flex flex-row justify-between items-center"
            style={{
              paddingTop: "5.29px",
              paddingBottom: "4.96px",
              paddingLeft: "19.86px",
              paddingRight: "7.23px",
              borderRadius: screenWidth < 1024 ? "22.66px" : "30px",
              borderWidth: "2px",
              borderColor: "rgba(255, 202, 45, 1)",
              gap: "9.14px",
              maxHeight: screenWidth >= 1024 && "53.33px",
            }}
          >
            <p className="text-left font-bold text-xs sm:text-base text-white">
              Estimasi Waktu Pengerjaan
            </p>
            <p
              className="text-center font-semibold text-xs sm:text-base text-black px-3 sm:px-4 sm:py-3 bg-white"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                borderRadius: "22.66px",
                maxHeight: screenWidth >= 1024 && "40.89px",
              }}
            >
              15 menit
            </p>
          </div>
        </div>
        <div
          className="flex flex-col items-center w-full h-auto text-left font-bold text-base sm:text-3xl text-white"
          style={{
            maxHeight: "201px",
            maxWidth: `${screenWidth < 1024 && "287px"}`,
            lineHeight: `${screenWidth < 1024 ? "20px" : "40px"}`,
            marginTop: screenWidth < 1024 ? "45px" : "12px",
          }}
        >
          <div
            className="flex flex-col w-full h-auto justify-center items-center relative"
            style={{
              maxWidth: "549.33px",
            }}
          >
            <p className="text-center font-bold text-base sm:text-xl text-white">
              Tes Minat
            </p>
            <img
              src="/illu_curved_underline.svg"
              style={{ maxWidth: "128.44px" }}
            />
          </div>
          <div
            className="flex flex-col items-center text-left font-semibold text-base text-white relative z-50 mt-6"
            style={{
              lineHeight: "20.16px",
              maxWidth: "549.33px",
            }}
          >
            {screenWidth < 1024 ? (
              <p>
                Kita akan menjelajahi galaxy minat untuk mencari tahu karakter
                kuat di dalam dirimu.
                <br />
                <br />
                Kencangkan sabuk pengaman,
                <br />
                dan ayo meluncur!
              </p>
            ) : (
              <p>
                Kita akan menjelajahi galaxy minat untuk mencari tahu karakter
                kuat di dalam dirimu.
                <br />
                <br />
                Kencangkan sabuk pengamanmu, dan ayo meluncur!
              </p>
            )}
            <div
              className="flex justify-end"
              style={{
                width: screenWidth < 1024 ? "100%" : "549.33px",
              }}
            >
              <button
                onClick={onNext}
                className="justify-center text-base text-black font-semibold relative z-30 mt-6"
                style={{
                  background: "rgba(255, 202, 45, 1)",
                  lineHeight: "20.16px",
                  minWidth: "132px",
                  minHeight: "36px",
                  borderRadius: "7.51px",
                }}
              >
                Mulai
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-screen justify-center h-full absolute z-0 bottom-0 sm:-bottom-20">
        {screenWidth >= 1024 ? (
          <img
            src="/illu_tmb_revamp.svg"
            className="absolute bottom-12"
            style={{ maxWidth: "612.01px" }}
          />
        ) : (
          <img
            src="/illu_tmb_revamp_mobile.svg"
            className="absolute bottom-0"
          />
        )}
      </div>
    </div>
  );
}
