import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import LayoutV2 from "../../components/Layout_V2";

export default function ConfirmationRegistration() {
  return (
    <LayoutV2>
      <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20">
        {/* Page header */}
        <div className="max-w-3xl mx-auto text-center pb-12 tablet:pb-20">
          <h1 className="h1 mb-6">Akun kamu telah teraktivasi</h1>
          <h3 className="mb-2">
            Silahkan masuk ke akunmu dan mulai telusuri kelas
          </h3>
          <p>
            Klik untuk{" "}
            <Link className="underline" to="/signin">
              masuk
            </Link>{" "}
            ke akunmu
          </p>
        </div>
      </div>
    </LayoutV2>
  );
}
