import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { authState } from "../../atoms/authState";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import LayoutV2 from "../../components/Layout_V2";

export default function TesMinatResult({ screenWidth, screenHeight }) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);

  useEffect(() => {
    if (!auth.isAuth) {
      navigate("/signin");
    }
  }, []);

  return (
    <LayoutV2 padding="0px">
      <div
        className="w-full flex flex-1 flex-col justify-start items-center relative"
        style={{
          backgroundImage: `url(${
            screenWidth < 1024
              ? "/illu_tmb_new.png"
              : "/illu_tmb_new_desktop.svg"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p
          className="absolute text-center font-bold text-xs sm:text-sm"
          style={{
            top: screenWidth < 1024 ? "71px" : "40px",
            left: "50%",
            transform: "translate(-50%, 0%)",
            maxWidth: screenWidth < 1024 ? "227px" : screenWidth,
            color: "rgba(255, 207, 45, 1)",
            lineHeight: screenWidth < 1024 ? "18px" : "21.33px",
            letterSpacing: "2px",
          }}
        >
          RESULT PAGE {screenWidth >= 1024 && <br />} KARAKTER & REKOMENDASI
          JURUSAN
        </p>
        <div
          className="absolute flex flex-row justify-center sm:justify-between items-start gap-8 sm:gap-0"
          style={{
            top: screenWidth < 1024 ? "206px" : "72px",
            left: "50%",
            transform: "translate(-50%, 0%)",
            width: screenWidth < 1024 ? "332px" : "945.78px",
          }}
        >
          <div
            className="flex flex-col items-center justify-start"
            style={{ gap: "22px" }}
          >
            <p
              className="text-center font-bold text-xl text-white"
              style={{
                width: screenWidth < 1024 ? "156px" : "212.44px",
                lineHeight: screenWidth < 1024 ? "24px" : "42.67px",
                letterSpacing: screenWidth < 1024 ? "-0.23px" : "0.8px",
                fontSize: screenWidth >= 1024 && "35.56px",
              }}
            >
              Yuk kenalin <span className="text-mustard2">karaktermu</span>!
            </p>
            <button
              onClick={() => {
                navigate(
                  `/tmb-result-detail/karakter/${
                    window.location.pathname.split("/")[2]
                  }`
                );
              }}
              className="w-12 h-12 sm:w-26 sm:h-26 rounded-full border-3 sm:border-6 border-white flex justify-center items-center"
            >
              <img
                src="/icon_theatre.svg"
                className="object-contain h-auto"
                style={{
                  width: screenWidth < 1024 ? "23px" : "57px",
                }}
              />
            </button>
          </div>
          <div
            className="flex flex-col items-center justify-start"
            style={{ gap: "22px" }}
          >
            <p
              className="text-center font-bold text-xl text-white"
              style={{
                width: screenWidth < 1024 ? "156px" : "212.44px",
                lineHeight: screenWidth < 1024 ? "24px" : "42.67px",
                letterSpacing: screenWidth < 1024 ? "-0.23px" : "0.8px",
                fontSize: screenWidth >= 1024 && "35.56px",
              }}
            >
              Yuk kenalin <span className="text-primaryColor">jurusanmu</span>!
            </p>
            <button
              onClick={() => {
                navigate(
                  `/tmb-result-detail/jurusan/${
                    window.location.pathname.split("/")[2]
                  }`
                );
              }}
              className="w-12 h-12 sm:w-26 sm:h-26 rounded-full border-3 sm:border-6 border-black flex justify-center items-center"
            >
              <img
                src="/icon_toga.svg"
                className="object-contain h-auto"
                style={{
                  width: screenWidth < 1024 ? "25.67px" : "59px",
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
