import { ArrowLeftIcon } from "@heroicons/react/solid";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { apiRM, notifError } from "../../../utils/api";

export default function RMSubRegionOriginRM({ submit, back }) {
  const [regionOriginRM, setRegionOriginRM] = useState("");
  const [branchSuggestion, setBranchSuggestion] = useState(false);
  const [isValidRegion, setIsValidRegion] = useState(false);
  const [ocbcRegions, setOcbcRegions] = useState();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Cookies.get("regionOriginRM")) {
      const fetchData = () => {
        setRegionOriginRM(Cookies.get("regionOriginRM"));
        setIsValidRegion(true);
      };

      fetchData();
    }

    apiRM
      .get("/rm/branch/list")
      .then((res) => {
        setIsLoading(false);
        setOcbcRegions(res.data.data);
        if (!Cookies.get("regionOriginRM")) {
          inputRef.current.focus();
          setBranchSuggestion(true);
        } else {
          inputRef.current.focus();
        }
      })
      .catch(notifError);
  }, []);

  useEffect(() => {
    if (ocbcRegions) {
      setIsValidRegion(
        ocbcRegions.some((branch) =>
          regionOriginRM?.toLowerCase().includes(branch.toLowerCase())
        )
      );
    }
  }, [regionOriginRM]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submit(e.target[1].value);
  };

  const handleEnterKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isValidRegion) {
      submit(e.target.value);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-end w-full">
      <button
        disabled={isLoading}
        onClick={back}
        className="w-full justify-start"
      >
        <ArrowLeftIcon className="w-6 h-6 text-white font-semibold" />
      </button>
      <label
        className="w-full text-left font-bold text-base text-white mt-20 tablet:mt-50"
        htmlFor="region_origin"
      >
        Asal Region Anda
      </label>
      <input
        ref={inputRef}
        className="form-input flex w-full mt-3 rounded-md text-center font-normal text-xs text-black py-2"
        style={{ height: "30px" }}
        id="region_origin"
        type="region_origin"
        name="region_origin"
        value={regionOriginRM}
        onFocus={() => {
          if (regionOriginRM === "") {
            setRegionOriginRM("Region");
          }
        }}
        onClick={() => {
          setBranchSuggestion(true);
        }}
        onChange={(e) => {
          setRegionOriginRM(e.target.value);
          setBranchSuggestion(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            // handleEnterKey(e);
          }
        }}
        placeholder="Pilih region Anda"
        autoComplete="off"
        required
      />
      <div className="flex w-full relative">
        {branchSuggestion && regionOriginRM.length > 0 && (
          <div
            className="absolute z-10 top-0 flex flex-col items-start w-full overflow-auto shadow-lg px-0 bg-white gap-1 rounded-lg mt-2"
            style={{ maxHeight: "200px" }}
          >
            {ocbcRegions
              ?.filter((item) =>
                item.toLowerCase().includes(regionOriginRM.toLowerCase())
              )
              .map((item, index) => (
                <button
                  key={index}
                  className="font-normal text-lg text-black1 text-left w-full justify-start px-3"
                  onClick={() => {
                    setBranchSuggestion(false);
                    setRegionOriginRM(item);
                    setIsValidRegion(true);
                  }}
                >
                  {item}
                </button>
              ))}
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between items-start w-full mt-2">
        <p className="text-left font-normal text-sm text-white italic leading-4">
          Contoh:
          <br /> Region 1 - AH-1
        </p>
        <button
          type="submit"
          disabled={!isValidRegion && !isLoading}
          className="w-max py-2 px-8 rounded-md text-center font-bold text-sm text-black1 mt-1"
          style={{
            backgroundColor: !isValidRegion ? "gray" : "rgba(255, 202, 45, 1)",
          }}
        >
          Next
        </button>
      </div>
    </form>
  );
}
