import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { apiRM, notifError } from "../../../utils/api";
import PageLoading from "../../PageLoading";
import Cookies from "js-cookie";

export default function RMSubClassProgram({ submit, back }) {
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );
  const api = apiRM;
  const [selectedClass, setSelectedClass] = useState();
  const [showClassDetailIndex, setShowClassDetailIndex] = useState();
  const [classPrograms, setClassPrograms] = useState();

  useEffect(() => {
    if (Cookies.get("classProgramIndex")) {
      setSelectedClass(parseInt(Cookies.get("classProgramIndex")));
      setShowClassDetailIndex(parseInt(Cookies.get("classProgramIndex")));
    }

    api
      .get("/rm/classes/exclusive")
      .then((res) => {
        setClassPrograms(res.data.data);
      })
      .catch(notifError);
  }, []);

  if (!classPrograms) {
    return <PageLoading />;
  }

  return (
    <form
      onSubmit={submit}
      className="flex flex-col flex-grow justify-start items-end w-full"
    >
      <label
        className="w-full text-left font-bold text-base text-white"
        htmlFor="class_program"
      >
        Pilih Program Kelas
      </label>
      <div className="flex flex-col w-full h-4/5 overflow-y-scroll overflow-x-hidden justify-start items-center gap-5 mt-6">
        {classPrograms.map((item, index) => {
          let baseOccupancy = item.subscriber / item.capacity;
          let occupancy = baseOccupancy;

          if (baseOccupancy > 0 && baseOccupancy < 0.6) {
            occupancy = baseOccupancy + 0.05;
          }
          const occupancyStatus = (occupancy) => {
            if (occupancy === 0) {
              return "Tersedia";
            }
            if (occupancy > 0 && occupancy <= 0.3) {
              return "Tersedia";
            }
            if (occupancy > 0.3 && occupancy <= 0.6) {
              return "Lagi diminati";
            }
            if (occupancy > 0.6 && occupancy < 1) {
              return "Segera penuh 🔥";
            }
            if (occupancy === 1) {
              return "Penuh 🔥";
            }
          };

          return (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowClassDetailIndex(index);
              }}
              key={index}
              className="flex flex-col w-full items-start justify-center rounded-xl bg-white relative transform transition-transform ease-in-out duration-150"
              style={{
                // minWidth: "285px",
                maxWidth: "285px",
                cursor: "pointer",
              }}
            >
              {index === showClassDetailIndex && (
                <div className="flex flex-col w-full justify-start items-end">
                  <img
                    src={item.img_url}
                    className="object-cover rounded-t-xl w-full"
                    style={{ maxHeight: "111px" }}
                  />
                  <div className="relative">
                    <img
                      src="/vec_exclass_banner.svg"
                      className="object-contain object-right w-max"
                      style={{ maxHeight: "26px" }}
                    />
                    <div className="absolute top-1" style={{ right: "10px" }}>
                      <p className="text-right font-semibold text-xss text-white relative">
                        Summer Camp Exclusive
                        <img
                          src="/star-4point.svg"
                          className="absolute -right-2 top-0"
                          style={{ maxWidth: "11px", maxHeight: "10px" }}
                        />
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-end items-center absolute"
                      style={{ right: "152px", top: "7px" }}
                    >
                      <img
                        src="/icon_person.svg"
                        className="w-2 h-2 object-contain"
                      />
                      <p
                        className="text-right font-medium text-black"
                        style={{ fontSize: "8px" }}
                      >
                        {item.subscriber}
                        {"/"}
                        {item.capacity}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col w-full justify-start items-stretch py-4 pl-5 pr-4">
                <div className="flex flex-row justify-start items-center gap-2">
                  <img
                    src={item.logo_url}
                    className="object-contain"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <p className="text-left font-normal text-xs text-black188">
                    {item.university_name}
                  </p>
                </div>
                <p
                  className="text-left font-bold text-base text-black1"
                  style={{ marginTop: "10px" }}
                >
                  {item.topic}
                </p>
                <p
                  className="text-left font-normal text-xs text-black1"
                  style={{ marginTop: "10px" }}
                >
                  {`Oleh: ${item.lecturer ? item.lecturer : "-"}`}
                </p>
              </div>
              {index === showClassDetailIndex && (
                <div className="flex flex-col w-full justify-start items-stretch pb-4 pl-5 pr-4">
                  <div className="flex flex-row justify-start items-center gap-2">
                    <img
                      alt="calendar icon"
                      src="/icon_calendar.svg"
                      className="w-3 h-auto"
                    />
                    <p className="text-left font-normal text-xss text-black188">
                      {moment(item.started_at)
                        .tz("Asia/Jakarta")
                        .format("DD MMMM YYYY")}
                    </p>
                  </div>
                  <div className="flex flex-row justify-start items-center mt-2 gap-2">
                    <img
                      alt="calendar icon"
                      src="/icon_clock.svg"
                      className="w-3 h-auto"
                    />
                    <p className="text-left font-normal text-xss text-black188">
                      {moment(item.started_at)
                        .tz("Asia/Jakarta")
                        .format("HH.mm")}{" "}
                      WIB -{" "}
                      {moment(item.ended_at).tz("Asia/Jakarta").format("HH.mm")}{" "}
                      WIB
                    </p>
                  </div>
                  <div className="flex flex-col w-full">
                    <div
                      className="w-full bg-gray7 mt-4"
                      style={{
                        minHeight: "6px",
                        maxHeight: "6px",
                        borderRadius: "4px",
                      }}
                    >
                      <div
                        className={`${
                          occupancy === 0
                            ? "bg-correctOTP"
                            : occupancy > 0 && occupancy <= 0.3
                            ? "bg-correctOTP"
                            : occupancy > 0.3 && occupancy <= 0.6
                            ? "bg-mustard"
                            : occupancy > 0.6 && occupancy <= 1 && "bg-red2"
                        } relative flex items-center justify-end`}
                        style={{
                          minHeight: "6px",
                          maxHeight: "6px",
                          borderRadius: "4px",
                          width: `${
                            item.is_past_classes === 1
                              ? "0%"
                              : occupancy === 0
                              ? "10%"
                              : `${occupancy * 100}%`
                          }`,
                        }}
                      >
                        <img
                          alt="progressbar icon"
                          src="/icon_progressbar.svg"
                          className="w-5 h-5 absolute -right-4"
                        />
                      </div>
                    </div>
                    <p
                      className={`text-left font-bold text-xss ${
                        occupancyStatus(occupancy) === "Lagi diminati"
                          ? "text-mustard"
                          : occupancyStatus(occupancy) === "Segera penuh 🔥"
                          ? "text-red2"
                          : occupancyStatus(occupancy) === "Penuh 🔥"
                          ? "text-red2"
                          : occupancyStatus(occupancy) === "Tersedia" &&
                            "text-correctOTP"
                      }`}
                      style={{ marginTop: "6px" }}
                    >
                      {occupancyStatus(occupancy)}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      setSelectedClass(index);
                      Cookies.set("classProgramIndex", index, {
                        sameSite: "None",
                        secure: true,
                        expires: 1,
                      });
                    }}
                    className={`flex justify-center items-center w-full mt-2 border-2 border-primaryColor rounded-full text-center font-bold text-sm ${
                      index === selectedClass
                        ? "bg-primaryColor text-white"
                        : "text-black"
                    }`}
                    style={{ paddingTop: "10.5px", paddingBottom: "10.5px" }}
                  >
                    Pilih Kelas
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {selectedClass >= 0 && (
        <button
          type="submit"
          className="w-max py-2 px-8 rounded-md text-center font-bold text-sm text-black1 mt-3"
          style={{ backgroundColor: "rgba(255, 202, 45, 1)" }}
        >
          Next
        </button>
      )}
      <input
        type="hidden"
        name="selected"
        value={`${JSON.stringify(classPrograms[selectedClass])}`}
      />
    </form>
  );
}
