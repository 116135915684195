import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Layout from "../components/Layout";
import { authState } from "../atoms/authState";
import Button from "../components/base/Button";
import { Fragment, useEffect, useState } from "react";
import { api, apiAnom, notifError } from "../utils/api";
import moment from "moment-timezone";

import Modal from "../components/Modal";
import { CheckCircleIcon } from "@heroicons/react/solid";
import useSWR from "swr";
import PageLoading from "./PageLoading";
import { signInUpState } from "../atoms/signInUpState";
import Cookies from "js-cookie";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import LayoutV2 from "../components/Layout_V2";

const today = new Date();
today.setHours(0, 0, 0, 0);

export default function ClassroomDetailSlug() {
  let { slug } = useParams();
  const auth = useRecoilValue(authState);
  const apiState = auth.isAuth ? api : apiAnom;
  const setSign = useSetRecoilState(signInUpState);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [classData, setClassData] = useState();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [cardDate, setCardDate] = useState();
  const [isFull, setIsFull] = useState();
  const [isClose, setIsClose] = useState();
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );

  useEffect(() => {
    if (auth.isAuth) {
      api
        .get("/me")
        .then((res) => {
          setUserData(res.data.data);

          if (res.data.data.verified_at.split("-")[0] === "0001") {
            navigate("/profile-edit");
            Cookies.remove("confirmClass");
          }
        })
        .catch(notifError);
    }

    apiState
      .get(`/explore-kelas/${slug}`)
      .then((res) => {
        setClassData(res.data.data);
        setCardDate(new Date(res.data.data.info.started_at));
        setIsFull(
          res.data.data.info.subscribers >= res.data.data.info.capacity
        );
        setIsClose(
          today.getTime() >= new Date(res.data.data.info.started_at).getTime()
        );
        let isConfirmClass = Cookies.get("confirmClass") ?? false;
        if (isConfirmClass && !res.data.data.subscribed) {
          api
            .post(`/students/subscribe/${res.data.data.info.id}`)
            .then((res) => {
              setVideoModalOpen(true);
              Cookies.remove("confirmClass");
            })
            .catch((err) => {
              notifError(
                err,
                "Kamu telah terdaftar di kelas ini. Yuk cek kelas lainnya!",
                "warning"
              );
              Cookies.remove("confirmClass");
            });
        } else {
          Cookies.remove("confirmClass");
        }

        document.title = `Explore Kelas - ${res.data.data.info.topic?.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ""
        )}`;
        setIsLoading(false);
      })
      .catch(notifError);
  }, []);

  const onFormSubmit = (e) => {
    if (userData.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    } else {
      e.preventDefault();
      e.stopPropagation();
      if (userData.fname.length < 1) {
        window.Swal.fire({
          title: "Lengkapi profil!",
          text: "Silahkan lengkapi profil anda terlebih dahulu!",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/profile-edit");
        });
      } else {
        setIsLoading(true);
        api
          .post(`/students/subscribe/${classData.info.id}`, {})
          .then((res) => {
            setVideoModalOpen(true);
          })
          .catch((err) => {
            notifError(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const onButtonReturnedClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/explore-kelas");
  };

  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <LayoutV2 showModal={true}>
      <div className="pt-4 pb-12 tablet:pb-20 ">
        <div className="flex flex-row w-full items-start">
          <div className="flex flex-col w-full h-full items-start justify-start gap-1 leading-tight text-sm tablet:text-2xl">
            <div className="font-bold">{classData.info.topic}</div>
            <div className="font-light">{classData.info.program_name}</div>
          </div>
          <div className="flex flex-col w-full h-full items-end justify-start gap-1 leading-tight text-right text-sm tablet:text-2xl">
            <div className="font-light">Diselenggarakan oleh:</div>
            <div className="font-bold">{classData.info.university_name}</div>
          </div>
        </div>
        <div className="flex flex-row h-full w-full items-end mt-4">
          <div className="flex flex-1 flex-col items-start justify-end w-full h-full leading-none gap-1 lg:gap-2">
            <div className="text-left text-sm lg:text-lg">Pembicara:</div>
            <div className="flex flex-row items-center gap-2 w-full h-full">
              <div className="w-10 h-10">
                <img
                  alt="lecturer photo"
                  src={classData.info.lecturer_avatar ?? "/avatar_default.svg"}
                  className=" w-full h-full object-contain"
                />
              </div>
              <div className="text-xs lg:text-lg font-bold">
                {classData.info.lecturer}
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col items-end justify-end w-full h-full leading-none gap-1 lg:gap-2">
            <div className="w-10 h-10">
              <img
                alt="uni_image"
                src={classData.info.logo_url}
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row bg-backgroundGray3 px-4 py-2 rounded-lg mt-4 text-xss lg:text-lg shadow-custom1">
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Kapasitas Terisi:</div>
              <div className="font-bold">
                {classData.info.subscribers ?? 0}/{classData.info.capacity}
              </div>
            </div>
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Tanggal:</div>
              <div className="font-bold">
                {moment(classData.info.started_at)
                  .tz("Asia/Jakarta")
                  .format("DD MMMM YYYY")}
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Waktu:</div>
              <div className="font-bold">
                {moment(classData.info.started_at)
                  .tz("Asia/Jakarta")
                  .format("HH.mm")}{" "}
                WIB -{" "}
                {moment(classData.info.ended_at)
                  .tz("Asia/Jakarta")
                  .format("HH.mm")}{" "}
                WIB
              </div>
            </div>
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Metode:</div>
              <div className="font-bold">
                {classData.info.is_online ? "Online" : "Offline"}
              </div>
            </div>
          </div>
        </div>
        <hr className="my-5" />
        <h3 className="text-xs lg:text-lg font-bold">Deskripsi Kelas</h3>
        <div
          className="text-xs lg:text-lg"
          dangerouslySetInnerHTML={{ __html: classData.info.description }}
        ></div>
        <form onSubmit={onFormSubmit}>
          <div className="flex items-center justify-center mt-10 gap-4">
            {isFull && !classData.subscribed && (
              <div className="text-xss italic text-rose-500">
                * Kuota kelas telah terpenuhi
              </div>
            )}
            {!isFull && !isClose && !classData.subscribed && (
              <div className="text-xss italic text-gray-400">
                * Daftar sebelum H-1 kelas dimulai
              </div>
            )}
            {isClose && !classData.subscribed && (
              <div className="text-xss italic text-rose-500">
                * Pendaftaran telah ditutup
              </div>
            )}
            {classData.subscribed && (
              <div className="text-xss italic text-gray-500">
                Kamu telah terdaftar dalam kelas ini!
              </div>
            )}
          </div>
          <div className="flex items-center justify-center mt-2 gap-4">
            <Button
              onClick={onButtonReturnedClicked}
              type="button"
              variant="secondary"
              disabled={isLoading}
            >
              Kembali
            </Button>
            {!auth.isAuth ? (
              <Button
                onClick={() => {
                  setSign({ trySignIn: false, trySignUp: true });
                }}
                type="button"
              >
                Masuk
              </Button>
            ) : (
              <Button
                disabled={
                  isFull || isClose || isLoading || classData.subscribed
                }
                type="submit"
                variant="primary"
              >
                Konfirmasi
              </Button>
            )}
          </div>
        </form>
      </div>

      <Transition appear show={videoModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-max h-full transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all relative z-30">
                  <div
                    className="flex flex-row relative"
                    style={{
                      width: "894px",
                      height: "467px",
                      borderRadius: "20px",
                    }}
                  >
                    <button
                      type="button"
                      className="absolute top-6 left-6 z-30"
                      onClick={() => {
                        setVideoModalOpen(false);
                      }}
                    >
                      <MdClose size={26} color="black " />
                    </button>
                    <div
                      className="relative flex flex-col justify-center items-center p-16"
                      style={{ backgroundColor: "#F1F3F6" }}
                    >
                      <img
                        className="h-60 w-60 mx-auto mb-8 mt-1"
                        src="/ic_qrcode_wa.jpg"
                        alt="Hero"
                        style={{
                          border: "20px solid #F9C60B",
                          borderRadius: "44px",
                        }}
                      />
                      <p
                        className="text-center font-medium text-base text-black1 w-60"
                        style={{ lineHeight: "24px" }}
                      >
                        Jangan lupa scan QR Code untuk bergabung dengan para
                        Explorers lainnya disini ya!
                      </p>
                    </div>
                    <div className="w-full h-full flex flex-col justify-start items-start p-12 overflow-auto">
                      <p className="text-left font-normal text-2xl text-black1">
                        Yeay! Kamu telah terdaftar pada kelas:
                      </p>
                      <p className="text-left font-semibold text-3xl text-primaryColor mt-4">
                        {classData.info.program_name}
                      </p>
                      <p className="text-left font-normal text-base text-black1 mt-6">
                        Link untuk mengikuti kelas sudah terkirim ke email kamu.
                        Kamu juga bisa mengaksesnya melalui “Aktivitas Kamu”
                        pada Profile! Sampai jumpa di kelas ya 👋
                      </p>
                      <div className="flex flex-grow"></div>
                      <div className="w-full flex flex-row justify-between items-center">
                        <button
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          <div className="text-center font-semibold text-base text-primaryColor">
                            Lihat Aktivitasku
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            navigate("/explore-kelas");
                          }}
                        >
                          <div className="text-center font-semibold text-base text-white bg-primaryColor px-10 py-3 rounded-lg">
                            Explore kelas lain yuk
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </LayoutV2>
  );
}
