import { components } from "react-select";

export default function DropdownItem(props) {
  return (
    // <div>
    //   <components.Option {...props}>
    //     <input
    //       type="checkbox"
    //       checked={props.isSelected}
    //       onChange={() => null}
    //     />{" "}
    //     <label>{props.label}</label>
    //   </components.Option>
    // </div>
    <div className="flex items-center">
      <components.Option className="bg-white" {...props}>
        <input
          defaultValue={props.defaultValue}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label
          className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  );
}