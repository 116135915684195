import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userUpdatePromptState } from "../atoms/userUpdatePromptState";
import { MdClose } from "react-icons/md";
import { authState } from "../atoms/authState";
import { api, notifError } from "../utils/api";
import { mapHasKeyAndValueNotEmpty } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { userUpdatedState } from "../atoms/userUpdatedState";

export default function UserUpdatePromptModal() {
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const setUserUpdatedState = useSetRecoilState(userUpdatedState);
  const updatePrompt = useRecoilValue(userUpdatePromptState);
  const setUpdatePrompt = useSetRecoilState(userUpdatePromptState);
  const navigate = useNavigate();
  const [educationLevelID, setEducationLevelID] = useState(
    auth.education_level_id
  );
  const [kelas, setKelas] = useState(auth.class);
  const [errors, setErrors] = useState({});

  const educationLevels = [
    { id: 0, name: "Pilih Tingkat Kelas" },
    { id: 1, name: "10" },
    { id: 2, name: "11" },
    { id: 3, name: "12" },
    { id: 4, name: "SMP (atau sederajat)" },
    { id: 5, name: "Lulusan SMA (atau sederajat)" },
  ];

  const updateUserData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const payload = {
      nis: auth.nis,
      fname: auth.fname,
      lname: auth.lname,
      phone: auth.phone,
      school_id: auth.school_id,
      custom_school_name: auth.custom_school_name,
      education_level_id: parseInt(educationLevelID),
      major: auth.major,
      class: kelas,
    };
    if (auth.isAuth) {
      api
        .put("/me?edulvlonly=1", payload)
        .then((_) => {
          api
            .get("/me/last-edu-lvl-update")
            .then((res) => {
              setUserUpdatedState({
                currentEduYearStart: res.data.data.current_edu_year_start,
                currentEduYearEnd: res.data.data.current_edu_year_end,
                isUpdated:
                  res.data.data.student_log.created_at.split("-")[0] === "0001"
                    ? false
                    : true,
              });
            })
            .catch(notifError);

          api
            .get("/me")
            .then((res) => {
              setAuth({ ...res.data.data, isAuth: true });
            })
            .catch(notifError);

          setUpdatePrompt({ isOpen: false });
          navigate("/profile-edit");
        })
        .catch(notifError);
    }
  };

  // setAuth({ ...res.data.data, isAuth: true });
  return (
    <div className="w-screen">
      <Transition show={updatePrompt.isOpen} as={Fragment}>
        <Dialog onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 z-40"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.5)" }}
            />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center px-4 tablet:px-0 z-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-100 h-100 bg-white border-2 shadow-lg rounded-20px p-4 relative">
                <div className="flex flex-col w-full h-full">
                  <div className="flex flex-row items-start justify-between w-full">
                    <div className="flex flex-col justify-start items-start pr-14">
                      <div className="text-left font-bold text-base text-black1">
                        Selamat Kembali di Exploration!
                      </div>
                      <div className="text-left font-normal text-sm text-black1">
                        Yuk perbaharui data kamu untuk membantu exploration
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setUpdatePrompt({ isOpen: false });
                      }}
                    >
                      <MdClose className="w-6 h-6 p-0 m-0" />
                    </button>
                  </div>
                  <div className="flex flex-col w-full mt-4">
                    <p className="text-center font-semibold text-base text-black1">
                      Tingkat Kelas
                    </p>
                    <select
                      id="education_level"
                      placeholder={"Pilih Tingkat Kelas"}
                      className={`form-input w-full text-gray-800 rounded-40px ${
                        mapHasKeyAndValueNotEmpty(errors, "education")
                          ? "border-red-400"
                          : ""
                      } mt-1`}
                      aria-label="education level"
                      name="education_level_id"
                      value={educationLevelID}
                      onChange={(e) => {
                        const eduLevelId = parseInt(e.target.value);
                        if (eduLevelId > 0) {
                          setEducationLevelID(eduLevelId);
                        }
                      }}
                      required
                    >
                      {educationLevels.map((item) => (
                        <option
                          disabled={item.id === 0 ? true : false}
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {educationLevelID !== 5 && (
                    <div className="flex flex-col w-full mt-4">
                      <p className="text-center font-semibold text-base text-black1">
                        Kelas
                      </p>
                      <input
                        id="class"
                        type="text"
                        name="class"
                        className={`form-input w-full text-gray-800 rounded-40px ${
                          mapHasKeyAndValueNotEmpty(errors, "grade")
                            ? "border-red-400"
                            : ""
                        }`}
                        placeholder="Contoh: IPA A, IPS B, IPS 1, IPA 2"
                        value={kelas}
                        onChange={(e) => setKelas(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="absolute bottom-0 left-0 w-full p-4">
                  <button
                    onClick={updateUserData}
                    className="w-full py-2 bg-primaryColor rounded-xl text-center font-medium text-base text-white"
                  >
                    Update
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
