import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ButtonV2 from "../../../../components/ButtonV2";

export default function MiniQuizExploreMore({
  onBack,
  onNext,
  screenWidth,
  isStandalone,
  quizResult,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState();
  return (
    <div className="flex flex-col w-full h-full items-center relative z-50">
      <button className="absolute top-13 left-9">
        <MdArrowBack
          onClick={onBack}
          className="w-7 sm:w-10 h-auto"
          style={{ color: "white" }}
        />
      </button>
      <div
        className="w-full h-full flex flex-col px-9 tablet:px-9 relative z-50 sm:absolute"
        style={{
          minWidth: screenWidth >= 1024 && "630px",
          maxWidth: screenWidth >= 1024 && "630px",
          marginTop: screenWidth < 1024 && "130px",
          top: screenWidth >= 1024 && "65px",
        }}
      >
        <p
          className="text-left font-semibold text-base text-white"
          style={{ lineHeight: "20.16px" }}
        >
          Apakah kamu tertarik untuk explore jurusan{" "}
          {quizResult === "IPA" ? "IPS" : "IPA"}?
        </p>
        <div
          className="flex flex-col items-stretch w-full"
          style={{
            marginTop: "13.89px",
          }}
        >
          <div
            className="flex flex-col justify-start items-stretch gap-2"
            style={{
              minWidth: screenWidth < 1024 ? "319px" : "553.78px",
              maxWidth: screenWidth < 1024 ? "319px" : "553.78px",
            }}
          >
            <button
              type="button"
              onClick={() => {
                setSelectedAnswer("Ya");
              }}
              className="w-full h-auto flex flex-row justify-start items-center rounded-full transform ease-in-out duration-300"
              style={{
                gap: "8px",
                backgroundColor:
                  selectedAnswer === "Ya" ? "rgba(255, 202, 45, 1)" : "white",
                padding: screenWidth < 1024 ? "4px" : "6px",
                paddingRight: "23px",
                maxWidth: "383.68px",
              }}
            >
              <div
                className="rounded-full transform ease-in-out duration-300 flex justify-center items-center"
                style={{
                  backgroundColor:
                    selectedAnswer === "Ya" ? "white" : "rgba(255, 202, 45, 1)",
                  padding: screenWidth < 1024 ? "9px" : "14px",
                  minWidth: screenWidth < 1024 ? "36px" : "52px",
                  maxWidth: screenWidth < 1024 ? "36px" : "52px",
                  minHeight: screenWidth < 1024 ? "36px" : "52px",
                  maxHeight: screenWidth < 1024 ? "36px" : "52px",
                }}
              ></div>
              <p
                className={`text-left ${
                  selectedAnswer === "Ya" ? "font-bold" : "font-medium"
                } text-xs tablet:text-sm text-black`}
                style={{
                  lineHeight: screenWidth < 1024 ? "15.12px" : "17.64px",
                }}
              >
                Ya
              </p>
            </button>
            <button
              type="button"
              onClick={() => {
                setSelectedAnswer("Tidak");
              }}
              className="w-full h-auto flex flex-row justify-start items-center rounded-full transform ease-in-out duration-300"
              style={{
                gap: "8px",
                backgroundColor:
                  selectedAnswer === "Tidak"
                    ? "rgba(255, 202, 45, 1)"
                    : "white",
                padding: screenWidth < 1024 ? "4px" : "6px",
                paddingRight: "23px",
                maxWidth: "383.68px",
              }}
            >
              <div
                className="rounded-full transform ease-in-out duration-300 flex justify-center items-center"
                style={{
                  backgroundColor:
                    selectedAnswer === "Tidak"
                      ? "white"
                      : "rgba(255, 202, 45, 1)",
                  padding: screenWidth < 1024 ? "9px" : "14px",
                  minWidth: screenWidth < 1024 ? "36px" : "52px",
                  maxWidth: screenWidth < 1024 ? "36px" : "52px",
                  minHeight: screenWidth < 1024 ? "36px" : "52px",
                  maxHeight: screenWidth < 1024 ? "36px" : "52px",
                }}
              ></div>
              <p
                className={`text-left ${
                  selectedAnswer === "Tidak" ? "font-bold" : "font-medium"
                } text-xs tablet:text-sm text-black`}
                style={{
                  lineHeight: screenWidth < 1024 ? "15.12px" : "17.64px",
                }}
              >
                Tidak
              </p>
            </button>
          </div>
          <div
            className="flex w-full justify-end"
            style={{ marginTop: screenWidth < 1024 ? "137px" : "85px" }}
          >
            <ButtonV2
              onClick={() => {
                if (selectedAnswer === "Ya") {
                  onNext(true);
                } else {
                  onNext(false);
                }
              }}
              disabled={!selectedAnswer}
              title="Lanjut"
              customStyle={{
                maxWidth: screenWidth < 1024 ? "132px" : "120px",
                backgroundColor: "rgba(255, 202, 45, 1)",
              }}
              customTextStyle={{ color: "black" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
