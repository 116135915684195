/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment-timezone";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "./base/Button";
import { Fragment, useState } from "react";
import { CalendarIcon, ClockIcon, UserIcon } from "@heroicons/react/outline";
import { api } from "../utils/api";

export default function CardClassRoom({
  isFull,
  isClose,
  isSubscribe,
  ...rest
}) {
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );
  const location = useLocation();
  const currentRoute = location.pathname;
  let navigate = useNavigate();
  const [isDownloadingPdf, setIsDownloadingPdf] = useState();
  const onButtonDetailClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/explore-kelas/detail/${rest.id}`, {
      state: { isFull, isClose, isSubscribe, ...rest },
    });
  };

  // const onDownloadCertificate = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   api
  //     .get(`/classes/${rest.id}/certificate`, {})
  //     .then((res) => {
  //       navigate("/certificate", { state: { ...res.data.data } });
  //     })
  //     .catch((err) => {
  //       notifError(err);
  //     });
  // };

  async function onDownloadCertificateV2() {
    await api
      .get(`/classes/${rest.id}/certificate-pdf`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `coa-${rest.topic}.pdf`);
        document.body.appendChild(link);
        link.click();
        setIsDownloadingPdf(false);
      })
      .catch((error) => {
        setIsDownloadingPdf(false);
        // window.Swal.fire({
        //   title: "Perhatian!",
        //   text: `${
        //     error.message === "Request failed with status code 412"
        //       ? "Silakan lengkapi data dirimu dahulu"
        //       : error.message
        //   }`,
        //   icon: "warning",
        //   confirmButtonText: `${
        //     error.message === "Request failed with status code 412"
        //       ? "Edit Profil"
        //       : "Tutup"
        //   }`,
        // }).then(() => {
        //   if (error.message === "Request failed with status code 412") {
        //     navigate("/profile-edit");
        //   }
        // });
        return;
      });
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const cardDate = new Date(rest.started_at);
  const isClassClosed = today.getTime() >= cardDate.getTime();

  if (!rest.id) {
    return null;
  }
  return (
    <>
      <div
        className={`p-3 mb-3 ${
          isSubscribe || isFull || isClose ? "bg-gray-200" : "bg-white"
        } border border-gray-200 rounded shadow-lg transition duration-300 ease-in-out hover:shadow-lg`}
      >
        <div className="flex flex-col tablet:flex-row">
          <div className={"flex flex-row gap-2 tablet:w-4/5"}>
            <div className="flex items-start w-1/6 h-full">
              <img
                alt="uni_logo"
                className="w-full h-full object-contain"
                src={rest.logo_url}
              />
              {/* <img
                alt="uni_logo"
                className="w-full h-full object-contain"
                src={buildAssetUrl(
                  rest.uni_image || rest.logo_url || rest.university_image,
                  getAssetBaseUrl(DIR_UNIVERSITY_LOGO)
                )}
              /> */}
            </div>
            <div className="flex flex-col w-5/6 tablet:ml-2">
              <div className="font-semibold text-sm mb-2 text-black line-clamp-2 tablet:text-2xl tablet:mb-5">
                {rest.topic}
              </div>
              <div className="font-normal text-xs mb-2 text-black leading-none tablet:text-base tablet:mb-3">
                {rest.university_name || rest.uni_name}
              </div>
              <div className="flex flex-row flex-grow gap-2 items-start">
                <div className="flex flex-col items-start justify-evenly w-1/2 h-full">
                  <div className="flex flex-row items-center h-4 w-full">
                    <div className="h-full">
                      <CalendarIcon className="h-full w-auto align-middle leading-none" />
                    </div>
                    <span>&nbsp;</span>
                    <div className="font-normal text-xsss tablet:text-sm leading-none">
                      {moment(rest.started_at)
                        .tz("Asia/Jakarta")
                        .format("DD MMMM YYYY")}
                    </div>
                  </div>
                  <div className="flex flex-row items-center h-4 w-full my-1">
                    <div className="h-full">
                      <ClockIcon className="h-full w-auto leading-none" />
                    </div>
                    <span>&nbsp;</span>
                    <div className="font-normal text-xsss tablet:text-sm leading-none">
                      {`${moment(rest.started_at)
                        .tz("Asia/Jakarta")
                        .format("HH.mm")} - ${moment(rest.ended_at)
                        .tz("Asia/Jakarta")
                        .format("HH.mm")} WIB`}
                    </div>
                  </div>
                  <div className="flex flex-row items-center h-4 w-full">
                    <div className="h-full">
                      <UserIcon className="h-full w-auto leading-none" />
                    </div>
                    <span>&nbsp;</span>
                    <div className="font-normal text-xsss tablet:text-sm leading-none">{`${
                      rest.subscriber ?? 0
                    }/${rest.capacity}`}</div>
                  </div>
                </div>
                <div className="flex flex-col items-start w-1/2 h-full justify-start">
                  <div className="flex items-start justify-start font-normal text-xs leading-none tablet:text-base">
                    <div
                      className="rounded-sm px-1 py-0.5 text-white leading-none"
                      style={{
                        backgroundColor: rest.is_online ? "#FFAD39" : "#43BCCD",
                      }}
                    >
                      {rest.is_online ? "Online" : "Offline"}
                    </div>
                  </div>
                  <div className="flex flex-auto flex-col items-start justify-start mt-2">
                    <div className="font-normal text-xss text-black leading-none tablet:text-base">
                      oleh:
                    </div>
                    <div className="font-bold text-xss text-black leading-none tablet:text-base">
                      {rest.lecturer}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={"flex justify-center tablet:justify-end tablet:w-1/5"}
          >
            <div className="flex flex-col items-center text-center">
              {currentRoute !== "/profile" && (
                <Fragment>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onButtonDetailClicked(e);
                    }}
                    aria-controls="modal"
                    variant="primary"
                    className="leading-none text-xs py-2 px-5 mt-1 tablet:text-base tablet:py-4 tablet:px-8"
                  >
                    Lihat Detail
                  </Button>
                  {isFull && (
                    <div className="text-xss italic text-rose-500 leading-none mt-2">
                      * Kuota kelas telah terpenuhi
                    </div>
                  )}
                  {!isFull && !isClose && (
                    <div className="text-xss italic text-gray-400 leading-none mt-2">
                      * Daftar sebelum H-1 kelas dimulai
                    </div>
                  )}
                  {isClose && (
                    <div className="text-xss italic text-rose-500 leading-none mt-2">
                      * Pendaftaran telah ditutup
                    </div>
                  )}
                </Fragment>
              )}
              {currentRoute === "/profile" && rest.url !== "" && (
                <Fragment>
                  <a
                    href={rest.url}
                    target="_blank"
                    aria-controls="modal"
                    variant="primary"
                    type="button"
                    className="w-40 mt-4 mb-1 btn text-gray-200 bg-purple-700 hover:bg-purple-800"
                    rel="noreferrer"
                  >
                    Link Kelas
                  </a>
                  {isClassClosed && (
                    <button
                      onClick={() => {
                        setIsDownloadingPdf(true);
                        onDownloadCertificateV2();
                      }}
                      disabled={isDownloadingPdf}
                      className={`w-40 mt-4 mb-1 btn ${
                        isDownloadingPdf
                          ? "bg-gray-400 hover:bg-gray-400 text-white"
                          : "bg-tosca2 hover:bg-tosca2dark text-gray-200"
                      }`}
                    >
                      {isDownloadingPdf
                        ? "Sedang download sertifikat..."
                        : "Unduh e-certificate"}
                    </button>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
