import Layout from "../../components/Layout";
import { CheckCircleIcon } from '@heroicons/react/outline'
import Button from "../../components/base/Button";
import {useNavigate} from "react-router-dom"
import LayoutV2 from "../../components/Layout_V2";
export default function SuccessResetPassword() {
  let navigate = useNavigate();
  const navigateToLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/signin")
  }
  return (
    <LayoutV2>
      <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20">
        {/* Page header */}
        <div className="flex flex-col items-center max-w-3xl mx-auto text-center pb-12 tablet:pb-20 gap-y-5">
          <CheckCircleIcon className="w-20 h-20" />
          {/* <img src="/ic_email_sent.svg" alt="email" className="w-20 h-20" /> */}
          <h2 className="h2 mb-6">Berhasil!</h2>
          <div className="flex flex-col items-center gap-y-10 text-gray-600">
            <p className="max-w-lg text-left">
              Passwordmu telah berhasil diubah. Yuk, segera mulai kembali mengeksplorasi kelas dari berbagai perguruan tinggi ternama!
            </p>
          </div>
        </div>
        <Button className="w-2/4 mx-auto" onClick={navigateToLogin}>
          Masuk
        </Button>
      </div>
    </LayoutV2>
  )
}