import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export default function ProfileDream({
  screenWidth,
  index,
  major,
  univ,
  editFunction,
  deleteFunction,
}) {
  const [isEditDream, setIsEditDream] = useState(false);
  return (
    <div className="w-full h-full relative">
      <button
        onMouseEnter={() => {
          setIsEditDream(true);
        }}
        onClick={() => {
          setIsEditDream(!isEditDream);
        }}
        className="w-full rounded-xl bg-white"
        style={{ padding: "12px 25.5px" }}
      >
        <div
          className="absolute flex justify-center items-center z-30"
          style={{
            width: "38.61px",
            height: "40.46px",
            left: "-19px",
            top: "-23px",
            backgroundImage: `url(${
              screenWidth < 1024
                ? "/illu_profile_yellowstar.svg"
                : "/illu_profile_yellowstar.svg"
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            gap: "26px",
          }}
        >
          <p
            className="text-center font-bold text-white"
            style={{
              fontSize: "19.53px",
              lineHeight: "21.1px",
              letterSpacing: "-0.33px",
            }}
          >
            {index + 1}
          </p>
        </div>
        <p
          className="text-center font-normal text-base text-black"
          style={{ lineHeight: "24px" }}
        >
          <span className="font-bold text-primaryColor">“Aku</span> ingin masuk
          jurusan <span className="font-bold text-primaryColor">{major}</span>{" "}
          di <span className="font-bold text-primaryColor">{`${univ}“`}</span>
        </p>
      </button>
      {isEditDream && (
        <div
          onClick={() => {
            setIsEditDream(false);
          }}
          onMouseLeave={() => {
            setIsEditDream(false);
          }}
          className="absolute w-full h-full rounded-xl top-0 left-0 z-20"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}
        />
      )}
      {isEditDream && (
        <div
          onMouseEnter={() => {
            setIsEditDream(true);
          }}
          onMouseLeave={() => {
            setIsEditDream(false);
          }}
          className="absolute flex flex-row justify-center items-center rounded-xl z-30"
          style={{
            backgroundColor: "transparent",
            gap: "7px",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
          }}
        >
          <button
            onClick={editFunction}
            className="w-10 h-10 p-3 rounded-lg relative z-30"
            style={{ backgroundColor: "rgba(165, 220, 134, 1)" }}
          >
            <img
              src="/icon_profile_dream_edit.svg"
              className="w-full h-full object-contain"
            />
          </button>
          <button
            onClick={deleteFunction}
            className="w-10 h-10 p-3 rounded-lg relative z-30"
            style={{ backgroundColor: "rgba(242, 119, 119, 1)" }}
          >
            <img
              src="/icon_profile_dream_delete.svg"
              className="w-full h-full object-contain"
            />
          </button>
        </div>
      )}
    </div>
  );
}
