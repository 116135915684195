import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Layout from "../components/Layout";
import { authState } from "../atoms/authState";
import Button from "../components/base/Button";
import { useEffect, useState } from "react";
import { api, apiAnom, notifError } from "../utils/api";
import moment from "moment-timezone";
import {
  buildAssetUrl,
  checkImageURL,
  DIR_LECTURER_AVATAR,
  DIR_UNIVERSITY_LOGO,
  getAssetBaseUrl,
} from "../utils/image";

import Modal from "../components/Modal";
import { CheckCircleIcon } from "@heroicons/react/solid";
import useSWR from "swr";
import LayoutV2 from "../components/Layout_V2";

const today = new Date();
today.setHours(0, 0, 0, 0);

const ClassRoomDetail = () => {
  moment.tz.add('Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6');
  let { id } = useParams();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const getClassDetail = async () => {
      await _api
        .get(`/classes/${id}`)
        .then((res) => {
          if (res.data.data.info.slug !== "") {
            navigate(`/explore-kelas/${res.data.data.info.slug}`, {
              replace: true,
            });
          }
          setData(res.data.data.info);
        })
        .catch((_) => {
          setData({});
          navigate("*");
        });
    };
    getClassDetail();
  }, [_api, auth.isAuth, id]);

  const { data: classDetail } = useSWR(
    `/classes/${id}`,
    auth.isAuth ? api : apiAnom,
    {
      revalidateOnFocus: false,
    }
  );

  const dataClass = state
    ? state
    : classDetail
    ? {
        ...classDetail.data.data.info,
        isSubscribe: classDetail.data.data.subscribed,
      }
    : {};

  const {
    topic,
    lecturer,
    lecturer_avatar,
    description,
    started_at,
    ended_at,
    capacity,
    is_online,
    url,
    slug,
    subscriber,
    program_name,
    university_name,
    university_city,
    logo_url,
    isSubscribe,
  } = dataClass;

  const cardDate = new Date(started_at);
  const isFull = subscriber >= capacity;
  const isClose = today.getTime() >= cardDate.getTime();

  const onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (auth.fname.length < 1) {
      window.Swal.fire({
        title: "Lengkapi profil!",
        text: "Silahkan lengkapi profil anda terlebih dahulu!",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/profile-edit");
      });
    } else {
      setIsLoading(true);
      api
        .post(`/students/subscribe/${id}`, {})
        .then((res) => {
          setVideoModalOpen(true);
        })
        .catch((err) => {
          notifError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onButtonReturnedClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/explore-kelas");
  };

  return (
    <LayoutV2>
      <div className="pt-4 pb-12 tablet:pb-20 ">
        <div className="flex flex-row w-full items-start">
          <div className="flex flex-col w-full h-full items-start justify-start gap-1 leading-tight text-sm tablet:text-2xl">
            <div className="font-bold">{topic}</div>
            <div className="font-light">{program_name}</div>
          </div>
          <div className="flex flex-col w-full h-full items-end justify-start gap-1 leading-tight text-right text-sm tablet:text-2xl">
            <div className="font-light">Diselenggarakan oleh:</div>
            <div className="font-bold">{university_name}</div>
          </div>
        </div>
        <div className="flex flex-row h-full w-full items-end mt-4">
          <div className="flex flex-1 flex-col items-start justify-end w-full h-full leading-none gap-1 lg:gap-2">
            <div className="text-left text-sm lg:text-lg">Pembicara:</div>
            <div className="flex flex-row items-center gap-2 w-full h-full">
              <div className="w-10 h-10">
                {/* <img
                  src={
                    checkImageURL(
                      buildAssetUrl(
                        lecturer_avatar,
                        getAssetBaseUrl(DIR_LECTURER_AVATAR)
                      )
                    ) || "/avatar_default.svg"
                  }
                  className=" w-full h-full object-contain"
                /> */}
                <img
                  src={lecturer_avatar ?? "/avatar_default.svg"}
                  className=" w-full h-full object-contain"
                />
              </div>
              <div className="text-xs lg:text-lg font-bold">{lecturer}</div>
            </div>
          </div>
          <div className="flex flex-1 flex-col items-end justify-end w-full h-full leading-none gap-1 lg:gap-2">
            <div className="w-10 h-10">
              <img
                alt="uni_image"
                src={buildAssetUrl(
                  logo_url,
                  getAssetBaseUrl(DIR_UNIVERSITY_LOGO)
                )}
                className="w-full h-full object-contain"
              />
            </div>
            <p className="text-xs lg:text-lg">{university_city}</p>
          </div>
        </div>
        <div className="flex flex-row bg-backgroundGray3 px-4 py-2 rounded-lg mt-4 text-xss lg:text-lg shadow-custom1">
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Kapasitas Terisi:</div>
              <div className="font-bold">
                {subscriber ?? 0}/{capacity}
              </div>
            </div>
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Tanggal:</div>
              <div className="font-bold">
                {moment(started_at).tz('Asia/Jakarta').format("DD MMMM YYYY")}
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Waktu:</div>
              <div className="font-bold">
                {moment(started_at).tz('Asia/Jakarta').format("HH.mm")} WIB -{" "}
                {moment(ended_at).tz('Asia/Jakarta').format("HH.mm")} WIB
              </div>
            </div>
            <div className="flex flex-col flex-1 items-start justify-center w-full h-full">
              <div>Metode:</div>
              <div className="font-bold">
                {is_online ? "Online" : "Offline"}
              </div>
            </div>
          </div>
        </div>
        <hr className="my-5" />
        <h3 className="text-xs lg:text-lg font-bold">Deskripsi Kelas</h3>
        <div
          className="text-xs lg:text-lg"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        <form onSubmit={onFormSubmit}>
          <div className="flex items-center justify-center mt-10 gap-4">
            {isFull && !isSubscribe && (
              <div className="text-xss italic text-rose-500">
                * Kuota kelas telah terpenuhi
              </div>
            )}
            {!isFull && !isClose && !isSubscribe && (
              <div className="text-xss italic text-gray-400">
                * Daftar sebelum H-1 kelas dimulai
              </div>
            )}
            {isClose && !isSubscribe && (
              <div className="text-xss italic text-rose-500">
                * Pendaftaran telah ditutup
              </div>
            )}
            {isSubscribe && (
              <div className="text-xss italic text-gray-500">
                Kamu telah terdaftar dalam kelas ini!
              </div>
            )}
          </div>
          <div className="flex items-center justify-center mt-2 gap-4">
            <Button
              onClick={onButtonReturnedClicked}
              type="button"
              variant="secondary"
              disabled={isLoading}
            >
              Kembali
            </Button>
            {!auth.isAuth ? (
              <Button
                onClick={() => {
                  setTimeout(() => {
                    navigate({
                      pathname: "/signin",
                      search: `?redirect=/explore-kelas/detail/${id}`,
                    });
                  }, 300);
                }}
                type="button"
              >
                Masuk
              </Button>
            ) : (
              <Button
                disabled={isFull || isClose || isLoading || isSubscribe}
                type="submit"
                variant="primary"
              >
                Konfirmasi
              </Button>
            )}
          </div>
        </form>
      </div>
      <Modal
        id="modal"
        ariaLabel="modal-headline"
        show={videoModalOpen}
        handleClose={() => setVideoModalOpen(false)}
      >
        <div className="max-w-7xl mx-auto p-8 px-6 bg-green-200">
          <div className="max-w-2xl lg:mx-auto lg:text-center">
            <div className="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              <CheckCircleIcon className="h-20 w-20 mx-auto" />
            </div>
            <div className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
              Pendaftaranmu telah dikonfirmasi!
            </div>
            <p className="mt-4 ">
              Yuk join Explorationid.com Community untuk informasi kelas ini dan
              informasi terbaru terkait kelas-kelas selanjutnya!
            </p>
            <p>{`\n`}Gabung sekarang di sini ya!</p>
            <img
              className="h-40 w-40 mx-auto"
              src="/ic_qrcode_wa.jpg"
              width="100%"
              height="100%"
              alt="Hero"
            />
            <p>
              Link untuk mengikuti kelas dapat kamus akses di email konfirmasi
              atau melalui website exploration pada:
            </p>
            <p>
              Profile {`->`} Aktifitas Kamu {`->`} Kelas Mendatang
            </p>
            <Link to="/profile">
              <Button className="mt-4 mb-2 mx-auto">Profile</Button>
            </Link>
            <Button
              onClick={() => setVideoModalOpen(false)}
              variant="link"
              className="mx-auto outline text-gray-500"
            >
              Kembali ke detail kelas
            </Button>
          </div>
        </div>
      </Modal>
    </LayoutV2>
  );
};

export default ClassRoomDetail;
