import Layout from "../components/Layout";
import parse from "html-react-parser";
import RenderHtml from "react-native-render-html";
import LayoutV2 from "../components/Layout_V2";

export default function TnC() {
  const data = {
    html: `<html>

    <head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=Generator content="Microsoft Word 15 (filtered)">
    <style>
    <!--
     /* Font Definitions */
     @font-face
        {font-family:Wingdings;
        panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
        {font-family:Calibri;
        panose-1:2 15 5 2 2 2 4 3 2 4;}
    @font-face
        {font-family:Cambria;
        panose-1:2 4 5 3 5 4 6 3 2 4;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:8.0pt;
        margin-left:0in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoFooter, li.MsoFooter, div.MsoFooter
        {mso-style-link:"Footer Char";
        margin:0in;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    a:link, span.MsoHyperlink
        {color:#0563C1;
        text-decoration:underline;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:8.0pt;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:0in;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:0in;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:8.0pt;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    span.FooterChar
        {mso-style-name:"Footer Char";
        mso-style-link:Footer;}
    .MsoChpDefault
        {font-family:"Calibri",sans-serif;}
    .MsoPapDefault
        {margin-bottom:8.0pt;
        line-height:107%;}
     /* Page Definitions */
     @page WordSection1
        {size:595.3pt 841.9pt;
        margin:1.0in 1.0in 1.0in 1.0in;}
    div.WordSection1
        {page:WordSection1;}
     /* List Definitions */
     ol
        {margin-bottom:0in;}
    ul
        {margin-bottom:0in;}
    -->
    </style>
    
    </head>
    
    <body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>
    
    <div class=WordSection1>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:18.0pt;font-family:"Times New Roman",serif'>Syarat dan
    Ketentuan (Terms &amp; Condition)</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:#7030A0'>Tanggal Berlaku: 15 Mei 2023</span></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Dokumen
    ini terdiri dari Perjanjian Ketentuan Penggunaan (&quot;Perjanjian&quot;) dan
    ketika diterima oleh Anda (&quot;Anda&quot;, atau &quot;Anggota&quot; atau
    &quot;Pengunjung&quot;) dengan cara yang dijelaskan di bawah ini akan menjadi
    kontrak yang mengikat secara hukum antara Exploration (&quot; Exploration&quot;,
    &quot;Kami&quot; atau &quot;Kami&quot;) yang mengoperasikan sistem pada website
    explorationid.com, dan Anda.</span></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Jika
    anda mengunduh, memasang dan/atau menggunakan aplikasi dan situs explorationid.com
    maka selaku pengguna dianggap telah memahami, mengetahui, menyetujui dan
    menerima setiap hal yang diatur dalam Syarat &amp; Ketentuan pada saat mulai
    menggunakan atau pada saat mulai mendaftarkan diri pada situs explorationid.com.</span></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Syarat
    &amp; Ketentuan penggunaan situs explorationid.com merupakan perjanjian yang
    mengikat dan sah antara Pengguna dan pemilik situs explorationid.com yang
    persetujuannya ditandai dengan digunakannya atau didaftarkannya Pengguna pada
    situs explorationid.com sehingga hal demikian dianggap sebagai syarat sahnya
    pengikatan.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>1. Definisi
    </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><b><span
    style='color:black'>PT OLAH CIPTA BIMASENA CARAKA NUSANTARA&nbsp;</span></b><span
    style='font-size:12.0pt;font-family:"Times New Roman",serif'> <span lang=EN-ID>merupakan
    pemilik dan pengelola situs explorationid.com, yang dikenal dengan sebutan Exploration,
    sebuah portal layanan pendidikan terintegrasi. Exploration hadir untuk membantu
    segenap siswa Indonesia guna meningkatkan kualitas pendidikan yang mudah
    dijangkau oleh berbagai kalangan.</span></span></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Pengguna
    merupakan setiap pihak yang menggunakan situs explorationid.com, baik yang
    telah mendaftar atau yang belum mendaftar namun telah mengakses situs explorationid.com.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>2. Ruang
    Lingkup</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Exploration
    menyediakan berbagai informasi yang berkaitan dengan dunia Pendidikan, yang
    mana layanan dalam situs </span><span lang=EN-ID style='font-size:12.0pt;
    font-family:"Times New Roman",serif'>explorationid.com termasuk namun tidak
    terbatas pada</span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>:</span></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span lang=EN-ID style='font-size:12.0pt;font-family:
    Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Explore
    Kampus</span></b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>,
    yaitu informasi yang berkaitan dengan kampus atau universitas yang termasuk
    namun tidak terbatas pada informasi yang diberikan oleh rekan institusi
    Exploration atau informasi yang dikumpulkan oleh exploration dan pihak ketiga
    lainnya</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span lang=EN-ID style='font-size:12.0pt;font-family:
    Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Explore
    Jurusan</span></b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>,<b>
    </b>yaitu informasi yang berkaitan dengan jurusan universitas yang termasuk
    namun tidak terbatas pada informasi yang diberikan oleh rekan institusi
    Exploration atau informasi yang dikumpulkan oleh exploration dan pihak ketiga
    lainnya</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span lang=EN-ID style='font-size:12.0pt;font-family:
    Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Pastiin
    Minat Kamu</span></b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>,
    serangkaian pertanyaan test terkait minat bakar pengguna / user yang akan
    menghasilkan rekomendasi jurusan </span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-bottom:12.0pt;text-indent:-.25in;
    line-height:normal'><span lang=EN-ID style='font-size:12.0pt;font-family:Symbol'>·<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kelas</span></b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>, kelas
    yang diselenggarakan namun tidak terbatas pada rekan insitusi Exploration atau
    kelas lainnya yang dilakukan oleh Exploration, pihak ketiga, atau informasi
    kelas yang diambil dari sumber lainnya.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>3. Pendaftaran
    Pengguna</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Pendaftaran Pengguna ditujukan untuk memperoleh akun dan password
    yang dilakukan oleh individual atau mewakili institusi untuk mengakses berbagai
    informasi atau data dapat dilakukan oleh Pengguna.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Proses pendaftaran ditetapkan secara penuh oleh Exploration melalui
    situs explorationid.com.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Pengguna menjamin kebenaran dan keabsahan informasi yang digunakan
    untuk melakukan pendaftaran sebagai data personal yang dimilikinya. Bagi
    Pengguna, yang usianya dibawah 17 tahun dan belum menikah, menjamin bahwa
    penggunaan atau pendaftaran pada situs </span><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif'>explorationid.com</span><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'> </span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>telah mendapatkan izin atau persetujuan dari orangtua atau walinya
    yang sah.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Pengguna tidak diperkenankan untuk menginformasikan akun dan
    password kepada pihak manapun. Setiap terjadinya penyalahgunaan akun dan
    password yang merugikan Pengguna sendiri atau Exploration atau pihak ketiga
    lainnya menjadi tanggungjawab Pengguna sepenuhnya.</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-bottom:12.0pt;text-indent:-.25in;
    line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Pengguna menyetujui untuk memberikan informasi kepada kami setiap
    adanya dugaan yang tidak sah/dengan pengatasnamaan nama akun terdaftar anda.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>4. Penggunaan
    Layanan Exploration</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Exploration menyediakan berbagai fitur yang dapat digunakan oleh
    pengguna</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Fitur yang diberikan dapat dibagi menjadi 2 (dua) kategori yaitu
    fitur berbayar dan fitur yang gratis</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Exploration tidak Benjamin pengembalian pembayaran apabila terjadi
    transaksi antara pengguna dengan rekan institusi Exploration diluar platform explorationid.com.</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-bottom:12.0pt;text-indent:-.25in;
    line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Jika seorang Pengguna melanggar atau berkali-kali melanggar hak
    atas kekayaan intelektual milik Exploration, maka kami dapat, maka dalam
    diskresinya, mengakhiri atau menolak akses terhadap dan penggunaan layanan
    secara sepihak dengan dan/atau tanpa pemberitahuan kepada pengguna layanan
    situs explorationid.com. Dalam hal ini, kami tidak memiliki kewajiban untuk
    memberikan suatu pengembalian terhadap jumlah apa pun yang telah dibayarkan
    kepada kami sebelumnya.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>5. Pemblokiran
    Akun Pengguna</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Akun dan password yang diberikan kepada Pengguna sifatnya
    individual, tidak dapat dipindahtangankan atau diperdagangkan, ditujukan
    semata-semata untuk layanan yang disediakan oleh Exploration yang tidak
    terbatas pada situs explorationid.com, memperhatikan ketentuan peraturan yang
    berlaku, termasuk namun tidak terbatas pada Syarat &amp; Ketentuan ini.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Setiap pelanggaran atas Pasal 4 huruf (a) dan Pasa 4 huruf (b) di
    atas, termasuk diantaranya pembajakan, penyampaian isu SARA (suku agama ras
    antar golongan), pelanggaran hak kekayaan intelektual, pengiriman spam,
    penyebaran virus, maka Kami berhak sepenuhnya untuk membatasi, memblokir atau
    mengakhiri pelayanan dari suatu akun, melarang akses ke situs explorationid.com
    dan konten, layanan, dan memperlambat atau menghapus konten, memngambil
    langkah-langkah hukum untuk menjaga keanggotaan explorationid.com atau pengguna
    lain jika Kami menganggap keanggotaan explorationid.com atau pengguna lain
    melanggar hukum-hukum yang berlaku, melanggar hak intelektual dari pihak
    terkait, atau melakukan suatu pelanggaran yang melanggar hal-hal yang tertera
    pada Ketentuan ini.</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-bottom:12.0pt;text-indent:-.25in;
    line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Penghentian atau pemblokiran akun pengguna tidak serta merta
    menghentikan kewajiban hukum atas pelanggaran yang dilakukan oleh Pengguna
    sesuai ketentuan hukum dan peraturan perundang-undangan yang berlaku.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>6. Kerahasiaan</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
    margin-bottom:12.0pt;margin-left:39.15pt;text-indent:-.25in;line-height:normal'><span
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>a.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Pengguna
    bersedia memberikan informasi pribadi termasuk namun tidak terbatas pada </span><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Nama Depan,
    Nama Belakang, Nomor Ponsel, Nama Sekolah, Tingkat Pendidikan, Jurusan Siswa, Kelas
    Siswa, Email, dan No. Virtual Account</span><span style='font-size:12.0pt;
    font-family:"Times New Roman",serif;color:black'>. Semua informasi pribadi akan
    dipergunakan, dijaga dan dirahasiakan sesuai dengan ketentuan hukum yang
    berlaku.</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
    margin-bottom:12.0pt;margin-left:39.15pt;text-indent:-.25in;line-height:normal'><span
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>b.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Semua
    informasi pribadi akan disimpan, dipergunakan, dijaga dan dirahasiakan sesuai
    dengan ketentuan hukum yang berlaku.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>7.
    Batasan Tanggung Jawab</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Tanggung jawab Kami hanyalah sebatas pada penyediaan dan
    pengelolaan situs explorationid.com, dan </span><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif'>acara yang
    melibatkan Exploration</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Situs explorationid.com tidak memiliki kewajiban untuk memeriksa
    informasi yang tersedia dalam situs explorationid.com yang berasal dari
    kontributor, namun secara internal telah dilakukan upaya-upaya yang diperlukan
    untuk memastikan kevalidan informasi tersebut. Namun demikian, Pengguna
    memahami bahwa informasi tersebut explorationid.com tidak pernah menjaminkan
    kebenaran sepenuhnya oleh explorationid.com. Dalam situasi dimana ditemukan
    kesalahan atau kekeliruan atau perubahan informasi yang telah disajikan
    sebelumnya, maka hal-hal demikian akan disesuaikan dan tidak akan pernah
    menimbulkan permasalahan terhadap pihak manapun.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Setiap informasi pendidikan yang disediakan oleh explorationid.com
    tidak dapat dijadikan dasar atau alasan bagi Pengguna untuk menghubungkannya
    dengan hasil prestasi Pengguna dalam bidang akademik termasuk dan tidak
    terbatas pada pengambilan keputusan universitas atau jurusan yang didaftarkan
    dengan mengingat informasi yang disediakan oleh Exploration merupakan sarana
    tambahan.</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-bottom:12.0pt;text-indent:-.25in;
    line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Exploration telah melakukan upaya teknis yang wajar untuk
    memberikan pengamanan atas data Pengguna dari risiko kehilangan atau akses,
    penggunaan, perubahan, dan pengungkapan oleh pihak yang tidak bertanggung jawab
    namun demikian, Exploration tidak dapat menjamin bahwa tidak pernah ada pihak
    ketiga yang tidak akan mampu melewati pengamanan tersebut dan menggunakan
    informasi Pengguna untuk maksud yang tidak sepantasnya. Oleh karenanya Pengguna
    membebaskan explorationid.com dari segala tuntutan baik Perdata maupun Pidana
    jika terjadi hal-hal tersebut diatas.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>8. Hukum
    yang Berlaku</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Hukum
    yang berlaku adalah hukum negara kesatuan Republik Indonesia.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>9. Penyelesaian
    Sengketa</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Setiap terjadi permasalahan, para pihak akan menyelesaikannya
    dengan cara musyawarah.</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-bottom:12.0pt;text-indent:-.25in;
    line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Apabila telah dilakukan musyawarah dalam 30 (tiga puluh) hari
    kalender sejak musyawarah pertama diadakan dan tetap tidak menghasilkan solusi
    maka para pihak sepakat untuk menyelesaikan perselisihan melalui Badan
    Arbitrase.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>10.
    Lain-lain</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Syarat &amp; Ketentuan ini merupakan kesepakatan antara para pihak
    sebagai dasar pemanfaatan situs explorationid.com.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Apabila dikemudian hari terdapat salah satu atau beberapa
    ketentuan yang diatur terbukti menjadi ilegal, tidak sah, maupun tidak dapat
    dilaksanakan, maka ketentuan tersebut akan segera diperbaharui oleh Exploration
    yang secara prinsip diupayakan untuk tidak mengubah mekanisme yang ada namun
    tetap memperhatikan ketentuan hukum dan perundang-undangan yang berlaku.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Pengguna memahami bahwa Kami sewaktu-waktu dapat melakukan perubahan
    (pengurangan atau penambahan) dalam Syarat &amp; Ketentuan ini. Kami
    menyarankan agar Pengguna secara berkala dan seksama melihat Syarat &amp;
    Ketentuan ini. Dengan tetap mengakses dan/atau menggunakan explorationid.com,
    maka Pengguna dianggap telah menyetujui perubahan-perubahan dalam kebijakan
    privasi ini.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:12.0pt;text-indent:
    -.25in;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Bentuk kerugian yang dikarenakan dari tindakan Pengguna yang
    melanggar perjanjian ini sepenuhnya adalah tanggung jawab Pengguna dimana
    Pengguna melepaskan Kami dari segala bentuk tanggung jawab, gugatan atau
    tuntutan pihak-pihak yang dirugikan.</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-bottom:12.0pt;text-indent:-.25in;
    line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:black'>Bahwa tidak ada satupun dari Kami maupun mitra Kami, pemberi
    lisensi, kontraktor atau pihak-pihak lain yang terkait dalam pembuatan,
    produksi, penyampaian layanan, atau konten yang terdapat dalam explorationid.com
    yang diharuskan bertanggung jawab terhadap Pengguna ataupun setiap orang yang
    mengajukan klaim melalui Pengguna, untuk setiap kerugian atas ketidakakuratan
    data, kegagalan untuk memperoleh hasil yang diharapkan, kerugian ekonomis,
    insidental, signifikan, hukuman, kerugian secara tidak langsung (termasuk
    tetapi tidak terbatas pada biaya-biaya yang diperlukan untuk perbaikan) yang
    disebabkan karena akses yang Pengguna lakukan ataupun dikarenakan kegagalan
    mengakses layanan atau konten yang terdapat dalam explorationid.com baik
    berdasarkan jaminan, kontrak, perbuatan melawan hukum, tanggung jawab produk
    atau teori hukum dan kemungkinan apakah Kami mengetahui ataupun tidak mengenaik
    kemungkinan kerugian tersebut ataupun jika kemungkinan kerugian itu telah dapat
    diperkirakan sebelumnya. Kami tidak bertanggung jawab kepada Pengguna ataupun
    kepada pihak lainnya untuk ketidakakuratan, kesalahan, kerusakan, atau kerugian
    yang disebabkan baik oleh karena kegagalan, penundaan, terputusnya layanan dan
    konten sebagaimana terdapat pada explorationid.com, baik seluruh ataupun
    sebagian. Pengguna menyetujui bahwa dalam setiap kejadian, Pengguna tidak dapat
    mengajukan klaim kepada Kami atas segala kerugian/kerusakan yang timbul
    sehubungan dengan akses terhadap layanan atau konten yang terdapat pada explorationid.com
    ataupun hal lain yang timbul sehubungan dengan ketentuan dalam perjanjian ini.
    Hal-hal tersebut diatas akan tetap berlaku meskipun terjadi pengakhiran
    terhadap perjanjian ini ataupun telah habis jangka waktu berlakunya.</span></p>
    
    </div>
    
    </body>
    
    </html>`,
  };

  return (
    <LayoutV2>
      {/* <div dangerouslySetInnerHTML={{ __html: perf }} /> */}
      {/* <div>{parse(data)}</div> */}
      <RenderHtml source={data} />
      
    </LayoutV2>
  );
}
