import {useState} from "react";
import {Link} from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import {apiAnom, notifError} from "../utils/api";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {InputPassword} from "../components/InputPassword";
import LayoutV2 from "../components/Layout_V2";

export default function ChangePassword() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMatch, setIsMatch] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.elements[0].value !== e.target.elements[1].value) {
      setIsMatch("Buat password dan Konfirmasi password tidak cocok.");
      return;
    }
    setIsMatch("");
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const token = params.get("t");
    if (!token) {
      notifError("Token is empty or invalid");
      return;
    }
    apiAnom
      .post("/auth/change-password", {
        password: e.target.elements[0].value,
        token,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        notifError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (success) {
    return (
      <LayoutV2>
        <div className="pt-8 pb-12 tablet:pt-36 tablet:pb-20">
          <CheckCircleIcon className="h-20 w-20 mx-auto text-green-500 mb-5" />
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1 className="tablet:h1 h3 mb-4">Berhasil!</h1>
            <p className="text-gray-600">
              Passwordmu telah berhasil diubah. Yuk, segera mulai kembali mengeksplorasi kelas dari berbagai perguruan tinggi ternama!
            </p>
          </div>
          <div className="max-w-sm mx-auto">
            <div className="flex items-center justify-center">
              <Link to="/signin">
                <Button>Masuk</Button>
              </Link>
            </div>
          </div>
        </div>
      </LayoutV2>
    );
  }

  return (
    <LayoutV2>
      <div className="pt-32 pb-12 tablet:pt-40 tablet:pb-20">
        <div className="max-w-3xl mx-auto text-center pb-12">
          <h1 className="tablet:h1 h3 mb-3">Buat Password Baru!</h1>
          <p>Pastikan passwordmu berbeda dengan sebelumnya</p>
        </div>
        <div className="max-w-sm mx-auto">
          <form onSubmit={onSubmit}>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full mb-4 px-3">
                <label
                  className="block text-gray-800 text-sm font-medium mb-1"
                  htmlFor="password"
                >
                  Buat password
                </label>
                <InputPassword
                    id="password"
                    name="password"
                    className="form-input w-full text-gray-800"
                    placeholder="masukkan password"
                    required
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block text-gray-800 text-sm font-medium mb-1"
                  htmlFor="password-confirm"
                >
                  Konfirmasi password
                </label>
                <div>
                  <InputPassword
                      id="password-confirm"
                      name="password-confirm"
                      className="form-input w-full text-gray-800"
                      placeholder="Masukkan konfirmasi password"
                      required
                  />
                  {isMatch && (
                    <div className="mt-2 text-red-400">{isMatch}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <Button disabled={loading} type="submit" className="w-full">
                  Buat Password
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutV2>
  );
}
