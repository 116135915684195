import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

export default function PretestInputName({ submit, screenWidth, name }) {
  const auth = useRecoilValue(authState);
  const inputRef = useRef();
  const [namaPeserta, setNamaPeserta] = useState("");

  useEffect(() => {
    if (auth.isAuth) {
      setNamaPeserta(`${auth.fname} ${auth.lname}`);
    }
    if (name) {
      setNamaPeserta(name);
    }
  }, []);

  const handleInput = (event) => {
    const inputValue =
      namaPeserta.length < 3
        ? event.target.value.replace(/[^a-zA-Z]/g, "")
        : event.target.value.replace(/[^a-zA-Z\s.]/g, "");
    setNamaPeserta(inputValue.slice(0, 40));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (namaPeserta.length < 3) {
      window.Swal.fire({
        text: "Minimal 3 karakter",
        icon: "warning",
      });
    } else {
      submit(e.target[0].value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (namaPeserta.length >= 3) {
        submit(namaPeserta);
      } else {
        window.Swal.fire({
          text: "Minimal 3 karakter",
          icon: "warning",
        });
      }
    }
  };

  return (
    <div
      className="h-full flex flex-col items-stretch mt-5"
      style={{
        minWidth: screenWidth >= 1024 ? "463.66px" : "326.08px",
        maxWidth: screenWidth >= 1024 ? "463.66px" : "326.08px",
      }}
    >
      <div
        className={`${
          screenWidth >= 1024 ? "relative -left-11 z-20" : ""
        } w-full h-full flex flex-col`}
      >
        <p
          className="text-left font-semibold text-base text-white"
          style={{ lineHeight: "20.16px" }}
        >
          Hi Explorers! Namaku Serra.
          <br />
          <br />
          Aku yang akan menemanimu untuk eksplorasi minat dan karakter kamu!
        </p>
        {/* <form
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
          className="flex flex-col items-end w-full mt-8"
        >
          <input
            ref={inputRef}
            className="form-input flex w-full rounded-md text-center font-normal text-xs sm:text-xl text-black"
            style={{
              height: screenWidth < 1024 ? "42px" : "61px",
              borderRadius: screenWidth < 1024 ? "5px" : "15px",
            }}
            value={namaPeserta}
            onInput={handleInput}
            maxLength="40"
            placeholder="Tuliskan nama lengkap kamu disini"
            required
          />
          <p
            className="text-right font-normal text-xs text-white"
            style={{ lineHeight: "15.12px", marginTop: "5px" }}
          >{`${namaPeserta.length ?? 0}/40`}</p>
          <button
            type="submit"
            className="w-max text-center font-bold text-base text-black1"
            style={{
              backgroundColor: "rgba(255, 202, 45, 1)",
              marginTop: "18.6px",
              minWidth: "132px",
              minHeight: "36px",
              borderRadius: "7.51px",
            }}
          >
            Yuk Mulai!
          </button>
        </form> */}
        <div className="w-full flex justify-end" style={{ marginTop: "35px" }}>
          <button
            onClick={submit}
            type="submit"
            className="w-max text-center font-bold text-base text-black1"
            style={{
              backgroundColor: "rgba(255, 202, 45, 1)",
              // marginTop: "18.6px",
              minWidth: "132px",
              minHeight: "36px",
              borderRadius: "7.51px",
            }}
          >
            Yuk Mulai!
          </button>
        </div>
      </div>
    </div>
  );
}
