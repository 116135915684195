import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useState } from "react";

export default function FormFieldText({
  id,
  title,
  placeholder,
  isRequired,
  isPassword,
  value,
  onChange,
  isDisabled,
  onInput,
}) {
  const [viewPassword, setViewPassword] = useState(false);
  return (
    <div className="w-full flex flex-col justify-start items-stretch gap-2">
      <p
        className="text-left font-medium text-base text-black"
        style={{ lineHeight: "12.33px" }}
      >
        {title}
      </p>
      <div className="w-full h-10 relative">
        <input
          id={id}
          onInput={onInput}
          className="h-full w-full"
          type={!isPassword ? "text" : viewPassword ? "text" : "password"}
          style={{
            border: isDisabled ? "none" : "0.62px solid rgba(224, 224, 225, 1)",
            borderRadius: "24.66px",
            padding: `8px 16px 8px ${isDisabled ? "0px" : "16px"}`,
            color: isDisabled && "rgba(138, 138, 138, 1)",
          }}
          placeholder={placeholder}
          defaultValue={value}
          required={isRequired}
          onChange={onChange}
          disabled={isDisabled}
        />
        {isPassword && (
          <button
            type="button"
            onClick={() => {
              setViewPassword(!viewPassword);
            }}
            className="absolute right-4"
            style={{ transform: "translate(0, -50%)", top: "50%" }}
          >
            {viewPassword ? (
              <EyeIcon
                className="h-5"
                style={{ color: "rgba(183, 183, 183, 1)" }}
              />
            ) : (
              <EyeOffIcon
                className="h-5"
                style={{ color: "rgba(183, 183, 183, 1)" }}
              />
            )}
          </button>
        )}
        {/* {ddOptions && openDD && (
          <div
            className="w-full overflow-y-auto flex flex-col gap-2 p-2 rounded-xl bg-white absolute left-0 -bottom-2 z-10"
            style={{
              transform: "translate(0, 100%)",
              border: "0.62px solid rgba(224, 224, 225, 1)",
              maxHeight: "140px",
            }}
          >
            {ddOptions.map((item, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setSelectedOption(item.name);
                  setOpenDD(false);
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
}
