import { CheckIcon } from "@heroicons/react/outline";

export default function VerifiedIcon() {
  return (
    <div className="flex items-center justify-center">
      <img
        src="/verified_icon2.svg"
        className="h-4 w-4 ml-1 mac:h-6 mac:w-6 mac:ml-2"
      />
    </div>
  );
}
