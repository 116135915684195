import React from "react";

export default function TestimonyCard({
  testimony,
  photo,
  source,
  name,
  degree,
}) {
  return (
    <div
      className="flex flex-col w-full h-auto bg-white shadow-xl rounded-xl"
      style={{ padding: "34.67px" }}
    >
      <p
        className="text-left font-normal text-sm text-black line-clamp-4 overflow-ellipsis"
        style={{ lineHeight: "21.7px" }}
      >
        {testimony}
      </p>
      <div
        className="flex flex-row items-center justify-start"
        style={{ marginTop: "26px", gap: "17.33px" }}
      >
        <div
          className={`${
            source === "individual" ? "rounded-full" : "rounded-lg"
          }`}
          style={{ width: "43.33px", height: "43.33px" }}
        >
          <img
            alt="individu"
            src={photo}
            className="w-full h-full object-contain"
          />
        </div>
        <div
          className="flex flex-col items-start justify-center"
          style={{ gap: "7px" }}
        >
          <p
            className="text-left font-bold text-sm text-black line-clamp-1 overflow-ellipsis"
            style={{ lineHeight: "21.7px" }}
          >
            {name}
          </p>
          {source === "individual" && (
            <p
              className="text-left font-normal text-sm text-black line-clamp-1 overflow-ellipsis"
              style={{ lineHeight: "20px" }}
            >
              {degree}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
