import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Layout from "../../../components/Layout";

import Pagination from "../../../components/pagination/Pagination";
import { authState } from "../../../atoms/authState";
import Button from "../../../components/base/Button";
import { StarIcon } from "@heroicons/react/solid";
import FavoriteButton from "../../../components/FavoriteButton";
import CardUniversity from "../../../components/CardUniversity";
import { api, apiAnom } from "../../../utils/api";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  HeartIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { HeartIcon as HeartIconFilled } from "@heroicons/react/solid";
import HomeCardUniv from "../../../components/HomeCardUniv";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import Slider from "react-slick";
import { MdClose } from "react-icons/md";
import ReactSelect from "react-select";
import DropdownItem from "../../../components/DropdownItem";
import PageLoading from "../../PageLoading";
import CardMajorSubject from "../../../components/CardMajorSubject";
import LayoutV2 from "../../../components/Layout_V2";
import HomeCardUnivV3 from "../../../components/HomeCardUnivV3";

export default function MajorDetailSlugV2() {
  const sliderRef = useRef();
  const sliderRefCareer = useRef();
  let { slug } = useParams();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;

  const [currentSubjectsPage, setCurrentSubjectsPage] = useState(1);
  const [subjectsTotalPage, setSubjectsTotalPage] = useState(1);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [listData, setListData] = useState();
  const [checkedMatkul, setCheckedMatkul] = useState([]);
  const [checkedMatkulWajib, setCheckedMatkulWajib] = useState(false);
  const [checkedMatkulPilihan, setCheckedMatkulPilihan] = useState(false);
  const [currentSliderPage, setCurrentSliderPage] = useState(1);
  const [faved, setFaved] = useState(false);

  useEffect(() => {
    const getMajorDetail = () => {
      _api
        .get(`/explore-jurusan/${slug}`)
        .then((res) => {
          setListData(res.data.data);
          setSubjects(res.data.data.subjects_v2);
          var selectedUni = res.data.data.subjects_v2.map(
            (item, index) => item.univ_name
          )[0];
          setSelectedUni(selectedUni);
          setSubjectsFiltered(
            res.data.data.subjects_v2.filter(
              (subject) => subject.univ_name === selectedUni
            )
          );
          setSubjectsTotalPage(
            res.data.data.subjects_v2.filter(
              (subject) => subject.univ_name === selectedUni
            ).length
          );
          setIsFavorite(res.data.data.favorite);
          setUnis([
            ...new Set(
              res.data.data.subjects_v2.map((item, index) => item.univ_name)
            ),
          ]);
          document.title = `Explore Jurusan - ${res.data.data.name.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ""
          )}`;
        })
        .catch((_) => {
          setListData({});
          navigate("*");
        });
    };
    getMajorDetail();
  }, [_api, auth.isAuth, slug]);

  function handleChangeWajib() {
    setCheckedMatkulWajib(!checkedMatkulWajib);
  }

  function handleChangePilihan() {
    setCheckedMatkulPilihan(!checkedMatkulPilihan);
  }

  useEffect(() => {
    const subjectByUni = subjects?.filter(
      (subject) => subject.univ_name === selectedUni
    );

    if (checkedMatkulWajib) {
      const subjectWajib = subjectByUni?.filter(
        (subject) => subject.type === "Wajib"
      );
      setSubjectsFiltered(subjectWajib);
      setSubjectsTotalPage(subjectWajib.length);
    } else if (checkedMatkulPilihan) {
      const subjectPilihan = subjectByUni?.filter(
        (subject) => subject.type === "Pilihan"
      );
      setSubjectsFiltered(subjectPilihan);
      setSubjectsTotalPage(subjectPilihan.length);
    }

    if (checkedMatkulWajib === checkedMatkulPilihan) {
      setSubjectsFiltered(subjectByUni);
      setSubjectsTotalPage(subjectByUni.length);
    }
  }, [checkedMatkulWajib, checkedMatkulPilihan]);

  function handleMatkulDesktop(matkulType) {
    var isWajib = matkulType === "Wajib";
    var isPilihan = matkulType === "Pilihan";
    if (isWajib) {
      setCheckedMatkulWajib(!checkedMatkulWajib);
    } else if (isPilihan) {
      setCheckedMatkulPilihan(!checkedMatkulPilihan);
    } else {
      const subject2 = subjects?.filter(
        (subject) => subject.univ_name === selectedUni
      );
      setSubjectsFiltered(subject2);
      setSubjectsTotalPage(subject2.length);
    }
  }

  function handleMatkulButtonBG(matkulType) {
    if (matkulType === "Wajib") {
      if (checkedMatkulWajib) {
        return "#43BCCD";
      }
    }
    if (matkulType === "Pilihan") {
      if (checkedMatkulPilihan) {
        return "#43BCCD";
      }
    }
    return "#F1F3F6";
  }

  const [isFavorite, setIsFavorite] = useState(state?.isFavorite ?? false);
  const [subjects, setSubjects] = useState([]);
  const [subjectsFiltered, setSubjectsFiltered] = useState([]);
  const [unis, setUnis] = useState([]);
  const [selectedUni, setSelectedUni] = useState("");

  function handleFilterUni(uniId) {
    setSelectedUni(uniId);
    var subFil = subjects?.filter((subject) => subject.univ_name === uniId);
    setSubjectsFiltered(subFil);
    setSubjectsTotalPage(subFil.length);
  }

  const [isActivityDDOpen, setIsActivityDDOpen] = useState(false);
  const [filterBidang, setFilterBidang] = useState([]);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    // if (checkedMatkulWajib === ) {}
  }

  function openModal() {
    setIsOpen(true);
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;
  const [matkulPageSize, setMatkulPageSize] = useState(
    screenWidth < 1024 ? 5 : 15
  );

  const careerProspectsSetting = {
    dots: false,
    infinite: true,
    slidesToShow:
      listData?.career_prospects_v2.length < 5
        ? listData?.career_prospects_v2.length
        : 5,
    slidesToScroll: 1,
  };

  const reccUnivSetting = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    // autoplaySpeed: 1000,
    easing: "linear",
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (provided, state) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: "1 !important",
      },
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
    }),
  };

  const matkulType = ["Wajib", "Pilihan"];

  const skillsetStyle = (index) => {
    if (index === 1 || index % 4 === 1) {
      return {
        border: "1px solid #F9C60B",
        borderRadius: "12px",
        color: "#F9C60B",
      };
    }
    if (index % 2 === 0 && index % 4 !== 0) {
      return {
        border: "1px solid #43BCCD",
        borderRadius: "12px",
        color: "#43BCCD",
      };
    }
    if (index === 3 || index % 4 === 3) {
      return {
        border: "1px solid #FE5244",
        borderRadius: "12px",
        color: "#FE5244",
      };
    }
    if (index % 2 === 0 && index % 4 === 0) {
      return {
        border: "1px solid #662E9B",
        borderRadius: "12px",
        color: "#662E9B",
      };
    }
  };

  const addMajorToFav = (fav, id) => {
    if (fav && !auth.isAuth) {
      navigate({
        pathname: "/signin",
        search: `?redirect=/explore-jurusan/detail/${id}`,
      });
    }

    const url = fav ? "/favorites/major/add" : "/favorites/major/delete";

    _api
      .post(url, {
        majors_of_universities_id: id,
      })
      .then(() => {
        setIsFavorite(fav);
      })
      .catch(() => {
        setIsFavorite(false);
      });
  };

  if (!listData) {
    return <PageLoading />;
  }

  const prospectColor = (index) => {
    switch (index) {
      case 0:
        return "rgba(253, 82, 68, 1)";
      case 1:
        return "rgba(253, 207, 48, 1)";
      case 2:
        return "rgba(87, 194, 210, 1)";
      default:
        return "black";
    }
  };

  return (
    <LayoutV2 padding="0px">
      <div className="bg-white mt-2 relative">
        <div className="flex flex-row justify-end gap-4 px-4 tablet:px-18 fixed z-80 w-full">
          <button
            className="p-3 bg-white"
            style={{
              boxShadow: "4px 4px 40px rgba(64, 69, 79, 0.16)",
              borderRadius: "8px",
            }}
            aria-hidden="true"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addMajorToFav(!isFavorite, listData.id);
            }}
          >
            {isFavorite ? (
              <HeartIconFilled className="h-7 w-7 text-red-700" />
            ) : (
              <HeartIcon className="h-7 w-7" />
            )}
          </button>
          <button
            onClick={() => {
              navigate("/tes-minat");
            }}
            className={`h-13 bg-white flex justify-center items-center ${
              screenWidth < 1024 ? "w-13" : "gap-2"
            }`}
            style={{
              boxShadow:
                screenWidth < 1024
                  ? "4px 4px 40px rgba(64, 69, 79, 0.16)"
                  : "3.56px 3.56px 35.56px 0px rgba(64, 69, 79, 0.16)",
              borderRadius: "8px",
              width: screenWidth >= 1024 && "115.22px",
            }}
          >
            <img
              src={
                screenWidth < 1024 ? "/icon_tmb.png" : "/icon_tmb_desktop.svg"
              }
              style={{ width: "26px", height: "26px" }}
            />
            {screenWidth >= 1024 && (
              <p
                className="text-left font-semibold text-primaryColor"
                style={{ fontSize: "14.22px", lineHeight: "21.33px" }}
              >
                Tes minat
              </p>
            )}
          </button>
        </div>
        <div className="flex flex-col mt-20 tablet:mt-11">
          <div
            className="flex flex-col tablet:flex-row tablet:justify-between w-full"
            style={{
              paddingRight: screenWidth >= 1024 && "71.11px",
              gap: screenWidth >= 1024 && "43.45px",
            }}
          >
            <div
              className="relative flex flex-col items-end justify-start"
              style={{
                backgroundImage: `url(${
                  screenWidth < 1024
                    ? "/bg_detail_jurusan_mobile.png"
                    : "/bg_detail_jurusan_desktop.svg"
                })`,
                minHeight: screenWidth < 1024 ? "330px" : "353px",
                maxHeight: screenWidth < 1024 ? "330px" : "353px",
                minWidth: screenWidth >= 1024 && "536px",
                maxWidth: screenWidth >= 1024 && "536px",
                paddingTop: screenWidth < 1024 ? "37px" : "39px",
              }}
            >
              <div
                className="flex items-center justify-center tablet:justify-end w-full px-4 tablet:px-6"
                style={{ height: screenWidth < 1024 ? "122px" : "86px" }}
              >
                <p
                  className="text-center tablet:text-right font-bold text-3xl text-white"
                  style={{ lineHeight: "38.4px", letterSpacing: "-1.3px" }}
                >
                  {listData.name}
                </p>
              </div>
              <img
                src={listData.img_url}
                className="w-full absolute bottom-0 tablet:-bottom-16 object-cover"
                style={{
                  marginTop: "33.92px",
                  height: screenWidth < 1024 ? "170.08px" : "248px",
                  borderRadius:
                    screenWidth < 1024 ? "18px" : "0px 18px 18px 0px",
                  maxWidth: screenWidth >= 1024 && "568px",
                  left: screenWidth >= 1024 && "-56.89px",
                }}
              />
            </div>
            <div
              className="flex flex-col items-stretch tablet:items-start w-full mt-5"
              style={{
                paddingLeft: "17px",
                paddingRight: screenWidth < 1024 && "17px",
                maxWidth: screenWidth >= 1024 && "655px",
              }}
            >
              <div
                className="text-left font-normal text-base text-black1"
                dangerouslySetInnerHTML={{ __html: listData?.description }}
                style={{
                  minHeight: screenWidth >= 1024 && "195px",
                  textAlign: "justify",
                }}
              />
              <p
                className="text-left font-normal text-xs italic mt-6"
                style={{ color: "rgba(23, 23, 23, 0.6)" }}
              >
                Sumber: Wikipedia
              </p>
              <div
                className="mt-6"
                style={{
                  paddingLeft: screenWidth < 1024 && "0px",
                  paddingRight: screenWidth < 1024 ? "0px" : "17px",
                }}
              >
                <p
                  className="text-left font-bold text-2xl text-black"
                  style={{ lineHeight: "28.8px", letterSpacing: "-0.71px" }}
                >
                  Prospek Karir
                </p>
                <div
                  className="flex flex-col tablet:flex-row w-full mt-5"
                  style={{ gap: "14px" }}
                >
                  {listData.career_prospects.slice(0, 3).map((item, index) => (
                    <div
                      className="flex items-center justify-center"
                      key={index}
                      style={{
                        height: screenWidth < 1024 && "29px",
                        backgroundColor: prospectColor(index),
                        borderRadius: "7.18px",
                        paddingTop: screenWidth >= 1024 && "3.36px",
                        paddingBottom: screenWidth >= 1024 && "3.36px",
                        paddingLeft: screenWidth >= 1024 && "10.77px",
                        paddingRight: screenWidth >= 1024 && "10.77px",
                      }}
                    >
                      <p
                        className={`text-center font-medium ${
                          index === 1 ? "text-black" : "text-white"
                        }`}
                        style={{ fontSize: "14.36px", lineHeight: "21.54px" }}
                      >
                        {item}
                      </p>
                    </div>
                  ))}
                </div>
                {screenWidth >= 1024 && (
                  <div
                    className="flex flex-row w-full"
                    style={{ gap: "14px", marginTop: "14px" }}
                  >
                    {listData.career_prospects
                      .slice(2, 5)
                      .map((item, index) => (
                        <div
                          className="flex items-center justify-center"
                          key={index}
                          style={{
                            height: screenWidth < 1024 && "29px",
                            backgroundColor: prospectColor(index),
                            borderRadius: "7.18px",
                            paddingTop: screenWidth >= 1024 && "3.36px",
                            paddingBottom: screenWidth >= 1024 && "3.36px",
                            paddingLeft: screenWidth >= 1024 && "10.77px",
                            paddingRight: screenWidth >= 1024 && "10.77px",
                          }}
                        >
                          <p
                            className={`text-center font-medium ${
                              index === 1 ? "text-black" : "text-white"
                            }`}
                            style={{
                              fontSize: "14.36px",
                              lineHeight: "21.54px",
                            }}
                          >
                            {/* sampel konten */}
                            {item}
                          </p>
                        </div>
                      ))}
                  </div>
                )}
                {screenWidth >= 1024 && (
                  <div
                    className="flex flex-row w-full"
                    style={{ gap: "14px", marginTop: "14px" }}
                  >
                    {listData.career_prospects
                      .slice(5, 8)
                      .map((item, index) => (
                        <div
                          className="flex items-center justify-center"
                          key={index}
                          style={{
                            height: screenWidth < 1024 && "29px",
                            backgroundColor: prospectColor(index),
                            borderRadius: "7.18px",
                            paddingTop: screenWidth >= 1024 && "3.36px",
                            paddingBottom: screenWidth >= 1024 && "3.36px",
                            paddingLeft: screenWidth >= 1024 && "10.77px",
                            paddingRight: screenWidth >= 1024 && "10.77px",
                          }}
                        >
                          <p
                            className={`text-center font-medium ${
                              index === 1 ? "text-black" : "text-white"
                            }`}
                            style={{
                              fontSize: "14.36px",
                              lineHeight: "21.54px",
                            }}
                          >
                            {/* sampel konten */}
                            {item}
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {listData?.univ_recommendations?.length > 0 && (
          <div
            className="relative pt-5 mt-8 bg-primaryColor"
            style={{
              minWidth: screenWidth,
              maxWidth: screenWidth,
              minHeight: screenWidth >= 1024 && "531px",
              maxHeight: screenWidth >= 1024 && "531px",
              paddingBottom: screenWidth >= 1024 && "54px",
            }}
          >
            <img
              className={`absolute top-0 ${
                screenWidth < 1024 ? "left-0" : "-left-40"
              } h-full w-full object-cover`}
              src="/bg-bubble-purple.svg"
            />
            <div className="relative z-10 tablet:flex tablet:flex-row tablet:h-auto tablet:w-full tablet:justify-between tablet:items-center px-4 tablet:px-20">
              <p className="text-left font-bold text-2xl tablet:text-48px text-white z-30">
                Rekomendasi Kampus
              </p>
            </div>
            {screenWidth >= 1024 ? (
              <div className="px-20 mt-10">
                {listData?.univ_recommendations.length < 4 ? (
                  <div className="w-full flex flex-row justify-start gap-4">
                    {listData?.univ_recommendations.map((item, index) => {
                      return (
                        <HomeCardUnivV3
                          key={index}
                          id={item.id}
                          univData={item}
                          fav={item.favorite}
                          popular={true}
                          width={
                            screenWidth < 1440
                              ? "272.54px"
                              : screenWidth < 1910
                              ? "310px"
                              : "430px"
                          }
                          height={
                            screenWidth < 1440
                              ? "281.13px"
                              : screenWidth < 1910
                              ? "317px"
                              : "437px"
                          }
                          screenWidth={screenWidth}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <Slider ref={sliderRef} {...reccUnivSetting}>
                    {listData?.univ_recommendations.map((item, index) => {
                      return (
                        <HomeCardUnivV3
                          key={index}
                          id={item.id}
                          univData={item}
                          fav={item.favorite}
                          popular={true}
                          width={
                            screenWidth < 1440
                              ? "272.54px"
                              : screenWidth < 1910
                              ? "310px"
                              : "430px"
                          }
                          height={
                            screenWidth < 1440
                              ? "281.13px"
                              : screenWidth < 1910
                              ? "317px"
                              : "437px"
                          }
                          screenWidth={screenWidth}
                        />
                      );
                    })}
                  </Slider>
                )}
              </div>
            ) : (
              <div className="flex flex-row gap-6 tablet:gap-6 overflow-x-auto pb-4 mt-10 px-4">
                {listData?.univ_recommendations.map((item, index) => {
                  return (
                    <div key={index}>
                      <HomeCardUniv
                        id={item.id}
                        univData={item}
                        width="324.95px"
                        height="428.04px"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {/* Next-Prev Button */}
            {screenWidth >= 1024 &&
              listData?.univ_recommendations.length > 4 && (
                <div className="w-full flex flex-row gap-2 justify-center items-center absolute bottom-10">
                  <button
                    onClick={() => {
                      sliderRef.current.slickPrev();
                    }}
                    className="bg-white rounded-lg p-3"
                  >
                    <ArrowLeftIcon
                      className="w-4 h-4 mac:w-5 mac:h-5"
                      color="rgba(23, 23, 23, 0.4)"
                    />
                  </button>
                  <button
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                    className="bg-white rounded-lg p-3"
                  >
                    <ArrowRightIcon
                      className="w-4 h-4 mac:w-5 mac:h-5"
                      color="rgba(23, 23, 23, 0.4)"
                    />
                  </button>
                </div>
              )}
            <div className="relative z-10 tablet:flex tablet:w-full tablet:justify-end px-4 tablet:px-20 mt-9">
              <Link
                to={"/explore-kampus"}
                className="flex items-center justify-center w-full tablet:w-max px-6 py-3 bg-white text-center font-semibold text-base text-primaryColor"
                style={{ borderRadius: "8px" }}
              >
                <div className="flex flex-row items-center justify-center gap-3">
                  <p>Lihat kampus lainnya</p>
                  <ArrowRightIcon className="h-5 w-auto" />
                </div>
              </Link>
            </div>
          </div>
        )}
        {listData?.subjects_v2?.length > 0 && (
          <div className="flex flex-col w-full">
            <div className="flex flex-col mt-8 tablet:mt-7 px-4 bg-white">
              <div className="flex flex-col items-start tablet:items-center">
                <div
                  className="tablet:flex tablet:items-center"
                  style={{
                    height: screenWidth >= 1024 && "103px",
                  }}
                >
                  <p
                    className="text-left tablet:text-center font-bold text-2xl tablet:text-3xl text-black1"
                    style={{
                      lineHeight: screenWidth < 1024 ? "38.4px" : "28.8px",
                      letterSpacing: screenWidth < 1024 ? "-0.71px" : "-0.86px",
                      width: screenWidth >= 1024 && "803px",
                    }}
                  >{`Di jurusan ${listData?.name} bakal belajar apa aja, ya?`}</p>
                </div>
                <p
                  className="text-left tablet:text-center font-normal text-base tablet:text-xl tablet:w-1/2 text-black1"
                  style={{
                    whiteSpace: "pre-line",
                    marginTop: screenWidth < 1024 && "17.12px",
                  }}
                >
                  {`${
                    screenWidth < 1024
                      ? `Yuk explore daftar mata kuliah berdasarkan rekomendasi universitas di bawah ini`
                      : `Yuk explore daftar mata kuliah berdasarkan \nrekomendasi universitas di bawah ini`
                  }`}
                </p>
              </div>
            </div>
            {/* matkul by univ */}
            {screenWidth < 1024 ? (
              <div className="relative flex flex-row w-full items-start justify-center px-4 bg-gradient-to-b from-white to-white2">
                <div className="flex flex-col w-full">
                  <Listbox value={selectedUni} onChange={setSelectedUni}>
                    <Listbox.Button
                      className="relative h-12 w-full py-3 px-auto rounded-lg bg-tosca2 mt-8"
                      onClick={() => {
                        setIsActivityDDOpen(!isActivityDDOpen);
                      }}
                    >
                      <p className="text-center text-white text-base font-semibold">
                        {selectedUni}
                      </p>
                      {!isActivityDDOpen ? (
                        <ChevronDownIcon
                          className="w-6 h-6 object contain absolute top-3 right-3"
                          style={{ color: "#FFFFFF" }}
                        />
                      ) : (
                        <ChevronUpIcon
                          className="w-6 h-6 object contain absolute top-3 right-3"
                          style={{ color: "#FFFFFF" }}
                        />
                      )}
                    </Listbox.Button>
                    <Listbox.Options className="bg-white rounded-b-lg pb-7 px-4 pt-2">
                      {unis
                        ?.filter((uni) => uni !== selectedUni)
                        .map((uni, index) => (
                          <Listbox.Option
                            key={index}
                            value={uni}
                            className="text-center text-tosca2 text-base font-semibold py-3 px-auto rounded-lg bg-backgroundGray4 mt-4"
                            // disabled={tab.unavailable}
                            onClick={() => {
                              setSelectedUni(uni);
                              setIsActivityDDOpen(!isActivityDDOpen);
                            }}
                          >
                            {uni}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Listbox>
                </div>
                <div className="flex items-center justify-center mt-10 px-2">
                  <button type="button" onClick={openModal}>
                    <img src="/filter-icon.svg" className="w-8 h-8" />
                  </button>
                  <Transition appear show={isOpen} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={() => {}}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div
                          className="fixed inset-0"
                          style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
                        />
                      </Transition.Child>
                      <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Dialog.Panel
                              className="flex w-screen transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                              style={{
                                minHeight: screenHeight - 35,
                                maxHeight: screenHeight - 35,
                              }}
                            >
                              <div className="relative flex min-h-screen w-full flex-col overflow-y-auto">
                                <button type="button" onClick={closeModal}>
                                  <MdClose
                                    size={24}
                                    color="rgba(0, 0, 0, 0.54)"
                                  />
                                </button>
                                <p className="text-left font-bold text-base text-black1 mt-14">
                                  Tipe Mata Kuliah
                                </p>
                                <div className="flex flex-col items-start px-2 mt-4">
                                  <div
                                    key="wjb"
                                    className="flex items-center mb-4"
                                  >
                                    <label
                                      htmlFor="matkulType-wjb"
                                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                      <input
                                        id="matkulType-wjb"
                                        type="checkbox"
                                        value="wjb"
                                        onChange={handleChangeWajib}
                                        checked={checkedMatkulWajib}
                                        className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      ></input>
                                      <span className="ml-2 text-left font-normal text-sm text-black1">
                                        Wajib
                                      </span>
                                    </label>
                                  </div>
                                  <div
                                    key="plh"
                                    className="flex items-center mb-4"
                                  >
                                    <label
                                      htmlFor="matkulType-plh"
                                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                      <input
                                        id="matkulType-plh"
                                        type="checkbox"
                                        value="plh"
                                        onChange={handleChangePilihan}
                                        checked={checkedMatkulPilihan}
                                        className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      ></input>
                                      <span className="ml-2 text-left font-normal text-sm text-black1">
                                        Pilihan
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <button
                                  onClick={closeModal}
                                  className="absolute flex items-center justify-center w-full h-12 rounded-lg bg-primaryColor bottom-20"
                                >
                                  <p className="font-semibold text-base text-center text-white">
                                    Terapkan filter pencarian
                                  </p>
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-4 items-center justify-start mt-10 bg-white px-20">
                <div className="flex flex-row gap-4 items-center justify-center">
                  {matkulType.map((item, index) => (
                    <button
                      key={index}
                      className="text-center font-semibold text-base px-6 py-3"
                      onClick={() => {
                        handleMatkulDesktop(item);
                        setCurrentSubjectsPage(1);
                      }}
                      style={{
                        backgroundColor: `${handleMatkulButtonBG(item)}`,
                        borderRadius: "8px",
                        color: `${
                          item === "Wajib"
                            ? "rgba(102, 46, 155, 1)"
                            : "rgba(254, 82, 68, 1)"
                        }`,
                      }}
                    >
                      {item}
                    </button>
                  ))}
                </div>
                <div
                  className="h-10"
                  style={{ border: "1px solid rgba(0, 0, 0, 0.3)" }}
                ></div>
                <div className="flex flex-row gap-4 items-center justify-center overflow-x-auto">
                  {unis?.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        handleFilterUni(item);
                        setCheckedMatkulPilihan(false);
                        setCheckedMatkulWajib(false);
                        setCurrentSubjectsPage(1);
                      }}
                      className={`text-center font-semibold text-base ${
                        selectedUni === item
                          ? "text-white bg-tosca2"
                          : "text-tosca2 bg-backgroundGray4"
                      } px-6 py-3`}
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {screenWidth >= 1024 && (
              <div
                className="bg-gradient-to-b from-white to-white2"
                style={{ height: "31.44px" }}
              />
            )}
            <div
              className="flex flex-col pb-10 bg-white2 h-full"
              style={{
                paddingLeft: screenWidth < 1024 ? "16px" : "71.11px",
                paddingRight: screenWidth < 1024 ? "16px" : "80px",
              }}
            >
              <div className="pb-4 grid tablet:grid-cols-3 gap-5">
                {subjectsFiltered
                  .slice(
                    (currentSubjectsPage - 1) * matkulPageSize,
                    (currentSubjectsPage - 1) * matkulPageSize + matkulPageSize
                  )
                  .map((item, index) => (
                    <CardMajorSubject
                      key={index}
                      item={item}
                      screenWidth={screenWidth}
                    />
                  ))}
              </div>
              <Pagination
                currentPage={currentSubjectsPage}
                totalCount={subjectsTotalPage}
                pageSize={matkulPageSize}
                onPageChange={(page) => setCurrentSubjectsPage(page)}
                noBG={true}
              />
            </div>
          </div>
        )}
        <div
          className="flex flex-col justify-start items-center relative"
          style={{
            backgroundImage: `url(${
              screenWidth < 1024
                ? "/bg_detail_jurusan_footer_mobile.png"
                : "/bg_detail_jurusan_footer_desktop.svg"
            })`,
            minHeight: screenWidth < 1024 ? "560px" : "430px",
            maxHeight: screenWidth < 1024 ? "560px" : "430px",
            paddingLeft: "17.33px",
            paddingRight: "17.33px",
            paddingTop: screenWidth < 1024 ? "76px" : "116.5px",
          }}
        >
          <p
            className="text-center font-bold text-2xl text-white"
            style={{
              lineHeight: screenWidth < 1024 ? "28.8px" : "51.2px",
              letterSpacing: screenWidth < 1024 ? "-1.3px" : "-0.71px",
              maxWidth: screenWidth >= 1024 && "720px",
              fontSize: screenWidth >= 1024 && "42.67px",
            }}
          >
            {`Gimana sobat, apakah ${listData?.name} bakal jadi jurusan impianmu?`}
          </p>
          <button
            onClick={() => {
              navigate("/tes-minat");
            }}
            className="bg-primaryColor px-19 py-4 mt-13"
            style={{
              borderRadius: "8.67px",
              marginTop: screenWidth >= 1024 && "54.5px",
              padding:
                screenWidth >= 1024 && "10.67px, 17.78px, 10.67px, 21.33px",
              paddingTop: screenWidth >= 1024 && "10.67px",
              paddingRight: screenWidth >= 1024 && "54.22px",
              paddingBottom: screenWidth >= 1024 && "10.67px",
              paddingLeft: screenWidth >= 1024 && "57.78px",
            }}
          >
            <p
              className="text-center font-semibold text-base tablet:text-base text-white"
              style={{ lineHeight: screenWidth < 1024 ? "24px" : "21.33px" }}
            >
              Tes Minat
            </p>
          </button>
          <img
            src={
              screenWidth < 1024
                ? "/illu_detail_jurusan_footer_mobile.png"
                : "/illu_detail_jurusan_footer_desktop.png"
            }
            className="absolute bottom-0 tablet:right-0"
          />
        </div>
      </div>
    </LayoutV2>
  );
}
