import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ButtonV2 from "../../../../components/ButtonV2";

export default function MiniQuizEnding({
  onBack,
  onNext,
  screenWidth,
  quizResult,
}) {
  const navigate = useNavigate();
  const [result, setResult] = useState();

  useEffect(() => {
    if (quizResult) {
      setResult(quizResult);
    }
  }, [quizResult]);

  const resultIllu = (result) => {
    switch (result) {
      case "IPA":
        return "/tmb_miniquiz/illu_result_baloon_ipa.png";
      case "IPS":
        return "/tmb_miniquiz/illu_result_baloon_ips.png";
      case "IPA,IPS":
        return "/tmb_miniquiz/illu_result_baloon_ipc.png";
      default:
        return "/tmb_miniquiz/illu_result_baloon_ipc.png";
    }
  };

  const resultText = (result) => {
    switch (result) {
      case "IPA":
        return "jurusan IPA";
      case "IPS":
        return "jurusan IPS";
      case "IPA,IPS":
        return "keduanya";
      default:
        return "keduanya";
    }
  };

  return (
    <div className="flex flex-col w-full h-full items-center relative z-50">
      <button className="absolute top-13 left-9">
        <MdArrowBack
          onClick={onBack}
          className="w-7 sm:w-10 h-auto"
          style={{ color: "white" }}
        />
      </button>
      <div
        className="flex flex-col w-full items-center"
        style={{
          marginTop: quizResult === "IPA,IPS" ? "61px" : "44px",
          maxWidth: `${screenWidth < 1024 ? "287px" : "524px"}`,
          lineHeight: `${screenWidth < 1024 ? "20px" : "40px"}`,
        }}
      >
        <img src={resultIllu(result)} className="w-max" />
        <p
          className="text-center font-bold text-base text-white"
          style={{
            maxWidth: screenWidth < 1024 && "247px",
            lineHeight: "20.16px",
            marginTop: quizResult === "IPA,IPS" ? "10.11px" : "28px",
          }}
        >
          Wah, dari hasil jawaban kamu sepertinya kamu cocok dengan{" "}
          {resultText(result)} loh!
        </p>
        <div className="w-full flex justify-end mt-8 sm:mr-4">
          <ButtonV2
            onClick={onNext}
            title="Lanjut Tes Minat (RIASEC)"
            customStyle={{
              maxWidth: screenWidth < 1024 ? "252px" : "221px",
              backgroundColor: "rgba(255, 202, 45, 1)",
              padding: "4px",
            }}
            customTextStyle={{ color: "black" }}
          />
        </div>
      </div>
    </div>
  );
}
