/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { usePagination, DOTS } from "./usePagination";

export default function Pagination(props) {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    noBG,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div
      className={`${
        !noBG && "bg-white"
      } py-3 flex items-center justify-between sm:px-6 sm:mx-8 sm:my-8`}
    >
      <div className=" flex-1 flex items-center justify-center">
        <div>
          <nav
            className="relative z-0 inline-flex  -space-x-px"
            aria-label="Pagination"
          >
            {currentPage === 1 ? (
              <a className="relative inline-flex items-center px-2 py-2 rounded-l-md  text-sm font-medium text-gray-500">
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            ) : (
              <a
                className="relative inline-flex items-center px-2 py-2 rounded-l-md  text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                onClick={onPrevious}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            )}
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white text-gray-500 hover:bg-gray-50" */}
            {paginationRange.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <span
                    key={index}
                    className={`relative inline-flex items-center px-4 py-2 ${
                      !noBG && "bg-white"
                    } text-sm font-medium text-gray-700 select-none`}
                  >
                    ...
                  </span>
                );
              }
              return (
                <a
                  key={index}
                  onClick={() => onPageChange(pageNumber)}
                  className={`
                    ${
                      pageNumber === currentPage
                        ? "bg-primaryColor text-white rounded"
                        : `${!noBG && "bg-white"} text-gray-500`
                    }
                    relative inline-flex items-center px-4 py-2 text-sm font-medium cursor-pointer select-none
                    `}
                >
                  {pageNumber}
                </a>
              );
            })}
            {currentPage === lastPage ? (
              <a className="relative inline-flex items-center px-2 py-2 rounded-l-md  text-sm font-medium text-gray-500">
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            ) : (
              <a
                className="relative inline-flex items-center px-2 py-2 rounded-l-md  text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                onClick={onNext}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
