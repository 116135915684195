import { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { api } from "../utils/api";
import PaymentInfoAccordion from "../components/PaymentInfoAccordion";
import { MenuItem } from "@mui/base";
import PageLoading from "./PageLoading";
import moment from "moment-timezone";
import "moment/locale/id.js";
import LayoutV2 from "../components/Layout_V2";

export default function Invoice() {
  moment.locale("id");
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );

  const [isLoading, setIsLoading] = useState(true);
  const param = new URLSearchParams(window.location.search);
  const invoiceNumber = param.get("invoice_number");
  const auth = useRecoilValue(authState);
  const virtualAccountRef = useRef(null);
  const [virtualAccountNumber, setVirtualAccountNumber] = useState("");
  const totalFeeRef = useRef(null);
  const [totalFee, setTotalFee] = useState(151000000);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [invoiceData, setInvoiceData] = useState();
  const [expiredDate, setExpiredDate] = useState("");
  const [startDate, setStartDate] = useState("");
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  let interval = useRef();

  useEffect(() => {
    api
      .post(`/students/univ-admission/invoice/detail`, {
        invoice_number: invoiceNumber,
      })
      .then((res) => {
        setInvoiceData(res.data.data);
        setStartDate(new Date(res.data.data.va_expired).getTime());
        setVirtualAccountNumber(res.data.data.va_number);
        setExpiredDate(
          moment(res.data.data.va_expired).format("dddd, DD MMMM YYYY HH:MM")
        );
        setTotalFee(res.data.data.total_price);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      })
      .catch((_) => {
        setInvoiceData({});
      });

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  function Separator({ margin }) {
    return (
      <div
        className={`flex w-full border-1 ${margin}`}
        style={{ borderColor: "rgba(217, 219, 219, 1)" }}
      />
    );
  }

  const startTimer = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      var distance = startDate - now;

      const remainingDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      const remainingHours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const remainingMinutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      const remainingSeconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setDays(remainingDays);
        setHours(remainingHours);
        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, [isLoading]);

  const handleVACopy = async () => {
    if (virtualAccountRef.current) {
      try {
        await navigator.clipboard.writeText(virtualAccountNumber);
      } catch (err) {
        console.error("Error copying text: ", err);
      }
    }
  };

  const handleTotalFeeCopy = async () => {
    if (totalFeeRef.current) {
      try {
        await navigator.clipboard.writeText(totalFee);
      } catch (err) {
        console.error("Error copying text: ", err);
      }
    }
  };

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0px">
      <div
        className="flex flex-col tablet:flex-row w-full items-start justify-start tablet:justify-evenly gap-10 px-5 tablet:px-20 pt-4 tablet:pt-20 pb-4 tablet:pb-36"
        style={{ backgroundColor: "rgba(241, 243, 246, 1)" }}
      >
        <div className="flex flex-col w-full p-4 mac:p-10 bg-white rounded-20px">
          <p
            className="text-left font-bold text-xl mac:text-2xl text-black1"
            style={{ lineHeight: screenWidth < 1024 ? "32px" : "36px" }}
          >
            Informasi Pembayaran
          </p>
          <Separator margin={"mt-4"} />
          <div
            className="flex flex-col items-center justify-center gap-2 rounded-3xl mt-6 py-6"
            style={{ backgroundColor: "rgba(249, 249, 249, 1)" }}
          >
            <p
              className="text-center font-bold text-sm mac:text-base text-black1"
              style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
            >
              Selesaikan pembayaran dalam
            </p>
            <p
              className="text-center font-bold text-xl mac:text-3xl"
              style={{
                color: "rgba(254, 82, 68, 1)",
                lineHeight: screenWidth < 1024 ? "32px" : "44px",
              }}
            >
              {`${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
                seconds < 10 ? "0" : ""
              }${seconds}`}
            </p>
            <p
              className="text-center font-normal text-sm mac:text-base text-black1"
              style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
            >
              Batas akhir pembayaran{" "}
              {screenWidth >= 1024 && (
                <span className="font-bold">{expiredDate}</span>
              )}
            </p>
            {screenWidth < 1024 && (
              <p className="text-center font-extrabold text-sm text-black1">
                {expiredDate}
              </p>
            )}
          </div>
          <div
            className="flex flex-col w-full gap-5 p-5 mt-6 border-1 rounded-2xl"
            style={{ borderColor: "rgba(217, 219, 219, 1)" }}
          >
            <div className="flex flex-row justify-between items-center">
              <p
                className="text-left font-extrabold text-sm mac:text-base text-black1"
                style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
              >
                Virtual Account
              </p>
              <img
                src="./logo_ocbc_va.svg"
                className="h-4 mac:h-5 object-contain"
              />
            </div>
            <Separator />
            <div className="flex flex-row gap-2 tablet:gap-0 justify-between items-center">
              <div className="flex flex-col justify-center items-start">
                <p
                  className="text-left font-normal text-sm mac:text-base"
                  style={{
                    lineHeight: screenWidth < 1024 ? "20px" : "24px",
                    color: "rgba(123, 128, 130, 1)",
                  }}
                >
                  Nomor Virtual Account
                </p>
                <p
                  ref={virtualAccountRef}
                  className="text-left font-extrabold text-sm mac:text-base text-black1"
                  style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
                >
                  {virtualAccountNumber}
                </p>
              </div>
              <button
                onClick={() => {
                  handleVACopy();
                }}
                className="flex flex-row items-center justify-end gap-1"
              >
                <p
                  className="text-left font-extrabold text-xs mac:text-base"
                  style={{ lineHeight: "24px", color: "rgba(101, 46, 151, 1)" }}
                >
                  Salin
                </p>
                <img
                  src="./icon_copy.svg"
                  className="h-3 mac:h-5 object-contain"
                />
              </button>
            </div>
            <Separator />
            <div className="flex flex-row gap-2 tablet:gap-0 justify-between items-center">
              <div className="flex flex-col justify-center items-start">
                <p
                  className="text-left font-normal text-sm mac:text-base"
                  style={{
                    lineHeight: screenWidth < 1024 ? "20px" : "24px",
                    color: "rgba(123, 128, 130, 1)",
                  }}
                >
                  Total Pembayaran
                </p>
                <div className="flex flex-row justify-start items-center gap-2">
                  <p
                    ref={totalFeeRef}
                    className="text-left font-extrabold text-sm mac:text-base text-black1"
                    style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
                  >
                    {`Rp ${invoiceData.total_price.toLocaleString()}`}
                  </p>
                  <button
                    onClick={() => {
                      handleTotalFeeCopy();
                    }}
                    style={{ width: "14px", height: "16px" }}
                  >
                    <img
                      src="./icon_copy_grey.svg"
                      className="h-full w-full object-contain"
                    />
                  </button>
                </div>
              </div>
              {/* <button
                className="text-left font-extrabold text-xs mac:text-base"
                style={{ lineHeight: "24px", color: "rgba(101, 46, 151, 1)" }}
              >
                Lihat Detail
              </button> */}
            </div>
            <Separator />
          </div>
          <p
            className="text-left font-bold textxl mac:text-2xl text-black1 mt-6"
            style={{ lineHeight: screenWidth < 1024 ? "32px" : "36px" }}
          >
            Cara Pembayaran
          </p>
          <Separator margin={"mt-4 mb-2"} />
          <PaymentInfoAccordion title="ATM Bank OCBC NISP">
            <div className="flex flex-row justify-start items-start gap-3">
              <p>1.</p>
              <p>Masukkan kartu debit OCBC NISP Anda.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>2.</p>
              <p>Pilih Menu Pembayaran.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>3.</p>
              <p>
                Pilih Pembayaran Lainnya dan pilih Biller UNIVERSITAS X (12345).
              </p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>4.</p>
              <p>Masukkan nomor Virtual Account Anda.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>5.</p>
              <p>
                Periksa dan konfirmasi kembali detail pembayaran Anda (nominal
                serta nama Universitas).
              </p>
            </div>
          </PaymentInfoAccordion>
          <PaymentInfoAccordion title="ONe Mobile / Internet Banking Bank OCBC NISP">
            <div className="flex flex-row justify-start items-start gap-3">
              <p>1.</p>
              <p>Pilih menu Pembayaran dan Pembelian.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>2.</p>
              <p>Pilih Pembayaran Tagihan dan pilih Pembayaran Lainnya.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>3.</p>
              <p>Pilih Kategori: VA Payment</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>4.</p>
              <p>Masukkan nomor Virtual Account dalam kolom ID Pelanggan.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>5.</p>
              <p>
                Periksa dan konfirmasi kembali detail pembayaran Anda (nominal
                serta nama Universitas).{" "}
              </p>
            </div>
          </PaymentInfoAccordion>
          <PaymentInfoAccordion title="Melalui Cabang Bank Lain">
            <div className="flex flex-row justify-start items-start gap-3">
              <p>1.</p>
              <p>
                Pilih formulir untuk pengiriman dana ke Bank lain melalui menu
                SKN dan RTGS.
              </p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>2.</p>
              <p>
                Tulis Bank OCBC NISP sebagai bank tujuan (atau masukkan Kode
                Bank OCBC NISP: 028 / Kode RTGS OCBC NISP: NISPIDJA).
              </p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>3.</p>
              <p>
                Masukkan info “Nomor Rekening” tujuan dengan nomor Virtual
                Account Anda. Format Kode Institusi UNIVERSITAS X (12345) +
                Nomor pelanggan Anda. Contoh: 12345XXXXXXXXXXX
              </p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>4.</p>
              <p>Tulis nama pelanggan sebagai nama penerima.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>5.</p>
              <p>Tulis nominal sesuai dengan tagihan Anda.</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>6.</p>
              <p>Periksa kembali pembayaran Virtual Account Anda.</p>
            </div>
          </PaymentInfoAccordion>
          <PaymentInfoAccordion title="Melalui ATM Bersama/Prima/Internet Banking/Mobile Banking Bank Lain ">
            <div className="flex flex-row justify-start items-start gap-3">
              <p>1.</p>
              <p>Pilih menu Transfer Antar Bank (menu Online Transfer).</p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>2.</p>
              <p>
                Pilih Bank OCBC NISP sebagai bank tujuan (atau masukkan Kode
                Bank OCBC NISP: 028).
              </p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>3.</p>
              <p>
                Masukkan info “Nomor Rekening” tujuan dengan nomor Virtual
                Account Anda. Format Kode Institusi UNIVERSITAS X (12345) +
                Nomor pelanggan Anda. Contoh: 12345XXXXXXXXXXX
              </p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>4.</p>
              <p>
                Periksa kembali jumlah pembayaran Anda (pastikan jumlah yang
                tertera sudah sesuai dengan nominal tagihan).
              </p>
            </div>
            <div className="flex flex-row justify-start items-start gap-3">
              <p>5.</p>
              <p>Periksa kembali pembayaran Virtual Account Anda. </p>
            </div>
          </PaymentInfoAccordion>
        </div>
        {screenWidth >= 1024 && (
          <div className="flex flex-col w-full p-4 mac:p-10 bg-white rounded-20px">
            <p
              className="text-left font-bold text-xl mac:text-2xl text-black1"
              style={{ lineHeight: screenWidth < 1024 ? "32px" : "36px" }}
            >
              Ringkasan Pembelian
            </p>
            <Separator margin={"mt-4"} />
            <div
              className="flex flex-col w-full gap-5 p-5 mt-6 border-1 rounded-2xl"
              style={{ borderColor: "rgba(217, 219, 219, 1)" }}
            >
              <div className="flex flex-row justify-between items-center">
                <p
                  className="text-left font-bold text-base text-black1"
                  style={{ lineHeight: "24px" }}
                >
                  {invoiceData.admission_name}
                </p>
                <p
                  className="text-left font-bold text-base text-black1"
                  style={{ lineHeight: "24px" }}
                >
                  {`Rp ${invoiceData.total_price.toLocaleString()}`}
                </p>
              </div>
              {/* <Separator />
              <div className="flex flex-row justify-between items-center">
                <p
                  className="text-left font-normal text-base"
                  style={{
                    lineHeight: "24px",
                    color: "rgba(123, 128, 130, 1)",
                  }}
                >
                  Biaya Transaksi
                </p>
                <p
                  className="text-left font-bold text-base text-black1"
                  style={{ lineHeight: "24px" }}
                >
                  Rp1.000
                </p>
              </div> */}
            </div>
          </div>
        )}
      </div>
      {screenWidth < 1024 && (
        <div className="flex flex-col w-full py-10 px-5 bg-white">
          <p
            className="text-left font-bold text-xl mac:text-2xl text-black1"
            style={{ lineHeight: screenWidth < 1024 ? "32px" : "36px" }}
          >
            Ringkasan Pembelian
          </p>
          <Separator margin={"mt-4"} />
          <div
            className="flex flex-col w-full gap-5 p-5 mt-6 border-1 rounded-2xl"
            style={{ borderColor: "rgba(217, 219, 219, 1)" }}
          >
            <div className="flex flex-row justify-between items-center">
              <p
                className="text-left font-bold text-sm mac:text-base text-black1 w-1/2"
                style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
              >
                {invoiceData.admission_name}
              </p>
              <p
                className="text-left font-bold text-sm mac:text-base text-black1"
                style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
              >
                {`Rp ${invoiceData.total_price.toLocaleString()}`}
              </p>
            </div>
            {/* <Separator />
            <div className="flex flex-row justify-between items-center">
              <p
                className="text-left font-normal text-sm mac:text-base"
                style={{
                  lineHeight: screenWidth < 1024 ? "20px" : "24px",
                  color: "rgba(123, 128, 130, 1)",
                }}
              >
                Biaya Transaksi
              </p>
              <p
                className="text-left font-bold text-sm mac:text-base text-black1"
                style={{ lineHeight: screenWidth < 1024 ? "20px" : "24px" }}
              >
                Rp1.000
              </p>
            </div> */}
          </div>
        </div>
      )}
    </LayoutV2>
  );
}
