import axios from "axios";
import Cookies from "js-cookie";
import _get from "lodash/get";

export const notifError = (err, customMessage, customIcon) => {
  if (typeof err === "string") {
    window.Swal.fire({
      title: "Error!",
      text: customMessage ?? err,
      icon: customIcon ?? "error",
      confirmButtonText: "Tutup",
    });
    return;
  }
  const reasons = JSON.stringify(_get(err.response, "data.error.reasons", {}));
  const message = _get(
    err.response,
    "data.error.message",
    "Something went wrong!"
  );
  window.Swal.fire({
    title: "Error!",
    text: message,
    icon: "error",
    confirmButtonText: "Tutup",
  });
};

export const apiAnom = axios.create({
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION,
});

export async function getAnomToken() {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        "/request",
      {},
      {
        headers: {
          Authorization: "Bearer:rex_web:password",
        },
      }
    );
    Cookies.set("anom.token", res.data.data.request_token, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    return res.data.data.request_token;
  } catch (err) {
    return "";
  }
}

apiAnom.interceptors.request.use(
  function (config) {
    let newConfig = config;
    newConfig.headers["Authorization"] = "Bearer " + Cookies.get("anom.token");
    return newConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiAnom.interceptors.request.use(
  async function (request) {
    const originalRequest = request;
    if (!Cookies.get("anom.token") && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await getAnomToken();
      originalRequest.headers["Authorization"] = "Bearer " + access_token;
      return apiAnom(originalRequest);
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiAnom.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const access_token = await getAnomToken();
      originalRequest.headers["Authorization"] = "Bearer " + access_token;
      return apiAnom(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION,
});

api.interceptors.request.use(
  function (config) {
    let newConfig = config;
    newConfig.headers["Authorization"] = "Bearer " + Cookies.get("user.token");
    return newConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // TODO: do logout
      window.location.replace("/logout");
    }
    return Promise.reject(error);
  }
);

export const apiV2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION2,
});

apiV2.interceptors.request.use(
  function (config) {
    let newConfig = config;
    newConfig.headers["Authorization"] = "Bearer " + Cookies.get("user.token");
    return newConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiV2.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // TODO: do logout
      window.location.replace("/logout");
    }
    return Promise.reject(error);
  }
);

export const apiRM = axios.create({
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION,
});

apiRM.interceptors.request.use(
  function (config) {
    let newConfig = config;
    newConfig.headers["Authorization"] = "Bearer " + Cookies.get("rm.token");
    return newConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiRM.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // TODO: do logout
      window.location.replace("/logout?rmlogout=true");
    }
    return Promise.reject(error);
  }
);
