import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import FavoriteButton from "./FavoriteButton";
import { signInUpState } from "../atoms/signInUpState";
import { favMajorState } from "../atoms/favMajor";

export default function HomeCardMajor({
  id,
  majorData,
  noShadow,
  univMajorLabel,
  univMajorAccreditation,
  isFavChanged,
  popular,
  forUnivDetail,
  forHomeSection,
  forExploreMajor,
  width,
  height,
}) {
  const [onHover, setOnHover] = useState(false);
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [favorite, setFavorite] = useState(majorData.favorite);
  const setSign = useSetRecoilState(signInUpState);
  const setFavState = useSetRecoilState(favMajorState);
  const navigate = useNavigate();

  function getCareerName(career) {
    var careerName = "";
    if (typeof career === "string" || career instanceof String) {
      return career;
    } else {
      careerName = career.name;
    }
    return careerName;
  }

  const addMajorToFav = (fav, id) => {
    if (!auth.isAuth) {
      if (window.location.pathname === "/") {
        navigate({
          pathname: "/signin",
          search: `?redirect=/explore-kampus/detail/${id}`,
        });
      }
      setSign({ trySignUp: true, trySignIn: false });
      setFavState({ afterFavMajor: true, majorId: id });
    }

    if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    }

    const url = fav ? "/favorites/major/add" : "/favorites/major/delete";

    _api
      .post(url, {
        majors_of_universities_id: id,
      })
      .then(() => {
        setFavorite(fav);
        // isFavChanged();
      })
      .catch(() => {
        setFavorite(false);
      });
  };
  return (
    <Link
      to={`/explore-jurusan/${majorData.slug}`}
      className="relative flex flex-col items-stretch bg-white rounded-lg shadow-md hover:shadow-2xl"
      style={{
        minWidth: width ? width : "100%",
        maxWidth: width ? width : "100%",
        minHeight: height ? height : "100%",
        maxHeight: height ? height : "100%",
      }}
    >
      <div className={`relative w-full h-full`}>
        <img
          src={majorData.img_url}
          className="object-cover rounded-t-lg w-full"
          alt="major bg img"
          style={{ minHeight: "173px", maxHeight: "173px" }}
        />
        <div className="absolute bottom-4 left-8 flex flex-row gap-2">
          {majorData.tmb_result_matched && (
            <p
              className="px-3 py-1 rounded-lg text-center font-medium text-base text-white"
              style={{ backgroundColor: "#FE5244" }}
            >
              Kamu banget ✨
            </p>
          )}
          {univMajorAccreditation && (
            <p
              className="px-3 py-1 rounded-lg text-center font-medium text-base text-black1"
              style={{ backgroundColor: "#F9C60B" }}
            >
              {`Akreditasi ${univMajorAccreditation}`}
            </p>
          )}
        </div>
      </div>
      <FavoriteButton
        className="top-4 right-4"
        isFavorite={favorite}
        onChangeFavorite={() => {
          //callback fav, and id;
          addMajorToFav(!favorite, id);
        }}
      />
      <div className="w-full h-full flex flex-1 flex-col gap-6 px-4 pt-4 pb-6">
        <p
          className="text-left font-bold text-xl text-black1 line-clamp-1 overflow-ellipsis"
          style={{ lineHeight: "24px" }}
        >
          {!univMajorLabel ? majorData.name : univMajorLabel}
        </p>
        <p
          className="text-left font-normal text-sm mac:text-base text-black1 line-clamp-2 overflow-ellipsis"
          style={{ lineHeight: "21.7px" }}
          dangerouslySetInnerHTML={{
            __html: majorData.description.replace(/<[^>]+>/g, ""),
          }}
        >
          {/* {majorData.description} */}
        </p>
        {majorData.career_prospects !== null &&
          majorData.career_prospects?.length > 0 && (
            <div className="w-full h-full flex flex-1 flex-col gap-4 justify-start items-stretch">
              <p
                className="text-left font-semibold text-sm text-black188"
                style={{ lineHeight: "21.7px" }}
              >
                Prospek Karir
              </p>
              <div
                className="w-full flex justify-start items-center gap-2 text-center font-medium text-base text-black1"
                style={{ lineHeight: "24px" }}
              >
                <div className="flex items-center justify-center bg-backgroundGray4 rounded-lg py-1 px-3">
                  <p className="line-clamp-1 overflow-ellipsis">
                    {getCareerName(majorData.career_prospects[0])}
                  </p>
                </div>
                {majorData.career_prospects?.length > 1 && (
                  <div className="flex items-center justify-center bg-backgroundGray4 rounded-lg py-1 px-3">
                    <p className="line-clamp-1 overflow-ellipsis">
                      {`+ ${majorData.career_prospects?.length - 1}`}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    </Link>
  );
}
