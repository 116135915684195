import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/solid";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";

export default function RMSubNamaPeserta({ submit, back }) {
  const [namaPeserta, setNamaPeserta] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
    if (Cookies.get("namaPeserta")) {
      setNamaPeserta(Cookies.get("namaPeserta"));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submit(e.target[1].value);
  };

  const handleEnterKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (namaPeserta !== "") {
      submit(e.target.value);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-end w-full">
      <button onClick={back} className="w-full justify-start">
        <ArrowLeftIcon className="w-6 h-6 text-white font-semibold" />
      </button>
      <label
        className="w-full text-left font-bold text-base text-white mt-20 tablet:mt-50"
        htmlFor="nama_student"
      >
        Nama Lengkap Peserta
      </label>
      <input
        ref={inputRef}
        className="form-input flex w-full mt-3 rounded-md text-center font-normal text-xs text-black py-2"
        style={{ height: "30px" }}
        id="nama_student"
        type="nama_student"
        name="nama_student"
        value={namaPeserta}
        onChange={(e) => setNamaPeserta(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            // handleEnterKey(e);
          }
        }}
        placeholder="Input nama lengkap Peserta sesuai paspor"
        required
      />
      <div className="flex flex-row justify-between items-start w-full mt-2">
        <p className="text-left font-normal text-sm text-white italic leading-4">
          Contoh:
          <br /> Marcello Ryandra Takain
        </p>
        <button
          type="submit"
          className="w-max py-2 px-8 rounded-md text-center font-bold text-sm text-black1 mt-1"
          style={{ backgroundColor: "rgba(255, 202, 45, 1)" }}
        >
          Next
        </button>
      </div>
    </form>
  );
}
