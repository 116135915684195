import { useEffect, useState } from "react";
import countryJSON from "../../../components/jsons/Countries.json";
import indoProvJSON from "../../../components/jsons/IndonesiaProvinceWithCities.json";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { getCountryCallingCode } from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en";
import { mapHasKeyAndValueNotEmpty } from "../../../utils/utils";

export default function LaSalleAddress({
  draftData,
  callbackData,
  setCurrentForm,
  currentForm,
}) {
  const [selectedAddressCountry, setSelectedAddressCountry] =
    useState("Indonesia");
  const [countryList, setCountryList] = useState([]);
  const [provinceNCity, setProvinceNCity] = useState([]);
  const [selectedAddressProvince, setSelectedAddressProvince] =
    useState("DKI Jakarta");
  const [selectedAddressCity, setSelectedAddressCity] = useState("Pilih Kota");
  const [kodePos, setKodePos] = useState("");
  const [namaJalan, setNamaJalan] = useState("");
  const [nomorRumah, setNomorRumah] = useState("");
  const [isCodeDropdownHousePhone, setIsCodeDropdownHousePhone] =
    useState(false);
  const [countryCodeRumah, setCountryCodeRumah] = useState("62");
  const [teleponRumah, setTeleponRumah] = useState("");
  const [isCodeDropdownAlternatePhone, setIsCodeDropdownAlternatePhone] =
    useState(false);
  const [countryCodeAlternatif, setCountryCodeAlternatif] = useState("62");
  const [teleponAlternatif, setTeleponAlternatif] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCountryList(countryJSON);
    setProvinceNCity(indoProvJSON);
  }, []);

  useEffect(() => {
    if (draftData.domisili !== undefined) {
      setSelectedAddressCountry(draftData.domisili.negara);
      setSelectedAddressProvince(draftData.domisili.provinsi);
      setSelectedAddressCity(draftData.domisili.kota);
      setKodePos(draftData.domisili.kodePos);
      setNamaJalan(draftData.domisili.namaJalan);
      setNomorRumah(draftData.domisili.nomorRumah);
      setTeleponRumah(
        draftData.domisili.teleponRumah.includes("-")
          ? draftData.domisili.teleponRumah.split("-")[1]
          : draftData.domisili.teleponRumah
      );
      setTeleponAlternatif(
        draftData.domisili.teleponAlternatif.includes("-")
          ? draftData.domisili.teleponAlternatif.split("-")[1]
          : draftData.domisili.teleponAlternatif
      );
    }
  }, [draftData]);

  function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    callbackData({
      negara: elements[0].value,
      provinsi:
        elements[0].value === "Indonesia" ? elements[1].value : "DKI Jakarta",
      kota:
        elements[0].value === "Indonesia" ? elements[2].value : "Pilih Kota",
      kodePos: elements[elements[0].value === "Indonesia" ? 3 : 1].value,
      namaJalan: elements[elements[0].value === "Indonesia" ? 4 : 2].value,
      nomorRumah: elements[elements[0].value === "Indonesia" ? 5 : 3].value,
      teleponRumah: `${countryCodeRumah}-${
        elements[elements[0].value === "Indonesia" ? 6 : 4].value
      }`,
      teleponAlternatif: `${countryCodeAlternatif}-${
        elements[elements[0].value === "Indonesia" ? 7 : 5].value
      }`,
    });
    setCurrentForm(currentForm + 1);
  }

  function toPrevStep() {
    callbackData({
      negara: selectedAddressCountry,
      provinsi: selectedAddressProvince,
      kota: selectedAddressCity,
      kodePos: kodePos,
      namaJalan: namaJalan,
      nomorRumah: nomorRumah,
      teleponRumah: teleponRumah,
      teleponAlternatif: teleponAlternatif,
    });
    setCurrentForm(currentForm - 1);
  }

  return (
    <form
      onSubmit={submitForm}
      className="flex flex-col w-full justify-start items-start pb-10"
    >
      <div className="flex flex-col w-full justify-start">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-90">
              Negara
            </label>
            <select
              placeholder={"Pilih Negara"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedAddressCountry}
              onChange={(e) => {
                const country = e.target.value;
                setSelectedAddressCountry(country);
              }}
              required
            >
              {countryList.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {selectedAddressCountry === "Indonesia" && (
            <div className="mt-2">
              <label className="block text-base font-bold leading-6 text-gray-90">
                Provinsi
              </label>
              <select
                placeholder={"Pilih Provinsi"}
                className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                value={selectedAddressProvince}
                onChange={(e) => {
                  const province = e.target.value;
                  setSelectedAddressProvince(province);
                }}
                required
              >
                {provinceNCity.map((item, index) => (
                  <option key={index} value={item.provinsi}>
                    {item.provinsi}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedAddressCountry === "Indonesia" && (
            <div className="mt-2">
              <label className="block text-base font-bold leading-6 text-gray-90">
                Kota
              </label>
              <select
                placeholder={"Pilih Kota"}
                className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                value={selectedAddressCity}
                onChange={(e) => {
                  const city = e.target.value;
                  setSelectedAddressCity(city);
                }}
                required
              >
                {provinceNCity.length > 0 &&
                  provinceNCity
                    .filter(
                      (element) => element.provinsi === selectedAddressProvince
                    )[0]
                    .kota.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
              </select>
            </div>
          )}

          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-90">
              Kode Pos
            </label>
            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan kode pos"
              defaultValue={kodePos}
              onChange={(e) => {
                setKodePos(e.target.value);
              }}
              disabled={false}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("Kode pos harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>

          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-90">
              Nama Jalan
            </label>
            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan nama jalan"
              defaultValue={namaJalan}
              onChange={(e) => {
                setNamaJalan(e.target.value);
              }}
              disabled={false}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("Nama jalan harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>

          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-90">
              Nomor Rumah
            </label>
            <input
              type="text"
              className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
              placeholder="Masukkan nomor rumah"
              defaultValue={nomorRumah}
              onChange={(e) => {
                setNomorRumah(e.target.value);
              }}
              disabled={false}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("Nomor rumah harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>

          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-90">
              Telepon Rumah
            </label>
            <div className="flex flex-row w-full justify-start items-center mt-3">
              <div
                className="flex gap-2 items-center rounded-l-40px bg-white px-3 relative"
                style={{
                  height: "50px",
                  border: "1px solid #E0E0E1",
                }}
                onClick={() => {
                  setIsCodeDropdownHousePhone(!isCodeDropdownHousePhone);
                }}
              >
                <div className="border-0 w-12 h:w-18 pl-1">
                  {`+${countryCodeRumah}`}
                </div>
                <ChevronDownIcon
                  className="h-5 w-auto p-0 m-0"
                  style={{ paddingTop: "2px" }}
                />
                {isCodeDropdownHousePhone && (
                  <div className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10">
                    {getCountries().map((country) => (
                      <button
                        key={country}
                        value={country}
                        className="text-left px-4 pt-2 w-max"
                        onClick={() => {
                          setIsCodeDropdownHousePhone(false);
                          setCountryCodeRumah(getCountryCallingCode(country));
                        }}
                      >
                        {en[country]}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <input
                id="addressHomePhone"
                type="number"
                name="addressHomePhone"
                accept="addressHomePhone"
                className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                  mapHasKeyAndValueNotEmpty(errors, "addressHomePhone")
                    ? "border-red-400"
                    : ""
                }`}
                placeholder="Masukkan nomor telepon rumah"
                defaultValue={teleponRumah}
                onChange={(e) => {
                  setTeleponRumah(e.target.value);
                }}
                required
                onInvalid={(F) =>
                  F.target.setCustomValidity("No. telepon rumah harus diisi")
                }
                onInput={(F) => F.target.setCustomValidity("")}
              />
            </div>
          </div>

          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-90">
              Telepon Alternatif
            </label>

            <div className="flex flex-row w-full justify-start items-center mt-3">
              <div
                className="flex gap-2 items-center rounded-l-40px bg-white px-3 relative"
                style={{
                  height: "50px",
                  border: "1px solid #E0E0E1",
                }}
                onClick={() => {
                  setIsCodeDropdownAlternatePhone(
                    !isCodeDropdownAlternatePhone
                  );
                }}
              >
                <div className="border-0 w-12 h:w-18 pl-1">
                  {`+${countryCodeAlternatif}`}
                </div>
                <ChevronDownIcon
                  className="h-5 w-auto p-0 m-0"
                  style={{ paddingTop: "2px" }}
                />
                {isCodeDropdownAlternatePhone && (
                  <div className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10">
                    {getCountries().map((country) => (
                      <button
                        key={country}
                        value={country}
                        className="text-left px-4 pt-2 w-max"
                        onClick={() => {
                          setIsCodeDropdownAlternatePhone(false);
                          setCountryCodeAlternatif(
                            getCountryCallingCode(country)
                          );
                        }}
                      >
                        {en[country]}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <input
                id="addressAlternatePhone"
                type="number"
                name="addressAlternatePhone"
                accept="addressAlternatePhone"
                className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                  mapHasKeyAndValueNotEmpty(errors, "addressAlternatePhone")
                    ? "border-red-400"
                    : ""
                }`}
                placeholder="Masukkan nomor telepon rumah"
                defaultValue={teleponAlternatif}
                onChange={(e) => {
                  setTeleponAlternatif(e.target.value);
                }}
                required
                onInvalid={(F) =>
                  F.target.setCustomValidity(
                    "No. telepon alternatif harus diisi"
                  )
                }
                onInput={(F) => F.target.setCustomValidity("")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
        <button
          onClick={() => {
            toPrevStep();
          }}
          className="py-3 bg-white text-center font-semibold text-base text-primaryColor rounded-lg mt-16 border-2 border-primaryColor"
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Sebelumnya
        </button>

        <button
          type="submit"
          disabled={
            kodePos === "" ||
            namaJalan === "" ||
            nomorRumah === "" ||
            teleponRumah === "" ||
            teleponAlternatif === ""
          }
          className={`py-3 ${
            kodePos === "" ||
            namaJalan === "" ||
            nomorRumah === "" ||
            teleponRumah === "" ||
            teleponAlternatif === ""
              ? "bg-gray-400"
              : "bg-primaryColor"
          } text-center font-semibold text-base text-white rounded-lg mt-16`}
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Selanjutnya
        </button>
      </div>
    </form>
  );
}
