import { useTranslation } from "react-i18next";
import React from "react";
import { TabList, TabPanel, Tabs, Tab } from "react-tabs";
import TestimonyCard from "./TestimonyCard";

export default function TestimoniesBlocks({ screenWidth }) {
  return (
    <div className="w-full flex flex-col justify-end items-stretch">
      {screenWidth >= 1024 ? (
        <div className="relative w-full flex flex-col justify-end items-center">
          <p
            className="text-center font-bold text-tosca2"
            style={{
              fontSize: "14.22px",
              lineHeight: "21.33px",
              letterSpacing: "1.78px",
            }}
          >
            TESTIMONIAL
          </p>
          <p
            className="text-center font-bold text-36px text-white"
            style={{
              lineHeight: "43.2px",
              marginTop: "14.22px",
              maxWidth: "551.11px",
            }}
          >
            Apa kata mereka tentang Exploration
          </p>
          <Tabs>
            <TabList
              className="w-full flex flex-row justify-center mt-13 text-center font-bold"
              style={{
                fontSize: "14.22px",
                lineHeight: "21.33px",
                gap: "14.22px",
              }}
            >
              <Tab>Siswa</Tab>
              <Tab>Kampus</Tab>
              <Tab>Sekolah Menengah Atas</Tab>
            </TabList>
            <div className="tablet:mt-10 mac:mt-20">
              <TabPanel>
                <div className="flex flex-row gap-6">
                  <TestimonyCard
                    testimony="“Bagaimana cara pengajar menyampaikan materi sangat interaktif, kreatif, dan bersemangat. Materinya pun sangat bermanfaat.”"
                    source="individual"
                    photo={"/avatar_default.svg"}
                    name="Siswa Kelas 12"
                    degree="SMA Regina Pacis Bogor"
                  />
                  <TestimonyCard
                    testimony="“Semua berjalan dengan baik. Aku dapat mengetahui informasi-informasi mengenai jurusan dan universitas yang selama ini tidak dijelaskan di sekolah.”"
                    source="individual"
                    photo={"/avatar_default.svg"}
                    name="Siswa Kelas 11"
                    degree="SMA Xaverius 1 Palembang"
                  />
                  <TestimonyCard
                    testimony="“Karena exploration, aku jadi bisa tau rasanya menjadi mahasiswa di universitas dan jurusan yang aku mau. Terima kasih exploration!”"
                    source="individual"
                    photo={"/avatar_default.svg"}
                    name="Siswa Kelas 12"
                    degree="SMA Budi Mulia Bogor"
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="flex flex-row gap-6">
                  <TestimonyCard
                    testimony="“Kami merasa terbantu dengan adanya exploration, karena kami dapat mengenal dan berinteraksi langsung dengan siswa-siswi dari sekolah yang beragam.”"
                    source="univ"
                    photo={
                      "https://strgonelabsprod.blob.core.windows.net/explorationid/logo/Kalbis%20Institute%20-%20UKA1053.png"
                    }
                    name="Kalbis Institute"
                  />
                  <TestimonyCard
                    testimony="“Melalui exploration kampus kami bisa menyajikan sneak peak rasanya menjadi mahasiswa UPH langsung ke siswa siswi yang berasal dari beragam SMA.”"
                    source="univ"
                    photo={
                      "https://strgonelabsprod.blob.core.windows.net/explorationid/logo/Universitas%20Pelita%20Harapan%20Surabaya%20-%20UPE1195.png"
                    }
                    name="Universitas Pelita Harapan"
                  />
                  <TestimonyCard
                    testimony="“Exploration selain membantu siswa dan guru, kami yakini juga dapat membantu orang tua siswa dalam memilih jurusan dan universitas untuk anaknya.”"
                    source="univ"
                    photo={
                      "https://strgonelabsprod.blob.core.windows.net/explorationid/logo/President%20University%20-%20UPR1079.png"
                    }
                    name="President University"
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="flex flex-row gap-6">
                  <TestimonyCard
                    testimony="“Program yang exploration adakan sangat membantu siswa-siswi kami di sekolah untuk bisa mengeksplor berbagai jurusan dan perguruan tinggi yang ada.”"
                    source="school"
                    photo={"/logo_rpb.png"}
                    name="SMA Regina Pacis Bogor"
                  />
                  <TestimonyCard
                    testimony="“Exploration selain membantu siswa dan guru, kami yakini juga dapat membantu orang tua siswa dalam memilih jurusan dan universitas untuk anaknya.”"
                    source="school"
                    photo={"/logo_bmb.png"}
                    name="SMA Budi Mulia Bogor"
                  />
                  <TestimonyCard
                    testimony="“Siswa siswi sekolah kami terbantu dengan adanya gambaran mengenai berbagai universitas dan program studi yang ada di Exploration.”"
                    source="school"
                    photo={"/logo_xaver.png"}
                    name="SMA Xaverius 1 Palembang"
                  />
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      ) : (
        <div className="flex flex-col justify-end items-stretch">
          <p
            className="text-left font-bold text-base text-tosca2"
            style={{ lineHeight: "24px", letterSpacing: "2px" }}
          >
            TESTIMONIAL
          </p>
          <p
            className="text-left font-bold text-2xl text-white mt-4"
            style={{ lineHeight: "28.8px", letterSpacing: "0.8px" }}
          >
            Apa kata mereka tentang exploration?
          </p>
          <div className="flex flex-col gap-10 mt-11">
            <TestimonyCard
              testimony="“Bagaimana cara pengajar menyampaikan materi sangat interaktif, kreatif, dan bersemangat. Materinya pun sangat bermanfaat.”"
              source="individual"
              photo={"/avatar_default.svg"}
              name="Siswa Kelas 12"
              degree="SMA Regina Pacis Bogor"
            />
            <TestimonyCard
              testimony="“Kami merasa terbantu dengan adanya exploration, karena kami dapat mengenal dan berinteraksi langsung dengan siswa-siswi dari sekolah yang beragam.”"
              source="univ"
              photo={
                "https://strgonelabsprod.blob.core.windows.net/explorationid/logo/Kalbis%20Institute%20-%20UKA1053.png"
              }
              name="Kalbis Institute"
            />
            <TestimonyCard
              testimony="“Exploration selain membantu siswa dan guru, kami yakini juga dapat membantu orang tua siswa dalam memilih jurusan dan universitas untuk anaknya.”"
              source="school"
              photo={"/logo_bmb.png"}
              name="SMA Budi Mulia Bogor"
            />
          </div>
        </div>
      )}
    </div>
  );
}
