import useSwrCache from "../pages/useSwrCache";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slickCarouselSetting = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  speed: 2000,
  // autoplaySpeed: 1000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 2000,
        // autoplaySpeed: 1000,
        cssEase: "linear",
      },
    },
    {
      breakpoint: 1921,
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 2000,
        // autoplaySpeed: 1000,
        cssEase: "linear",
      },
    },
  ],
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src="/carousel_left_arrow.svg"
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
      alt="carousel left arrow"
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src="/carousel_right_arrow.svg"
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
      alt="carousel right arrow"
    />
  );
}

const PartnerUniversitiesSection = () => {
  const { data: universities } = useSwrCache("/universities", {
    key: "listUniversities",
    initialValue: [],
  });

  if (!universities) {
    return <div className="w-full text-center">Loading...</div>;
  }

  return (
    <div className="font-plusJakartaSans w-full px-4 tablet:px-18 tablet:py-5">
      <div className="text-left text-xl text-black1 font-bold">
        Partner Sekolah Exploration
      </div>
      <div className="h-16 w-full mt-10">
        <Slider {...slickCarouselSetting}>
          {universities.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-center h-16 w-16"
            >
              <img
                src={item.logo_url}
                alt="logo university"
                className="h-full w-full object-contain"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PartnerUniversitiesSection;
