import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { authState } from "../../atoms/authState";
import { api, apiAnom, apiV2, notifError } from "../../utils/api";
import PageLoading from "../PageLoading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../../utils/utils";
import Button from "../../components/base/Button";
import { signInUpState } from "../../atoms/signInUpState";
import { postCheckModalState } from "../../atoms/postCheckModalState";
import PostCheckModal from "../../components/PostCheckModal";
import LayoutV2 from "../../components/Layout_V2";

export default function AdmissionForm() {
  const params = new URLSearchParams(window.location.search);
  const admissionID = params.get("admissionID");
  let { slug } = useParams();
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const [univData, setUnivData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCodeDropdown, setIsCodeDropdown] = useState("");
  const [errors, setErrors] = useState({});
  const [disableChangePhone, setDisableChangePhone] = useState(false);
  const [countryCode, setCountryCode] = useState(getCountryCallingCode("ID"));
  const [isSubmitProcess, setIsSubmitProcess] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [postCheckData, setPostcheckData] = useState();
  const [formFinalData, setFormFinalData] = useState();
  const setPostCheckModalState = useSetRecoilState(postCheckModalState);
  const [uniID, setUniID] = useState();
  const [showPostCheckModal, setShowPostCheckModal] = useState(false);

  function closeModal() {
    setShowPostCheckModal(false);
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    setShowPostCheckModal(false);
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  useEffect(() => {
    if (!auth.isAuth) {
      setIsLoading(false);
      setTimeout(() => {
        setSign({ trySignIn: true, trySignUp: false });
      }, 100);
    } else if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    } else {
      setFullName(
        auth.fname && auth.lname ? auth.fname + " " + auth.lname : ""
      );
      setEmail(auth.email);
      let newCountryCode;
      let newPhone;
      if (auth.phone.includes("-")) {
        newCountryCode = auth.phone.split("-")[0];
        newPhone = auth.phone.split("-")[1];
      } else {
        newCountryCode = getCountryCallingCode("ID");
        newPhone = auth.phone.slice(auth.phone.indexOf("8"));
      }
      setPhone(newPhone);
      setCountryCode(newCountryCode);

      _api
        .get(`/explore-kampus-v2/${slug}`)
        .then((res) => {
          setIsLoading(false);
          setUniID(res.data.data.id);
          setUnivData(res.data.data);
          document.title = `Admission - ${res.data.data.name.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ""
          )}`;
        })
        .catch((_) => {
          setUnivData({});
        });
    }
  }, [_api, auth.isAuth, slug]);

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          case "countryCode":
            isErr = !/()\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitProcess(true);
    const { elements } = e.target;
    if (!validate(elements)) return;
    // building payload
    const newPhone = countryCode + "-" + phone;
    {
      /* RESERVED FOR LATER USE */
    }
    // const payload = {
    //   name: fullName,
    //   email: email,
    //   phone: newPhone,
    //   uni_id: univData.id,
    //   univ_form_data: JSON.stringify({}),
    // };

    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("name", fullName);
    formData.append("email", email);
    formData.append("phone", newPhone);
    formData.append("uni_id", univData.id);
    formData.append("univ_form_data", JSON.stringify({}));
    formData.append("paid_status", "SUBMITTED"); // DRAFT or SUBMITTED

    apiV2
      .get(`/invoices/postcheck/${uniID}`)
      .then((res) => {
        setFormFinalData(formData);
        setPostcheckData(res.data.data);
        setShowPostCheckModal(true);
        // setPostCheckModalState({ isOpen: true });
      })
      .catch((err) => {
        setFormFinalData(formData);
        setPostcheckData(false);
        if (err.response.status === 404) {
          _api
            .post(`/students/univ-admission/${admissionID}`, formData, config)
            .then((res) => {
              setIsSubmitProcess(false);

              window.Swal.fire({
                title: "Success!",
                text: `Kamu telah mendaftar di ${univData.name}`,
                icon: "success",
                confirmButtonText: "Tutup",
                allowOutsideClick: false,
              }).then(() => {
                navigate(
                  `/invoice?invoice_number=${res.data.data.invoice_number}`
                );
              });
            })
            .catch((_) => {
              setIsSubmitProcess(false);

              window.Swal.fire({
                title: "Gagal",
                text: `Pendaftaran kampus gagal`,
                icon: "error",
                confirmButtonText: "Tutup",
                allowOutsideClick: false,
              }).then(() => {
                navigate(`/profile`);
              });
            });
        } else {
          window.Swal.fire({
            title: "Gagal",
            text: err.response.data.error.message,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/profile`);
          });
        }
      });
  };

  function submitForm() {
    setShowPostCheckModal(false);
    // setPostCheckModalState({ isOpen: false });
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    apiV2
      .post(
        `/invoices/recreate-invoice/${univData.id}/${admissionID}`,
        formFinalData,
        config
      )
      .then((res) => {
        setTimeout(() => {
          setIsSubmitProcess(false);

          window.Swal.fire({
            title: "Success!",
            text: `Kamu telah mendaftar di ${univData.name}`,
            icon: "success",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            // navigate(`/explore-kampus/${univData.slug}`);
            navigate(`/invoice?invoice_number=${res.data.data.invoice_number}`);
          });
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          window.Swal.fire({
            title: "Gagal",
            text: err
              ? err.response.data.error.message
              : `Pendaftaran kampus gagal`,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/profile`);
          });
        }, 1000);
      });
  }

  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <LayoutV2>
      <p className="text-center font-bold text-3xl hd:text-48px text-black1 mt-4 hd:mt-20">
        Isi Formulir Pendaftaran
      </p>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-8 px-4 hd:px-20 mt-12 pb-20 overflow-auto"
      >
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Nama Lengkap <span className={"text-red-500"}>*</span>
          </label>
          <input
            id="fullName"
            type="text"
            name="fullName"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan nama lengkap"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama Lengkap harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Email <span className={"text-red-500"}>*</span>
          </label>
          <input
            id="email"
            type="text"
            name="email"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) => F.target.setCustomValidity("Email harus diisi")}
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="phone"
          >
            No. HP Siswa <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-row w-full justify-start items-center mt-4">
            <div
              className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
              style={{
                height: "50px",
                border: "1px solid #E0E0E1",
              }}
              onClick={() => {
                setIsCodeDropdown(!isCodeDropdown);
              }}
            >
              <div className="border-0 w-12 h:w-18 pl-1">
                {`+${countryCode}`}
              </div>
              <ChevronDownIcon
                className="h-5 w-auto p-0 m-0"
                style={{ paddingTop: "2px" }}
              />
              {isCodeDropdown && (
                <div
                  className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10"
                  //   style={{ maxHeight: "344px" }}
                >
                  {/* <option value="">{en["ZZ"]}</option> */}
                  {getCountries().map((country) => (
                    <button
                      key={country}
                      value={country}
                      className="text-left px-4 pt-2 w-max"
                      onClick={() => {
                        setIsCodeDropdown(false);
                        setCountryCode(getCountryCallingCode(country));
                      }}
                    >
                      {en[country]}
                      {/* {labels[country]} +{getCountryCallingCode(country)} */}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              id="phone"
              type="number"
              name="phone"
              accept="phone"
              className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                mapHasKeyAndValueNotEmpty(errors, "phone")
                  ? "border-red-400"
                  : ""
              }`}
              placeholder="cth. 8XXXXXXXXXXXX"
              value={phone}
              onChange={(e) => {
                if (phone[0] === "0") {
                  setPhone(e.target.value.slice(1));
                } else {
                  setPhone(e.target.value);
                }
              }}
              onBlur={() => {
                if (phone[0] === "0") {
                  setPhone(phone.slice(1));
                }
              }}
              disabled={disableChangePhone}
              required
              onInvalid={(F) =>
                F.target.setCustomValidity("No. HP harus diisi")
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </div>
        </div>
        <div className="flex flex-wrap px-3 -mx-3 mt-10 w-full justify-center">
          <Button
            disabled={isSubmitProcess}
            type="submit"
            className="w-max rounded-lg"
          >
            {isSubmitProcess ? "Submitting..." : "Simpan"}
          </Button>
        </div>
      </form>

      {/* post check modal */}

      <PostCheckModal
        isModalShow={showPostCheckModal}
        screenWidth={screenWidth}
        postCheckData={postCheckData}
        submitForm={submitForm}
        closeModal={closeModal}
      />
    </LayoutV2>
  );
}
