import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom, notifError } from "../utils/api";

export default function SchoolPartnerSection() {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [schools, setSchools] = useState();

  useEffect(() => {
    _api
      .get("/schools/logo/home/list")
      .then((res) => {
        setSchools(res.data.data);
      })
      .catch(notifError);
  }, []);

  const slickCarouselSetting = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
          speed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 1921,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 6,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
          speed: 2000,
          cssEase: "linear",
        },
      },
    ],
  };

  return (
    <div className="font-plusJakartaSans w-full px-4 tablet:px-18 tablet:py-5">
      <div className="text-left text-xl text-black1 font-bold">
        Partner Sekolah Exploration
      </div>
      <div className="h-16 w-full mt-10">
        <Slider {...slickCarouselSetting}>
          {schools?.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-center h-16 w-16"
            >
              <img
                src={item.image_url}
                alt="logo_sekolah"
                className="h-full w-full object-contain"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
