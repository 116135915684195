import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";

export default function MiniQuizResultCard({ screenWidth, data }) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );

  return (
    <div
      className="relative rounded-xl"
      style={{
        minWidth: "363px",
        minHeight: "250px",
        boxShadow: "4px 4px 40px 0px rgba(64, 69, 79, 0.16)",
      }}
    >
      <div
        className="h-full bg-white absolute top-0 left-0 z-10 rounded-xl flex flex-col justify-start items-start"
        style={{
          width: "220px",
          paddingTop: "28.72px",
          paddingLeft: "28.72px",
          paddingRight: "9.05px",
        }}
      >
        <p
          className="text-left font-normal"
          style={{
            fontSize: "12.56px",
            lineHeight: "19.48px",
            color: "rgba(131, 130, 132, 1)",
          }}
        >
          {moment(data.created_at).format("DD MMMM YYYY | HH:mm")}
        </p>
        <p
          className="text-left font-semibold text-black188"
          style={{
            fontSize: "14.36px",
            lineHeight: "22.26px",
            marginTop: "21.54px",
          }}
        >
          Mini Quiz
        </p>
        <div
          className="flex flex-col justify-start items-start"
          style={{ gap: "7.18px", marginTop: "14.36px" }}
        >
          <p
            className="text-left font-medium text-black"
            style={{
              paddingTop: "3.36px",
              paddingBottom: "3.36px",
              paddingLeft: "10.77px",
              paddingRight: "10.77px",
              backgroundColor: "rgba(241, 243, 246, 1)",
              borderRadius: "7.18px",
              fontSize: "14.36px",
              lineHeight: "21.54px",
            }}
          >
            {data.miniquiz_result}
          </p>
        </div>
      </div>
        <img
          src="/profile_miniquiz.png"
          className="h-full bg-blue-300 absolute top-0 right-0 rounded-xl"
          style={{ width: "220px" }}
        />
    </div>
  );
}
