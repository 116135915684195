import { useRecoilValue } from "recoil";
import { useState, useEffect, Fragment, useRef } from "react";

import { authState } from "../../atoms/authState";
import { apiAnom, api } from "../../utils/api";
import ReactSelect from "react-select";
import * as _ from "lodash";
import PageLoading from "../PageLoading";
import Layout from "../../components/Layout";
import Button from "../../components/base/Button";
import Pagination from "../../components/pagination/Pagination";
import DropdownItem from "../../components/DropdownItem";
import CardUniversity from "../../components/CardUniversity";
import { useNavigate } from "react-router-dom";
import HomeCardUniv from "../../components/HomeCardUniv";
import { MdClose } from "react-icons/md";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import Slider from "react-slick";
import Headerv2 from "../../components/Header_v2";
import "../../slick-carousel.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import HomeCardUnivV3 from "../../components/HomeCardUnivV3";
import LayoutV2 from "../../components/Layout_V2";
import Footer from "../../components/Footer";

let pageSize = 16;

export default function EksplorasiKampusV3() {
  const sliderRef = useRef();
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [currentPage, setCurrentPage] = useState(1);

  const [filterProvince, setFilterProvince] = useState(new Set());
  const [filterAcreditation, setFilterAcreditation] = useState(new Set());
  const [filterMajor, setFilterMajor] = useState(new Set());
  const [filterType, setFilterType] = useState(new Set());

  const [mobileFilterProvince, setMobileFilterProvince] = useState([]);
  const [listData, setListData] = useState();
  const [topUnivs, setTopUnivs] = useState();
  const [univNames, setUnivNames] = useState();
  const [univSuggestion, setUnivSuggestion] = useState(false);
  const [keyword, setKeyword] = useState();
  const [listProvince, setListProvince] = useState([]);
  const [favList, setFavList] = useState([]);
  const [majorsList, setMajorsList] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [currentSliderPage, setCurrentSliderPage] = useState(1);
  const [favUniTotalSlides, setFavUniTotalSlides] = useState();
  const [favUniCurrentSlideIndex, setFavUniCurrentSlideIndex] = useState(1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  isMenuOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  useEffect(() => {
    document.title = "Explore Kampus";
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  const favUnivSetting = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
  };

  const universityType = [
    {
      value: "N",
      label: "Negeri",
    },
    {
      value: "S",
      label: "Swasta",
    },
    {
      value: "L",
      label: "Luar Negeri",
    },
  ];

  const univAccreditation = [
    {
      value: "A",
      label: "A",
    },
    {
      value: "B",
      label: "B",
    },
    {
      value: "C",
      label: "C",
    },
    {
      value: "U",
      label: "Unggul",
    },
    {
      value: "BS",
      label: "Baik Sekali",
    },
    {
      value: "O",
      label: "Lainnya",
    },
  ];

  useEffect(() => {
    const getListProvinces = () => {
      _api
        .get("/provinces")
        .then((res) => {
          setListProvince(res.data.data);
        })
        .catch((_) => {
          setListProvince([]);
        });
    };
    getListProvinces();
  }, [_api, auth.isAuth]);

  useEffect(() => {
    const getMajorsList = () => {
      _api
        .get("/major/list/all")
        .then((res) => {
          setMajorsList(res.data.data);
        })
        .catch((_) => {
          setMajorsList([]);
        });
    };
    getMajorsList();
  }, [_api, auth.isAuth]);

  useEffect(() => {
    const getUniveristy = () => {
      _api
        .post("/university/search", {
          name: keyword,
          type: _.map(Array.from(filterType), "value"),
          province_ids: _.map(Array.from(filterProvince), "id"),
          accreditation: _.map(Array.from(filterAcreditation), "value"),
          major_ids: _.map(Array.from(filterMajor), "id"),
          page: currentPage,
          limit: pageSize,
          sort_direction: "DESC",
        })
        .then((res) => {
          setListData(res.data.data);
          setUnivNames(res.data.data.universities.map((item) => item.name));
          setFavList(res.data.data.favorites);
        })
        .catch((_) => {
          setListData([]);
        });
    };
    getUniveristy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.isAuth,
    currentPage,
    filterType,
    filterAcreditation,
    filterProvince,
    filterMajor,
    keyword,
  ]);

  useEffect(() => {
    const getTop5Univeristy = () => {
      _api
        .get("/university/list/top-15-most-fav-by-students")
        .then((res) => {
          setTopUnivs(res.data.data.universities);
          setFavUniTotalSlides(
            Math.ceil(res.data.data.universities.length / 3)
          );
        })
        .catch((_) => {
          setTopUnivs([]);
        });
    };
    getTop5Univeristy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!listData) return <PageLoading />;

  const styles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
      textAlign: "left",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
      textAlign: "left",
    }),
    input: (provided, state) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: "1 !important",
      },
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
      fontWeight: "400",
      fontSize: "14px",
    }),
  };

  const onDeleteType = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    const univType = new Set(filterType);
    univType.delete(type);
    setFilterType(univType);
    // setFilterType(filterType.filter((item) => item.value !== type["value"]));
    setCurrentPage(1);
  };

  const onDeleteAcreditation = (e, accreditation) => {
    e.preventDefault();
    e.stopPropagation();
    const univAccre = new Set(filterAcreditation);
    univAccre.delete(accreditation);
    setFilterAcreditation(univAccre);
    // setFilterAcreditation(
    //   filterAcreditation.filter((item) => item.value !== accreditation["value"])
    // );
    setCurrentPage(1);
  };

  const onDeleteMajor = (e, major) => {
    e.preventDefault();
    e.stopPropagation();
    const univMajor = new Set(filterMajor);
    univMajor.delete(major);
    setFilterMajor(univMajor);
    // setFilterMajor(filterMajor.filter((item) => item.id !== major.id));
    setCurrentPage(1);
  };

  const onDeleteProvince = (e, prov) => {
    e.preventDefault();
    e.stopPropagation();
    const univProvince = new Set(filterProvince);
    univProvince.delete(prov);
    setFilterProvince(univProvince);
    // setFilterProvince(filterProvince.filter((item) => item.id !== prov["id"]));
    setCurrentPage(1);
  };

  const onSearchByBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _api
      .post("/university/search", {
        name: keyword,
        type: _.map(filterType, "value"),
        province_ids: _.map(filterProvince, "id"),
        accreditation: _.map(filterAcreditation, "value"),
        page: 1,
        limit: pageSize,
        sort_direction: "DESC",
      })
      .then((res) => {
        setListData(res.data.data);
        setCurrentPage(1);
      })
      .catch((_) => {
        setListData([]);
      });
  };

  const navigateToCompare = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/bandingkan-kampus");
  };

  function handleFilterLokasiKampusMobile(e, item) {
    const checked = e.currentTarget.checked;
    const univProvince = new Set(filterProvince);
    if (checked) {
      univProvince.add(item);
    } else {
      univProvince.delete(item);
    }
    setFilterProvince(univProvince);
    setCurrentPage(1);
  }

  function handleFilterAkreditasiMobile(e, item) {
    const checked = e.currentTarget.checked;
    const univAccre = new Set(filterAcreditation);
    if (checked) {
      univAccre.add(item);
    } else {
      univAccre.delete(item);
    }
    setFilterAcreditation(univAccre);
    setCurrentPage(1);
  }

  function handleFilterJurusanMobile(e, item) {
    const checked = e.currentTarget.checked;
    const univMajor = new Set(filterMajor);
    if (checked) {
      univMajor.add(item);
    } else {
      univMajor.delete(item);
    }
    setFilterMajor(univMajor);
    setCurrentPage(1);
  }

  function handleFilterUnivTypeMobile(e, item) {
    const checked = e.currentTarget.checked;
    const univType = new Set(filterType);
    if (checked) {
      univType.add(item);
    } else {
      univType.delete(item);
    }
    setFilterType(univType);
    setCurrentPage(1);
  }

  return (
    <div className="w-full flex flex-col justify-start items-stretch overflow-x-hidden">
      {screenWidth < 1024 ? (
        <LayoutV2
          withoutBody={true}
          withoutFooter={true}
          noHeight={true}
          showMenu={(e) => {
            setIsMenuOpen(!e);
          }}
        />
      ) : (
        <Headerv2 screenWidth={screenWidth} />
      )}
      <div className="flex flex-row w-full gap-6 py-3 px-6 tablet:px-16 mac:px-36 fhd:px-110 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg sticky top-0 z-30">
        <form className="relative flex flex-row remove-input-txt-border w-full tablet:gap-5">
          <label className="flex flex-row items-center w-1/3">
            <div className="pr-2">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.1115 0C4.80323 0 0.5 4.20819 0.5 9.39926C0.5 14.5903 4.80323 18.7985 10.1115 18.7985C12.3819 18.7985 14.4684 18.0287 16.113 16.7415L19.2371 19.7886L19.3202 19.8586C19.6102 20.0685 20.0214 20.0446 20.2839 19.7873C20.5726 19.5043 20.572 19.0459 20.2825 18.7636L17.1952 15.7523C18.7649 14.0794 19.7231 11.8487 19.7231 9.39926C19.7231 4.20819 15.4198 0 10.1115 0ZM10.1115 1.44774C14.6022 1.44774 18.2426 5.00776 18.2426 9.39926C18.2426 13.7908 14.6022 17.3508 10.1115 17.3508C5.62086 17.3508 1.98044 13.7908 1.98044 9.39926C1.98044 5.00776 5.62086 1.44774 10.1115 1.44774Z"
                  fill="#171717"
                />
              </svg>
            </div>
            <input
              type="text"
              className="flex-1 sm:flex-2 form-input border-none pb-4 tablet:rounded-none"
              style={{
                maxHeight: "38px",
                borderBottom: screenWidth < 1024 && "1px solid grey",
                borderRight: screenWidth >= 1024 && "1px solid grey",
              }}
              placeholder="Cari nama kampus"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setUnivSuggestion(true);
              }}
            />
          </label>
          {univSuggestion && keyword.length > 0 && (
            <div className="absolute z-10 top-10 left-0 flex flex-col items-start w-full overflow-auto shadow-lg px-0 tablet:px-8 bg-white">
              {univNames.map((item, index) => (
                <Button
                  size="small"
                  variant="link"
                  key={`univ ${index}`}
                  className="font-normal text-sm text-black1 text-left w-full py-3 justify-start"
                  onClick={() => {
                    setUnivSuggestion(false);
                    setKeyword(item);
                  }}
                >
                  {item}
                </Button>
              ))}
            </div>
          )}
          {screenWidth >= 1024 && (
            <div
              className="flex flex-row w-2/3 relative z-80"
              style={{ right: "-12px" }}
            >
              <div className="flex flex-1 flex-col w-max items-start justify-center">
                {/* <p className="font-bold text-base text-black1 text-left pl-3 pb-2">
                  Lokasi kampus
                </p> */}
                <ReactSelect
                  styles={styles}
                  options={listProvince}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  menuColor
                  getOptionLabel={(data) => data["name"]}
                  getOptionValue={(data) => data["id"]}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Lokasi Kampus"
                  onChange={(v) => {
                    const univProvince = new Set(v);
                    setFilterProvince(univProvince);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterProvince)}
                />
              </div>
              <div className="flex flex-1 flex-col w-max items-start justify-center pl-4">
                <ReactSelect
                  styles={styles}
                  options={univAccreditation}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  getOptionLabel={(data) => data["label"]}
                  getOptionValue={(data) => data["value"]}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Akreditasi"
                  onChange={(v) => {
                    const univAccre = new Set(v);
                    setFilterAcreditation(univAccre);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterAcreditation)}
                />
              </div>
              <div className="flex flex-1 flex-col w-max items-start justify-center pl-4">
                <ReactSelect
                  styles={styles}
                  options={majorsList}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  menuColor
                  getOptionLabel={(data) => data["name"]}
                  getOptionValue={(data) => data["id"]}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Jurusan"
                  onChange={(v) => {
                    const univMajor = new Set(v);
                    setFilterMajor(univMajor);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterMajor)}
                />
              </div>
              <div className="flex flex-1 flex-col w-max items-start justify-center pl-4">
                <ReactSelect
                  styles={styles}
                  options={universityType}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  menuColor
                  getOptionLabel={(data) => data["label"]}
                  getOptionValue={(data) => data["value"]}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Tipe Kampus"
                  onChange={(v) => {
                    const univType = new Set(v);
                    setFilterType(univType);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterType)}
                />
              </div>
            </div>
          )}
        </form>
        {/* Mobile Filter Modal */}
        {screenWidth < 1024 && (
          <div className="flex items-center z-80">
            <button
              type="button"
              onClick={() => {
                openModal();
                setFilterProvince([]);
                setFilterAcreditation([]);
                setFilterType([]);
              }}
            >
              <img src="/filter-icon.svg" />
            </button>
            <Transition appear show={isOpen} as={Fragment}>
              <Dialog as="div" className="relative z-90" onClose={() => {}}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="fixed inset-0"
                    style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
                  />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                  <div className="h-screen w-screen flex items-center justify-center p-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="flex w-full h-full overflow-hidden bg-white text-left align-middle shadow-xl rounded-2xl">
                        <div className="relative flex min-h-screen w-full flex-col overflow-y-auto bg-white">
                          <div className="flex flex-col w-full p-4 h-4/5 overflow-y-auto">
                            <div className="flex flex-col w-full p-2">
                              <button type="button" onClick={closeModal}>
                                <MdClose
                                  size={24}
                                  color="rgba(0, 0, 0, 0.54)"
                                />
                              </button>
                              <div className="flex flex-col gap-4 divide-y-2 mt-10 overflow-y-auto">
                                <div className="w-full items-start justify-center">
                                  <p className="font-bold text-base text-black1 text-left pb-2">
                                    Lokasi kampus
                                  </p>
                                  <Listbox value={mobileFilterProvince}>
                                    <div className="flex flex-col">
                                      <Listbox.Button>
                                        <div className="flex flex-row items-center">
                                          <p className="flex-1 font-normal text-sm text-gray1 text-left">
                                            Pilih lokasi kampus
                                          </p>
                                          <ChevronDownIcon
                                            className="w-5 h-5"
                                            style={{
                                              color: "rgba(23, 23, 23, 1)",
                                            }}
                                          />
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        unmount={false}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="h-46 overflow-y-auto mt-2"
                                        >
                                          {listProvince.map((item, index) => {
                                            return (
                                              <div
                                                key={item.id}
                                                className="flex items-center mb-4"
                                              >
                                                <label
                                                  htmlFor={item.name}
                                                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                  <input
                                                    id={item.name}
                                                    type="checkbox"
                                                    value={item.id}
                                                    onChange={(e) => {
                                                      handleFilterLokasiKampusMobile(
                                                        e,
                                                        item
                                                      );
                                                    }}
                                                    className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                  ></input>
                                                  <span className="ml-2 text-left font-normal text-sm text-black1">
                                                    {item.name}
                                                  </span>
                                                </label>
                                              </div>
                                            );
                                          })}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                                <div className="w-full items-start justify-center pt-4">
                                  <p className="font-bold text-base text-black1 text-left pb-2">
                                    Akreditasi
                                  </p>
                                  <Listbox value={filterAcreditation}>
                                    <div className="flex flex-col">
                                      <Listbox.Button>
                                        <div className="flex flex-row items-center">
                                          <p className="flex-1 font-normal text-sm text-gray1 text-left">
                                            Pilih akreditasi kampus
                                          </p>
                                          <ChevronDownIcon
                                            className="w-5 h-5"
                                            style={{
                                              color: "rgba(23, 23, 23, 1)",
                                            }}
                                          />
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        unmount={false}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="h-46 overflow-y-auto mt-2"
                                        >
                                          {univAccreditation.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  key={item.value}
                                                  className="flex items-center mb-4"
                                                >
                                                  <label
                                                    htmlFor={item.label}
                                                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                  >
                                                    <input
                                                      id={item.label}
                                                      type="checkbox"
                                                      value={item.id}
                                                      onChange={(e) => {
                                                        handleFilterAkreditasiMobile(
                                                          e,
                                                          item
                                                        );
                                                      }}
                                                      className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    ></input>
                                                    <span className="ml-2 text-left font-normal text-sm text-black1">
                                                      {item.label}
                                                    </span>
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                                <div className="w-full items-start justify-center pt-4">
                                  <p className="font-bold text-base text-black1 text-left pb-2">
                                    Jurusan
                                  </p>
                                  <Listbox value={filterMajor}>
                                    <div className="flex flex-col">
                                      <Listbox.Button>
                                        <div className="flex flex-row items-center">
                                          <p className="flex-1 font-normal text-sm text-gray1 text-left">
                                            Pilih kampus sesuai jurusan
                                          </p>
                                          <ChevronDownIcon
                                            className="w-5 h-5"
                                            style={{
                                              color: "rgba(23, 23, 23, 1)",
                                            }}
                                          />
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        unmount={false}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="h-46 overflow-y-auto mt-2"
                                        >
                                          {majorsList.map((item, index) => {
                                            return (
                                              <div
                                                key={item.id}
                                                className="flex items-center mb-4"
                                              >
                                                <label
                                                  htmlFor={item.name}
                                                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                  <input
                                                    id={item.name}
                                                    type="checkbox"
                                                    value={item.id}
                                                    onChange={(e) => {
                                                      handleFilterJurusanMobile(
                                                        e,
                                                        item
                                                      );
                                                    }}
                                                    className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                  ></input>
                                                  <span className="ml-2 text-left font-normal text-sm text-black1">
                                                    {item.name}
                                                  </span>
                                                </label>
                                              </div>
                                            );
                                          })}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                                <div className="w-full items-start justify-center pt-4">
                                  <p className="font-bold text-base text-black1 text-left pb-2">
                                    Tipe kampus
                                  </p>
                                  <Listbox value={filterType}>
                                    <div className="flex flex-col">
                                      <Listbox.Button>
                                        <div className="flex flex-row items-center">
                                          <p className="flex-1 font-normal text-sm text-gray1 text-left">
                                            Pilih tipe kampus
                                          </p>
                                          <ChevronDownIcon
                                            className="w-5 h-5"
                                            style={{
                                              color: "rgba(23, 23, 23, 1)",
                                            }}
                                          />
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        unmount={false}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="h-46 overflow-y-auto mt-2"
                                        >
                                          {universityType.map((item, index) => {
                                            return (
                                              <div
                                                key={item.value}
                                                className="flex items-center mb-4"
                                              >
                                                <label
                                                  htmlFor={item.label}
                                                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                  <input
                                                    id={item.label}
                                                    type="checkbox"
                                                    value={item.id}
                                                    onChange={(e) => {
                                                      handleFilterUnivTypeMobile(
                                                        e,
                                                        item
                                                      );
                                                    }}
                                                    className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                  ></input>
                                                  <span className="ml-2 text-left font-normal text-sm text-black1">
                                                    {item.label}
                                                  </span>
                                                </label>
                                              </div>
                                            );
                                          })}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="absolute flex flex-col w-full bottom-14 px-6 bg-white">
                            <button
                              onClick={closeModal}
                              className="flex flex-col items-center justify-center h-12 rounded-lg bg-primaryColor"
                            >
                              <p className="font-semibold text-base text-center text-white">
                                Terapkan filter pencarian
                              </p>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </div>
        )}
      </div>
      <div className="w-full flex flex-col justify-start items-center">
        {!keyword && (
          <div
            className="relative flex flex-col w-screen bg-primaryColor"
            style={{ paddingBottom: screenWidth >= 1024 && "23.2px" }}
          >
            <img
              className="absolute h-full top-0 left-0 object-cover"
              src="/bg-bubble-purple.svg"
            />
            <img
              className="absolute top-0 right-0 tablet:h-24 tablet:right-22 fhd:right-110 tablet:top-6"
              src={
                screenWidth < 1024
                  ? "/tosca-plane-with-trail-v2-mobile.svg"
                  : "/tosca-plane-with-trail-long.svg"
              }
            />
            <div
              className="relative tablet:pl-18 z-10"
              style={{
                paddingTop: screenWidth < 1024 ? "27px" : "33.37px",
              }}
            >
              <div className="flex flex-row w-full ">
                <p
                  className="text-left font-bold text-white ml-4 tablet:ml-0"
                  style={{
                    letterSpacing: screenWidth < 1024 ? "-0.87px" : "0.7px",
                    fontSize: screenWidth < 1024 ? "26px" : "42px",
                    lineHeight: screenWidth < 1024 ? "28.08px" : "45.36px",
                  }}
                >
                  Kampus paling diminati
                </p>
              </div>
            </div>
            {screenWidth < 1024 ? (
              <div className="flex flex-row w-full gap-6 justify-start items-center px-4 pb-8 overflow-x-auto mt-4">
                {topUnivs?.map((item, index) => {
                  return (
                    <HomeCardUniv
                      key={index}
                      id={item.id}
                      univData={item}
                      fav={item.favorite}
                      width="324.95px"
                      height="428.04px"
                    />
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  marginTop: "22.63px",
                  paddingLeft: screenWidth < 1910 && "71.11px",
                  paddingRight: screenWidth < 1910 && "46.22px",
                }}
              >
                <Slider ref={sliderRef} {...favUnivSetting}>
                  {topUnivs?.map((item, index) => (
                    <div key={index} className="pr-6">
                      <HomeCardUnivV3
                        id={item.id}
                        univData={item}
                        fav={item.favorite}
                        popular={true}
                        width={
                          screenWidth < 1440
                            ? "272.54px"
                            : screenWidth < 1910
                            ? "310px"
                            : "430px"
                        }
                        height={
                          screenWidth < 1440
                            ? "281.13px"
                            : screenWidth < 1910
                            ? "317px"
                            : "437px"
                        }
                        screenWidth={screenWidth}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            {screenWidth >= 1024 && topUnivs?.length > 4 && (
              <div
                className="w-full flex flex-row gap-2 items-center justify-center"
                style={{ marginTop: "22px" }}
              >
                <button
                  onClick={() => {
                    if (currentSliderPage === 2) {
                      sliderRef.current.slickPrev();
                    }
                    setCurrentSliderPage(1);
                  }}
                  className={`bg-white rounded-lg p-3 ${
                    currentSliderPage === 2 ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <ArrowLeftIcon
                    className="w-4 h-4 mac:w-5 mac:h-5"
                    color="rgba(23, 23, 23, 0.4)"
                  />
                </button>
                <button
                  onClick={() => {
                    if (currentSliderPage === 1) {
                      sliderRef.current.slickNext();
                    }
                    setCurrentSliderPage(2);
                  }}
                  className={`bg-white rounded-lg p-3 ${
                    currentSliderPage === 1 ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <ArrowRightIcon
                    className="w-4 h-4 mac:w-5 mac:h-5"
                    color="rgba(23, 23, 23, 0.4)"
                  />
                </button>
              </div>
            )}
          </div>
        )}
        <div
          className={`flex flex-col w-full items-start tablet:px-7 ${
            Array.from(filterProvince).length > 1 ||
            Array.from(filterAcreditation).length > 1 ||
            Array.from(filterMajor).length > 1 ||
            Array.from(filterType).length > 1
              ? "mt-12 tablet:mt-32"
              : "mt-7"
          } mb-0`}
        >
          {/* Filter Province */}
          {Array.from(filterProvince).length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Lokasi Kampus:</p>
              {Array.from(filterProvince).map((prov, index) => (
                <span
                  key={prov["id"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{prov["name"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteProvince(e, prov)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
          {/* Filter Akreditas */}
          {Array.from(filterAcreditation).length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Akreditasi:</p>
              {Array.from(filterAcreditation).map((accreditation, index) => (
                <span
                  key={accreditation["value"]}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{accreditation["label"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteAcreditation(e, accreditation)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
          {/* Filter Akreditas */}
          {Array.from(filterMajor).length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Jurusan:</p>
              {Array.from(filterMajor).map((major, index) => (
                <span
                  key={index}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{major.name}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteMajor(e, major)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
          {/* Filter Univ Type */}
          {Array.from(filterType).length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Tipe Kampus:</p>
              {Array.from(filterType).map((type, index) => (
                <span
                  key={type["value"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{type["label"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteType(e, type)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
        {listData.universities.length < 1 && listData.universities && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">
                Pencarian data tidak di temukan
              </h3>
            </div>
          </div>
        )}
        <div
          className="pb-4 grid tablet:grid-cols-4 justify-center tablet:mt-0"
          style={{ rowGap: "28.44px", columnGap: "15.91px" }}
        >
          {listData.universities.map((item, index) => {
            return screenWidth < 1024 ? (
              <HomeCardUniv
                key={index}
                id={item.id}
                univData={item}
                fav={item.favorite}
                width="324.95px"
                height="428.04px"
              />
            ) : (
              <HomeCardUnivV3
                key={index}
                id={item.id}
                univData={item}
                fav={item.favorite}
                popular={true}
                width={
                  screenWidth < 1440
                    ? "272.54px"
                    : screenWidth < 1910
                    ? "310px"
                    : "430px"
                }
                height={
                  screenWidth < 1440
                    ? "281.13px"
                    : screenWidth < 1910
                    ? "317px"
                    : "437px"
                }
                screenWidth={screenWidth}
              />
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={listData.metadata.total_item}
          pageSize={listData.metadata.limit}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <Footer />
    </div>
  );
}
