import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useState } from "react";

export default function PaymentInfoAccordion({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="flex flex-col justify-center items-center w-full rounded-2xl p-4 border-1 mt-4"
      style={{ borderColor: "rgba(217, 219, 219, 1)" }}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <p
          className="text-left font-normal text-base"
          style={{ lineHeight: "24px", color: "rgba(123, 128, 130, 1)" }}
        >
          {title}
        </p>
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <ChevronUpIcon
              style={{
                width: "24px",
                height: "24px",
                color: "rgba(179, 182, 184, 1)",
              }}
            />
          ) : (
            <ChevronDownIcon
              style={{
                width: "24px",
                height: "24px",
                color: "rgba(179, 182, 184, 1)",
              }}
            />
          )}
        </button>
      </div>
      {isOpen && (
        <div
          className="flex flex-col w-full justify-start items-start mt-5 text-left text-sm gap-3"
          style={{ lineHeight: "24px", color: "rgba(123, 128, 130, 1)" }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
