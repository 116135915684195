import { Link } from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import LayoutV2 from "../components/Layout_V2";

function Login() {
  return (
    <LayoutV2>
      <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20 h-full flex flex-1 px-4">
        <div className="max-w-3xl mx-auto text-center pb-12 tablet:pb-20">
          <h1 className="h1 mb-4 text-8xl">404</h1>
          <p className="h4">Halaman yang kamu cari tidak di temukan</p>
          <div className="text-center flex justify-center mt-10">
            <Link to="/" className="block">
              <Button>Kembali ke Beranda</Button>
            </Link>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}

export default Login;
