import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";
import { api, apiAnom, notifError } from "../../../../utils/api";
import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import MiniQuizData from "./miniQuizData.json";

export default function MiniQuiz({ screenWidth, onBack, onNext }) {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [quiz, setQuiz] = useState();
  const [quizIndex, setQuizIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState();
  const [selectedAnswerId, setSelectedAnswerId] = useState();
  const [isLastQ, setIsLastQ] = useState(false);

  useEffect(() => {
    setQuiz(MiniQuizData);
    // _api
    //   .get("/mini-quiz/questions")
    //   .then((res) => {
    //     setQuiz(res.data.data);
    //   })
    //   .catch(notifError);
  }, []);

  function prevQuestion() {
    if (quizIndex === 0) {
      onBack();
    } else {
      setQuizIndex(quizIndex - 1);
    }
  }

  function nextQuestion() {
    setIsLastQ(false);
    if (quizIndex === quiz.length - 1) {
      let jsonForm = [
        ...answers,
        {
          question_id: quizIndex + 1,
          answer_id: selectedAnswerId,
          answer_index: selectedAnswerIndex + 1,
        },
      ];
      onNext(jsonForm);
    } else {
      let jsonForm = [
        ...answers,
        {
          question_id: quizIndex + 1,
          answer_id: selectedAnswerId,
          answer_index: selectedAnswerIndex + 1,
        },
      ];
      setAnswers(jsonForm);
      setSelectedAnswer();
      setSelectedAnswerIndex();
      setSelectedAnswerId();
      setQuizIndex(quizIndex + 1);
    }
  }

  useEffect(() => {
    if (quizIndex !== quiz?.length - 1) {
      if (selectedAnswer && selectedAnswerIndex >= 0 && selectedAnswerId) {
        nextQuestion();
      }
    }
  }, [selectedAnswer, selectedAnswerIndex, selectedAnswerId]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isLastQ) {
      event.preventDefault();
      nextQuestion();
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      className="flex flex-col w-full h-full items-center sm:relative"
    >
      <div
        className="flex w-full h-auto flex-row justify-between items-start relative z-50"
        style={{
          marginTop: screenWidth < 1024 ? "46px" : "40.25px",
          paddingLeft: screenWidth < 1024 ? "35.38px" : "113.25px",
          paddingRight: screenWidth < 1024 ? "17px" : "60px",
        }}
      >
        <button>
          <MdArrowBack
            onClick={prevQuestion}
            className="w-6 sm:w-10 h-auto"
            style={{ color: "white" }}
          />
        </button>
        <div
          className="h-auto flex flex-row justify-between items-center"
          style={{
            paddingTop: "5.29px",
            paddingBottom: "4.96px",
            paddingLeft: "19.86px",
            paddingRight: "7.23px",
            borderRadius: screenWidth < 1024 ? "22.66px" : "30px",
            borderWidth: "2px",
            borderColor: "rgba(255, 202, 45, 1)",
            gap: "9.14px",
          }}
        >
          <p className="text-left font-bold text-xs sm:text-base text-white">
            Mini Quiz
          </p>
          <p
            className="text-center font-semibold text-xs sm:text-base text-black px-6 sm:px-4 sm:py-3 bg-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "22.66px",
            }}
          >
            {quizIndex + 1}/{quiz?.length}
          </p>
        </div>
      </div>
      {quiz && (
        <div
          className="w-full h-full flex flex-col px-5 tablet:px-9 relative z-50 sm:absolute"
          style={{
            minWidth: screenWidth >= 1024 && "630px",
            maxWidth: screenWidth >= 1024 && "630px",
            marginTop: screenWidth < 1024 && "14px",
          }}
        >
          <div
            className="text-left font-semibold text-base sm:text-xl text-white sm:h-auto flex items-end"
            style={{
              minHeight: screenWidth < 1024 ? "73.1px" : "127.34px",
            }}
          >
            {quiz[quizIndex].question}
          </div>
          <div
            className="flex flex-col items-stretch w-full"
            style={{
              marginTop: "13.89px",
            }}
          >
            <div
              className="flex flex-col justify-start items-stretch gap-2"
              style={{
                minWidth: screenWidth < 1024 ? "319px" : "553.78px",
                maxWidth: screenWidth < 1024 ? "319px" : "553.78px",
              }}
            >
              {quiz[quizIndex].answers.map((item, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => {
                    setSelectedAnswer(item.answer_code);
                    setSelectedAnswerIndex(index);
                    setSelectedAnswerId(item.answer_id);
                    if (quizIndex === quiz?.length - 1) {
                      setIsLastQ(true);
                    }
                  }}
                  className="w-full h-auto flex flex-row justify-start items-center rounded-full transform ease-in-out duration-300"
                  style={{
                    gap: "8px",
                    backgroundColor:
                      index === selectedAnswerIndex
                        ? "rgba(255, 202, 45, 1)"
                        : "white",
                    padding: screenWidth < 1024 ? "4px" : "6px",
                    paddingRight: "23px",
                  }}
                >
                  <div
                    className="rounded-full transform ease-in-out duration-300 flex justify-center items-center"
                    style={{
                      backgroundColor:
                        index === selectedAnswerIndex
                          ? "white"
                          : "rgba(255, 202, 45, 1)",
                      padding: screenWidth < 1024 ? "9px" : "14px",
                      minWidth: screenWidth < 1024 ? "36px" : "52px",
                      maxWidth: screenWidth < 1024 ? "36px" : "52px",
                      minHeight: screenWidth < 1024 ? "36px" : "52px",
                      maxHeight: screenWidth < 1024 ? "36px" : "52px",
                    }}
                  >
                    <p
                      className="text-center font-medium text-base sm:text-lg"
                      style={{
                        color:
                          index === selectedAnswerIndex
                            ? "rgba(255, 202, 45, 1)"
                            : "black",
                      }}
                    >
                      {item.answer_code}
                    </p>
                  </div>
                  <p
                    className={`text-left ${
                      index === selectedAnswerIndex
                        ? "font-bold"
                        : "font-medium"
                    } text-xs tablet:text-sm text-black`}
                    style={{
                      lineHeight: screenWidth < 1024 ? "15.12px" : "17.64px",
                    }}
                  >
                    {item.answer_description}
                  </p>
                </button>
              ))}
            </div>
            <input type="hidden" name="selected" value={selectedAnswer ?? ""} />
            {quizIndex === quiz.length - 1 && (
              <div className="flex w-full justify-end">
                <button
                  onClick={nextQuestion}
                  type="button"
                  disabled={!selectedAnswerIndex && !selectedAnswer}
                  className="flex items-center justify-center rounded-md text-center font-bold text-base text-black1"
                  style={{
                    backgroundColor:
                      !selectedAnswerIndex && !selectedAnswer
                        ? "grey"
                        : "rgba(255, 202, 45, 1)",
                    marginTop: "25px",
                    lineHeight: "20.16px",
                    borderRadius: "7.51px",
                    minHeight: "46px",
                    maxHeight: "46px",
                    minWidth: "120px",
                    minWidth: "120px",
                  }}
                >
                  Selesai
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
