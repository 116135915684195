import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { api, apiAnom, notifError } from "../utils/api";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../utils/utils";
import Button from "../components/base/Button";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { map } from "lodash";
import LayoutV2 from "../components/Layout_V2";

const educationLevels = [
  { id: 1, name: "10" },
  { id: 2, name: "11" },
  { id: 3, name: "12" },
  { id: 4, name: "SMP (atau sederajat)" },
  { id: 5, name: "Lulusan SMA (atau sederajat)" },
];

const majors = [
  { name: "IPA" },
  { name: "IPS" },
  { name: "Bahasa" },
  { name: "Not Applicable" },
];

const otherSchool = {
  id: 0,
  name: "Others",
};

const ProfileEdit = () => {
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authState);
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [firstName, setFirstName] = useState("");
  const [disableChangeFirstName, setDisableChangeFirstName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [disableChangeLastName, setDisableChangeLastName] = useState(false);
  const [phone, setPhone] = useState("");
  const [disableChangePhone, setDisableChangePhone] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [customSchoolName, setCustomSchoolName] = useState("");
  const [school, setSchool] = useState(null);
  const [schools, setSchools] = useState([]);
  const [major, setMajor] = useState("Not Applicable");
  const [educationLevelId, setEducationLevelId] = useState(0);
  const [kelas, setKelas] = useState("");
  const [disableKelas, setDisableKelas] = useState(false);

  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get("/me")
      .then((res) => {
        const data = res.data.data;
        setFirstName(data.fname);
        if (data.fname.length > 0) {
          setDisableChangeFirstName(false);
        }
        setLastName(data.lname);
        if (data.lname.length > 0) {
          setDisableChangeLastName(false);
        }
        setPhone(data.phone);
        if (data.phone.length > 0) {
          setDisableChangePhone(false);
        }
        setKeyword(data.school_name);
        setSchool({ id: data.school_id, name: data.school_name });
        setMajor(res.data.data.major);
        if (data.education_level_id > 0) {
          setEducationLevelId(data.education_level_id);
          if (data.education_level_id === 5 || data.education_level_id === 4) {
            setDisableKelas(true);
          } else {
            setDisableKelas(false);
          }
        }
        setCustomSchoolName(data.custom_school_name);
        setKelas(data.class);
      })
      .catch(notifError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    apiAnom
      .get(`/schools?p=1&l=20&k=${keyword}`)
      .then((res) => {
        let data = res.data.data;
        if (data.length < 1) {
          data = [otherSchool];
        }
        setSchools(data);
      })
      .catch((_) => {
        setSchools(_);
      })
      .finally(() => setLoading(false));
  }, [keyword]);

  useEffect(() => {
    if (!school) return;
    setKeyword(school.name);
  }, [school]);

  useEffect(() => {
    if (disableKelas) setKelas("");
  }, [disableKelas]);

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    if (!validate(elements)) return;
    setLoading(true);
    // building payload
    const payload = {
      fname: firstName,
      lname: lastName,
      phone: phone,
      school_id: school.id,
      custom_school_name: customSchoolName,
      education_level_id: parseInt(educationLevelId),
      major: major,
      class: kelas,
    };
    api
      .put("/me", payload)
      .then(() => {
        api
          .get("/me")
          .then((res) => {
            setAuth({ ...res.data.data, isAuth: true });
            window.Swal.fire({
              title: "Success!",
              text: "Profile berhasil diubah!",
              icon: "success",
              confirmButtonText: "Tutup",
            }).then(() => {
              if (auth.tmb_result_id > 0) {
                navigate(`/tes-minat-result/${auth.tmb_result_id}`);
              } else {
                navigate("/profile");
              }
            });
          })
          .catch(notifError);
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <LayoutV2>
      <div className="tablet:pt-8 pb-12 tablet:pb-20 mx-auto">
        {/* Page header */}
        <div className="max-w-3xl mx-auto text-center pb-12 tablet:pb-20">
          <h1 className="tablet:h1 h3 tracking-wide">Lengkapi Profil</h1>
        </div>

        {/* Form */}
        <div className="max-w-lg mx-auto">
          <form onSubmit={onSubmit} className="w-full lg:w-auto">
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <div className={"grid grid-cols-2 gap-4"}>
                  <div>
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="fname"
                    >
                      Nama depan <span className={"text-red-500"}>*</span>
                    </label>
                    <input
                      id="fname"
                      type="text"
                      name="fname"
                      className={`form-input w-full text-gray-800 ${
                        mapHasKeyAndValueNotEmpty(errors, "fname")
                          ? "border-red-400"
                          : ""
                      }`}
                      placeholder="Masukkan nama depan"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={disableChangeFirstName}
                      required
                      onInvalid={(F) =>
                        F.target.setCustomValidity("Nama Depan harus diisi")
                      }
                      onInput={(F) => F.target.setCustomValidity("")}
                    />
                    {mapHasKeyAndValueNotEmpty(errors, "fname") && (
                      <div className={"text-red-400"}>{errors["fname"]}</div>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="lname"
                    >
                      Nama belakang <span className={"text-red-500"}>*</span>
                    </label>
                    <input
                      id="lname"
                      type="text"
                      name="lname"
                      className={`form-input w-full text-gray-800 ${
                        mapHasKeyAndValueNotEmpty(errors, "lname")
                          ? "border-red-400"
                          : ""
                      }`}
                      placeholder="Masukkan nama belakang"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      disabled={disableChangeLastName}
                      required
                      onInvalid={(F) =>
                        F.target.setCustomValidity("Nama Belakang harus diisi")
                      }
                      onInput={(F) => F.target.setCustomValidity("")}
                    />
                    {mapHasKeyAndValueNotEmpty(errors, "lname") && (
                      <div className={"text-red-400"}>{errors["lname"]}</div>
                    )}
                  </div>
                </div>
                <label
                  className="block text-gray-800 text-sm font-medium mb-1 mt-4"
                  htmlFor="phone"
                >
                  No. HP Siswa <span className={"text-red-500"}>*</span>
                </label>
                <input
                  id="phone"
                  type="text"
                  name="phone"
                  accept="phone"
                  className={`form-input w-full text-gray-800 ${
                    mapHasKeyAndValueNotEmpty(errors, "phone")
                      ? "border-red-400"
                      : ""
                  }`}
                  placeholder="08xxxxxxx"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={disableChangePhone}
                  required
                  onInvalid={(F) =>
                    F.target.setCustomValidity("No. HP harus diisi")
                  }
                  onInput={(F) => F.target.setCustomValidity("")}
                />
                {mapHasKeyAndValueNotEmpty(errors, "phone") && (
                  <div className={"text-red-400"}>{errors["phone"]}</div>
                )}
                <label
                  className="block text-gray-800 text-sm font-medium mb-1 mt-4"
                  htmlFor="school"
                >
                  Nama Sekolah <span className={"text-red-500"}>*</span>
                </label>
                <div className="relative">
                  <input
                    id="school"
                    type="text"
                    name="school"
                    autoComplete="off"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      setShowOptions(true);
                    }}
                    className="w-full text-gray-800 form-input"
                    placeholder="Pilih Sekolah"
                    onFocus={() => setShowOptions(true)}
                    required
                  />
                  {showOptions && schools.length > 0 && (
                    <div
                      className="absolute left-0 w-full bg-white border border-gray-200 shadow-lg top-1/1"
                      style={{ marginTop: -3 }}
                    >
                      <div
                        style={{ maxHeight: 150 }}
                        className="overflow-auto py-3"
                      >
                        {schools.map((d, i) => {
                          return (
                            <Button
                              size="small"
                              key={i}
                              onClick={() => {
                                setSchool(d);
                                setKeyword(d.name);
                                setShowOptions(false);
                              }}
                              className="justify-start w-full"
                              variant="link"
                            >
                              {d.name}
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {mapHasKeyAndValueNotEmpty(errors, "school") && (
                  <div className={"text-red-400"}>{errors["school"]}</div>
                )}
                {school && school.id === otherSchool.id && (
                  <>
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1 mt-4"
                      htmlFor="custom_school_name"
                    >
                      Masukkan Nama Sekolah{" "}
                      <span className={"text-red-500"}>*</span>
                    </label>
                    <input
                      id="custom_school_name"
                      type="text"
                      name="custom_school_name"
                      autoComplete="off"
                      value={customSchoolName}
                      onChange={(e) => setCustomSchoolName(e.target.value)}
                      className="w-full text-gray-800 form-input"
                      placeholder="Masukkan nama sekolah kamu"
                      required
                    />
                  </>
                )}
                <label
                  className="block text-gray-800 text-sm font-medium mb-1 mt-4"
                  htmlFor="major"
                >
                  Jurusan <span className={"text-red-500"}>*</span>
                </label>
                <select
                  id="major"
                  placeholder={"Jurusan"}
                  className={`form-input w-full text-gray-800 ${
                    mapHasKeyAndValueNotEmpty(errors, "major")
                      ? "border-red-400"
                      : ""
                  }`}
                  aria-label="major"
                  name="major"
                  value={major}
                  onChange={(e) => setMajor(e.target.value)}
                  required
                >
                  <option disabled value="">
                    Pilih Jurusan
                  </option>
                  {majors.map((item) => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {mapHasKeyAndValueNotEmpty(errors, "major") && (
                  <div className={"text-red-400"}>{errors["major"]}</div>
                )}
                <label
                  className="block text-gray-800 text-sm font-medium mb-1 mt-4"
                  htmlFor="education_level"
                >
                  Tingkat Kelas <span className={"text-red-500"}>*</span>
                </label>
                <select
                  id="education_level"
                  placeholder={"Tingkat Kelas"}
                  className={`form-input w-full text-gray-800 ${
                    mapHasKeyAndValueNotEmpty(errors, "education")
                      ? "border-red-400"
                      : ""
                  }`}
                  aria-label="education level"
                  name="education_level_id"
                  value={educationLevelId}
                  onChange={(e) => {
                    const eduLevelId = parseInt(e.target.value);
                    if (eduLevelId > 0) {
                      setEducationLevelId(eduLevelId);
                    }
                    if (eduLevelId === 5 || eduLevelId === 4) {
                      setDisableKelas(true);
                    } else {
                      setDisableKelas(false);
                    }
                  }}
                  required
                >
                  <option disabled value="0">
                    Tingkat Kelas
                  </option>
                  {educationLevels.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {mapHasKeyAndValueNotEmpty(errors, "education_level") && (
                  <div className={"text-red-400"}>
                    {errors["education_level"]}
                  </div>
                )}
                <label
                  className="block text-gray-800 text-sm font-medium mb-1 mt-4"
                  htmlFor="grade"
                >
                  Kelas <span className={"text-red-500"}>*</span>
                </label>
                <input
                  id="class"
                  type="text"
                  name="class"
                  className={`form-input w-full text-gray-800 ${
                    mapHasKeyAndValueNotEmpty(errors, "grade")
                      ? "border-red-400"
                      : ""
                  }`}
                  placeholder="Contoh: A, B, 1, 2"
                  value={kelas}
                  onChange={(e) => setKelas(e.target.value)}
                  disabled={disableKelas}
                  required
                />
                {mapHasKeyAndValueNotEmpty(errors, "grade") && (
                  <div className={"text-red-400"}>{errors["grade"]}</div>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <Button disabled={loading} type="submit" className="w-full">
                  Simpan
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutV2>
  );
};

export default ProfileEdit;