import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { useState } from "react";

export default function AdmisFAQ({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`transition transform ease-in-out flex flex-col p-4 w-full h-max gap-4 rounded-2xl ${
        isOpen ? "bg-tosca2" : "bg-white"
      }`}
      style={{
        border: "1px solid #43BCCD",
      }}
    >
      <div className="flex flex-row gap-3 w-full justify-between items-end h-max">
        <p
          className={`text-left font-bold text-base ${
            isOpen ? "text-white" : "text-black1"
          }`}
        >
          {question}
        </p>
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{
            padding: "0px",
            minWidth: "16px",
            maxWidth: "16px",
          }}
        >
          {isOpen ? (
            <ChevronUpIcon color="#FFFFFF" />
          ) : (
            <ChevronDownIcon color="#171717" />
          )}
        </button>
      </div>
      {isOpen && (
        <p
          className="text-left font-normal text-base text-white"
          style={{ lineHeight: "24px" }}
        >
          {answer}
        </p>
      )}
    </div>
  );
}
