import { useState } from "react";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import { apiAnom } from "../utils/api";
import _get from "lodash/get";
import Cookies from "js-cookie";
import LayoutV2 from "../components/Layout_V2";

function SignUp() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    apiAnom
      .post("/students/pre-register", {
        email: e.target.elements[0].value,
      })
      .then(async (response) => {
        // when recently has proceeding the same request
        if (response.status === 208) {
          setMessage("An email has been sent. Please check your inbox");
          return;
        }
        const storage = localStorage.getItem("TMB_ANSWERS");
        if (storage != null) {
          const answers = JSON.parse(storage);
          const result = await apiAnom.post(
            `/tmb/submit-test-result?email=${
              e.target.elements[0].value
            }&ptmq_id=${Cookies.get("ptmqID")}`,
            answers
          );
          if (result?.data?.code === 200) {
            Cookies.remove("ptmqID");
            localStorage.removeItem("TMB_ANSWERS");
            // navigate("/tes-minat-result/3");
          }
        }

        setSuccess(true);
      })
      .catch((err) => {
        const message = _get(
          err.response,
          "data.error.message",
          "Something went wrong!"
        );
        window.Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          confirmButtonText: "Tutup",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (success) {
    return (
      <LayoutV2>
        <div className="pt-8 pb-12 tablet:pt-36 tablet:pb-20">
          <img
            src={"/email.svg"}
            alt={"mail sent"}
            className="h-20 w-20 mx-auto mb-5"
          />
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1 className="tablet:h1 h3 mb-6">Cek Emailmu!</h1>
            <p className="text-gray-600">
              Kami telah mengirimkan link ke alamat emailmu agar kamu dapat
              melanjutkan proses registrasi.
            </p>
            <p className="text-gray-600 mt-4">
              Yuk, ikuti langkah-langkah pembuatan akun agar kamu bisa mulai
              mengeksplor berbagai kelas di exploration!
            </p>
          </div>
        </div>
      </LayoutV2>
    );
  }

  return (
    <LayoutV2>
      <div className="pb-12 tablet:pt-8 tablet:pb-20">
        <div className="max-w-3xl pb-12 mx-auto text-center tablet:pb-20">
          <h1 className="tablet:h1 h3">Registrasi Akun</h1>
        </div>
        <div className="max-w-lg mx-auto">
          <form onSubmit={onSubmit}>
            <div className="flex flex-wrap mb-4 -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-1 text-sm font-medium text-gray-800"
                  htmlFor="email"
                >
                  Email <span className="text-red-600">*</span>
                </label>
                <input
                  id="email"
                  type="email"
                  className="w-full text-gray-800 form-input"
                  placeholder="Masukkan alamat email kamu"
                  required
                />
                {message.length > 0 && (
                  <div className={"text-red-500"}>{message}</div>
                )}
              </div>
            </div>
            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full px-3">
                <Button disabled={loading} type="submit" className="w-full">
                  Kirim Link
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutV2>
  );
}

export default SignUp;
