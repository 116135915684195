import { LocationMarkerIcon, StarIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import FavoriteButton from "./FavoriteButton";
import { favUniState } from "../atoms/favUni";
import { signInUpState } from "../atoms/signInUpState";

export default function HomeCardUnivV3({
  univData,
  fav,
  width,
  height,
  screenWidth,
}) {
  const [onHover, setOnHover] = useState(false);
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(fav);
  const auth = useRecoilValue(authState);
  const [faved, setFaved] = useState(false);
  const _api = auth.isAuth ? api : apiAnom;
  const setSign = useSetRecoilState(signInUpState);
  const setFavState = useSetRecoilState(favUniState);

  const addUnivToFav = (fav, id) => {
    if (!auth.isAuth) {
      if (window.location.pathname === "/") {
        navigate({
          pathname: "/signin",
          search: `?redirect=/explore-kampus/detail/${id}`,
        });
      }
      setSign({ trySignUp: true, trySignIn: false });
      setFavState({ afterFavUni: true, univId: id });
    }

    if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    }

    const url = fav
      ? "/favorites/university/add"
      : "/favorites/university/delete";

    _api
      .post(url, {
        uni_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };

  const typeTitle = (type) => {
    switch (type) {
      case "N":
        return "Negeri";
      case "L":
        return "Luar Negeri";
      case "S":
        return "Swasta";
      default:
        return "Lainnya";
    }
  };

  return (
    <div
      onClick={() => {
        navigate(
          univData.slug
            ? `/explore-kampus/${univData.slug}`
            : `/explore-kampus/detail/${univData.id}`
        );
      }}
      className="relative flex flex-col items-stretch bg-white rounded-lg shadow-md hover:shadow-2xl"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      style={{
        minWidth: width ? width : "100%",
        maxWidth: width ? width : "100%",
        minHeight: height ? height : "100%",
        maxHeight: height ? height : "100%",
        cursor: "pointer",
      }}
    >
      <div className="w-full h-full relative">
        {univData && (
          <img
            src={univData.img_url}
            className="object-cover rounded-t-lg w-full"
            alt="univ bg img"
            style={{
              maxHeight:
                screenWidth < 1440
                  ? "103.44px"
                  : screenWidth < 1910
                  ? "110px"
                  : "160px",
            }}
          />
        )}
        <FavoriteButton
          className="top-3 right-3 shadow-2xl"
          isFavorite={favorite}
          onChangeFavorite={() => {
            //callback fav, and id;
            addUnivToFav(!favorite, univData.id);
            if (!favorite) {
              setFaved(true);
              setTimeout(() => {
                setFaved(false);
              }, 1000);
            }
          }}
        />
        <div
          className="absolute flex flex-row justify-start items-center gap-2"
          style={{ left: "17.92px", bottom: "9.95px" }}
        >
          <div
            className="flex justify-center items-center bg-backgroundGray4"
            style={{
              height: "21.22px",
              padding: "0px 7.96px",
              borderRadius: "5.3px",
            }}
          >
            <p
              className="text-center font-medium text-black1"
              style={{
                fontSize: "10.44px",
                lineHeight: "15.67px",
              }}
            >
              {typeTitle(univData.type)}
            </p>
          </div>
          {univData.tmb_result_matched && (
            <div
              className="flex justify-center items-center"
              style={{
                height: "21.22px",
                padding: "0px 7.96px",
                borderRadius: "5.3px",
                backgroundColor: "#FE5244",
              }}
            >
              <p
                className="text-center font-medium text-white"
                style={{
                  fontSize: "10.44px",
                  lineHeight: "15.67px",
                }}
              >
                Kamu banget ✨
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        className="relative mac:mt-2 fhd:mt-4"
        style={{ padding: "15.91px 21.22px" }}
      >
        <img
          alt="favgirl"
          className={`absolute -top-42 left-14 transition-opacity duration-1000 ease-in-out ${
            faved ? "opacity-100" : "opacity-0"
          }`}
          src="/favorite-girl-fade.svg"
        />
        <div
          className="flex flex-row items-center justify-start"
          style={{ gap: "10.21px", height: "37.13px" }}
        >
          <img
            src={univData.logo_url}
            alt={"logo"}
            className="object-contain"
            style={{
              width:
                screenWidth < 1440
                  ? "37.13px"
                  : screenWidth < 1440
                  ? "40px"
                  : "50px",
              height:
                screenWidth < 1440
                  ? "37.13px"
                  : screenWidth < 1440
                  ? "40px"
                  : "50px",
            }}
          />
          <p
            className="text-left font-bold text-base fhd:text-2xl text-black1 line-clamp-2 overflow-ellipsis"
            // style={{ fontSize: "14.92px", lineHeight: "17.9px" }}
          >
            {univData.name}
            {univData.verified && (
              <span className="inline-flex tablet:align-text-bottom mac:align-bottom">
                <img
                  alt="verifIcon"
                  src="/verified_icon2.svg"
                  className="h-4 w-4 ml-1 tablet:h-5 tablet:w-5 tablet:ml-2 mac:h-6 mac:w-6 mac:ml-2"
                />
              </span>
            )}
          </p>
        </div>
        <div
          className="text-left font-normal text-xs fhd:text-lg text-black1 line-clamp-3 overflow-ellipsis mt-4 mac:mt-6 fhd:mt-8"
          // style={{ fontSize: "10.44px", lineHeight: "16.19px" }}
          dangerouslySetInnerHTML={{
            __html: univData.description.replace(/<[^>]+>/g, ""),
          }}
        />
        <div className="flex flex-row mt-4 gap-4">
          <div
            className="flex flex-row items-center justify-start"
            style={{ gap: "5.3px" }}
          >
            <LocationMarkerIcon
              style={{ width: "10.41px", height: "10.41px" }}
            />
            <p
              className="text-left font-normal text-xs fhd:text-lg text-black188"
              // style={{
              //   fontSize: "10.44px",
              //   lineHeight: "16.19px",
              // }}
            >
              {univData.province}
            </p>
          </div>
          {univData.accreditation !== "Not Applied" && (
            <div
              className="flex flex-row items-center justify-start"
              style={{ gap: "5.3px" }}
            >
              <StarIcon style={{ width: "10.41px", height: "10.41px" }} />
              <p
                className="text-left font-normal text-xs fhd:text-lg text-black188"
                // style={{
                //   fontSize: "10.44px",
                //   lineHeight: "16.19px",
                // }}
              >
                Akreditasi {univData.accreditation}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
