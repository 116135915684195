export default function CardContentDetailKampus({
  screenWidth,
  imgUrl,
  title,
  customGap,
  img_src,
}) {
  return (
    <div
      className="flex flex-col bg-white"
      style={{
        minWidth: screenWidth < 1024 ? "273px" : "366px",
        maxWidth: screenWidth < 1024 ? "273px" : "366px",
        minHeight: screenWidth < 1024 ? "182px" : "253.56px",
        maxHeight: screenWidth < 1024 ? "182px" : "253.56px",
        borderRadius: "10px",
        boxShadow: "26px 26px 216.67px 0px rgba(23, 23, 23, 0.08)",
        marginRight: customGap,
      }}
    >
      <div
        className="w-full relative"
        style={{
          height: screenWidth < 1024 ? "106px" : "128px",
        }}
      >
        <img
          className="object-cover w-full h-full"
          src={imgUrl}
          style={{
            borderRadius: "10px 10px 0px 0px",
          }}
        />
        {screenWidth >= 1024 && (
          <p
            className="text-right font-normal italic text-white absolute z-30"
            style={{
              fontSize: "14.22px",
              lineHeight: "22.04px",
              right: "28.22px",
              bottom: "10.89px",
            }}
          >
            {img_src}
          </p>
        )}
      </div>
      <div className="flex flex-col h-full w-full px-5 py-6 tablet:p-7">
        <p
          className="text-left font-bold text-xl text-black line-clamp-2 overflow-ellipsis"
          style={{ lineHeight: "24px" }}
        >
          {title}
        </p>
      </div>
    </div>
  );
}
