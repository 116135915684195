import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { signInUpState } from "../atoms/signInUpState";
import { InputPassword } from "./InputPassword";
import { Link, useNavigate } from "react-router-dom";
import Button from "./base/Button";
import { api, apiAnom, notifError } from "../utils/api";
import Cookies from "js-cookie";
import { authState } from "../atoms/authState";
import { useGoogleLogin } from "@react-oauth/google";
import { regisUniState } from "../atoms/regisUni";
import { favUniState } from "../atoms/favUni";
import { favMajorState } from "../atoms/favMajor";
import { get, split } from "lodash";
import ButtonV2 from "./ButtonV2";

export default function SignInModal() {
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const regisUni = useRecoilValue(regisUniState);
  const setRegisUni = useSetRecoilState(regisUniState);
  const favUni = useRecoilValue(favUniState);
  const setFavUniState = useSetRecoilState(favUniState);
  const favMajor = useRecoilValue(favMajorState);
  const setFavMajorState = useSetRecoilState(favMajorState);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  const onSubmit = async (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();
    setSign({ trySignIn: false, trySignUp: false });

    apiAnom
      .post("/auth/student", {
        email: elements[0].value.trim(),
        password: elements[1].value,
      })
      .then((res1) => {
        const tmb_result_id = res1.data.data.tmb_result_id;
        Cookies.set("user.token", res1.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });
        if (res1.data.data.is_student_inject) {
          navigate("/link-email");
          return;
        }
        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res1.data.data.request_token,
            },
          })
          .then((res) => {
            setAuth({ ...res.data.data, isAuth: true });
            const storage = localStorage.getItem("TMB_ANSWERS");
            if (storage != null) {
              const ptmqData = JSON.parse(Cookies.get("userPTMQData"));
              const responseBody = {
                ...ptmqData,
                student_id: parseInt(res.data.data.id),
                pre_test_result: {
                  ...ptmqData.pre_test_result,
                  email: res.data.data.email,
                },
                mini_quiz_result: {
                  ...ptmqData.mini_quiz_result,
                  email: res.data.data.email,
                },
              };
              api
                .post("/ptmq/submit", responseBody)
                .then((res) => {
                  Cookies.remove("userPretestData");
                  Cookies.remove("pretestStepIndex");
                  Cookies.remove("userMQData");
                  Cookies.remove("userPTMQData");
                  const answers = JSON.parse(storage);
                  api
                    .post(
                      `/tmb/submit-test-result?email=${elements[0].value}&ptmq_id=${res.data.data.ptmq_id}`,
                      answers
                    )
                    .then((res) => {
                      if (res.data.code === 200) {
                        localStorage.removeItem("TMB_ANSWERS");
                        Cookies.set("justFinishTMB", true, {
                          sameSite: "None",
                          secure: true,
                          expires: 1,
                        });
                        navigate("/profile");
                      }
                    })
                    .catch(notifError);
                })
                .catch(notifError);
            }
            const params = new URLSearchParams(window.location.search);
            let path = window.location.pathname;
            if (regisUni.toRegis) {
              path = `/daftar-kampus/${
                window.location.pathname.split("/")[2]
              }?admissionID=2U1p2QtEMc2SE3drW1CMGPRbmKD`;
              navigate(path);
            }
            if (favUni.afterFavUni) {
              path = `/explore-kampus/detail/${favUni.univId}`;
              const url = "/favorites/university/add";

              api
                .post(url, {
                  uni_id: favUni.univId,
                })
                .then()
                .catch();
              navigate(path);
            }
            if (favMajor.afterFavMajor) {
              path = `/explore-jurusan/detail/${favMajor.majorId}`;
              const url = "/favorites/major/add";

              api
                .post(url, {
                  majors_of_universities_id: favMajor.majorId,
                })
                .then()
                .catch();
              navigate(path);
            } else {
              if (params.get("redirect")) {
                path = params.get("redirect");
              }
              if (path.split("/")[1] === "explore-kelas") {
                Cookies.set("confirmClass", true, {
                  sameSite: "None",
                  secure: true,
                });
                window.location.reload();
              }
              if (
                res.data.data.phone === "" ||
                res.data.data.verified_at.split("-")[0] === "0001"
              ) {
                path = "/profile-edit";
              }
              if (tmb_result_id > 0) {
                navigate(`/tes-minat-result/${tmb_result_id}`);
              }
              if (params.get("admissionID")) {
                navigate(`${path}?admissionID=${params.get("admissionID")}`);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }
              if (res.data.data.username === "") {
                navigate(`/profile-edit`);
              } else {
                navigate(path);
              }
            }
          })
          .catch(notifError);
      })
      .catch((err) => {
        notifError(err);
        if (window.location.pathname.split("/")[1] === "daftar-kampus") {
          let slug = window.location.pathname.split("/")[2];
          navigate(`/explore-kampus/${slug}`);
        }
      });
  };

  const googleSignin = useGoogleLogin({
    // onSuccess: codeResponse => console.log(codeResponse),
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: process.env.REACT_APP_GAUTH_REDIRECT_URI,
    // redirect_uri: "http://localhost:3000/auth/google/callback/",
  });

  return (
    <div className="w-screen">
      <Transition appear show={sign.trySignIn} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          onClose={
            (!auth.isAuth &&
              (window.location.pathname.split("/")[1] === "daftar-kampus" ||
                window.location.pathname.split("/")[1] === "admission")) ||
            !auth.isAuth
              ? () => null
              : () => {
                  setSign({
                    trySignIn: false,
                    trySignUp: false,
                  });
                  setRegisUni({ toRegis: false });
                  setFavUniState({ afterFavUni: false, univId: "" });
                  setFavMajorState({ afterFavMajor: false, majorId: "" });
                }
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-max h-full transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all">
                  <div
                    className={`flex flex-col rounded-20px p-10 bg-white ${
                      screenWidth < 1024 ? "w-full" : "w-628px"
                    }`}
                  >
                    <div className="flex flex-row justify-center items-center gap-3 mt-4"></div>
                    <form onSubmit={onSubmit} className="mt-6">
                      <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full px-3">
                          <label
                            className="text-left font-medium text-base text-black3"
                            htmlFor="email"
                          >
                            Email/Username
                          </label>
                          <input
                            id="email"
                            type="email"
                            name="email"
                            className="form-input w-full text-gray-800 rounded-40px mt-2"
                            placeholder="Masukkan alamat e-mail"
                            required
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full px-3">
                          <div className="flex justify-between">
                            <label
                              className="text-left font-medium text-base text-black3"
                              htmlFor="password"
                            >
                              Password
                            </label>
                          </div>
                          <div className="mt-2">
                            <InputPassword
                              id="password"
                              name="password"
                              className="form-input w-full text-gray-800"
                              placeholder="Masukkan password"
                              rounded={true}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row w-full justify-end items-center mt-4">
                        <Link
                          to={"/forgot-password"}
                          onClick={() => {
                            setSign({
                              trySignIn: false,
                              trySignUp: false,
                            });
                          }}
                        >
                          <p
                            className="text-right font-normal text-black1 opacity-60"
                            style={{ fontSize: "13px" }}
                          >
                            Lupa password
                          </p>
                        </Link>
                      </div>
                      <ButtonV2
                        type="submit"
                        title="Masuk"
                        customStyle={{ marginTop: "24px" }}
                      />
                      <ButtonV2
                        title="Cancel"
                        secondary={true}
                        onClick={() => {
                          setSign({
                            trySignIn: false,
                            trySignUp: false,
                          });
                        }}
                        customStyle={{ marginTop: "14.42px" }}
                      />
                    </form>
                    <p
                      className="text-left font-normal text-black1 mt-4 opacity-60"
                      style={{ fontSize: "13px" }}
                    >
                      Belum punya akun?{" "}
                      <span>
                        <button
                          onClick={() => {
                            setSign({
                              trySignIn: false,
                              trySignUp: true,
                            });
                          }}
                        >
                          <p
                            className="text-left font-normal"
                            style={{ color: "#662E9B", fontWeight: "700" }}
                          >
                            Daftar
                          </p>
                        </button>
                      </span>
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
