import { Link } from "react-router-dom";

export default function CharSectionMajorCard({ screenWidth, data }) {
  let width = screenWidth >= 1024 ? "526px" : "230px";

  return (
    <Link
      to={`/explore-jurusan/detail/${data.id}`}
      className="flex flex-col rounded-2xl bg-white tablet:flex-row mac:gap-8 w-max"
      style={{ minWidth: width }}
    >
      {screenWidth < 1024 && (
        <img
          alt="img url"
          src={data.img_url}
          className={`${
            !data ? "animate-pulse" : ""
          } w-full h-45 object-cover rounded-t-2xl`}
        />
      )}
      <div className="h-45 rounded-b-2xl px-8 pt-8 pb-7 tablet:h-auto mac:w-100 tablet:rounded-l-2xl tablet:rounded-b-none">
        <p
          className={`${
            !data
              ? "animate-pulse bg-gray-200"
              : "text-left text-black1 text-xl mac:text-2xl font-bold line-clamp-1"
          }`}
        >
          {data.name}
        </p>
        <div
          className={`${
            !data
              ? "animate-pulse bg-gray-200"
              : `text-left text-black1 text-xs tablet:text-base font-normal ${
                  screenWidth < 1024 ? "line-clamp-4" : "line-clamp-3"
                } mt-4`
          }`}
          dangerouslySetInnerHTML={{ __html: data.description }}
        >
          {/* {data.description} */}
        </div>
      </div>
      {screenWidth >= 1024 && (
        <img
          alt="img url"
          src={data.img_url}
          className={`${
            !data ? "animate-pulse" : ""
          } w-40 h-45 object-cover rounded-r-2xl rounded-t-none`}
        />
      )}
    </Link>
  );
}
