import { useEffect, useState } from "react";
import { RadioButton } from "../../../components/RadioButton";

export default function LaSallePersonalInformation({
  draftData,
  callbackData,
  setCurrentForm,
  currentForm,
}) {
  const nativeLang = ["English", "Indonesian", "Other"];
  const gender = ["Laki-laki", "Perempuan"];

  const [infoGuardianName, setInfoGuardianName] = useState("");
  const [infoGuardianEmail, setInfoGuardianEmail] = useState("");
  const [infoGuardianPhone, setInfoGuardianPhone] = useState("");
  const [selectedNativeLang, setSelectedNativeLang] = useState(nativeLang[0]);
  const [selectedGender, setSelectedGender] = useState(gender[0]);
  // const [disableSubmit, setDisableSubmit] = useState(true);

  // Event handler for nativeLang radio change
  const nativeLangRadioChangeHandler = (event) => {
    setSelectedNativeLang(event.target.value);
  };

  useEffect(() => {
    if (draftData) {
      setSelectedGender(
        draftData.jenisKelamin
          ? gender[gender.indexOf(draftData.jenisKelamin)]
          : gender[0]
      );
      setSelectedNativeLang(
        draftData.bahasaAsli
          ? nativeLang[nativeLang.indexOf(draftData.bahasaAsli)]
          : nativeLang[0]
      );
      setInfoGuardianName(draftData.namaDepanWali ?? "");
      setInfoGuardianEmail(draftData.emailWali ?? "");
      setInfoGuardianPhone(draftData.teleponWali ?? "");
    }
  }, [draftData]);

  function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    callbackData({
      jenisKelamin: elements[0].value,
      bahasaAsli: selectedNativeLang,
      namaDepanWali: elements[4].value,
      emailWali: elements[5].value,
      teleponWali: elements[6].value,
    });
    setCurrentForm(currentForm + 1);
  }

  function toPrevStep() {
    callbackData({
      jenisKelamin: selectedGender,
      bahasaAsli: selectedNativeLang,
      namaDepanWali: infoGuardianName,
      emailWali: infoGuardianEmail,
      teleponWali: infoGuardianPhone,
    });
    setCurrentForm(currentForm - 1);
  }

  return (
    <form
      onSubmit={submitForm}
      className="flex flex-col w-full justify-start items-start pb-10"
    >
      <div className="flex flex-col w-full justify-start">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
          <div className="mt-2">
            <label className="text-left font-bold text-base text-black4">
              Jenis Kelamin <span className={"text-red-500"}>*</span>
            </label>
            <select
              placeholder={"Pilih Jenis Kelamin"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              defaultValue={selectedGender}
              onChange={(e) => {
                const g = e.target.value;
                setSelectedGender(g);
              }}
              required
            >
              {gender.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-2">
            <label
              htmlFor="bahasa_asli"
              className="text-left font-bold text-base text-black4"
              style={{ lineHeight: "20px" }}
            >
              Bahasa Asli
            </label>
            <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-4">
              {nativeLang.map((item, index) => (
                <div key={index}>
                  <RadioButton
                    onClicked={nativeLangRadioChangeHandler}
                    id={index + 1}
                    isSelected={selectedNativeLang === item}
                    label={item}
                    value={item}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="mt2">
            <label
              htmlFor="guardian-name"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Nama Depan Wali
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="guardian-name"
                id="guardian-name"
                autoComplete="guardian-name"
                className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={infoGuardianName}
                onChange={(e) => {
                  setInfoGuardianName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="mt2">
            <label
              htmlFor="guardian-email"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Email Wali
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="guardian-email"
                id="guardian-email"
                autoComplete="guardian-email"
                className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={infoGuardianEmail}
                onChange={(e) => {
                  setInfoGuardianEmail(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="mt2">
            <label
              htmlFor="guardian-phone"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Telepon Wali
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="guardian-phone"
                id="guardian-phone"
                autoComplete="guardian-phone"
                className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={infoGuardianPhone}
                onChange={(e) => {
                  setInfoGuardianPhone(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
        <button
          onClick={() => {
            toPrevStep();
          }}
          className="py-3 bg-white text-center font-semibold text-base text-primaryColor rounded-lg mt-16 border-2 border-primaryColor"
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Sebelumnya
        </button>

        <button
          type="submit"
          disabled={
            infoGuardianName === "" ||
            infoGuardianEmail === "" ||
            infoGuardianPhone === ""
          }
          className={`py-3 ${
            infoGuardianName === "" ||
            infoGuardianEmail === "" ||
            infoGuardianPhone === ""
              ? "bg-gray-400"
              : "bg-primaryColor"
          } text-center font-semibold text-base text-white rounded-lg mt-16`}
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Selanjutnya
        </button>
      </div>
    </form>
  );
}
