import { useEffect, useState } from "react";
import { api, apiAnom, notifError } from "../utils/api";
import Cookies from "js-cookie";
import { useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { useNavigate } from "react-router-dom";

export default function Callback() {
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let code = "code";
    if (params.get("code")) {
      code = params.get("code");
    }

    apiAnom
      .post(`/auth/student-google?auth_code=${code}`)
      .then((res1) => {
        const tmb_result_id = res1.data.data.tmb_result_id;
        Cookies.set("user.token", res1.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });

        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res1.data.data.request_token,
            },
          })
          .then((res) => {
            setAuth({ ...res.data.data, isAuth: true });
            const params = new URLSearchParams(window.location.search);
            let path = "/profile";
            if (params.get("redirect")) {
              path = params.get("redirect");
            }
            if (res.data.data.phone === "") {
            // if (res.data.data.fname.length < 1) {
              path = "/profile-edit";
            }
            if (tmb_result_id > 0) {
              navigate(`/tes-minat-result/${tmb_result_id}`);
            } else {
              navigate(path);
            }
          })
          .catch(notifError);
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  });

  return <div className="w-screen h-screen bg-white"></div>;
}
