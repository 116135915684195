export default function Button({
  className,
  variant = "primary",
  size = "medium",
  children,
  onClick,
  ...rest
}) {
  let classes = [
    "transition button focus:outline-none duration-150 ease-in-out flex items-center",
  ];

  if (variant === "primary") {
    classes.push("btn text-white bg-primaryColor hover:bg-purple-800");
  }

  if (variant === "secondary") {
    classes.push("btn border border-purple-400");
  }

  if (variant === "tosca") {
    classes.push("btn text-white bg-tosca2");
  }

  if (variant === "alternate") {
    classes.push("btn text-white bg-alternate border border-alternate");
  }

  if (variant === "link") {
    classes.push("font-medium text-gray-600 hover:text-purple-700 px-5");
  }

  if (size === "small") {
    classes.push("btn-sm");
  }

  return (
    <button
      onClick={onClick}
      className={[className, ...classes].join(" ")}
      {...rest}
    >
      {children}
    </button>
  );
}
