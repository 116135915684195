import Button from "../components/base/Button";
import Layout from "../components/Layout";
import LayoutV2 from "../components/Layout_V2";

const QUESTIONS = [
  {
    question: "Apakah kelas ini menarik?",
    name: "apakah_kelas_ini_menarik",
    anwers: ["Ya", "Tidak"],
  },
  {
    question: "Apakah kelas ini membantumu dalam memilih jurusan?",
    name: "apakah_kelas_ini_membantumu_dalam_memilih_jurusan",
    anwers: ["Ya", "Tidak"],
  },
  {
    question: "Bagaimana tanggapanmu mengenai dosen pengajar kelas ini?",
    name: "bagaimana_tanggapanmu_mengenai_dosen_pengajar_kelas_ini",
    anwers: ["Suka", "Tidak Suka"],
  },
  {
    question:
      "Apakah kamu tertarik untuk tahu lebih lanjut tentang universitas ini?",
    name: "apakah_kamu_tertarik_untuk_tahu_lebih_lanjut_tentang_universitas_ini",
    anwers: ["Ya", "Tidak"],
  },
];

export default function Feedback() {
  return (
    <LayoutV2>
      <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20">
        {/* Page header */}
        <div className="max-w-3xl mx-auto pb-12 tablet:pb-20">
          <h1 className="h1 mb-2 text-center">Formulir Feedback</h1>
          <h3 className="mb-2 text-center">
            Silahkan masuk ke akunmu dan mulai telusuri kelas
          </h3>
          <div className="max-w-xl mx-auto">
            <form>
              {QUESTIONS.map((d, i) => {
                return (
                  <div className="w-full p-3" key={i}>
                    <label
                      className="block text-gray-900 font-medium py-2 mb-1"
                      htmlFor={d.name}
                    >
                      {i + 1}. {d.question}
                    </label>
                    <div className="grid pl-4 grid-cols-2 tablet:w-1/2">
                      {d.anwers.map((dd, ii) => {
                        return (
                          <label
                            key={`${ii}{i}`}
                            className="cursor-pointer flex items-center"
                          >
                            <input
                              type="radio"
                              className="cursor-pointer mr-2"
                              name={d.name}
                              placeholder="Enter your email address"
                              required
                            />
                            {dd}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div className="flex justify-center flex-wrap -mx-3 mt-6">
                <div className="w-1/2 px-3">
                  <Button variant="primary" className="w-full">
                    Kirim
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
