export default function CardInfoDetailKampus({
  icon,
  content,
  additionalContent,
  link,
}) {
  const correctUrl = (url) => {
    if (!url.startsWith("https://")) {
      return "https://" + url;
    }
    return url;
  };

  return (
    <div
      onClick={() => {
        link && window.open(correctUrl(link), "_blank", "noopener,noreferrer");
      }}
      className="w-full flex flex-row justify-start items-start gap-3"
      style={{
        cursor: link ? "pointer" : "default",
      }}
    >
      <div
        className="bg-primaryColor flex items-center justify-center"
        style={{
          borderRadius: "5.33px",
          minWidth: "20px",
          maxWidth: "20px",
          minHeight: "20px",
          maxHeight: "20px",
        }}
      >
        <img
          src={icon}
          className="object-contain"
          style={{ maxWidth: "13px" }}
        />
      </div>
      <p
        className={`text-left font-normal text-black188 ${
          !link?.startsWith("https://" || "www") &&
          "line-clamp-2 overflow-ellipsis"
        }`}
        style={{
          fontSize: "14.22px",
          lineHeight: "21.33px",
          letterSpacing: "-0.71px",
        }}
      >
        {content} {additionalContent}
      </p>
    </div>
  );
}
