import { HeartIcon } from "@heroicons/react/outline";
import { HeartIcon as HeartIconFilled } from "@heroicons/react/solid";

export default function FavoriteButton({
  className,
  isFavorite,
  onChangeFavorite,
}) {
  return (
    <button
      className={`${className} h-8 w-8 lg:h-10 lg:w-10 absolute z-10 focus:outline-none text-purple-700 p-1 shadow-sm flex items-center justify-center rounded-md bg-white`}
      aria-hidden="true"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onChangeFavorite();
      }}
    >
      {isFavorite ? (
        <HeartIconFilled className="h-full w-full p-1 2mac:p-2 text-red-700" />
      ) : (
        <HeartIcon className="h-full w-full p-1 2mac:p-2" />
      )}
    </button>
  );
}
