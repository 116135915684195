import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { signInUpState } from "../atoms/signInUpState";
import { InputPassword } from "./InputPassword";
import { Link, useNavigate } from "react-router-dom";
import Button from "./base/Button";
import { api, apiAnom, notifError } from "../utils/api";
import Cookies from "js-cookie";
import { authState } from "../atoms/authState";
import { useGoogleLogin } from "@react-oauth/google";
import { regisUniState } from "../atoms/regisUni";
import { favUniState } from "../atoms/favUni";
import { favMajorState } from "../atoms/favMajor";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import ButtonV2 from "./ButtonV2";

export default function SignUpModal() {
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const regisUni = useRecoilValue(regisUniState);
  const setRegisUni = useSetRecoilState(regisUniState);
  const favUni = useRecoilValue(favUniState);
  const setFavUniState = useSetRecoilState(favUniState);
  const favMajor = useRecoilValue(favMajorState);
  const setFavMajorState = useSetRecoilState(favMajorState);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();
  const [isAgreePrivacy, setIsAgreePrivacy] = useState();
  const [isAgreeLegalTerms, setIsAgreeLegalTerms] = useState();
  const [showPassword, setShowPassword] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  const refreshPage = (isRefresh) => {
    window.location.reload(isRefresh);
  };

  const onSubmit = async (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();

    let classSlug = "";

    if (window.location.pathname.split("/")[1] === "explore-kelas") {
      classSlug = window.location.pathname.split("/")[2];
    }

    const payload = {
      first_name: elements[0].value,
      last_name: elements[1].value,
      email: elements[2].value,
      password: elements[3].value,
      class_slug: classSlug,
    };

    apiAnom
      .post("/students/registration", payload)
      .then((res1) => {
        const tmb_result_id = res1.data.data.tmb_result_id;
        Cookies.set("user.token", res1.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });

        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res1.data.data.request_token,
            },
          })
          .then((res) => {
            setAuth({ ...res.data.data, isAuth: true });
            const storage = localStorage.getItem("TMB_ANSWERS");
            if (storage != null) {
              const ptmqData = JSON.parse(Cookies.get("userPTMQData"));
              const responseBody = {
                ...ptmqData,
                student_id: parseInt(res.data.data.id),
                pre_test_result: {
                  ...ptmqData.pre_test_result,
                  email: res.data.data.email,
                },
                mini_quiz_result: {
                  ...ptmqData.mini_quiz_result,
                  email: res.data.data.email,
                },
              };
              api
                .post("/ptmq/submit", responseBody)
                .then((res) => {
                  Cookies.remove("userPretestData");
                  Cookies.remove("pretestStepIndex");
                  Cookies.remove("userMQData");
                  Cookies.remove("userPTMQData");
                  const answers = JSON.parse(storage);
                  api
                    .post(
                      `/tmb/submit-test-result?email=${elements[2].value}&ptmq_id=${res.data.data.ptmq_id}`,
                      answers
                    )
                    .then((res) => {
                      if (res.data.code === 200) {
                        localStorage.removeItem("TMB_ANSWERS");
                        Cookies.set("justFinishTMB", true, {
                          sameSite: "None",
                          secure: true,
                          expires: 1,
                        });
                        navigate("/profile-edit");
                      }
                    })
                    .catch(notifError);
                })
                .catch(notifError);
            }
            const params = new URLSearchParams(window.location.search);
            let path = "/profile";
            if (regisUni.toRegis) {
              path = `/daftar-kampus/${
                window.location.pathname.split("/")[2]
              }?admissionID=2U1p2QtEMc2SE3drW1CMGPRbmKD`;
              navigate(path);
            }
            if (favUni.afterFavUni) {
              path = `/explore-kampus/detail/${favUni.univId}`;
              const url = "/favorites/university/add";

              api
                .post(url, {
                  uni_id: favUni.univId,
                })
                .then()
                .catch();
              navigate(path);
            }
            if (favMajor.afterFavMajor) {
              path = `/explore-jurusan/detail/${favMajor.majorId}`;
              const url = "/favorites/major/add";

              api
                .post(url, {
                  majors_of_universities_id: favMajor.majorId,
                })
                .then()
                .catch();
              navigate(path);
            } else {
              if (params.get("redirect")) {
                path = params.get("redirect");
              }
              if (path.split("/")[1] === "explore-kelas") {
                Cookies.set("confirmClass", true, {
                  sameSite: "None",
                  secure: true,
                });
                window.location.reload();
              }
              if (
                res.data.data.phone === "" ||
                res.data.data.verified_at.split("-")[0] === "0001"
              ) {
                path = "/profile-edit";
              }
              if (tmb_result_id > 0) {
                navigate(`/tes-minat-result/${tmb_result_id}`);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }
              if (params.get("admissionID")) {
                navigate(`${path}?admissionID=${params.get("admissionID")}`);
              } else {
                navigate(path);
              }
            }
          })
          .catch(notifError);
      })
      .catch(notifError);
  };

  const googleSignUp = useGoogleLogin({
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: process.env.REACT_APP_GAUTH_REDIRECT_URI,
    // redirect_uri: "http://localhost:3000/auth/google/callback/",
  });

  return (
    <div className="w-screen">
      <Transition appear show={sign.trySignUp} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          onClose={
            (!auth.isAuth &&
              (window.location.pathname.split("/")[1] === "daftar-kampus" ||
                window.location.pathname.split("/")[1] === "admission")) ||
            !auth.isAuth
              ? () => null
              : () => {
                  setSign({
                    trySignIn: false,
                    trySignUp: false,
                  });
                  setRegisUni({ toRegis: false });
                  setFavUniState({ afterFavUni: true, univId: "" });
                  setFavMajorState({ afterFavMajor: false, majorId: "" });
                }
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="h-full transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all">
                  <div
                    className={`relative z-30 flex flex-col rounded-20px p-6 bg-white ${
                      screenWidth < 1024 ? "w-full" : "w-535px"
                    }`}
                  >
                    {/* <p className="text-left font-bold text-2xl text-black1">
                      Kamu perlu masuk untuk daftar kampus impianmu, yuk daftar
                      dulu disini!
                    </p>
                    <button
                      className="flex flex-row justify-center items-center py-3 mt-3 gap-3"
                      style={{
                        border: "1px solid #E0E0E1",
                        borderRadius: "8px",
                      }}
                      onClick={googleSignUp}
                    >
                      <img src="/logo_google_g.svg" className="h-4 w-4" />
                      <p className="text-left font-normal text-base text-black1">
                        Daftar dengan Google
                      </p>
                    </button>
                    <div className="flex flex-row justify-center items-center gap-3 mt-4">
                      <div
                        style={{
                          border: "1px solid rgba(36, 16, 54, 0.3",
                          flexGrow: "1",
                        }}
                      ></div>
                      <p
                        className="text-center font-normal text-black1 opacity-60"
                        style={{ fontSize: "13px" }}
                      >
                        or
                      </p>
                      <div
                        style={{
                          border: "1px solid rgba(36, 16, 54, 0.3",
                          flexGrow: "1",
                        }}
                      ></div>
                    </div> */}
                    <form onSubmit={onSubmit}>
                      <div className="flex flex-row justify-between items-center gap-4 hd:gap-9">
                        <div className="flex flex-wrap -mx-3 mb-4">
                          <div className="w-full px-3">
                            <label
                              className="text-left font-medium text-xs sm:text-base text-black3"
                              htmlFor="nama depan"
                            >
                              Nama Depan
                            </label>
                            <input
                              id="nama depan"
                              type="nama depan"
                              name="nama depan"
                              className="form-input w-full text-gray-800 rounded-40px mt-2 overflow-ellipsis"
                              placeholder="Masukkan nama depan"
                              required
                              style={{
                                fontSize: screenWidth >= 1024 ? "14px" : "12px",
                                height:
                                  screenWidth >= 1024 ? "48px" : "29.59px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                          <div className="w-full px-3">
                            <label
                              className="text-left font-medium text-xs sm:text-base text-black3"
                              htmlFor="nama belakang"
                            >
                              Nama Belakang
                            </label>
                            <input
                              id="nama belakang"
                              type="nama belakang"
                              name="nama belakang"
                              className="form-input w-full text-gray-800 rounded-40px mt-2 overflow-ellipsis"
                              placeholder="Masukkan nama belakang"
                              required
                              style={{
                                fontSize: screenWidth >= 1024 ? "14px" : "12px",
                                height:
                                  screenWidth >= 1024 ? "48px" : "29.59px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full px-3">
                          <label
                            className="text-left font-medium text-xs sm:text-base text-black3"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <input
                            id="email"
                            type="email"
                            name="email"
                            className="form-input w-full text-gray-800 rounded-40px mt-2"
                            placeholder="Masukkan alamat e-mail"
                            required
                            style={{
                              fontSize: screenWidth >= 1024 ? "14px" : "12px",
                              height: screenWidth >= 1024 ? "48px" : "29.59px",
                            }}
                            pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full px-3">
                          <div className="flex justify-between">
                            <label
                              className="text-left font-medium text-xs sm:text-base text-black3"
                              htmlFor="password"
                            >
                              Password
                            </label>
                          </div>
                          <div className="relative w-full mt-2">
                            <input
                              id="password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              className="form-input w-full text-gray-800 rounded-40px"
                              placeholder="Masukkan password"
                              required
                              style={{
                                fontSize: screenWidth >= 1024 ? "14px" : "12px",
                                height:
                                  screenWidth >= 1024 ? "48px" : "29.59px",
                              }}
                            />
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowPassword(!showPassword);
                              }}
                              className="absolute right-3 z-30 w-3 sm:w-6 h-auto"
                              style={{
                                color: "rgba(151, 151, 151, 1)",
                                top: "50%",
                                transform: "translate(0%, -50%)",
                              }}
                            >
                              {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="flex flex-row w-full justify-start items-center mt-3"
                        style={{ gap: "6.47px" }}
                      >
                        <div
                          className="flex justify-center items-center"
                          onClick={() => {
                            setIsAgreePrivacy(!isAgreePrivacy);
                          }}
                          style={{
                            height: "18.5px",
                            width: "18.5px",
                            border: "1.5px solid rgba(102, 46, 155, 0.6)",
                            borderRadius: "4px",
                            padding: "3.25px",
                          }}
                        >
                          {isAgreePrivacy && (
                            <div className="flex w-full h-full bg-primaryColor rounded-sm" />
                          )}
                        </div>
                        <p
                          className="text-left font-normal text-black1"
                          style={{
                            fontSize: screenWidth < 1024 ? "10px" : "13px",
                          }}
                        >
                          <span className="opacity-60">
                            Saya setuju dengan{" "}
                          </span>
                          <span>
                            <a
                              href="privacy"
                              rel="noreferrer"
                              target="_blank"
                              style={{ color: "#662E9B", fontWeight: "500" }}
                            >
                              kebijakan privasi dan penggunaan Exploration.
                            </a>
                          </span>
                        </p>
                      </div>
                      <div
                        className="flex flex-row w-full justify-start items-center mt-3"
                        style={{ gap: "6.47px" }}
                      >
                        <div
                          className="flex justify-center items-center"
                          onClick={() => {
                            setIsAgreeLegalTerms(!isAgreeLegalTerms);
                          }}
                          style={{
                            height: "18.5px",
                            width: "18.5px",
                            border: "1.5px solid rgba(102, 46, 155, 0.6)",
                            borderRadius: "4px",
                            padding: "3.25px",
                          }}
                        >
                          {isAgreeLegalTerms && (
                            <div className="flex w-full h-full bg-primaryColor rounded-sm" />
                          )}
                        </div>
                        <p
                          className="text-left font-normal text-black1"
                          style={{
                            fontSize: screenWidth < 1024 ? "10px" : "13px",
                          }}
                        >
                          <span className="opacity-60">
                            Saya setuju dengan{" "}
                          </span>
                          <span>
                            <a
                              href="/terms-condition"
                              rel="noreferrer"
                              target="_blank"
                              style={{ color: "#662E9B", fontWeight: "500" }}
                            >
                              legal terms & condition Exploration.
                            </a>
                          </span>
                        </p>
                      </div>
                      <button
                        type="submit"
                        disabled={!isAgreePrivacy && !isAgreeLegalTerms}
                        className={`w-full flex items-center justify-center ${
                          isAgreePrivacy && isAgreeLegalTerms
                            ? "bg-primaryColor"
                            : "bg-gray-400"
                        } py-2`}
                        style={{ marginTop: "14.8px", borderRadius: "4.93px" }}
                      >
                        <p
                          className={`text-center font-semibold text-base ${
                            isAgreePrivacy && isAgreeLegalTerms
                              ? "text-white"
                              : "text-gray-800"
                          }`}
                          style={{ lineHeight: "24px" }}
                        >
                          Daftar
                        </p>
                      </button>
                      <ButtonV2
                        title="Cancel"
                        secondary={true}
                        onClick={() => {
                          setSign({
                            trySignIn: false,
                            trySignUp: false,
                          });
                        }}
                        customStyle={{ marginTop: "14.42px" }}
                      />
                    </form>
                    <p
                      className="text-left font-normal text-black1 mt-4 opacity-60"
                      style={{ fontSize: screenWidth < 1024 ? "10px" : "13px" }}
                    >
                      Sudah punya akun?{" "}
                      <span style={{ color: "#662E9B", fontWeight: "700" }}>
                        <button
                          onClick={() => {
                            setSign({
                              trySignIn: true,
                              trySignUp: false,
                            });
                          }}
                        >
                          <p
                            className="text-left font-normal"
                            style={{ color: "#662E9B", fontWeight: "700" }}
                          >
                            Masuk
                          </p>
                        </button>
                      </span>
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
