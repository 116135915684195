import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function CardPenerimaan({
  screenWidth,
  logoUrl,
  univName,
  admissionName,
  admisFee,
  admisOpenDate,
  admisCloseDate,
  redirect,
  isDisabled,
}) {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        navigate(redirect);
      }}
      disabled={isDisabled}
      className={`flex flex-col justify-start items-start w-full h-max ${
        isDisabled ? "bg-gray-400" : "bg-white"
      } rounded-xl p-4 relative`}
      style={{
        minWidth: `${screenWidth < 1024 ? "300px" : "410px"}`,
        maxWidth: `${screenWidth < 1024 ? "300px" : "410px"}`,
        minHeight: `${screenWidth < 1024 ? "272px" : "306px"}`,
        maxHeight: `${screenWidth < 1024 ? "272px" : "306px"}`,
        boxShadow: "24px 24px 200px rgba(23, 23, 23, 0.08)",
      }}
    >
      <div className="flex flex-row gap-2 tablet:gap-6 justify-start items-center">
        <img
          src={logoUrl}
          alt="logo url"
          className="object-contain"
          style={{
            minWidth: "30px",
            maxWidth: "30px",
            minHeight: "30px",
            maxHeight: "30px",
          }}
        />
        <p
          className="text-left font-normal text-sm text-black188 line-clamp-2 overflow-ellipsis"
          style={{ lineHeight: "22px" }}
        >
          {univName}
        </p>
      </div>
      <p
        className="text-left font-bold text-xl tablet:text-2xl text-black1 line-clamp-2 overflow-ellipsis mt-3"
        style={{ lineHeight: `${screenWidth < 1024 ? "24px" : "29px"}` }}
      >
        {admissionName}
      </p>
      {/* <p
        className="text-left font-bold text-base text-black1 line-clamp-2 overflow-ellipsis mt-2"
        style={{ lineHeight: "19px" }}
      >
        Jenjang S1
      </p> */}
      <p
        className="text-left font-normal text-xl tablet:text-2xl text-black1 line-clamp-2 overflow-ellipsis mt-4"
        style={{ lineHeight: `${screenWidth < 1024 ? "24px" : "29px"}` }}
      >
        {`Rp ${admisFee.toLocaleString()}`}
      </p>
      <div className="flex flex-col justify-end items-start gap-2 absolute bottom-4 left-4">
        <p
          className="text-left font-semibold text-sm tablet:text-base text-black188"
          style={{
            lineHeight: `${screenWidth < 1024 ? "22px" : "25px"}`,
          }}
        >
          Masa Pendaftaran
        </p>
        <div className="flex flex-row items-center justify-start gap-3">
          <img
            src="/icon_calendar.svg"
            alt="ikon kalender"
            style={{
              width: `${screenWidth < 1024 ? "15px" : "18px"}`,
              height: `${screenWidth < 1024 ? "15px" : "18px"}`,
            }}
          />
          <p
            className="text-left font-normal text-sm tablet:text-base text-black188"
            style={{
              lineHeight: `${screenWidth < 1024 ? "22px" : "25px"}`,
            }}
          >
            {moment(admisOpenDate).format("DD MMMM YYYY")} -{" "}
            {moment(admisCloseDate).format("DD MMMM YYYY")}
            {/* Dibuka sepanjang tahun */}
          </p>
        </div>
      </div>
    </button>
  );
}
