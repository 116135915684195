import { useState } from "react";
import Footer from "../../components/Footer";
import Headerv2 from "../../components/Header_v2";
import RMSubClassProgram from "./rm_sub_steps/RMSubClassProgram";
import RMSubBudgetType from "./rm_sub_steps/RMSubBudgetType";
import RMSubEmailNasabah from "./rm_sub_steps/RMSubEmailNasabah";
import RMSubNamaNasabah from "./rm_sub_steps/RMSubNamaNasabah";
import RMSubTeleponNasabah from "./rm_sub_steps/RMSubTeleponNasabah";
import RMSubNamaRM from "./rm_sub_steps/RMSubNamaRM";
import RMSubEmailRM from "./rm_sub_steps/RMSubEmailRM";
import RMSubNamaPeserta from "./rm_sub_steps/RMSubNamaPeserta";
import RMSubEmailPeserta from "./rm_sub_steps/RMSubEmailPeserta";
import RMSubPhonePeserta from "./rm_sub_steps/RMSubPhonePeserta";
import RMSubFinishPage from "./rm_sub_steps/RMSubFinishPage";
import { apiRM, notifError } from "../../utils/api";
import Cookies from "js-cookie";
import RMSubUmurPeserta from "./rm_sub_steps/RMSubUmurPeserta";
import RMSubEmailDirectLeader from "./rm_sub_steps/RMSubEmailDirectLeader";
import RMSubBranchOriginRM from "./rm_sub_steps/RMSubBranchOriginRM";
import RMSubRegionOriginRM from "./rm_sub_steps/RMSubRegionOriginRM";
import LayoutV2 from "../../components/Layout_V2";

export default function RMSubmission() {
  const [currentStep, setCurrentStep] = useState(0);
  const [rmJsonData, setRMJsonData] = useState({});

  function NextStep() {
    setCurrentStep(currentStep + 1);
  }

  function PrevStep() {
    setCurrentStep(currentStep - 1);
  }

  function ClassProgramSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    let jsonForm = {
      ...rmJsonData,
      classProgram: JSON.parse(elements[2].value).id,
    };
    setRMJsonData(jsonForm);
    NextStep();
  }

  function BudgetTypeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    let jsonForm = {
      ...rmJsonData,
      budgetPlacementType: JSON.parse(elements[3].value).budget,
    };
    setRMJsonData(jsonForm);
    NextStep();
  }

  function EmailNasabahSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      customerEmail: elements,
    };
    Cookies.set("emailNasabah", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function NamaNasabahSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      customerFullName: elements,
    };
    Cookies.set("namaNasabah", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function TeleponNasabahSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      customerPhone: elements,
    };
    Cookies.set("teleponNasabah", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function RegionOriginRMSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      regionOriginRM: elements,
    };
    Cookies.set("regionOriginRM", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function BranchRMSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      ocbcBranch: elements,
    };
    Cookies.set("branchRM", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function NamaRMSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      rmFullName: elements,
    };
    Cookies.set("namaRM", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function EmailRMSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      rmEmail: elements,
    };
    Cookies.set("emailRM", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function EmailDirectLeaderSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      emailLeaderRM: elements,
    };
    Cookies.set("emailLeaderRM", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function NamaPesertaSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      studentFullName: elements,
    };
    Cookies.set("namaPeserta", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function EmailPesertaSubmit(e) {
    const elements = e;
    Cookies.set("emailPeserta", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    apiRM
      .get(
        `/rm/leads/check/availability?student_email=${elements}&classes_id=${rmJsonData.classProgram}`
      )
      .then((_) => {
        let jsonForm = {
          ...rmJsonData,
          studentEmail: elements,
        };
        setRMJsonData(jsonForm);
        NextStep();
      })
      .catch((err) => {
        window.Swal.fire({
          // title: "Perhatian!",
          text: err.response.data.error.message,
          icon: "error",
          confirmButtonText: "Tutup",
        });
      });
  }

  function PhonePesertaSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      studentPhone: elements,
      budgetPlacementType: 99,
    };
    Cookies.set("phonePeserta", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    NextStep();
  }

  function TanggalLahirPesertaSubmit(e) {
    const elements = e;
    let jsonForm = {
      ...rmJsonData,
      studentBirthday: elements,
      budgetPlacementType: 99,
    };
    Cookies.set("tanggalLahirPeserta", elements, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    setRMJsonData(jsonForm);
    apiRM
      .post("/rm/leads/create", jsonForm)
      .then((_) => {
        NextStep();
        Cookies.remove("classProgramIndex");
        Cookies.remove("emailNasabah");
        Cookies.remove("namaNasabah");
        Cookies.remove("teleponNasabah");
        Cookies.remove("regionOriginRM");
        Cookies.remove("emailLeaderRM");
        Cookies.remove("branchRM");
        Cookies.remove("namaRM");
        Cookies.remove("emailRM");
        Cookies.remove("namaPeserta");
        Cookies.remove("emailPeserta");
        Cookies.remove("phonePeserta");
        Cookies.remove("tanggalLahirPeserta");
      })
      .catch((err) => {
        setCurrentStep(currentStep - 2);
        if (err.response.data.code === 400) {
          window.Swal.fire({
            title: "Error!",
            text: err.response.data.error.message,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          });
        } else {
          window.Swal.fire({
            title: "Error!",
            text: err.response.data.error.message,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          });
        }
      });
  }

  const submissionSteps = [
    <RMSubClassProgram submit={ClassProgramSubmit} />,
    // <RMSubBudgetType submit={BudgetTypeSubmit} back={PrevStep} />,
    <RMSubEmailNasabah submit={EmailNasabahSubmit} back={PrevStep} />,
    <RMSubNamaNasabah submit={NamaNasabahSubmit} back={PrevStep} />,
    <RMSubTeleponNasabah submit={TeleponNasabahSubmit} back={PrevStep} />,
    <RMSubRegionOriginRM submit={RegionOriginRMSubmit} back={PrevStep} />,
    <RMSubBranchOriginRM submit={BranchRMSubmit} back={PrevStep} />,
    <RMSubNamaRM submit={NamaRMSubmit} back={PrevStep} />,
    <RMSubEmailRM submit={EmailRMSubmit} back={PrevStep} />,
    <RMSubEmailDirectLeader submit={EmailDirectLeaderSubmit} back={PrevStep} />,
    <RMSubNamaPeserta submit={NamaPesertaSubmit} back={PrevStep} />,
    <RMSubEmailPeserta submit={EmailPesertaSubmit} back={PrevStep} />,
    <RMSubPhonePeserta submit={PhonePesertaSubmit} back={PrevStep} />,
    <RMSubUmurPeserta submit={TanggalLahirPesertaSubmit} back={PrevStep} />,
    <RMSubFinishPage back={PrevStep} />,
  ];

  return (
    <LayoutV2 isRM={true}>
      <div className="flex flex-col flex-grow w-full h-auto justify-start items-center">
        <div
          className="pt-11 pb-10 px-10 bg-primaryColor flex flex-col items-end justify-start w-full h-full overflow-y-auto relative"
          style={{ maxWidth: "360px" }}
        >
          <img src="/illu_curve_rmsubmission.svg" className="absolute left-0" />
          <div className="relative z-50 flex w-full h-full">
            {submissionSteps[currentStep]}
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
