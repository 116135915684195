import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import React, { useEffect, useState } from "react";
import { authState } from "../../atoms/authState";
import { api, apiAnom, notifError } from "../../utils/api";
import PageLoading from "../PageLoading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../../utils/utils";
import Button from "../../components/base/Button";
import { signInUpState } from "../../atoms/signInUpState";
import { RadioButton } from "../../components/RadioButton";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { add } from "lodash";
import LayoutV2 from "../../components/Layout_V2";

export default function LaSalleAdmissionFormV2() {
  //   let { slug } = useParams();
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const [errors, setErrors] = useState({});
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const [univData, setUnivData] = useState();
  const [isLoading, setLoading] = useState(true);

  // personal information
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [startDate, setStartDate] = useState();
  const [birthPlace, setBirthPlace] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState("01/01/0001");
  const gender = ["Laki-laki", "Perempuan"];
  const [selectedGender, setSelectedGender] = useState(gender[0]);
  const [address, setAddress] = useState('');

  const [guardianName, setGuardianName] = useState('');
  const [guardianEmail, setGuardianEmail] = useState('');
  const [guardianRelationship, setGuardianRelationship] = useState('');
  const [guardianPhone, setGuardianPhone] = useState('');



  // const [birthCountry, setBirthCountry] = useState("");
  // const [province, setProvince] = useState("");
  // const [city, setCity] = useState("");
  // const [citizenship, setCitizenship] = useState("");
  // const [occupationCountry, setOccupationCountry] = useState("");
  // const [discipline, setDiscipline] = useState("");
  // const [highSchool, setHighSchool] = useState("");
  // const [graduationYear, setGraduationYear] = useState("");
  // const [lastGrade, setLastGrade] = useState("");
  // const [otherLang, setOtherLang] = useState("");
  // const [addressCountry, setAddressCountry] = useState("");
  // const [addressProvince, setAddressProvince] = useState("");
  // const [addressStreetNumber, setAddressStreetNumber] = useState("");
  // const [address, setAddress] = useState("");
  // const [addressCity, setAddressCity] = useState("");
  // const [addressZipCode, setAddressZipCode] = useState("");
  // const [addressHomePhone, setAddressHomePhone] = useState("");
  // const [addressSecondaryPhone, setAddressSecondaryPhone] = useState("");
  // const [phone, setPhone] = useState("");
  // const [isCodeDropdown, setIsCodeDropdown] = useState("");
  // const [isCodeDropdown2, setIsCodeDropdown2] = useState("");
  // const [isCodeDropdown3, setIsCodeDropdown3] = useState("");
  // const [isCodeDropdown4, setIsCodeDropdown4] = useState("");
  
  const [disableChangePhone, setDisableChangePhone] = useState(false);
  // const [countryCode, setCountryCode] = useState(getCountryCallingCode("ID"));
  // const [countryCode2, setCountryCode2] = useState(getCountryCallingCode("ID"));
  // const [countryCode3, setCountryCode3] = useState(getCountryCallingCode("ID"));
  // const [countryCode4, setCountryCode4] = useState(getCountryCallingCode("ID"));
  // const [countryList, setCountryList] = useState([]);
  // const [provinceNCity, setProvinceNCity] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState("Indonesia");
  // const [selectedCitizenship, setSelectedCitizenship] = useState("Indonesia");
  // const [selectedWorkCountry, setSelectedWorkCountry] = useState("Indonesia");
  // const [selectedProvince, setSelectedProvince] = useState("DKI Jakarta");
  // const [selectedCity, setSelectedCity] = useState("Pilih Kota");
  // const [selectedAddressCountry, setSelectedAddressCountry] =
  //   useState("Indonesia");
  // const [selectedAddressProvince, setSelectedAddressProvince] =
  //   useState("DKI Jakarta");
  // const [selectedAddressCity, setSelectedAddressCity] = useState("Pilih Kota");

  const [selectedTabs, setSelectedTabs] = useState(1);

  useEffect(() => {
    // setCountryList(countryJSON);
    // setProvinceNCity(indoProvJSON);
    const getUnivDetails = async () => {
      await _api
        .get(`/explore-kampus-v2/${window.location.pathname.split("/")[2]}`)
        .then((res) => {
          setUnivData(res.data.data);
        })
        .catch((err) => {
          setUnivData({});
        });
    };

    if (!auth.isAuth) {
      setSign({ trySignIn: true, trySignUp: false });
    } else if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    } else {
      setLoading(true);
      setFullName(
        auth.fname && auth.lname ? auth.fname + " " + auth.lname : ""
      );
      setFirstName(auth.fname ? auth.fname : "");
      setLastName(auth.lname ? auth.lname : "");
      setEmail(auth.email);
      let newCountryCode;
      let newPhone;
      if (auth.phone.includes("-")) {
        newCountryCode = auth.phone.split("-")[0];
        newPhone = auth.phone.split("-")[1];
      } else {
        newCountryCode = getCountryCallingCode("ID");
        newPhone = auth.phone.slice(auth.phone.indexOf("8"));
      }
      setPhone(newPhone);
      // setCountryCode(newCountryCode);
    }

    getUnivDetails().then(() => {
      setLoading(false);
    });
  }, []);

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          case "countryCode":
            isErr = !/()\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    if (!validate(elements)) return;
    setLoading(true);
    // building payload
    // const newPhone = countryCode + "-" + phone;
    // const newGuardianPhone = countryCode2 + "-" + guardianPhone;
    // const newHomePhone = countryCode3 + "-" + addressHomePhone;
    const jsonForm = {
      informasi_personal: {
        jenis_kelamin: selectedGender,
        bahasa_asli: "ID",
        nama_depan_wali: guardianName,
        email_wali: guardianEmail,
        telepon_wali: guardianPhone
      },
    };
    //   uni_location: selectedUniLocation,
    //   regis_session: selectedRegisSession,
    //   program_type: selectedProgram,
    //   program_choice: selectedProgramStudi,
    //   first_name: firstName,
    //   last_name: lastName,
    //   date_of_birth: dateOfBirth,
    //   email: email,
    //   phone: newPhone,
    //   gender: selectedGender,
    //   native_language: selectedNativeLang,
    //   guardian_first_name: guardianFirstName,
    //   guardian_email: guardianEmail,
    //   guardian_phone: newGuardianPhone,
    //   birth_country: selectedCountry,
    //   province: selectedProvince,
    //   city: selectedCity,
    //   citizenship: selectedCitizenship,
    //   legal_status: selectedLegalStatus,
    //   address: {
    //     country: selectedAddressCountry,
    //     province: selectedAddressProvince,
    //     street_number: addressStreetNumber,
    //     street: address,
    //     city: selectedAddressCity,
    //     zip_code: addressZipCode,
    //     home_phone: newHomePhone,
    //     secondary_phone: addressSecondaryPhone,
    //   },
    //   principal_occupation: selectedOccupation,
    //   country_of_occupation: selectedWorkCountry,
    //   discipline: discipline,
    //   high_school_name: highSchool,
    //   graduation_year: graduationYear,
    //   last_grade_completed: lastGrade,
    //   language: selectedLang,
    //   other_language: otherLang,
    // };


    const payload = {
      name: "",
      email: "",
      phone: "",
      uni_id: univData.id,
      univ_form_data: JSON.stringify(jsonForm),
    };
    _api
      .post("/students/univ-admission", payload)
      .then(() => {
        window.Swal.fire({
          title: "Success!",
          text: `Kamu telah mendaftar di ${univData.name}`,
          icon: "success",
          confirmButtonText: "Tutup",
          allowOutsideClick: false,
        }).then(() => {
          navigate(`/explore-kampus/${univData.slug}`);
        });
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  };



  const uniLocation = ["Jakarta", "Surabaya"];
  const regisSession = [
    "January 2023",
    "May 2023",
    "September 2023",
    "January 2024",
  ];
  const programType = ["Diploma", "Certificate", "D4", "S1"];
  const programChoices = [
    {
      type: "Diploma",
      choices: [
        "Fashion Design",
        "Fashion Business",
        "Graphic Design Diploma",
        "Game Art Design",
        "Photography",
        "Interior Design",
        "Management Creative Industries Diploma",
      ],
    },
    {
      type: "Certificate",
      choices: [
        "Pattern Maker",
        "Stylist/Illustrator",
        "Buyer",
        "Retail Manager",
        "Graphic Design Certificate",
        "Photography",
        "Interior Design",
        "Artistic Make Up Module 1 & 2",
        "Management Creative Industries Certificate",
      ],
    },
    {
      type: "D4",
      choices: [
        "Fashion Design D4 Standard",
        "Fashion Design D4 Bridging",
        "Fashion Merchandising D4 Standard",
        "Fashion Merchandising D4 Bridging",
      ],
    },
    {
      type: "S1",
      choices: [
        "Interior Design Bachelor Standard",
        "Interior Design Bachelor Bridging",
      ],
    },
  ];
  // const nativeLang = ["English", "Indonesian", "Other"];

  // const principalOccupation = ["Employee", "Student", "Other"];
  // const language = ["English", "Indonesian", "Other"];

  // const [selectedUniLocation, setSelectedUniLocation] = useState(
  //   uniLocation[0]
  // );
  // const [selectedRegisSession, setSelectedRegisSession] = useState(
  //   regisSession[0]
  // );
  // const [selectedProgram, setSelectedProgram] = useState(programType[0]);
  // const [selectedProgramChoice, setSelectedProgramChoice] = useState(
  //   programChoices[0].choices
  // );
  // const [selectedProgramStudi, setSelectedProgramStudi] = useState(
  //   programChoices[0].choices[0]
  // );
  
  // const [selectedNativeLang, setSelectedNativeLang] = useState(nativeLang[0]);
  // const [selectedLang, setSelectedLang] = useState(nativeLang[0]);

  // const [selectedOccupation, setSelectedOccupation] = useState(
  //   principalOccupation[0]
  // );

  // const nativeLangRadioChangeHandler = (e) => {
  //   setSelectedNativeLang(e.target.value);
  // };


  // const occupationRadioChangeHandler = (e) => {
  //   setSelectedOccupation(e.target.value);
  // };

  // const langRadioChangeHandler = (e) => {
  //   setSelectedLang(e.target.value);
  // };

  function FormStep({ number, title }) {
    return (
      <div className="flex flex-row gap-4 justify-start items-center">
        <div
          className="flex items-center justify-center text-center font-bold text-xl text-white rounded-full bg-primaryColor"
          style={{
            minHeight: "50px",
            maxHeight: "50px",
            minWidth: "50px",
            maxWidth: "50px",
            lineHeight: "25px",
          }}
        >
          {number}
        </div>
        <p
          className="text-center font-medium text-xl rounded-full whitespace-nowrap"
          style={{ color: "rgba(23, 23, 23, 1)" }}
        >
          {title}
        </p>
      </div>
    );
  }

  const [currentForm, setCurrentForm] = useState('PersonalInformation');

  const formStepsTitle = [
    "Informasi Pribadi",
    "Informasi Akademik",
    "Detail Pendaftaran",
    "Unggah Dokumen",
  ];

  function PersonalInformation() {
    const [firstFormData, setFirstFormData] = useState({
      firstName: firstName,
      lastName: lastName,
    });

    const tabs = [
      { id: 0, label: "Nama Lengkap" },
      { id: 1, label: "Informasi Pribadi" },
      { id: 2, label: "Informasi Kontak" },
    ];

    return (
      <div className="flex flex-col w-full justify-start items-start">
        <div className="flex flex-row justify-start items-center gap-4">
          {tabs.map((item, index) => (
            <div
              key={index}
              className="px-6 py-3 rounded-lg"
              style={{
                backgroundColor:
                  index === selectedTabs
                    ? "rgba(67, 188, 205, 1)"
                    : "rgba(241, 243, 246, 1)",
              }}
              onClick={() => setSelectedTabs(index)}
            >
              <p
                className={`text-center font-semibold text-base ${
                  index === selectedTabs ? "text-white" : "text-tosca2"
                }`}
              >
                {item.label}
              </p>
            </div>
          ))}
        </div>
        <div className="flex w-full mt-8">
          {/* FirstTabForm */}
          {selectedTabs === 0 && (
            <div className="flex flex-col w-full items-center pb-10">
              <div className="flex w-full justify-start">
                <div className="flex flex-row justify-start items-center gap-3 relative">
                  <div
                    className="flex w-full text-left font-bold text-2xl text-black4"
                    style={{ lineHeight: "34px" }}
                  >
                    Nama Lengkap
                  </div>
                  <img src="../icon_info.svg" />
                  <div
                    className="flex flex-col gap-3 items-start justify-center rounded-xl p-4 absolute top-0 left-55 z-10"
                    style={{
                      backgroundColor: "rgba(255, 249, 227, 1)",
                      minWidth: "251.28px",
                      maxWidth: "251.28px",
                    }}
                  >
                    <p
                      className="text-left font-normal text-base text-black188"
                      style={{ lineHeight: "20px" }}
                    >
                      Kamu hanya bisa mengubah Nama Lengkap melalui Profil
                    </p>
                    <button
                      onClick={() => {
                        navigate("/profile-edit");
                      }}
                      className="text-left font-bold text-base text-primaryColor"
                      style={{ lineHeight: "20px" }}
                    >
                      Edit ke Profil
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full mt-6 gap-10">
                <div className="flex flex-col w-1/2 gap-4">
                  <label
                    htmlFor="firstName"
                    className="text-left font-bold text-base text-black4"
                    style={{ lineHeight: "20px" }}
                  >
                    Nama Depan
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstFormData.firstName}
                    onChange={(e) => {
                      setFirstFormData((prevData) => ({
                        ...prevData,
                        firstName: e.target.value,
                      }));
                    }}
                    disabled={true}
                    className="border-0 p-0 text-left font-normal text-base"
                    style={{
                      color: "rgba(129, 127, 130, 1)",
                    }}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-4">
                  <label
                    htmlFor="firstName"
                    className="text-left font-bold text-base text-black4"
                    style={{ lineHeight: "20px" }}
                  >
                    Nama Belakang
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={firstFormData.lastName}
                    onChange={(e) => {
                      setFirstFormData((prevData) => ({
                        ...prevData,
                        lastName: e.target.value,
                      }));
                    }}
                    disabled={true}
                    className="border-0 p-0 text-left font-normal text-base"
                    style={{
                      color: "rgba(129, 127, 130, 1)",
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  setSelectedTabs(2);
                }}
                className="py-3 bg-primaryColor text-center font-semibold text-base text-white rounded-lg mt-16"
                style={{ lineHeight: "24px", minWidth: "200px" }}
              >
                Selanjutnya
              </button>
            </div>
          )}
          {/* SecondTabForm */}
          {selectedTabs === 1 && (
            <div className="flex flex-col w-full items-center pb-10">
              <div className="flex flex-col w-full justify-start">
                <div className="grid gap-x-6 gap-y-8 col-span-full">
                  <div
                      className="sm:col-span-12 text-left font-bold text-2xl text-black4"
                      style={{ lineHeight: "34px" }}
                    >
                    Informasi Diri
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor="birth-place" className="block text-base font-bold leading-6 text-gray-900">
                      Tempat Kelahiran
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="birth-place"
                        id="birth-place"
                        autoComplete="birth-place"
                        value={birthPlace}
                        className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => {
                          setBirthPlace(e.target.value) 
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="birth-date" className="block w-full text-base font-bold leading-6 text-gray-900">
                      Tanggal Lahir
                    </label>
                    <div className="mt-2">
                      <ReactDatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          setDateOfBirth(
                            moment(date).tz("Asia/Jakarta").format("DD/MM/YYYY")
                          );
                        }}
                        className={`form-input block w-full text-gray-800 rounded-40px ${
                          mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
                        }`}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="gender" className="block text-base font-bold leading-6 text-gray-900">
                      Jenis Kelamin
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="gender"
                        autoComplete="gender"
                        className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="address" className="block text-base font-bold leading-6 text-gray-900">
                      Alamat
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="address"
                        id="address"
                        autoComplete="address"
                        value={address}
                        className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => { setAddress(e.target.value) }}
                      />
                    </div>
                  </div>

                  <div
                      className="sm:col-span-12 text-left font-bold text-2xl text-black4"
                      style={{ lineHeight: "34px" }}
                    >
                    Informasi Wali
                  </div>
                  
                  <div className="sm:col-span-6">
                    <label htmlFor="guardian-name" className="block text-base font-bold leading-6 text-gray-900">
                      Nama Wali
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="guardian-name"
                        id="guardian-name"
                        autoComplete="guardian-name"
                        className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => {
                          setGuardianName(e.target.value) 
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="guardian-relationship" className="block text-base font-bold leading-6 text-gray-900">
                      Hubungan Wali
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="guardian-relationship"
                        id="guardian-relationship"
                        autoComplete="guardian-relationship"
                        className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => {
                          setGuardianRelationship(e.target.value) 
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="guardian-email" className="block text-base font-bold leading-6 text-gray-900">
                      Email Wali
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="guardian-email"
                        id="guarian-email"
                        autoComplete="guardian-email"
                        className="form-input block w-full text-gray-800 rounded-40px ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => {
                          setGuardianEmail(e.target.value) 
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                      Nomor Ponsel Wali
                    </label>
                    <div className="mt-2">
                      <div class="flex flex-row w-full">
                        <div
                          className="flex flex-row items-center rounded-l-40px bg-white px-3 relative border border-gray-300"
                          style={{
                            height: "50px",
                            border: "1px solid #E0E0E1",
                          }}
                        >
                          <div className="border-0 w-12 h:w-18 pl-1">
                            +62
                          </div>
                          <ChevronDownIcon
                            className="h-5 w-auto p-0 m-0"
                            style={{ paddingTop: "2px" }}
                          />
                        </div>
                        <input
                          id="guardianPhone"
                          type="number"
                          name="guardianPhone"
                          accept="guardianPhone"
                          className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                            mapHasKeyAndValueNotEmpty(errors, "guardianPhone")
                              ? "border-red-400"
                              : ""
                          }`}
                          placeholder="cth. 8XXXXXXXXXXXX"
                          value={guardianPhone}
                          onChange={(e) => {
                            if (guardianPhone[0] === "0") {
                              setGuardianPhone(e.target.value.slice(1));
                            } else {
                              setGuardianPhone(e.target.value);
                            }
                          }}
                          onBlur={() => {
                            if (guardianPhone[0] === "0") {
                              setGuardianPhone(guardianPhone.slice(1));
                            }
                          }}
                          disabled={disableChangePhone}
                          required
                          onInvalid={(F) =>
                            F.target.setCustomValidity("No. HP orang tua harus diisi")
                          }
                          onInput={(F) => F.target.setCustomValidity("")}
                        />
                      </div>{" "}
                    </div>
                  </div> 

              
                </div>
              </div>
            
              <button
                onClick={(e) => {
                  setSelectedTabs(2);
                }}
                className="py-3 bg-primaryColor text-center font-semibold text-base text-white rounded-lg mt-16"
                style={{ lineHeight: "24px", minWidth: "200px" }}
              >
                Selanjutnya
              </button>
            </div>
          )}
          {/* ThirdTabForm */}
          {selectedTabs === 2 && (
            <div className="flex flex-col w-full items-center pb-10">
              <div className="flex w-full justify-start">
                <div className="flex flex-row justify-start items-center gap-3 relative">
                  <div
                    className="flex w-full text-left font-bold text-2xl text-black4"
                    style={{ lineHeight: "34px" }}
                  >
                   Informasi Kontak
                  </div>
                  <img src="../icon_info.svg" />
                  <div
                    className="flex flex-col gap-3 items-start justify-center rounded-xl p-4 absolute top-0 left-55 z-10"
                    style={{
                      backgroundColor: "rgba(255, 249, 227, 1)",
                      minWidth: "251.28px",
                      maxWidth: "251.28px",
                    }}
                  >
                    <p
                      className="text-left font-normal text-base text-black188"
                      style={{ lineHeight: "20px" }}
                    >
                      Kamu hanya bisa mengubah No. HP melalui Profil
                    </p>
                    <button
                      onClick={() => {
                        navigate("/profile-edit");
                      }}
                      className="text-left font-bold text-base text-primaryColor"
                      style={{ lineHeight: "20px" }}
                    >
                      Edit ke Profil
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full mt-6 gap-10">
                <div className="flex flex-col w-1/2 gap-4">
                  <label
                    htmlFor="firstName"
                    className="text-left font-bold text-base text-black4"
                    style={{ lineHeight: "20px" }}
                  >
                    No. HP siswa
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstFormData.firstName}
                    onChange={(e) => {
                      setFirstFormData((prevData) => ({
                        ...prevData,
                        firstName: e.target.value,
                      }));
                    }}
                    disabled={true}
                    className="border-0 p-0 text-left font-normal text-base"
                    style={{
                      color: "rgba(129, 127, 130, 1)",
                    }}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-4">
                  <label
                    htmlFor="firstName"
                    className="text-left font-bold text-base text-black4"
                    style={{ lineHeight: "20px" }}
                  >
                    Email siswa
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={firstFormData.lastName}
                    onChange={(e) => {
                      setFirstFormData((prevData) => ({
                        ...prevData,
                        lastName: e.target.value,
                      }));
                    }}
                    disabled={true}
                    className="border-0 p-0 text-left font-normal text-base"
                    style={{
                      color: "rgba(129, 127, 130, 1)",
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  setCurrentForm('AcademicInformation');
                }}
                className="py-3 bg-primaryColor text-center font-semibold text-base text-white rounded-lg mt-16"
                style={{ lineHeight: "24px", minWidth: "200px" }}
              >
                Selanjutnya
              </button>
            </div>
          )}

        </div>
      </div>
    );
  }

  function AcademicInformation() {
    return (
      <div className="flex flex-col w-full justify-start items-start">
        <div className="flex w-full">
          <div className="flex flex-col w-full items-center pb-10">
            <div className="flex flex-col w-full justify-start">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
                <div className="mt-2">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Nama Sekolah
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
  
                <div className="mt-2">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Asal Sekolah
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
  
                <div className="mt-2">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Tingkat Kelas
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
  
                <div className="mt-2">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Jurusan
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
  
            <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
              <button
                   onClick={() => {
                    setCurrentForm('PersonalInformation');
                  }}
                  className="py-3 bg-white text-center font-semibold text-base text-primaryColor rounded-lg mt-16 border-2 border-primaryColor"
                  style={{ lineHeight: "24px", minWidth: "200px" }}
              >
                  Sebelumnya
              </button>
            
              <button
                 onClick={() => {
                  setCurrentForm('RegistrationDetails');
                }}
                  className="py-3 bg-primaryColor text-center font-semibold text-base text-white rounded-lg mt-16"
                  style={{ lineHeight: "24px", minWidth: "200px" }}
              >
                  Selanjutnya
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function RegistrationDetails() {
    return (
      <div className="flex flex-col w-full justify-start items-start">
        <div className="flex w-full">
          <div className="flex flex-col w-full items-center pb-10">
            <div className="flex flex-col w-full justify-start">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
                <div className="mt-2">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Minat Jurusan
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
      
                <div className="mt-2">
                  <label className="block text-base font-bold leading-6 text-gray-900">Pilih Masa Studi</label>
                  <div className="mt-2 flex">
                    <label className="mr-4">
                      <input type="radio" name="masa-studi" value="Spring" className="form-radio h-4 w-4 text-indigo-600" />
                      <span className="ml-2">Spring Take (Januari)</span>
                    </label>
                    <label>
                      <input type="radio" name="masa-studi" value="Summer" className="form-radio h-4 w-4 text-indigo-600" />
                      <span className="ml-2">Summer/Fall Intake (Juni/Agustus)</span>
                    </label>
                  </div>
                </div>
              </div>
      
              <div className="grid grid-cols-1 gap-x-6 gap-y-8">
                <div className="mt-8">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Apakah kamu pernah mengikuti test IELTS/TOEFL/SAT sebelumnya?
                  </label>
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      name="test"
                      id="test"
                      className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <span className="ml-2">Ya, saya pernah mengikuti test</span>
                  </div>
                </div>
      
                <div className="mt-1">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Berapa skor yang kamu dapatkan?
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
  
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
                <div className="mt-8">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Apakah kamu mendaftar PTN(Perguruan Tinggi Negri)?
                  </label>
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      name="test"
                      id="test"
                      className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <span className="ml-2">Ya, saya mendaftar PTN</span>
                  </div>
                </div>
      
                <div className="mt-8">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Apakah kamu mendaftar Universitas di luar negri?
                  </label>
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      name="test"
                      id="test"
                      className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <span className="ml-2">Ya, saya mendaftar Universitas di luar negri</span>
                  </div>
                </div>
              </div>
  
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
                <div className="mt-8">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Sebutkan PTN yg sedang kamu daftar
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <span className="ml-2 text-gray-900">Ya, saya mendaftar PTN</span>
                  </div>
                </div>
      
                <div className="mt-8">
                  <label htmlFor="first-name" className="block text-base font-bold leading-6 text-gray-900">
                    Sebutkan Universitas luar negri yang sedang kamu daftar
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="form-input block w-full rounded-40px text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <span className="ml-2 text-gray-900">Contoh: University of Columbia</span>
                  </div>
                </div>
              </div>
  
              <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
                <button
                    onClick={() => {
                      setCurrentForm('AcademicInformation')
                    }}
                    className="py-3 bg-white text-center font-semibold text-base text-primaryColor rounded-lg mt-16 border-2 border-primaryColor"
                    style={{ lineHeight: "24px", minWidth: "200px" }}
                >
                    Sebelumnya
                </button>
              
                <button
                    onClick={() => {}}
                    className="py-3 bg-primaryColor text-center font-semibold text-base text-white rounded-lg mt-16"
                    style={{ lineHeight: "24px", minWidth: "200px" }}
                >
                    Selanjutnya
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    <PageLoading />;
  }
  return (
    <LayoutV2 bgColor="bg-white" padding="0px">
      <p className="text-center font-bold text-3xl hd:text-48px text-black1 mt-4 hd:mt-20">
        Isi Formulir Pendaftaran
      </p>
      <div className="flex w-full px-20">
        <div className="flex flex-row items-center justify-between w-full gap-4 mt-12 overflow-auto pb-4">
          {formStepsTitle.map((item, index) => (
            <React.Fragment key={index}>
              <FormStep number={`0${index + 1}`} title={item} />
              {index !== 3 && (
                <div
                  className="w-full"
                  style={{
                    minHeight: "4px",
                    maxHeight: "4px",
                    backgroundColor: "rgba(217, 217, 217, 1)",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="flex w-full mt-4 px-20">
        <div
          className="w-full"
          style={{
            minHeight: "1px",
            maxHeight: "1px",
            backgroundColor: "rgba(36, 16, 54, 0.30)",
          }}
        />
      </div>
      <div className="w-full px-20 mt-8">
        {currentForm === 'PersonalInformation' && <PersonalInformation />}
        {currentForm === 'AcademicInformation' && <AcademicInformation />}
        {currentForm === 'RegistrationDetails' && <RegistrationDetails />}
      </div>
    </LayoutV2>
  );
}
