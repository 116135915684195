import { useEffect, useState } from "react";
import { RadioButton } from "../../../components/RadioButton";
import PageLoading from "../../PageLoading";
import countryJSON from "../../../components/jsons/Countries.json";
import indoProvJSON from "../../../components/jsons/IndonesiaProvinceWithCities.json";

export default function LaSalleBirthPlaceAndLegalStatus({
  draftData,
  callbackData,
  setCurrentForm,
  currentForm,
}) {
  const legalStatus = [
    { status: ["Indonesian"] },
    {
      status: [
        "Indonesian",
        "Permanent Resident",
        "Temporary Resident",
        "License to Obtain",
        "Refugee",
        "Other",
      ],
    },
  ];

  const [selectedCountry, setSelectedCountry] = useState("Indonesia");
  const [countryList, setCountryList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("DKI Jakarta");
  const [provinceNCity, setProvinceNCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("Pilih Kota");
  const [selectedCitizenship, setSelectedCitizenship] = useState("Indonesia");
  const [selectedLegalStatus, setSelectedLegalStatus] = useState(
    legalStatus[0].status[0]
  );

  useEffect(() => {
    setCountryList(countryJSON);
    setProvinceNCity(indoProvJSON);
  }, []);

  useEffect(() => {
    if (draftData) {
      setSelectedCountry(draftData.negaraKelahiran);
      setSelectedProvince(draftData.provinsiKelahiran ?? "DKI Jakarta");
      setSelectedCity(draftData.kotaKelahiran ?? "Pilih Kota");
      setSelectedCitizenship(draftData.kewarganegaraan ?? "Indonesia");
      setSelectedLegalStatus(
        legalStatus[draftData.kewarganegaraan === "Indonesia" ? 0 : 1].status[
          draftData.kewarganegaraan === "Indonesia"
            ? 0
            : legalStatus[1].status.indexOf(draftData.statusKewarganegaraan)
        ]
      );
    }
  }, [draftData]);

  const legalStatusRadioChangeHandler = (e) => {
    setSelectedLegalStatus(e.target.value);
  };

  function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    callbackData({
      negaraKelahiran: elements[0].value,
      provinsiKelahiran:
        elements[0].value === "Indonesia" ? elements[1].value : "DKI Jakarta",
      kotaKelahiran:
        elements[0].value === "Indonesia" ? elements[2].value : "Pilih Kota",
      kewarganegaraan:
        elements[elements[0].value === "Indonesia" ? 3 : 1].value,
      statusKewarganegaraan: selectedLegalStatus,
    });
    setCurrentForm(currentForm + 1);
  }

  function toPrevStep() {
    callbackData({
      negaraKelahiran: selectedCountry,
      provinsiKelahiran: selectedProvince,
      kotaKelahiran: selectedCity,
      kewarganegaraan: selectedCitizenship,
      statusKewarganegaraan: selectedLegalStatus,
    });
    setCurrentForm(currentForm - 1);
  }

  if (!countryList || !provinceNCity) {
    <PageLoading />;
  }
  return (
    <form
      onSubmit={submitForm}
      className="flex flex-col w-full justify-start items-start pb-10"
    >
      <div className="flex flex-col w-full justify-start">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
          <div className="mt-2">
            <label
              className="block text-base font-bold leading-6 text-gray-90"
              htmlFor="negara_kelahiran"
            >
              Negara Kelahiran<span className={"text-red-500"}>*</span>
            </label>
            <select
              placeholder={"Pilih Negara"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedCountry}
              onChange={(e) => {
                const country = e.target.value;
                setSelectedCountry(country);
              }}
              required
            >
              {countryList.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {selectedCountry === "Indonesia" && (
            <div className="mt-2">
              <label
                className="block text-base font-bold leading-6 text-gray-90"
                htmlFor="provinsi_kelahiran"
              >
                Provinsi Kelahiran<span className={"text-red-500"}>*</span>
              </label>
              <select
                placeholder={"Pilih Provinsi"}
                className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                value={selectedProvince}
                onChange={(e) => {
                  const province = e.target.value;
                  setSelectedProvince(province);
                }}
                required
              >
                {provinceNCity.map((item, index) => (
                  <option key={index} value={item.provinsi}>
                    {item.provinsi}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedCountry === "Indonesia" && (
            <div className="mt-2">
              <label
                className="block text-base font-bold leading-6 text-gray-90"
                htmlFor="alamat_lengkap"
              >
                Kota Kelahiran<span className={"text-red-500"}>*</span>
              </label>
              <select
                placeholder={"Pilih Kota"}
                className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                value={selectedCity}
                onChange={(e) => {
                  const city = e.target.value;
                  setSelectedCity(city);
                }}
                required
              >
                {provinceNCity.length > 0 &&
                  provinceNCity
                    .filter(
                      (element) => element.provinsi === selectedProvince
                    )[0]
                    .kota.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
              </select>
            </div>
          )}

          <div className="mt-2">
            <label
              className="block text-base font-bold leading-6 text-gray-90"
              htmlFor="citizenship"
            >
              Kewarnegaraan <span className={"text-red-500"}>*</span>
            </label>

            <select
              placeholder={"Pilih Negara"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedCitizenship}
              onChange={(e) => {
                const country = e.target.value;
                setSelectedCitizenship(country);
              }}
              required
            >
              {countryList.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-2">
            <label className="block text-base font-bold leading-6 text-gray-90">
              Status Kewarganegaraan <span className={"text-red-500"}>*</span>
            </label>
            {selectedCitizenship !== "Indonesia" ? (
              <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-4">
                {legalStatus[1].status.map((item, index) => (
                  <div key={index}>
                    <RadioButton
                      onClicked={legalStatusRadioChangeHandler}
                      id={"legal" + index}
                      isSelected={selectedLegalStatus === item}
                      label={item}
                      value={item}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-row gap-8 mt-4">
                {legalStatus[0].status.map((item, index) => (
                  <div key={index}>
                    <RadioButton
                      onClicked={legalStatusRadioChangeHandler}
                      id={index + 1}
                      isSelected={selectedLegalStatus === item}
                      label={item}
                      value={item}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
        <button
          onClick={() => {
            toPrevStep();
          }}
          className="py-3 bg-white text-center font-semibold text-base text-primaryColor rounded-lg mt-16 border-2 border-primaryColor"
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Sebelumnya
        </button>

        <button
          type="submit"
          className="py-3 bg-primaryColor text-center font-semibold text-base text-white rounded-lg mt-16"
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Selanjutnya
        </button>
      </div>
    </form>
  );
}
