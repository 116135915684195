import Layout from "../components/Layout";
import parse from "html-react-parser";
import RenderHtml from "react-native-render-html";
import LayoutV2 from "../components/Layout_V2";

export default function PrivacyPolicy() {
  const data = {
    html: `<html>

    <head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=Generator content="Microsoft Word 15 (filtered)">
    <style>
    <!--
     /* Font Definitions */
     @font-face
      {font-family:Wingdings;
      panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;}
    @font-face
      {font-family:Cambria;
      panose-1:2 4 5 3 5 4 6 3 2 4;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:8.0pt;
      margin-left:0in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoFooter, li.MsoFooter, div.MsoFooter
      {mso-style-link:"Footer Char";
      margin:0in;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    a:link, span.MsoHyperlink
      {color:#0563C1;
      text-decoration:underline;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:8.0pt;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:0in;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:0in;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:8.0pt;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    span.FooterChar
      {mso-style-name:"Footer Char";
      mso-style-link:Footer;}
    .MsoChpDefault
      {font-family:"Calibri",sans-serif;}
    .MsoPapDefault
      {margin-bottom:8.0pt;
      line-height:107%;}
     /* Page Definitions */
     @page WordSection1
      {size:595.3pt 841.9pt;
      margin:1.0in 1.0in 1.0in 1.0in;}
    div.WordSection1
      {page:WordSection1;}
     /* List Definitions */
     ol
      {margin-bottom:0in;}
    ul
      {margin-bottom:0in;}
    -->
    </style>
    
    </head>
    
    <body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>
    
    <div class=WordSection1>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:18.0pt;font-family:"Times New Roman",serif'>Kebijakan Privasi </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif;
    color:#7030A0'>Tanggal Berlaku: 15 Mei 2023</span></p>
    
    <p class=MsoNormal style='line-height:normal'><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif'>Privasi Anda
    penting bagi Exploration, sehingga kami telah mengembangkan Kebijakan Privasi
    yang berisi cara pengumpulan, penggunaan, pengungkapan, pengalihan, dan
    penyimpanan informasi pribadi Anda. Sebagai pengguna, Anda dianjurkan untuk
    membaca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa Anda
    memahami bagaimana ketentuan Kebijakan Privasi ini kami berlakukan. </span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>1.
    Pengumpulan dan Penggunaan Informasi Pribadi</span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Informasi
    Pribadi&nbsp;</span></b><span lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    adalah data yang dapat digunakan untuk mengenali atau menghubungi seseorang
    seperti: Nama Depan, Nama Belakang, Nomor Ponsel, Nama Sekolah, Tingkat
    Pendidikan, Jurusan Siswa, Kelas Siswa, Email, dan No. Virtual Account dan
    informasi lain yang mungkin dapat mengidentifikasi Anda sebagai pengguna Exploration.
    Exploration dapat saja meminta Informasi Pribadi saat Anda menghubungi Exploration
    atau pun mitra yang bekerja sama dengan Exploration. Informasi Pribadi yang
    diperoleh Exploration bersama mitranya dapat saling dibagi dan digunakan sesuai
    Kebijakan Privasi ini. Mereka juga dapat menggabungkannya dengan informasi lain
    untuk menyediakan dan menyempurnakan produk, layanan, konten, serta promosi
    dari mitra Exploration. Anda dapat tidak memberikan Informasi Pribadi yang
    diminta, tetapi sebagai konsekuensinya, ada kemungkinan Exploration tidak dapat
    menyediakan produk dan layanan maupun menjawab pertanyaan Anda. Berikut adalah
    contoh jenis Informasi Pribadi yang dapat dikumpulkan Exploration serta
    penggunaannya: <br>
    <br>
    <b>Informasi Pribadi yang Dikumpulkan </b></span></p>
    
    <ul type=disc>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Ketika Anda
         mengaktifkan akun Exploration, menghubungi Exploration melalui media
         sosial, atau berpartisipasi dalam survei online, berbagai Informasi
         Pribadi milik Anda, termasuk nama, no KTP, NPWP, pengenal perangkat,
         alamat IP, informasi lokasi, dan informasi profil tempat kontak tersebut
         berada melalui media sosial dapat dikumpulkan Exploration.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Bila Anda
         berbagi konten Exploration dengan keluarga dan teman, atau mengajak orang
         lain untuk berpartisipasi dalam layanan maupun acara yang melibatkan Exploration,
         maka informasi yang Anda berikan tentang mereka seperti nama, nomor
         telepon dan alamat email juga dapat dikumpulkan Exploration. Informasi
         tersebut akan digunakan untuk memenuhi permintaan Anda, menyediakan
         layanan yang relevan, atau untuk mencegah penipuan.</span></li>
    </ul>
    
    <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Bagaimana
    Informasi Pribadi Anda Digunakan </span></b></p>
    
    <p class=MsoNormal style='line-height:normal'><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif'>Informasi Pribadi
    Anda digunakan untuk tujuan yang dijelaskan dalam Kebijakan Privasi ini dengan
    persetujuan Anda sebagai syarat kepatuhan wajib hukum yang harus ditaati oleh Exploration
    atau ketika dinilai telah diperlukan untuk tujuan dan kepentingan sah yang
    diupayakan oleh Exploration atau pihak ketiga yang mungkin memerlukan
    pengungkapan informasi dari Exploration. </span></p>
    
    <ul type=disc>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Informasi
         Pribadi yang dikumpulkan memungkinkan Exploration untuk terus mengabari
         Anda tentang pemberitahuan produk terkini, pembaruan perangkat lunak, dan
         acara mendatang yang melibatkan Exploration.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Informasi
         Pribadi juga digunakan untuk membantu, membuat, mengembangkan,
         mengoperasikan, memberikan, dan meningkatkan produk, layanan, konten,
         serta promo marketing mitra Exploration, dan untuk mencegah penipuan
         identitas. Explorationjuga dapat menggunakan Informasi Pribadi untuk
         tujuan keamanan akun dan jaringan, termasuk untuk melindungi pelayanan
         demi keuntungan semua pengguna. Exploration membatasi akses data dalam
         kepentingan sah untuk melindungi pengguna dan layanan Exploration.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Informasi
         Pribadi Anda dapat digunakan untuk keperluan verifikasi identitas,
         membantu mengidentifikasi pengguna, atau menentukan promosi pemasaran yang
         tepat.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Dari waktu ke
         waktu, Exploration dapat menggunakan Informasi Pribadi untuk mengirimi
         Anda pemberitahuan penting seperti komunikasi tentang perubahan
         persyaratan dan ketentuan, serta kebijakan kami. Karena informasi ini
         penting bagi interaksi Anda dengan Exploration, Anda tidak dapat memilih
         untuk berhenti menerima informasi ini.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Informasi
         Pribadi juga dapat digunakan untuk tujuan internal seperti audit, analisis
         data, dan penelitian guna menyempurnakan produk, layanan, serta komunikasi
         pelanggan Exploration.</span></li>
    </ul>
    
    <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Sumber
    Informasi Pribadi yang Tidak Dikumpulkan dari Anda </span></b></p>
    
    <p class=MsoNormal style='line-height:normal'><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif'>Sebagai siswa di
    institusi yang bekerja sama dengan Exploration dalam penggunaan produk dan
    layanan Exploration, Informasi Pribadi Anda mungkin saja telah diterima Exploration
    dari institusi pendidikan tersebut. Informasi Pribadi Anda tersebut meliputi Nama
    Depan, Nama Belakang, Nomor Ponsel, Nama Sekolah, Tingkat Pendidikan, Jurusan
    Siswa, Kelas Siswa, Email, dan No. Virtual Account dan informasi lain yang
    digunakan untuk keperluan pendaftaran pengguna produk dan layanan Exploration.
    Informasi Pribadi Anda juga dapat diperoleh dari pihak lain apabila pihak
    tersebut telah mendaftarkan Anda untuk menerima produk dan layanan atau
    berpartisipasi di acara yang melibatkan Exploration. Informasi yang Anda
    berikan ketika mengaktifkan akun Exploration akan divalidasi untuk tujuan
    keamanan dan pencegahan penipuan. Dari Informasi Pribadi yang diterima, Exploration
    juga dapat menghubungi Anda untuk menginformasikan promosi dari mitra Exploration.
    Jika Anda tidak memberi Informasi Pribadi secara langsung namun Exploration tetap
    dapat menghubungi Anda, sumber informasi akan diinformasikan kepada Anda saat
    kami pertama kali Anda dihubungi terkait promosi tersebut.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>2.
    Pengumpulan dan Penggunaan Informasi Non-Pribadi </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Exploration
    dapat mengumpulkan, menggunakan, mengalihkan, dan mengungkapkan Informasi Non-
    Pribadi untuk tujuan apa pun. Berikut adalah beberapa contoh Informasi
    Non-Pribadi yang dikumpulkan serta penggunaannya:</span></p>
    
    <ul type=disc>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Informasi
         seperti tagihan virtual account dari siswa, laporan transaksi pembayaran
         virtual account dari mitra bank, informasi kelas, pengenal perangkat unik,
         dan zona waktu saat Exploration digunakan dapat diutilisasi pihak Exploration
         agar dapat memahami perilaku pengguna dengan lebih baik serta
         menyempurnakan produk, layanan, dan konten promosi.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>Exploration
         dapat mengumpulkan informasi terkait aktivitas pengguna dari produk dan
         layanan Exploration. Informasi ini digabungkan dan digunakan agar Exploration
         dapat menyediakan informasi yang lebih bermanfaat kepada pengguna serta
         dapat memahami fitur paling tepat dari produk dan layanan Exploration.
         Data yang dikumpulkan dianggap sebagai Informasi Non-Pribadi untuk
         keperluan Kebijakan Privasi ini.</span></li>
    </ul>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Jika
    Informasi Non-Pribadi dan Informasi Pribadi digabungkan, maka informasi
    gabungan tersebut akan diperlakukan sebagai Informasi Pribadi selama tetap
    dalam gabungan.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>3.
    Pengungkapan kepada Pihak Ketiga </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Exploration
    dapat memberikan Informasi Pribadi tertentu kepada pihak ketiga untuk
    menyediakan atau meningkatkan mutu produk dan layanan Exploration, termasuk
    membagikan Informasi Pribadi dengan mitra bank atau layan pembyaran lainnya yang
    memberikan layanan virtual account dalam kaitannya dengan tagihan dari pihak
    institusi pendidikan. Dalam konteks tersebut, Exploration meminta pihak ketiga
    tersebut untuk menanganinya sesuai dengan hukum terkait.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Lainnya</span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Undang-undang,
    proses hukum, litigasi, dan/atau permintaan dari lembaga publik maupun
    pemerintah di dalam atau luar negara domisili Anda mungkin mengharuskan Exploration
    mengungkapkan informasi pribadi Anda. Exploration juga dapat mengungkapkan
    informasi tentang Anda jika bertujuan untuk keamanan nasional, penegakan hukum,
    atau masalah lain demi kepentingan publik harus atau perlu dilakukan. Exploration
    dapat mengungkapkan informasi Anda, hanya jika ada dasar yang sah untuk
    melakukannya dan telah ditentukan bahwa pengungkapan diperlukan untuk
    menegakkan Syarat &amp; Ketentuan Exploration atau melindungi operasional dan
    pengguna Exploration. Hal ini dapat termasuk memberikan informasi kepada
    otoritas publik atau pemerintah. Selain itu, jika terjadi reorganisasi, merger,
    atau penjualan, Exploration dapat mengalihkan setiap dan semua informasi
    pribadi yang telah dikumpulkan ke pihak ketiga terkait.</span></p>
    
    <p class=MsoNormal style='line-height:normal'><b><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#7030A0'>4.
    Perlindungan Informasi Pribadi </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Ketika
    Anda menggunakan produk atau layanan Exploration dan mengunggah/membagikannya
    di ruang obrolan daring atau layanan jejaring sosial, informasi pribadi dan
    konten yang Anda bagikan dapat dilihat oleh pengguna lain dan dapat dibaca,
    dikumpulkan, atau digunakan oleh mereka. Dalam hal ini, Anda bertanggung jawab
    atas semua Informasi Pribadi yang Anda bagikan. Misalnya, jika Anda
    mencantumkan nama dan alamat surel dalam unggahan jejaring sosial, maka
    informasi tersebut akan ditampilkan secara publik.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Integritas
    dan Penyimpanan Informasi Pribadi </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Exploration
    akan memudahkan Anda menjaga keakuratan, kelengkapan, dan keterbaruan Informasi
    Pribadi Anda. Informasi Pribadi Anda akan disimpan selama periode yang
    diperlukan guna memenuhi tujuan yang diuraikan dalam Kebijakan Privasi ini. Exploration
    dengan hati-hati memeriksa kebutuhan Informasi Pribadi dan jika memang relevan,
    informasi akan disimpan dalam periode yang<br>
    sesingkat mungkin guna merealisasikan tujuan pengumpulan kecuali jika
    diperlukan periode penyimpanan yang lebih lama oleh hukum.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Akses
    ke Informasi Pribadi </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Anda
    dapat turut memastikan keakuratan, kelengkapan, dan kebaruan informasi kontak
    dan preferensi Anda dengan masuk ke halaman Profil Exploration.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Penyimpanan,
    Pemrosesan, Pemindahan, dan Akses Data Pribadi </span></b></p>
    
    <ul type=disc>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>a. Kami akan
         menyimpan Informasi Pribadi Anda untuk periode yang diperlukan untuk
         memenuhi berbagai tujuan yang diuraikan dalam Kebijakan Privasi ini dan
         setiap tujuan usaha atau hukum yang sah, kecuali periode
         penyimpanan/retensi yang lebih lama diizinkan atau diwajibkan oleh
         peraturan perundang- undangan. Setelah jangka waktu tersebut, kami akan
         menghapus dan memusnahkan atau membuat anonim semua dokumen yang memuat
         Informasi Pribadi Anda secara aman dan terjamin.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>b. Informasi
         Pribadi Anda yang Anda berikan kepada kami dapat disimpan, diproses,
         dipindahkan antara, dan diakses dari server Kami maupun dari server
         konsultan-konsultan Kami, dan/atau pemberi jasa-pemberi jasa kami, baik
         yang terletak di Republik Indonesia maupun negara-negara lain yang
         memiliki peraturan perundang-undangan yang mungkin tidak menjamin tingkat
         perlindungan Data Pribadi yang sama dengan di Republik Indonesia. Namun,
         Kami akan mengambil langkah sewajarnya untuk memastikan bahwa Informasi
         Pribadi Anda ditangani sesuai dengan Kebijakan Privasi ini, terlepas dari
         tempat Informasi Pribadi Anda disimpan atau diakses.</span></li>
    </ul>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Exploration
    dapat menolak proses permintaan yang tidak serius/menggangu, membahayakan
    privasi orang lain, sangat tidak praktis, atau yang aksesnya tidak diwajibkan
    oleh hukum setempat. Exploration juga dapat menolak aspek permintaan akses atau
    penghapusan jika hal itu dapat menghambat penggunaan data Exploration yang sah
    untuk tujuan anti-penipuan dan keamanan seperti yang dijelaskan sebelumnya.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Situs
    dan Layanan Pihak Ketiga </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Produk
    dan layanan Exploration dapat berisi tautan ke situs web, produk, maupun
    layanan pihak ketiga. Produk dan layanan Exploration juga mungkin menggunakan
    atau menawarkan produk maupun layanan dari pihak ketiga, misalnya laporan
    transaksi pembayaran virtual account dari mitra bank.<br>
    Informasi yang dikumpulkan pihak ketiga, yang mungkin menyertakan hal seperti
    data lokasi atau detail kontak, diatur oleh praktik privasi mereka. Anda
    dianjurkan untuk mempelajari praktik privasi milik pihak ketiga tersebut.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Keamanan
    </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kerahasiaan
    informasi pribadi Anda adalah hal yang terpenting bagi Exploration. Segala
    upaya dan langkah terbaik akan dilakukan untuk melindungi dan mengamankan data
    dan informasi pribadi Anda. Akan tetapi, Exploration tidak dapat sepenuhnya
    menjamin bahwa sistem tidak akan dapat ditembus sama sekali akibat adanya
    virus, malware, gangguan atau kejadian luar biasa termasuk akses tanpa otorisasi oleh pihak ketiga. Anda tidak boleh mengungkapkan PIN, kata
    sandi atau bentuk autentikasi lainnya pada akun Anda kepada siapapun dan harus
    senantiasa menjaga keamanan perangkat yang Anda gunakan.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Perubahan
    atas Kebijakan Privasi </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Exploration
    dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Bila terdapat
    perubahan penting pada kebijakan, pemberitahuan akan dikirimkan ke alamat surel
    Anda bersama Kebijakan Privasi yang telah diperbarui.</span></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kepatuhan
    Kepada Ketentuan Peraturan Perundang-Undangan Yang Berlaku </span></b></p>
    
    <ul type=disc>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>a. Kebijakan
         Privasi ini tunduk pada hukum Negara Republik Indonesia.</span></li>
     <li class=MsoNormal style='line-height:normal'><span lang=EN-ID
         style='font-size:12.0pt;font-family:"Times New Roman",serif'>b. Kami
         mematuhi ketentuan peraturan perundang-undangan yang berlaku di Republik
         Indonesia, dan kami (termasuk konsultan-konsultan kami, dan/atau pemberi
         jasa-pemberi jasa kami) dapat dan akan mengungkapkan data dan informasi
         apa pun, termasuk Data Pribadi Anda, kepada pemerintah atau aparat penegak
         hukum apabila diminta secara sah oleh pengadilan berdasarkan hukum yang
         berlaku di Republik Indonesia.</span></li>
    </ul>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><b><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Hubungi
    Kami </span></b></p>
    
    <p class=MsoNormal style='margin-left:7.5pt;line-height:normal'><span
    lang=EN-ID style='font-size:12.0pt;font-family:"Times New Roman",serif'>Jika
    Anda memiliki pertanyaan atau masalah tentang Kebijakan Privasi atau
    pemprosesan data Exploration silahkan menghubungi kami dengan mengirimkan surel
    ke </span><a href="mailto:contact@explorationid.com"><span lang=EN-ID
    style='font-size:12.0pt;font-family:"Times New Roman",serif'>contact@explorationid.com</span></a></p>
    
    <span lang=EN-ID style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'><br
    clear=all style='page-break-before:always'>
    </span>
    
    <p class=MsoNormal><span lang=EN-ID style='font-size:12.0pt;line-height:107%;
    font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
    </div>
    
    </body>
    
    </html>`,
  };

  return (
    <LayoutV2>
      {/* <div dangerouslySetInnerHTML={{ __html: perf }} /> */}
      {/* <div>{parse(data)}</div> */}
      <RenderHtml source={data} />
    </LayoutV2>
  );
}
