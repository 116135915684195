import { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import LayoutV2 from "../components/Layout_V2";

export default function FAQSummerCamp() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [selectedTab, setSelectedTab] = useState(0);
  const tabsContainerRef = useRef(null);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  function FAQAccordion({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div
        className={`flex flex-col justify-start ${
          screenWidth < 1024 ? "p-3" : "py-5 px-6"
        } w-full border border-tosca2 ${
          isOpen ? "bg-tosca2" : "bg-white"
        } rounded-lg gap-3`}
      >
        <div
          className={`flex flex-row justify-between ${
            isOpen ? "items-center" : "items-end"
          } w-full`}
          style={{ gap: screenWidth < 1024 ? "12px" : "293px" }}
        >
          <p
            className={`w-full text-left font-bold ${
              screenWidth < 1024 ? "text-base" : "text-xl"
            } ${isOpen ? "text-white" : "text-black"}`}
            style={{ lineHeight: screenWidth < 1024 ? "27.21px" : "34px" }}
          >
            {question}
          </p>
          {!isOpen ? (
            <ChevronDownIcon
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={`${
                screenWidth < 1024 ? "w-12 h-auto" : "w-10 h-auto"
              } p-0 m-0 text-black1 cursor-pointer`}
            />
          ) : (
            <ChevronUpIcon
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={`${
                screenWidth < 1024 ? "w-12 h-auto" : "w-10 h-auto"
              } p-0 m-0 text-white cursor-pointer`}
            />
          )}
        </div>
        {isOpen && (
          <p
            className={`text-justify font-normal ${
              screenWidth < 1024 ? "text-base" : "text-xl"
            } text-white`}
            style={{ lineHeight: screenWidth < 1024 ? "27.21px" : "34px" }}
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        )}
      </div>
    );
  }

  const knowMore = [
    {
      icon: "/icon_call.svg",
      name: "Tanya OCBC",
      type: "phone",
      redirect: "tel:1500-999",
    },
    {
      icon: "/icon_whatsapp.svg",
      name: "Whatsapp",
      type: "link",
      redirect: "https://wa.me/6282118887806",
    },
  ];

  const faqTabs = [
    {
      id: 0,
      name: "General",
      faqs: [
        {
          question:
            "Apa tema dan topik yang ditawarkan dalam OCBC Summer Camp 2024?",
          answer: `OCBC Summer Camp 2024 mengusung tema “Technology & Digital: Unleashing Tomorrow’s Possibilities”.<br /><br />Terdapat dua (2) topik yang dapat dipilih peserta, yakni:<br />1. Digital Business Management<br />2. Road to ChatGPT - Basics of AI in Language Processing
            `,
        },
        {
          question:
            "Apa saja keuntungan dan keunggulan yang ditawarkan oleh OCBC Summer Camp 2024?",
          answer:
            "OCBC Summer Camp memberikan kesempatan bagi siswa-siswi SMA (atau sederajat) untuk mendapatkan pengalaman menjadi mahasiswa National University of Singapore (NUS) dengan menghadiri kelas level perkuliahan, kunjungan perusahaan, serta tur universitas selama 6 hari di Singapura.",
        },
        {
          question: "Apakah OCBC Summer Camp dibuka untuk umum?",
          answer: `OCBC Summer Camp merupakan program bersyarat yang dibuka untuk umum. Pelajari persyaratan untuk mengikuti program pada laman <a style="color: #0563c1;" href="https://explorationid.com/explore-summer-camp" target="_blank" rel="noreferrer">Explore Summer Camp</a>.`,
        },
        {
          question: "Kapan OCBC Summer Camp 2024 akan diadakan?",
          answer:
            "OCBC Summer Camp pada tahun ini akan diadakan pada tanggal 30 Juni 2024 hingga 5 Juli 2024.",
        },
        {
          question: "Berapakah kuota peserta OCBC Summer Camp?",
          answer:
            "Kuota peserta OCBC Summer Camp adalah sebanyak 40 peserta untuk masing-masing topik.",
        },
        {
          question:
            "Apakah OCBC Summer Camp dilaksanakan secara tatap muka (offline)?",
          answer:
            "Seluruh kegiatan dan rangkaian acara OCBC Summer Camp akan dilaksanakan secara tatap muka (offline) di Singapura.",
        },
        {
          question:
            "Apa saja pilihan paket yang ditawarkan dalam OCBC Summer Camp?",
          answer: `Terdapat 2 (dua) paket yang ditawarkan, di antaranya:<br />1. Paket 1 - hanya program dan akomodasi<br />2. Paket 2 - program, akomodasi, dan penerbangan
          `,
        },
        {
          question: "Apakah program ini berbayar?",
          answer: `OCBC Summer Camp merupakan reward yang diberikan atas pemenuhan persyaratan pendaftaran program. Pelajari persyaratan untuk mengikuti program pada laman <a style="color: #0563c1;" href="https://explorationid.com/explore-summer-camp" target="_blank" rel="noreferrer">Explore Summer Camp</a>.`,
        },
        {
          question:
            "Bagaimana jika saya masih memiliki pertanyaan lebih lanjut terkait OCBC Summer Camp?",
          answer: `Anda dapat memilih salah satu channel yang terdapat pada laman <a style="color: #0563c1;" href="https://explorationid.com/explore-summer-camp" target="_blank" rel="noreferrer">Explore Summer Camp</a>, yakni dengan menghubungi TANYA OCBC di 1500-999 ataupun WhatsApp Official Exploration.`,
        },
      ],
    },
    {
      id: 1,
      name: "Persyaratan Peserta",
      faqs: [
        {
          question: "Siapakah yang bisa menjadi peserta OCBC Summer Camp?",
          answer:
            "Peserta diwajibkan berusia 15-18 tahun sebelum dilaksanakannya OCBC Summer Camp (30 Juni 2024). OCBC Summer Camp dirancang khusus untuk siswa-siswi yang sedang duduk di bangku SMA (atau sederajat).",
        },
        {
          question:
            "Bagaimana jika calon peserta telah memenuhi persyaratan usia (15-18 tahun), namun calon peserta sedang tidak duduk di bangku SMA (atau sederajat)?",
          answer:
            "Selama memenuhi persyaratan usia, calon peserta diperbolehkan untuk mendaftarkan diri ke dalam OCBC Summer Camp.",
        },
        {
          question:
            "Bagaimana jika calon peserta merupakan siswa yang sedang duduk di bangku SMA (atau sederajat), namun calon peserta tidak memenuhi persyaratan usia (15-18 tahun).",
          answer: `Jika calon peserta berusia di bawah 15 tahun namun sedang duduk di bangku SMA (atau sederajat), calon peserta TIDAK DIPERBOLEHKAN untuk mendaftarkan diri ke dalam OCBC Summer Camp.<br/><br/> Jika calon peserta berusia di atas 18 tahun namun sedang duduk di bangku SMA (atau sederajat), calon peserta MASIH DIPERBOLEHKAN untuk mendaftarkan diri ke dalam OCBC Summer Camp.
          `,
        },
        {
          question:
            "Apakah mahasiswa dapat mendaftarkan diri ke dalam OCBC Summer Camp?",
          answer: `Topik “Digital Business Management”<br /><br />Mahasiswa tahun pertama dari jurusan apapun diperbolehkan mengikuti OCBC Summer Camp Topik “Road to ChatGPT - Basics of AI in Language Processing”<br /><br />Mahasiswa tahun pertama diperbolehkan mengikuti OCBC Summer Camp selama dari jurusan <b>selain</b> jurusan Information Technology, Sistem Informasi, Computer Engineering, atau jurusan yang berhubungan dengan IT lainnya.`,
        },
        {
          question:
            "Bagaimana jika orang tua/wali dari peserta ingin mengikuti OCBC Summer Camp?",
          answer: `OCBC Summer Camp dirancang hanya untuk siswa-siswi yang sedang duduk di bangku SMA (atau sederajat) dan tidak terdapat paket untuk orang tua/wali. Akan tetapi jika orang tua/wali ingin menemani peserta, orang tua/wali dapat melakukan pemesanan penerbangan serta hotel secara mandiri. `,
        },
      ],
    },
    {
      id: 2,
      name: "Skema & Pendaftaran",
      faqs: [
        {
          question: "Kapan periode pendaftaran OCBC Summer Camp?",
          answer: `Pendaftaran akan dibagi menjadi dua (2) jalur, yakni:<br />1. Early Bird Promo: Sampai dengan 29 Februari 2024<br />2. Regular Price: 1 Maret – 2 April 2024`,
        },
        {
          question:
            "Apa langkah yang harus dilakukan untuk mendaftarkan diri ke dalam OCBC Summer Camp?",
          answer: `Anda dapat mengklik tombol <b>Saya Tertarik</b> yang terdapat pada laman <a style="color: #0563c1;" href="https://ocbc-id.typeform.com/to/g2wj25QH" target="_blank" rel="noreferrer">Explore Summer Camp</a>.`,
        },
        {
          question:
            "Apa persyaratan yang harus dipenuhi untuk mendaftarkan calon peserta ke dalam OCBC Summer Camp?",
          answer: `Orang tua/wali dari calon peserta diwajibkan untuk menjadi nasabah OCBC dan memenuhi persyaratan skema penempatan produk yang berlaku. Pelajari persyaratan untuk mengikuti program pada laman <a style="color: #0563c1;" href="https://explorationid.com/explore-summer-camp" target="_blank" rel="noreferrer">Explore Summer Camp</a>.`,
        },
        {
          question:
            "Apa saja dokumen yang harus disiapkan oleh peserta OCBC Summer Camp?",
          answer: `Dokumen wajib yang perlu disiapkan adalah paspor dengan masa aktif setidaknya 6 bulan setelah Program diselenggarakan. Dokumen persyaratan lainnya akan dikomunikasikan kepada peserta lebih lanjut setelah proses pendaftaran berhasil.`,
        },
        {
          question:
            "Bagaimana jika saya ingin membatalkan pendaftaran peserta OCBC Summer Camp?",
          answer: `Jika terdapat pembatalan setelah dilakukannya penempatan produk, maka reward atas program akan hangus dan terdapat penalti yang harus ditanggung oleh nasabah.`,
        },
        {
          question: "Bagaimana jika saya ingin mengganti pilihan paket saya?",
          answer: `Nasabah tidak dapat mengubah paket yang telah dipilih setelah penempatan produk dilakukan.`,
        },
        {
          question:
            "Bagaimana jika saya ingin mendaftarkan lebih dari 1 peserta?",
          answer: `Nasabah diperbolehkan untuk mendaftarkan lebih dari 1 peserta ke dalam OCBC Summer Camp.`,
        },
        {
          question:
            "Apakah ada diskon tertentu jika 2 peserta yang didaftarkan?",
          answer: `Tidak ada potongan harga ataupun diskon bagi nasabah. Nasabah harus mengikuti skema penempatan yang berlaku.`,
        },
        {
          question:
            "Mengapa nasabah harus memberikan konfirmasi serta memberikan izin pemberian data peserta kepada NUS? ",
          answer: `Data peserta dibutuhkan untuk proses validasi dan kegiatan belajar mengajar selama program berlangsung.`,
        },
      ],
    },
    {
      id: 3,
      name: "Keberangkatan",
      faqs: [
        {
          question:
            "Apakah memungkinkan jika saya memilih Paket 2 (dengan penerbangan) namun memilih untuk penerbangan dilakukan dari kota asal daerah saya (di luar Jabodetabek)?",
          answer: `Tidak. Penerbangan hanya bisa dilakukan dari Bandara Udara Internasional Soekarno Hatta, Kota Tangerang dengan jadwal penerbangan yang telah ditentukan oleh pelaksana Program.`,
        },
        {
          question:
            "Apakah memungkinkan jika saya menjadwalkan ulang (reschedule) penerbangan saya?",
          answer: `Penjadwalan ulang penerbangan tidak dapat dilakukan. Jika Anda memilih Program Paket 2, Anda wajib untuk mengikuti jadwal penerbangan yang telah ditentukan.`,
        },
        {
          question:
            "Bagaimana jika paspor calon peserta yang saya ajukan dan/atau saya akan jatuh tempo kurang dari 6 bulan ketika Program berlangsung?",
          answer: `Calon peserta dan/atau Anda harus memperpanjang paspor terlebih dahulu sebelum proses pendaftaran. Paspor yang masa aktifnya kurang dari 6 bulan ketika Program berlangsung tidak dapat menjadi syarat pendaftaran yang sah.`,
        },
      ],
    },
    {
      id: 4,
      name: "Kelangsungan Program",
      faqs: [
        {
          question:
            "Dimana kah peserta akan menginap di Singapura selama OCBC Summer Camp berlangsung?",
          answer: `Peserta akan menginap di hotel bintang 4 yang ditentukan oleh pihak penyelenggara.`,
        },
        {
          question:
            "Apa maskapai penerbangan yang akan digunakan jika saya memilih Paket 2?",
          answer: `Penerbangan peserta dari Jakarta ke Singapura, maupun sebaliknya, akan menggunakan Singapore Airlines kelas ekonomi.`,
        },
        {
          question:
            "Apakah saja yang akan didapatkan peserta selama OCBC Summer Camp berlangsung?",
          answer: `Peserta akan mendapatkan materi pembelajaran, kegiatan pembelajaran interaktif, transportasi selama program, tempat penginapan, sarapan dan makan siang.`,
        },
        {
          question:
            "Apakah peserta akan mendapatkan bukti penyelesaian OCBC Summer Camp?",
          answer: `Peserta akan mendapatkan bukti penyelesaian OCBC Summer Camp dalam bentuk e-certificate jika peserta Program berhasil mengikuti semua rangkaian kegiatan dan memenuhi persyaratan yang ditentukan oleh NUS. E-certificate akan dibagikan setelah program berlangsung.`,
        },
        {
          question: "Peralatan apa saja yang harus siapkan oleh peserta?",
          answer: `Alat tulis, laptop, pakaian, dan obat pribadi.`,
        },
        {
          question:
            "Siapakah pihak yang akan menemani dan bertanggung jawab atas peserta selama kegiatan Program berlangsung?",
          answer: `Pihak penyelenggara dari National University of Singapore (NUS) akan membimbing peserta selama rangkaian kegiatan workshop berlangsung. Untuk kegiatan diluar workshop akan didampingi oleh Vendor Penyelenggara.`,
        },
      ],
    },
  ];

  const handleNextTab = () => {
    // Logic to scroll to the next tab
    // You may need to adjust the scroll distance based on your tab width
    tabsContainerRef.current.scrollLeft += 100; // Adjust the value as needed
  };

  const handlePrevTab = () => {
    // Logic to scroll to the previous tab
    // You may need to adjust the scroll distance based on your tab width
    tabsContainerRef.current.scrollLeft -= 100; // Adjust the value as needed
  };

  useEffect(() => {
    // Logic to snap to the nearest tab when a tab is clicked
    const container = tabsContainerRef.current;
    const selectedTabElement = container.querySelector(
      `[data-tab-id="${selectedTab}"]`
    );
    if (selectedTabElement) {
      container.scrollLeft =
        selectedTabElement.offsetLeft - container.offsetLeft;
    }
  }, [selectedTab]);

  return (
    <LayoutV2 padding="0px">
      <div
        className="flex flex-col justify-start items-center w-full"
        // style={{
        //   paddingLeft: screenWidth < 1024 ? "35px" : "60px",
        //   paddingRight: screenWidth < 1024 ? "35px" : "60px",
        // }}
      >
        <p
          className={`text-center font-bold ${
            screenWidth < 1024 ? "text-2xl" : "text-60px"
          } text-black`}
          style={{
            lineHeight: screenWidth < 1024 ? "28.8px" : "72px",
            marginTop: screenWidth < 1024 ? "59px" : "177px",
            paddingLeft: screenWidth < 1024 ? "35px" : "60px",
            paddingRight: screenWidth < 1024 ? "35px" : "60px",
          }}
        >
          Frequently Asked {screenWidth < 1024 && <br />}Questions <br /> Summer
          Camp
        </p>
        <div
          className="flex w-full h-auto relative"
          style={{
            paddingLeft: screenWidth < 1024 ? "35px" : "60px",
            paddingRight: screenWidth < 1024 ? "35px" : "60px",
          }}
        >
          {screenWidth < 1024 && (
            <ChevronLeftIcon
              onClick={handlePrevTab}
              className="absolute left-3 top-10 w-6 h-auto text-black z-10"
            />
          )}
          {screenWidth < 1024 && (
            <ChevronRightIcon
              onClick={handleNextTab}
              className="absolute right-3 top-10 w-6 h-auto text-black z-10"
            />
          )}
          <div
            ref={tabsContainerRef}
            className="w-full h-auto flex flex-row justify-start items-center overflow-x-scroll"
            style={{
              marginTop: screenWidth < 1024 ? "31px" : "92px",
              gap: screenWidth < 1024 ? "7px" : "15px",
              scrollBehavior: 'smooth',
            }}
          >
            {faqTabs.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  setSelectedTab(item.id);
                }}
                className={`py-3 px-6 rounded-lg text-center font-semibold text-base ${
                  item.id === selectedTab ? "text-white" : "text-tosca2"
                } w-max h-auto whitespace-nowrap`}
                style={{
                  backgroundColor:
                    item.id === selectedTab
                      ? "rgba(67, 188, 205, 1)"
                      : "rgba(241, 243, 246, 1)",
                  lineHeight: "24px",
                }}
                data-tab-id={item.id}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <div
          className="flex flex-col justify-start items-center w-full gap-5 pb-5"
          style={{
            marginTop: screenWidth < 1024 ? "7px" : "29px",
            paddingLeft: screenWidth < 1024 ? "35px" : "60px",
            paddingRight: screenWidth < 1024 ? "35px" : "60px",
          }}
        >
          {faqTabs[selectedTab].faqs.map((item, index) => (
            <div key={index} className="w-full">
              <FAQAccordion question={item.question} answer={item.answer} />
            </div>
          ))}
        </div>
        <div
          className="w-full flex flex-col justify-start items-stretch"
          style={{
            maxWidth: screenWidth >= 1024 && "566px",
            paddingLeft: screenWidth < 1024 ? "35px" : "60px",
            paddingRight: screenWidth < 1024 ? "35px" : "60px",
          }}
        >
          <a
            href="https://ocbc-id.typeform.com/to/g2wj25QH"
            target="_blank"
            rel="noreferrer"
            className={`w-full py-3 rounded-lg bg-primaryColor text-center font-semibold ${
              screenWidth < 1024 ? "text-xs" : "text-base"
            } text-white`}
            style={{
              lineHeight: screenWidth < 1024 ? "18px" : "24px",
              marginTop: screenWidth < 1024 ? "85px" : "100px",
            }}
          >
            Saya Tertarik!
          </a>
          <p
            className={`text-center font-bold ${
              screenWidth < 1024 ? "text-2xl" : "text-60px"
            } text-black mt-16`}
            style={{
              lineHeight: screenWidth < 1024 ? "28.8px" : "72px",
              letterSpacing: "-1.2px",
            }}
          >
            Ingin tahu lebih <br />
            lanjut?
          </p>
          <div className="flex flex-row justify-center items-center gap-2 w-full">
            {knowMore.map((item, index) => (
              <a
                rel="noreferrer"
                href={item.redirect}
                target="_blank"
                key={index}
                className="flex flex-row items-center justify-center w-full bg-transparent border-2 border-primaryColor rounded-lg cursor-pointer"
                style={{
                  marginTop: "95px",
                  paddingTop: "14px",
                  paddingBottom: "14px",
                  gap: "10px",
                }}
              >
                <img src={item.icon} className="h-5 w-5" />
                {screenWidth >= 1024 && (
                  <p
                    className="text-left font-semibold text-base text-black"
                    style={{ lineHeight: "24px" }}
                  >
                    {item.name}
                  </p>
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div
        className="flex flex-row items-end justify-between w-full px-5 relative tablet:h-24"
        // className="flex flex-row items-end justify-between w-full px-5 tablet:absolute tablet:bottom-0 tablet:pl-8 tablet:pr-8 mac:pl-15 mac:pr-7"
        style={{ marginTop: "29px" }}
      >
        <img
          src="/hand_illu_left.svg"
          className="tablet:absolute tablet:left-14 tablet:w-26 mac:left-15 mac:w-36"
          alt="hand illu left"
        />
        {screenWidth >= 1024 ? (
          <img
            src="/hand_illu_mid0.svg"
            className="tablet:absolute tablet:left-36 tablet:w-24 mac:left-40 mac:w-32"
            alt="hand illu mid0"
          />
        ) : (
          <div></div>
        )}
        {screenWidth >= 1024 ? (
          <div className="flex w-full"></div>
        ) : (
          <div></div>
        )}
        <img
          src="/hand_illu_mid.svg"
          className="tablet:absolute tablet:right-40 tablet:w-30 mac:right-42 mac:w-42"
          alt="hand illu mid"
        />
        <img
          src="/hand_illu_right.svg"
          className="tablet:absolute tablet:right-10 tablet:w-28 mac:right-7 mac:w-38"
          alt="hand illu right"
        />
      </div>
    </LayoutV2>
  );
}
