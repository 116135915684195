import Layout from "../components/Layout";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useLocation } from "react-router-dom";
import LayoutV2 from "../components/Layout_V2";

const slugMap = {
  "#umum": 0,
  "#akun": 1,
  "#kelas": 2,
  "#kemitraan": 3,
};

const FAQ = () => {
  const location = useLocation();
  let activeIndex = 0;
  const hash = (location.hash ?? "").toLowerCase();
  if (typeof slugMap[hash] !== "undefined" && hash in slugMap) {
    activeIndex = slugMap[hash];
  }
  return (
    <LayoutV2>
      <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20 text-center">
        <h2 className="h2 mb-16">Frequently Asked Questions (FAQ)</h2>
        <Tabs defaultIndex={activeIndex}>
          <TabList className="flex flex-col hd:flex-row text-base gap-4 justify-center mt-10">
            <Tab>UMUM</Tab>
            <Tab>AKUN</Tab>
            <Tab>KELAS</Tab>
            <Tab>KEMITRAAN</Tab>
          </TabList>
          <TabPanel>
            <div className="grid grid-cols-1 hd:grid-cols-2 gap-6 mt-7">
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Apa itu exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 overflow-hidden text-gray-400">
                  Exploration adalah platform persiapan kuliah bagi siswa-siswi
                  SMA (atau sederajat), yang menawarkan pengalaman mendalam,
                  dengan tujuan untuk memberikan paparan terhadap berbagai
                  perguruan tinggi dan program studi favorit dalam dan luar
                  negeri.
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Siapa saja yang bisa tergabung dalam exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Platform ini terbuka untuk umum. Jadi, bagi kamu yang sedang
                  dalam proses mencari perguruan tinggi dan program studi, baik
                  yang sedang duduk di bangku SMA (atau sederjat), SMP (atau
                  sederajat), atau sudah menjadi alumni, silahkan bergabung
                  dalam exploration!
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana cara agar selalu bisa mendapatkan info terbaru dari
                  exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Kamu dapat mengikuti exploration di Instagram agar tidak
                  ketinggalan informasi dan kegiatan menarik lainnya di
                  @exploration.id
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana cara melakukan pengaduan konsumen?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Jika kamu memiliki kendala atau pertanyaan lebih lanjut, kamu
                  bisa terhubung dengan tim exploration dengan mengirimkan email
                  ke support@explorationid.com dengan subject 'Pengaduan
                  Konsumen'
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="grid grid-cols-1 hd:grid-cols-2 gap-6 mt-7">
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Mengapa aku harus memiliki akun di exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 overflow-hidden text-gray-400">
                  Dengan memiliki akun, kamu dapat mendaftarkan diri ke berbagai
                  kegiatan yang diadakan di exploration. Selain itu, kamu akan
                  mendapatkan update secara berkala, agar tidak melewatkan
                  kesempatan untuk mempelajari pererguruan tinggi dan program
                  studi impianmu
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana cara membuat akun di exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Kamu dapat membuat akun di exploration dengan cara mengklik
                  tombol 'Registrasi' di pojok kanan atas laman, dan mengisi
                  informasi yang dibutuhkan. Dalam 1-3 hari, kamu akan menerima
                  email dari exploration yang berisikan link untuk mulai
                  aktivasi akun kamu
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana cara masuk ke akun exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Untuk masuk ke akun exploration, kamu dapat mengklik tombol
                  'Masuk' di pojok kanan atas laman, dan mengisi alamat email
                  dan password yang kamu gunakan untuk membuat akun
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana jika aku memiliki kendala dalam pembuatan dan
                  pengelolaan akun?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Jika kamu memiliki kendala atau pertanyaan lebih lanjut, kamu
                  bisa terhubung dengan tim exploration dengan mengirimkan email
                  ke support@explorationid.com dengan subject 'Pembuatan dan
                  Pengelolaan Akun'
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="grid grid-cols-1 hd:grid-cols-2 gap-6 mt-7">
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Apa saja yang bisa didapatkan dengan mengikuti kelas di
                  exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 overflow-hidden text-gray-400">
                  Kamu akan mendapatkan informasi yang lebih mendalam mengenai
                  berbagai perguruan tinggi dan program studi dengan cara yang
                  unik dan interaktif. Selain itu, kamu juga dapat terhubung
                  langsung dengan praktisi dan ahli
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Perguruan tinggi apa saja yang mengadakan kelas di
                  exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Saat ini, jumlah perguruan tinggi yang bekerja sama dengan
                  exploration terus bertambah. Kamu dapat melihat daftar Mitra
                  perguruan tinggi exploration pada laman utama exploration
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana cara mendaftarkan diri ke dalam kelas di
                  exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Masuk ke akun exploration, klik tombol 'Eksplorasi Kelas',
                  lalu pilih kelas yang ingin kamu ikuti. Setelah pendaftaran
                  berhasil, kamu akan mendapatkan notifikasi melalui email dan
                  kelas tersebut akan masuk ke daftar 'Kelas Mendatang' dalam
                  laman profilmu
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Berapa biaya yang perlu dikeluarkan untuk mengikuti kelas di
                  exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Kelas yang diadakan di exploration GRATIS, tidak dipungut
                  biaya apapun. Jadi, kamu dapat mempelajari berbagai perguruan
                  tingggi dan program studi sebanyak-banyaknya, tanpa
                  mengeluarkan uang sepeserpun!
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="grid grid-cols-1 hd:grid-cols-2 gap-6 mt-7">
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Apa itu Mitra exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 overflow-hidden text-gray-400">
                  Mitra exploration adalah institusi pendidikan, baik sekolah
                  maupun perguruan tinggi, yang bekerja sama dengan exploration
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana saya bisa mendaftarkan institusi pendidikan saya
                  sebagai Mitra?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Anda dapat mendaftarkan institusi pendidikan Anda dengan cara
                  mengisi formulir pendaftaran Mitra di laman utama exploration.
                  Dalam 1-3 hari, Anda akan dihubungi oleh tim exploration untuk
                  proses lebih lanjut
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Apa saja keuntungan menjadi Mitra exploration?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Bagi sekolah, institusi Anda akan berkesempatan untuk
                  menghubungkan siswa-siswi Anda langsung dengan berbagai
                  perguruan tinggi ternama. Bagi perguruan tinggi, institusi
                  Anda akan berkesempatan untuk menjangkau calon mahasiswa Anda
                  dengan cara yang unik
                </div>
              </div>
              <div className="grid-rows-2 shadow rounded-lg p-5">
                <div className="text-left mt-2 hd:h-12 overflow-hidden font-bold">
                  Bagaimana cara mendapatkan info lebih lanjut terkait
                  kemitraan?
                </div>
                <div className="text-left mt-2 hd:h-32 text-gray-400">
                  Jika Anda ingin mendapatkan info lebih lanjut terkait
                  kemitraan, Anda dapat menghubungi tim exploration melalui
                  WhatsApp di nomor (+62) 813-8900-8901
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </LayoutV2>
  );
};

export default FAQ;
