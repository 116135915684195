export default function ButtonRekJurusanTMB({
  item,
  onClick,
  majorsData,
  screenWidth,
}) {
  return (
    <button
      onClick={onClick}
      className="flex flex-row sm:flex-col gap-5 w-full h-full items-center justify-start sm:justify-between left-0 bg-white"
      style={{
        height: screenWidth >= 1024 ? "214.93px" : "53.57px",
        width: screenWidth >= 1024 && "215.22px",
        gap: "10px",
        padding: "15px 26px 20px 26px",
        borderRadius: "10px",
        backgroundImage: `url(${
          screenWidth >= 1024
            ? "/bg_tmb_result_pilihan_jurusan_white_desktop.svg"
            : "/bg_tmb_result_pilihan_jurusan_white.svg"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div
        className=""
        style={{
          borderRadius: screenWidth >= 1024 ? "11.98px" : "5px",
          width: screenWidth >= 1024 ? "110px" : "44px",
          height: screenWidth >= 1024 ? "110px" : "44px",
        }}
      >
        <img
          src={
            majorsData[
              majorsData.findIndex((icon) => icon.major_id === item.id)
            ]?.icon_url
          }
          alt="icon_jurusan"
          className="w-full h-full flex justify-center items-center object-contain"
          style={{
            borderRadius: screenWidth >= 1024 ? "11.98px" : "5px",
          }}
        />
      </div>
      <div
        className="w-full flex items-center justify-start sm:justify-center"
        style={{ height: "60px" }}
      >
        <p
          className="text-left font-bold text-sm sm:text-center sm:text-base text-primaryColor line-clamp-3 overflow-ellipsis"
          style={{
            lineHeight: screenWidth >= 1024 ? "19.2px" : "16.8px",
          }}
        >
          {item.name}
        </p>
      </div>
    </button>
  );
}
