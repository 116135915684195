export default function StepOne({
  onNext
}) {
  return (
    <>
      {/* Greeting message */}
      <div className="flex">
        <div className="mr-2 flex-shrink-0">
          <img src="/home-logo-tmb.svg" alt="" className="h-15 w-15 object-contain" />
        </div>
        <div className="bg-white rounded-lg px-4 sm:px-10 py-2 sm:py-4">
          <p className="text-sm sm:text-lg">
            Hi sobat, selamat datang di sesi konsultasi minat bersama ku Serra, konsultan minat terbaikmu sepanjang masa!
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <img
          src="/illustration_step_one.svg"
          alt=""
          className="object-cover"
        />
      </div>
      <button onClick={onNext} className="flex sm:w-56 w-full self-end justify-center text-base text-white font-semibold bg-primaryColor px-6 py-3 rounded-lg">
        Hi salam kenal Serra!
      </button>
    </>
  )
}