import { useRef, useState } from "react";
import { api, apiAnom, notifError } from "../utils/api";
import SignInCarouselSlides from "../components/SignInCarouselSlides";
import LayoutV2 from "../components/Layout_V2";
import Slider from "react-slick";
import charCardInfo from "./CharCardInfo.json";
import FormField from "../components/FormField";
import { InputPassword } from "../components/InputPassword";
import ButtonV2 from "../components/ButtonV2";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";

export default function ChangePasswordV2() {
  const navigate = useNavigate();
  const charCardsRef = useRef();
  const params = new URLSearchParams(window.location.search);
  const setAuth = useSetRecoilState(authState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target[0].value !== e.target[1].value) {
      window.Swal.fire({
        text: "Password tidak sama",
        icon: "warning",
        confirmButtonText: "Tutup",
      });
    } else {
      setIsSubmitting(true);
      apiAnom
        .post("/student-b2b/verification", {
          email: params.get("email"),
          password: e.target[0].value,
          username: params.get("username"),
        })
        .then((res) => {
          Cookies.set("user.token", res.data.data.request_token, {
            sameSite: "None",
            secure: true,
            expires: 1,
          });
          
          window.Swal.fire({
            text: "Pembuatan password berhasil",
            icon: "success",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              api
                .get("/me", {
                  headers: {
                    Authorization: "Bearer " + res.data.data.request_token,
                  },
                })
                .then((authRes) => {
                  setAuth({ ...authRes.data.data, isAuth: true });
                  navigate("/profile");
                }) 
                .catch((err) => {
                  setIsSubmitting(false);
                  notifError(err);
                });
            }
          });
        })
        .catch((err) => {
          setIsSubmitting(false);
          notifError(err);
        });
    }
  }

  const charCardsSettings = {
    arrows: false,
    className: "center",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
  };

  const charCards = charCardInfo.map((item, index) => (
    <SignInCarouselSlides
      key={index}
      backgroundColor={item.backgroundColor}
      iconUrl={
        window.innerWidth < 1024 ? item.iconUrlMobile : item.iconUrlDesktop
      }
    />
  ));

  return (
    <LayoutV2 padding="0">
      <div
        className="w-full sm:flex sm:justify-end relative"
        style={{
          padding:
            window.innerWidth < 1024 ? "27px 35.2px 0px 25px" : "32px 71px",
          minHeight: window.innerWidth < 1024 ? 661 : 514,
          height: window.innerHeight - (window.innerWidth < 1024 ? 210 : 145),
        }}
      >
        <div className="w-screen h-full absolute top-0 left-0">
          <Slider ref={charCardsRef} {...charCardsSettings}>
            {charCards.map((item) => item)}
          </Slider>
        </div>
        <div
          className="w-full h-full flex flex-col justify-start items-stretch relative z-10"
          style={{
            gap: "15px",
            width: window.innerWidth >= 1024 && "485px",
          }}
        >
          <p className="text-left text-white">
            <span
              className="font-bold text-2xl"
              style={{ lineHeight: "30.24px" }}
            >
              Selamat Datang
            </span>
            <br />
            <span
              className="font-medium text-base"
              style={{ lineHeight: "24px" }}
            >
              Raih masa depanmu bersama Exploration!
            </span>
          </p>
          <div
            className="w-full flex flex-col bg-white p-6 gap-5"
            style={{
              borderRadius: "12.33px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "30.24px" }}
            >
              Ganti Password
            </p>
            <form onSubmit={onSubmit} className="w-full flex flex-col gap-5">
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full mb-4 px-3">
                  <label
                    className="block text-gray-800 text-sm mb-1"
                    style={{fontSize: "16px"}}
                    htmlFor="password"
                  >
                    Buat password
                  </label>
                  <InputPassword
                      id="password"
                      name="password"
                      className="form-input w-full text-gray-800"
                      placeholder="Masukkan password baru"
                      rounded={true}
                      required
                  />
                </div>
                <div className="w-full px-3">
                  <label
                    className="block text-gray-800 text-sm mb-1"
                    style={{fontSize: "16px"}}
                    htmlFor="password-confirm"
                  >
                    Konfirmasi password
                  </label>
                  <div>
                    <InputPassword
                        id="password-confirm"
                        name="password-confirm"
                        className="form-input w-full text-gray-800"
                        placeholder="Konfirmasi password baru"
                        rounded={true}
                        required
                    />
                  </div>
                </div>
              </div>
              <ButtonV2 type="submit" title="Buat Password" disabled={isSubmitting} />
            </form>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
