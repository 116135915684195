import { ArrowRightIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function HomeCardExplore({
  bgColor,
  logoBGColor,
  logo,
  destination,
  title,
  description,
}) {
  const [onHover, setOnHover] = useState(false);

  return (
    <Link
      to={destination}
      className={`flex flex-col p-8 tablet:p-6 fhd:p-8 w-full rounded-xl shadow-xl ${
        onHover === true ? "tablet:shadow-custom2" : "tablet:shadow-lg"
      }  ${bgColor}`}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className="flex flex-row items-center justify-between">
        <div
          className={`flex items-center justify-center w-14 h-14 ${
            bgColor === "bg-redTM" ? "" : "px-2 py-3.5"
          } rounded-lg tablet:w-10 tablet:h-10 mac:w-14 mac:h-14`}
          style={{ backgroundColor: logoBGColor }}
        >
          <img src={logo} alt="logo-jpg" className="w-full object-contain" />
        </div>
        <ArrowRightIcon
          className="w-5 h-5"
          color={`${
            bgColor === "bg-redTM"
              ? "rgba(255, 255, 255, 1)"
              : "rgba(23, 23, 23, 0.4)"
          }`}
        />
      </div>
      <div
        className={`text-left text-xl ${
          bgColor === "bg-redTM" ? "text-white" : "text-black1"
        } font-bold w-full mt-6 tablet:mt-4 tablet:text-base mac:text-2xl`}
      >
        {title}
      </div>
      <div
        className={`text-left text-sm ${
          bgColor === "bg-redTM" ? "text-white" : "text-black1"
        } w-full mt-2 tablet:mt-1 tablet:text-xs mac:text-base`}
      >
        {`${description}`}
      </div>
    </Link>
  );
}
