import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useState, useEffect, Fragment, useRef } from "react";

import { authState } from "../../atoms/authState";
import { apiAnom, api } from "../../utils/api";
import ReactSelect from "react-select";
import * as _ from "lodash";
import PageLoading from "../PageLoading";
import Layout from "../../components/Layout";
import Button from "../../components/base/Button";
import Pagination from "../../components/pagination/Pagination";
import DropdownItem from "../../components/DropdownItem";
import CardMajor from "../../components/CardMajor";
import HomeCardMajor from "../../components/HomeCardMajor";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import Slider from "react-slick";
import { filter } from "lodash";
import Headerv2 from "../../components/Header_v2";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import HomeCardMajorV3 from "../../components/HomeCardMajorV3";
import LayoutV2 from "../../components/Layout_V2";
import Footer from "../../components/Footer";

let pageSize = 16;

export default function EksplorasiJurusanV3() {
  const sliderRef = useRef();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBidang, setFilterBidang] = useState(new Set());
  const [filterKarakter, setFilterKarakter] = useSearchParams();
  const karakter = filterKarakter.get("karakter");
  const navigate = useNavigate();
  const [currentSliderPage, setCurrentSliderPage] = useState(1);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [fosList, setFosList] = useState([]);
  const [listData, setListData] = useState();
  const [topMajors, setTopMajors] = useState([]);
  const [majorNames, setMajorNames] = useState();
  const [keyword, setKeyword] = useState();
  const [majorSuggestion, setMajorSuggestion] = useState(false);
  const [isFavChanged, setIsFavChanged] = useState({});
  const [favMajorTotalSlides, setFavMajorTotalSlides] = useState();
  const [favMajorCurrentSlideIndex, setFavMajorCurrentSlideIndex] = useState(1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  isMenuOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.title = "Explore Jurusan";
  }, []);

  useEffect(() => {
    const getMajors = () => {
      _api
        .post("/university/major/search", {
          name: keyword,
          field_of_studies: _.map(Array.from(filterBidang), "id"),
          tmbs: [karakter ?? ""],
          page: currentPage,
          limit: pageSize,
          sort_direction: "DESC",
        })
        .then((res) => {
          setListData(res.data.data);
          setMajorNames(res.data.data.majors.map((item) => item.name));
        })
        .catch((_) => {
          setListData([]);
        });
    };
    getMajors();
  }, [auth.isAuth, currentPage, filterBidang, karakter, keyword]);

  useEffect(() => {
    _api
      .get("/major/list/top-15-most-fav-by-students")
      .then((res) => {
        setTopMajors(res.data.data.majors);
        setFavMajorTotalSlides(Math.ceil(res.data.data.majors.length / 3));
      })
      .catch((_) => {
        setTopMajors(_);
      });
  }, [_api, auth.isAuth]);

  useEffect(() => {
    const getFieldOfStudy = () => {
      _api
        .get("/field-of-study")
        .then((res) => {
          setFosList(res.data.data);
        })
        .catch((_) => {
          setFosList(_);
        });
    };
    getFieldOfStudy();
  }, [_api, auth.isAuth]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  const favMajorSetting = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  const charType = (char) => {
    switch (char) {
      case "R":
        return "The Doers";
      case "I":
        return "The Thinkers";
      case "A":
        return "The Creators";
      case "S":
        return "The Helpers";
      case "E":
        return "The Persuaders";
      case "C":
        return "The Organizer";
      default:
        return "Unique";
    }
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (provided, state) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: "1 !important",
      },
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
    }),
  };

  const onDeleteBidang = (e, bidang) => {
    e.preventDefault();
    e.stopPropagation();
    const fb = new Set(filterBidang);
    fb.delete(bidang);
    setFilterBidang(fb);
    setCurrentPage(1);
  };

  const onDeleteKarakter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterKarakter("");
    setCurrentPage(1);
  };

  const onSearchByBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _api
      .post("/university/major/search", {
        name: keyword,
        field_of_studies: _.map(filterBidang, "id"),
        page: 1,
        limit: pageSize,
        sort_direction: "DESC",
      })
      .then((res) => {
        setListData(res.data.data);
        setCurrentPage(1);
      })
      .catch((_) => {
        setListData([]);
      });
  };

  function handleFilterBidangMobile(e, item) {
    const checked = e.currentTarget.checked;
    const fb = new Set(filterBidang);
    if (checked) {
      fb.add(item);
    } else {
      fb.delete(item);
    }
    setFilterBidang(fb);
    setCurrentPage(1);
  }

  if (!listData || !topMajors) return <PageLoading />;
  return (
    <div className="w-full flex flex-col justify-start items-stretch overflow-x-hidden">
      {screenWidth < 1024 ? (
        <LayoutV2
          withoutBody={true}
          withoutFooter={true}
          noHeight={true}
          showMenu={(e) => {
            setIsMenuOpen(!e);
          }}
        />
      ) : (
        <Headerv2 screenWidth={screenWidth} />
      )}
      <div className="w-full bg-white sticky top-0 z-30">
        <div className="flex flex-row w-full py-3 px-6 tablet:px-16 mac:px-36 fhd:px-110 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg">
          <form className="relative flex flex-row divide-y-2 remove-input-txt-border w-full">
            <label className="flex flex-row items-center pl-5 tablet:pl-0 tablet:w-2/3">
              <div className="pr-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1115 0C4.80323 0 0.5 4.20819 0.5 9.39926C0.5 14.5903 4.80323 18.7985 10.1115 18.7985C12.3819 18.7985 14.4684 18.0287 16.113 16.7415L19.2371 19.7886L19.3202 19.8586C19.6102 20.0685 20.0214 20.0446 20.2839 19.7873C20.5726 19.5043 20.572 19.0459 20.2825 18.7636L17.1952 15.7523C18.7649 14.0794 19.7231 11.8487 19.7231 9.39926C19.7231 4.20819 15.4198 0 10.1115 0ZM10.1115 1.44774C14.6022 1.44774 18.2426 5.00776 18.2426 9.39926C18.2426 13.7908 14.6022 17.3508 10.1115 17.3508C5.62086 17.3508 1.98044 13.7908 1.98044 9.39926C1.98044 5.00776 5.62086 1.44774 10.1115 1.44774Z"
                    fill="#171717"
                  />
                </svg>
              </div>
              <input
                type="text"
                className="w-2/3 sm:flex-2 form-input border-none pb-4"
                style={{
                  maxHeight: "38px",
                }}
                placeholder="Cari nama jurusan"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  setMajorSuggestion(true);
                }}
              />
            </label>
            {screenWidth >= 1024 && (
              <div className="w-1/3 relative" style={{ right: "-6px" }}>
                <ReactSelect
                  styles={styles}
                  options={fosList}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  menuColor
                  getOptionLabel={(data) => data["name"]}
                  getOptionValue={(data) => data["id"]}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Bidang Studi"
                  onChange={(v) => {
                    const fb = new Set(v);
                    setFilterBidang(fb);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterBidang)}
                />
              </div>
            )}
          </form>
          {screenWidth < 1024 && (
            <div className="flex items-center">
              <button
                type="button"
                onClick={() => {
                  openModal();
                  setFilterBidang(new Set());
                }}
                className="pr-6"
              >
                <img src="/filter-icon.svg" />
              </button>
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-90" onClose={() => {}}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className="fixed inset-0"
                      style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
                    />
                  </Transition.Child>
                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="h-screen w-screen flex items-center justify-center p-4">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="flex w-full h-full rounded-2xl bg-white p-6 text-left align-middle shadow-xl">
                          <div className="flex h-full w-full flex-col">
                            <button type="button" onClick={closeModal}>
                              <MdClose size={24} color="rgba(0, 0, 0, 0.54)" />
                            </button>
                            <div className="flex flex-col gap-4 divide-y-2 mt-10">
                              <div className="w-full items-start justify-center">
                                <p className="font-bold text-base text-black1 text-left pb-2">
                                  Bidang Studi
                                </p>
                              </div>
                            </div>
                            <div className="h-3/5 overflow-y-auto">
                              {fosList.map((item, index) => (
                                <div
                                  key={item.id}
                                  className="flex items-center mb-4"
                                >
                                  <label
                                    htmlFor={item.name}
                                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    <input
                                      id={item.name}
                                      type="checkbox"
                                      value={item.id}
                                      onChange={(e) => {
                                        handleFilterBidangMobile(e, item);
                                      }}
                                      className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    ></input>
                                    <span className="ml-2 text-left font-normal text-sm text-black1">
                                      {item.name}
                                    </span>
                                  </label>
                                </div>
                              ))}
                            </div>
                            <div className="flex h-1/3 items-end">
                              <button
                                onClick={() => {
                                  closeModal();
                                }}
                                className="flex items-center justify-center w-full h-12 rounded-lg py-3 bg-primaryColor mt-3"
                              >
                                <p className="font-semibold text-base text-center text-white">
                                  Terapkan filter pencarian
                                </p>
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          )}
          {majorSuggestion && keyword.length > 0 && (
            <div className="absolute top-12 z-10 flex flex-col items-start w-full overflow-auto shadow-lg px-2 bg-white">
              {majorNames.map((item, index) => (
                <Button
                  size="small"
                  variant="link"
                  key={`major ${index}`}
                  className="font-normal text-sm text-black1 text-left w-full py-3 justify-start"
                  onClick={() => {
                    setMajorSuggestion(false);
                    setKeyword(item);
                  }}
                >
                  {item}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full items-center">
        {!keyword && (
          <div
            className="relative flex flex-col w-full bg-tosca2 mac:px-18 fhd:px-0"
            style={{
              maxHeight: "626px",
              paddingBottom: screenWidth < 1024 ? "26.25px" : "25px",
            }}
          >
            <img
              className="absolute h-full top-0 right-0 object-cover"
              src="/bg-bubble-tosca.svg"
            />
            <div className="relative pl-4 tablet:pl-18 z-10">
              <p
                className="text-left font-bold text-white"
                style={{
                  fontSize: screenWidth < 1024 ? "26px" : "42px",
                  lineHeight: screenWidth < 1024 ? "28.08px" : "45.36px",
                  letterSpacing: screenWidth < 1024 ? "-0.87px" : "-0.71px",
                  marginTop: screenWidth < 1024 ? "28px" : "29px",
                }}
              >
                Jurusan paling diminati
              </p>
              {screenWidth < 1024 ? (
                <div className="flex flex-row overflow-x-auto mt-4">
                  {topMajors?.map((item, index) => {
                    return (
                      <div key={index} style={{ marginRight: "26px" }}>
                        <HomeCardMajor
                          id={item.id}
                          majorData={item}
                          width="324.95px"
                          height="428.04px"
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mt-6 pr-13">
                  <Slider
                    ref={sliderRef}
                    {...favMajorSetting}
                    className="expJurusanMostFav"
                  >
                    {topMajors?.map((item, index) => {
                      return (
                        <HomeCardMajorV3
                          key={index}
                          id={item.id}
                          majorData={item}
                          forHomeSection={true}
                          width={
                            screenWidth < 1440
                              ? "273px"
                              : screenWidth < 1910
                              ? "310px"
                              : "430px"
                          }
                          height={
                            screenWidth < 1440
                              ? "290px"
                              : screenWidth < 1910
                              ? "317px"
                              : "437px"
                          }
                          screenWidth={screenWidth}
                        />
                      );
                    })}
                  </Slider>
                </div>
              )}
            </div>
            {screenWidth >= 1024 && topMajors?.length > 4 && (
              <div
                className="flex flex-row gap-2 items-center justify-center"
                style={{ marginTop: "22px" }}
              >
                <button
                  onClick={() => {
                    if (currentSliderPage === 2) {
                      sliderRef.current.slickPrev();
                    }
                    setCurrentSliderPage(1);
                  }}
                  className={`bg-white rounded-lg p-3 ${
                    currentSliderPage === 2 ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <ArrowLeftIcon
                    className="w-4 h-4 mac:w-5 mac:h-5"
                    color="rgba(23, 23, 23, 0.4)"
                  />
                </button>
                <button
                  onClick={() => {
                    if (currentSliderPage === 1) {
                      sliderRef.current.slickNext();
                    }
                    setCurrentSliderPage(2);
                  }}
                  className={`bg-white rounded-lg p-3 ${
                    currentSliderPage === 1 ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <ArrowRightIcon
                    className="w-4 h-4 mac:w-5 mac:h-5"
                    color="rgba(23, 23, 23, 0.4)"
                  />
                </button>
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col w-full items-start mt-4 ml-9">
          {Array.from(filterBidang).length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Bidang Studi:</p>
              {Array.from(filterBidang).map((bidang, index) => (
                <span
                  key={bidang["id"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{bidang["name"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteBidang(e, bidang)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-start w-full mb-4">
          {karakter !== null && (
            <div className="px-2 flex flex-row flex-wrap items-center tablet:justify-start tablet:w-full">
              <p className="text-xs text-black mr-3">Karakter:</p>
              <span className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900">
                <span> {` ${charType(karakter)}`}</span>
                <button
                  type="button"
                  className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                  onClick={(e) => onDeleteKarakter(e)}
                >
                  <span className="sr-only">Remove filter </span>
                  <svg
                    className="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </span>
            </div>
          )}
        </div>
        {!listData && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">Loading...</h3>
            </div>
          </div>
        )}
        {listData?.majors.length < 1 && listData?.majors && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">
                Pencarian data tidak di temukan
              </h3>
            </div>
          </div>
        )}
        <div className="px-0 tablet:px-16" style={{ paddingBottom: "30px" }}>
          <div
            className={
              screenWidth < 1200
                ? "w-full flex flex-col justify-start"
                : "w-full tablet:grid tablet:grid-cols-4"
            }
            style={{
              rowGap: "28.44px",
              columnGap: screenWidth >= 1280 && "15.91px",
            }}
          >
            {listData?.majors.map((item, index) => {
              return screenWidth < 1024 ? (
                <HomeCardMajor
                  key={index}
                  id={item.id}
                  majorData={item}
                  width="324.95px"
                  height="428.04px"
                />
              ) : (
                <HomeCardMajorV3
                  key={index}
                  id={item.id}
                  majorData={item}
                  forHomeSection={true}
                  width={
                    screenWidth < 1440
                      ? "273px"
                      : screenWidth < 1910
                      ? "310px"
                      : "430px"
                  }
                  height={
                    screenWidth < 1440
                      ? "290px"
                      : screenWidth < 1910
                      ? "317px"
                      : "437px"
                  }
                  screenWidth={screenWidth}
                />
              );
            })}
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={listData?.metadata.total_item}
          pageSize={listData?.metadata.limit}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <Footer />
    </div>
  );
}
