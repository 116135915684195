import { useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { rmAuthState } from "../atoms/rmAuthState";
import { Link, useNavigate } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

export default function MobileViewMenu({ setIsMenuOpen, isMenuOpen }) {
  const auth = useRecoilValue(authState);
  const rmAuth = useRecoilValue(rmAuthState);
  const navigate = useNavigate();
  const [isKampusOpen, setIsKampusOpen] = useState(false);
  const [isJurusanOpen, setIsJurusanOpen] = useState(false);
  const [isKelasOpen, setIsKelasOpen] = useState(false);

  const resetTMB = true;
  return (
    <div className="w-full h-full flex flex-1 flex-col justify-start items-stretch px-4">
      <div className="w-full h-full flex flex-col items-start pt-6 flex-1">
        <div className="flex flex-col items-start justify-start gap-8">
          <div
            className="flex flex-col"
            onClick={() => {
              setIsJurusanOpen(false);
              setIsKelasOpen(false);
              setIsKampusOpen(!isKampusOpen);
            }}
          >
            <div className="flex flex-row items-center justify-start gap-3 w-full">
              <p
                className={`text-left text-base ${
                  isKampusOpen === true ? "text-primaryColor" : "text-black1"
                } font-semibold relative`}
              >
                Tes Minat
                <img
                  src="/star-4point.svg"
                  className="absolute -right-4 -top-1 w-5 h-5"
                />
              </p>
              {isKampusOpen === true ? (
                <ChevronUpIcon className="h-5 w-auto text-primaryColor" />
              ) : (
                <ChevronDownIcon className="h-5 w-auto" />
              )}
            </div>
            {isKampusOpen === true && (
              <div className="flex flex-col mt-6 relative z-100">
                <button
                  onClick={() => {
                    navigate("/tes-minat", { state: { resetTMB } });
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  className="flex items-center w-full"
                >
                  <div className="flex flex-col items-start justify-center w-full">
                    <div className="font-bold text-sm text-left text-black1">
                      Tes Minat (RIASEC)
                    </div>
                    <div className="font-normal text-sm text-left text-black1 mt-2">
                      Temukan karakter dan rekomendasi jurusanmu yang sesuai!
                    </div>
                  </div>
                </button>
                <div className="h-0.5 w-full my-3 bg-gray-200"></div>
                <Link
                  to="/mini-quiz-ipa-ips"
                  className="flex items-center w-full"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <div className="flex flex-col items-start justify-center w-full">
                    <div className="font-bold text-sm text-left text-black1">
                      Mini Quiz IPA - IPS
                    </div>
                    <div className="font-normal text-sm text-left text-black1 mt-2">
                      Bingung untuk milih peminatan IPA atau IPS? Yuk cek
                      sekarang!
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div
            className="flex flex-col"
            onClick={() => {
              setIsKampusOpen(false);
              setIsKelasOpen(false);
              setIsJurusanOpen(!isJurusanOpen);
            }}
          >
            <div className="flex flex-row items-center justify-start gap-3 w-full">
              <p
                className={`text-left text-base ${
                  isJurusanOpen === true ? "text-primaryColor" : "text-black1"
                } font-semibold`}
              >
                Explore
              </p>
              {isJurusanOpen === true ? (
                <ChevronUpIcon className="h-5 w-auto text-primaryColor" />
              ) : (
                <ChevronDownIcon className="h-5 w-auto" />
              )}
            </div>
            {isJurusanOpen === true && (
              <div className="flex flex-col mt-6 relative z-100">
                <Link
                  to="/explore-jurusan"
                  className="flex items-center w-full"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <div className="flex flex-col items-start justify-center w-full">
                    <div className="font-bold text-sm text-left text-black1">
                      Explore Jurusan
                    </div>
                    <div className="font-normal text-sm text-left text-black1 mt-2">
                      Lihat informasi berbagai Jurusan dan temukan Jurusan
                      pilihanmu sekarang!
                    </div>
                  </div>
                </Link>
                <div className="h-0.5 w-full my-3 bg-gray-200"></div>
                <Link
                  to="/explore-kampus"
                  className="flex items-center w-full"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <div className="flex flex-col items-start justify-center w-full">
                    <div className="font-bold text-sm text-left text-black1">
                      Explore Kampus
                    </div>
                    <div className="font-normal text-sm text-left text-black1 mt-2">
                      Lihat informasi berbagai Kampus dan temukan Kampus
                      pilihanmu sekarang!
                    </div>
                  </div>
                </Link>
                <div className="h-0.5 w-full my-3 bg-gray-200"></div>
                <Link
                  to="/explore-kelas"
                  className="flex items-center w-full"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <div className="flex flex-col items-start justify-center w-full">
                    <div className="font-bold text-sm text-left text-black1">
                      Explore Kelas
                    </div>
                    <div className="font-normal text-sm text-left text-black1 mt-2">
                      Lihat informasi berbagai Kelas dan temukan Kelas pilihanmu
                      sekarang!
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
          {/* <Link
            to="/explore-summer-camp"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <div className="font-bold text-sm text-left text-black1 relative">
              Summer Camp
              <img
                src="/star-4point.svg"
                className="absolute -right-4 -top-1 w-5 h-5"
              />
            </div>
          </Link> */}
          <Link
            to="/explore-penerimaan"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <div className="text-left text-base text-black1 font-semibold relative">
              Admission
            </div>
          </Link>
        </div>
      </div>
      <div className="flex flex-col justify-end gap-7 w-full pb-3">
        <Link
          to={
            rmAuth.isAuth ? "/rm/signin" : !auth.isAuth ? "/signin" : "/profile"
          }
        >
          <button
            className="flex items-center justify-center w-full text-center text-base text-primaryColor font-semibold line-clamp-1"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {!auth.isAuth
              ? "Masuk"
              : `${auth.fname === "" ? "Lihat Akun" : auth.fname} ${
                  auth.lname === "" ? "" : auth.lname
                }`}
          </button>
        </Link>
        <Link
          to={
            rmAuth.isAuth ? "/rm/signin" : !auth.isAuth ? "/signup" : "/logout"
          }
        >
          <button
            className="flex items-center justify-center w-full text-center text-base text-white font-semibold bg-primaryColor px-6 py-3 rounded-lg"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {!auth.isAuth ? "Daftar" : "Keluar"}
          </button>
        </Link>
      </div>
    </div>
  );
}
