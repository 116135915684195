export const DIR_LECTURER_AVATAR = "ava-pembicara";
export const DIR_UNIVERSITY_LOGO = "logo";

export const getAssetBaseUrl = (suffix) => {
  suffix = suffix ?? "";
  if (!suffix.startsWith("/")) {
    suffix = `/${suffix}`;
  }
  return `${process.env.REACT_APP_ASSET_BASE_URL ?? ""}${suffix ?? ""}`;
};

export const isEmpty = (value) => {
  return (
    typeof value === "undefined" ||
    value === null ||
    value === "" ||
    value === 0
  );
};

export const checkImageURL = (URL) => {
  fetch(URL)
    .then((res) => {
      if (res.status === 404) {
        return "/avatar_default.svg";
      } else {
        return URL;
      }
    })
    .catch((err) => {
      return "/avatar_default.svg";
    });
};

export const buildAssetUrl = (value, baseUrl) => {
  if (isEmpty(value)) {
    return "";
  }
  if (isEmpty(baseUrl)) {
    return value;
  }
  if (value.startsWith("http:") || value.startsWith("https:")) {
    return value;
  }
  if (value.startsWith("/")) {
    value = value.substring(1);
  }
  return `${baseUrl}/${value}`;
};
