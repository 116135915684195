import { MdArrowBack } from "react-icons/md";

export default function MiniQuizIntro({
  onBack,
  onNext,
  screenWidth,
  standalone,
}) {
  return (
    <div className="flex flex-col w-full h-full items-center">
      <div
        className={`flex w-full h-auto flex-row ${
          standalone ? "justify-end" : "justify-between"
        } items-start relative z-50`}
        style={{
          marginTop: screenWidth < 1024 ? "46px" : "36px",
          paddingLeft: screenWidth < 1024 ? "35.38px" : "113.25px",
          paddingRight: screenWidth < 1024 ? "17px" : "60px",
        }}
      >
        <button hidden={standalone}>
          <MdArrowBack
            onClick={onBack}
            className="w-6 sm:w-10 h-auto"
            style={{ color: "white" }}
          />
        </button>
        <div
          className="h-auto flex flex-row justify-between items-center"
          style={{
            paddingTop: "5.29px",
            paddingBottom: "4.96px",
            paddingLeft: "19.86px",
            paddingRight: "7.23px",
            borderRadius: screenWidth < 1024 ? "22.66px" : "30px",
            borderWidth: "2px",
            borderColor: "rgba(255, 202, 45, 1)",
            gap: "9.14px",
            maxHeight: "53.33px",
          }}
        >
          <p className="text-left font-bold text-xs sm:text-base text-white">
            Estimasi Waktu Pengerjaan
          </p>
          <p
            className="text-center font-semibold text-xs sm:text-base text-black px-3 sm:px-4 sm:py-3 bg-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "22.66px",
            }}
          >
            2 menit
          </p>
        </div>
      </div>
      <div
        className="flex flex-col w-full h-auto text-left font-bold text-base text-white"
        style={{
          maxHeight: "201px",
          maxWidth: `${screenWidth < 1024 && "287px"}`,
          lineHeight: `${screenWidth < 1024 ? "20px" : "40px"}`,
          marginTop: screenWidth < 1024 ? "45px" : "12px",
        }}
      >
        <div className="flex w-full h-auto justify-center relative z-50">
          <p
            className="text-center font-bold text-base sm:text-xl text-white"
            style={{ lineHeight: screenWidth >= 1024 && "25.2px" }}
          >
            Mini Quiz
          </p>
          <div className="absolute -bottom-3">
            <img src="/illu_curved_underline.svg" />
          </div>
        </div>
        <div
          className="flex flex-col items-center text-left font-semibold text-base text-white relative z-30"
          style={{
            marginTop: "27.54px",
            lineHeight: "20.16px",
          }}
        >
          {screenWidth < 1024 ? (
            <>
              Yuk! Ikuti Mini Quiz terlebih dahulu untuk meyakinkan pilihan
              minat kamu!
            </>
          ) : (
            <p
              style={{
                maxWidth: "618px",
              }}
            >
              Yuk! Ikuti Mini Quiz terlebih dahulu untuk meyakinkan pilihan
              kamu!
            </p>
          )}
          <div
            className="flex justify-end mt-6 sm:mt-12"
            style={{
              width: screenWidth < 1024 ? "100%" : "618px",
            }}
          >
            <button
              onClick={onNext}
              className="flex items-center justify-center text-base text-black font-semibold relative z-30"
              style={{
                background: "rgba(255, 202, 45, 1)",
                lineHeight: "20.16px",
                borderRadius: "7.51px",
                minHeight: "36px",
                maxHeight: "36px",
                minWidth: "132px",
                minWidth: "132px",
              }}
            >
              Mulai
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
