export default function CharCard({
  isRotated,
  charTitle,
  character,
  nickName,
}) {
  const charTypeIllustration = (type) => {
    switch (type) {
      case "R":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Doers.png";
      case "I":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Thinkers.png";
      case "A":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Creators.png";
      case "S":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Helpers.png";
      case "E":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Persuaders.png";
      case "C":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Organizer.png";
      default:
        return "Unique";
    }
  };

  return (
    <img
      alt="char illu"
      src={charTypeIllustration(character)}
      className={`${
        isRotated
          ? "transform -rotate-8 w-3/4 h-auto mt-16 ml-9 tablet:w-max tablet:h-max tablet:mt-0 tablet:ml-0"
          : "transform rotate-0"
      }`}
    />
  );

  // return (
  //   <div
  //     className={`relative w-68 h-94 flex flex-col items-center justify-top py-7 rounded-20px transform ${
  //       isRotated === false ? "mt-8 tablet:mt-0" : "-rotate-8 ml-7 mt-18"
  //     }`}
  //     style={{ backgroundColor: "rgba(247, 207, 60, 1)" }}
  //   >
  //     <div
  //       className="w-44 h-44 rounded-40px p-4"
  //       style={{ backgroundColor: "rgba(254, 142, 133, 1)" }}
  //     >
  //       <img
  //         src={charTypeIllustration(character)}
  //         className="w-full h-full object-contain rounded-3xl bg-white"
  //       />
  //     </div>
  //     <img
  //       src="/charcard-purplestar.svg"
  //       className="absolute top-7 left-6 w-18 h-18 object-contain"
  //     />
  //     <img
  //       src="/charcard-blueflower.svg"
  //       className="absolute top-40 right-5 w-10 h-10 object-contain"
  //     />
  //     <p className="text-center text-primaryColor text-2xl font-bold mt-4">
  //       {charTitle}
  //     </p>
  //     <p className="text-center text-primaryColor text-base font-medium px-12 my-2">
  //       {nickName}
  //     </p>
  //   </div>
  // );
}
