import React from "react";
import { useNavigate } from "react-router-dom";

export default function AboutUsSection({ screenWidth }) {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col bg-white font-plusJakartaSans px-4 py-16 tablet:flex-row tablet:items-start tablet:justify-between tablet:px-12 mac:px-20"
      style={{
        gap: screenWidth > 1024 && "172px",
      }}
    >
      <div className="flex flex-col items-start tablet:mt-5">
        <p
          className="text-base font-bold tracking-widest leading-tight"
          style={{ color: "#43BCCD" }}
        >
          TENTANG KAMI
        </p>
        <p
          className="text-left font-bold text-2xl tablet:text-40px text-black1 tracking-0.8px mt-4"
          style={{ lineHeight: screenWidth < 1024 ? "28.8px" : "43.2px" }}
        >
          Rencanakan Masa Depan
          <br />
          Dengan Exploration!
        </p>
        <p
          className="text-left font-normal text-base tablet:text-xl text-black188 mt-10"
          style={{ lineHeight: screenWidth < 1024 ? "24.8px" : "31px" }}
        >
          Ikuti berbagai kelas pilihan yang dilaksanakan oleh universitas
          unggulan di Indonesia, rasakan keseruannya dan tentukan masa depanmu
          sekarang!
        </p>
        <button
          onClick={() => {
            navigate("/explore-kelas");
          }}
          className={`${
            screenWidth < 1024 && "flex w-full justify-center items-center"
          } rounded-lg bg-primaryColor text-center font-semibold text-base text-white`}
          style={{
            lineHeight: "24px",
            marginTop: "34px",
            paddingTop: "14px",
            paddingBottom: "14px",
            paddingLeft: "24px",
            paddingRight: "20px",
          }}
        >
          Gabung Sekarang
        </button>
      </div>
      <div className="flex items-center justify-center w-full mt-10">
        <img
          src="/about_us_illustration.svg"
          className="object-contain w-full h-auto"
          alt="about us illustration"
        />
      </div>
    </div>
  );
}
