import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageLoading from "../pages/PageLoading";
import HomeCardMajor from "./HomeCardMajor";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeCardMajorV3 from "./HomeCardMajorV3";

export default function HomeMajorSection({ screenWidth, majors }) {
  const navigate = useNavigate();
  const sliderRef = useRef();
  const [currentSliderPage, setCurrentSliderPage] = useState(1);

  const slickCarouselSetting = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  if (!majors) {
    return <PageLoading />;
  }

  return (
    <section
      className="relative max-h-full w-full"
      style={{ padding: screenWidth >= 1024 && "0px 71px 0px 68px" }}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 h-full">
        <img
          src="/home_bg_major.svg"
          className="h-full w-full object-cover"
          alt="home bg"
        />
      </div>
      <div className="relative tablet:mt-6">
        {screenWidth >= 1024 && (
          <div
            className="text-left font-bold text-white"
            style={{ fontSize: "36px", lineHeight: "38.88px" }}
          >
            Explore Jurusan
          </div>
        )}
        {screenWidth >= 1024 ? (
          <Slider
            ref={sliderRef}
            {...slickCarouselSetting}
            id="slick"
            className="mt-8 -mr-3"
          >
            {majors.slice(0, 4).map((item, index) => {
              return (
                <div key={index}>
                  <HomeCardMajorV3
                    id={item.id}
                    majorData={item}
                    forHomeSection={true}
                    width={
                      screenWidth < 1440
                        ? "273px"
                        : screenWidth < 1910
                        ? "310px"
                        : "430px"
                    }
                    height={
                      screenWidth < 1440
                        ? "290px"
                        : screenWidth < 1910
                        ? "317px"
                        : "437px"
                    }
                    screenWidth={screenWidth}
                  />
                </div>
              );
            })}
          </Slider>
        ) : (
          <div className="relative overflow-x-auto mt-6 pb-4 ml-4">
            <div className="inline-flex gap-6 lg:justify-end">
              {majors.map((item, index) => {
                return (
                  <div key={index}>
                    <HomeCardMajor
                      id={item.id}
                      majorData={item}
                      width="300px"
                      height="399px"
                    />
                  </div>
                );
              })}
              <div></div>
            </div>
          </div>
        )}
        <div
          className={`w-full px-4 ${
            screenWidth >= 1024 && "flex justify-center items-center relative"
          }`}
          style={{
            paddingTop: "22px",
            paddingBottom: "23px",
            marginTop: "49px",
          }}
        >
          {/* {screenWidth >= 1024 && (
            <div className="flex flex-row gap-2 items-center justify-center">
              <button
                onClick={() => {
                  if (currentSliderPage === 2) {
                    sliderRef.current.slickPrev();
                  }
                  setCurrentSliderPage(1);
                }}
                className={`bg-white rounded-lg p-3 ${
                  currentSliderPage === 2 ? "opacity-100" : "opacity-30"
                }`}
              >
                <ArrowLeftIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
              <button
                onClick={() => {
                  if (currentSliderPage === 1) {
                    sliderRef.current.slickNext();
                  }
                  setCurrentSliderPage(2);
                }}
                className={`bg-white rounded-lg p-3 ${
                  currentSliderPage === 1 ? "opacity-100" : "opacity-30"
                }`}
              >
                <ArrowRightIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
            </div>
          )} */}
          <button
            onClick={() => {
              navigate("/explore-jurusan");
            }}
            className={`w-full flex flex-row items-center justify-center gap-2 bg-white rounded-lg ${
              screenWidth >= 1024 && "absolute right-0 bottom-6"
            }`}
            style={{
              padding: "14px 0",
              maxWidth: screenWidth >= 1024 && "201.56px",
            }}
          >
            <div
              className="text-right font-semibold text-base tablet:text-sm text-primaryColor"
              style={{ lineHeight: screenWidth < 1024 ? "24px" : "21px" }}
            >
              {screenWidth < 1024
                ? "Lihat semua jurusan"
                : "Lihat jurusan lainnya"}
            </div>
            <ArrowRightIcon className="w-5 h-5 text-primaryColor" />
          </button>
        </div>
      </div>
    </section>
  );
}
