import React from 'react';
import moment from "moment-timezone";
import {useLocation, useNavigate} from 'react-router-dom';
import Button from '../components/base/Button';
import {ArrowLeftIcon, DownloadIcon} from '@heroicons/react/outline';
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";

export default function Certificate() {
  moment.tz.add('Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6');
  const { state } = useLocation();
  const navigate = useNavigate();
  const eCertRef = React.createRef();
  const {student_name, class_title} = state;

  if (!state) {
    setTimeout(() => {
      navigate('/profile', { replace: true })
    }, 100);
    return (<></>);
  }

  const onDownload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.scrollTo(0,0);
    html2canvas(eCertRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        // format: [4, 2]
      });
      const pdfWidth = pdf.internal.pageSize.width;
      const pdfHeight = pdf.internal.pageSize.height;
      let widthRatio = pdfWidth / canvas.width;
      let heightRatio = pdfHeight / canvas.height;
      let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      pdf.addImage(imgData, "png", (pdfWidth - canvas.width * ratio) / 2, 0, canvas.width * ratio, canvas.height * ratio);
      pdf.save(`${student_name}-${class_title}`);
    });
  }
  return <>
    <div className='w-screen h-screen flex justify-center items-center'>
      <ECertificate ref={eCertRef} {...state} />
      <div className='absolute hide-print' style={{
        'top': '100px',
        'right': '100px'
      }} >
        <Button onClick={onDownload} variant="primary" size="small" className="ml-3 mb-3">
          <DownloadIcon className="w-5 h-5 text-white mr-2" />
          <span>Download</span>
        </Button>
        <Button onClick={() => navigate(-1)} variant="primary" size="small" className="ml-3">
          <ArrowLeftIcon className="w-5 h-5 text-white mr-2" />
          <span>Back</span>
        </Button>
      </div>
    </div>
  </>
}

const ECertificate = React.forwardRef(({
  student_name,
  class_title,
  university_name,
  class_date,
}, ref) => {
  return <>
    <div ref={ref} className="relative w-800px h-600px bg-white shadow-sm print-area-cert">
      <img
        src="/ic_top_right.png"
        alt=""
        className="absolute right-0 top-0"
      />
      <img
        src="/ic_bottom_left.png"
        alt=""
        className="absolute bottom-0 left-0"
      />
      <img
        src="/ic_rex_logo.png"
        alt=""
        className="absolute"
        style={{
          'top':'45px',
          'left': '45px',
        }}
      />
      <img
        src="/ic_ocbc.png"
        alt=""
        className="absolute"
        style={{
          'right':'50px',
          'top': '25px'
        }}
      />
      <div className="flex h-full justify-center items-center flex-col">
        <img src="/ic_certificate_header.png" alt="" />
        <img src="/ic_greetings_proud.png" alt="" />
        <p className="text-purple-700 font-semibold text-xl mt-4 text-center">{student_name}</p>
        <img
          src="/ic_underline_input.png"
          alt=""
          className="absolute bottom-[35%]"
          style={{
            'bottom': '35%',
          }}
        />
        <div className="text-center text-xs mt-12">
          <p className="font-normal">For participating in exploration’s class</p>
          <p className="font-bold py-2">
            {class_title}
          </p>
          <p className="font-normal">by {university_name}</p>
          <p className="font-normal italic"> on {moment(class_date).tz('Asia/Jakarta').format("DD MMMM YYYY")}</p>
        </div>
      </div>
    </div>
  </>
});