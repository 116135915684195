import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/base/Button";
import { useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import Cookies from "js-cookie";
import Footer from "../components/Footer";
import Headerv2 from "../components/Header_v2";
import { api, notifError } from "../utils/api";
import SigninupSlide from "../components/SigninupSlide";
import Carousel from "react-multi-carousel";

export default function LinkToEmail() {
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authState);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  const onSubmit = (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();

    api
      .post("/student-inject/verification", {
        email: elements[0].value,
      })
      .then((res) => {
        Cookies.set("user.token", res.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });

        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res.data.data.request_token,
            },
          })
          .then((resMe) => {
            setAuth({ ...resMe.data.data, isAuth: true });
            navigate("/profile-edit");
          })
          .catch(notifError);
      })
      .catch(notifError);
  };

  const bgSlideItems = [
    { image_src: "/illu_signinup_creator.svg", bg_color: "#57C2D2" },
    { image_src: "/illu_signinup_doer.svg", bg_color: "#FD5244" },
    { image_src: "/illu_signinup_organizer.svg", bg_color: "#FDCF30" },
    { image_src: "/illu_signinup_thinker.svg", bg_color: "#FD5244" },
    { image_src: "/illu_signinup_helper.svg", bg_color: "#57C2D2" },
    { image_src: "/illu_signinup_persuader.svg", bg_color: "#FDCF30" },
  ];

  return (
    <div className="flex flex-col justify-start w-screen h-max relative">
      {screenWidth >= 1024 ? <Headerv2 screenWidth={screenWidth} logoOnly={true} /> : <Headerv2 />}
      {screenWidth < 1024 && (
        <div className="flex h-max w-screen relative">
          <div className="flex flex-col w-screen pt-10 px-4 relative z-10">
            <p
              className="text-left font-bold text-2xl text-white"
              style={{ lineHeight: "30px" }}
            >
              Raih masa depanmu bersama Exploration
            </p>
            <p
              className="text-left font-medium text-base text-white mt-6"
              style={{ lineHeight: "20px" }}
            >
              Exploration hadir untuk menemani kamu yang masih bingung menata
              masa depan, dalam bereksplorasi berbagai pilihan jurusan dan
              kampus untuk memulai planning masa depan impian kamu.
            </p>
            <div className="flex flex-col w-full rounded-20px bg-white py-10 px-4 my-8">
              <p
                className="text-left font-medium text-base text-black"
                style={{ lineHeight: "20px" }}
              >
                Input alamat email yang akan dihubungkan dengan Username
                tersebut
              </p>
              <form onSubmit={onSubmit} className="mt-6">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="text-left font-medium text-base text-black3"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="form-input w-full text-gray-800 rounded-40px mt-2"
                      placeholder="Masukkan alamat e-mail"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <Button type="submit" className="w-full">
                      Verifikasi Email
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <img
            src="/bg_loginv2.png"
            alt="login-bg"
            className="w-auto h-full absolute top-0 -z-1 object-cover"
          />
          <div className="w-screen h-full absolute bg-gradient-to-t from-black to-gray-600 opacity-60" />
        </div>
      )}
      {screenWidth >= 1024 && (
        <div className="w-full h-full flex overflow-hidden relative">
          <p
            className="text-left font-bold text-white absolute z-10"
            style={{
              fontSize: "42.67px",
              lineHeight: "51.2px",
              letterSpacing: "-1.07px",
              top: "50px",
              left: "71.11px",
            }}
          >
            Selamat datang
          </p>
          <Carousel
            className="flex flex-1 w-full h-full"
            ssr={true}
            arrows={false}
            swipeable={false}
            draggable={false}
            showDots={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={500}
            transitionDuration={3000}
            containerClass="carousel-container"
            responsive={{
              desktop: {
                breakpoint: {
                  max: 1920,
                  min: 1024,
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 390,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 390,
                  min: 0,
                },
                items: 1,
              },
            }}
          >
            {bgSlideItems.map((item, index) => {
              return (
                <SigninupSlide
                  key={index}
                  bgColor={item.bg_color}
                  image={item.image_src}
                />
              );
            })}
          </Carousel>
          <div
            className="flex flex-col sm:right-18 sm:top-12 rounded-20px p-10 absolute bg-white"
            style={{ width: "475.56px" }}
          >
            <p
              className="text-left font-medium text-base text-black"
              style={{ lineHeight: "20px" }}
            >
              Input alamat email yang akan dihubungkan dengan Username tersebut
            </p>
            <form onSubmit={onSubmit} className="mt-6">
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="text-left font-medium text-base text-black3"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="form-input w-full text-gray-800 rounded-40px mt-2"
                    placeholder="Masukkan alamat e-mail"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full px-3">
                  <Button type="submit" className="w-full">
                    Verifikasi Email
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
