import { useState, useEffect } from "react";

export const mapHasKeyAndValueNotEmpty = (map, key) => {
    if (ifEmpty(map)) return false;
    return key in map && !ifEmpty(map[key]);
}

export const ifNotExist = (hashmap, key, defaultValue) => {
    if (ifEmpty(hashmap)) return defaultValue;
    if (key in hashmap) {
        return hashmap[key];
    }
    return defaultValue;
}

export const ifEmpty = (value) => {
    return typeof value === "undefined" || value === null || value === "" || value === 0;
}

export const iif = (expr, whenTrue, whenFalse) => {
    if (expr) {
        return whenTrue;
    }
    return whenFalse;
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}