import React, { useEffect, useRef, useState, Component } from "react";
import { useRecoilValue } from "recoil";
import { Link, useNavigate, useParams } from "react-router-dom";
import CharSection from "../components/CharSection";
import Layout from "../components/Layout";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import PageLoading from "./PageLoading";
import HomeCardClassV2 from "../components/HomeCardClassV2";
import Slider from "react-slick";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import LayoutV2 from "../components/Layout_V2";

export default function TMBResultDetail() {
  let navigate = useNavigate();
  let { id } = useParams();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [resultData, setResultData] = useState([]);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState();
  const [classRecommendation, setClassRecommendation] = useState([]);
  const classRecRef = useRef();
  const [classRecCurrentPage, setClassRecCurrentPage] = useState(1);
  const [tmbCode, setTmbCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getTMBResultDetail = async () => {
      await _api
        .get(`/tmb/test-result-detail/${id}`)
        .then((res) => {
          setResultData(res.data.data);
          // setTmbCode(res.data.data.map((item) => item.id));
          setTmbCode(res.data.data[0].code);
        })
        .catch((_) => {
          navigate("*");
          setResultData({});
        });
    };

    getTMBResultDetail();
  }, [_api, auth.isAuth, id]);

  useEffect(() => {
    let payload = {
      university_ids: [],
      keyword: "",
      page: 1,
      limit: 3,
      field_of_study_ids: [],
      sort_direction: "DESC",
      sort_key: "availability",
      is_online: -1,
      is_past_classes: 0,
      tmb_res_code: tmbCode,
      tmb_res_codes: resultData.map((item) => item.code),
    };

    const getUpcoming = async () => {
      await _api
        .post("/classes/upcoming", payload)
        .then((res) => {
          setClassRecommendation(res.data.data.upcoming);
          setIsLoading(false);
        })
        .catch((_) => {
          setClassRecommendation([]);
        });
    };

    getUpcoming();
  }, [tmbCode]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  async function downloadResult() {
    await api
      .get(`/tmb/test-result-detail-pdf/${id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "my-character.pdf");
        document.body.appendChild(link);
        link.click();
        setIsDownloadingPdf(false);
      })
      .catch((error) => {
        setIsDownloadingPdf(false);
        window.Swal.fire({
          title: "Perhatian!",
          text: `${
            error.message === "Request failed with status code 412"
              ? "Silakan lengkapi data dirimu dahulu"
              : error.message
          }`,
          icon: "warning",
          confirmButtonText: `${
            error.message === "Request failed with status code 412"
              ? "Edit Profil"
              : "Tutup"
          }`,
        }).then(() => {
          if (error.message === "Request failed with status code 412") {
            navigate("/profile-edit");
          }
        });
        return;
      });
  }

  var screenWidth = windowSize.innerWidth;

  const classRecSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: screenWidth < 1024 ? 1 : 3,
    slidesToScroll: screenWidth < 1024 ? 1 : 3,
  };

  if (resultData.length === 0) {
    return <PageLoading />;
  }
  return (
    <LayoutV2 padding="0px">
      <div className="flex flex-col h-auto w-screen bg-white">
        <div className="relative flex flex-col w-full px-4 mb-10 tablet:items-center">
          <img
            src="/star-4point.svg"
            className="absolute left-2 top-8 mac:h-24 mac:w-24 mac:top-14 mac:left-50"
          />
          <img
            src="/star-4point.svg"
            className="absolute right-2 top-44 mac:h-24 mac:w-24 mac:top-56 mac:right-66"
          />
          <p
            className="text-center tablet:text-center text-base font-bold tracking-widest leading-tight mt-6 mac:mt-20"
            style={{ color: "#43BCCD" }}
          >
            TIPE KARAKTER
          </p>
          <p className="text-center text-black2 text-3xl font-bold leading-tight tablet:leading-normal tracking-tighter tablet:tracking-normal mt-4 mac:text-60px mac:px-60 mac:leading-tight z-10">
            Kenalin, karakter kuatmu adalah
            <span className="text-primaryColor">
              {" "}
              {`${resultData[0].label_short.split(" ")[1]}, ${
                resultData[1].label_short.split(" ")[1]
              }, ${resultData[2].label_short.split(" ")[1]}!`}
            </span>
          </p>
          <div className="relative flex items-center h-20 mt-7">
            <button
              to="/tes-minat"
              onClick={() => {
                setIsDownloadingPdf(true);
                downloadResult();
              }}
              disabled={isDownloadingPdf ? true : false}
              className="w-full pl-10 tablet:flex tablet:items-center tablet:justify-center tablet:w-90"
            >
              <div
                className={`w-full tablet:w-90 h-auto py-3 ${
                  isDownloadingPdf ? "bg-gray-400" : "bg-primaryColor"
                } text-center text-base text-white font-semibold rounded-lg tablet:px-6`}
              >
                {isDownloadingPdf
                  ? "Sedang download hasil..."
                  : "Download hasil konsultasimu"}
              </div>
            </button>
            <img
              src="/download-result.svg"
              className="absolute left-0 top-auto"
            />
          </div>
        </div>
        {/* section Kelas Rekomendasi */}
        {classRecommendation.length > 0 && (
          <div className="flex flex-col w-full">
            <div
              className="flex flex-col py-12 px-4 relative z-10 tablet:px-20 tablet:pt-20 tablet:pb-10"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
            >
              {screenWidth < 1024 ? (
                <p
                  className="text-left font-bold text-2xl text-black1 tracking-0.8px"
                  style={{ lineHeight: "29px" }}
                >
                  Coba liat kelas yang cocok untuk kamu!
                </p>
              ) : (
                <div className="flex flex-row justify-between items-end w-full">
                  <p
                    className="text-left font-bold text-48px text-black1 tracking-0.8px"
                    style={{ lineHeight: "58px" }}
                  >
                    Coba liat kelas yang <br />
                    cocok untuk kamu!
                  </p>
                  {classRecommendation.length > 3 && (
                    <div className="flex flex-row gap-2 items-center justify-end pr-12">
                      <button
                        onClick={() => {
                          classRecRef.current.slickPrev();
                          setClassRecCurrentPage(1);
                        }}
                        disabled={classRecCurrentPage === 1}
                        className={`bg-tosca2 rounded-lg p-3 ${
                          classRecCurrentPage === 1
                            ? "opacity-30"
                            : "opacity-100"
                        }`}
                      >
                        <ArrowLeftIcon
                          className="w-4 h-4 mac:w-5 mac:h-5"
                          color="white"
                        />
                      </button>
                      <button
                        onClick={() => {
                          classRecRef.current.slickNext();
                          setClassRecCurrentPage(2);
                        }}
                        disabled={classRecCurrentPage === 2}
                        className={`bg-tosca2 rounded-lg p-3 ${
                          classRecCurrentPage === 2
                            ? "opacity-30"
                            : "opacity-100"
                        }`}
                      >
                        <ArrowRightIcon
                          className="w-4 h-4 mac:w-5 mac:h-5"
                          color="white"
                        />
                      </button>
                    </div>
                  )}
                </div>
              )}
              {classRecommendation.length > 3 && screenWidth >= 1024 ? (
                <div className="mt-16">
                  <Slider ref={classRecRef} {...classRecSettings}>
                    {classRecommendation.map((item, index) => {
                      return (
                        <div key={index}>
                          <HomeCardClassV2
                            key={index}
                            item={item}
                            mobile={screenWidth < 1024 ? true : false}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              ) : (
                <div
                  className={`flex flex-row justify-start ${
                    screenWidth < 1024 && "overflow-x-auto"
                  } items-center gap-6 w-full mt-8 tablet:mt-16`}
                >
                  {classRecommendation.map((item, index) => {
                    return (
                      <div key={index}>
                        <HomeCardClassV2
                          key={index}
                          item={item}
                          mobile={screenWidth < 1024 ? true : false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="flex w-full justify-end pr-32">
              <button
                onClick={() => {
                  navigate("/explore-kelas");
                }}
                className="px-6 py-4 rounded-lg shadow-md"
                style={{ backgroundColor: "#662E9B" }}
              >
                <div className="flex flex-row justify-center items-center gap-3">
                  <p className="text-right font-semibold text-base text-white">
                    Lihat semua kelas
                  </p>
                  <ArrowRightIcon className="w-5 h-5 text-white" />
                </div>
              </button>
            </div>
          </div>
        )}

        <CharSection
          data={resultData[0]}
          order="01"
          screenWidth={screenWidth}
        />
        <CharSection
          data={resultData[1]}
          order="02"
          screenWidth={screenWidth}
        />
        <CharSection
          data={resultData[2]}
          order="03"
          screenWidth={screenWidth}
        />
        <div
          className="tablet:relative flex flex-col pt-32 px-4 mac:pt-26 tablet:items-center tablet:px-0 tablet:py-20 mt-22"
          style={{ backgroundColor: "rgba(254, 82, 68, 1)" }}
        >
          <p className="text-center text-white text-3xl font-bold mac:text-60px">
            Kamu belum yakin? {screenWidth >= 1024 ? <br></br> : ""}It's okay.
            Pastiin lagi, yuk!
          </p>
          <div className="z-10 tablet:object-center">
            <Link to="/tes-minat">
              <div className="w-full tablet:w-max h-auto py-3 bg-primaryColor mt-12 text-center text-base text-white font-semibold rounded-lg tablet:px-6 mac:my-0 mac:mt-12 mac:mb-26">
                Pastiin sekarang
              </div>
            </Link>
          </div>
          <img
            src="/illu-tmb-2.svg"
            className="object-cover object-bottom mt-10 tablet:absolute tablet:bottom-0 tablet:right-4 tablet:h-80"
          />
        </div>
      </div>
    </LayoutV2>
  );
}
