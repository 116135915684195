import CardClassRoom from "../components/CardClassRoomV3";
import { useRecoilValue } from "recoil";
import useSwrCache from "./useSwrCache";
import Layout from "../components/Layout";
import {authState} from "../atoms/authState";
import {api, apiAnom} from "../utils/api";
import PageLoading from "../pages/PageLoading";
import Pagination from "../components/pagination/Pagination";
import {useEffect, useState} from 'react';
import Button from '../components/base/Button';
import DropdownItem from '../components/DropdownItem';
import ReactSelect from "react-select";
import * as _ from 'lodash';
import { useLocation } from "react-router-dom";
import LayoutV2 from "../components/Layout_V2";


const today = new Date();
today.setHours(0, 0, 0, 0);

let pageSize = 12;

function ClassRoom() {
  const auth = useRecoilValue(authState);
  const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBidang, setFilterBidang] = useState([]);
  const [filterOrganizer, setFilterOrganizer] = useState(state?.initialUniv ? [state?.initialUniv] : []);
  const { data: universities } = useSwrCache("/universities", {
    key: "listUniversities",
    initialValue: [],
  });

  const [fosList, setFosList] = useState([]);
  const [listData, setListData] = useState();
  const [keyword, setKeyword] = useState();

  const _api = auth.isAuth ? api : apiAnom;

  useEffect(() => {
    document.title = `Explore Kelas`;
  }, []);

  useEffect(() => {
    const getFieldOfStudy = () => {
      _api.get('/field-of-study')
        .then((res) => {
          setFosList(res.data.data);
        })
        .catch((_) => {
          setFosList(_);
        });
    };
    getFieldOfStudy();
  }, [_api, auth.isAuth])

  useEffect(() => {
    const getUpcoming = () => {
      _api
        .post('/classes/upcoming', {
          "university_ids": _.map(filterOrganizer, 'id'),
          "keyword": keyword,
          "page": currentPage,
          "limit": pageSize,
          "field_of_study_ids": _.map(filterBidang, 'id'),
          "sort_direction": "DESC",
          "sort_key": "availability"
        })
        .then((res) => {
          setListData(res.data.data);
        })
        .catch((_) => {
          setListData([])
        });
    }
    getUpcoming();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuth, currentPage, filterBidang, filterOrganizer])

  if (!listData) return <PageLoading />;

  const styles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: 'rgba(217, 217, 217, 0.5)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    input: (provided) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: '1 !important'
      }
    }),
  }

  const onDeleteBidang = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterBidang(filterBidang.slice(index + 1));
  }

  const onDeleteOrganizer = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterOrganizer(filterOrganizer.slice(index + 1));
  }

  const onSearchByBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _api
      .post('/classes/upcoming', {
        "university_ids":  _.map(filterOrganizer, 'id'),
        "keyword": keyword,
        "page": currentPage,
        "limit": pageSize,
        "field_of_study_ids": _.map(filterBidang, 'id'),
        "sort_direction": "DESC",
        "sort_key": "availability"
      })
      .then((res) => {
        setListData(res.data.data);
      })
      .catch((_) => {
        setListData([])
      });
  }

  return (
    <LayoutV2>
      <div>
        <h1 className="mb-1 text-2xl tablet:text-5xl font-bold">
          Explore Kelas
        </h1>
        <p className="text-lg font-light text-gray-500">
          Bergabung dalam kelas perkuliahan sekarang!
        </p>
        <div className="mt-6 mb-6 flex justify-between items-center">
          <div
            className="w-full px-4 py-3 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg"
          >
            <form className="flex flex-col sm:flex-row remove-input-txt-border">
              <input
                type="text"
                className="flex-1 sm:flex-2 form-input shadow-sm sm:text-sm sm:rounded-r-none"
                style={{
                  'maxHeight': '38px',
                  'backgroundColor': 'rgba(217, 217, 217, 0.5)',
                }}
                placeholder="Cari kelas berdasarkan nama..."
                onChange={(e) => setKeyword(e.target.value)}
              />
              <ReactSelect
                styles={styles}
                options={fosList}
                isMulti
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuColor
                getOptionLabel={(data) => data['name']}
                getOptionValue={(data) => data['id']}
                components={{
                  Option: DropdownItem
                }}
                className="flex-1 shadow-sm sm:text-sm focus:border-none"
                placeholder="Bidang Studi"
                onChange={setFilterBidang}
              />
              <ReactSelect
                styles={styles}
                options={universities}
                isMulti
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                getOptionLabel={(data) => data['name']}
                getOptionValue={(data) => data['id']}
                components={{
                  Option: DropdownItem
                }}
                className="flex-1 shadow-sm sm:text-sm focus:border-none"
                placeholder="Penyelenggara"
                onChange={setFilterOrganizer}
              />
              <Button
                onClick={onSearchByBtn}
                aria-controls="modal"
                variant="primary"
                style={{
                  'maxHeight': '38px'
                }}
                className="rounded-md sm:rounded-r-md sm:rounded-l-none mt-2 sm:mt-0"
              >
                <span className="px-2.5">Cari</span>
              </Button>
            </form>
          </div>
          {/*
          <div>
            <select
              onChange={(e) => {
                const params = new URLSearchParams(window.location.search);
                navigate({
                  pathname: "/explore-kelas",
                  search: `?s=${params.get("s") || ""}&u=${e.target.value}`,
                });
              }}
            >
              <option value="">Semua Penyelenggara</option>
              {universities.map((d, i) => {
                return (
                  <option key={i} value={d.id}>
                    {d.name}
                  </option>
                );
              })}
            </select>
          </div> */}
        </div>
        <div className="mb-4">
          {filterBidang.length > 0 && (
            <div
              className="px-2 flex flex-row flex-wrap items-center"
            >
              <p className="text-xs text-black mr-3">Bidang Studi:</p>
              {
                filterBidang.map((bidang, index) => (
                  <span
                    key={bidang['id'].toString()}
                    className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                  >
                    <span>{bidang['name']}</span>
                    <button
                      type="button"
                      className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                      onClick={(e) => onDeleteBidang(e, index)}
                    >
                      <span className="sr-only">Remove filter </span>
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                ))
              }
            </div>
          )}
          {filterOrganizer.length > 0 && (
            <div
              className="px-2 flex flex-row flex-wrap  items-center"
            >
              <p className="text-xs text-black mr-3">Penyelenggara:</p>
              {
                filterOrganizer.map((organizer, index) => (
                  <span
                    key={organizer['id'].toString()}
                    className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                  >
                    <span>{organizer['name']}</span>
                    <button
                      type="button"
                      className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                      onClick={(e) => onDeleteOrganizer(e, index)}
                    >
                      <span className="sr-only">Remove filter </span>
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                ))
              }
            </div>
          )}
        </div>
        {!listData && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">Loading...</h3>
            </div>
          </div>
        )}
        {listData.upcoming.length < 1 && listData.upcoming && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">
                Pencarian data tidak di temukan
              </h3>
            </div>
          </div>
        )}
        <div>
          <ul>
            {listData.upcoming.map((d, i) => {
                const cardDate = new Date(d.started_at);
                cardDate.setDate(cardDate.getDate() - 1);
                cardDate.setHours(0, 0, 0, 0);
                return (
                  <li key={i}>
                    <CardClassRoom
                      {...d}
                      isFull={d.is_full_booked === 1}
                      isSubscribe={
                        auth.isAuth
                          ? listData.subscribed.includes(d.id)
                          : false
                      }
                      isClose={d.is_past_classes === 1}
                    />
                  </li>
                );
              })}
          </ul>
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={listData.metadata.total_item}
          pageSize={listData.metadata.limit}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </LayoutV2>
  );
}

export default ClassRoom;
