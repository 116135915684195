import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { authState } from "../../atoms/authState";
import { api, apiAnom, apiV2, notifError } from "../../utils/api";
import PageLoading from "../PageLoading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../../utils/utils";
import Button from "../../components/base/Button";
import { signInUpState } from "../../atoms/signInUpState";
import { RadioButton } from "../../components/RadioButton";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { add, functions, update } from "lodash";
import countryJSON from "../../components/jsons/Countries.json";
import indoProvJSON from "../../components/jsons/IndonesiaProvinceWithCities.json";
import { laSalleEduData } from "../../atoms/laSalleEducationData";
import "flowbite";
import LaSalleRegistrationDetails from "./LaSalleAdmission/LaSalleRegisDetail";
import { Dialog, Transition } from "@headlessui/react";
import Cookies from "js-cookie";
import LaSalleContactInformation from "./LaSalleAdmission/LaSalleContactInformastion";
import LaSallePersonalInformation from "./LaSalleAdmission/LaSallePersonalInformation";
import LaSalleBirthPlaceAndLegalStatus from "./LaSalleAdmission/LaSalleBirthPlaceAndLegalStatus";
import LaSalleAddress from "./LaSalleAdmission/LaSalleAddress";
import LaSalleAcademicInformation from "./LaSalleAdmission/LaSalleAcademicInformation";
import Slider from "react-slick";
import { postCheckModalState } from "../../atoms/postCheckModalState";
import PostCheckModal from "../../components/PostCheckModal";
import LayoutV2 from "../../components/Layout_V2";

export default function LaSalleAdmissionFormV4() {
  const today = new Date();
  const params = new URLSearchParams(window.location.search);
  const admissionID = params.get("admissionID");
  const sliderRef = useRef();
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const eduData = useRecoilValue(laSalleEduData);
  const [errors, setErrors] = useState({});
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const [univData, setUnivData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isSaveDraft, setIsSaveDraft] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentForm, setCurrentForm] = useState(1);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [showPostCheckModal, setShowPostCheckModal] = useState(false);
  const [isSubmitProcess, setIsSubmitProcess] = useState(false);
  const setShowPostCheckModalState = useSetRecoilState(postCheckModalState);

  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [univFormData, setUnivFormData] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [postCheckData, setPostcheckData] = useState();
  const [formFinalData, setFormFinalData] = useState();
  const [uniID, setUniID] = useState();

  const uniLocation = ["Jakarta", "Surabaya"];
  const programType = ["Diploma", "Certificate", "D4", "S1"];
  const programChoices = [
    {
      type: "Diploma",
      choices: [
        "Fashion Design",
        "Fashion Business",
        "Graphic Design Diploma",
        "Game Art Design",
        "Photography",
        "Interior Design",
        "Management Creative Industries Diploma",
      ],
    },
    {
      type: "Certificate",
      choices: [
        "Pattern Maker",
        "Stylist/Illustrator",
        "Buyer",
        "Retail Manager",
        "Graphic Design Certificate",
        "Photography",
        "Interior Design",
        "Artistic Make Up Module 1 & 2",
        "Management Creative Industries Certificate",
      ],
    },
    {
      type: "D4",
      choices: [
        "Fashion Design D4 Standard",
        "Fashion Design D4 Bridging",
        "Fashion Merchandising D4 Standard",
        "Fashion Merchandising D4 Bridging",
      ],
    },
    {
      type: "S1",
      choices: [
        "Interior Design Bachelor Standard",
        "Interior Design Bachelor Bridging",
      ],
    },
  ];

  const slickCarouselSetting = {
    centerMode: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    dots: false,
    swipe: false,
  };

  function closeModal() {
    setShowPostCheckModal(false);
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    setShowPostCheckModal(false);
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  useEffect(() => {
    if (!auth.isAuth) {
      setLoading(false);
      setTimeout(() => {
        setSign({ trySignIn: true, trySignUp: false });
      }, 100);
    } else if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    } else {
      setLoading(true);
      setFullName(
        auth.fname && auth.lname ? auth.fname + " " + auth.lname : ""
      );
      setFirstName(auth.fname ? auth.fname : "");
      setLastName(auth.lname ? auth.lname : "");
      setEmail(auth.email);
      let newCountryCode;
      let newPhone;
      if (auth.phone.includes("-")) {
        newCountryCode = auth.phone.split("-")[0];
        newPhone = auth.phone.split("-")[1];
      } else {
        newCountryCode = getCountryCallingCode("ID");
        newPhone = auth.phone.slice(auth.phone.indexOf("8"));
      }
      setPhone(newPhone);
    }

    const getUnivDetails = async () => {
      await _api
        .get(`/explore-kampus-v2/${window.location.pathname.split("/")[2]}`)
        .then((res) => {
          setUnivData(res.data.data);
          Cookies.set("lasalle_id", res.data.data.id, {
            sameSite: "None",
            secure: true,
          });
          setUniID(res.data.data.id);
          if (auth.isAuth) {
            api
              .get(`/students/univ-admission/${admissionID}`)
              .then((res) => {
                if (res.data.data.has_draft) {
                  setShowDraftModal(true);
                  setUnivFormData(res.data.data.univ_form_data);
                }
                setLoading(false);
              })
              .catch(notifError);
          }
        })
        .catch(notifError);
    };

    getUnivDetails();
  }, []);

  const formStepsTitle = [
    {
      sortIndex: 1,
      title: "Detail Pendaftaran",
      component: "RegisrationDetails",
    },
    {
      sortIndex: 2,
      title: "Informasi Kontak",
      component: "ContactInformation",
    },
    {
      sortIndex: 3,
      title: "Detail Personal",
      component: "PersonalInformation",
    },
    {
      sortIndex: 4,
      title: "Tempat Kelahiran-dan Status Hukum",
      component: "BirthPlaceAndLegalStatus",
    },
    { sortIndex: 5, title: "Alamat Domisili", component: "Address" },
    { sortIndex: 6, title: "Pendidikan", component: "AcademicInformation" },
  ];

  function FormStep({ number, title }) {
    return (
      <div className="flex flex-row gap-4 justify-start items-center w-full pr-4">
        <div
          className={`flex items-center justify-center text-center font-bold text-xl text-white rounded-full ${
            currentForm.toString() === number
              ? "bg-primaryColor"
              : "bg-gray-400"
          }`}
          style={{
            minHeight: "50px",
            maxHeight: "50px",
            minWidth: "50px",
            maxWidth: "50px",
            lineHeight: "25px",
          }}
        >
          0{number}
        </div>
        <p
          className="text-center font-medium text-xl rounded-full whitespace-nowrap"
          style={{ color: "rgba(23, 23, 23, 1)" }}
        >
          {title.includes("-") ? (
            <span>
              {title.split("-")[0]}
              <br />
              {title.split("-")[1]}
            </span>
          ) : (
            title
          )}
        </p>
        {number < 6 && (
          <div
            className="w-full"
            style={{
              minHeight: "4px",
              maxHeight: "4px",
              // minWidth: "50px",
              // maxWidth: "50px",
              backgroundColor: "rgba(217, 217, 217, 1)",
            }}
          />
        )}
      </div>
    );
  }

  function saveDraft(data) {
    setIsSaving(true);
    setIsSaveDraft(true);

    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("name", fullName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("uni_id", Cookies.get("lasalle_id"));
    formData.append(
      "univ_form_data",
      JSON.stringify(data ? data : univFormData)
    );
    formData.append("paid_status", "DRAFT"); // DRAFT or SUBMITTED

    _api
      .post(`/students/univ-admission/${admissionID}`, formData, config)
      .then((_) => {
        setTimeout(() => {
          setIsSaving(false);
        }, 500);
      })
      .catch(notifError);
  }

  function SetCurrentFormIndex(e) {
    setCurrentForm(e);
    if (currentForm < e) {
      sliderRef.current.slickNext();
    }
    if (currentForm > e) {
      sliderRef.current.slickPrev();
    }
  }

  function regisDetailData(item) {
    const selectedUniLocation =
      uniLocation[uniLocation.indexOf(item.lokasiKampus)];
    const selectedProgram = programType[programType.indexOf(item.jenisProgram)];
    const selectedProgramStudi =
      programChoices[
        programChoices.findIndex(
          (program) => program.type === item.jenisProgram
        )
      ].choices[
        programChoices[
          programChoices.findIndex(
            (choice) => choice.type === item.jenisProgram
          )
        ].choices.indexOf(item.pilihanProgram)
      ];

    let jsonForm = {
      ...univFormData,
      lokasiKampus: selectedUniLocation,
      jenisProgram: selectedProgram,
      pilihanProgram: selectedProgramStudi,
    };

    setUnivFormData(jsonForm);
    saveDraft(jsonForm);
  }

  function contactInfoData(item) {
    const dateOfBirth = item.tanggalLahir;
    const email = item.email;
    const phone = item.phone;

    let jsonForm = {
      ...univFormData,
      tanggalLahir: dateOfBirth,
      email: email,
      phone: phone,
    };

    setUnivFormData(jsonForm);

    saveDraft(jsonForm);
  }

  function personalInfoData(item) {
    const selectedGender = item.jenisKelamin;
    const selectedNativeLang = item.bahasaAsli;
    const guardianName = item.namaDepanWali;
    const guardianEmail = item.emailWali;
    const guardianPhone = item.teleponWali;

    let jsonForm = {
      ...univFormData,
      jenisKelamin: selectedGender,
      bahasaAsli: selectedNativeLang,
      namaDepanWali: guardianName,
      emailWali: guardianEmail,
      teleponWali: guardianPhone,
    };

    setUnivFormData(jsonForm);

    saveDraft(jsonForm);
  }

  function legalStatusData(item) {
    const selectedCountry = item.negaraKelahiran;
    const selectedProvince = item.provinsiKelahiran;
    const selectedCity = item.kotaKelahiran;
    const selectedCitizenship = item.kewarganegaraan;
    const selectedLegalStatus = item.statusKewarganegaraan;

    let jsonForm = {
      ...univFormData,
      negaraKelahiran: selectedCountry,
      provinsiKelahiran: selectedProvince,
      kotaKelahiran: selectedCity,
      kewarganegaraan: selectedCitizenship,
      statusKewarganegaraan: selectedLegalStatus,
    };

    setUnivFormData(jsonForm);

    saveDraft(jsonForm);
  }

  function addressData(item) {
    const setSelectedAddressCountry = item.negara;
    const setSelectedAddressProvince = item.provinsi;
    const setSelectedAddressCity = item.kota;
    const setKodePos = item.kodePos;
    const setNamaJalan = item.namaJalan;
    const setNomorRumah = item.nomorRumah;
    const setTeleponRumah = item.teleponRumah;
    const setTeleponAlternatif = item.teleponAlternatif;

    let jsonForm = {
      ...univFormData,
      domisili: {
        negara: setSelectedAddressCountry,
        provinsi: setSelectedAddressProvince,
        kota: setSelectedAddressCity,
        kodePos: setKodePos,
        namaJalan: setNamaJalan,
        nomorRumah: setNomorRumah,
        teleponRumah: setTeleponRumah,
        teleponAlternatif: setTeleponAlternatif,
      },
    };

    setUnivFormData(jsonForm);

    saveDraft(jsonForm);
  }

  function academicInfoData(item) {
    setIsSubmitProcess(true);
    const selectedOccupation = item.pekerjaanUtama;
    const selectedWorkCountry = item.negaraBekerja;
    const discipline = item.bidangPekerjaan;
    const highSchool = item.namaSMA;
    const graduationYear = item.tahunKelulusan;
    const lastGrade = item.pendidikanTerakhir;
    const nativeLang = item.bahasa;
    const otherLang = item.bahasaLainnya;

    let jsonForm = {
      ...univFormData,
      pekerjaanUtama: selectedOccupation,
      negaraBekerja: selectedWorkCountry,
      bidangPekerjaan: discipline,
      namaSMA: highSchool,
      tahunKelulusan: graduationYear,
      pendidikanTerakhir: lastGrade,
      bahasa: nativeLang,
      bahasaLainnya: otherLang,
    };

    setUnivFormData(jsonForm);

    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("name", fullName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("uni_id", Cookies.get("lasalle_id"));
    formData.append("univ_form_data", JSON.stringify(jsonForm));
    formData.append("paid_status", "SUBMITTED"); // DRAFT or SUBMITTED

    apiV2
      .get(`/invoices/postcheck/${uniID}`)
      .then((res) => {
        setPostcheckData(res.data.data);
        setFormFinalData(formData);
        setShowPostCheckModal(true);
        // setShowPostCheckModalState({ isOpen: true });
      })
      .catch((err) => {
        setFormFinalData(formData);
        setPostcheckData(false);
        if (err.response.status === 404) {
          _api
            .post(`/students/univ-admission/${admissionID}`, formData, config)
            .then((res) => {
              setIsSubmitProcess(false);

              window.Swal.fire({
                title: "Success!",
                text: `Kamu telah mendaftar di ${univData.name}`,
                icon: "success",
                confirmButtonText: "Tutup",
                allowOutsideClick: false,
              }).then(() => {
                navigate(
                  `/invoice?invoice_number=${res.data.data.invoice_number}`
                );
              });
            })
            .catch((_) => {
              setIsSubmitProcess(false);

              window.Swal.fire({
                title: "Gagal",
                text: `Pendaftaran kampus gagal`,
                icon: "error",
                confirmButtonText: "Tutup",
                allowOutsideClick: false,
              }).then(() => {
                navigate(`/profile`);
              });
            });
        } else {
          window.Swal.fire({
            title: "Gagal",
            text: err.response.data.error.message,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/profile`);
          });
        }
      });
  }

  function academicInfoPrevData(item) {
    const selectedOccupation = item.pekerjaanUtama;
    const selectedWorkCountry = item.negaraBekerja;
    const discipline = item.bidangPekerjaan;
    const highSchool = item.namaSMA;
    const graduationYear = item.tahunKelulusan;
    const lastGrade = item.pendidikanTerakhir;
    const nativeLang = item.bahasa;
    const otherLang = item.bahasaLainnya;

    let jsonForm = {
      ...univFormData,
      pekerjaanUtama: selectedOccupation,
      negaraBekerja: selectedWorkCountry,
      bidangPekerjaan: discipline,
      namaSMA: highSchool,
      tahunKelulusan: graduationYear,
      pendidikanTerakhir: lastGrade,
      bahasa: nativeLang,
      bahasaLainnya: otherLang,
    };

    setUnivFormData(jsonForm);

    saveDraft(jsonForm);
  }

  function submitForm() {
    setShowPostCheckModal(false);
    // setShowPostCheckModalState({ isOpen: false });
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    apiV2
      .post(
        `/invoices/recreate-invoice/${Cookies.get(
          "lasalle_id"
        )}/${admissionID}`,
        formFinalData,
        config
      )
      .then((res) => {
        setTimeout(() => {
          setIsSubmitProcess(false);

          window.Swal.fire({
            title: "Success!",
            text: `Kamu telah mendaftar di ${univData.name}`,
            icon: "success",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/invoice?invoice_number=${res.data.data.invoice_number}`);
            setShowPostCheckModal(false);
          });
        }, 1000);
      })
      .catch((_) => {
        setTimeout(() => {
          setIsSubmitProcess(false);

          window.Swal.fire({
            title: "Gagal",
            text: `Pendaftaran kampus gagal`,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/profile`);
          });
        }, 1000);
      });
  }

  // below function is for auto save feature
  useEffect(() => {
    if (univFormData) {
      const saveInterval = setInterval(() => {
        let regisData = univFormData;
        saveDraft(regisData);
      }, 30000);

      return () => {
        clearInterval(saveInterval);
      };
    }
  }, [univFormData]);

  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <LayoutV2 bgColor="bg-white" padding="0px">
      <p className="text-center font-bold text-3xl hd:text-48px text-black1 mt-4 hd:mt-20">
        Isi Formulir Pendaftaran
      </p>
      {isSaveDraft && (
        <div className="flex flex-row mt-12 w-full justify-center items-center gap-2">
          {!isSaving ? (
            <button
              onClick={() => {
                saveDraft();
              }}
            >
              <img src="/icon_save_as_draft.svg" className="w-6 h-6" />
            </button>
          ) : (
            <div className="animate-spin w-6 h-6 bg-gradient-to-r from-white to-gray-300 rounded-full flex items-center justify-center">
              <div className="bg-white h-4 w-4 rounded-full" />
            </div>
          )}
          <p className="text-left font-normal text-base text-black1">
            {`Saved as a draft ${moment(today)
              .tz("Asia/Jakarta")
              .format("DD MMMM YYYY HH.mm.ss")}`}
          </p>
        </div>
      )}
      <div className="w-full px-20 mt-12">
        <Slider ref={sliderRef} {...slickCarouselSetting} id="slick">
          {formStepsTitle.map((item, index) => (
            <div key={index}>
              <FormStep number={`${index + 1}`} title={item.title} />
              {/* {index !== 3 && (
                <div
                  className="w-full"
                  style={{
                    minHeight: "4px",
                    maxHeight: "4px",
                    backgroundColor: "rgba(217, 217, 217, 1)",
                  }}
                />
              )} */}
            </div>
          ))}
        </Slider>
        {/* <div className="flex flex-row items-center justify-between w-full gap-4 mt-12 overflow-auto pb-4">
          {formStepsTitle.map((item, index) => (
            <React.Fragment key={index}>
              <FormStep number={`${index + 1}`} title={item.title} />
              {index !== 3 && (
                <div
                  className="w-full"
                  style={{
                    minHeight: "4px",
                    maxHeight: "4px",
                    backgroundColor: "rgba(217, 217, 217, 1)",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div> */}
      </div>
      <div className="flex w-full mt-4 px-20">
        <div
          className="w-full"
          style={{
            minHeight: "1px",
            maxHeight: "1px",
            backgroundColor: "rgba(36, 16, 54, 0.30)",
          }}
        />
      </div>
      <div className="w-full px-20 mt-8">
        {currentForm === 1 && (
          <LaSalleRegistrationDetails
            draftData={univFormData}
            callbackData={regisDetailData}
            currentForm={currentForm}
            setCurrentForm={SetCurrentFormIndex}
          />
        )}
        {currentForm === 2 && (
          <LaSalleContactInformation
            firstName={auth.fname}
            lastName={auth.lname}
            email={auth.email}
            phone={auth.phone.replace("-", "")}
            draftData={univFormData}
            callbackData={contactInfoData}
            currentForm={currentForm}
            setCurrentForm={SetCurrentFormIndex}
          />
        )}
        {currentForm === 3 && (
          <LaSallePersonalInformation
            draftData={univFormData}
            callbackData={personalInfoData}
            currentForm={currentForm}
            setCurrentForm={SetCurrentFormIndex}
          />
        )}
        {currentForm === 4 && (
          <LaSalleBirthPlaceAndLegalStatus
            draftData={univFormData}
            callbackData={legalStatusData}
            currentForm={currentForm}
            setCurrentForm={SetCurrentFormIndex}
          />
        )}
        {currentForm === 5 && (
          <LaSalleAddress
            draftData={univFormData}
            callbackData={addressData}
            currentForm={currentForm}
            setCurrentForm={SetCurrentFormIndex}
          />
        )}
        {currentForm === 6 && (
          <LaSalleAcademicInformation
            draftData={univFormData}
            callbackData={academicInfoData}
            callbackPrevData={academicInfoPrevData}
            currentForm={currentForm}
            setCurrentForm={SetCurrentFormIndex}
            isSubmitProcess={isSubmitProcess}
          />
        )}
      </div>

      {/* draft modal */}

      <Transition appear show={showDraftModal} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-max h-full transform overflow-hidden ${
                    screenWidth >= 1024 ? "rounded-3xl" : "rounded-2xl"
                  } bg-white text-left align-middle shadow-xl transition-all relative z-50`}
                >
                  <div
                    className="flex flex-col items-center rounded-20px bg-white p-10"
                    style={{
                      // width: `${screenWidth >= 1024 && "894px"}`,
                      // height: `${screenWidth >= 1024 && "467px"}`,
                      borderRadius: "20px",
                    }}
                  >
                    <img
                      src="/illu_admission_draft.svg"
                      style={{
                        minWidth: screenWidth >= 1024 && "417px",
                        minHeight: screenWidth >= 1024 && "341px",
                        maxWidth: screenWidth >= 1024 && "417px",
                        maxHeight: screenWidth >= 1024 && "341px",
                      }}
                    />
                    <div
                      className="flex flex-col w-full items-center justify-start gap-2 tablet:gap-4 mt-8"
                      style={{
                        minWidth: screenWidth >= 1024 && "543px",
                        maxWidth: screenWidth >= 1024 && "543px",
                      }}
                    >
                      <p
                        className={`text-center font-bold ${
                          screenWidth >= 1024 ? "text-3xl" : "text-xl"
                        } text-black1`}
                        style={{ lineHeight: screenWidth >= 1024 && "40.32px" }}
                      >
                        Kamu ada Draft untuk jalur ini!
                      </p>
                      <p
                        className={`text-left font-normal ${
                          screenWidth >= 1024 ? "text-base" : "text-sm"
                        }`}
                        style={{
                          color: "rgba(110, 116, 118, 1)",
                          lineHeight: screenWidth >= 1024 && "24px",
                        }}
                      >
                        Kamu memiliki 1 draft yang telah kamu isi sebelumnya
                        untuk pendaftaran melalui jalur ini! Sebentar lagi, kamu
                        akan diarahkan untuk melengkapi draft kamu ya!
                      </p>
                      <button
                        onClick={() => {
                          setShowDraftModal(false);
                        }}
                        className="w-full py-3 bg-primaryColor rounded-lg text-center font-bold text-base text-white mt-2 tablet:mt-0"
                      >
                        Lanjutkan Formulir!
                      </button>
                    </div>
                    {/* <button
                      type="button"
                      className="absolute top-6 left-6 z-30"
                      onClick={() => {
                        setVideoModalOpen(false);
                      }}
                    >
                      <MdClose size={26} color="black " />
                    </button>
                    <div
                      className="relative flex flex-col justify-center items-center p-8 tablet:p-16 rounded-20px tablet:rounded-none"
                      style={{ backgroundColor: "#F1F3F6" }}
                    >
                      <img
                        className="h-48 w-48 tablet:h-60 tablet:w-60 mx-auto mb-8 mt-1"
                        src="/ic_qrcode_wa.jpg"
                        alt="Hero"
                        style={{
                          border: "20px solid #F9C60B",
                          borderRadius: "44px",
                        }}
                      />
                      <p
                        className="text-center font-medium text-base text-black1 w-60"
                        style={{ lineHeight: "24px" }}
                      >
                        Jangan lupa scan QR Code untuk bergabung dengan para
                        Explorers lainnya disini ya!
                      </p>
                    </div>
                    <div className="w-full h-full flex flex-col justify-start items-start px-4 py-6 tablet:p-12 overflow-auto">
                      <p className="text-left font-bold tablet:font-normal text-base tablet:text-2xl text-black1">
                        Yeay! Kamu telah terdaftar pada kelas:
                      </p>
                      <p className="text-left font-semibold text-xl tablet:text-3xl text-primaryColor mt-2 tablet:mt-4">
                        {classData.info.topic}
                      </p>
                      <p className="text-left font-normal text-sm tablet:text-base text-black1 mt-4 tablet:mt-6">
                        Link untuk mengikuti kelas sudah terkirim ke email kamu.
                        Kamu juga bisa mengaksesnya melalui “Aktivitas Kamu”
                        pada Profile! Sampai jumpa di kelas ya 👋
                      </p>
                      <div className="flex flex-grow"></div>
                      <div className="w-full flex flex-col tablet:flex-row justify-between items-center mt-12 tablet:mt-0">
                        <button
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          <div className="text-center font-semibold text-base text-primaryColor px-10 py-3 rounded-lg">
                            Lihat Aktivitasku
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            navigate("/explore-kelas");
                          }}
                        >
                          <div className="text-center font-semibold text-base text-white bg-primaryColor px-10 py-3 rounded-lg">
                            Explore kelas lain yuk
                          </div>
                        </button>
                      </div>
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* post check modal */}

      <PostCheckModal
        isModalShow={showPostCheckModal}
        screenWidth={screenWidth}
        postCheckData={postCheckData}
        submitForm={submitForm}
        closeModal={closeModal}
      />
    </LayoutV2>
  );
}
