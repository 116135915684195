import { ChevronRightIcon } from "@heroicons/react/solid";
import { useState } from "react";

export default function ButtonBiayaMasuk({ item, screenWidth }) {
  const [isHovered, setIsHovered] = useState(false);
  function truncateString(str) {
    if (str?.length <= 27) {
      return str;
    }
    return str?.slice(0, 27) + "...";
  }

  return (
    <button
      onClick={() => {
        item.cta_url &&
          window.open(item.cta_url, "_blank", "noopener,noreferrer");
      }}
      onMouseEnter={() => {
        if (item.cta_url) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (item.cta_url) {
          setIsHovered(false);
        }
      }}
      disabled={!item.cta_url}
      className={`flex flex-row h-full rounded-lg bg-red justify-start items-center ${
        screenWidth < 1024
          ? "bg_white"
          : isHovered
          ? "bg-primaryColor"
          : "bg-white"
      } mr-4 tablet:mr-0`}
      style={{
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        minWidth: screenWidth < 1024 && "346px",
        width: screenWidth >= 1024 && isHovered ? "387.89px" : "368px",
        maxWidth:
          screenWidth < 1024 ? "346px" : isHovered ? "387.89px" : "368px",
        minHeight: isHovered ? "116.46px" : "111px",
        maxHeight: isHovered ? "116.46px" : "111px",
        gap: "20px",
        cursor: !item.cta_url ? "default" : "pointer",
        paddingLeft: "18px",
        paddingRight: "18px",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <div
        className="flex items-center justify-center"
        style={{
          backgroundColor:
            screenWidth < 1024
              ? "rgba(102, 46, 155, 0.2)"
              : isHovered
              ? "rgba(255, 255, 255, 0.2)"
              : "rgba(102, 46, 155, 0.2)",
          width: "49.78px",
          height: "49.78px",
          borderRadius: "7.11px",
        }}
      >
        <img
          alt="gambar"
          src={
            !item.cta_url
              ? "/icon_toga_primary_v2.svg"
              : isHovered
              ? "/icon_link.svg"
              : "/icon_link_primary.svg"
          }
        />
      </div>
      <div
        className="flex flex-col justify-center items-start"
        style={{ gap: "8px" }}
      >
        <p
          className={`text-left font-semibold text-sm ${
            screenWidth < 1024
              ? "text-black188"
              : isHovered
              ? "text-white"
              : "text-black188"
          }`}
          style={{ lineHeight: "14.22px" }}
        >
          {item.cta_url ? item.label.split(" - ")[1] : item.label}
        </p>
        <p
          className={`text-left font-bold text-xl transform transition-opacity ease-in-out duration-300 ${
            screenWidth < 1024
              ? "text-primaryColor"
              : isHovered
              ? "text-white"
              : "text-primaryColor"
          }`}
          style={{ lineHeight: "25px" }}
        >
          {item.cta_url ? (
            <span className="flex flex-row gap-2 justify-start items-end line-clamp-1 overflow-ellipsis">
              {/* {`Link ${item.label}`} */}
              Link Biaya Kampus
              <ChevronRightIcon
                className="w-6 h-6"
                style={{
                  color:
                    screenWidth < 1024
                      ? "black"
                      : isHovered
                      ? "white"
                      : "black",
                }}
              />
            </span>
          ) : (
            <span>
              {truncateString(
                `Rp ${(item.min_fee / 1000000).toLocaleString()} Juta - Rp ${(
                  item.max_fee / 1000000
                ).toLocaleString()} Juta` ?? ""
              )}
            </span>
          )}
        </p>
      </div>
    </button>
  );
}
