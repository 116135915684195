import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import PageLoading from "../pages/PageLoading";
import CharCard from "./CharCard";
import CharSectionMajorCard from "./CharSectionMajorCard";

export default function CharSection({ order, screenWidth, data }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  if (!data) {
    return <PageLoading />;
  }
  return (
    <div className="flex flex-col h-full w-full mt-10 tablet:mt-32">
      <div className="flex flex-col bg-gradient-to-b from-white to-white2 px-4 mac:pl-30 mac:pr-44">
        <div className="flex flex-col tablet:flex-row tablet:items-center tablet:gap-32">
          <div className="flex flex-col tablet:items-start mac:w-2/3">
            <p
              className="text-left tablet:text-center text-base font-bold tracking-widest leading-tight"
              style={{ color: "#43BCCD" }}
            >
              {order}
            </p>
            <p className="text-left text-black2 text-2xl font-bold mt-4 mac:text-48px">
              {data.label_long}
            </p>
            <p className="text-left text-black21 text-base font-normal mt-4 mac:text-xl">
              {data.description_short}
            </p>
            <button
              type="button"
              onClick={openModal}
              className="flex flex-row justify-start items-center gap-3 mt-6"
            >
              <p className="text-left text-primaryColor text-base font-semibold">
                Kenali lebih dalam
              </p>
              <ArrowRightIcon className="w-5 h-5" color={"#662E9B"} />
            </button>
          </div>
          <div className="mac:w-1/3">
            <CharCard
              isRotated={true}
              charTitle={data.label_short}
              nickName={data.nick_name}
              character={data.code}
            />
          </div>
        </div>
        <p className="text-left text-black2 text-xl font-bold mt-12 mb-6 mac:text-28px">
          {`Jurusan yang ${data.label_short.split(" ")[1]} banget`}
        </p>
        <div className="flex flex-row items-center justify-start gap-8 overflow-x-auto pb-4">
          {data.majors.map((data, index) => {
            return (
              <CharSectionMajorCard
                data={data}
                key={index}
                screenWidth={screenWidth}
              />
            );
          })}
        </div>
      </div>
      <div className="pt-12 px-4 bg-gradient-to-b from-white2 to-white tablet:w-full tablet:flex tablet:justify-end mac:pr-20 tablet:pt-0">
        {/* DO NOT DELETE THIS LINES BELOW!! */}
        <Link to={`/explore-jurusan?karakter=${data.code}`} className="pt-12">
          <div className="flex flex-row gap-3 items-center justify-center w-full h-auto py-3 bg-white text-center text-base text-primaryColor font-semibold rounded-lg tablet:w-max tablet:px-6 mac:my-0 shadow-sm">
            Lihat Jurusan Lainnya
            <ArrowRightIcon className="w-5 h-5" color={"#662E9B"} />
          </div>
        </Link>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full h-full transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all"
                >
                  <div className="w-full h-full flex flex-col tablet:flex-row tablet:gap-14 tablet:items-center">
                    <div
                      className="relative h-full rounded-3xl px-6 pt-6 pb-8 tablet:w-1/2 tablet:py-14 tablet:px-16"
                      style={{ backgroundColor: "rgba(241, 243, 246, 1)" }}
                    >
                      <button
                        type="button"
                        onClick={closeModal}
                        className="tablet:absolute tablet:top-6 tablet:left-6"
                      >
                        <MdClose size={24} color="rgba(0, 0, 0, 0.54)" />
                      </button>
                      <CharCard
                        charTitle={data.label_short}
                        nickName={data.nick_name}
                        character={data.code}
                      />
                    </div>
                    <div className="h-auto flex flex-col p-6 tablet:w-1/2">
                      <p className="text-left text-black2 text-2xl font-bold tracking-tighter tablet:mt-0">
                        {data.label_long}
                      </p>
                      <p className="text-left text-black2 text-base font-normal mt-6">
                        {data.description_long.split("\n\n")[0]}
                      </p>
                      <p className="text-left text-black2 text-base font-normal mt-4">
                        {data.description_long.split("\n\n")[1]}
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
