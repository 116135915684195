import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Layout from "../../../components/Layout";

import { authState } from "../../../atoms/authState";
import Button from "../../../components/base/Button";
import { StarIcon } from "@heroicons/react/solid";
import FavoriteButton from "../../../components/FavoriteButton";
import CardUniversity from "../../../components/CardUniversity";
import { api, apiAnom } from "../../../utils/api";
import { useEffect, useState } from "react";
import LayoutV2 from "../../../components/Layout_V2";

export default function MajorDetail() {
  let { id } = useParams();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;

  const { state } = useLocation();
  const navigate = useNavigate();
  const [listData, setListData] = useState();
  const [isFavorite, setIsFavorite] = useState(state?.isFavorite ?? false);

  useEffect(() => {
    const getMajorDetail = () => {
      _api
        .get(`major/university/detail/${id}`)
        .then((res) => {
          if (res.data.data.slug !== "") {
            navigate(`/explore-jurusan/${res.data.data.slug}`, {
              replace: true,
            });
          }
          setListData(res.data.data);
          setIsFavorite(res.data.data.favorite);
        })
        .catch((_) => {
          setListData({});
        });
    };
    getMajorDetail();
  }, [_api, auth.isAuth, id]);

  const addMajorToFav = (fav, id) => {
    if (fav && !auth.isAuth) {
      navigate({
        pathname: "/signin",
        search: `?redirect=/explore-jurusan/detail/${id}`,
      });
    }

    const url = fav ? "/favorites/major/add" : "/favorites/major/delete";

    _api
      .post(url, {
        majors_of_universities_id: id,
      })
      .then(() => {
        setIsFavorite(fav);
      })
      .catch(() => {
        setIsFavorite(false);
      });
  };

  return (
    <LayoutV2>
      <div>
        <div className="flex flex-row items-center justify-start text-xss text-left lg:text-base font-medium py-4 -ml-2">
          <Link to="/explore-jurusan">Explore Jurusan</Link>
          <div>&nbsp;{` > ${listData?.name}`}</div>
        </div>
        <div className="-mx-6 relative group divide-y divide-gray-200 overflow-hidden bg-white shadow">
          <div className="shadow-2xl">
            <FavoriteButton
              className="top-3 right-5 lg:top-5"
              isFavorite={isFavorite}
              onChangeFavorite={() => {
                //callback fav, and id;
                addMajorToFav(!isFavorite, listData.id);
              }}
            />
          </div>
          <div className="">
            <img src={listData?.img_url} alt="" />
          </div>
          <div className="absolute bottom-0 w-full">
            <div
              className="relative group"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.86)",
              }}
            >
              <div className="px-8 py-5 tablet:p-4">
                <div className="flex items-center gap-4 tablet:py-2">
                  <div className="focus:outline-none">
                    <p className="text-lg font-semibold text-gray-900">
                      {listData?.name}
                    </p>
                  </div>
                </div>
              </div>
              {/* <FavoriteButton
                className="top-4 right-4"
                isFavorite={isFavorite}
                onChangeFavorite={() => {
                  //callback fav, and id;
                  addMajorToFav(!isFavorite, listData.id);
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl py-4 sm:p-6 lg:p-8">
          <div className="border border-gray-400 rounded-lg p-4 tablet:p-8 mt-5">
            <h3 className="font-medium">Deskripsi Jurusan</h3>
            <div
              className="text-sm text-gray-600 text-left my-2 whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: listData?.description }}
            ></div>
          </div>
          <div
            className="my-8 -mx-16 lg:mx-0 px-16 py-8"
            style={{
              backgroundColor: "#D9D9D980",
            }}
          >
            <h2 className="text-gray-800 mb-2">Daftar Mata Kuliah</h2>
            <ul className="list-decimal list-inside mt-5">
              {listData?.subjects.map((i) => {
                return (
                  <li key={i} className="text-xs text-gray-400">
                    {i}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="my-8 ">
            <h2 className="text-gray-600 mb-2">Prospek Karir</h2>
            <div className="flex flex-row gap-2 tablet:gap-4 py-3 overflow-x-auto">
              {listData?.career_prospects.map((d, i) => {
                return (
                  <div
                    key={i}
                    className="py-2 px-4 rounded-lg text-sm text-white shadow-lg line-clamp-2"
                    style={{
                      minWidth: "170px",
                      backgroundColor: "#04C4B2",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    {d}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="my-8 ">
            <h2 className="text-gray-600 mb-2">Rekomendasi Kampus</h2>
            <div className="flex flex-row gap-2 tablet:gap-6 overflow-x-auto pb-4">
              {listData?.univ_recommendations.map((d, i) => {
                return (
                  <CardUniversity
                    key={d.id}
                    isFavorite={
                      listData?.favorite_uni_ids.includes(d.id) ?? false
                    }
                    // onChangeFavorite={(fav, id) => console.log(fav, id)}
                    {...d}
                  />
                );
              })}
            </div>
          </div>
          <div className="mt-16 pb-8">
            <Button
              variant={isFavorite ? "secondary" : "primary"}
              size="small"
              className="m-auto"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addMajorToFav(!isFavorite, listData.id);
              }}
            >
              {isFavorite ? (
                <>
                  <StarIcon className="h-6 w-6 text-purple-700" />
                  <span className="text-purple-700">
                    Telah Ditambahkan ke Favorit
                  </span>
                </>
              ) : (
                <>
                  <StarIcon className="h-6 w-6 text-white" />
                  <span>Tambahkan ke Favorit</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
