import moment from "moment";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../../../utils/utils";

export default function LaSalleContactInformation({
  firstName,
  lastName,
  draftData,
  callbackData,
  setCurrentForm,
  currentForm,
}) {
  const [contactEmail, setContactEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [startDate, setStartDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState("01/01/0001");
  const [errors, setErrors] = useState({});

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  useEffect(() => {
    if (draftData) {
      if (draftData.tanggalLahir) {
        let birthDate = draftData.tanggalLahir;
        const [day, month, year] = birthDate.split("/").map(Number);
        const dateObject = new Date(year, month - 1, day);
        setStartDate(dateObject);
      }
      setDateOfBirth(draftData.tanggalLahir ?? "01/01/0001");
      setContactEmail(draftData.email ?? "");
      setContactPhone(draftData.phone ?? "");
    }
  }, [draftData]);

  function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    callbackData({
      tanggalLahir: elements[2].value,
      email: elements[3].value,
      phone: elements[4].value,
    });
    setCurrentForm(currentForm + 1);
  }

  function toPrevStep() {
    callbackData({
      tanggalLahir: dateOfBirth,
      email: contactEmail,
      phone: contactPhone,
    });
    setCurrentForm(currentForm - 1);
  }

  return (
    <form
      onSubmit={submitForm}
      className="flex flex-col w-full justify-start items-start pb-10"
    >
      <div className="flex flex-col w-full justify-start">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
          <div className="mt-2">
            <label
              htmlFor="firstName"
              className="block text-base font-bold leading-6 text-gray-900"
              style={{ lineHeight: "20px" }}
            >
              Nama Depan
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              defaultValue={firstName}
              disabled={true}
              className="form-input w-full text-gray-800 rounded-40px mt-4"
              style={{
                color: "rgba(129, 127, 130, 1)",
              }}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="lastName"
              className="block text-base font-bold leading-6 text-gray-900"
              style={{ lineHeight: "20px" }}
            >
              Nama Belakang
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              defaultValue={lastName}
              disabled={true}
              className="form-input w-full text-gray-800 rounded-40px mt-4"
              style={{
                color: "rgba(129, 127, 130, 1)",
              }}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="birth-date"
              className="block w-full text-base font-bold leading-6 text-gray-900"
            >
              Tanggal Lahir
            </label>
            <div className="mt-2">
              <ReactDatePicker
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setDateOfBirth(
                    moment(date).tz("Asia/Jakarta").format("DD/MM/YYYY")
                  );
                }}
                className={`form-input block w-full text-gray-800 rounded-40px ${
                  mapHasKeyAndValueNotEmpty(errors, "phone")
                    ? "border-red-400"
                    : ""
                }`}
              />
            </div>
          </div>

          <div className="mt-2">
            <label
              htmlFor="studentEmail"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              defaultValue={contactEmail}
              onChange={(e) => {
                setContactEmail(e.target.value);
              }}
              onInvalid={(F) => F.target.setCustomValidity("Email harus diisi")}
              className="form-input w-full text-gray-800 rounded-40px mt-4"
              style={{
                color: "rgba(129, 127, 130, 1)",
              }}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="studentPhone"
              className="block text-base font-bold leading-6 text-gray-900"
            >
              Telepon
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              defaultValue={contactPhone}
              onChange={(e) => {
                setContactPhone(e.target.value);
              }}
              onInvalid={(F) =>
                F.target.setCustomValidity("Telepon harus diisi")
              }
              className="form-input w-full text-gray-800 rounded-40px mt-4"
              style={{
                color: "rgba(129, 127, 130, 1)",
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
        <button
          onClick={() => {
            toPrevStep();
          }}
          className="py-3 bg-white text-center font-semibold text-base text-primaryColor rounded-lg mt-16 border-2 border-primaryColor"
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Sebelumnya
        </button>

        <button
          type="submit"
          disabled={
            dateOfBirth === "" ||
            dateOfBirth === "01/01/0001" ||
            contactEmail === "" ||
            contactPhone === ""
          }
          className={`py-3 ${
            dateOfBirth === "" ||
            dateOfBirth === "01/01/0001" ||
            contactEmail === "" ||
            contactPhone === ""
              ? "bg-gray-400"
              : "bg-primaryColor"
          } text-center font-semibold text-base text-white rounded-lg mt-16`}
          style={{ lineHeight: "24px", minWidth: "200px" }}
        >
          Selanjutnya
        </button>
      </div>
    </form>
  );
}
