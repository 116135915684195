import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import {api, apiAnom, notifError} from "../utils/api";
import Cookies from "js-cookie";
import {CheckCircleIcon} from "@heroicons/react/outline";
import {useSetRecoilState} from "recoil";
import {authState} from "../atoms/authState";
import LayoutV2 from "../components/Layout_V2";

export default function CreatePassword() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMatch, setIsMatch] = useState("");
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.elements[0].value !== e.target.elements[1].value) {
      setIsMatch("Buat password dan Konfirmasi password tidak cocok.");
      return;
    }
    setIsMatch("");
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const token = params.get("t");
    if (!token) {
      notifError("Token is empty or invalid");
      return;
    }
    apiAnom
      .post("/students/verification", {
        password: e.target.elements[0].value,
        token,
      })
      .then((response) => {
        Cookies.set("user.token", response.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });
        api
            .get("/me", {
              headers: {
                Authorization: "Bearer " + response.data.data.request_token,
              },
            })
            .then((res) => {
              setAuth({ ...res.data.data, tmb_result_id: response.data.data.tmb_result_id, isAuth: true });
              navigate("/profile-edit");
            })
            .catch(notifError)
            .finally(() => {
              setLoading(false);
            });
      })
      .catch((err) => {
        notifError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (success) {
    return (
      <LayoutV2>
        <div className="pt-8 pb-12 tablet:pt-36 tablet:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-4">
            <CheckCircleIcon className="h-20 w-20 mx-auto text-green-500 mb-5" />
          </div>
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1 className="tablet:h1 h3 mb-4">Berhasil!</h1>
            <p className="text-gray-600">
              Akunmu telah berhasil dibuat.
            </p>
            <p className="text-gray-600">
              Yuk, lengkapi profilmu dan mulai eksplorasi berbagai kelas menarik dari perguruan tinggi pilihan!
            </p>
          </div>
          <div className="max-w-sm mx-auto">
            <div className="flex items-center justify-center">
              <Link to="/profile-edit">
                <Button>Lengkapi Profil</Button>
              </Link>
            </div>
          </div>
        </div>
      </LayoutV2>
    );
  }

  return (
    <LayoutV2>
      <div className="pt-8 pb-12 tablet:pt-16 tablet:pb-20">
        <div className="max-w-3xl mx-auto text-center pb-12">
          <h1 className="tablet:h1 h3">Buat Password!</h1>
        </div>
        <div className="max-w-sm mx-auto">
          <form onSubmit={onSubmit}>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full mb-4 px-3">
                <label
                  className="block text-gray-800 text-sm font-medium mb-1"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  className="form-input w-full text-gray-800"
                  placeholder="masukkan password"
                  minLength={5}
                  required
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block text-gray-800 text-sm font-medium mb-1"
                  htmlFor="password"
                >
                  Konfirmasi password
                </label>
                <div>
                  <input
                    id="password"
                    type="password"
                    className="form-input w-full text-gray-800"
                    placeholder="Masukkan konfirmasi password"
                    minLength={5}
                    required
                  />
                  {isMatch && (
                    <div className="mt-2 text-red-400">{isMatch}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <Button disabled={loading} type="submit" className="w-full">
                  Konfirmasi
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutV2>
  );
}
