import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import FormFieldText from "./FormFieldText";
import FormFieldDropdown from "./FormFieldDropdown";

export default function FormField({
  id,
  fieldType,
  title,
  placeholder,
  isRequired,
  isPassword,
  value,
  onChange,
  ddOptions,
  isDisabled,
  onInput,
}) {
  const FormFieldType = (type) => {
    switch (type) {
      case "dropdown":
        return (
          <FormFieldDropdown
            id={id}
            onInput={onInput}
            ddOptions={ddOptions}
            title={title}
            placeholder={placeholder}
            value={value}
            isRequired={isRequired}
          />
        );
      default:
        return (
          <FormFieldText
            id={id}
            onInput={onInput}
            title={title}
            placeholder={placeholder}
            value={value}
            isPassword={isPassword}
            isRequired={isRequired}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        );
    }
  };

  return FormFieldType(fieldType);
}
