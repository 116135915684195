import { Link, useNavigate } from "react-router-dom";
import { InputPassword } from "../../components/InputPassword";
import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import { apiAnom, getAnomToken, notifError } from "../../utils/api";
import Headerv2 from "../../components/Header_v2";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rmAuthState } from "../../atoms/rmAuthState";
import { useEffect } from "react";
import Cookies from "js-cookie";
import PageLoading from "../PageLoading";
import LayoutV2 from "../../components/Layout_V2";

export default function RMSignin() {
  const navigate = useNavigate();
  const auth = useRecoilValue(rmAuthState);
  const setAuth = useSetRecoilState(rmAuthState);

  useEffect(() => {
    if (!Cookies.get("anom.token")) {
      getAnomToken().then((_) => {
        window.location.reload();
      });
    }
    if (auth.isAuth) {
      navigate("/summer-camp-2024");
    }
  }, []);

  const onSubmit = async (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();

    apiAnom
      .post("/rm/login", {
        password: elements[0].value,
      })
      .then((res) => {
        Cookies.set("rm.token", res.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });
        setAuth({ isAuth: true });
        navigate("/summer-camp-2024");
      })
      .catch(notifError);
  };

  if (!Cookies.get("anom.token")) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 isRM={true}>
      <div className="p-4 bg-primaryColor flex flex-grow items-center justify-center w-full h-full overflow-y-auto">
        <div className="flex flex-col w-full rounded-20px bg-white py-10 px-4 my-8 tablet:w-120">
          <form onSubmit={onSubmit} className="flex flex-col gap-6">
            {/* <div className="flex flex-col">
            <label
              className="text-left font-medium text-base text-black3"
              htmlFor="email"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              className="form-input w-full text-gray-800 rounded-40px mt-2"
              placeholder="Masukkan alamat e-mail"
              required
            />
          </div> */}
            <div className="flex flex-col">
              <label
                className="text-left font-medium text-base text-black3"
                htmlFor="password"
              >
                Password
              </label>
              <div className="mt-2">
                <InputPassword
                  id="password"
                  name="password"
                  className="form-input w-full text-gray-800"
                  placeholder="Masukkan password"
                  rounded={true}
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="flex w-full items-center justify-center py-4 rounded-lg mt-4 bg-primaryColor text-white font-medium"
            >
              Masuk
            </button>
          </form>
          <button
            type="button"
            onClick={() => {
              navigate("/rm/dashboard");
            }}
            className="flex w-full items-center justify-center py-4 rounded-lg mt-4 bg-primaryColor text-white font-medium"
          >
            Lihat Dashboard
          </button>
        </div>
      </div>
    </LayoutV2>
  );
}
