import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/solid";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function RMSubFinishPage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center w-full">
      <p
        className="w-full text-center font-normal text-xs text-white object-center"
        style={{ width: "208px", lineHeight: "15.12px", marginTop: "194px" }}
      >
        <span className="font-bold">
          Terima kasih Anda telah menyelesaikan formulir Kelengkapan Data
          Peserta <br />
          OCBC Summer Camp 2024!
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Untuk informasi lengkap cek:</span>
        <br />
        <span className="font-bold" style={{ color: "rgba(0, 209, 255, 1)" }}>
          <a
            // don't forget to put https://
            href="https://www.explorationid.com/explore-summer-camp"
            target="_blank"
            rel="noreferrer"
          >
            www.explorationid.com/explore-summer-camp
          </a>
        </span>
      </p>
      <button
        onClick={() => {
          navigate("/");
        }}
        className="py-2 px-7 rounded-md text-center font-bold text-sm text-black1 mt-20"
        style={{ backgroundColor: "rgba(255, 202, 45, 1)" }}
      >
        Kembali ke Homepage
      </button>
    </div>
  );
}
