import { Fragment, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import PaymentInfoAccordion from "../components/PaymentInfoAccordion";
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import PageLoading from "./PageLoading";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Dialog, Transition } from "@headlessui/react";
import LayoutV2 from "../components/Layout_V2";

export default function PaymentStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const param = new URLSearchParams(window.location.search);
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const invoiceNumberRef = useRef(null);
  const [totalFee, setTotalFee] = useState(151000);
  const invoiceNumber = param.get("invoice_number");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [invoiceData, setInvoiceData] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    api
      .post(`/students/univ-admission/invoice/detail`, {
        invoice_number: invoiceNumber,
      })
      .then((res) => {
        setInvoiceData(res.data.data);
        setPaymentStatus(res.data.data.payment_status);
        setIsLoading(false);
      })
      .catch((_) => {
        setInvoiceData({});
      });

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  function Separator({ margin }) {
    return (
      <div
        className={`flex w-full border-1 ${margin}`}
        style={{ borderColor: "rgba(217, 219, 219, 1)" }}
      />
    );
  }

  function DashedSeparator({ margin }) {
    return (
      <svg
        className={`${margin}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 574 3"
        fill="none"
      >
        <path
          d="M1 1.5H573"
          stroke="#D9DBDB"
          strokeLinecap="round"
          strokeDasharray="5 5"
          strokeWidth="1px"
        />
      </svg>
    );
  }

  function printDocument() {
    setShowInvoice(true);
    const input = document.getElementById("divToPrint");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${invoiceNumber}.pdf`);
      setIsDownloading(false);
      setShowInvoice(false);
    });
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0px">
      <div
        className="flex flex-col tablet:flex-row w-full items-start justify-start tablet:justify-evenly gap-22 tablet:px-20 tablet:pt-20 tablet:pb-36"
        style={{ backgroundColor: "rgba(241, 243, 246, 1)" }}
      >
        <div
          className={`flex flex-col w-full bg-white ${
            screenWidth >= 1024 && "rounded-20px"
          }`}
        >
          <div
            // id="divToPrint"
            className="flex flex-col w-full justify-center items-center p-4 mac:p-10 bg-white"
          >
            <div className="flex flex-row items-center justify-between w-full">
              <p
                className="text-left font-bold text-xl mac:text-2xl text-black1"
                style={{ lineHeight: screenWidth < 1024 ? "32px" : "36px" }}
              >
                Informasi Pembayaran
              </p>
              <div
                className="px-3 py-1 rounded-2xl text-center font-semibold text-sm mac:text-base text-white"
                style={{
                  lineHeight: screenWidth < 1024 ? "20px" : "24px",
                  backgroundColor:
                    paymentStatus === "PAID"
                      ? "rgba(0, 168, 119, 1)"
                      : "rgba(254, 82, 68, 1)",
                }}
              >
                {paymentStatus === "PAID" ? "Sukses" : "Gagal"}
              </div>
            </div>
            <Separator margin={"mt-4"} />
            <div className="flex flex-col tablet:flex-row gap-2 tablet:gap-0 justify-between items-start tablet:items-center mt-6 w-full">
              <div className="flex flex-col justify-center items-start">
                <p
                  className="text-left font-normal text-base"
                  style={{
                    lineHeight: "24px",
                    color: "rgba(123, 128, 130, 1)",
                  }}
                >
                  Nomor Invoice
                </p>
                <p
                  ref={invoiceNumberRef}
                  className="text-left font-extrabold text-base text-black1"
                  style={{ lineHeight: "24px" }}
                >
                  {invoiceNumber}
                </p>
              </div>
              <div
                className={`flex flex-col justify-center ${
                  screenWidth < 1024 ? "items-start" : "items-end"
                }`}
              >
                <p
                  className={`${
                    screenWidth < 1024 ? "text-left" : "text-right"
                  } font-normal text-base`}
                  style={{
                    lineHeight: "24px",
                    color: "rgba(123, 128, 130, 1)",
                  }}
                >
                  Tanggal Pembelian
                </p>
                {paymentStatus === "PAID" && (
                  <p
                    className="text-right font-extrabold text-base text-black1"
                    style={{ lineHeight: "24px" }}
                  >
                    {moment(invoiceData.purchase_date).format(
                      "DD MMMM YY, HH:MM"
                    ) + " WIB"}
                  </p>
                )}
              </div>
            </div>
            {screenWidth < 1024 && (
              <div className="flex flex-col w-full items-center justify-start mt-4">
                <img
                  src={`${
                    paymentStatus === "PAID"
                      ? "./illu_payment_success.svg"
                      : "./illu_payment_failed.svg"
                  }`}
                />
                <p
                  className="text-center font-bold text-xl mac:text-3xl text-black1 mt-12"
                  style={{ lineHeight: "44px" }}
                >
                  {paymentStatus === "PAID"
                    ? "Pembayaran Telah Berhasil"
                    : "Pembayaran Gagal"}
                </p>
                <p
                  className="text-center font-normal text-base mt-4 px-5"
                  style={{
                    lineHeight: "24px",
                    color: "rgba(123, 128, 130, 1)",
                  }}
                >
                  {paymentStatus === "PAID"
                    ? "Yay! pembayaranmu telah kami terima, informasi selanjutnya kamu akan dihubungi pihak Universitas ya"
                    : "Yah pembayaranmu belum kami terima nih"}
                </p>
                <button
                  onClick={() => {
                    navigate("/explore-penerimaan");
                  }}
                  className="flex justify-center w-full py-3 rounded-lg mt-8 bg-primaryColor text-center font-bold text-base text-white"
                  style={{ lineHeight: "24px" }}
                >
                  {paymentStatus === "PAID"
                    ? "Lihat admisi lainnya"
                    : "Beli lagi"}
                </button>
                {paymentStatus === "PAID" && screenWidth < 1024 && (
                  <button
                    disabled={isDownloading}
                    onClick={() => {
                      setIsDownloading(true);
                      setTimeout(() => {
                        printDocument();
                      }, 300);
                    }}
                    className={`flex flex-row gap-2 justify-center w-full rounded-lg text-center font-bold text-base ${
                      isDownloading
                        ? "bg-gray-400 text-white"
                        : "bg-white border-1 border-primaryColor text-primaryColor"
                    } mt-6 py-3`}
                    style={{
                      lineHeight: "24px",
                    }}
                  >
                    <img src="/icon_regis_download.svg" />
                    <p>
                      {isDownloading ? "Sedang Mengunduh..." : "Unduh Invoice"}
                    </p>
                  </button>
                )}
              </div>
            )}
            <Separator margin={"mt-4"} />
            <p
              className="text-left font-bold text-xl text-black1 mt-6"
              style={{ lineHeight: screenWidth < 1024 ? "25px" : "36px" }}
            >
              Detail Pembelian
            </p>
            <div
              className="flex flex-row w-full items-center justify-between p-5 rounded-2xl mt-6"
              style={{ border: "1px dashed var(--blue-primary, #43BCCD)" }}
            >
              <p
                className="text-left font-extrabold text-sm mac:text-base text-black1"
                style={{ lineHeight: "24px" }}
              >
                {invoiceData.admission_name}
              </p>
              <p
                className="text-right font-extrabold text-sm mac:text-base text-black1"
                style={{ lineHeight: "24px" }}
              >
                {`Rp ${invoiceData.total_price.toLocaleString()}`}
              </p>
            </div>
            <Separator margin={"mt-6"} />
            <p
              className="text-left font-bold text-xl text-black1 mt-6"
              style={{ lineHeight: "36px" }}
            >
              Rincian Pembayaran
            </p>
            <div className="flex flex-row justify-between items-center w-full mt-6">
              <p
                className="text-left font-normal text-base"
                style={{
                  lineHeight: "24px",
                  color: "rgba(123, 128, 130, 1)",
                }}
              >
                Metode Pembayaran
              </p>
              <p
                className="text-right font-extrabold text-base text-black1"
                style={{ lineHeight: "24px" }}
              >
                OCBC NISP Virtual Account
              </p>
            </div>
            <DashedSeparator margin={"mt-5"} />
            <div className="flex flex-row justify-between items-center w-full mt-5">
              <p
                className="text-left font-normal text-base"
                style={{
                  lineHeight: "24px",
                  color: "rgba(123, 128, 130, 1)",
                }}
              >
                Total Harga
              </p>
              <p
                className="text-right font-extrabold text-base text-black1"
                style={{ lineHeight: "24px" }}
              >
                {`Rp ${invoiceData.total_price.toLocaleString()}`}
              </p>
            </div>
            {/* <div className="flex flex-row justify-between items-center w-full mt-5">
              <p
                className="text-left font-normal text-base"
                style={{
                  lineHeight: "24px",
                  color: "rgba(123, 128, 130, 1)",
                }}
              >
                Biaya Transaksi
              </p>
              <p
                className="text-right font-extrabold text-base text-black1"
                style={{ lineHeight: "24px" }}
              >
                Rp 1000
              </p>
            </div> */}
            <DashedSeparator margin={"mt-5"} />
            <div className="flex flex-row justify-between items-center w-full mt-6">
              <p
                className="text-left font-extrabold text-base mac:text-xl text-black1"
                style={{
                  lineHeight: "32px",
                }}
              >
                Total Harga
              </p>
              <p
                className="text-right font-extrabold text-base mac:text-xl text-black1"
                style={{ lineHeight: "32px" }}
              >
                {`Rp ${invoiceData.total_price.toLocaleString()}`}
              </p>
            </div>
          </div>
          {paymentStatus === "PAID" && screenWidth >= 1024 && (
            <div className="w-full px-10 pb-10">
              <button
                disabled={isDownloading}
                onClick={() => {
                  setIsDownloading(true);
                  setTimeout(() => {
                    printDocument();
                  }, 300);
                }}
                className="flex items-center justify-center w-full rounded-lg text-center font-bold text-base text-white mt-6 py-3"
                style={{
                  backgroundColor: isDownloading
                    ? "rgba(153, 153, 153"
                    : "rgba(210, 0, 20, 1)",
                  lineHeight: "24px",
                }}
              >
                {isDownloading ? "Sedang Mengunduh..." : "Unduh Bukti Bayar"}
              </button>
            </div>
          )}
        </div>
        {screenWidth >= 1024 && (
          <div className="flex flex-col w-full items-center justify-start">
            <img
              src={`${
                paymentStatus === "PAID"
                  ? "./illu_payment_success.svg"
                  : "./illu_payment_failed.svg"
              }`}
            />
            <p
              className="text-center font-bold text-3xl text-black1 mt-12"
              style={{ lineHeight: "44px" }}
            >
              {paymentStatus === "PAID"
                ? "Pembayaran Telah Berhasil"
                : "Pembayaran Gagal"}
            </p>
            <p
              className="text-center font-normal text-base mt-4 px-5"
              style={{ lineHeight: "24px", color: "rgba(123, 128, 130, 1)" }}
            >
              {paymentStatus === "PAID"
                ? "Yay! pembayaranmu telah kami terima, informasi selanjutnya kamu akan dihubungi pihak Universitas ya"
                : "Yah pembayaranmu belum kami terima nih"}
            </p>
            <button
              onClick={() => {
                navigate("/explore-penerimaan");
              }}
              className="flex justify-center w-full py-3 rounded-lg mt-8 bg-primaryColor text-center font-bold text-base text-white"
              style={{ lineHeight: "24px" }}
            >
              {paymentStatus === "PAID" ? "Lihat admisi lainnya" : "Beli lagi"}
            </button>
          </div>
        )}
      </div>

      <Transition show={showInvoice} as={Fragment}>
        <Dialog onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 z-40"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.5)" }}
            />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center px-4 tablet:px-0 z-50 bg-white tablet:bg-none">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white">
                <div
                  id="divToPrint"
                  className="flex flex-col w-full justify-center items-center p-4 mac:p-10 bg-white"
                >
                  <div className="flex flex-row items-center justify-between w-full">
                    <p
                      className="text-left font-bold text-xl mac:text-2xl text-black1"
                      style={{
                        lineHeight: screenWidth < 1024 ? "32px" : "36px",
                      }}
                    >
                      Informasi Pembayaran
                    </p>
                    <div
                      className="px-3 py-1 rounded-2xl text-center font-semibold text-sm mac:text-base text-white"
                      style={{
                        lineHeight: screenWidth < 1024 ? "20px" : "24px",
                        backgroundColor:
                          paymentStatus === "PAID"
                            ? "rgba(0, 168, 119, 1)"
                            : "rgba(254, 82, 68, 1)",
                      }}
                    >
                      {paymentStatus === "PAID" ? "Sukses" : "Gagal"}
                    </div>
                  </div>
                  <Separator margin={"mt-1 tablet:mt-4"} />
                  <div className="flex flex-col tablet:flex-row gap-0 justify-between items-start tablet:items-center mt-1 tablet:mt-6 w-full">
                    <div className="flex flex-col justify-center items-start">
                      <p
                        className="text-left font-normal text-sm tablet:text-base"
                        style={{
                          lineHeight: "24px",
                          color: "rgba(123, 128, 130, 1)",
                        }}
                      >
                        Nomor Invoice
                      </p>
                      <p
                        ref={invoiceNumberRef}
                        className="text-left font-extrabold text-xs tablet:text-sm text-black1 pr-4"
                        style={{ lineHeight: "24px" }}
                      >
                        {invoiceNumber}
                      </p>
                    </div>
                    <div
                      className={`flex flex-col justify-center ${
                        screenWidth < 1024 ? "items-start" : "items-end"
                      }`}
                    >
                      <p
                        className={`${
                          screenWidth < 1024 ? "text-right" : "text-right"
                        } font-normal text-sm tablet:text-base`}
                        style={{
                          lineHeight: "24px",
                          color: "rgba(123, 128, 130, 1)",
                        }}
                      >
                        Tanggal Pembelian
                      </p>
                      {paymentStatus === "PAID" && (
                        <p
                          className="text-right font-extrabold text-xs tablet:text-sm text-black1"
                          style={{ lineHeight: "24px" }}
                        >
                          {moment(invoiceData.purchase_date).format(
                            "DD MMMM YY, HH:MM"
                          ) + " WIB"}
                        </p>
                      )}
                    </div>
                  </div>
                  <Separator margin={"mt-4"} />
                  <p
                    className="text-left font-bold text-xl text-black1 mt-2 tablet:mt-6"
                    style={{ lineHeight: screenWidth < 1024 ? "25px" : "36px" }}
                  >
                    Detail Pembelian
                  </p>
                  <div
                    className="flex flex-row w-full items-center justify-between p-5 rounded-2xl mt-2 tablet:mt-6"
                    style={{
                      border: "1px dashed var(--blue-primary, #43BCCD)",
                    }}
                  >
                    <p
                      className="text-left font-extrabold text-sm mac:text-base text-black1"
                      style={{ lineHeight: "24px" }}
                    >
                      {invoiceData.admission_name}
                    </p>
                    <p
                      className="text-right font-extrabold text-sm mac:text-base text-black1"
                      style={{ lineHeight: "24px" }}
                    >
                      {`Rp ${invoiceData.total_price.toLocaleString()}`}
                    </p>
                  </div>
                  <Separator margin={"mt-6"} />
                  <p
                    className="text-left font-bold text-xl text-black1 mt-2 tablet:mt-6"
                    style={{ lineHeight: "36px" }}
                  >
                    Rincian Pembayaran
                  </p>
                  <div className="flex flex-row justify-between items-center w-full mt-2 tablet:mt-6">
                    <p
                      className="text-left font-normal text-base"
                      style={{
                        lineHeight: "24px",
                        color: "rgba(123, 128, 130, 1)",
                      }}
                    >
                      Metode Pembayaran
                    </p>
                    <p
                      className="text-right font-extrabold text-base text-black1"
                      style={{ lineHeight: "24px" }}
                    >
                      OCBC NISP Virtual Account
                    </p>
                  </div>
                  <DashedSeparator margin={"mt-5"} />
                  <div className="flex flex-row justify-between items-center w-full mt-5">
                    <p
                      className="text-left font-normal text-base"
                      style={{
                        lineHeight: "24px",
                        color: "rgba(123, 128, 130, 1)",
                      }}
                    >
                      Total Harga
                    </p>
                    <p
                      className="text-right font-extrabold text-base text-black1"
                      style={{ lineHeight: "24px" }}
                    >
                      {`Rp ${invoiceData.total_price.toLocaleString()}`}
                    </p>
                  </div>
                  {/* <div className="flex flex-row justify-between items-center w-full mt-5">
                    <p
                      className="text-left font-normal text-base"
                      style={{
                        lineHeight: "24px",
                        color: "rgba(123, 128, 130, 1)",
                      }}
                    >
                      Biaya Transaksi
                    </p>
                    <p
                      className="text-right font-extrabold text-base text-black1"
                      style={{ lineHeight: "24px" }}
                    >
                      Rp 1000
                    </p>
                  </div> */}
                  <DashedSeparator margin={"mt-5"} />
                  <div className="flex flex-row justify-between items-center w-full mt-6">
                    <p
                      className="text-left font-extrabold text-base mac:text-xl text-black1"
                      style={{
                        lineHeight: "32px",
                      }}
                    >
                      Total Harga
                    </p>
                    <p
                      className="text-right font-extrabold text-base mac:text-xl text-black1"
                      style={{ lineHeight: "32px" }}
                    >
                      {`Rp ${invoiceData.total_price.toLocaleString()}`}
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </LayoutV2>
  );
}
