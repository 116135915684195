import useSWR from "swr";
import { useState, useEffect } from "react";
import _get from "lodash/get";
import { apiAnom } from "../utils/api";

function getExpired(key) {
  if (window.localStorage.getItem(key)) {
    return JSON.parse(window.localStorage.getItem(key)).expired;
  }
  return 0;
}

export default function useSwrCache(url, opt) {
  const [isCached, setIsCached] = useState(getExpired(opt.key));
  const { data } = useSWR(isCached ? null : url, apiAnom, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    const currentDate = new Date();
    const isExpired = currentDate.getTime() >= isCached;
    if (isExpired && isCached !== 0) {
      setIsCached(0);
    }
  }, [isCached]);

  useEffect(() => {
    if (data && isCached === 0) {
      const currentDate = new Date();
      const expired = currentDate.setHours(currentDate.getHours() + 1);
      window.localStorage.setItem(
        opt.key,
        JSON.stringify({ data: data.data.data, expired })
      );
      setIsCached(currentDate.getTime());
    }
  }, [data, isCached, opt.key]);

  const savedData = isCached
    ? JSON.parse(window.localStorage.getItem(opt.key))?.data
    : _get(data, "data.data", opt.initialValue);

  return { data: savedData };
}
