export const RadioButton = (props) => {
  const { onClicked, id, isSelected, label, value } = props;
  return (
    <div className="RadioButton">
      <input
        type="radio"
        id={id}
        onChange={onClicked}
        value={value}
        checked={isSelected}
      />
      <label key={id} htmlFor={id} className="ml-2">
        {label}
      </label>
    </div>
  );
};
