import { api, notifError } from "../utils/api";
import { useEffect, useState } from "react";
import PageLoading from "./PageLoading";
import { useNavigate } from "react-router-dom";

export default function Attend() {
  let navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const class_id = params.get("l");
    if (class_id) {
      api
        .post("/students/attend", {
          class_id: Number(class_id),
        })
        .then((res) => {
          if (res.status === 200) {
            window.location.href = res.data.data.url;
          } else if (res.status === 202) {
            window.Swal.fire({
              html: "<b>Hai Explorers…</b><br/><br/>Kelas sudah berjaln lebih dari setengah jalan loh…<br/><br/>Kamu masih bisa mengikuti kelas, tapi kamu tidak bisa mendapatkan sertifikat ya",
              icon: "info",
              confirmButtonText: "Masuk Kelas",
            }).then(() => {
              window.location.href = res.data.data.url;
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            let eta = error.response.data.error.message.split("|")[1];
            let minutes = eta.split("h")[1].split("m")[0];
            let hours = eta.split("h")[0];
            let days = hours > 24 ? Math.floor(hours / 24) : 0;
            let remainingHours = hours > 24 ? hours % 24 : hours;

            window.Swal.fire({
              html: `<b>Hai Explorers…</b></br></br>Kelas akan dimulai dalam <b>${
                days > 0 ? `${days} hari ` : " "
              }${remainingHours > 0 ? `${remainingHours} jam` : ""} ${
                minutes > 0 ? `${minutes} menit` : ""
              }</b></br></br>Refresh halaman ini atau klik link dari email untuk bergabung di kelas Exploration ya Explorers!`,
              icon: "info",
              confirmButtonText: "Oke!",
            }).then(() => {
              navigate("/");
            });
          } else if (error.response.status === 403) {
            window.Swal.fire({
              html: "Yah Explorers…</br>Kelas sudah selesai nih. Kalau kamu ketinggalan kelas hari ini, kamu bisa ikut kelas lainnya dari Exploration</br></br>Sampai jumpa di kelas selanjutnya!",
              icon: "info",
              confirmButtonText: "Oke!",
            }).then(() => {
              navigate("/explore-kelas");
            });
          } else notifError();
          // } else notifError(error.response.data.error.message);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);
  return <PageLoading />;
}
