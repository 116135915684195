import { CalendarIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function RMSubTanggalLahirPeserta({ submit, back }) {
  const [tanggalLahirPeserta, setTanggalLahirPeserta] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isValidBirthday, setIsValidBirthday] = useState(false);

  useEffect(() => {
    if (Cookies.get("tanggalLahirPeserta")) {
      setTanggalLahirPeserta(Cookies.get("tanggalLahirPeserta"));
    }
  }, []);

  useEffect(() => {
    if (
      Date.parse(new Date(tanggalLahirPeserta)) <
      Date.parse(new Date(new Date(2009, 5, 30)))
    ) {
      setIsValidBirthday(true);
    } else {
      setIsValidBirthday(false);
    }
  }, [tanggalLahirPeserta]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submit(e.target[2].value);
  };

  const handleEnterKey = (e) => {
    submit(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-end w-full">
      <button onClick={back} className="w-full justify-start">
        <ArrowLeftIcon className="w-6 h-6 text-white font-semibold" />
      </button>
      <label
        className="w-full text-left font-bold text-base text-white mt-20 tablet:mt-50"
        htmlFor="birthday_student"
      >
        Input Tanggal Lahir Peserta
      </label>
      {!isValidBirthday && (
        <p
          className="text-left font-bold text-xs"
          style={{ lineHeight: "15.12px", color: "rgba(254, 82, 68, 1)" }}
        >
          Peserta harus minimal berumur 15 tahun pada 30 Juni 2024
        </p>
      )}
      <div
        onClick={() => {
          setIsCalendarOpen(true);
        }}
        className="w-full h-auto flex flex-row justify-start items-center relative mt-3"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <CalendarIcon
          className="w-8 h-auto absolute left-0 p-0 m-0"
          style={{ color: "black" }}
        />
        <input
          className="form-input flex w-full rounded-md text-center font-normal text-xs text-black py-2 border-0"
          style={{ height: "30px" }}
          id="birthday_student"
          type="birthday_student"
          name="birthday_student"
          value={moment(tanggalLahirPeserta)
            .tz("Asia/Jakarta")
            .format("DD MMMM YYYY")}
          disabled={true}
          required
        />
        <input
          className="hidden"
          style={{ height: "30px" }}
          id="birthday_student"
          type="birthday_student"
          name="birthday_student"
          value={moment(tanggalLahirPeserta)
            .tz("Asia/Jakarta")
            .format("YYYY-MM-DD")}
          disabled={true}
          required
        />
      </div>
      <div className="flex flex-row justify-end items-start w-full mt-2 relative">
        {isCalendarOpen && (
          <div className="absolute top-0 left-0 z-10 w-full">
            <Calendar
              onChange={setTanggalLahirPeserta}
              value={tanggalLahirPeserta}
              onClickDay={() => {
                setIsCalendarOpen(false);
              }}
            />
          </div>
        )}
        {/* <p className="text-left font-normal text-sm text-white italic leading-4">
          Contoh:
          <br /> 08123456789
        </p> */}
        <button
          type="submit"
          disabled={!isValidBirthday}
          className="w-max py-2 px-8 rounded-md text-center font-bold text-sm text-black1 mt-1"
          style={{
            backgroundColor: !isValidBirthday
              ? "gray"
              : "rgba(255, 202, 45, 1)",
          }}
        >
          Next
        </button>
      </div>
    </form>
  );
}
