import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { authState } from "../../atoms/authState";
import { api, apiAnom, apiV2, notifError } from "../../utils/api";
import PageLoading from "../PageLoading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../../utils/utils";
import Button from "../../components/base/Button";
import { signInUpState } from "../../atoms/signInUpState";
import { RadioButton } from "../../components/RadioButton";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import countryJSON from "../../components/jsons/Countries.json";
import indoProvJSON from "../../components/jsons/IndonesiaProvinceWithCities.json";
import PostCheckModal from "../../components/PostCheckModal";
import { postCheckModalState } from "../../atoms/postCheckModalState";
import LayoutV2 from "../../components/Layout_V2";

export default function WidyatamaAdmissionForm() {
  const params = new URLSearchParams(window.location.search);
  const admissionID = params.get("admissionID");
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const [univData, setUnivData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [guardianPhone, setGuardianPhone] = useState("");
  const [highSchool, setHighSchool] = useState("");
  const [schoolProgram, setSchoolProgram] = useState("");
  const [address, setAddress] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [isCodeDropdown, setIsCodeDropdown] = useState("");
  const [isCodeDropdown2, setIsCodeDropdown2] = useState("");
  const [isCodeDropdown3, setIsCodeDropdown3] = useState("");
  const [errors, setErrors] = useState({});
  const [disableChangePhone, setDisableChangePhone] = useState(false);
  const [countryCode, setCountryCode] = useState(getCountryCallingCode("ID"));
  const [countryCode2, setCountryCode2] = useState(getCountryCallingCode("ID"));
  const [countryCode3, setCountryCode3] = useState(getCountryCallingCode("ID"));
  const [countryList, setCountryList] = useState([]);
  const [provinceNCity, setProvinceNCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("Indonesia");
  const [selectedProvince, setSelectedProvince] = useState("DKI Jakarta");
  const [selectedCity, setSelectedCity] = useState("Pilih Kota");
  const [selectedSchoolCountry, setSelectedSchoolCountry] =
    useState("Indonesia");
  const [selectedSchoolProvince, setSelectedSchoolProvince] =
    useState("DKI Jakarta");
  const [selectedSchoolCity, setSelectedSchoolCity] = useState("Pilih Kota");
  const [startDate, setStartDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState("01/01/0001");
  const [isSubmitProcess, setIsSubmitProcess] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [postCheckData, setPostcheckData] = useState();
  const [formFinalData, setFormFinalData] = useState();
  const setPostCheckModalState = useSetRecoilState(postCheckModalState);
  const [uniID, setUniID] = useState();
  const [showPostCheckModal, setShowPostCheckModal] = useState(false);

  function closeModal() {
    setShowPostCheckModal(false);
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    setShowPostCheckModal(false);
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  useEffect(() => {
    setCountryList(countryJSON);
    setProvinceNCity(indoProvJSON);
    if (!auth.isAuth) {
      setSign({ trySignIn: true, trySignUp: false });
    } else if (auth.verified_at.split("-")[0] === "0001") {
      setIsLoading(false);
      navigate("/profile-edit");
    } else {
      _api
        .get(`/explore-kampus-v2/${window.location.pathname.split("/")[2]}`)
        .then((res) => {
          setUniID(res.data.data.id);
          setUnivData(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setUnivData({});
        });

      setFullName(
        auth.fname && auth.lname ? auth.fname + " " + auth.lname : ""
      );
      setFirstName(auth.fname ? auth.fname : "");
      setLastName(auth.lname ? auth.lname : "");
      setEmail(auth.email);
      let newCountryCode;
      let newPhone;
      if (auth.phone.includes("-")) {
        newCountryCode = auth.phone.split("-")[0];
        newPhone = auth.phone.split("-")[1];
      } else {
        newCountryCode = getCountryCallingCode("ID");
        newPhone = auth.phone.slice(auth.phone.indexOf("8"));
      }
      setPhone(newPhone);
      setCountryCode(newCountryCode);
    }
  }, []);

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          case "countryCode":
            isErr = !/()\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  function submitForm() {
    setShowPostCheckModal(false);
    // setPostCheckModalState({ isOpen: false });
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    apiV2
      .post(
        `/invoices/recreate-invoice/${univData.id}/${admissionID}`,
        formFinalData,
        config
      )
      .then((res) => {
        setTimeout(() => {
          setIsSubmitProcess(false);

          window.Swal.fire({
            title: "Success!",
            text: `Kamu telah mendaftar di ${univData.name}`,
            icon: "success",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/invoice?invoice_number=${res.data.data.invoice_number}`);
          });
        }, 1000);
      })
      .catch((_) => {
        setTimeout(() => {
          setIsSubmitProcess(false);

          window.Swal.fire({
            title: "Gagal",
            text: `Pendaftaran kampus gagal`,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/profile`);
          });
        }, 1000);
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitProcess(true);
    const { elements } = e.target;
    if (!validate(elements)) return;
    // building payload
    const newPhone = countryCode + "-" + phone;
    const newGuardianPhone = countryCode3 + "-" + guardianPhone;
    const jsonForm = {
      full_name: lastName,
      address: {
        country: selectedCountry,
        province: selectedProvince,
        city: selectedCity,
        full_address: address,
        zip_code: addressZipCode,
      },
      date_of_birth: dateOfBirth,
      contact: {
        phone: newPhone,
        email: email,
        whatsapp: whatsapp,
        parents_phone: newGuardianPhone,
      },
      school: {
        last_education_grade: selectedLastEdu,
        school_address: {
          country: selectedSchoolCountry,
          city: selectedSchoolCity,
          province: selectedSchoolProvince,
        },
        school_name: highSchool,
        program: schoolProgram,
      },
      selected_program: selectedProgramStudi,
    };
    {
      /* RESERVED FOR LATER USE */
    }
    // const payload = {
    //   name: "",
    //   email: "",
    //   phone: "",
    //   uni_id: univData.id,
    //   univ_form_data: JSON.stringify(jsonForm),
    // };

    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("name", fullName);
    formData.append("email", email);
    formData.append("phone", newPhone);
    formData.append("uni_id", univData.id);
    formData.append("univ_form_data", JSON.stringify(jsonForm));
    formData.append("paid_status", "SUBMITTED"); // DRAFT or SUBMITTED

    apiV2
      .get(`/invoices/postcheck/${uniID}`)
      .then((res) => {
        setPostcheckData(res.data.data);
        setFormFinalData(formData);
        setShowPostCheckModal(true);
        // setPostCheckModalState({ isOpen: true });
      })
      .catch((err) => {
        setFormFinalData(formData);
        setPostcheckData(false);
        if (err.response.status === 404) {
          _api
            .post(`/students/univ-admission/${admissionID}`, formData, config)
            .then((res) => {
              setIsSubmitProcess(false);

              window.Swal.fire({
                title: "Success!",
                text: `Kamu telah mendaftar di ${univData.name}`,
                icon: "success",
                confirmButtonText: "Tutup",
                allowOutsideClick: false,
              }).then(() => {
                navigate(
                  `/invoice?invoice_number=${res.data.data.invoice_number}`
                );
              });
            })
            .catch((_) => {
              setIsSubmitProcess(false);

              window.Swal.fire({
                title: "Gagal",
                text: `Pendaftaran kampus gagal`,
                icon: "error",
                confirmButtonText: "Tutup",
                allowOutsideClick: false,
              }).then(() => {
                navigate(`/profile`);
              });
            });
        } else {
          window.Swal.fire({
            title: "Gagal",
            text: err.response.data.error.message,
            icon: "error",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            navigate(`/profile`);
          });
        }
      });
  };

  const pilihanProgramStudi = [
    "Akuntansi D3",
    "Akuntansi S1",
    "Bahasa Inggris S1",
    "Bahasa Jepang D3",
    "Bahasa Jepang S1",
    "Desain Grafis D4",
    "Manajemen D3 ",
    "Manajemen S1",
    "Multimedia D3",
    "Perdagangan Internasional S1",
    "Perpustakaan &amp; Sains Informasi S1",
    "Produksi Film &amp; Televisi D4",
    "Sistem Informasi S1",
    "Teknik Elektro S1",
    "Teknik Industri S1",
    "Teknik Informatika S1",
    "Teknik Mesin S1",
    "Teknik Sipil S1",
  ];
  const lastEdu = ["SMA", "SMK", "MA", "D3", "D4", "S1"];
  const [selectedProgramStudi, setSelectedProgramStudi] = useState(
    pilihanProgramStudi[0]
  );
  const [selectedLastEdu, setSelectedLastEdu] = useState(lastEdu[0]);

  const lastEduRadioChangeHandler = (e) => {
    setSelectedLastEdu(e.target.value);
  };

  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <LayoutV2>
      <p className="text-center font-bold text-3xl hd:text-48px text-black1 mt-4 hd:mt-20">
        Isi Formulir Pendaftaran
      </p>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-8 px-4 hd:px-20 mt-12 pb-20 overflow-auto"
      >
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Nama Lengkap <span className={"text-red-500"}>*</span>
          </label>
          <input
            id="fullName"
            type="text"
            name="fullName"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
            placeholder="Masukkan nama lengkap"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Nama Lengkap harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="alamat_lengkap"
          >
            Alamat Lengkap<span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Negara"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedCountry}
            onChange={(e) => {
              const country = e.target.value;
              setSelectedCountry(country);
            }}
            required
          >
            {countryList.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
          {selectedCountry === "Indonesia" && (
            <select
              placeholder={"Pilih Provinsi"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedProvince}
              onChange={(e) => {
                const province = e.target.value;
                setSelectedProvince(province);
              }}
              required
            >
              {provinceNCity.map((item, index) => (
                <option key={index} value={item.provinsi}>
                  {item.provinsi}
                </option>
              ))}
            </select>
          )}
          {selectedCountry === "Indonesia" && (
            <select
              placeholder={"Pilih Kota"}
              className={`form-input w-full text-gray-800 rounded-40px mt-4`}
              value={selectedCity}
              onChange={(e) => {
                const city = e.target.value;
                setSelectedCity(city);
              }}
              required
            >
              {provinceNCity.length > 0 &&
                provinceNCity
                  .filter((element) => element.provinsi === selectedProvince)[0]
                  .kota.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
            </select>
          )}
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan alamat lengkap"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Alamat lengkap harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
          <input
            type="text"
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4`}
            placeholder="Masukkan kode pos"
            value={addressZipCode}
            onChange={(e) => setAddressZipCode(e.target.value)}
            disabled={false}
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("Kode pos harus diisi")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />
        </div>
        <div className="flex flex-col justify-start items-start w-full">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="fname"
          >
            Tanggal Lahir <span className={"text-red-500"}>*</span>
          </label>
          <ReactDatePicker
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setDateOfBirth(
                moment(date).tz("Asia/Jakarta").format("DD/MM/YYYY")
              );
            }}
            className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
              mapHasKeyAndValueNotEmpty(errors, "phone") ? "border-red-400" : ""
            }`}
          />
        </div>
        <div className="flex flex-col justify-start items-start">
          <label
            className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
            htmlFor="kontakt"
          >
            Kontak <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-col justify-start items-start gap-4 ml-4 mt-2 w-full">
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                Nomor HP
              </p>
              <div className="flex flex-row w-full justify-start items-center mt-1">
                <div
                  className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
                  style={{
                    height: "50px",
                    border: "1px solid #E0E0E1",
                  }}
                  onClick={() => {
                    setIsCodeDropdown(!isCodeDropdown);
                  }}
                >
                  <div className="border-0 w-12 h:w-18 pl-1">
                    {`+${countryCode}`}
                  </div>
                  <ChevronDownIcon
                    className="h-5 w-auto p-0 m-0"
                    style={{ paddingTop: "2px" }}
                  />
                  {isCodeDropdown && (
                    <div
                      className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10"
                      //   style={{ maxHeight: "344px" }}
                    >
                      {/* <option value="">{en["ZZ"]}</option> */}
                      {getCountries().map((country) => (
                        <button
                          key={country}
                          value={country}
                          className="text-left px-4 pt-2 w-max"
                          onClick={() => {
                            setIsCodeDropdown(false);
                            setCountryCode(getCountryCallingCode(country));
                          }}
                        >
                          {en[country]}
                          {/* {labels[country]} +{getCountryCallingCode(country)} */}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <input
                  id="phone"
                  type="number"
                  name="phone"
                  accept="phone"
                  className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                    mapHasKeyAndValueNotEmpty(errors, "phone")
                      ? "border-red-400"
                      : ""
                  }`}
                  placeholder="cth. 8XXXXXXXXXXXX"
                  value={phone}
                  onChange={(e) => {
                    if (phone[0] === "0") {
                      setPhone(e.target.value.slice(1));
                    } else {
                      setPhone(e.target.value);
                    }
                  }}
                  onBlur={() => {
                    if (phone[0] === "0") {
                      setPhone(phone.slice(1));
                    }
                  }}
                  disabled={disableChangePhone}
                  required
                  onInvalid={(F) =>
                    F.target.setCustomValidity("No. HP harus diisi")
                  }
                  onInput={(F) => F.target.setCustomValidity("")}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                Email
              </p>
              <div className="flex flex-col justify-start items-start">
                <input
                  id="personal_email"
                  type="text"
                  name="personal_email"
                  className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-1 ${
                    mapHasKeyAndValueNotEmpty(errors, "phone")
                      ? "border-red-400"
                      : ""
                  }`}
                  placeholder="Masukkan email pribadi"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={false}
                  required
                  onInvalid={(F) =>
                    F.target.setCustomValidity("Email pribadi harus diisi")
                  }
                  onInput={(F) => F.target.setCustomValidity("")}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                Whatsapp
              </p>
              <div className="flex flex-row w-full justify-start items-center mt-1">
                <div
                  className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
                  style={{
                    height: "50px",
                    border: "1px solid #E0E0E1",
                  }}
                  onClick={() => {
                    setIsCodeDropdown2(!isCodeDropdown2);
                  }}
                >
                  <div className="border-0 w-12 h:w-18 pl-1">
                    {`+${countryCode2}`}
                  </div>
                  <ChevronDownIcon
                    className="h-5 w-auto p-0 m-0"
                    style={{ paddingTop: "2px" }}
                  />
                  {isCodeDropdown2 && (
                    <div
                      className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10"
                      //   style={{ maxHeight: "344px" }}
                    >
                      {/* <option value="">{en["ZZ"]}</option> */}
                      {getCountries().map((country) => (
                        <button
                          key={country}
                          value={country}
                          className="text-left px-4 pt-2 w-max"
                          onClick={() => {
                            setIsCodeDropdown2(false);
                            setCountryCode2(getCountryCallingCode(country));
                          }}
                        >
                          {en[country]}
                          {/* {labels[country]} +{getCountryCallingCode(country)} */}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <input
                  id="whatsapp"
                  type="number"
                  name="whatsapp"
                  accept="whatsapp"
                  className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                    mapHasKeyAndValueNotEmpty(errors, "whatsapp")
                      ? "border-red-400"
                      : ""
                  }`}
                  placeholder="cth. 8XXXXXXXXXXXX"
                  value={whatsapp}
                  onChange={(e) => {
                    if (whatsapp[0] === "0") {
                      setWhatsapp(e.target.value.slice(1));
                    } else {
                      setWhatsapp(e.target.value);
                    }
                  }}
                  onBlur={() => {
                    if (whatsapp[0] === "0") {
                      setWhatsapp(whatsapp.slice(1));
                    }
                  }}
                  disabled={disableChangePhone}
                  required
                  onInvalid={(F) =>
                    F.target.setCustomValidity("No. Whatsapp harus diisi")
                  }
                  onInput={(F) => F.target.setCustomValidity("")}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                No HP Orangtua
              </p>
              <div className="flex flex-row w-full justify-start items-center mt-1">
                <div
                  className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative"
                  style={{
                    height: "50px",
                    border: "1px solid #E0E0E1",
                  }}
                  onClick={() => {
                    setIsCodeDropdown3(!isCodeDropdown3);
                  }}
                >
                  <div className="border-0 w-12 h:w-18 pl-1">
                    {`+${countryCode3}`}
                  </div>
                  <ChevronDownIcon
                    className="h-5 w-auto p-0 m-0"
                    style={{ paddingTop: "2px" }}
                  />
                  {isCodeDropdown3 && (
                    <div className="flex flex-col gap-3 absolute h-46 w-70 hd:w-max bg-white border-2 shadow-md top-12 -ml-2 pb-4 overflow-y-auto z-10">
                      {getCountries().map((country) => (
                        <button
                          key={country}
                          value={country}
                          className="text-left px-4 pt-2 w-max"
                          onClick={() => {
                            setIsCodeDropdown3(false);
                            setCountryCode3(getCountryCallingCode(country));
                          }}
                        >
                          {en[country]}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <input
                  id="guardianPhone"
                  type="number"
                  name="guardianPhone"
                  accept="guardianPhone"
                  className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                    mapHasKeyAndValueNotEmpty(errors, "guardianPhone")
                      ? "border-red-400"
                      : ""
                  }`}
                  placeholder="cth. 8XXXXXXXXXXXX"
                  value={guardianPhone}
                  onChange={(e) => {
                    if (guardianPhone[0] === "0") {
                      setGuardianPhone(e.target.value.slice(1));
                    } else {
                      setGuardianPhone(e.target.value);
                    }
                  }}
                  onBlur={() => {
                    if (guardianPhone[0] === "0") {
                      setGuardianPhone(guardianPhone.slice(1));
                    }
                  }}
                  disabled={disableChangePhone}
                  required
                  onInvalid={(F) =>
                    F.target.setCustomValidity("No. HP orang tua harus diisi")
                  }
                  onInput={(F) => F.target.setCustomValidity("")}
                />
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Sekolah <span className={"text-red-500"}>*</span>
          </label>
          <div className="flex flex-col justify-start items-start gap-4 ml-4 mt-4 w-full">
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                {"Pendidikan Terakhir"}
              </p>
              <div className="flex flex-col hd:flex-row gap-4 hd:gap-8 mt-1">
                {lastEdu.map((item, index) => (
                  <div key={index}>
                    <RadioButton
                      onClicked={lastEduRadioChangeHandler}
                      id={item + index}
                      isSelected={selectedLastEdu === item}
                      label={item}
                      value={item}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                {"Alamat Sekolah"}
              </p>
              <select
                placeholder={"Pilih Negara"}
                className={`form-input w-full text-gray-800 rounded-40px mt-1`}
                value={selectedSchoolCountry}
                onChange={(e) => {
                  const country = e.target.value;
                  setSelectedSchoolCountry(country);
                }}
                required
              >
                {countryList.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
              {selectedSchoolCountry === "Indonesia" && (
                <select
                  placeholder={"Pilih Provinsi"}
                  className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                  value={selectedSchoolProvince}
                  onChange={(e) => {
                    const province = e.target.value;
                    setSelectedSchoolProvince(province);
                  }}
                  required
                >
                  {provinceNCity.map((item, index) => (
                    <option key={index} value={item.provinsi}>
                      {item.provinsi}
                    </option>
                  ))}
                </select>
              )}
              {selectedSchoolCountry === "Indonesia" && (
                <select
                  placeholder={"Pilih Kota"}
                  className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                  value={selectedSchoolCity}
                  onChange={(e) => {
                    const city = e.target.value;
                    setSelectedSchoolCity(city);
                  }}
                  required
                >
                  {provinceNCity.length > 0 &&
                    provinceNCity
                      .filter(
                        (element) => element.provinsi === selectedSchoolProvince
                      )[0]
                      .kota.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                </select>
              )}
            </div>
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                {"Nama Sekolah"}
              </p>
              <input
                type="text"
                className={`form-input w-full text-gray-800 rounded-40px mt-1`}
                placeholder="Masukkan nama sekolah"
                value={highSchool}
                onChange={(e) => setHighSchool(e.target.value)}
                disabled={false}
                required
                onInvalid={(F) =>
                  F.target.setCustomValidity("Nama sekolah harus diisi")
                }
                onInput={(F) => F.target.setCustomValidity("")}
              />
            </div>
            <div className="flex flex-col w-full">
              <p className="text-left font-semibold text-sm hd:text-xl text-black1">
                {"Jurusan/Peminatan/Prodi di Sekolah:"}
              </p>
              <input
                type="text"
                className={`form-input w-full text-gray-800 rounded-40px mt-1`}
                placeholder="Masukkan Jurusan/Peminatan/Prodi"
                value={schoolProgram}
                onChange={(e) => setSchoolProgram(e.target.value)}
                disabled={false}
                required
                onInvalid={(F) =>
                  F.target.setCustomValidity(
                    "Jurusan/Peminatan/Prodi harus diisi"
                  )
                }
                onInput={(F) => F.target.setCustomValidity("")}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1">
            Program Studi: <span className={"text-red-500"}>*</span>
          </label>
          <select
            placeholder={"Pilih Tipe Program"}
            className={`form-input w-full text-gray-800 rounded-40px mt-4`}
            value={selectedProgramStudi}
            onChange={(e) => {
              const program = e.target.value;
              setSelectedProgramStudi(program);
            }}
            required
          >
            {pilihanProgramStudi.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        {/* Submit Button */}
        <div className="flex flex-wrap px-3 -mx-3 mt-10 w-full justify-center">
          <Button
            disabled={isSubmitProcess}
            type="submit"
            className="w-max rounded-lg"
          >
            {isSubmitProcess ? "Submitting..." : "Simpan"}
          </Button>
        </div>
      </form>

      {/* post check modal */}

      <PostCheckModal
        isModalShow={showPostCheckModal}
        screenWidth={screenWidth}
        postCheckData={postCheckData}
        submitForm={submitForm}
        closeModal={closeModal}
      />
    </LayoutV2>
  );
}
