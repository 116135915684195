import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";

export default function TMBResultCard({ screenWidth, data }) {
  const auth = useRecoilValue(authState);
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );
  let width = screenWidth >= 1024 ? "480px" : "280px";
  const charTypeIllustration = (type) => {
    switch (type) {
      case "R":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Doers.png";
      case "I":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Thinkers.png";
      case "A":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Creators.png";
      case "S":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Helpers.png";
      case "E":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Persuaders.png";
      case "C":
        return "https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Card/Card%20Template-The%20Organizer.png";
      default:
        return "Unique";
    }
  };

  return (
    <Link
      to={
        auth.verified_at.split("-")[0] === "0001"
          ? "/profile-edit"
          : `/tes-minat-result/${data.id}`
      }
      className="flex flex-col mt-6 rounded-2xl shadow-xl tablet:shadow-2xl bg-white"
      style={{
        minWidth: width,
      }}
    >
      <div className="w-full flex flex-col items-stretch pt-8 px-8">
        <p
          className={`${
            !data ? "animate-pulse" : "text-left text-sm font-normal"
          }`}
          style={{ color: "rgba(131, 130, 132, 1)" }}
        >
          {moment(data.created_at).tz("Asia/Jakarta").format("DD MMMM YYYY")}
        </p>
        <p
          className={`${
            !data
              ? "animate-pulse bg-gray-200"
              : "text-left text-black21 text-base font-semibold mt-6 h-13"
          }`}
        >
          {data.label_long}
        </p>
        <div className="flex flex-row gap-2 mt-4">
          {screenWidth >= 1024 ? (
            <div className="flex flex-row gap-2">
              {data.results.map((data, index) => {
                return (
                  <p
                    key={index}
                    className={`${
                      !data
                        ? "animate-pulse"
                        : "text-center text-black2 text-base font-medium"
                    } px-3 py-1 rounded-lg bg-backgroundGray4`}
                  >
                    {data.label_short}
                  </p>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-row gap-2">
              <p
                className={`${
                  !data
                    ? "animate-pulse"
                    : "text-center text-black2 text-base font-medium"
                } px-3 py-1 rounded-lg bg-backgroundGray4`}
              >
                {data.results[0].label_short}
              </p>
              <p
                className={`${
                  !data
                    ? "animate-pulse"
                    : "text-center text-black2 text-base font-medium"
                } px-3 py-1 rounded-lg bg-backgroundGray4`}
              >
                +2
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row mt-8 h-20 tablet:h-26 w-full">
        <img
          alt="illu 1"
          src={charTypeIllustration(data.results[0].code)}
          className="h-full w-1/2 object-cover object-top z-20 rounded-bl-2xl"
        />
        <img
          alt="illu 2"
          src={charTypeIllustration(data.results[1].code)}
          className="h-full w-1/2 object-cover object-top -ml-15 tablet:-ml-30 z-10 opacity-90"
        />
        <img
          alt="illu 3"
          src={charTypeIllustration(data.results[2].code)}
          className="h-full w-1/2 object-cover object-top -ml-15 tablet:-ml-30 rounded-br-2xl opacity-60"
        />
      </div>
      {/* <img
        src="/tmbresultcard-illu.svg"
        className="rounded-b-2xl object-contain mt-8"
      /> */}
    </Link>
  );
}
