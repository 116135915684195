import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

export default function FormFieldDropdown({
  id,
  ddOptions,
  title,
  placeholder,
  isRequired,
  value,
  onInput,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const [openDD, setOpenDD] = useState(false);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
      setSelectedOption(
        ddOptions[ddOptions.findIndex((item) => item.id === value)].name
      );
    }
  }, [value]);

  return (
    <div className="w-full flex flex-col justify-start items-stretch gap-2">
      <p
        className="text-left font-medium text-base text-black"
        style={{ lineHeight: "12.33px" }}
      >
        {title}
      </p>
      <div className="w-full h-10 relative">
        <input
          id={id}
          className="h-full w-full hidden"
          style={{
            border: "0.62px solid rgba(224, 224, 225, 1)",
            borderRadius: "24.66px",
            padding: "8px 16px 8px 16px",
          }}
          onInput={onInput}
          defaultValue={selectedValue}
          required={isRequired}
        />
        <div
          onClick={() => {
            setOpenDD(!openDD);
          }}
          className="h-full w-full flex gap-2"
          style={{
            border: "0.62px solid rgba(224, 224, 225, 1)",
            borderRadius: "24.66px",
            padding: "8px 16px 8px 16px",
            color:
              selectedOption.length > 0 ? "black" : "rgba(138, 138, 138, 1)",
            cursor: "pointer",
          }}
        >
          <p className="w-full text-left line-clamp-1 overflow-ellipsis">
            {selectedOption.length > 0 ? selectedOption : placeholder}
          </p>
          {openDD ? (
            <ChevronUpIcon className="w-6 h-6" />
          ) : (
            <ChevronDownIcon className="w-6 h-6" />
          )}
        </div>
        {openDD && (
          <div
            className="w-full overflow-y-auto flex flex-col gap-2 p-2 rounded-xl bg-white absolute left-0 -bottom-2 z-10"
            style={{
              transform: "translate(0, 100%)",
              border: "0.62px solid rgba(224, 224, 225, 1)",
              maxHeight: "140px",
            }}
          >
            {ddOptions.map((item, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setSelectedValue(item.id);
                  setSelectedOption(
                    ddOptions[
                      ddOptions.findIndex((option) => option.id === item.id)
                    ].name
                  );
                  setOpenDD(false);
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
