import Button from "./base/Button";
import FavoriteButton from "./FavoriteButton";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import { setLocalStorage, getLocalStorage } from "../utils/localStorage";
import { useState } from "react";
import VerifiedIcon from "../components/VerifiedIcon";

export default function UnivShortcut({
  onRemoveSelect,
  onChangeFavorite,
  onOpenDetail,
  ...data
}) {
  const transformTuitionName = (name) => {
    if (name == null) return "";
    if (name.toLowerCase() === "semua jurusan") return name;
    return `Jurusan ${name}`;
  };

  const typeTitle = (type) => {
    switch (type) {
      case "N":
        return "Negeri";
      case "L":
        return "Luar Negeri";
      case "S":
        return "Swasta";
      default:
        return "Lainnya";
    }
  };

  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;

  const navigate = useNavigate();

  const addUnivToFav = (fav, id) => {
    if (fav && !auth.isAuth) {
      navigate({
        pathname: "/signin",
        search: `?redirect=/explore-kampus/detail/${id}`,
      });
    }

    const url = fav
      ? "/favorites/university/add"
      : "/favorites/university/delete";

    _api
      .post(url, {
        uni_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };

  const [favorite, setFavorite] = useState(data.favorite ?? false);

  return (
    <div
      className={
        data?.verified ? "border-4 border-indigo-600" : "border border-gray-200"
      }
      style={{
        minHeight: "50vh",
        // width: '220px'
      }}
    >
      <div className="relative">
        <img
          src={data?.img_url}
          className="object-cover w-full"
          alt=""
          style={{ height: "100px" }}
        />
        <span className="absolute top-2 right-2 p-1" aria-hidden="true">
          {data?.verified && (
            <FavoriteButton
              className="top-1 right-1"
              isFavorite={favorite}
              onChangeFavorite={() => {
                addUnivToFav(!favorite, data.id);
                var listUniv = getLocalStorage("selectedUniversity");
                listUniv.map((v, i) => {
                  if (v.id === data.id) {
                    listUniv[i].favorite = !favorite;
                    setLocalStorage(
                      "selectedUniversity",
                      listUniv,
                      process.env.REACT_APP_TTL_LOCALSTORAGE_IN_MINUTE
                    );
                  }
                });
              }}
            />
            // <img
            //   src="/verified_status.svg"
            //   alt="Verified"
            //   title="Verified"
            //   className="h-5 w-5"
            // />
          )}
        </span>
      </div>

      <div className="relative">
        <div className="p-2 sm:p-4">
          <div className="flex flex-row items-center">
            <p className="flex flex-1 justify-start text-sm text-gray-500">
              {typeTitle(data?.type)}
            </p>
            <VerifiedIcon className="flex flex-1 justify-end" />
          </div>
          <div className="flex items-center h-14 tablet:h-12 gap-3 mt-0 tablet:mt-5 mb-2 tablet:my-1">
            <img
              className="h-10 w-10 rounded-full"
              src={data?.logo_url}
              alt={"logo"}
            />
            <div className="focus:outline-none">
              <p className="text-sm line-clamp-2 sm:text-md font-bold text-gray-900">
                {data?.name}
              </p>
            </div>
          </div>
          <p className="text-sm font-normal text-gray-500 mb-2">
            {`Akreditasi ${data?.accreditation}`}
          </p>
          <div className="flex gap-1 items-center">
            <LocationMarkerIcon className="h-3 w-3" />
            <p className="text-sm font-normal text-gray-500">
              {data?.province}
            </p>
          </div>
          <div className="flex flex-row justify-between gap-2 my-4">
            <div
              className="p-2 flex-1 rounded-lg flex flex-col"
              style={{ backgroundColor: "#FFAD39" }}
            >
              <span className="text-white text-lg text-center">
                {data?.total_faculties ?? 0}
              </span>
              <span className="text-white text-sm text-center">Fakultas</span>
            </div>
            <div
              className="p-2 flex-1 rounded-lg flex flex-col"
              style={{ backgroundColor: "#04C4B2" }}
            >
              <span className="text-white text-lg text-center">
                {data?.total_majors ?? 0}
              </span>
              <span className="text-white text-sm text-center">Jurusan</span>
            </div>
          </div>

          <div className="items-start">
            <div className="text-left text-gray-700">{"Jurusan Favorit"}</div>
            <div style={{ height: "25vh" }}>
              <ul className="list-decimal list-inside">
                {data?.favorite_majors.length !== 0 ? (
                  data?.favorite_majors.map((d, i) => {
                    return (
                      <div
                        key={d.id}
                        className={`flex flex-row mb-1 text-xs text-gray-400 ${
                          d.majors_of_universities_id != null &&
                          d.majors_of_universities_id !== 0
                            ? "underline cursor-pointer"
                            : "no-underline"
                        } `}
                      >
                        <div>
                          {`${i + 1}.`}
                          <span>&nbsp;</span>
                        </div>
                        <div>{d.name}</div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className={`text-xs text-gray-400`}
                    // onClick={() => navigateToMajorDetail(d.majors_of_universities_id)}
                  >
                    -
                  </div>
                )}
              </ul>
            </div>
          </div>
          <div className="h-32 sm:h-52 tablet:h-40 lg:h-10"></div>

          <div className="my-3">
            <p>Biaya Kuliah</p>
            <p className="text-xs">Biaya Semester Min.</p>
            <p className="text-sm font-bold " style={{ color: "#04C4B2" }}>
              Rp. {data?.lowest_fee_major?.semester_fees?.toLocaleString() ?? 0}
            </p>
            <p className="text-xs font-bold">
              {transformTuitionName(data?.lowest_fee_major?.name ?? `-`)}
            </p>
            <p className="text-xs mt-2">Biaya Semester Max.</p>
            <p className="text-sm font-bold " style={{ color: "#04C4B2" }}>
              Rp.{" "}
              {data?.highest_fee_major?.semester_fees?.toLocaleString() ?? 0}
            </p>
            <p className="text-xs font-bold">
              {transformTuitionName(data?.highest_fee_major?.name ?? `-`)}
            </p>
          </div>
          <div className="my-4 flex flex-col gap-2">
            <Button
              onClick={onOpenDetail}
              variant="primary"
              size="small"
              className="text-sm font-normal"
            >
              Lihat Profil Lengkap
            </Button>
            <Button
              onClick={() => addUnivToFav(!favorite, data.id)}
              variant="secondary"
              size="small"
              className="text-sm font-normal text-gray-700"
            >
              {!favorite ? "Tambahkan ke favorit" : "Hapus dari favorit"}
            </Button>
            <Button
              onClick={onRemoveSelect}
              variant="link"
              size="small"
              className="text-sm font-normal"
            >
              Hapus
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
