import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Headerv2 from "../../components/Header_v2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { apiAnom, notifError } from "../../utils/api";
import moment from "moment";
import LayoutV2 from "../../components/Layout_V2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function RMDashboard() {
  const [pieChartData, setPieChartData] = useState();
  const [rmTarget, setRMTarget] = useState();
  const [tableData, setTableData] = useState();
  const [totals, setTotals] = useState();

  const columnNames = [
    { id: 0, name: "Region" },
    { id: 1, name: "Area" },
    { id: 2, name: "Pipeline" },
    { id: 3, name: "Pending (Registered on System)" },
    { id: 4, name: "Canceled" },
    { id: 5, name: "Confirmed" },
  ];

  useEffect(() => {
    apiAnom
      .get("/rm/dashboard/target")
      .then((res) => {
        setRMTarget(res.data.data);
      })
      .catch(notifError);

    apiAnom
      .get("/rm/dashboard/pie-chart")
      .then((res) => {
        setPieChartData({
          labels: ["Confirmed", "Pending", "Cancelled"],
          datasets: [
            {
              label: "# of Votes",
              data: [
                res.data.data.success,
                res.data.data.pending,
                res.data.data.cancel,
              ],
              backgroundColor: [
                "rgba(75, 192, 192, 0.2)", // green
                "rgba(255, 206, 86, 0.2)", // yellow
                "rgba(255, 99, 132, 0.2)", // red
              ],
              borderColor: [
                "rgba(75, 192, 192, 1)", // green
                "rgba(255, 206, 86, 1)", // yellow
                "rgba(255, 99, 132, 1)", // red
              ],
              borderWidth: 1,
            },
          ],
        });
      })
      .catch(notifError);

    apiAnom
      .get("/rm/dashboard/table")
      .then((res) => {
        setTableData(res.data.data);
        const tableTotals = res.data.data.reduce(
          (acc, item) => {
            acc[0] += item.pipeline;
            acc[1] += item.pending;
            acc[2] += item.cancel;
            acc[3] += item.success;
            return acc;
          },
          [0, 0, 0, 0]
        );
        setTotals(tableTotals);
      })
      .catch(notifError);
  }, []);

  return (
    <LayoutV2 isRM={true}>
      <div
        className="w-screen h-auto flex flex-grow flex-col overflow-auto"
        style={{
          backgroundColor: "rgba(246, 241, 245, 1)",
          paddingTop: "96px",
          paddingLeft: "77px",
          paddingRight: "87px",
          paddingBottom: "50px",
        }}
      >
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-3/5 h-auto flex flex-col justify-start items-start">
            <p
              className="text-left font-bold text-3xl tracking-0.8px"
              style={{ lineHeight: "38.4px" }}
            >
              Summer Camp Program 2024
            </p>
            <div className="w-max flex flex-col justify-center items-center gap-3 px-7 py-6 rounded-20px bg-white mt-7">
              <p
                className="text-center font-bold text-2xl tracking-0.8px"
                style={{ lineHeight: "28.8px" }}
              >
                Product Placement Deadline
              </p>
              <p
                className="text-center font-semibold text-base text-white py-3 w-full bg-primaryColor rounded-lg"
                style={{ lineHeight: "24px" }}
              >
                2 April 2024
              </p>
            </div>
            <div className="w-max flex flex-col justify-center items-start gap-3 px-7 py-6 rounded-20px bg-white mt-3">
              <p
                className="text-center font-bold text-2xl tracking-0.8px"
                style={{ lineHeight: "28.8px" }}
              >
                Product Placement Deadline
              </p>
              <div className="flex flex-row justify-start items-center gap-4 w-max">
                <p
                  className="text-center font-semibold text-base text-white py-3 px-14 w-max whitespace-nowrap bg-primaryColor rounded-lg"
                  style={{ lineHeight: "24px" }}
                >
                  <span className="text-2xl" style={{ lineHeight: "36px" }}>
                    {rmTarget?.closing}
                  </span>
                  <br />
                  Closing
                </p>
                <p
                  className="text-center font-semibold text-base text-white py-3 px-7 w-max whitespace-nowrap bg-primaryColor rounded-lg"
                  style={{ lineHeight: "24px" }}
                >
                  <span className="text-2xl" style={{ lineHeight: "36px" }}>
                    {rmTarget?.overall_target}
                  </span>
                  <br />
                  Target
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row justify-start items-start gap-3 mt-3 overflow-x-scroll">
              {rmTarget?.classes.map((item, index) => (
                <div
                  key={index}
                  className="w-max flex flex-col justify-center items-center gap-3 px-7 py-6 rounded-20px bg-white"
                  style={{ minWidth: "252px", maxWidth: "252px" }}
                >
                  <p
                    className="w-full text-left font-bold text-2xl tracking-0.8px line-clamp-1"
                    style={{ lineHeight: "28.8px" }}
                  >
                    {item.topic}
                  </p>
                  <p
                    className="text-center font-semibold text-base text-white py-3 w-full bg-primaryColor rounded-lg"
                    style={{ lineHeight: "24px" }}
                  >
                    {item.target}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-2/5 h-auto">
            {pieChartData && <Pie data={pieChartData} />}
          </div>
        </div>
        <div
          className="w-full grid grid-cols-6 rounded-t-lg mt-14"
          style={{ backgroundColor: "rgba(203, 213, 225, 1)" }}
        >
          {columnNames.map((item, index) => (
            <div
              key={index}
              className={`w-full h-full flex justify-center items-center text-center font-bold text-xl text-black py-7 border border-gray-300 ${
                index === 0 ? "rounded-tl-lg" : index === 5 && "rounded-tr-lg"
              }`}
              style={{ height: "124px" }}
            >
              {item.name.includes("Pending") ? (
                <>
                  {item.name.split(" ")[0]}
                  <br />
                  {item.name.split(" ").slice(1).join(" ")}
                </>
              ) : index === 2 ? (
                <p className="text-base">
                  {"Pipeline last updated at"}
                  <br />
                  {tableData
                    ? moment(tableData[0].updated_at)
                        .tz("Asia/Jakarta")
                        .format("DD MMMM YYYY HH:mm [GMT+07]")
                    : ""}
                </p>
              ) : (
                item.name
              )}
            </div>
          ))}
        </div>
        {tableData && (
          <div className="w-full h-auto flex flex-col">
            {tableData.map((_, indexData) => (
              <div key={indexData} className="w-full grid grid-cols-6 bg-white">
                {columnNames.map((_, index) => (
                  <div
                    key={index}
                    className="w-full h-full flex justify-start items-center text-center font-normal text-xl text-black py-7 border border-gray-300 pl-13"
                    style={{ height: "75px" }}
                  >
                    {index === 0
                      ? tableData[indexData].region
                      : index === 1
                      ? tableData[indexData].area
                      : index === 2
                      ? tableData[indexData].pipeline
                      : index === 3
                      ? tableData[indexData].pending
                      : index === 4
                      ? tableData[indexData].cancel
                      : index === 5 && tableData[indexData].success}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        {totals && (
          <div className="w-full grid grid-cols-6 rounded-b-lg bg-primaryColor">
            {columnNames.map((item, index) => (
              <div
                key={index}
                className={`w-full h-full flex justify-start items-center text-center font-bold text-xl text-white py-7 border border-gray-300 pl-13 ${
                  index === 0 ? "rounded-bl-lg" : index === 5 && "rounded-br-lg"
                }`}
                style={{ height: "75px" }}
              >
                {index === 0
                  ? "Total"
                  : index === 1
                  ? ""
                  : index === 2
                  ? totals[0]
                  : index === 3
                  ? totals[1]
                  : index === 4
                  ? totals[2]
                  : totals[3]}
              </div>
            ))}
          </div>
        )}
      </div>
    </LayoutV2>
  );
}
