import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

export default function NoConcentration({ submit, screenWidth, major }) {
  const auth = useRecoilValue(authState);
  const [selectedConcentrationIndex, setSelectedConcentrationIndex] =
    useState();
  const concentrationOptions = ["IPA", "IPS", "Belum tahu"];
  const majorIndex = (major) => {
    switch (major) {
      case "IPA":
        return 0;
      case "IPS":
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    if (auth.isAuth) {
      setSelectedConcentrationIndex(majorIndex(auth.major));
    }
    if (major) {
      setSelectedConcentrationIndex(majorIndex(major));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submit(e.target[3].value);
  };

  return (
    <div
      className="w-full h-full flex flex-col items-stretch"
      style={{
        minWidth: screenWidth >= 1024 && "630px",
        maxWidth: screenWidth >= 1024 && "630px",
      }}
    >
      <div className="relative z-20 w-full h-full flex flex-col px-8 sm:px-0">
        <p className="text-left font-semibold text-base text-white">
          Apakah kamu sudah memiliki minat jurusan tertentu?
        </p>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-stretch relative"
          style={{
            marginTop: "13.89px",
            minWidth: screenWidth >= 1024 ? "630px" : "321px",
            maxWidth: screenWidth >= 1024 ? "630px" : "321px",
            minHeight: screenWidth >= 1024 ? "383.75px" : "269px",
            maxHeight: screenWidth >= 1024 ? "383.75px" : "269px",
          }}
        >
          <div
            className="flex flex-col justify-start items-stretch gap-2"
            style={{
              minWidth: screenWidth < 1024 ? "215.6px" : "431.64px",
              maxWidth: screenWidth < 1024 ? "215.6px" : "431.64px",
            }}
          >
            {concentrationOptions.map((item, index) => (
              <button
                type="submit"
                key={index}
                onClick={() => {
                  setSelectedConcentrationIndex(index);
                }}
                className="w-full h-auto flex flex-row justify-start items-center rounded-full transform ease-in-out duration-300"
                style={{
                  gap: "14.09px",
                  backgroundColor:
                    index === selectedConcentrationIndex
                      ? "rgba(255, 202, 45, 1)"
                      : "white",
                  padding: screenWidth < 1024 ? "4px" : "6px",
                }}
              >
                <div
                  className="w-9 h-9 sm:w-15 sm:h-15 rounded-full transform ease-in-out duration-300"
                  style={{
                    backgroundColor:
                      index === selectedConcentrationIndex
                        ? "white"
                        : "rgba(255, 202, 45, 1)",
                    padding: screenWidth < 1024 ? "9px" : "14px",
                  }}
                >
                  <div
                    className="w-full h-full rounded-full transform ease-in-out duration-300"
                    style={{
                      backgroundColor:
                        index === selectedConcentrationIndex
                          ? "rgba(255, 202, 45, 1)"
                          : "white",
                    }}
                  />
                </div>
                <p
                  className={`text-left ${
                    index === selectedConcentrationIndex
                      ? "font-bold"
                      : "font-medium"
                  } text-sm text-black`}
                >
                  {item}
                </p>
              </button>
            ))}
          </div>
          <input
            type="hidden"
            name="selected"
            value={concentrationOptions[selectedConcentrationIndex ?? 0]}
          />
          {/* <div
            className="flex w-full justify-end absolute"
            style={{
              bottom: "0px",
            }}
          >
            <button
              type="submit"
              disabled={!(selectedConcentrationIndex >= 0)}
              className="w-max py-2 px-8 rounded-md text-center font-bold text-sm text-black1"
              style={{
                backgroundColor:
                  selectedConcentrationIndex >= 0
                    ? "rgba(255, 202, 45, 1)"
                    : "grey",
                marginTop: "18.6px",
              }}
            >
              Lanjut
            </button>
          </div> */}
        </form>
      </div>
    </div>
  );
}
