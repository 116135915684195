import moment from "moment-timezone";
import Headerv2 from "../../components/Header_v2";
import Layout from "../../components/Layout";
import { Fragment, useEffect, useRef, useState } from "react";
import AdmisFAQ from "../../components/AdmisFAQ";
import { Link } from "react-router-dom";
import CardPenerimaan from "../../components/CardPenerimaan";
import { Typewriter } from "react-simple-typewriter";
import Pagination from "../../components/pagination/Pagination";
import { api, apiAnom } from "../../utils/api";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import PageLoading from "../PageLoading";
import { MdClose } from "react-icons/md";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import ReactDatePicker from "react-datepicker";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/solid";
import Slider from "react-slick";
import LayoutV2 from "../../components/Layout_V2";

export default function ExplorasiPenerimaan() {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [admissionsEndSoon, setAdmissionsEndSoon] = useState();
  const [admissionsData, setAdmissionsData] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchDeadline, setSearchDeadline] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isDateDDOpen, setIsDateDDOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const dateRef = useRef();
  const sliderRef = useRef();
  const [currentSliderPage, setCurrentSliderPage] = useState(1);
  const today = new Date();

  const slickCarouselSetting = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  useEffect(() => {
    document.title = "Explore Penerimaan";

    const getEndSoon = () => {
      _api
        .get("/admissions/end-soon")
        .then((res) => {
          setAdmissionsEndSoon(res.data.data);
        })
        .catch((_) => {
          setAdmissionsEndSoon([]);
        });
    };
    getEndSoon();
  }, []);

  useEffect(() => {
    const getAdmissionsData = () => {
      _api
        .post("/admissions/search", {
          univ_name: searchKeyword,
          deadline: searchDeadline,
          limit: 9,
          page: currentPage,
        })
        .then((res) => {
          setAdmissionsData(res.data.data);
        })
        .catch((_) => {
          setAdmissionsData([]);
        });
    };
    getAdmissionsData();
  }, [searchKeyword, searchDeadline, currentPage]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  const admisStep = [
    {
      image: "/illu_admis_step1.svg",
      imageBGCol: "rgba(254, 82, 68, 0.2)",
      imageCount: "/illu_admis_stepcount1.svg",
      title: "Pilih penerimaan",
      description:
        "Cari dan pilih berbagai pendaftaran kampus yang kamu inginkan.",
    },
    {
      image: "/illu_admis_step2.svg",
      imageBGCol: "rgba(249, 198, 11, 0.2)",
      imageCount: "/illu_admis_stepcount2.svg",
      title: "Isi formulir",
      description:
        "Lengkapi informasi dan segala dokumen yang dibutuhkan untuk pendaftaran.",
    },
    {
      image: "/illu_admis_step3.svg",
      imageBGCol: "rgba(67, 188, 205, 0.2)",
      imageCount: "/illu_admis_stepcount3.svg",
      title: "Lakukan pembayaran",
      description:
        "Dengan menyelesaikan pembayaran, kamu telah berhasil membeli formulir!",
    },
    {
      image: "/illu_admis_step4.svg",
      imageBGCol: "rgba(255, 220, 218, 1)",
      imageCount: "/illu_admis_stepcount4.svg",
      title: "Pilih lainnya",
      description:
        "Ga perlu repot lagi! Kamu bisa langsung pilih pendaftaran dari kampus lainnya ",
    },
  ];

  const admisFAQ = [
    {
      question:
        "Mengapa aku tidak dapat meng-klik jalur admisi ke suatu universitas?",
      answer:
        "Kamu telah menekan tombol submit untuk formulir registrasi ke universitas tersebut, sehingga kamu harus membayar biaya formulir registrasi tersebut dalam waktu 24 jam terlebih dahulu. Setelah itu, kamu dapat menekan kembali jalur admisi tersebut.",
    },
    // {
    //   question: "Apa itu Exploration Admisi?",
    //   answer: `Exploration Admisi membantu pelajar SMA mencari informasi mengenai kampus idaman dan membantu dalam proses pendaftaran. Platform ini memberikan informasi lengkap mengenai program studi, panduan pendaftaran, dan tips untuk meningkatkan peluang diterima di universitas impian.`,
    // },
    // {
    //   question:
    //     "Apakah data status pembayaran di dashboard Exploration ditampilkan real-time?",
    //   answer:
    //     "Exploration Admisi membantu pelajar SMA mencari informasi mengenai kampus idaman dan membantu dalam proses pendaftaran. Platform ini memberikan informasi lengkap mengenai program studi, panduan pendaftaran, dan tips untuk meningkatkan peluang diterima di universitas impian.",
    // },
    // {
    //   question: "Bagaimana kalau ada payment dispute?",
    //   answer: "",
    // },
  ];

  const uniAdmises = [
    {
      logoUrl:
        "https://strgonelabsprod.blob.core.windows.net/explorationid/New%20University%20Information/Partner%20University/10.%20Lasalle%20Collage%20Jakarta/Info_LaSalle%20College%20Jakarta/Format-%20Logo%20Photo_%5BLaSalle%20College%20Jakarta%5D.jpg",
      univName: "LaSalle College Jakarta",
      admissionName: "LaSalle College Jakarta",
      redirect:
        "/admission/lasalle-college-jakarta?admissionID=2ThmTYRZNwZoniAFH1coUFOqO7T",
    },
    {
      logoUrl:
        "https://strgonelabsprod.blob.core.windows.net/explorationid/University%20Logo/Logo_Sampoerna%20University.png",
      univName: "Sampoerna University",
      admissionName: "Sampoerna University",
      redirect:
        "/admission/sampoerna-university?admissionID=2U1p0nZx3FbmkVmCGxlhWidpP6x",
    },
    {
      logoUrl:
        "https://strgonelabsprod.blob.core.windows.net/explorationid/New%20University%20Information/Partner%20University/2.%20Universitas%20Widyatama/Logo%20Univ%20Widyatama_New.png",
      univName: "Universitas Widyatama",
      admissionName: "Universitas Widyatama",
      redirect:
        "/admission/universitas-widyatama?admissionID=2U1oduDcRfxTxCJgYeN61Pv7phB",
    },
  ];

  const [currentUniIndex, setCurrentUniIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [changeUni, setChangeUni] = useState(false);
  const [formWords, setFormWords] = useState(
    `Formulir Pendaftaran|${uniAdmises[currentUniIndex].univName}`
  );

  function fadeHandler(isFade) {
    setFade(isFade);
  }

  useEffect(() => {
    setTimeout(() => {
      fadeHandler(true);
    }, 1000);

    setTimeout(() => {
      if (currentUniIndex < 2) {
        setCurrentUniIndex(currentUniIndex + 1);
        fadeHandler(false);
        setTimeout(() => {
          setChangeUni(!changeUni);
        }, 4000);
      } else {
        setCurrentUniIndex(0);
        fadeHandler(false);
        setTimeout(() => {
          setChangeUni(!changeUni);
        }, 4000);
      }
    }, 5000);
  }, [changeUni]);

  var i = 0;

  useEffect(() => {
    setFormWords(`${uniAdmises[currentUniIndex].univName}`);
  }, [currentUniIndex]);

  const formSentences = [
    "LaSalle College Jakarta",
    "Sampoerna University",
    "Universitas Widyatama",
  ];

  if (!admissionsData || !admissionsEndSoon) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0">
      <div
        className="flex flex-row justify-center w-screen pb-10 tablet:pb-26"
        // style={{
        //   paddingTop: screenWidth < 1024 ? "16px" : "55px",
        //   paddingLeft: screenWidth < 1024 ? "16px" : "80px",
        //   paddingBottom: screenWidth < 1024 ? "40px" : "104px",
        // }}
      >
        <div className="flex flex-col justify-start items-start w-full mt-4 tablet:mt-14 pl-4 tablet:pl-8 hd:pl-20">
          <p
            className="text-left font-bold text-3xl tablet:text-5xl mac:text-60px text-black1 tablet:tracking-1.2px"
            style={{ lineHeight: screenWidth < 1024 ? "110%" : "120%" }}
          >
            Satu Kali Isi,
            <br />
            Daftar Banyak Uni!
          </p>
          <p
            className="text-left text-base tablet:text-lg mac:text-xl text-black188"
            style={{
              lineHeight: screenWidth < 1024 ? "130%" : "150%",
              marginTop: screenWidth < 1024 ? "12px" : "6px",
            }}
          >
            Cukup isi data diri 1 kali, kamu bisa daftar di berbagai
            {screenWidth >= 1024 && <br />} kampus impian!
          </p>
          <a
            href="https://ocbcnisp-hcis.typeform.com/to/mn0sO3sN"
            target="_blank"
            rel="noreferrer"
            className="p-3 w-max rounded-xl bg-primaryColor text-left font-semibold text-base text-white mt-4 relative z-20"
            style={{ lineHeight: "150%" }}
          >
            Daftar Sekarang
          </a>
        </div>
        {screenWidth >= 1024 && (
          <div
            className="absolute w-full h-full overflow-hidden z-10"
            // style={{
            //   minWidth: "715.271px",
            //   minHeight: "451.95px",
            //   maxWidth: "715.271px",
            //   maxHeight: "451.95px",
            // }}
          >
            <img
              src="./illu_admisi_formpaper.svg"
              className="absolute -top-2 -right-50 mac:right-0"
            />
            <div
              className="flex flex-row justify-start items-center gap-6 absolute -right-10 mac:right-32"
              // className={`flex flex-row justify-start items-center gap-6 absolute -right-10 mac:right-32 transform transition ${
              //   !fade ? "opacity-100" : "opacity-0"
              // }`}
              style={{
                top: "68px",
                transitionDuration: "3000ms",
                transform: "rotate(5.714deg)",
              }}
            >
              <img
                alt="uni_logo"
                src={uniAdmises[currentUniIndex].logoUrl}
                className={`rounded-full object-contain transform transition ${
                  !fade ? "opacity-100" : "opacity-0"
                }`}
                style={{
                  transitionDuration: "3000ms",
                  minWidth: "136px",
                  minHeight: "136px",
                  maxWidth: "136px",
                  maxHeight: "136px",
                }}
              />
              <p
                className="text-left font-bold text-3xl text-black1 line-clamp-2 w-94"
                style={{
                  lineHeight: "120%",
                  letterSpacing: "-1.013px",
                }}
              >
                Formulir Pendaftaran <br />
                <Typewriter
                  words={formSentences}
                  loop={0}
                  cursor
                  cursorStyle="|"
                  typeSpeed={50}
                  deleteSpeed={50}
                  delaySpeed={3000}
                  // onLoopDone={handleDone}
                  // onType={handleType}
                />
                {/* Formulir Pendaftaran <br />{" "}
                {uniAdmises[currentUniIndex].univName} */}
              </p>
              {/* <p id="demo"></p> */}
            </div>
          </div>
        )}
        {/* <div
          className="flex flex-col px-4 gap-6 justify-start items-center relative w-full"
          style={{
            marginTop: "54px",
            minWidth: `${screenWidth >= 1024 && "720px"}`,
            maxWidth: `${screenWidth >= 1024 && "720px"}`,
          }}
        >
          <img
            src="/star-4point.svg"
            className="w-13 h-13 tablet:w-22 tablet:h-22 absolute -top-8 tablet:-top-12 right-2 tablet:-right-8"
          />
          <img
            src="/illu_purple_pencil2.svg"
            className={`absolute ${
              screenWidth < 1024 ? "top-16" : "bottom-4"
            } left-4 tablet:-left-16 tablet:w-24 tablet:h-24`}
          />
          <p
            className="text-center font-bold text-3xl tablet:text-60px text-black1 tracking-1.2px px-6"
            style={{ lineHeight: `${screenWidth < 1024 ? "38px" : "72px"}` }}
          >
            Pilih penerimaan dan daftarkan dirimu!
          </p>
          <p
            className="text-center font-normal text-base tablet:text-xl text-black188"
            style={{
              lineHeight: `${screenWidth < 1024 ? "24px" : "30px"}`,
            }}
          >
            Berbagai pilihan penerimaan universitas bisa kamu lihat{" "}
            {screenWidth >= 1024 && <br />}di sini. Yuk buruan daftar sekarang!
          </p>
        </div> */}
      </div>
      <div
        className="flex flex-col items-start justify-start w-full h-max pt-12 tablet:p-20 relative z-20"
        style={{ backgroundColor: "#EE4033", paddingBottom: "84px" }}
      >
        {/* <img
          src="illu_mejajalan.svg"
          className="absolute tablet:h-46 tablet:w-46 -top-20 tablet:-top-28 right-4 tablet:right-74 z-10"
        /> */}
        {screenWidth >= 1024 && (
          <img
            src="illu_admis_halo.svg"
            className="absolute right-0 bottom-30 z-20"
          />
        )}
        <img
          src={`${
            screenWidth < 1024
              ? "background_exploreadmisi.svg"
              : "background_exploreadmisi2.svg"
          }`}
          className="absolute bottom-0 right-0 h-full object-cover"
        />
        <div className="flex flex-row w-full justify-between items-center">
          <p
            className="text-left font-bold text-2xl tablet:text-48px text-white tracking-0.8px px-4 relative z-10"
            style={{ lineHeight: "26px" }}
          >
            Yang sedang dibuka
          </p>
          {screenWidth >= 1024 && admissionsEndSoon.length > 3 && (
            <div className="flex flex-row gap-2 w-1/6 items-center justify-end relative z-10">
              <div className="flex flex-row items-center justify-end text-base mac:text-xl text-right text-white font-normal tracking-wide mr-10">
                <p>{currentSliderPage}</p>
                <p>/2</p>
              </div>
              <button
                onClick={() => {
                  if (currentSliderPage == 2) {
                    sliderRef.current.slickPrev();
                  }
                  setCurrentSliderPage(1);
                }}
                className={`bg-white rounded-lg p-3 ${
                  currentSliderPage == 2 ? "opacity-100" : "opacity-30"
                }`}
              >
                <ArrowLeftIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
              <button
                onClick={() => {
                  if (currentSliderPage == 1) {
                    sliderRef.current.slickNext();
                  }
                  setCurrentSliderPage(2);
                }}
                className={`bg-white rounded-lg p-3 ${
                  currentSliderPage == 1 ? "opacity-100" : "opacity-30"
                }`}
              >
                <ArrowRightIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
            </div>
          )}
        </div>

        {admissionsEndSoon.length <= 3 || screenWidth <= 1024 ? (
          <div className="flex flex-row gap-6 overflow-x-auto w-full px-4 tablet:px-0 mt-12 tablet:mt-16 relative z-10">
            {admissionsEndSoon.map((item, index) => (
              <div key={index}>
                <CardPenerimaan
                  screenWidth={screenWidth}
                  logoUrl={item.logo_url}
                  univName={item.univ_name}
                  admissionName={item.title}
                  admisFee={item.price}
                  admisOpenDate={item.start_date}
                  admisCloseDate={item.end_date}
                  redirect={
                    item.form_slug === "general"
                      ? `/daftar-kampus/${item.univ_slug}?admissionID=${item.admission_id}`
                      : `/admission/${item.form_slug}?admissionID=${item.admission_id}`
                  }
                  isDisabled={item.is_admission_expired}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full pl-4 tablet:pl-0 mt-12 tablet:mt-16 relative z-10">
            <Slider ref={sliderRef} {...slickCarouselSetting} id="slick">
              {admissionsEndSoon.map((item, index) => (
                <div key={index}>
                  <CardPenerimaan
                    screenWidth={screenWidth}
                    logoUrl={item.logo_url}
                    univName={item.univ_name}
                    admissionName={item.title}
                    admisFee={item.price}
                    admisOpenDate={item.start_date}
                    admisCloseDate={item.end_date}
                    redirect={
                      item.form_slug === "general"
                        ? `/daftar-kampus/${item.univ_slug}?admissionID=${item.admission_id}`
                        : `/admission/${item.form_slug}?admissionID=${item.admission_id}`
                    }
                    isDisabled={item.is_admission_expired}
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>

      {/* Steps Section */}
      <div
        className="flex flex-col w-full h-max px-4 pt-8 tablet:px-20 tablet:pt-15 relative"
        style={{
          backgroundColor: "#EEF0F3",
          paddingBottom: `${screenWidth < 1024 ? "85px" : "100px"}`,
        }}
      >
        <p
          className="text-left font-bold text-2xl tablet:text-48px text-black1 tracking-0.8px"
          style={{ lineHeight: `${screenWidth < 1024 ? "26px" : "58px"}` }}
        >
          4 Langkah mudah untuk mendaftar
        </p>
        <div className="flex flex-col tablet:flex-row justify-start mac:justify-center items-center gap-8 w-full mt-8 tablet:mt-16 overflow-x-auto mac:overflow-x-hidden">
          {admisStep.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col w-full items-center justify-center h-max bg-white rounded-xl ${
                screenWidth < 1024 ? "p-6" : index === 2 ? "px-5 py-8" : "p-8"
              }`}
              style={{
                minHeight: "257px",
                maxHeight: "257px",
                minWidth: "296px",
                maxWidth: "296px",
                boxShadow: "24px 24px 200px rgba(23, 23, 23, 0.08)",
              }}
            >
              <div
                className="relative h-14 w-14 rounded-lg pt-2 pl-3"
                style={{ backgroundColor: item.imageBGCol }}
              >
                <img
                  src={item.image}
                  className={`${index === 3 && "w-20 absolute top-0 left-2"}`}
                />
                <img
                  src={item.imageCount}
                  className="object-contain w-9 h-9 absolute -bottom-2 -right-3"
                />
              </div>
              <p
                className="text-center font-bold text-xl tablet:text-2xl text-black1 mt-6"
                style={{
                  lineHeight: `${screenWidth < 1024 ? "25px" : "30px"}`,
                }}
              >
                {item.title}
              </p>
              <p
                className="text-center font-normal text-sm tablet:text-base text-black1 mt-2"
                style={{
                  lineHeight: `${screenWidth < 1024 ? "22px" : "25px"}`,
                }}
              >
                {item.description}
              </p>
            </div>
          ))}
        </div>
        <div className="absolute -bottom-8 tablet:-bottom-10 flex w-full left-0 px-4 mac:px-50">
          <div className="flex flex-row w-full py-3 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg">
            <form className="relative flex flex-col divide-y-2 remove-input-txt-border w-full px-0 tablet:px-4">
              <input
                type="text"
                className="flex-1 sm:flex-2 form-input border-none pb-4"
                style={{
                  maxHeight: "38px",
                }}
                placeholder="&#x1F50D; Cari kampus"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                  // setUnivSuggestion(true);
                }}
              />
              {/* {univSuggestion && keyword.length > 0 && (
                <div className="absolute z-10 top-10 left-0 flex flex-col items-start w-full overflow-auto shadow-lg px-0 tablet:px-8 bg-white">
                  {univNames.map((item, index) => (
                    <Button
                      size="small"
                      variant="link"
                      key={`univ ${index}`}
                      className="font-normal text-sm text-black1 text-left w-full py-3 justify-start"
                      onClick={() => {
                        setUnivSuggestion(false);
                        setKeyword(item);
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </div>
              )} */}
              {/* {screenWidth >= 1024 && (
                <div className="flex flex-col tablet:flex-row pt-4 divide-x-2">
                  <div className="flex flex-1 flex-col w-full items-start justify-center">
                    <p className="font-bold text-base text-black1 text-left pl-3 pb-2">
                      Lokasi kampus
                    </p>
                    <ReactSelect
                      styles={styles}
                      options={listProvince}
                      isMulti
                      isClearable={false}
                      isSearchable={false}
                      controlShouldRenderValue={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      menuColor
                      getOptionLabel={(data) => data["name"]}
                      getOptionValue={(data) => data["id"]}
                      components={{
                        Option: DropdownItem,
                      }}
                      className="w-full border-none"
                      placeholder="Pilih lokasi Kampus"
                      onChange={(v) => {
                        const univProvince = new Set(v);
                        setFilterProvince(univProvince);
                        setCurrentPage(1);
                      }}
                      value={Array.from(filterProvince)}
                    />
                  </div>
                  <div className="flex flex-1 flex-col w-full items-start justify-center pl-4">
                    <p className="font-bold text-base text-black1 text-left pl-3 pb-2">
                      Akreditasi
                    </p>
                    <ReactSelect
                      styles={styles}
                      options={univAccreditation}
                      isMulti
                      isClearable={false}
                      isSearchable={false}
                      controlShouldRenderValue={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      getOptionLabel={(data) => data["label"]}
                      getOptionValue={(data) => data["value"]}
                      components={{
                        Option: DropdownItem,
                      }}
                      className="w-full border-none"
                      placeholder="Pilih akreditasi kampus"
                      onChange={(v) => {
                        const univAccre = new Set(v);
                        setFilterAcreditation(univAccre);
                        setCurrentPage(1);
                      }}
                      value={Array.from(filterAcreditation)}
                    />
                  </div>
                  <div className="flex flex-1 flex-col w-full items-start justify-center pl-4">
                    <p className="font-bold text-base text-black1 text-left pl-3 pb-2">
                      Jurusan
                    </p>
                    <ReactSelect
                      styles={styles}
                      options={majorsList}
                      isMulti
                      isClearable={false}
                      isSearchable={false}
                      controlShouldRenderValue={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      menuColor
                      getOptionLabel={(data) => data["name"]}
                      getOptionValue={(data) => data["id"]}
                      components={{
                        Option: DropdownItem,
                      }}
                      className="w-full border-none"
                      placeholder="Pilih Kampus sesuai Jurusan"
                      onChange={(v) => {
                        const univMajor = new Set(v);
                        setFilterMajor(univMajor);
                        setCurrentPage(1);
                      }}
                      value={Array.from(filterMajor)}
                    />
                  </div>
                  <div className="flex flex-1 flex-col w-full items-start justify-center pl-4">
                    <p className="font-bold text-base text-black1 text-left pl-3 pb-2">
                      Tipe kampus
                    </p>
                    <ReactSelect
                      styles={styles}
                      options={universityType}
                      isMulti
                      isClearable={false}
                      isSearchable={false}
                      controlShouldRenderValue={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      menuColor
                      getOptionLabel={(data) => data["label"]}
                      getOptionValue={(data) => data["value"]}
                      components={{
                        Option: DropdownItem,
                      }}
                      className="w-full border-none"
                      placeholder="Pilih tipe Kampus"
                      onChange={(v) => {
                        const univType = new Set(v);
                        setFilterType(univType);
                        setCurrentPage(1);
                      }}
                      value={Array.from(filterType)}
                    />
                  </div>
                </div>
              )} */}
            </form>
            {screenWidth < 1024 ? (
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => {
                    openModal();
                  }}
                  className="pr-6"
                >
                  <img src="/filter-icon.svg" />
                </button>
                <Transition appear show={isOpen} as={Fragment}>
                  <Dialog as="div" className="relative z-10" onClose={() => {}}>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div
                        className="fixed inset-0"
                        style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
                      />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel
                            className="flex w-screen min-h-screen transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                            style={{
                              minHeight: screenHeight - 35,
                              maxHeight: screenHeight - 35,
                            }}
                          >
                            <div
                              className="relative flex min-h-screen w-full flex-col overflow-y-auto bg-white"
                              style={{
                                minHeight: screenHeight - 20,
                                maxHeight: screenHeight - 20,
                              }}
                            >
                              <div className="flex flex-col w-full p-4 h-4/5 overflow-y-auto">
                                <div className="flex flex-col w-full p-2">
                                  <button type="button" onClick={closeModal}>
                                    <MdClose
                                      size={24}
                                      color="rgba(0, 0, 0, 0.54)"
                                    />
                                  </button>
                                  <div className="flex flex-col gap-4 divide-y-2 mt-10 overflow-y-auto">
                                    <div className="w-full items-start justify-center">
                                      <p className="font-bold text-base text-black1 text-left pb-2">
                                        Batas Pendaftaran
                                      </p>
                                      <div className="flex flex-row w-full justify-center items-center gap-2">
                                        <ReactDatePicker
                                          showYearDropdown
                                          dropdownMode="select"
                                          ref={dateRef}
                                          dateFormat="dd MMMM yyyy"
                                          selected={startDate}
                                          placeholderText="Pilih Tanggal"
                                          onChange={(date) => {
                                            setStartDate(date);
                                            setSearchDeadline(
                                              moment(date).format("YYYY-MM-DD")
                                            );
                                            setIsDateDDOpen(false);
                                          }}
                                          className="form-input block w-full rounded-lg bg-gray9 h-8 text-center font-medium text-sm text-black1"
                                        />
                                        {isDateDDOpen ? (
                                          <ChevronUpIcon
                                            className="h-6"
                                            onClick={() => {
                                              dateRef.current.setOpen(false);
                                              setIsDateDDOpen(false);
                                            }}
                                          />
                                        ) : (
                                          <ChevronDownIcon
                                            className="h-6"
                                            onClick={() => {
                                              dateRef.current.setOpen(true);
                                              setIsDateDDOpen(true);
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="absolute flex flex-col w-full bottom-10 px-6 bg-white">
                                <button
                                  disabled={!startDate}
                                  onClick={closeModal}
                                  className={`flex flex-col items-center justify-center h-12 rounded-lg ${
                                    !startDate
                                      ? "bg-gray-400"
                                      : "bg-primaryColor"
                                  }`}
                                >
                                  <p className="font-semibold text-base text-center text-white">
                                    Terapkan filter pencarian
                                  </p>
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </div>
            ) : (
              <div className="flex flex-row justify-center items-center pr-8">
                <div className="border-1 h-full" />
                <ReactDatePicker
                  showYearDropdown
                  dropdownMode="select"
                  ref={dateRef}
                  dateFormat="dd MMMM yyyy"
                  selected={startDate}
                  placeholderText="Batas Pendaftaran"
                  onChange={(date) => {
                    setStartDate(date);
                    setSearchDeadline(moment(date).format("YYYY-MM-DD"));
                    setIsDateDDOpen(false);
                  }}
                  className="form-input block w-full h-8 text-center font-semibold text-base text-black1 border-0"
                />
                {isDateDDOpen ? (
                  <ChevronUpIcon
                    className="h-6"
                    onClick={() => {
                      dateRef.current.setOpen(false);
                      setIsDateDDOpen(false);
                    }}
                  />
                ) : (
                  <ChevronDownIcon
                    className="h-6"
                    onClick={() => {
                      dateRef.current.setOpen(true);
                      setIsDateDDOpen(true);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* List Penerimaan Section */}
      {admissionsData.admissions.length < 1 ? (
        <div
          className="flex w-full justify-center"
          style={{
            paddingTop: "84px",
            paddingBottom: "86px",
            paddingLeft: `${screenWidth < 1024 ? "30px" : "80px"}`,
            paddingRight: `${screenWidth < 1024 ? "30px" : "80px"}`,
          }}
        >
          <h3 className="text-gray-800 text-2xl text-center">
            Pencarian data tidak di temukan
          </h3>
        </div>
      ) : (
        <div
          className="grid grid-cols-1 tablet:grid-cols-3 gap-6 w-full"
          style={{
            paddingTop: "84px",
            paddingBottom: "86px",
            paddingLeft: `${screenWidth < 1024 ? "30px" : "80px"}`,
            paddingRight: `${screenWidth < 1024 ? "30px" : "80px"}`,
          }}
        >
          {admissionsData.admissions.map((item, index) => (
            <div key={index}>
              <CardPenerimaan
                screenWidth={screenWidth}
                logoUrl={item.logo_url}
                univName={item.univ_name}
                admissionName={item.title}
                admisFee={item.price}
                admisOpenDate={item.start_date}
                admisCloseDate={item.end_date}
                redirect={
                  item.form_slug === "general"
                    ? `/daftar-kampus/${item.univ_slug}?admissionID=${item.admission_id}`
                    : `/admission/${item.form_slug}?admissionID=${item.admission_id}`
                }
                isDisabled={item.is_admission_expired}
              />
            </div>
          ))}
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalCount={admissionsData.metadata.total_item}
        pageSize={admissionsData.metadata.limit}
        onPageChange={(page) => setCurrentPage(page)}
      />

      {/* FAQ Section */}
      <div
        className="flex flex-col tablet:flex-row pt-8 pb-16 tablet:py-15"
        style={{
          paddingLeft: `${screenWidth < 1024 ? "30px" : "80px"}`,
          paddingRight: `${screenWidth < 1024 ? "30px" : "80px"}`,
        }}
      >
        <div className="flex flex-col w-full justify-start">
          <p
            className="text-left font-bold text-2xl tablet:text-48px text-black1 tracking-0.8px"
            style={{ lineHeight: `${screenWidth < 1024 ? "26px" : "58px"}` }}
          >
            Frequently Asked
            <br />
            Questions
          </p>
          {screenWidth >= 1024 && (
            <div
              className="flex flex-row justify-start items-end mt-8 gap-1 text-left text-xl"
              style={{ lineHeight: "34px" }}
            >
              <p className="font-normal text-gray8">Ingin tahu lebih lanjut?</p>
              <a
                href="https://wa.me/6282113371572"
                target="_blank"
                rel="noreferrer"
                className="font-bold text-primaryColor"
              >
                Hubungi Kami
              </a>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full justify-start items-center gap-6 mt-8 tablet:mt-0">
          {admisFAQ.map((item, index) => (
            <div key={index} className="flex w-full">
              <AdmisFAQ question={item.question} answer={item.answer} />
            </div>
          ))}
        </div>
        {screenWidth < 1024 && (
          <div
            className="flex flex-row justify-start items-end mt-8 gap-1 text-left text-base"
            style={{ lineHeight: "24px" }}
          >
            <p className="font-normal text-gray8">Ingin tahu lebih lanjut?</p>
            <Link className="font-bold text-primaryColor">Hubungi Kami</Link>
          </div>
        )}
      </div>
    </LayoutV2>
  );
}
