import { PlusIcon } from "@heroicons/react/solid";

export default function ButtonV2({
  title,
  onClick,
  type,
  secondary,
  disabled,
  customStyle,
  customTextStyle,
  prefixIcon,
  suffixIcon,
}) {
  return (
    <button
      onClick={onClick}
      type={type ? type : "button"}
      disabled={disabled}
      className={`w-full py-3 rounded-lg flex justify-center items-center gap-2 relative ${
        disabled
          ? "bg-gray-300"
          : secondary
          ? "bg-white border border-primaryColor"
          : "bg-primaryColor"
      }`}
      style={customStyle}
    >
      {prefixIcon}
      <p
        className={`text-center font-bold text-sm ${
          disabled
            ? "text-gray-500"
            : secondary
            ? "text-primaryColor"
            : "text-white"
        }`}
        style={customTextStyle}
      >
        {title}
      </p>
      {suffixIcon}
    </button>
  );
}
