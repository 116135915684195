import React, { lazy, Suspense, useEffect, useState } from "react";
import Headerv2 from "../components/Header_v2";
import HomeCardExplore from "../components/HomeCardExplore";
import { Link } from "react-router-dom";
import AboutUsSection from "../components/AboutUsSection";
import Footer from "../components/Footer";
import TestMinatSection from "../components/TestMinatSection";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import generatedGitInfo from "../generatedGitInfo.json";
const PartnerUniversitiesSection = lazy(() =>
  import("../components/Universities")
);
const HomeMajorSection = lazy(() => import("../components/HomeMajorSection"));
const HomeUnivSection = lazy(() => import("../components/HomeUnivSection"));
const TestimoniesBlocks = lazy(() => import("../components/Testimonies"));
const HomeClassSection = lazy(() => import("../components/HomeClassSection"));

function HomeV2() {
  const auth = useRecoilValue(authState);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  return (
    <div className="bg-white font-plusJakartaSans flex flex-col overflow-hidden w-full">
      <Headerv2 />
      <p className="hidden">
        {`branch: ${generatedGitInfo.gitBranch}`}
        <br />
        {`hash: ${generatedGitInfo.gitCommitHash}`}
        <br />
        {`date: ${generatedGitInfo.gitCommitDate}`}
        <br />
        {`author: ${generatedGitInfo.gitCommitAuthor}`}
        <br />
        {`message: ${generatedGitInfo.gitCommitMessage}`}
      </p>
      <div className="flex flex-col items-center justify-center bg-backgroundGray4 mac:h-auto tablet:pb-32 mac:pb-50">
        <div className="flex flex-col w-full tablet:items-center">
          <div className="text-center text-black1 text-3xl font-bold leading-tight mt-10 tablet:text-4xl tablet:w-2/5 mac:text-60px">
            Siapkan diri meraih kampus impian
          </div>
          <div className="text-center text-black1 text-base mt-6 px-10 tablet:text-sm tablet:w-1/3 mac:text-xl mac:px-14">
            Temukan jurusan dan kampus idaman kini lebih mudah dengan{" "}
            <span className="font-bold">exploration!</span>
          </div>
          {!auth.isAuth ? (
            <Link to="/signup" className="px-4">
              <div className="relative z-10 w-full h-auto py-3 bg-primaryColor mt-12 tablet:mt-8 mac:mt-12 text-white text-center font-semibold rounded-lg tablet:px-6 tablet:text-xs mac:text-base">
                Daftar Sekarang
              </div>
            </Link>
          ) : (
            <div className="tablet:h-18 hd:h-16 mac:h-20 fhd:h-28"></div>
          )}
        </div>
        {screenWidth >= 1024 ? (
          <div className="flex flex-row items-start justify-center tablet:bottom-32 hd:bottom-24 mac:bottom-28 fhd:bottom-68 absolute w-full px-9">
            <div className="tablet:flex tablet:w-1/2 tablet:justify-start mac:flex mac:w-1/2 mac:justify-start">
              <img
                src="/home_img1.svg"
                className="object-top object-cover w-full h-54 tablet:w-64 tablet:h-auto mac:w-80 fhd:w-90"
                alt="home img 1"
              />
            </div>
            <div className="tablet:flex tablet:w-1/2 tablet:justify-end mac:flex mac:w-1/2 mac:justify-end">
              <img
                src="/home_img1_2.svg"
                className="object-top object-cover w-full h-54 tablet:w-64 tablet:h-auto tablet:pt-8 mac:w-84 fhd:w-94"
                alt="home img 1"
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full mt-12 px-8">
            <img
              src="/home_img1.svg"
              className="h-54 w-full object-cover object-top"
              alt="home img 1"
            />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center w-full bg-white px-4 py-16 tablet:px-16 tablet:absolute tablet:bottom-0 tablet:bg-transparent hd:-bottom-10 mac:px-20 fhd:bottom-32">
        <div className="flex flex-col gap-6 tablet:flex-row tablet:justify-evenly">
          {screenWidth < 1024 && (
            <HomeCardExplore
              bgColor="bg-redTM"
              logoBGColor=""
              logo="/home-logo-tmb.svg"
              destination="/tes-minat"
              title="Pastiin minat kamu"
              description={`Pastiin minat yang kamu banget bersama Serra, konsultan minat virtual kami.`}
            />
          )}
          <HomeCardExplore
            bgColor="bg-white"
            logoBGColor="rgba(67, 188, 205, 0.2)"
            logo="/card_explore_icon1.png"
            destination="/explore-jurusan"
            title="Explore Jurusan"
            description="Cari tau mata kuliah, prospek karir dan kampus rekomendasi jurusan pilihanmu"
          />
          <HomeCardExplore
            bgColor="bg-white"
            logoBGColor="rgba(102, 46, 155, 0.2)"
            logo="/card_explore_icon2.png"
            destination="/explore-kampus"
            title="Explore Kampus"
            description="Lihat informasi berbagai kampus dan temukan kampus pilihanmu sekarang"
          />
          <HomeCardExplore
            bgColor="bg-white"
            logoBGColor="rgba(249, 198, 11, 0.2)"
            logo="/card_explore_icon3.png"
            destination="/explore-kelas"
            title="Explore Kelas"
            description="Ikuti kelas untuk merasakan serunya jadi mahasiswa"
          />
          {screenWidth >= 1024 && (
            <HomeCardExplore
              bgColor="bg-redTM"
              logoBGColor=""
              logo="/home-logo-tmb.svg"
              destination="/tes-minat"
              title="Pastiin minat kamu"
              description={`Pastiin minat yang kamu banget bersama Serra, konsultan minat virtual kami.`}
            />
          )}
        </div>
      </div>
      {screenWidth >= 1024 ? (
        <div className="h-28 mac:h-44 w-full bg-white"></div>
      ) : (
        <div></div>
      )}
      <AboutUsSection screenWidth={screenWidth} />
      <Suspense fallback={<div className="animate-pulse"></div>}>
        <PartnerUniversitiesSection />
      </Suspense>
      <TestMinatSection screenWidth={screenWidth} />
      <Suspense fallback={<div className="animate-pulse"></div>}>
        <HomeMajorSection screenWidth={screenWidth} />
      </Suspense>
      <Suspense fallback={<div className="animate-pulse"></div>}>
        <HomeUnivSection screenWidth={screenWidth} />
      </Suspense>
      <Suspense fallback={<div className="animate-pulse"></div>}>
        <TestimoniesBlocks screenWidth={screenWidth} />
      </Suspense>
      <Suspense fallback={<div className="animate-pulse"></div>}>
        <HomeClassSection screenWidth={screenWidth} />
      </Suspense>
      {!auth.isAuth ? (
        <div className="px-4 pt-32 tablet:flex tablet:flex-col tablet:items-center tablet:justify-center tablet:relative tablet:w-full tablet:pt-18 mac:pt-26">
          <p className="text-center text-3xl text-black1 font-bold mac:text-60px tablet:tracking-wide hd:tracking-normal mac:leading-snug">
            Tunggu apa lagi?
          </p>
          <p className="text-center text-3xl text-black1 font-bold mac:text-60px tablet:tracking-wide hd:tracking-normal mac:leading-none">
            Raih masa depanmu sekarang!
          </p>
          <Link to="/signup">
            <div className="w-full h-auto py-3 bg-primaryColor my-12 text-center text-base text-white font-semibold rounded-lg tablet:px-6 mac:my-0 mac:mt-12 mac:mb-26">
              Daftar Sekarang
            </div>
          </Link>
          <div className="flex flex-row items-end justify-between w-full tablet:absolute tablet:bottom-0 tablet:pl-8 tablet:pr-8 mac:pl-15 mac:pr-7">
            <img
              src="/hand_illu_left.svg"
              className="tablet:absolute tablet:left-14 tablet:w-26 mac:left-15 mac:w-36"
              alt="hand illu left"
            />
            {screenWidth >= 1024 ? (
              <img
                src="/hand_illu_mid0.svg"
                className="tablet:absolute tablet:left-36 tablet:w-24 mac:left-40 mac:w-32"
                alt="hand illu mid0"
              />
            ) : (
              <div></div>
            )}
            {screenWidth >= 1024 ? (
              <div className="flex w-full"></div>
            ) : (
              <div></div>
            )}
            <img
              src="/hand_illu_mid.svg"
              className="tablet:absolute tablet:right-40 tablet:w-30 mac:right-42 mac:w-42"
              alt="hand illu mid"
            />
            <img
              src="/hand_illu_right.svg"
              className="tablet:absolute tablet:right-10 tablet:w-28 mac:right-7 mac:w-38"
              alt="hand illu right"
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <Footer />
    </div>
  );
}

export default HomeV2;
