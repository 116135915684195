import { ArrowRightIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

export default function TestMinatSection({ screenWidth }) {
  return (
    <div className="px-4 mt-9 tablet:mt-0 mb-16">
      <div className="relative flex flex-col tablet:flex-row rounded-20px bg-redTM">
        {/* <div className="absolute -top-26 -right-18">
          <img src="/tmbsection-curve-desktop.svg" className="object-contain" />
        </div> */}
        <div className="flex flex-col px-4 tablet:pl-26 tablet:pt-24 tablet:flex-1 z-10">
          <p className="mt-11 tablet:mt-0 text-left text-2xl tablet:text-48px text-white font-bold">
            #PastiinBarengSerra
          </p>
          <p
            className="mt-8 text-left text-base tablet:text-xl font-normal tablet:pr-20"
            style={{ color: "rgba(255, 255, 255, 0.88)" }}
          >
            Hi namaku Serra, konsultan minat virtual dari Exploration yang siap
            bantuin kamu mastiin minat dan jurusan yang tepat untuk masa
            depanmu.
          </p>
          {screenWidth <= 1024 && (
            <Link
              to="/tes-minat"
              className="mt-10 mb-9 w-full tablet:w-auto"
            >
              <div className="flex flex-row items-center justify-center gap-2 bg-white py-3.5 rounded-lg tablet:mx-0 tablet:flex-none tablet:px-6 mac:mx-0 mac:flex-none mac:px-6">
                <div className="text-base text-primaryColor font-semibold">
                  Pastiin sekarang
                </div>
                <ArrowRightIcon className="w-5 h-5 text-primaryColor" />
              </div>
            </Link>
          )}
        </div>
        <div
          className={`${
            screenWidth >= 1024
              ? "flex flex-1 h-428px mt-7 justify-end z-10"
              : "z-10"
          }`}
        >
          <img
            alt="illu"
            src={`${screenWidth >= 1024 ? "/espa-1.svg" : "/espa-1-2.svg"}`}
          />
        </div>
      </div>
    </div>
  );
}
