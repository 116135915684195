import { useState } from "react";

export default function ButtonJalurPenerimaan({ item, screenWidth, slider }) {
  const [isHovered, setIsHovered] = useState(false);

  return slider ? (
    <button
      className="pb-2 w-full"
      onClick={() => {
        if (item.web_url) {
          window.open(item.web_url, "_blank", "noopener,noreferrer");
        }
      }}
      disabled={!item.web_url}
      style={{
        cursor: item.web_url ? "pointer" : "default",
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <div
        className={`flex flex-row justify-start items-center ${
          isHovered ? "bg-primaryColor" : "bg-white"
        }`}
        style={{
          boxShadow: "0px 3.27px 3.27px 0px rgba(0, 0, 0, 0.25)",
          minHeight: isHovered ? "93px" : "84px",
          maxHeight: isHovered ? "93px" : "84px",
          width: "100%",
          // width: screenWidth < 1024 ? "306px" : isHovered ? "380px" : "368px",
          // minWidth:
          //   screenWidth < 1024 ? "306px" : isHovered ? "380px" : "368px",
          // maxWidth:
          //   screenWidth < 1024 ? "306px" : isHovered ? "380px" : "368px",
          gap: "29px",
          borderRadius: "9.82px",
          padding: "30px",
        }}
      >
        <img
          src={
            isHovered
              ? "/icon_kampus_jalur_white.svg"
              : "/icon_kampus_jalur.svg"
          }
          style={{
            width: "32.75px",
            height: "32.75px",
          }}
        />
        <p
          className={`text-left font-medium text-xl ${
            isHovered ? "text-white" : "text-black"
          } line-clamp-1 overflow-ellipsis`}
          style={{ lineHeight: "30px" }}
        >
          {item.title}
        </p>
      </div>
    </button>
  ) : (
    <button
      className={`flex flex-row justify-center items-center ${
        isHovered ? "bg-primaryColor" : "bg-white"
      } mr-4`}
      onClick={() => {
        if (item.web_url) {
          window.open(item.web_url, "_blank", "noopener,noreferrer");
        }
      }}
      disabled={!item.web_url}
      style={{
        boxShadow: "0px 3.27px 3.27px 0px rgba(0, 0, 0, 0.25)",
        minHeight: isHovered ? "93px" : "84px",
        maxHeight: isHovered ? "93px" : "84px",
        minWidth:
          screenWidth < 1024 ? "306px" : isHovered ? "380px" : "362.12px",
        maxWidth:
          screenWidth < 1024 ? "306px" : isHovered ? "380px" : "362.12px",
        gap: "14px",
        borderRadius: "9.82px",
        cursor: item.web_url ? "pointer" : "default",
      }}
      //   onMouseEnter={() => {
      //     setIsHovered(true);
      //   }}
      //   onMouseLeave={() => {
      //     setIsHovered(false);
      //   }}
    >
      <img
        src={
          isHovered ? "/icon_kampus_jalur_white.svg" : "/icon_kampus_jalur.svg"
        }
      />
      <p
        className={`text-center font-medium text-xl ${
          isHovered ? "text-white" : "text-black"
        } line-clamp-1 overflow-ellipsis`}
        style={{ lineHeight: "30px", width: "203.86px" }}
      >
        {item.title}
      </p>
    </button>
  );
}
