import {
  CalendarIcon,
  ClockIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

export default function HomeCardClass({
  screenWidth,
  data,
  univLogo,
  univName,
}) {
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );

  return (
    <div
      className="flex flex-col bg-white rounded-lg shadow-custom1 w-75 p-8 tablet:p-5 mac:p-8 tablet:w-full"
      style={{ maxWidth: "620px", outline: "none" }}
    >
      <p
        className={`h-22 text-left text-xl text-black1 font-bold ${
          screenWidth >= 1024 ? "line-clamp-2" : "line-clamp-3"
        } tablet:h-15 mac:text-2xl`}
      >
        {data.topic}
      </p>
      <div className="flex flex-row items-center justify-start mt-4 h-12">
        <div className="h-10 w-10">
          <img
            className="h-10 w-10 object-contain"
            src={
              univLogo
                ? univLogo
                : data.uni_id === "EXPL0000"
                ? data.organizer_logo === ""
                  ? data.logo_url
                  : data.organizer_logo
                : data.logo_url
            }
            alt={"logo"}
          />
        </div>
        <div className="flex items-center h-full text-left text-xl text-black1 font-normal line-clamp-2 ml-4">
          {univName
            ? univName
            : data.uni_id === "EXPL0000"
            ? data.organizer_name === ""
              ? data.university_name
              : data.organizer_name
            : data.university_name}
        </div>
      </div>
      <div className="h-0.5 bg-gray-200 my-4 tablet:my-3 mac:my-4"></div>
      <div className="flex flex-col items-start tablet:flex-row tablet:items-center tablet:justify-between">
        <div className="flex flex-row items-center justify-start">
          <CalendarIcon className="h-4 w-4 tablet:h-3 tablet:w-3 mac:h-4 mac:w-4" />
          <div className="text-left text-sm tablet:text-xss mac:text-sm text-black1 font-normal ml-2">
            {moment(data.started_at).tz("Asia/Jakarta").format("DD MMMM YYYY")}
          </div>
        </div>
        <div className="flex flex-row items-center justify-start my-4">
          <ClockIcon className="h-4 w-4 tablet:h-3 tablet:w-3 mac:h-4 mac:w-4" />
          <div className="text-left text-sm tablet:text-xss mac:text-sm text-black1 font-normal ml-2">
            {`${moment(data.started_at)
              .tz("Asia/Jakarta")
              .format("HH.mm")} - ${moment(data.ended_at)
              .tz("Asia/Jakarta")
              .format("HH.mm")} WIB`}
          </div>
        </div>
        <div className="flex flex-row items-center justify-start">
          <LocationMarkerIcon className="h-4 w-4 tablet:h-3 tablet:w-3 mac:h-4 mac:w-4" />
          <div className="text-left text-sm tablet:text-xss mac:text-sm text-black1 font-normal ml-2">
            {data.is_online ? "Online" : "Offline"}
          </div>
        </div>
        <Link
          to={`/explore-kelas/detail/${data.id}`}
          className="flex w-full mt-7 items-center justify-center bg-primaryColor rounded-lg py-3 tablet:py-2 mac:py-3 tablet:w-auto tablet:mt-0 tablet:px-4 mac:px-6"
        >
          <div className="text-base tablet:text-xs mac:text-base text-white font-semibold">
            Daftar
          </div>
        </Link>
      </div>
    </div>
  );
}
