import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  return (
    <div className="border-t border-gray-200 w-full py-3 px-4 flex flex-col gap-6 tablet:flex-row tablet:gap-0 tablet:justify-between tablet:px-14 mac:px-20 bg-white relative z-50">
      <div className="flex flex-row items-center">
        <Link to="/" aria-label="Cruip">
          <img
            alt="exploration"
            src="/logo_v2.svg"
            className="flex items-center justify-starth-10 w-auto"
          />
        </Link>
      </div>
      <div className="flex flex-row items-center">
        <div
          className={`flex ${
            screenWidth < 1024 && "flex-1"
          } flex-row text-left text-black1 font-semibold pl-0 hd:pl-6 tablet:mr-10`}
        >
          <Link to="/faq" aria-label="faq">
            FAQ
          </Link>
          <span className="mx-2">|</span>
          <Link to="/terms-condition" aria-label="tnc">
            TnC
          </Link>
          <span className="mx-2">|</span>
          <Link to="/privacy" aria-label="privacy">
            Privasi
          </Link>
        </div>
        <div className="flex flex-1 flex-row items-center justify-end gap-2">
          <a
            href="https://wa.me/6282118887806"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center h-12 w-12 p-3 bg-primaryColor rounded-lg"
          >
            <img
              src="/logo-whatsapp.svg"
              className="h-full w-full object-contain"
              alt="line"
            />
          </a>
          <a
            href="https://www.instagram.com/exploration.id"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center h-12 w-12 p-3 bg-primaryColor rounded-lg"
          >
            <img
              src={`/icon_ig.${screenWidth < 1024 ? "png" : "svg"}`}
              className="h-full w-full object-contain"
              alt="instagram"
            />
          </a>
          <a
            href="https://www.tiktok.com/@exploration.id?_t=8kquUUla3MO&_r=1"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center h-12 w-12 p-3 bg-primaryColor rounded-lg"
          >
            <img
              src="/icon_tiktok.svg"
              className="h-full w-full object-contain"
              alt="tiktok"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
