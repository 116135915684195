import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Layout from "../components/Layout";
import { authState } from "../atoms/authState";
import { Fragment, useEffect, useRef, useState, Component } from "react";
import { api, apiAnom, notifError } from "../utils/api";
import moment from "moment-timezone";
import PageLoading from "./PageLoading";
import { signInUpState } from "../atoms/signInUpState";
import Cookies from "js-cookie";
import SpeakerCard from "../components/SpeakerCard";
import ClassFacilities from "../components/ClassFacilities";
import HomeCardClassV2 from "../components/HomeCardClassV2";
import { mapHasKeyAndValueNotEmpty } from "../utils/utils";
import Button from "../components/base/Button";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import Slider from "react-slick";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  LocationMarkerIcon,
  StarIcon,
} from "@heroicons/react/solid";
import { paramCase } from "param-case";
import Headerv2 from "../components/Header_v2";
import LayoutV2 from "../components/Layout_V2";

const today = new Date();
today.setHours(0, 0, 0, 0);

export default function ClassroomDetailSlugV2() {
  let { slug } = useParams();
  const auth = useRecoilValue(authState);
  const apiState = auth.isAuth ? api : apiAnom;
  const setAuth = useSetRecoilState(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [classData, setClassData] = useState();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [cardDate, setCardDate] = useState();
  const [isFull, setIsFull] = useState();
  const [isClose, setIsClose] = useState();
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [accessCode, setAccessCode] = useState("");
  const [errors, setErrors] = useState({});
  const speakerScroll = useRef();
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
  const [currentSpeaker, setCurrentSpeaker] = useState(0);
  const uniScroll = useRef();
  const [uniScrollX, setUniScrollX] = useState(0); // For detecting start scroll postion
  const [uniScrolEnd, setUniScrolEnd] = useState(false); // For detecting end of scrolling
  const [currentUni, setCurrentUni] = useState(0);
  const [classRecommendation, setclassRecommendation] = useState([]);
  const classRecRef = useRef();
  const speakersRef = useRef();
  const [classRecCurrentPage, setClassRecCurrentPage] = useState(1);
  const [fullDescription, setFullDescription] = useState(false);
  const unisRef = useRef();
  const [isSticky, setIsSticky] = useState(false);
  const [stopSticky, setStopSticky] = useState(false);
  const thresholdRef = useRef(null);
  const stopStickyRef = useRef(null);
  const cardRef = useRef(null);
  const [reloadPage, setReloadPage] = useState(false);
  const [isUserSubbed, setIsUserSubbed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const thresholdElement = thresholdRef.current;
      const stopStickyElement = stopStickyRef.current;
      const cardElement = cardRef.current;

      const thresholdPosition =
        thresholdElement.getBoundingClientRect().top + window.scrollY;
      const stopStickyPosition =
        stopStickyElement.getBoundingClientRect().top + window.scrollY;
      const cardPosition = cardElement.getBoundingClientRect().top;

      setIsSticky(
        window.scrollY >= thresholdPosition &&
          window.scrollY < stopStickyPosition &&
          !stopSticky
      );
    };

    if (screenWidth >= 1024) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stopSticky]);

  const handleStopSticky = () => {
    setStopSticky(true);
  };

  const unisSliderSettings = {
    arrows: false,
    // className: "center",
    // centerMode: true,
    // centerPadding: "0px",
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: false,
    dots: false,
  };

  const slide = (shift) => {
    speakerScroll.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(
        speakerScroll.current.scrollWidth - speakerScroll.current.scrollLeft
      ) <= speakerScroll.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //This will check scroll event and checks for scroll end
  const scrollCheck = () => {
    setscrollX(speakerScroll.current.scrollLeft);
    if (
      Math.floor(
        speakerScroll.current.scrollWidth - speakerScroll.current.scrollLeft
      ) <= speakerScroll.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    //Check width of the scollings
    if (
      speakerScroll.current &&
      speakerScroll?.current?.scrollWidth ===
        speakerScroll?.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [
    speakerScroll?.current?.scrollWidth,
    speakerScroll?.current?.offsetWidth,
  ]);

  const slideUni = (shift) => {
    uniScroll.current.scrollLeft += shift;
    setUniScrollX(uniScrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(
        uniScroll.current.scrollWidth - uniScroll.current.scrollLeft
      ) <= uniScroll.current.offsetWidth
    ) {
      setUniScrolEnd(true);
    } else {
      setUniScrolEnd(false);
    }
  };

  //This will check scroll event and checks for scroll end
  const uniScrollCheck = () => {
    setUniScrollX(uniScroll.current.scrollLeft);
    if (
      Math.floor(
        uniScroll.current.scrollWidth - uniScroll.current.scrollLeft
      ) <= uniScroll.current.offsetWidth
    ) {
      setUniScrolEnd(true);
    } else {
      setUniScrolEnd(false);
    }
  };

  useEffect(() => {
    //Check width of the scollings
    if (
      uniScroll.current &&
      uniScroll?.current?.scrollWidth === uniScroll?.current?.offsetWidth
    ) {
      setUniScrolEnd(true);
    } else {
      setUniScrolEnd(false);
    }
    return () => {};
  }, [uniScroll?.current?.scrollWidth, uniScroll?.current?.offsetWidth]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  useEffect(() => {
    setIsLoading(true);
    if (auth.isAuth) {
      api
        .get("/me")
        .then((res) => {
          setUserData(res.data.data);

          if (res.data.data.verified_at.split("-")[0] === "0001") {
            navigate("/profile-edit");
            Cookies.remove("confirmClass");
          }
        })
        .catch(notifError);
    }

    apiState
      .get(`/explore-kelas/${slug}`)
      .then((res) => {
        setClassData(res.data.data);
        setIsUserSubbed(res.data.data.subscribed);
        setCardDate(new Date(res.data.data.info.started_at));
        setIsFull(
          res.data.data.info.subscribers >= res.data.data.info.capacity
        );
        setIsClose(
          today.getTime() >= new Date(res.data.data.info.started_at).getTime()
        );
        let isConfirmClass = Cookies.get("confirmClass") ?? false;

        apiState
          .get(`/classes/recommendation/${res.data.data.info.id}`)
          .then((res) => {
            setclassRecommendation(res.data.data.recommendation);
          })
          .catch(notifError);

        if (isConfirmClass && !res.data.data.subscribed) {
          api
            .post(`/students/subscribe/${res.data.data.info.id}`)
            .then((_) => {
              setIsUserSubbed(true);
              setVideoModalOpen(true);
              Cookies.remove("confirmClass");
            })
            .catch((err) => {
              notifError(
                err,
                "Kamu telah terdaftar di kelas ini. Yuk cek kelas lainnya!",
                "warning"
              );
              Cookies.remove("confirmClass");
            });
        } else {
          Cookies.remove("confirmClass");
        }

        document.title = `Explore Kelas - ${res.data.data.info.topic?.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ""
        )}`;
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          window.Swal.fire({
            icon: "warning",
            title: "Kelas tidak ditemukan",
            text: "Silakan klik tombol di bawah untuk cek kelas lainnya",
            confirmButtonText: "Lihat kelas lainnya",
            allowOutsideClick: false,
          }).then(() => {
            navigate("/explore-kelas");
          });
        }
      });
  }, [window.location.pathname, reloadPage]);

  // const [days, setDays] = useState("00");
  // const [hours, setHours] = useState("00");
  // const [minutes, setMinutes] = useState("00");
  // const [seconds, setSeconds] = useState("00");

  // let interval = useRef();

  // const startTimer = () => {
  //   const startDate = new Date(classData.info.started_at).getTime();

  //   interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const distance = startDate - now;

  //     const remainingDays = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     const remainingHours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     const remainingMinutes = Math.floor(
  //       (distance % (1000 * 60 * 60)) / (1000 * 60)
  //     );
  //     const remainingSeconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     if (distance < 0) {
  //       clearInterval(interval.current);
  //     } else {
  //       setDays(remainingDays);
  //       setHours(remainingHours);
  //       setMinutes(remainingMinutes);
  //       setSeconds(remainingSeconds);
  //     }
  //   }, 1000);
  // };

  // useEffect(() => {
  //   startTimer();
  //   return () => {
  //     clearInterval(interval.current);
  //   };
  // });

  const onFormSubmit = (e) => {
    if (userData.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    } else {
      e.preventDefault();
      e.stopPropagation();
      if (userData.fname.length < 1) {
        window.Swal.fire({
          title: "Lengkapi profil!",
          text: "Silahkan lengkapi profil anda terlebih dahulu!",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/profile-edit");
        });
      } else {
        setIsLoading(true);
        api
          .post(`/students/subscribe/${classData.info.id}`, {})
          .then((_) => {
            setReloadPage(!reloadPage);
            setVideoModalOpen(true);
          })
          .catch((err) => {
            notifError(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const onButtonReturnedClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/explore-kelas");
  };

  const exClassSteps = [
    {
      image: "/illu_ex_class_1.svg",
      title: "Bergabung menjadi Explorer",
      description:
        "Daftarkan dirimu sekarang juga dan jadilah salah satu dari para Explorer peraih masa depan!",
    },
    {
      image: "/illu_ex_class_2.svg",
      title: "Dapatkan kode akses",
      description:
        "Dapatkan kode akses khusus dari tim exploration untuk mengikuti kelas ekslusif",
    },
    {
      image: "/illu_ex_class_3.svg",
      title: "Akses Kelasnya",
      description:
        "Masukkan kode akses yang telah kamu dapatkan dan selamat, kini kamu bisa mengikuti kelas yang Eksklusif!",
    },
  ];

  const ExclusiveClassStep = ({ image, title, description }) => {
    return (
      <div className="flex flex-col items-center justify-start w-full gap-4">
        <img src={image} />
        <p
          className="text-center font-bold text-xl text-white"
          style={{ lineHeight: "22px" }}
        >
          {title}
        </p>
        <p
          className="text-center font-normal text-base text-white"
          style={{ lineHeight: "25px" }}
        >
          {description}
        </p>
      </div>
    );
  };

  if (isLoading || !classData) {
    return <PageLoading />;
  }

  let baseOccupancy = classData.info.subscribers / classData.info.capacity;
  let occupancy = baseOccupancy;

  if (baseOccupancy > 0 && baseOccupancy < 0.6) {
    occupancy = baseOccupancy + 0.05;
  }
  const occupancyStatus = (occupancy) => {
    if (occupancy === 0) {
      return "Tersedia";
    }
    if (occupancy > 0 && occupancy <= 0.3) {
      return "Tersedia";
    }
    if (occupancy > 0.3 && occupancy <= 0.6) {
      return "Lagi diminati";
    }
    if (occupancy > 0.6 && occupancy < 1) {
      return "Segera penuh 🔥";
    }
    if (occupancy === 1) {
      return "Penuh 🔥";
    }
  };

  const charType = (char) => {
    switch (char) {
      case "R":
        return "The Doers";
      case "I":
        return "The Thinkers";
      case "A":
        return "The Creators";
      case "S":
        return "The Helpers";
      case "E":
        return "The Persuaders";
      case "C":
        return "The Organizer";
      default:
        return "Unique";
    }
  };

  const classRecSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const speakerSettings = {
    arrows: false,
    centerMode: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    dots: false,
    swipe: false,
  };

  const classTags = [
    {
      id: 1,
      label: "Administrasi Bisnis",
    },
    {
      id: 2,
      label: "Administrasi Pendidikan",
    },
    {
      id: 3,
      label: "Agribisnis",
    },
  ];
  // const classTags = ["Business administration", "Bisnis", "Marketing"];

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0" showModal={true}>
      <div ref={thresholdRef} className="flex w-full" />
      <div className="flex flex-col tablet:flex-row items-center justify-between w-full px-4 py-6 tablet:p-20 bg-red bg-gradient-to-b tablet:bg-gradient-to-r from-white to-E7">
        <div className="flex flex-col items-start justify-center w-full tablet:w-3/5">
          {/* <p
            className="text-left font-bold text-base text-tosca2"
            style={{ lineHeight: "24px", letterSpacing: "2px" }}
          >
            KELAS GRATIS
          </p> */}
          {/* <div className="relative">
            <p
              className="text-left font-bold text-base text-tosca2"
              style={{ lineHeight: "24px", letterSpacing: "2px" }}
            >
              KELAS EKSKLUSIF
            </p>
            <img
              src="/star-4point.svg"
              className="absolute -top-5 -left-3 w-7 h-7"
            />
            <img
              src="/star-4point.svg"
              className="absolute -bottom-4 -right-6 w-7 h-7"
            />
          </div> */}
          <p
            className="text-left font-bold text-3xl tablet:text-48px text-black1 mt-4 tracking-0.8px"
            style={{
              lineHeight: `${screenWidth < 1024 ? "38px" : "58px"}`,
            }}
          >
            {classData.info.topic}
          </p>
          {classData.info.tags.length > 0 && (
            <div className="flex flex-wrap gap-2 w-full overflow-hidden mt-8 tablet:mt-12">
              {classData.info.tags.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    Cookies.set("tagKelas", item.label, {
                      sameSite: "None",
                      secure: true,
                      expires: 1,
                    });
                    navigate(`/explore-kelas?tag=${item.id}`);
                  }}
                  className="py-2 px-3 rounded-lg text-center font-medium text-xs tablet:text-base text-black1"
                  style={{
                    backgroundColor: "rgba(255, 207, 45, 1)",
                    lineHeight: "18px",
                  }}
                >{`#${item.label}`}</button>
              ))}
            </div>
          )}
          <p
            className={`font-normal text-base tablet:text-xl text-black1 mt-8 tablet:mt-12 ${
              screenWidth < 1024 &&
              !fullDescription &&
              "line-clamp-2 overflow-ellipsis"
            }`}
            style={{ textAlign: "justify" }}
          >
            {classData.info.description}
          </p>
          {screenWidth < 1024 && !fullDescription && (
            <button
              onClick={() => {
                setFullDescription(true);
              }}
              className="text-left font-bold"
              style={{ color: "rgba(0, 86, 210, 0.88)" }}
            >
              lihat selengkapnya
            </button>
          )}
          {classData.subscribed && (
            <div
              className="flex flex-row items-start w-full tablet:w-max py-4 px-6 tablet:p-8 bg-white mt-8"
              style={{
                borderRadius: "20px",
                boxShadow: "24px 24px 200px rgba(23, 23, 23, 0.08)",
                gap: "22px",
              }}
            >
              <img src="/char-espa-03.svg" />
              <p
                className="font-normal text-xs text-black1"
                style={{ textAlign: "justify", lineHeight: "18.6px" }}
              >
                Selamat Explorers, kamu sudah terdaftar pada kelas ini! Pastiin
                untuk tandai kalendermu agar tidak lupa ya!
              </p>
            </div>
          )}
          {/* <p
            className="text-left font-normal text-2xl tablet:text-3xl text-black1 mt-3 tablet:mt-4"
            style={{
              lineHeight: `${screenWidth < 1024 ? "29px" : "38px"}`,
              letterSpacing: "-0.8px",
            }}
          >
            {classData.info.program_name}
          </p> */}
          {/* <form onSubmit={onFormSubmit}>
            {!auth.isAuth ? (
              <Button
                onClick={() => {
                  setSign({ trySignIn: false, trySignUp: true });
                }}
                disabled={
                  isFull || isClose || isLoading || classData.subscribed
                }
                className="rounded-lg bg-primaryColor py-3 px-6 mt-8 text-center font-semibold text-sm text-white"
                type="button"
              >
                Masuk
              </Button>
            ) : (
              <Button
                className="rounded-lg bg-primaryColor py-3 px-6 mt-8 text-center font-semibold text-sm text-white"
                disabled={
                  isFull || isClose || isLoading || classData.subscribed
                }
                type="submit"
                variant="primary"
              >
                {isClose ? "Telah Usai" : "Ikuti Kelas"}
              </Button>
            )}
            <div className="flex items-center justify-center mt-2 gap-4">
              {isFull && !classData.subscribed && (
                <div className="text-xss italic text-rose-500">
                  * Kuota kelas telah terpenuhi
                </div>
              )}
              {!isFull && !isClose && !classData.subscribed && (
                <div className="text-xss italic text-gray-400">
                  * Daftar sebelum H-1 kelas dimulai
                </div>
              )}
              {isClose && !classData.subscribed && (
                <div className="text-xss italic text-rose-500">
                  * Pendaftaran telah ditutup
                </div>
              )}
              {classData.subscribed && (
                <div className="text-xss italic text-gray-500">
                  Kamu telah terdaftar dalam kelas ini!
                </div>
              )}
            </div>
          </form> */}
          {/* <button
            disabled
            className="rounded-lg bg-primaryColor py-3 px-6 mt-8 text-center font-semibold text-sm text-white"
          >
            Ikuti Kelas
          </button> */}
          {/* <div className="flex flex-col">
            <div
              className="flex flex-row items-center p-1 rounded-lg mt-8"
              style={{ border: "1px solid rgba(36, 16, 54, 0.3)" }}
            >
              <input
                id="accessCode"
                type="text"
                name="accessCode"
                className={`w-full text-gray-800 ${
                  mapHasKeyAndValueNotEmpty(errors, "accessCode")
                    ? "border-red-400"
                    : "border-0"
                }`}
                placeholder="Masukkan Kode Akses*"
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                disabled={false}
                required
                onInvalid={(F) =>
                  F.target.setCustomValidity("Kode Akses harus diisi")
                }
                onInput={(F) => F.target.setCustomValidity("")}
              />
              <button className="rounded-lg bg-primaryColor py-3 px-6 text-center font-semibold text-sm text-white w-3/5">
                Ikuti Kelas
              </button>
            </div>
            <p className="text-left font-normal text-xs text-black1 mt-2">
              <span style={{ opacity: "0.88" }}>
                *Pelajari bagaimana cara mendapat kode akses{" "}
              </span>
              <span className="font-semibold text-primaryColor">
                <Link>di sini</Link>
              </span>
            </p>
          </div> */}
        </div>
        {screenWidth < 1024 && (
          <div className="flex flex-col items-start justify-start bg-white w-full rounded-xl p-8 mt-10 tablet:mt-0">
            {/* <p
              className="text-left font-bold text-2xl tablet:text-3xl text-black1 tracking-0.8px"
              style={{ lineHeight: `${screenWidth < 1024 ? "26px" : "35px"}` }}
            >
              Detail Kelas
            </p> */}
            {/* save it for later use */}
            {/* {days < 7 && (
            <p>{`${days < 10 ? "0" : ""}${days}:${
              hours < 10 ? "0" : ""
            }${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
              seconds < 10 ? "0" : ""
            }${seconds}`}</p>
          )} */}
            {classData.extras.universities.length > 0 && (
              <div className="w-full relative">
                <Slider ref={unisRef} {...unisSliderSettings}>
                  {classData.extras.universities.map((item, index) => (
                    <div key={index} className="w-full">
                      <button
                        onClick={() => {
                          navigate(`/explore-kampus/${item.slug}`);
                        }}
                        className="flex flex-row items-center justify-start gap-4 w-full overflow-hidden"
                      >
                        <img
                          src={item.logo_url}
                          className="w-14 h-14 object-contain"
                        />
                        <p
                          className="text-left font-bold text-xl text-black1 line-clamp-2 overflow-ellipsis"
                          style={{ lineHeight: "24px" }}
                        >
                          {item.name}
                        </p>
                      </button>
                    </div>
                  ))}
                </Slider>
                {classData.extras.universities.length > 1 && (
                  <button
                    onClick={() => {
                      unisRef.current.slickPrev();
                    }}
                    className="-left-6 absolute z-10"
                    // className={`p-3 bg-white rounded-full transform transition ease-in absolute left-0 z-10`}
                    style={{ top: "20%" }}
                  >
                    <ChevronLeftIcon className="w-7 h-7 text-black1" />
                  </button>
                )}
                {classData.extras.universities.length > 1 && (
                  <button
                    onClick={() => {
                      unisRef.current.slickNext();
                    }}
                    className="-right-6 absolute z-10"
                    // className={`p-3 bg-white rounded-full transform transition ease-in absolute right-0 z-10`}
                    style={{ top: "20%" }}
                  >
                    <ChevronRightIcon className="w-7 h-7 text-black1" />
                  </button>
                )}
              </div>
            )}
            <div className="flex flex-row items-center justify-start gap-3 mt-4">
              <img
                src="/icon_calendar.svg"
                style={{
                  width: `${screenWidth < 1024 ? "24px" : "24px"}`,
                  height: `${screenWidth < 1024 ? "24px" : "24px"}`,
                }}
              />
              <p className="text-left font-normal text-base tablet:text-2xl text-black1">
                {moment(classData.info.started_at)
                  .tz("Asia/Jakarta")
                  .format("DD MMMM YYYY")}
              </p>
            </div>
            <div className="flex flex-row items-center justify-start gap-3 mt-4">
              <img
                src="/icon_clock.svg"
                style={{
                  width: `${screenWidth < 1024 ? "24px" : "24px"}`,
                  height: `${screenWidth < 1024 ? "24px" : "24px"}`,
                }}
              />
              <p className="text-left font-normal text-base tablet:text-2xl text-black1">
                {moment(classData.info.started_at)
                  .tz("Asia/Jakarta")
                  .format("HH.mm")}{" "}
                WIB -{" "}
                {moment(classData.info.ended_at)
                  .tz("Asia/Jakarta")
                  .format("HH.mm")}{" "}
                WIB
              </p>
            </div>
            {classData.subscribed && (
              <div className="flex flex-row items-center justify-start gap-3 mt-4">
                <img
                  src="/icon_class_subscribed.svg"
                  style={{
                    width: `${screenWidth < 1024 ? "26px" : "24px"}`,
                    height: `${screenWidth < 1024 ? "26px" : "24px"}`,
                  }}
                />
                <p
                  className="text-left font-normal text-base tablet:text-2xl"
                  style={{ color: "rgba(0, 168, 119, 1)" }}
                >
                  Terdaftar
                </p>
              </div>
            )}
            {!isClose &&
              (isUserSubbed ? (
                <div className="flex flex-col w-full gap-2 mt-2">
                  <button
                    onClick={() => {
                      navigate("/explore-kelas");
                    }}
                    className="flex items-center justify-center w-full py-3 rounded-lg bg-primaryColor text-center font-semibold text-sm text-white"
                    style={{ lineHeight: "21px" }}
                  >
                    Explore kelas lain yuk
                  </button>
                  <button
                    onClick={() => {
                      navigate("/profile");
                    }}
                    className="flex items-center justify-center w-full py-3 rounded-lg bg-white text-center font-semibold text-sm text-primaryColor"
                    style={{ lineHeight: "21px" }}
                  >
                    Lihat Aktivitasku
                  </button>
                </div>
              ) : (
                <div className="flex flex-col w-full gap-4 mt-4">
                  {window.location.pathname.split("/")[1] !==
                    "explore-summer-camp" && (
                    <button
                      onClick={(e) => {
                        if (!auth.isAuth) {
                          setSign({ trySignIn: false, trySignUp: true });
                        } else {
                          onFormSubmit(e);
                        }
                      }}
                      className="flex items-center justify-center w-full py-3 rounded-lg bg-primaryColor text-center font-semibold text-sm text-white"
                      style={{ lineHeight: "21px" }}
                    >
                      Daftar Sekarang
                    </button>
                  )}
                  {classData.info.tmb_result_matched && (
                    <p
                      className="text-left font-normal text-base"
                      style={{
                        color: "rgba(15, 186, 72, 1)",
                        lineHeight: "24.8px",
                      }}
                    >
                      Kelas ini cocok banget sama karaktermu sebagai{" "}
                      <span className="font-bold">
                        {charType(classData.info.tmb_ctgr_codes[0])}
                      </span>{" "}
                      loh!
                    </p>
                  )}
                </div>
              ))}
            {/* <div
            className="w-full bg-gray7 mt-4"
            style={{ minHeight: "6px", maxHeight: "6px", borderRadius: "4px" }}
          >
            <div
              className={`${
                occupancy === 0
                  ? "bg-correctOTP"
                  : occupancy > 0 && occupancy <= 0.3
                  ? "bg-correctOTP"
                  : occupancy > 0.3 && occupancy <= 0.6
                  ? "bg-mustard"
                  : occupancy > 0.6 && occupancy <= 1 && "bg-red2"
              } relative flex items-center justify-end`}
              style={{
                minHeight: "6px",
                maxHeight: "6px",
                borderRadius: "4px",
                width: `${occupancy === 0 ? "5%" : `${occupancy * 100}%`}`,
              }}
            >
              <img
                src="/icon_progressbar.svg"
                className="w-5 h-5 absolute -right-4"
              />
            </div>
          </div>
          <p
            className={`text-left font-bold text-xs tablet:text-base ${
              occupancyStatus(occupancy) === "Lagi diminati"
                ? "text-mustard"
                : occupancyStatus(occupancy) === "Segera penuh 🔥"
                ? "text-red2"
                : occupancyStatus(occupancy) === "Penuh 🔥"
                ? "text-red2"
                : occupancyStatus(occupancy) === "Tersedia" && "text-correctOTP"
            }`}
            style={{ marginTop: "6px" }}
          >
            {occupancyStatus(occupancy)}
          </p>
          <div className="bg-mustard rounded-lg px-3 py-1 mt-5 text-center font-medium text-base text-white">
            Online
          </div> */}
          </div>
        )}
      </div>
      {classData.extras.subjects.length > 0 && (
        <div className="flex flex-col py-16 px-4 bg-mustard relative">
          <img
            src={
              screenWidth < 1024
                ? "/background_classdetail_1.svg"
                : "/background_classdetail_1_2.svg"
            }
            className="absolute top-26 tablet:top-0 left-0 tablet:left-36"
          />
          <div className="flex flex-col w-full tablet:w-3/5 items-center tablet:items-start tablet:pl-22">
            <p
              className="text-center font-bold text-2xl tablet:text-48px text-black1 tracking-0.8px relative z-10"
              style={{
                lineHeight: `${screenWidth < 1024 ? "29px" : "52px"}`,
              }}
            >
              Apa yang akan kamu pelajari
            </p>
            <p
              className="text-center font-normal text-base tablet:text-xl text-black1 mt-4"
              style={{
                lineHeight: `${screenWidth < 1024 ? "25px" : "31px"}`,
                opacity: "0.88",
              }}
            >
              Dengan mengikuti kelas ini, kamu bakal dapetin banyak materi yang
              menarik loh!
            </p>
            <div className="flex flex-col gap-4 w-full tablet:items-start mt-8 relative z-10 tablet:pr-30">
              {classData.extras.subjects.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2 w-full  p-4 tablet:py-4 tablet:px-8 bg-white rounded-xl"
                  // style={{
                  //   minWidth: `${screenWidth >= 1024 && "1062px"}`,
                  //   maxWidth: `${screenWidth >= 1024 && "1062px"}`,
                  // }}
                >
                  <p
                    className="text-left font-bold text-xl text-black1"
                    style={{ lineHeight: "30px" }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="text-left font-normal text-base text-black1"
                    style={{ lineHeight: "20px", opacity: "0.88" }}
                  >
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div
        className="flex flex-col px-4 py-8 w-full tablet:p-20 relative z-20"
        style={{ backgroundColor: "rgba(238, 240, 243, 1)" }}
      >
        <p
          className="text-left font-bold text-2xl tablet:text-48px text-black1 tracking-0.8px"
          style={{ lineHeight: `${screenWidth < 1024 ? "26px" : "52px"}` }}
        >
          Yang kamu dapetin di kelas ini
        </p>
        <p
          className="text-left font-normal text-base tablet:text-xl text-black1 tracking-0.8px mt-4"
          style={{
            lineHeight: `${screenWidth < 1024 ? "26px" : "31px"}`,
            opacity: "0.88",
          }}
        >
          {screenWidth < 1024
            ? "Apa aja sih benefit yang bakal kamu dapetin di kelas ini?"
            : "Banyak banget benefit yang akan kamu dapetin dengan mengikuti kelas ini loh"}
        </p>
        <div
          className={`${
            screenWidth < 1024
              ? "flex flex-row gap-6 overflow-x-scroll mt-6"
              : "grid grid-cols-2 gap-6 mt-8"
          }`}
          style={{ maxWidth: "590px" }}
        >
          {classData.extras.benefits.map((item, index) => (
            <ClassFacilities
              item={item}
              index={index}
              key={index}
              mobile={screenWidth < 1024 ? true : false}
            />
          ))}
        </div>
      </div>
      <div
        className="flex w-full"
        ref={stopStickyRef}
        onMouseEnter={handleStopSticky}
      ></div>
      <div className="flex flex-col justify-start items-start pt-8 pb-8 tablet:pb-28 w-full relative z-10 bg-gradient-to-b from-white to-E7">
        {window.location.pathname.split("/")[1] !== "explore-summer-camp" && (
          <>
            {screenWidth < 1024 && !isClose && (
              <div className="flex flex-col w-full justify-center items-center gap-6 py-8 px-12 bg-white mt-4">
                <p
                  className="text-center font-bold text-base"
                  style={{
                    lineHeight: "24.8px",
                    color: "rgba(0, 168, 119, 1)",
                  }}
                >
                  Sudah siap ikut kelasnya?
                </p>
                <button
                  onClick={(e) => {
                    onFormSubmit(e);
                  }}
                  className="flex items-center justify-center w-full py-3 rounded-lg bg-primaryColor text-center font-semibold text-sm text-white"
                  style={{ lineHeight: "21px" }}
                >
                  Daftar Sekarang
                </button>
              </div>
            )}
          </>
        )}
        <div className="flex flex-col w-full justify-start items-start pl-4 tablet:pl-20 mt-6">
          <p
            className="text-left font-bold text-2xl tablet:text-48px text-black1 tracking-0.8px"
            style={{ lineHeight: `${screenWidth < 1024 ? "29px" : "58px"}` }}
          >
            Temui pembicara kelas ini
          </p>
          <div className="flex flex-col tablet:flex-row tablet:justify-start tablet:gap-12 w-full tablet:mt-12">
            <div
              className="relative"
              style={{
                minWidth: `${screenWidth >= 1024 && "712px"}`,
                maxWidth: `${screenWidth >= 1024 && "712px"}`,
                // height: `${screenWidth >= 1024 && "388px"}`,
              }}
            >
              <div className="mt-6 tablet:mt-0">
                {screenWidth < 1024 ? (
                  <div
                    className="flex flex-row overflow-x-auto"
                    style={{ gap: "10px" }}
                  >
                    {classData.extras.lecturers.map((item, index) => (
                      <SpeakerCard
                        item={item}
                        index={index}
                        key={index}
                        mobile={screenWidth < 1024 ? true : false}
                        currentSpeaker={currentSpeaker}
                        totalSpeakers={classData.extras.lecturers.length}
                        prevClick={() => {
                          speakersRef.current.slickPrev();
                          setCurrentSpeaker(currentSpeaker - 1);
                        }}
                        // scrollX={scrollX}
                        nextClick={() => {
                          speakersRef.current.slickNext();
                          setCurrentSpeaker(currentSpeaker + 1);
                        }}
                        // scrolEnd={scrolEnd}
                      />
                    ))}
                  </div>
                ) : (
                  <Slider ref={speakersRef} {...speakerSettings}>
                    {classData.extras.lecturers.map((item, index) => (
                      <SpeakerCard
                        item={item}
                        index={index}
                        key={index}
                        mobile={screenWidth < 1024 ? true : false}
                        currentSpeaker={currentSpeaker}
                        totalSpeakers={classData.extras.lecturers.length}
                        prevClick={() => {
                          speakersRef.current.slickPrev();
                          setCurrentSpeaker(currentSpeaker - 1);
                        }}
                        // scrollX={scrollX}
                        nextClick={() => {
                          speakersRef.current.slickNext();
                          setCurrentSpeaker(currentSpeaker + 1);
                        }}
                        // scrolEnd={scrolEnd}
                      />
                    ))}
                  </Slider>
                )}
              </div>
            </div>
            {/* {classData.extras.universities.length > 0 &&
              classData.info.uni_id !== "EXPL0000" && (
                <div
                  className="flex flex-col items-start justify-start bg-white w-full rounded-xl p-6 mt-6 tablet:mt-0 relative"
                  style={{
                    maxWidth: "520px",
                    minHeight: "359px",
                    maxHeight: "359px",
                  }}
                >
                  <p
                    className="text-left font-semibold text-base tablet:text-xl text-black1"
                    style={{
                      lineHeight: `${screenWidth < 1024 ? "25px" : "31px"}`,
                      opacity: "0.88",
                    }}
                  >
                    Pelajari juga lebih lanjut terkait partner kampus di kelas
                    ini
                  </p>
                  <div
                    ref={uniScroll}
                    className="flex flex-row gap-3 overflow-x-scroll tablet:overflow-hidden w-full"
                    style={{ scrollBehavior: "smooth" }}
                  >
                    {classData.extras.universities.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col w-full h-max p-6 rounded-lg mt-6"
                        style={{
                          border: "1px solid rgba(36, 16, 54, 0.3)",
                          maxWidth: `${
                            screenWidth >= 1024 ? "472px" : "260px"
                          }`,
                          minWidth: `${
                            screenWidth >= 1024 ? "472px" : "260px"
                          }`,
                          maxHeight: `${screenWidth >= 1024 && "153px"}`,
                          minHeight: `${screenWidth >= 1024 && "153px"}`,
                        }}
                      >
                        <div className="flex flex-row gap-4 items-center justify-start">
                          <img
                            src={
                              item.id === "EXPL0000"
                                ? item.organizer_logo === ""
                                  ? item.logo_url
                                  : item.organizer_logo
                                : item.logo_url
                            }
                            className="rounded-lg"
                            style={{
                              minHeight: "56px",
                              minWidth: "56px",
                              maxHeight: "56px",
                              maxWidth: "56px",
                            }}
                          />
                          <Link
                            to={`/explore-kampus/${item.slug}`}
                            className="text-left font-bold text-xl text-black1"
                            style={{ lineHeight: "24px" }}
                          >
                            {item.id === "EXPL0000"
                              ? item.organizer_name === ""
                                ? item.name
                                : item.organizer_name
                              : item.name}
                          </Link>
                        </div>
                        <div className="flex flex-row gap-6 items-center justify-start mt-6">
                          <div className="flex flex-row items-center justify-start gap-2">
                            <img src="/icon_pinpoint.svg" className="h-4 w-4" />
                            <p
                              className="text-left font-normal text-sm text-black 1"
                              style={{ lineHeight: "22px", opacity: "0.88" }}
                            >
                              {item.province_name}
                            </p>
                          </div>
                          <div className="flex flex-row items-center justify-start gap-2">
                            <img
                              src="/icon_star_hollow.svg"
                              className="h-4 w-4"
                            />
                            <p
                              className="text-left font-normal text-sm text-black 1"
                              style={{ lineHeight: "22px", opacity: "0.88" }}
                            >
                              {item.accreditation}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {screenWidth >= 1024 &&
                    classData.extras.universities.length > 1 && (
                      <div className="flex flex-row gap-2 items-center justify-end absolute bottom-6 right-6">
                        <p
                          className="text-center font-normal text-black1"
                          style={{ fontSize: "22px", opacity: "0.6" }}
                        >
                          {currentUni + 1}/
                          {classData.extras.universities.length}
                        </p>
                        <button
                          onClick={() => {
                            slideUni(-484);
                            setCurrentUni(currentUni - 1);
                          }}
                          disabled={uniScrollX === 0 || currentUni === 0}
                          className={`bg-tosca2 rounded-lg p-3 ml-10 ${
                            uniScrollX === 0 || currentUni === 0
                              ? "opacity-30"
                              : "opacity-100"
                          }`}
                        >
                          <ArrowLeftIcon
                            className="w-4 h-4 mac:w-5 mac:h-5"
                            color="white"
                          />
                        </button>
                        <button
                          onClick={() => {
                            slideUni(+484);
                            setCurrentUni(currentUni + 1);
                          }}
                          disabled={
                            uniScrolEnd ||
                            currentUni ===
                              classData.extras.universities.length - 1
                          }
                          className={`bg-tosca2 rounded-lg p-3 ${
                            uniScrolEnd ||
                            currentUni ===
                              classData.extras.universities.length - 1
                              ? // currentSliderPage === Math.ceil(topUnivs?.length / 3)
                                "opacity-30"
                              : "opacity-100"
                          }`}
                        >
                          <ArrowRightIcon
                            className="w-4 h-4 mac:w-5 mac:h-5"
                            color="white"
                          />
                        </button>
                      </div>
                    )}
                </div>
              )} */}
          </div>
        </div>
        {!isSticky && (
          <div
            // ref={cardRef}
            className={`flex flex-col items-start justify-start bg-white rounded-xl p-8 absolute bottom-28 right-20 z-50 ${
              screenWidth < 1024 && "invisible"
            }`}
            style={{
              minWidth: "443px",
              maxWidth: "443px",
              boxShadow: "31px 31px 84px 0px rgba(23, 23, 23, 0.25)",
            }}
          >
            {classData.extras.universities.length > 0 && (
              <div
                className="w-full relative rounded-lg p-6"
                style={{
                  border: "1px solid var(--overlay, rgba(36, 16, 54, 0.30))",
                }}
              >
                <Slider ref={unisRef} {...unisSliderSettings}>
                  {classData.extras.universities.map((item, index) => (
                    <div key={index} className="flex flex-col w-full">
                      <button
                        onClick={() => {
                          navigate(`/explore-kampus/${item.slug}`);
                        }}
                        className="flex flex-row items-center justify-start gap-4 w-full overflow-hidden"
                      >
                        <img
                          src={item.logo_url}
                          className="w-14 h-14 object-contain"
                        />
                        <p
                          className="text-left font-bold text-xl text-black1 line-clamp-2 overflow-ellipsis"
                          style={{ lineHeight: "24px" }}
                        >
                          {item.name}
                        </p>
                      </button>
                      <div className="flex flex-row mt-6 gap-6">
                        <div className="flex flex-row items-center justify-start">
                          <LocationMarkerIcon className="h-4 w-4 mr-1" />
                          <div className="text-left text-sm text-black1 font-normal mac:text-base">
                            {item.province_name}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                          <StarIcon className="h-4 w-4 mr-1" />
                          <div className="text-left text-sm text-black1 font-normal mac:text-base">
                            Akreditasi {item.accreditation}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                {classData.extras.universities.length > 1 && (
                  <button
                    onClick={() => {
                      unisRef.current.slickPrev();
                    }}
                    className="-left-6 absolute z-10"
                    // className={`p-3 bg-white rounded-full transform transition ease-in absolute left-0 z-10`}
                    style={{ top: "20%" }}
                  >
                    <ChevronLeftIcon className="w-7 h-7 text-black1" />
                  </button>
                )}
                {classData.extras.universities.length > 1 && (
                  <button
                    onClick={() => {
                      unisRef.current.slickNext();
                    }}
                    className="-right-6 absolute z-10"
                    // className={`p-3 bg-white rounded-full transform transition ease-in absolute right-0 z-10`}
                    style={{ top: "20%" }}
                  >
                    <ChevronRightIcon className="w-7 h-7 text-black1" />
                  </button>
                )}
              </div>
            )}
            <div className="flex flex-row items-center justify-start gap-3 mt-4">
              <img
                src="/icon_calendar.svg"
                style={{
                  width: `${screenWidth < 1024 ? "24px" : "24px"}`,
                  height: `${screenWidth < 1024 ? "24px" : "24px"}`,
                }}
              />
              <p className="text-left font-normal text-base tablet:text-2xl text-black1">
                {moment(classData.info.started_at)
                  .tz("Asia/Jakarta")
                  .format("DD MMMM YYYY")}
              </p>
            </div>
            <div className="flex flex-row items-center justify-start gap-3 mt-4">
              <img
                src="/icon_clock.svg"
                style={{
                  width: `${screenWidth < 1024 ? "24px" : "24px"}`,
                  height: `${screenWidth < 1024 ? "24px" : "24px"}`,
                }}
              />
              <p className="text-left font-normal text-base tablet:text-2xl text-black1">
                {moment(classData.info.started_at)
                  .tz("Asia/Jakarta")
                  .format("HH.mm")}{" "}
                WIB -{" "}
                {moment(classData.info.ended_at)
                  .tz("Asia/Jakarta")
                  .format("HH.mm")}{" "}
                WIB
              </p>
            </div>
            {classData.subscribed && (
              <div className="flex flex-row items-center justify-start gap-3 mt-4">
                <img
                  src="/icon_class_subscribed.svg"
                  style={{
                    width: `${screenWidth < 1024 ? "26px" : "24px"}`,
                    height: `${screenWidth < 1024 ? "26px" : "24px"}`,
                  }}
                />
                <p
                  className="text-left font-normal text-base tablet:text-2xl"
                  style={{ color: "rgba(0, 168, 119, 1)" }}
                >
                  Terdaftar
                </p>
              </div>
            )}
            {!isClose &&
              (isUserSubbed ? (
                <div className="flex flex-col w-full gap-2 mt-2">
                  <button
                    onClick={() => {
                      navigate("/explore-kelas");
                    }}
                    className="flex items-center justify-center w-full py-3 rounded-lg bg-primaryColor text-center font-semibold text-sm text-white"
                    style={{ lineHeight: "21px" }}
                  >
                    Explore kelas lain yuk
                  </button>
                  <button
                    onClick={() => {
                      navigate("/profile");
                    }}
                    className="flex items-center justify-center w-full py-3 rounded-lg bg-white text-center font-semibold text-sm text-primaryColor"
                    style={{ lineHeight: "21px" }}
                  >
                    Lihat Aktivitasku
                  </button>
                </div>
              ) : (
                <div className="flex flex-col w-full gap-4 mt-4">
                  {window.location.pathname.split("/")[1] !==
                    "explore-summer-camp" && (
                    <button
                      onClick={(e) => {
                        if (!auth.isAuth) {
                          setSign({ trySignIn: false, trySignUp: true });
                        } else {
                          onFormSubmit(e);
                        }
                      }}
                      className="flex items-center justify-center w-full py-3 rounded-lg bg-primaryColor text-center font-semibold text-sm text-white"
                      style={{ lineHeight: "21px" }}
                    >
                      Daftar Sekarang
                    </button>
                  )}
                  {classData.info.tmb_result_matched && (
                    <p
                      className="text-left font-normal text-base"
                      style={{
                        color: "rgba(15, 186, 72, 1)",
                        lineHeight: "24.8px",
                      }}
                    >
                      Kelas ini cocok banget sama karaktermu sebagai{" "}
                      <span className="font-bold">
                        {charType(classData.info.tmb_ctgr_codes[0])}
                      </span>{" "}
                      loh!
                    </p>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      {classRecommendation.length > 0 && (
        <div className="flex flex-col py-12 px-4 relative z-10 tablet:px-20 tablet:pb-20 tablet:pt-0 bg-E7">
          {screenWidth < 1024 ? (
            <p
              className="text-left font-bold text-2xl text-black1 tracking-0.8px"
              style={{ lineHeight: "29px" }}
            >
              Coba liat kelas lain yang mungkin kamu suka!
            </p>
          ) : (
            <div className="flex flex-row justify-between items-end w-full">
              <p
                className="text-left font-bold text-48px text-black1 tracking-0.8px"
                style={{ lineHeight: "58px" }}
              >
                Coba liat kelas yang <br />
                mungkin kamu suka!
              </p>
              {screenWidth >= 1024 && classRecommendation.length > 3 && (
                <div className="flex flex-row gap-2 items-center justify-end pr-12">
                  <button
                    onClick={() => {
                      classRecRef.current.slickPrev();
                      setClassRecCurrentPage(1);
                    }}
                    disabled={classRecCurrentPage === 1}
                    className={`bg-tosca2 rounded-lg p-3 ${
                      classRecCurrentPage === 1 ? "opacity-30" : "opacity-100"
                    }`}
                  >
                    <ArrowLeftIcon
                      className="w-4 h-4 mac:w-5 mac:h-5"
                      color="white"
                    />
                  </button>
                  <button
                    onClick={() => {
                      classRecRef.current.slickNext();
                      setClassRecCurrentPage(2);
                    }}
                    disabled={classRecCurrentPage === 2}
                    className={`bg-tosca2 rounded-lg p-3 ${
                      classRecCurrentPage === 2 ? "opacity-30" : "opacity-100"
                    }`}
                  >
                    <ArrowRightIcon
                      className="w-4 h-4 mac:w-5 mac:h-5"
                      color="white"
                    />
                  </button>
                </div>
              )}
            </div>
          )}
          {classRecommendation.length > 3 && screenWidth >= 1024 ? (
            <div className="mt-16">
              <Slider ref={classRecRef} {...classRecSettings}>
                {classRecommendation.map((item, index) => {
                  return (
                    <div key={index}>
                      <HomeCardClassV2
                        key={index}
                        item={item}
                        mobile={screenWidth < 1024 ? true : false}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          ) : (
            <div
              className={`flex flex-row justify-start ${
                screenWidth < 1024 && "overflow-x-auto"
              } items-center gap-6 w-full mt-8 tablet:mt-16`}
            >
              {classRecommendation.map((item, index) => {
                return (
                  <div key={index}>
                    <HomeCardClassV2
                      key={index}
                      item={item}
                      mobile={screenWidth < 1024 ? true : false}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      <Transition appear show={videoModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-max h-full transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all relative z-50">
                  <div
                    className="flex flex-col tablet:flex-row relative"
                    style={{
                      width: `${screenWidth >= 1024 && "894px"}`,
                      height: `${screenWidth >= 1024 && "467px"}`,
                      borderRadius: "20px",
                    }}
                  >
                    <button
                      type="button"
                      className="absolute top-6 left-6 z-30"
                      onClick={() => {
                        setVideoModalOpen(false);
                      }}
                    >
                      <MdClose size={26} color="black " />
                    </button>
                    <div
                      className="relative flex flex-col justify-center items-center p-8 tablet:p-16 rounded-20px tablet:rounded-none"
                      style={{ backgroundColor: "#F1F3F6" }}
                    >
                      <img
                        className="h-48 w-48 tablet:h-60 tablet:w-60 mx-auto mb-8 mt-1"
                        src="/ic_qrcode_wa.jpg"
                        alt="Hero"
                        style={{
                          border: "20px solid #F9C60B",
                          borderRadius: "44px",
                        }}
                      />
                      <p
                        className="text-center font-medium text-base text-black1 w-60"
                        style={{ lineHeight: "24px" }}
                      >
                        Jangan lupa scan QR Code untuk bergabung dengan para
                        Explorers lainnya disini ya!
                      </p>
                    </div>
                    <div className="w-full h-full flex flex-col justify-start items-start px-4 py-6 tablet:p-12 overflow-auto">
                      <p className="text-left font-bold tablet:font-normal text-base tablet:text-2xl text-black1">
                        Yeay! Kamu telah terdaftar pada kelas:
                      </p>
                      <p className="text-left font-semibold text-xl tablet:text-3xl text-primaryColor mt-2 tablet:mt-4">
                        {classData.info.topic}
                      </p>
                      <p className="text-left font-normal text-sm tablet:text-base text-black1 mt-4 tablet:mt-6">
                        Link untuk mengikuti kelas sudah terkirim ke email kamu.
                        Kamu juga bisa mengaksesnya melalui “Aktivitas Kamu”
                        pada Profile! Sampai jumpa di kelas ya 👋
                      </p>
                      <div className="flex flex-grow"></div>
                      <div className="w-full flex flex-col tablet:flex-row justify-between items-center mt-12 tablet:mt-0">
                        <button
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          <div className="text-center font-semibold text-base text-primaryColor px-10 py-3 rounded-lg">
                            Lihat Aktivitasku
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            navigate("/explore-kelas");
                          }}
                        >
                          <div className="text-center font-semibold text-base text-white bg-primaryColor px-10 py-3 rounded-lg">
                            Explore kelas lain yuk
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {screenWidth >= 1024 && (
        <div
          ref={cardRef}
          className={`flex flex-col items-start justify-start bg-white rounded-xl p-8 ${
            isSticky
              ? "fixed top-40 right-20 z-30"
              : "absolute top-40 right-20 z-30"
          }`}
          style={{
            minWidth: "443px",
            maxWidth: "443px",
            boxShadow: "31px 31px 84px 0px rgba(23, 23, 23, 0.25)",
          }}
        >
          {classData.extras.universities.length > 0 && (
            <div
              className="w-full relative rounded-lg p-6"
              style={{
                border: "1px solid var(--overlay, rgba(36, 16, 54, 0.30))",
              }}
            >
              <Slider ref={unisRef} {...unisSliderSettings}>
                {classData.extras.universities.map((item, index) => (
                  <div key={index} className="flex flex-col w-full">
                    <button
                      onClick={() => {
                        navigate(`/explore-kampus/${item.slug}`);
                      }}
                      className="flex flex-row items-center justify-start gap-4 w-full overflow-hidden"
                    >
                      <img
                        src={item.logo_url}
                        className="w-14 h-14 object-contain"
                      />
                      <p
                        className="text-left font-bold text-xl text-black1 line-clamp-2 overflow-ellipsis"
                        style={{ lineHeight: "24px" }}
                      >
                        {item.name}
                      </p>
                    </button>
                    <div className="flex flex-row mt-6 gap-6">
                      <div className="flex flex-row items-center justify-start">
                        <LocationMarkerIcon className="h-4 w-4 mr-1" />
                        <div className="text-left text-sm text-black1 font-normal mac:text-base">
                          {item.province_name}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-start">
                        <StarIcon className="h-4 w-4 mr-1" />
                        <div className="text-left text-sm text-black1 font-normal mac:text-base">
                          Akreditasi {item.accreditation}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              {classData.extras.universities.length > 1 && (
                <button
                  onClick={() => {
                    unisRef.current.slickPrev();
                  }}
                  className="-left-6 absolute z-10"
                  // className={`p-3 bg-white rounded-full transform transition ease-in absolute left-0 z-10`}
                  style={{ top: "20%" }}
                >
                  <ChevronLeftIcon className="w-7 h-7 text-black1" />
                </button>
              )}
              {classData.extras.universities.length > 1 && (
                <button
                  onClick={() => {
                    unisRef.current.slickNext();
                  }}
                  className="-right-6 absolute z-10"
                  // className={`p-3 bg-white rounded-full transform transition ease-in absolute right-0 z-10`}
                  style={{ top: "20%" }}
                >
                  <ChevronRightIcon className="w-7 h-7 text-black1" />
                </button>
              )}
            </div>
          )}
          <div className="flex flex-row items-center justify-start gap-3 mt-4">
            <img
              src="/icon_calendar.svg"
              style={{
                width: `${screenWidth < 1024 ? "24px" : "24px"}`,
                height: `${screenWidth < 1024 ? "24px" : "24px"}`,
              }}
            />
            <p className="text-left font-normal text-base tablet:text-2xl text-black1">
              {moment(classData.info.started_at)
                .tz("Asia/Jakarta")
                .format("DD MMMM YYYY")}
            </p>
          </div>
          <div className="flex flex-row items-center justify-start gap-3 mt-4">
            <img
              src="/icon_clock.svg"
              style={{
                width: `${screenWidth < 1024 ? "24px" : "24px"}`,
                height: `${screenWidth < 1024 ? "24px" : "24px"}`,
              }}
            />
            <p className="text-left font-normal text-base tablet:text-2xl text-black1">
              {moment(classData.info.started_at)
                .tz("Asia/Jakarta")
                .format("HH.mm")}{" "}
              WIB -{" "}
              {moment(classData.info.ended_at)
                .tz("Asia/Jakarta")
                .format("HH.mm")}{" "}
              WIB
            </p>
          </div>
          {classData.subscribed && (
            <div className="flex flex-row items-center justify-start gap-3 mt-4">
              <img
                src="/icon_class_subscribed.svg"
                style={{
                  width: `${screenWidth < 1024 ? "26px" : "24px"}`,
                  height: `${screenWidth < 1024 ? "26px" : "24px"}`,
                }}
              />
              <p
                className="text-left font-normal text-base tablet:text-2xl"
                style={{ color: "rgba(0, 168, 119, 1)" }}
              >
                Terdaftar
              </p>
            </div>
          )}
          {!isClose &&
            (isUserSubbed ? (
              <div className="flex flex-col w-full gap-2 mt-2">
                <button
                  onClick={() => {
                    navigate("/explore-kelas");
                  }}
                  className="flex items-center justify-center w-full py-3 rounded-lg bg-primaryColor text-center font-semibold text-sm text-white"
                  style={{ lineHeight: "21px" }}
                >
                  Explore kelas lain yuk
                </button>
                <button
                  onClick={() => {
                    navigate("/profile");
                  }}
                  className="flex items-center justify-center w-full py-3 rounded-lg bg-white text-center font-semibold text-sm text-primaryColor"
                  style={{ lineHeight: "21px" }}
                >
                  Lihat Aktivitasku
                </button>
              </div>
            ) : (
              <div className="flex flex-col w-full gap-4 mt-4">
                {window.location.pathname.split("/")[1] !==
                  "explore-summer-camp" && (
                  <button
                    onClick={(e) => {
                      if (!auth.isAuth) {
                        setSign({ trySignIn: false, trySignUp: true });
                      } else {
                        onFormSubmit(e);
                      }
                    }}
                    className="flex items-center justify-center w-full py-3 rounded-lg bg-primaryColor text-center font-semibold text-sm text-white"
                    style={{ lineHeight: "21px" }}
                  >
                    Daftar Sekarang
                  </button>
                )}
                {classData.info.tmb_result_matched && (
                  <p
                    className="text-left font-normal text-base"
                    style={{
                      color: "rgba(15, 186, 72, 1)",
                      lineHeight: "24.8px",
                    }}
                  >
                    Kelas ini cocok banget sama karaktermu sebagai{" "}
                    <span className="font-bold">
                      {charType(classData.info.tmb_ctgr_codes[0])}
                    </span>{" "}
                    loh!
                  </p>
                )}
              </div>
            ))}
        </div>
      )}
    </LayoutV2>
  );
}

{
  /* RESERVED FOR LATER USE */
}
{
  /* <div
className="flex flex-row gap-5 items-start w-full p-4 tablet:p-8 bg-white"
style={{
  borderRadius: "20px",
  boxShadow: "24px 24px 200px rgba(23, 23, 23, 0.08)",
}}
>
<img src="/char-espa-03.svg" />
{auth.isAuth && data?.tmb_result_matched === true ? (
  <p className="text-left font-normal text-sm text-black1">
    Wah, Jurusan ini kamu banget loh ✨, sesuai sama karaktermu
    sebagai
    <b>{` ${charType(data?.tmb_ctgr_codes[0])}`}</b>
  </p>
) : (
  <div className="flex flex-col w-full items-start">
    <p className="text-left font-normal text-sm tablet:text-base text-black1">
      Kira kira jurusan ini kamu banget gak ya? yuk coba cari tau
      bareng Serra!
    </p>
    <Link
      to={"/tes-minat"}
      className="flex flex-row items-center gap-1 text-center font-semibold text-xs text-white px-3 py-1 tablet:py-2 bg-primaryColor mt-4"
      style={{ borderRadius: "8px" }}
    >
      Pastiin sekarang
      <span>
        <ArrowRightIcon className="w-4 h-auto" />
      </span>
    </Link>
  </div>
)}
</div> */
}
