import { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom, notifError } from "../utils/api";
import PageLoading from "./PageLoading";
import HomeCardClassV2 from "../components/HomeCardClassV2";
import TestimonyCard from "../components/TestimonyCard";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import LayoutV2 from "../components/Layout_V2";

export default function ExploreSummerCamp() {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const contentSliderRef = useRef();
  const imageCarouselRef = useRef();
  const [summerCampData, setSummerCampData] = useState();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [exclusiveClasses, setExclusiveClasses] = useState();
  const [selectedTabData, setSelectedTabData] = useState();
  const [carouselImages, setCarouselImages] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isTabOpen, setIsTabOpen] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    _api
      .get("/summer-camp/about")
      .then((res) => {
        setSummerCampData(res.data.data);
      })
      .catch(notifError);

    _api
      .post("/classes-exclusive/upcoming", {
        university_ids: [],
        keyword: "",
        page: 1,
        limit: 999,
        field_of_study_ids: [],
        sort_direction: "DESC",
        sort_key: "availability",
        is_online: -1, //Status: ALL = -1, True = 1, False = 0
        is_past_classes: -1, //Status: ALL = -1, True = 1, False = 0
        tags: [],
      })
      .then((res) => {
        setExclusiveClasses(res.data.data);
      })
      .catch(notifError);

    _api
      .get("/summer-camp/pictures")
      .then((res) => {
        setCarouselImages(res.data.data);
      })
      .catch(notifError);
  }, []);

  useEffect(() => {
    if (summerCampData) {
      // setSelectedTabData(summerCampData[selectedTab ? selectedTab - 1 : 0]);
      setSelectedTabData(summerCampData[selectedTabIndex]);
    }
  }, [summerCampData, selectedTabIndex]);

  var screenWidth = windowSize.innerWidth;

  const knowMore = [
    {
      icon: "/icon_typo_faq.svg",
      name: "FAQ Summer Camp",
      type: "link",
      redirect: "/faq/summer-camp",
    },
    {
      icon: "/icon_call.svg",
      name: "Tanya OCBC",
      type: "phone",
      redirect: "tel:1500-999",
    },
    {
      icon: "/icon_whatsapp.svg",
      name: "Whatsapp",
      type: "link",
      redirect: "https://wa.me/6282118887806",
    },
  ];

  const testimonies = [
    {
      testimony:
        "“Program yang diadakan sudah cukup baik, kedepannya dapat disesuaikan dengan kegiatan seperti acara pertukaran seni budaya serta program yang dapat membuka paradigma baru”",
      source: "individual",
      photo: "/avatar_default.svg",
      name: "Parents - SW",
      degree: "",
    },
    {
      testimony:
        "“Everything is already good and I'm satisfied with this programme”",
      source: "individual",
      photo: "/avatar_default.svg",
      name: "Partisipan - JK",
      degree: "SMA Santo Yakobus",
    },
    {
      testimony:
        "“I'm satisfied with this programme, please extend the program time”",
      source: "individual",
      photo: "/avatar_default.svg",
      name: "Partisipan - IEC",
      degree: "PKBM Teach Indonesia School",
    },
  ];

  const contentSliderSetting = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    swipe: true,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  const imageCarouselSetting = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    adaptiveHeight: true,
    variableWidth: true,
    slidesToShow: 1,
    speed: 500,
    arrows: false,
  };

  if (!summerCampData) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0">
      <div className="flex flex-col justify-start items-stretch w-full px-4 tablet:px-20">
        <img
          src="https://strgonelabsprod.blob.core.windows.net/explorationid/TEST/Summer%20Camp/Banner%20Updated_19Jan24.png"
          className="w-full h-auto mt-9"
        />
        <div
          className="flex flex-col tablet:flex-row w-full tablet:justify-between tablet:gap-28"
          style={{ marginTop: screenWidth >= 1024 && "99px" }}
        >
          <div
            className="flex flex-col w-full justify-start tablet:items-start"
            style={{ width: screenWidth >= 1024 && "740px" }}
          >
            <p
              className="text-left font-bold text-base text-tosca2 tablet:m-0"
              style={{
                lineHeight: "24px",
                letterSpacing: "2px",
                marginTop: screenWidth < 1024 ? "50px" : "",
              }}
            >
              TENTANG SUMMER CAMP
            </p>
            <p
              className="text-left font-bold text-2xl text-black1 mt-4 tracking-tighter"
              style={{ lineHeight: "28.8px" }}
            >
              OCBC Summer Camp 2024
            </p>
            {screenWidth < 1024 ? (
              <div
                onClick={() => {
                  setIsTabOpen(!isTabOpen);
                }}
                className={`w-full py-3 ${
                  isTabOpen ? "rounded-t-lg" : "rounded-lg"
                } text-center font-semibold text-base text-white mt-10 cursor-pointer relative`}
                style={{
                  backgroundColor: "rgba(67, 188, 205, 1)",
                  lineHeight: "24px",
                }}
              >
                {!isTabOpen ? (
                  <ChevronDownIcon
                    onClick={() => {
                      setIsTabOpen(!isTabOpen);
                    }}
                    className={`${
                      screenWidth < 1024 ? "h-auto" : "w-10 h-auto"
                    } p-0 m-0 text-black1 cursor-pointer absolute right-5`}
                    style={{ color: "white", width: "26px" }}
                  />
                ) : (
                  <ChevronUpIcon
                    onClick={() => {
                      setIsTabOpen(!isTabOpen);
                    }}
                    className={`${
                      screenWidth < 1024 ? "h-auto" : "w-10 h-auto"
                    } p-0 m-0 text-white cursor-pointer absolute right-5`}
                    style={{ color: "white", width: "26px" }}
                  />
                )}
                {summerCampData[selectedTabIndex]?.tab_title}
              </div>
            ) : (
              <div className="flex flex-col tablet:flex-row w-full gap-4 mt-5">
                {summerCampData?.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedTabIndex(index);
                      contentSliderRef.current.slickGoTo(0);
                    }}
                    className={`py-3 px-6 rounded-lg text-center font-semibold text-base ${
                      selectedTabIndex === index ? "text-white" : "text-tosca2"
                    }`}
                    style={{
                      backgroundColor:
                        selectedTabIndex === index
                          ? "rgba(67, 188, 205, 1)"
                          : "rgba(241, 243, 246, 1)",
                      lineHeight: "24px",
                    }}
                  >
                    {item.tab_title}
                  </button>
                ))}
              </div>
            )}
            {isTabOpen && (
              <div className="w-full flex flex-col justify-start items-stretch">
                {summerCampData?.map(
                  (item, index) =>
                    item.id !== summerCampData[selectedTabIndex].id && (
                      <div
                        key={index}
                        onClick={() => {
                          setSelectedTabIndex(index);
                          contentSliderRef.current.slickGoTo(0);
                          setIsTabOpen(false);
                        }}
                        className={`w-full py-3 text-center font-semibold text-base text-tosca2 cursor-pointer ${
                          index === 2 && "rounded-b-lg"
                        }`}
                        style={{
                          backgroundColor: "rgba(241, 243, 246, 1)",
                          lineHeight: "24px",
                        }}
                      >
                        {item.tab_title}
                      </div>
                    )
                )}
              </div>
            )}
            <p
              className="text-left font-normal text-base text-black188 mt-10"
              style={{ lineHeight: "24.8px" }}
              dangerouslySetInnerHTML={{
                __html: summerCampData[selectedTabIndex]?.tab_description,
              }}
            />
            <a
              href="https://ocbc-id.typeform.com/to/g2wj25QH"
              target="_blank"
              rel="noreferrer"
              className={`w-full tablet:w-max tablet:px-16 tablet:py-3 py-3 rounded-lg bg-primaryColor text-center font-semibold ${
                screenWidth < 1024 ? "text-base" : "text-base"
              } text-white`}
              style={{
                lineHeight: screenWidth < 1024 ? "18px" : "24px",
                marginTop: screenWidth < 1024 ? "40px" : "22px",
              }}
            >
              Saya Tertarik!
            </a>
          </div>
          <div
            className="mt-10 tablet:mt-0"
            style={{
              width: screenWidth >= 1024 && "518px",
              height: screenWidth < 1024 ? "464px" : "579px",
            }}
          >
            <Slider ref={contentSliderRef} {...contentSliderSetting}>
              {screenWidth < 1024
                ? selectedTabData?.banners_mobile_url?.map((item, index) => (
                    <a
                      href={
                        selectedTabData.redirect.length > 0 &&
                        selectedTabData.redirect[index] &&
                        !selectedTabData?.redirect[index]
                          ?.toLowerCase()
                          .startsWith("https://")
                          ? `https://${selectedTabData?.redirect[index]}`
                          : selectedTabData?.redirect[index]
                      }
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                      className="rounded-20px cursor-pointer"
                      style={{
                        width: screenWidth >= 1024 && "518px",
                        height: screenWidth < 1024 ? "464px" : "579px",
                      }}
                    >
                      <img
                        src={item}
                        className="object-cover rounded-20px"
                        style={{
                          width: screenWidth >= 1024 && "518px",
                          height: screenWidth < 1024 ? "464px" : "579px",
                        }}
                      />
                    </a>
                  ))
                : selectedTabData?.banners_desktop_url?.map((item, index) => (
                    <a
                      href={
                        selectedTabData.redirect.length > 0 &&
                        selectedTabData.redirect[index] &&
                        !selectedTabData?.redirect[index]
                          ?.toLowerCase()
                          .startsWith("https://")
                          ? `https://${selectedTabData?.redirect[index]}`
                          : selectedTabData?.redirect[index]
                      }
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                      className={`rounded-20px ${
                        selectedTabData.redirect[index]
                          ? "cursor-pointer"
                          : "cursor-default"
                      }`}
                      style={{
                        width: screenWidth >= 1024 && "518px",
                        height: screenWidth < 1024 ? "464px" : "579px",
                      }}
                    >
                      <img
                        src={item}
                        className="object-cover rounded-20px"
                        style={{
                          width: screenWidth >= 1024 && "518px",
                          height: screenWidth < 1024 ? "464px" : "579px",
                        }}
                      />
                    </a>
                  ))}
            </Slider>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col justify-start items-stretch w-full gap-8 tablet:gap-12 pl-4 pb-7 tablet:px-19 tablet:pb-8 bg-primaryColor relative"
        style={{ marginTop: screenWidth < 1024 ? "72px" : "120px" }}
      >
        <img
          className={`absolute h-full object-cover ${
            screenWidth < 1024 ? "left-0" : "right-12"
          }`}
          src={
            screenWidth < 1024
              ? "/illu_curve_rmsubmission.svg"
              : "/illu_bg_exclusive_classes.svg"
          }
        />
        <p
          className="text-left font-bold text-2xl tablet:text-48px text-white tracking-0.8px relative z-10 mt-16 tablet:mt-19"
          style={{ lineHeight: screenWidth < 1024 ? "25.92px" : "51.84px" }}
        >
          OCBC Summer Camp 2024
        </p>
        <div
          className="flex flex-row justify-start items-start w-full overflow-x-scroll tablet:pb-6"
          style={{ gap: screenWidth < 1024 ? "17.4px" : "23px" }}
        >
          {exclusiveClasses?.upcoming?.map((item, index) => (
            <div key={index}>
              <HomeCardClassV2
                isExclusive={true}
                item={item}
                mobile={screenWidth < 1024 ? true : false}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="relative w-full h-auto mt-9 tablet:mt-18">
        <Slider ref={imageCarouselRef} {...imageCarouselSetting}>
          {carouselImages?.map((item, index) => (
            <div key={index} className="px-2 tablet:px-16">
              <img
                src={`${
                  screenWidth < 1024 ? item.mobile_url : item.desktop_url
                }`}
                className="object-cover rounded-md"
                style={{
                  minWidth: screenWidth < 1024 ? "297.39px" : "928px",
                  minHeight: screenWidth < 1024 ? "166px" : "518px",
                  maxWidth: screenWidth < 1024 ? "297.39px" : "928px",
                  maxHeight: screenWidth < 1024 ? "166px" : "518px",
                }}
              />
            </div>
          ))}
        </Slider>
        <div
          className="absolute w-full object-center flex flex-row justify-between top-1/2 mt-5 px-7 tablet:px-9 hd:px-42 satutiga:px-52 mac:px-62"
          style={{
            paddingLeft: screenWidth >= 1920 && "25%",
            paddingRight: screenWidth >= 1920 && "25%",
          }}
        >
          <button
            onClick={(e) => {
              imageCarouselRef.current.slickPrev();
            }}
            className="rounded-full p-2 bg-white"
          >
            <ChevronLeftIcon
              className="w-auto p-0 m-0"
              style={{ height: screenWidth < 1024 ? "20px" : "40px" }}
            />
          </button>
          <button
            onClick={() => {
              imageCarouselRef.current.slickNext();
            }}
            className="rounded-full p-2 bg-white"
          >
            <ChevronRightIcon
              className="w-auto p-0 m-0"
              style={{ height: screenWidth < 1024 ? "20px" : "40px" }}
            />
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-start items-center px-4 mt-8 tablet:mt-32 w-full">
        <p
          className="text-left tablet:text-center font-bold text-base text-tosca2 w-full"
          style={{ lineHeight: "24px", letterSpacing: "2px" }}
        >
          TESTIMONIAL
        </p>
        <p
          className="text-left tablet:text-center font-bold text-2xl tablet:text-48px text-black1 mt-4"
          style={{
            lineHeight: screenWidth < 1024 ? "28.8px" : "57.6px",
            letterSpacing: "-0.8px",
          }}
        >
          Apa kata mereka tentang {screenWidth >= 1024 && <br />}{" "}
          {screenWidth >= 1024 && "OCBC "}Summer Camp{" "}
          {screenWidth >= 1024 && <br />}
          {screenWidth >= 1024 && "2023"}?
        </p>
        <div className="w-full tablet:grid tablet:grid-cols-3 gap-6 mt-10 tablet:mt-16 tablet:px-20">
          {testimonies
            .slice(0, screenWidth < 1024 ? 1 : 3)
            .map((item, index) => (
              <div key={index}>
                <TestimonyCard
                  testimony={item.testimony}
                  source={item.source}
                  photo={item.photo}
                  name={item.name}
                  degree={item.degree}
                />
              </div>
            ))}
        </div>
      </div>
      {/* footer */}
      <div className="flex flex-col w-full items-center">
        <div
          className="w-full flex flex-col justify-start items-stretch px-4 mt-16"
          style={{
            maxWidth: screenWidth >= 1024 && "600px",
          }}
        >
          <a
            href="https://ocbc-id.typeform.com/to/g2wj25QH"
            target="_blank"
            rel="noreferrer"
            className={`w-full py-3 rounded-lg bg-primaryColor text-center font-semibold ${
              screenWidth < 1024 ? "text-xs" : "text-base"
            } text-white`}
            style={{
              lineHeight: screenWidth < 1024 ? "18px" : "24px",
            }}
          >
            Saya Tertarik!
          </a>
          <p
            className={`text-center font-bold ${
              screenWidth < 1024 ? "text-3xl" : "text-60px"
            } text-black`}
            style={{
              lineHeight: screenWidth < 1024 ? "38.4px" : "72px",
              letterSpacing: "-1.2px",
              marginTop: screenWidth ? "67px" : "",
            }}
          >
            Ingin tahu lebih <br />
            lanjut?
          </p>
          <div
            className="flex flex-row justify-center items-center gap-2 w-full"
            style={{
              marginTop: "71px",
            }}
          >
            {knowMore.map((item, index) => (
              <a
                rel="noreferrer"
                href={item.redirect}
                target="_blank"
                key={index}
                className="flex flex-row items-center justify-center w-full bg-transparent border-2 border-primaryColor rounded-lg cursor-pointer"
                style={{
                  paddingTop: "14px",
                  paddingBottom: "14px",
                  gap: "10px",
                }}
              >
                {index > 0 ? (
                  <img src={item.icon} className="h-5 w-auto" />
                ) : (
                  index >= 0 &&
                  screenWidth < 1024 && (
                    <img src={item.icon} className="h-5 w-auto" />
                  )
                )}
                {screenWidth >= 1024 && (
                  <p
                    className="text-left font-semibold text-base text-black"
                    style={{ lineHeight: "24px" }}
                  >
                    {item.name}
                  </p>
                )}
              </a>
            ))}
          </div>
        </div>
        <div
          className="flex flex-row items-end justify-between w-full px-5 relative tablet:h-24"
          style={{ marginTop: "29px" }}
        >
          <img
            src="/hand_illu_left.svg"
            className="tablet:absolute tablet:left-14 tablet:w-26 mac:left-15 mac:w-36"
            alt="hand illu left"
          />
          {screenWidth >= 1024 ? (
            <img
              src="/hand_illu_mid0.svg"
              className="tablet:absolute tablet:left-36 tablet:w-24 mac:left-40 mac:w-32"
              alt="hand illu mid0"
            />
          ) : (
            <div></div>
          )}
          {screenWidth >= 1024 ? (
            <div className="flex w-full"></div>
          ) : (
            <div></div>
          )}
          <img
            src="/hand_illu_mid.svg"
            className="tablet:absolute tablet:right-40 tablet:w-30 mac:right-42 mac:w-42"
            alt="hand illu mid"
          />
          <img
            src="/hand_illu_right.svg"
            className="tablet:absolute tablet:right-10 tablet:w-28 mac:right-7 mac:w-38"
            alt="hand illu right"
          />
        </div>
      </div>
    </LayoutV2>
  );
}
