export default function CardMajorSubject({ item, screenWidth }) {
  return (
    <div
      className="flex flex-col justify-start items-start w-full bg-white rounded-lg"
      style={{
        boxShadow: "24px 24px 200px rgba(64, 69, 79, 0.06)",
        padding:
          screenWidth < 1024
            ? "34.23px 0px 25.67px 34.23px"
            : "27.43px 72px 20.57px 27.43px",
        gap: screenWidth < 1024 ? "17.12px" : "13.71px",
        minWidth: screenWidth >= 1024 && "362.58px",
        maxWidth: screenWidth >= 1024 && "362.58px",
        minHeight: screenWidth >= 1024 && "120px",
        maxHeight: screenWidth >= 1024 && "120px",
      }}
    >
      <p
        className="text-left font-bold text-2xl tablet:text-xl text-black1 line-clamp-2 overflow-ellipsis"
        style={{ lineHeight: screenWidth < 1024 && "36px" }}
      >
        {item.name}
      </p>
      {/* <p
        className={`text-center font-medium text-base tablet:text-sm ${
          item.type === "Wajib" ? "text-primaryColor" : "text-red1"
        }`}
        style={{
          backgroundColor: "#F1F3F6",
          borderRadius: "8px",
          lineHeight: screenWidth < 1024 ? "24px" : "20.57px",
          padding:
            screenWidth < 1024
              ? "5.12px 12.84px 5.12px 12.84px"
              : "3.21px 10.29px 3.21px 10.29px",
        }}
      >
        {item.type}
      </p> */}
    </div>
  );
}
