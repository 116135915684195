import { useEffect, useState } from "react";
import PageLoading from "../../PageLoading";

export default function LaSalleRegistrationDetails({
  draftData,
  callbackData,
  setCurrentForm,
  currentForm,
}) {
  const uniLocation = ["Jakarta", "Surabaya"];
  const programType = ["Diploma", "Certificate", "D4", "S1"];
  const programChoices = [
    {
      type: "Diploma",
      choices: [
        "Fashion Design",
        "Fashion Business",
        "Graphic Design Diploma",
        "Game Art Design",
        "Photography",
        "Interior Design",
        "Management Creative Industries Diploma",
      ],
    },
    {
      type: "Certificate",
      choices: [
        "Pattern Maker",
        "Stylist/Illustrator",
        "Buyer",
        "Retail Manager",
        "Graphic Design Certificate",
        "Photography",
        "Interior Design",
        "Artistic Make Up Module 1 & 2",
        "Management Creative Industries Certificate",
      ],
    },
    {
      type: "D4",
      choices: [
        "Fashion Design D4 Standard",
        "Fashion Design D4 Bridging",
        "Fashion Merchandising D4 Standard",
        "Fashion Merchandising D4 Bridging",
      ],
    },
    {
      type: "S1",
      choices: [
        "Interior Design Bachelor Standard",
        "Interior Design Bachelor Bridging",
      ],
    },
  ];

  const [selectedUniLocation, setSelectedUniLocation] = useState(
    uniLocation[0]
  );
  const [selectedProgram, setSelectedProgram] = useState(programType[0]);
  const [selectedProgramChoice, setSelectedProgramChoice] = useState(
    programChoices[0].choices
  );
  const [selectedProgramStudi, setSelectedProgramStudi] = useState(
    programChoices[0].choices[0]
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (draftData) {
      setSelectedUniLocation(
        uniLocation[uniLocation.indexOf(draftData.lokasiKampus)]
      );
      setSelectedProgram(
        programType[programType.indexOf(draftData.jenisProgram)]
      );
      setSelectedProgramChoice(
        programChoices[
          programChoices.findIndex(
            (program) => program.type === draftData.jenisProgram
          )
        ].choices
      );
      setSelectedProgramStudi(
        programChoices[
          programChoices.findIndex(
            (program) => program.type === draftData.jenisProgram
          )
        ].choices[
          programChoices[
            programChoices.findIndex(
              (choice) => choice.type === draftData.jenisProgram
            )
          ].choices.indexOf(draftData.pilihanProgram)
        ]
      );
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [draftData]);

  function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    callbackData({
      lokasiKampus: elements[0].value,
      jenisProgram: elements[1].value,
      pilihanProgram: elements[2].value,
    });
    setCurrentForm(currentForm + 1);
  }

  // below function is for auto save feature
  // useEffect(() => {
  //   const saveInterval = setInterval(() => {
  //     submitForm();
  //   }, 30000);

  //   return () => {
  //     clearInterval(saveInterval);
  //   };
  // }, []);

  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <form onSubmit={submitForm} className="flex w-full">
      <div className="flex flex-col w-full justify-start items-start pb-10">
        <div className="flex flex-col w-full justify-start">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
            <div className="mt-2">
              <label
                className="block text-base font-bold leading-6 text-gray-900"
                htmlFor="uni_location"
              >
                Lokasi Kampus <span className={"text-red-500"}>*</span>
              </label>
              <select
                id="education_level"
                placeholder={"Pilih Lokasi Kampus"}
                className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                aria-label="education level"
                name="education_level_id"
                value={selectedUniLocation}
                onChange={(e) => {
                  const location = e.target.value;
                  setSelectedUniLocation(location);
                }}
                required
              >
                {uniLocation.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-2">
              <label className="block text-base font-bold leading-6 text-gray-900">
                Jenis Program <span className={"text-red-500"}>*</span>
              </label>
              <select
                className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                placeholder={"Pilih Tipe Program"}
                value={selectedProgram}
                onChange={(e) => {
                  const program = e.target.value;
                  setSelectedProgram(program);
                  setSelectedProgramChoice(
                    programChoices[
                      programChoices.findIndex((item) => item.type === program)
                    ].choices
                  );
                  setSelectedProgramStudi(
                    programChoices[
                      programChoices.findIndex((item) => item.type === program)
                    ].choices[0]
                  );
                }}
                required
              >
                {programType.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-2">
              <label className="block text-base font-bold leading-6 text-gray-900">
                Pilihan Program<span className={"text-red-500"}>*</span>
              </label>
              <select
                placeholder={"Pilih Program Studi"}
                className={`form-input w-full text-gray-800 rounded-40px mt-4`}
                value={selectedProgramStudi}
                onChange={(e) => {
                  setSelectedProgramStudi(e.target.value);
                }}
                required
              >
                {selectedProgramChoice.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center space-x-10 w-full items-center pb-10">
          <button
            type="submit"
            className="py-3 bg-primaryColor text-center font-semibold text-base text-white rounded-lg mt-16"
            style={{ lineHeight: "24px", minWidth: "200px" }}
          >
            Selanjutnya
          </button>
        </div>
      </div>
    </form>
  );
}
