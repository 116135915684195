import { ArrowRightIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";

export default function TMBCharSection({
  order,
  img_url,
  title,
  caption,
  detail1,
  detail2,
}) {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  
  var screenWidth = windowSize.innerWidth;

  return (
    <div className="flex flex-col tablet:flex-row tablet:gap-40 tablet:items-center">
      {order === "even" && screenWidth >= 1024 && (
        <img
          src={img_url}
          className="object-contain w-full h-auto tablet:h-100 tablet:w-auto mt-13"
          alt="about us illustration"
        />
      )}
      <div className="flex flex-col">
        <p className="text-left text-black2 text-2xl tablet:text-48px font-bold tracking-tighter">
          {title}
        </p>
        <p className="text-left text-black2 text-base tablet:text-xl font-normal mt-10">
          {caption}
        </p>
        <button
          type="button"
          onClick={openModal}
          className="flex flex-row justify-start items-center gap-3 mt-6"
        >
          <p className="text-left text-primaryColor text-base font-semibold">
            Kenali lebih dalam
          </p>
          <ArrowRightIcon className="w-5 h-5" color={"#662E9B"} />
        </button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-col">
                    <button type="button" onClick={closeModal}>
                      <MdClose size={24} color="rgba(0, 0, 0, 0.54)" />
                    </button>
                    <div className="flex flex-col tablet:flex-row tablet:gap-14 tablet:items-center tablet:my-14 tablet:mx-16">
                      <img
                        src={img_url}
                        className="object-contain w-full h-auto mt-16 tablet:mt-0 tablet:w-1/2"
                        alt="illustration"
                      />
                      <div className="flex flex-col">
                        <p className="text-left text-black2 text-2xl font-bold tracking-tighter mt-18 tablet:mt-0">
                          {title}
                        </p>
                        <p className="text-left text-black2 text-base font-normal mt-6">
                          {detail1}
                        </p>
                        <p className="text-left text-black2 text-base font-normal mt-4">
                          {detail2}
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {screenWidth < 1024 && (
        <img
          src={img_url}
          className="object-contain w-full h-auto mt-13"
          alt="about us illustration"
        />
      )}
      {order === "odd" && screenWidth >= 1024 && (
        <img
          src={img_url}
          className="object-contain w-full h-auto tablet:h-100 tablet:w-auto mt-13"
          alt="about us illustration"
        />
      )}
    </div>
  );
}
