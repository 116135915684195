import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";

export default function SpeakerCard({
  item,
  index,
  mobile,
  currentSpeaker,
  totalSpeakers,
  prevClick,
  scrollX,
  nextClick,
  scrolEnd,
}) {
  return (
    <div
      className={`relative flex flex-col ${
        mobile ? "w-full" : "w-max"
      } gap-4 p-4 hd:p-6 rounded-xl bg-white`}
      style={{
        minWidth: `${!mobile ? "712px" : "300px"}`,
        maxWidth: `${!mobile ? "712px" : "300px"}`,
        height: `${
          item.description === "" ? "263px" : mobile ? "336px" : "388px"
        }`,
        // boxShadow: "24px 24px 200px rgba(23, 23, 23, 0.08)",
      }}
    >
      <div className="flex flex-row gap-2 hd:gap-6 items-center justify-start">
        <img
          alt="profile icon"
          src={item.image_url !== "" ? item.image_url : "/icon_profile.svg"}
          className="w-20 h-20 hd:w-30 hd:h-30 rounded-xl object-cover"
        />
        <div className="flex flex-col justify-center items-start gap-2 hd:gap-3">
          <p
            className="text-left font-semibold text-base hd:text-2xl text-black1 tracking-0.8px line-clamp-2 overflow-ellipsis"
            style={{ lineHeight: `${mobile ? "17px" : "26px"}` }}
          >
            {item.name}
          </p>
          <p
            className="text-left font-normal text-sm hd:text-xl text-gray5 line-clamp-2 overflow-ellipsis"
            style={{ lineHeight: `${mobile ? "17px" : "22px"}` }}
          >
            {item.work}
          </p>
        </div>
      </div>
      <p
        className="text-justify font-normal text-base hd:text-xl text-black1"
        style={{ lineHeight: `${mobile ? "25px" : "31px"}`, opacity: "0.88" }}
      >
        {item.description}
      </p>
      {!mobile && (
        <div className="flex flex-row gap-2 items-center justify-end absolute bottom-6 right-6">
          <p
            className="text-center font-normal text-black1"
            style={{ fontSize: "22px", opacity: "0.6" }}
          >
            {currentSpeaker + 1}/{totalSpeakers}
          </p>
          <button
            onClick={() => {
              prevClick();
            }}
            disabled={scrollX === 0 || currentSpeaker === 0}
            className={`bg-tosca2 rounded-lg p-3 ml-10 ${
              scrollX === 0 || currentSpeaker === 0
                ? "opacity-30"
                : "opacity-100"
            }`}
          >
            <ArrowLeftIcon className="w-4 h-4 mac:w-5 mac:h-5" color="white" />
          </button>
          <button
            onClick={() => {
              nextClick();
            }}
            disabled={scrolEnd || currentSpeaker === totalSpeakers - 1}
            className={`bg-tosca2 rounded-lg p-3 ${
              scrolEnd || currentSpeaker === totalSpeakers - 1
                ? // currentSliderPage === Math.ceil(topUnivs?.length / 3)
                  "opacity-30"
                : "opacity-100"
            }`}
          >
            <ArrowRightIcon className="w-4 h-4 mac:w-5 mac:h-5" color="white" />
          </button>
        </div>
      )}
    </div>
  );
}
