import { useEffect } from "react";
import { api } from "../utils/api";
import Cookies from "js-cookie";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import { useNavigate, useParams } from "react-router-dom";

export default function EmailVerifCallback() {
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();
  let { token } = useParams();
  const params = new URLSearchParams(window.location.search);
  let path = "/profile-edit";

  useEffect(() => {
    if (!auth.isAuth || !Cookies.get("user.token")) {
      navigate(`/signin?redirect=/students/verification/${token}`);
    } else {
      api
        .post(`/students/registration/verification/${token}`)
        .then(() => {
          window.Swal.fire({
            title: "Success!",
            text: `Verifikasi Email Berhasil!`,
            icon: "success",
            confirmButtonText: "Tutup",
            allowOutsideClick: false,
          }).then(() => {
            if (!params.get("class_slug")) {
              navigate(path);
            }
            if (params.get("class_slug")) {
              path = params.get("class_slug");
              Cookies.set("confirmClass", true, {
                sameSite: "None",
                secure: true,
              });
              navigate(`/explore-kelas/${path}`);
            } else {
              navigate(path);
            }
          });
        })
        .catch((err) => {
          if (err.response.status === 304) {
            navigate(`/profile-edit`);

            // Reserved for later use, when Google OAuth is stable
            // if (err.response.data.code === 304) {
            // window.Swal.fire({
            //   // title: "Success!",
            //   text: `silakan Masuk dengan Google`,
            //   icon: "error",
            //   confirmButtonText: "Tutup",
            //   allowOutsideClick: false,
            // }).then(() => {
            //   navigate(`/profile-edit`);
            // });
          } else {
            window.Swal.fire({
              title: "Error!",
              text: err.response.data.error.message,
              icon: "error",
              confirmButtonText: "Tutup",
              allowOutsideClick: false,
            }).then(() => {
              navigate(`/`);
            });
          }
        });
    }
  });

  return <div className="w-screen h-screen bg-white"></div>;
}
