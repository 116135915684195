import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import TMBCard from "../components/TMBCard";
import TMBCharSection from "../components/TMBCharSection";
import LayoutV2 from "../components/Layout_V2";

export default function TestMinatBakat() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  var screenWidth = windowSize.innerWidth;

  return (
    <LayoutV2 padding="0px">
      <div className="flex flex-col w-full">
        <div className="flex flex-col pt-14 mac:pt-28 px-4 tablet:px-0 bg-redTM tablet:relative">
          <p className="text-center text-3xl tablet:text-4xl mac:text-60px text-white font-bold tablet:px-60 hd:px-100 mac:px-98">
            Pastiin minat kamu, Pastiin masa depanmu, #PastiinBarengSerra
          </p>
          <p className="text-center text-base hd:text-xl mac:text-xl tablet:px-56 hd:px-70 mac:px-98 text-white font-normal mt-6">
            Pastiin minat dan jurusan yang kamu banget bersama Serra, virtual
            passion consultant dari Exploration.
          </p>
          <div className="tablet:flex tablet:justify-center z-10">
            <Link to="/tes-minat">
              <div className="w-full h-auto py-3 bg-primaryColor mt-12 text-center text-base text-white font-semibold rounded-lg tablet:w-max tablet:px-6 mac:my-0 mac:mt-12 tablet:mb-20 mac:mb-26">
                Pastiin sekarang
              </div>
            </Link>
          </div>
          {screenWidth >= 1024 && (
            <div>
              <img
                src="/tmb-header-leftvector.svg"
                className="absolute top-0 tablet:h-modal mac:h-max"
              />
              <img
                src="/tmb-header-rightvector.svg"
                className="absolute top-0 right-0 tablet:h-modal mac:h-max"
              />
              <img
                src="/tmb-header-boxvector1.svg"
                className="absolute tablet:top-60 tablet:left-30 mac:top-74 mac:left-49 tablet:h-10 mac:w-max mac:h-max"
              />
              <img
                src="/tmb-header-boxvector2.svg"
                className="absolute tablet:top-92 tablet:left-44 mac:top-122 mac:left-74 tablet:h-10 mac:w-max mac:h-max"
              />
              <img
                src="/tmb-header-boxvector3.svg"
                className="absolute tablet:top-4 tablet:left-90 mac:top-6 mac:left-496px tablet:h-10 mac:w-max mac:h-max"
              />
              <img
                src="/tmb-header-boxvector4.svg"
                className="absolute tablet:top-30 tablet:right-50 mac:top-32 mac:right-70 tablet:h-10 mac:w-max mac:h-max"
              />
            </div>
          )}
          <img
            src="/illu-tmb-1.svg"
            className="object-cover object-top -mt-6 h-46 tablet:absolute tablet:h-64 hd:h-70 mac:h-88 tablet:object-contain tablet:bottom-0 tablet:right-0 tablet:mr-10"
          />
        </div>
        <div className="px-4 mac:px-20 bg-gradient-to-b from-E7 to-white">
          <p className="text-left text-black2 text-2xl mac:text-48px mac:w-1/2 mac:pr-18 font-bold mt-24 mb-16">
            3 Langkah sat set, temukan jurusan yang kamu banget{" "}
          </p>
          <div className="flex flex-col tablet:flex-row gap-5 mac:gap-6">
            <TMBCard
              icon="/home-logo-tmb.svg"
              title="Ikuti passion consulting"
              caption="Jadilah diri sendiri dan jawablah dengan jujur untuk mengetahui tipe minat Anda."
            />
            <TMBCard
              icon="/card_explore_icon4.svg"
              title="Lihat detail hasil consul"
              caption="Pelajari bagaimana tipe karakter kamu memengaruhi minat dan jurusan-mu."
            />
            <TMBCard
              icon="/card_explore_icon3.png"
              title="Explore rekomendasi jurusan"
              caption="Pilih rekomendasi jurusan yang sesuai dengan preferensi kamu dan tambahkan ke favorit."
            />
          </div>
          <p
            className="text-left tablet:text-center text-base font-bold tracking-widest leading-tight mt-22 mac:mt-30"
            style={{ color: "#43BCCD" }}
          >
            TIPE KARAKTER
          </p>
          <p className="text-left tablet:text-center text-black2 text-2xl mac:text-48px mac:px-80 font-bold tracking-tighter mt-4">
            Pahami setiap karakter kuat, untuk setiap passion hebat
          </p>
          <div className="flex flex-col gap-32 mt-20 mb-16 tablet:ml-5 tablet:mr-15">
            <TMBCharSection
              order="odd"
              img_url="https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Illustration/The%20Doers.png"
              title={`The Doers (Realistic)`}
              caption="Kepribadian ini cenderung lebih suka untuk bekerja di luar ruangan daripada harus duduk cantik di dalam ruangan. Orang-orang disekitar, memandangmu sebagai orang yang sederhana, realistis, dan dapat diandalkan. Intinya, No Ribet-Ribet Club!"
              detail1="Kamu cenderung lebih suka untuk bekerja di luar ruangan daripada harus duduk cantik di dalam ruangan. Ketika disuruh memilih tipe tugas atau pekerjaan yang sesuai sama style kamu, kamu bakal pilih untuk kerja dengan hal-hal yang bisa kamu liat dan sentuh secara langsung. Eits, tapi yang dimaksud di sini bukan doi ya melainkan peralatan, mesin, hewan, tumbuhan, dan sebagainya."
              detail2="Orang-orang disekitarmu memandangmu sebagai orang yang sederhana, realistis, dan dapat diandalkan. Kamu tipe orang yang No Ribet Ribet Club, jadi ketika dihadapkan pada suatu masalah, kamu pasti langsung cari cara untuk menyelesaikan masalah tersebut dengan cara yang simple."
            />
            <TMBCharSection
              order="even"
              img_url="https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Illustration/The%20Thinkers.png"
              title={`The Thinkers (Investigative)`}
              caption="Kepo menjadi kata yang tepat buat menggambarkan dirimu. Selalu kepingin tau kenapa suatu hal bisa ada dan cara gimana cara kerjanya. Orang-orang kaya kamu digambarkan sebagai orang yang sangat analitis, intelektual, dan berwawasan luas!"
              detail1="Kamu selalu kepingin tau kenapa suatu hal bisa ada dan cara kerjanya itu gimana. Kamu nggak bakal kalah kalau dibandingin sama admin lambe turah!Kenapa? Searching informasi adalah suatu hal yang udah basic buat kamu! Setelah berhasil mengumpulkan informasi,  kamu langsung  menganalisis dan memecahkan masalah. Kalau admin lambe mah cuma bisa cari info doang, tapi kamu? Sampai memecahkan masalah lho!"
              detail2="Kamu yang termasuk dalam kepribadian investigative ini suka karier yang membutuhkan ide, analisis, dan intelektual. Orang-orang kaya kamu biasanya digambarkan sebagai orang yang sangat analitis, intelektual, dan berwawasan luas."
            />
            <TMBCharSection
              order="odd"
              img_url="https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Illustration/The%20Creator.png"
              title={`The Creators (Artistic)`}
              caption="Seorang yang berjiwa seni dengan kreativitas tingkat dewa. Dimana pun dan kapan pun, kamu selalu bisa menyelesaikan masalah dengan kreatif!  Orang di sekitar kamu seringkali menilai kamu berjiwa bebas, sensitif, dan imajinatif."
              detail1="Yap, kamu adalah orang yang berjiwa seni! Berbeda dengan karakter lainnya, kamu punya tingkat kreatifitas tingkat dewa. Orang di sekitar kamu seringkali menilai kamu berjiwa bebas, sensitif, dan imajinatif. Tapi, nggak perlu khawatir! Justru itu adalah kelebihan kamu lho!"
              detail2="Dimana pun dan kapan pun, kamu selalu bisa aja menyelesaikan tugas atau masalah dengan kreatif! Tipe-tipe orang seperti kamu ini akan lebih berkembang ketika dibebaskan untuk dapat mengekspresikan diri secara bebas. Nah, satu hal yang kebanyakan orang dengan tipe ini nggak suka adalah aktivitas yang rutin atau berulang."
            />
            <TMBCharSection
              order="even"
              img_url="https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Illustration/The%20Helpers.png"
              title={`The Helpers (Social)`}
              caption="Tiga kata yang kamu banget adalah pandai bersosialisasi, hangat, dan suka membantu. Kamu terlahir dengan kemampuan empati dan komunikasi yang baik, sehingga kamu sangat enjoy berada di dalam sebuah komunitas yang melibatkan banyak orang."
              detail1="Pandai bersosialisasi, hangat, suka membantu. Tiga kata ini sangat mendeskripsikan kamu nih bestie! Kamu terlahir dengan kemampuan empati dan komunikasi yang baik. Saat temen atau seseorang yang kamu kenal mengalami masalah, kamu selalu menjadi yang terdepan buat bantu mereka sambil nyanyi “i’m only one call away~”."
              detail2="“Lagi pingin sendiri?” Kata-kata ini nggak ada di kamus kalian. Kenapa? Karena kalian sangat enjoy berada di dalam sebuah komunitas yang melibatkan banyak orang. Bagi kamu keberadaan orang lain itu hal yang sangat berharga. Jadi “jangan tinggalin aku ya” sepertinya adalah sebuah kata-kata yang pasti sering kalian ucapin ya bestie!"
            />
            <TMBCharSection
              order="odd"
              img_url="https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Illustration/The%20Persuaders.png"
              title={`The Persuaders (Enterprising)`}
              caption="Temen-temen kamu pasti sering banget kerayu ama omongan kamu, karena kamu emang jagonya bujuk dan meyakinkan orang. Populer, supel, percaya diri, ambisius dan pinter ngomong. Lima kata ini bener-bener mewakilkan kepribadianmu deh!"
              detail1="Sering nggak sih temen kamu itu kerayu ama omongan kamu? Pasti sering lah! Orang kamu emang jago banget buat bujuk dan meyakinkan orang. Kamu yang masuk ke kepribadian ini punya 1001 macam ide dan kemampuan komunikasi yang baik jadi orang itu bakalan susah buat nolak ajakan kalian. Ya bisa dibilang mulut kalian itu udah selevel alat hipnotis ya!"
              detail2="Populer, supel, percaya diri, ambisius dan pinter ngomong. Lima kata ini bener-bener mewakilkan kepribadian kalian deh!. Jadi nggak kaget dong kalau orang di sekitar kalian secara nggak sengaja itu bakalan tertarik sama kalian dan selalu setuju sama ide-ide kalian."
            />
            <TMBCharSection
              order="even"
              img_url="https://strgonelabsprod.blob.core.windows.net/explorationid/Test%20Minat/Character%20Illustration/The%20Organizer.png"
              title={`The Organizer (Conventional)`}
              caption="Kamu dengan tipe kepribadian ini punya obsesi untuk membuat segala hal itu jadi rapi, tertata, dan sempurna.  Tipe-tipe karyawan yang mementingkan akurasi dan detail, yang kalau kerja itu sat set dan hasilnya sangat rapi, teratur dan baik."
              detail1="Welcome to tipe kepribadian organizer! Kamu dengan tipe kepribadian ini punya obsesi untuk membuat segala hal itu jadi rapi, tertata, dan sempurna. Kalian bisa pingsan kalau dihadapkan sama situasi atau lingkungan yang kalang kabut."
              detail2="Kalau diibaratin sama karakter di drama atau film, kalian itu mirip banget ama karyawan di perusahaan besar. Tipe-tipe karyawan yang mementingkan akurasi dan detail, yang kalau kerja itu sat set dan hasilnya sangat rapi, teratur dan baik. Jadi mau nggak mau bos itu bakal cinta ama kalian dan bakal mempercayakan tugas-tugas yang sulit buat kalian kerjakan."
            />
          </div>
        </div>
        <div className="relative flex flex-col px-4 bg-redTM tablet:items-center">
          <img
            src={`${
              screenWidth >= 1024
                ? "/tmb-footer-vector.svg"
                : "/tmb-footer-vector-mobile.svg"
            }`}
            className="absolute right-0"
          />
          <p className="text-center text-white text-3xl tablet:text-6xl tablet:px-60 font-bold tablet:mt-26 mt-32 z-10">
            Nah, kalo kamu tipe karakternya apa ?
          </p>
          <div className="z-10">
            <Link to="/tes-minat">
              <div className="w-full tablet:w-max h-auto py-3 bg-primaryColor mt-12 text-center text-base text-white font-semibold rounded-lg tablet:px-6 mac:my-0 mac:mt-12 mac:mb-26">
                Pastiin sekarang
              </div>
            </Link>
          </div>
          <img
            src="/illu-tmb-2.svg"
            className="object-cover object-bottom mt-19 tablet:absolute tablet:bottom-0 tablet:right-11 tablet:h-80"
          />
        </div>
      </div>
    </LayoutV2>
  );
}
